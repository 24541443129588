import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, toArray, ofArray } from "./.fable/fable-library.3.2.4/List.js";
import { useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { empty, append, singleton, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { tryFocus } from "./Utils.js";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { Helpers_ResultMessage$reflection, LoginForm$reflection, LoginForm } from "./Shared/ApiDataTypes.js";
import { printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "./.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryPost_5760677E } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { some } from "./.fable/fable-library.3.2.4/Option.js";
import { uncurry } from "./.fable/fable-library.3.2.4/Util.js";
import { obj_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { errorBanner, loadingIndicatorSmallColored } from "./ViewHelpers.js";

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    const submit = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#FFFFFF"], ["borderColor", "#6495ED"], ["backgroundColor", "#6495ED"], ["marginTop", 10]]));
    o = {
        inpt: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginTop", 10], ["marginBottom", 10]])),
        submit: submit,
    };
    return Object.assign({}, o);
});

export function Activation(activationInputProps) {
    const token = activationInputProps.token;
    const s = useStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505({
        Activated: false,
        ErrorMsg: void 0,
        Password: "",
        PasswordsMatch: true,
        Processing: false,
        RepeatPassword: "",
        ValidPassword: true,
    });
    const updateState = patternInput[1];
    const state = patternInput[0];
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 100 + "vw",
            height: 100 + "vh",
            backgroundColor: "#f5f5f5",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Paper, [["elevation", 2], ["style", {
            width: 90 + "%",
            maxWidth: 400,
            padding: 30,
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (state.Activated ? singleton(createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 1.5 + "rem",
                },
                children: "Bruker aktivert!",
            }), createElement("a", {
                children: "Logg inn",
                href: "/",
            })]),
        })) : singleton(toArray(ofArray([createElement("div", {
            style: {
                textAlign: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("img", {
                src: "/images/logo_large.png",
                style: {
                    marginBottom: 15 + "px",
                },
            })]),
        }), createElement("span", {
            children: "Velg ett passord for innlogging i Derigo",
        }), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["className", s.inpt]), delay(() => append(singleton(["id", "pwd-1"]), delay(() => append(singleton(["autoFocus", true]), delay(() => append(singleton(["required", true]), delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["label", "Passord"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["type", "password"]), delay(() => append(singleton(["defaultValue", state.Password]), delay(() => append(singleton(["onChange", (e) => {
            updateState({
                Activated: state.Activated,
                ErrorMsg: state.ErrorMsg,
                Password: Browser_Types_Event__Event_get_Value(e),
                PasswordsMatch: state.PasswordsMatch,
                Processing: state.Processing,
                RepeatPassword: state.RepeatPassword,
                ValidPassword: state.ValidPassword,
            });
        }]), delay(() => append((!state.ValidPassword) ? append(singleton(["error", true]), delay(() => singleton(["helperText", "Ugyldig passord. Må være minst 8 tegn."]))) : empty(), delay(() => singleton(["onKeyUp", (kc) => {
            kc.preventDefault();
            if ((kc.key === "Enter") ? (!state.Processing) : false) {
                tryFocus("pwd-2");
            }
        }])))))))))))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["className", s.inpt]), delay(() => append(singleton(["id", "pwd-2"]), delay(() => append(singleton(["required", true]), delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["label", "Gjenta passord"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["type", "password"]), delay(() => append(singleton(["defaultValue", state.RepeatPassword]), delay(() => append(singleton(["onChange", (e_1) => {
            updateState({
                Activated: state.Activated,
                ErrorMsg: state.ErrorMsg,
                Password: state.Password,
                PasswordsMatch: state.PasswordsMatch,
                Processing: state.Processing,
                RepeatPassword: Browser_Types_Event__Event_get_Value(e_1),
                ValidPassword: state.ValidPassword,
            });
        }]), delay(() => ((!state.PasswordsMatch) ? append(singleton(["error", true]), delay(() => singleton(["helperText", "Passord er ikke lik"]))) : empty())))))))))))))))))))))))), MuiHelpers_createElement(Button, [["className", s.submit], ["fullWidth", true], ["variant", "contained"], ["color", "primary"], ["onClick", (e_2) => {
            if (!state.Processing) {
                const pwMatch = state.Password === state.RepeatPassword;
                if (!(state.Password.length >= 8)) {
                    updateState({
                        Activated: state.Activated,
                        ErrorMsg: state.ErrorMsg,
                        Password: state.Password,
                        PasswordsMatch: state.PasswordsMatch,
                        Processing: state.Processing,
                        RepeatPassword: state.RepeatPassword,
                        ValidPassword: false,
                    });
                }
                else if (!pwMatch) {
                    updateState({
                        Activated: state.Activated,
                        ErrorMsg: state.ErrorMsg,
                        Password: state.Password,
                        PasswordsMatch: false,
                        Processing: state.Processing,
                        RepeatPassword: state.RepeatPassword,
                        ValidPassword: true,
                    });
                }
                else {
                    updateState({
                        Activated: state.Activated,
                        ErrorMsg: state.ErrorMsg,
                        Password: state.Password,
                        PasswordsMatch: true,
                        Processing: true,
                        RepeatPassword: state.RepeatPassword,
                        ValidPassword: true,
                    });
                    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const req = new LoginForm("", state.Password, false);
                        const requestPath = toText(printf("/api/basicauth/activate?token=%s"))(token);
                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: LoginForm$reflection,
                            })(req);
                            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: Helpers_ResultMessage$reflection,
                            });
                            return Fetch_tryPost_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                                ResolveType: Helpers_ResultMessage$reflection,
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg1) => {
                            const res = _arg1;
                            if (res.tag === 0) {
                                const x = res.fields[0];
                                if (x.Result === "success") {
                                    updateState({
                                        Activated: true,
                                        ErrorMsg: state.ErrorMsg,
                                        Password: state.Password,
                                        PasswordsMatch: state.PasswordsMatch,
                                        Processing: state.Processing,
                                        RepeatPassword: state.RepeatPassword,
                                        ValidPassword: state.ValidPassword,
                                    });
                                    return Promise.resolve();
                                }
                                else {
                                    updateState({
                                        Activated: state.Activated,
                                        ErrorMsg: x.Message,
                                        Password: state.Password,
                                        PasswordsMatch: state.PasswordsMatch,
                                        Processing: false,
                                        RepeatPassword: state.RepeatPassword,
                                        ValidPassword: state.ValidPassword,
                                    });
                                    return Promise.resolve();
                                }
                            }
                            else {
                                updateState({
                                    Activated: state.Activated,
                                    ErrorMsg: "Kunne ikke aktivere bruker. Vennligst prøv igjen senere.",
                                    Password: state.Password,
                                    PasswordsMatch: state.PasswordsMatch,
                                    Processing: false,
                                    RepeatPassword: state.RepeatPassword,
                                    ValidPassword: state.ValidPassword,
                                });
                                return Promise.resolve();
                            }
                        }));
                    }));
                    pr.then();
                }
            }
        }], ["style", {
            height: 37,
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!state.Processing) ? singleton("Aktiver bruker") : singleton(loadingIndicatorSmallColored("#fff")))))))]]), errorBanner(state.ErrorMsg, () => {
            updateState({
                Activated: state.Activated,
                ErrorMsg: void 0,
                Password: state.Password,
                PasswordsMatch: state.PasswordsMatch,
                Processing: state.Processing,
                RepeatPassword: state.RepeatPassword,
                ValidPassword: state.ValidPassword,
            });
        })]))))))))]])]),
    });
}

