import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, string_type, option_type, array_type, bool_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { AttestationContent$reflection, AttestationContent, AttestationMeta$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { hasProjectPermission } from "./ProjectPermission.js";
import { Functionality } from "../Shared/PermissionMapping.js";
import { hasPermission } from "../LoginState.js";
import { sortBy, map, sortByDescending, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, item as item_1, length, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { map as map_1, empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Table from "@material-ui/core/Table";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { loadingIndicatorSmall, snackbarError, PageElements_filenameToIcon } from "../ViewHelpers.js";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import Button from "@material-ui/core/Button";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { Project_fetchLoggedInUserPermissions } from "../Promises.js";

class Model extends Record {
    constructor(ProjectId, CanEdit, Attestations, ErrorMsg) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.CanEdit = CanEdit;
        this.Attestations = Attestations;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ProjectAttestation.Model", [], Model, () => [["ProjectId", int32_type], ["CanEdit", bool_type], ["Attestations", option_type(array_type(AttestationMeta$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AttestationsResponse", "PermissionsResponse", "AddResponse", "DeleteResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProjectAttestation.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(AttestationMeta$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(AttestationMeta$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(int32_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(int32_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [AttestationMeta$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", AttestationMeta$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [int32_type, FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", int32_type]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(projId) {
    return new Model(projId, false, void 0, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                return new Model(model.ProjectId, hasProjectPermission(new Functionality(2), res_1.fields[0]) ? true : hasPermission(new Functionality(60)), model.Attestations, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.ProjectId, model.CanEdit, model.Attestations, "Noe gikk galt. Kunne ikke laste opp attest.");
            }
            else {
                const x_2 = res_2.fields[0];
                return new Model(model.ProjectId, model.CanEdit, (matchValue = model.Attestations, (matchValue != null) ? append([x_2], matchValue) : [x_2]), model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 0) {
                return new Model(model.ProjectId, model.CanEdit, (matchValue_1 = model.Attestations, (matchValue_1 != null) ? matchValue_1.filter((y_1) => (y_1.Id !== res_3.fields[0])) : (void 0)), model.ErrorMsg);
            }
            else {
                return new Model(model.ProjectId, model.CanEdit, model.Attestations, "Noe gikk galt. Kunne ikke slette attest.");
            }
        }
        case 4: {
            return new Model(model.ProjectId, model.CanEdit, model.Attestations, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProjectId, model.CanEdit, model.Attestations, "Noe gikk galt. Kunne ikke laste attester for prosjekt.");
            }
            else {
                return new Model(model.ProjectId, model.CanEdit, res.fields[0], model.ErrorMsg);
            }
        }
    }
}

function fetchAttestations(projectId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/projectattestation/project/%i"))(projectId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(AttestationMeta$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(AttestationMeta$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function tryUpload(projId, content, filename, attestations, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let x_3;
        const req = new AttestationContent(-1, filename, "", filename, ((attestations != null) ? ((x_3 = sortByDescending((x_1) => x_1, map((x) => x.RowPos, attestations, Int32Array), {
            Compare: (x_2, y) => comparePrimitives(x_2, y),
        }), (x_3.length > 0) ? x_3[0] : 0)) : 0) + 1, content, projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: AttestationContent$reflection,
            })(req);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: AttestationMeta$reflection,
            });
            return Fetch_tryPost_5760677E("/api/projectattestation/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: AttestationMeta$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function tryDelete(attestationId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/projectattestation/delete/%i"))(attestationId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: () => string_type,
            })("");
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => int32_type,
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => int32_type,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function drawAttestations(model, dispatch, x) {
    const children_10 = toList(delay(() => append_1((x.length === 0) ? singleton(createElement("span", {
        children: "Ingen attester lastet opp for dette prosjektet.",
    })) : singleton(MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, []), MuiHelpers_createElement(TableCell, [["variant", "head"], ["children", "Filnavn"]]), MuiHelpers_createElement(TableCell, [])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((x_2) => MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([PageElements_filenameToIcon(x_2.Filename)])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("a", {
        target: "_blank",
        href: toText(printf("/api/projectattestation/download/%i"))(x_2.Id),
        children: x_2.Filename,
    })])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (model.CanEdit ? singleton(MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Delete, {})], ["onClick", (_arg1) => {
        tryDelete(x_2.Id, dispatch);
    }]])) : empty_1())))))]])])]]), sortBy((y) => y.RowPos, x, {
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    }))])]])])]])), delay(() => {
        let children_8;
        return model.CanEdit ? singleton((children_8 = singleton_1(createElement("div", {
            style: {
                marginTop: 25,
                display: "flex",
                justifyContent: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("input", {
                id: "attestation-select-input",
                type: "file",
                onChange: (ev) => {
                    const fileList = ev.target.files;
                    if (!(fileList == null)) {
                        const x_3 = toList(delay(() => map_1((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                        if (length(x_3) > 0) {
                            const file = item_1(0, x_3);
                            const reader = new FileReader();
                            reader.onload = ((_arg2) => {
                                tryUpload(model.ProjectId, toString(reader.result), file.name, model.Attestations, dispatch);
                            });
                            reader.readAsDataURL(file);
                        }
                    }
                },
                style: {
                    display: "none",
                },
            }), createElement("label", {
                htmlFor: "attestation-select-input",
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["component", "span"], ["startIcon", createElement("span", {
                    className: "fas fa-upload",
                })], ["children", "Last opp"]])]),
            })]),
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        }))) : empty_1();
    }))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(4));
        })), delay(() => append_1(singleton(createElement("span", {
            style: {
                fontSize: 1.3 + "rem",
                marginBottom: 20,
            },
            children: "Attester",
        })), delay(() => {
            const matchValue = model.Attestations;
            return (matchValue != null) ? singleton(drawAttestations(model, dispatch, matchValue)) : singleton(loadingIndicatorSmall());
        })))))))),
    });
}

export function Attestations(attestationsInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(attestationsInputProps.projId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchAttestations(model_1.ProjectId, dispatch);
        Project_fetchLoggedInUserPermissions(model_1.ProjectId, (arg) => {
            dispatch(new Message(1, arg));
        });
    });
    return view(model_1, dispatch);
}

