import { toString, Union, Record } from "./.fable/fable-library.3.2.4/Types.js";
import { Startpage_MissingHms$reflection, ProjectPlan_ProjectActivityUserResponsibility$reflection, Nonconformity_UserResponsible$reflection, Startpage_UserProject$reflection, ProcessModule_RevisionHistory$reflection, User$reflection } from "./Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, string_type, array_type, option_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { empty } from "./.fable/fable-library.3.2.4/List.js";
import { compareArrays, createObj, uncurry } from "./.fable/fable-library.3.2.4/Util.js";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import { loadingIndicatorLarge, snackbarError, Text_secondaryText, loadingIndicatorSmall } from "./ViewHelpers.js";
import { sortBy, map } from "./.fable/fable-library.3.2.4/Array.js";
import { ProjectStatus__ColorCode, ProjectStatus_FromInt_Z524259A4 } from "./Shared/Shared.js";
import { join, printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { asMonth, toDateStringNO, fromUtcString, fromUtcToLocalString } from "./Utils.js";
import { now, compare } from "./.fable/fable-library.3.2.4/Date.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./.fable/Feliz.1.68.0/React.fs.js";
import { User_fetchMe } from "./Promises.js";
import { Calendar } from "./MyPage/Calendar.js";

class Model extends Record {
    constructor(UserInfo, DocumentRevisions, Projects, Nonconformities, ProjectResponsibilities, Hms, ErrorMsg) {
        super();
        this.UserInfo = UserInfo;
        this.DocumentRevisions = DocumentRevisions;
        this.Projects = Projects;
        this.Nonconformities = Nonconformities;
        this.ProjectResponsibilities = ProjectResponsibilities;
        this.Hms = Hms;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("StartPage.Model", [], Model, () => [["UserInfo", option_type(User$reflection())], ["DocumentRevisions", option_type(array_type(ProcessModule_RevisionHistory$reflection()))], ["Projects", option_type(array_type(Startpage_UserProject$reflection()))], ["Nonconformities", option_type(array_type(Nonconformity_UserResponsible$reflection()))], ["ProjectResponsibilities", option_type(array_type(ProjectPlan_ProjectActivityUserResponsibility$reflection()))], ["Hms", option_type(array_type(Startpage_MissingHms$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UserInfoResponse", "ProjectsResponse", "DocumentRevisionsResponse", "NonconformitiesResponse", "ProjectResponsibilitiesResponse", "HmsResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("StartPage.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [User$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", User$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Startpage_UserProject$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Startpage_UserProject$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProcessModule_RevisionHistory$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProcessModule_RevisionHistory$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Nonconformity_UserResponsible$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Nonconformity_UserResponsible$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectPlan_ProjectActivityUserResponsibility$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectPlan_ProjectActivityUserResponsibility$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Startpage_MissingHms$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Startpage_MissingHms$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init() {
    return new Model(void 0, void 0, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, model.Nonconformities, model.ProjectResponsibilities, model.Hms, "Kunne ikke laste prosjekter. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.UserInfo, model.DocumentRevisions, res_1.fields[0], model.Nonconformities, model.ProjectResponsibilities, model.Hms, model.ErrorMsg);
            }
        }
        case 4: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, model.Nonconformities, model.ProjectResponsibilities, model.Hms, "Kunne ikke hente aktivitetsansvar. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, model.Nonconformities, res_2.fields[0], model.Hms, model.ErrorMsg);
            }
        }
        case 2: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, model.Nonconformities, model.ProjectResponsibilities, model.Hms, "Kunne ikke laste dokumentrevisjoner. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.UserInfo, res_3.fields[0], model.Projects, model.Nonconformities, model.ProjectResponsibilities, model.Hms, model.ErrorMsg);
            }
        }
        case 3: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, model.Nonconformities, model.ProjectResponsibilities, model.Hms, "Kunne ikke laste avvik. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, res_4.fields[0], model.ProjectResponsibilities, model.Hms, model.ErrorMsg);
            }
        }
        case 5: {
            const res_5 = msg.fields[0];
            if (res_5.tag === 1) {
                return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, model.Nonconformities, model.ProjectResponsibilities, model.Hms, "Kunne ikke laste HMS. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, model.Nonconformities, model.ProjectResponsibilities, res_5.fields[0], model.ErrorMsg);
            }
        }
        case 6: {
            return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, model.Nonconformities, model.ProjectResponsibilities, model.Hms, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.UserInfo, model.DocumentRevisions, model.Projects, model.Nonconformities, model.ProjectResponsibilities, model.Hms, "Kunne ikke laste bruker. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.DocumentRevisions, model.Projects, model.Nonconformities, model.ProjectResponsibilities, model.Hms, model.ErrorMsg);
            }
        }
    }
}

function getLastRevisions(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProcessModule_RevisionHistory$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/processactivitydoc/latest-revisions", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProcessModule_RevisionHistory$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function getNonconformities(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Nonconformity_UserResponsible$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/nonconformity/report/my-responsible", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Nonconformity_UserResponsible$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(3, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function getProjectResponsibilities(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProjectPlan_ProjectActivityUserResponsibility$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/project-plan/user-resposibilities", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProjectPlan_ProjectActivityUserResponsibility$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(4, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function getProjects(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Startpage_UserProject$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/project/my-projects", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Startpage_UserProject$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function getHms(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Startpage_MissingHms$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/hms/report/missing-registrations", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Startpage_MissingHms$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(5, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function drawRevisions(x) {
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", "Siste dokumentrevisjoner"]])), delay(() => ((x != null) ? ((x.length === 0) ? singleton(createElement("span", {
            children: "Ingen nylige revisjoner",
        })) : empty_1()) : singleton(loadingIndicatorSmall())))))))),
    });
}

function drawProjects(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            maxWidth: 400,
            padding: 10,
            ["box-shadow"]: "rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px",
            borderRadius: 4,
            margin: 5,
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", "Mine prosjekter"]])), delay(() => {
            const matchValue = model.Projects;
            if (matchValue != null) {
                const x = matchValue;
                return append((x.length === 0) ? singleton(createElement("div", {
                    style: {
                        textAlign: "center",
                        padding: 5,
                    },
                    children: Interop_reactApi.Children.toArray([Text_secondaryText("Ingen prosjekter")]),
                })) : empty_1(), delay(() => singleton(createElement("div", {
                    style: {
                        padding: 1,
                        overflowY: "auto",
                        maxHeight: 400,
                    },
                    children: Interop_reactApi.Children.toArray([map((p) => {
                        const status = ProjectStatus_FromInt_Z524259A4(p.Status);
                        return createElement("div", {
                            style: {
                                display: "flex",
                                margin: ((5 + "px ") + 0) + "px",
                                padding: 5,
                                borderRadius: 4,
                                ["box-shadow"]: "rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                style: {
                                    display: "flex",
                                    marginRight: 5,
                                },
                                children: Interop_reactApi.Children.toArray([createElement("img", {
                                    style: {
                                        maxWidth: 60,
                                        maxHeight: 60,
                                        borderRadius: 4,
                                    },
                                    src: toText(printf("/api/projectimage/thumbnail/%i"))(p.Id),
                                })]),
                            }), createElement("div", {
                                style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    flexGrow: 1,
                                },
                                children: Interop_reactApi.Children.toArray([createElement("div", {
                                    style: {
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("a", {
                                        href: toText(printf("/project/%i"))(p.Id),
                                        style: {
                                            overflowX: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            fontSize: 1.2 + "rem",
                                        },
                                        children: Interop_reactApi.Children.toArray([createElement("span", {
                                            children: [p.Name],
                                        })]),
                                    }), Text_secondaryText(p.Role)]),
                                }), createElement("span", {
                                    style: {
                                        fontSize: 0.8 + "rem",
                                        color: ProjectStatus__ColorCode(status),
                                    },
                                    children: toString(status),
                                })]),
                            })]),
                        });
                    }, x)]),
                }))));
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        })))))),
    });
}

function drawNonconformities(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            maxWidth: 400,
            padding: 10,
            ["box-shadow"]: "rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px",
            borderRadius: 4,
            margin: 5,
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", "Ansvarlige avvik"]])), delay(() => append(singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                marginBottom: 10,
            },
            children: "Avvik uten registrert tiltak hvor du er satt som ansvarlig.",
        })), delay(() => {
            const matchValue = model.Nonconformities;
            if (matchValue != null) {
                const x = matchValue;
                return append((x.length === 0) ? singleton(createElement("div", {
                    style: {
                        textAlign: "center",
                        padding: 5,
                    },
                    children: Interop_reactApi.Children.toArray([Text_secondaryText("Ingen avvik")]),
                })) : empty_1(), delay(() => singleton(createElement("div", {
                    style: {
                        maxHeight: 400,
                        overflowY: "auto",
                        padding: 1,
                    },
                    children: Interop_reactApi.Children.toArray([map((a) => createElement("div", {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            margin: ((5 + "px ") + 0) + "px",
                            padding: 5,
                            borderRadius: 4,
                            ["box-shadow"]: "rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            children: Interop_reactApi.Children.toArray([createElement("a", {
                                href: toText(printf("/project/%i"))(a.ProjectId),
                                style: {
                                    fontSize: 1.2 + "rem",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: [a.Project],
                                })]),
                            })]),
                        }), createElement("div", {
                            style: {},
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let arg10_1;
                                return append(singleton(createElement("div", {
                                    style: {
                                        marginBottom: 5,
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("span", {
                                        style: {
                                            fontSize: 0.85 + "rem",
                                        },
                                        children: Interop_reactApi.Children.toArray([createElement("span", {
                                            className: "fas fa-calendar-alt",
                                            style: {
                                                marginRight: 5,
                                            },
                                        }), (arg10_1 = fromUtcToLocalString(a.IncidentTime), toText(printf("Tidspunkt for hendelse: %s"))(arg10_1))]),
                                    })]),
                                })), delay(() => {
                                    let matchValue_1, ad, beyondDeadline, arg10_2;
                                    return append((matchValue_1 = a.ActionDeadline, (matchValue_1 != null) ? ((ad = matchValue_1, (beyondDeadline = (compare(fromUtcString(ad), now()) < 0), singleton(createElement("div", {
                                        style: {
                                            paddingBottom: 5,
                                        },
                                        children: Interop_reactApi.Children.toArray([createElement("span", {
                                            style: createObj(toList(delay(() => append(singleton(["fontSize", 0.85 + "rem"]), delay(() => (beyondDeadline ? singleton(["color", "#FF0000"]) : singleton(["color", "#9a9a9a"]))))))),
                                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                                className: "fas fa-clock",
                                                style: {
                                                    marginRight: 5,
                                                },
                                            }), (arg10_2 = fromUtcToLocalString(ad), toText(printf("Frist: %s"))(arg10_2))]),
                                        })]),
                                    }))))) : ((empty_1()))), delay(() => singleton(createElement("span", {
                                        children: a.Desc,
                                    }))));
                                }));
                            })))),
                        })]),
                    }), x)]),
                }))));
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        })))))))),
    });
}

function drawProjectResponsibilities(model) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            maxWidth: 400,
            padding: 10,
            ["box-shadow"]: "rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px",
            borderRadius: 4,
            margin: 5,
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", "Mine prosjekt aktiviteter"]])), delay(() => append(singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                marginBottom: 10,
            },
            children: "Prosjektplan aktiviteter der du er satt som ansvarlig.",
        })), delay(() => {
            const matchValue = model.ProjectResponsibilities;
            if (matchValue != null) {
                const x = matchValue;
                return append((x.length === 0) ? singleton(createElement("div", {
                    style: {
                        textAlign: "center",
                        padding: 5,
                    },
                    children: Interop_reactApi.Children.toArray([Text_secondaryText("Ingen uløste prosjekt aktiviteter.")]),
                })) : empty_1(), delay(() => singleton(createElement("div", {
                    style: {
                        maxHeight: 400,
                        overflowY: "auto",
                        padding: 1,
                    },
                    children: Interop_reactApi.Children.toArray([map((y_2) => createElement("div", {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            margin: ((5 + "px ") + 0) + "px",
                            padding: 5,
                            borderRadius: 4,
                            ["box-shadow"]: "rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
                            style: {
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("a", {
                                href: toText(printf("/project/%i"))(y_2.ProjectId),
                                style: {
                                    fontSize: 1.2 + "rem",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: [y_2.ActivityName],
                                })]),
                            })]),
                        })), delay(() => {
                            let matchValue_1, dl, beyondDeadline, arg10_1;
                            return append((matchValue_1 = y_2.DeadLine, (matchValue_1 != null) ? ((dl = matchValue_1, (beyondDeadline = (compare(dl, now()) < 0), singleton(createElement("div", {
                                style: {
                                    paddingBottom: 5,
                                },
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    style: createObj(toList(delay(() => append(singleton(["fontSize", 0.85 + "rem"]), delay(() => (beyondDeadline ? singleton(["color", "#FF0000"]) : singleton(["color", "#9a9a9a"]))))))),
                                    children: Interop_reactApi.Children.toArray([createElement("span", {
                                        className: "fas fa-clock",
                                        style: {
                                            marginRight: 5,
                                        },
                                    }), (arg10_1 = toDateStringNO(dl), toText(printf("Frist: %s"))(arg10_1))]),
                                })]),
                            }))))) : ((empty_1()))), delay(() => singleton(createElement("div", {
                                style: {},
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    style: {
                                        fontSize: 0.8 + "rem",
                                        display: "block",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    },
                                    children: y_2.ActivityDesc,
                                })]),
                            }))));
                        })))))),
                    }), sortBy((y) => [y.DeadLine == null, y.DeadLine], x, {
                        Compare: (x_1, y_1) => compareArrays(x_1, y_1),
                    }))]),
                }))));
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        })))))))),
    });
}

function drawHms(model) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            maxWidth: 400,
            padding: 10,
            ["box-shadow"]: "rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px",
            borderRadius: 4,
            margin: 5,
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", "Manglende HMS-registreringer"]])), delay(() => {
            const matchValue = model.Hms;
            if (matchValue != null) {
                const x = matchValue;
                return append((x.length === 0) ? singleton(createElement("div", {
                    style: {
                        textAlign: "center",
                        padding: 5,
                    },
                    children: Interop_reactApi.Children.toArray([Text_secondaryText("Ingen manglende HMS-registreringer")]),
                })) : empty_1(), delay(() => singleton(createElement("div", {
                    style: {
                        maxHeight: 400,
                        overflowY: "auto",
                        padding: 1,
                    },
                    children: Interop_reactApi.Children.toArray([map((y_2) => {
                        let arg10_1;
                        const missing = join(", ", y_2.HmsTypes);
                        return createElement("div", {
                            style: {
                                display: "flex",
                                flexDirection: "column",
                                margin: ((5 + "px ") + 0) + "px",
                                padding: 5,
                                borderRadius: 4,
                                ["box-shadow"]: "rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                style: {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("a", {
                                    href: toText(printf("/project/%i"))(y_2.ProjectId),
                                    style: {
                                        fontSize: 1.2 + "rem",
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("span", {
                                        children: [y_2.Project],
                                    })]),
                                }), createElement("span", {
                                    children: (arg10_1 = asMonth(y_2.Month), toText(printf("%s - %i"))(arg10_1)(y_2.Year)),
                                })]),
                            }), createElement("div", {
                                style: {},
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    style: {
                                        fontSize: 0.8 + "rem",
                                    },
                                    children: missing,
                                })]),
                            })]),
                        });
                    }, sortBy((y) => [y.ProjectId, y.Year, y.Month], x, {
                        Compare: (x_1, y_1) => compareArrays(x_1, y_1),
                    }))]),
                }))));
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        })))))),
    });
}

export function StartPage() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        User_fetchMe((arg) => {
            dispatch(new Message(0, arg));
        });
        getProjects(dispatch);
        getNonconformities(dispatch);
        getHms(dispatch);
        getLastRevisions(dispatch);
        getProjectResponsibilities(dispatch);
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            padding: 2 + "vw",
            justifyContent: "space-around",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(snackbarError(model_1.ErrorMsg, () => {
            dispatch(new Message(6));
        })), delay(() => {
            const matchValue = model_1.UserInfo;
            if (matchValue != null) {
                const userInfo = matchValue;
                return append(singleton(createElement("div", {
                    style: {
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        margin: 10,
                    },
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["variant", "h4"], ["style", {
                        marginBottom: 10,
                    }], ["children", toText(printf("Hei %s!"))(userInfo.Firstname)]]), createElement("div", {
                        style: {
                            display: "flex",
                            flexWrap: "wrap",
                            ["justify-content"]: "space-evenly",
                        },
                        children: Interop_reactApi.Children.toArray([drawProjects(model_1, dispatch), drawNonconformities(model_1, dispatch), drawHms(model_1), drawProjectResponsibilities(model_1)]),
                    })]),
                })), delay(() => singleton(createElement("div", {
                    style: {
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 400,
                        width: 100 + "%",
                        margin: 10,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        style: {
                            width: 100 + "%",
                            maxWidth: 400,
                        },
                        children: Interop_reactApi.Children.toArray([createElement(Calendar, {
                            userId: userInfo.Id,
                            canEdit: true,
                        })]),
                    }), drawRevisions(model_1.DocumentRevisions)]),
                }))));
            }
            else {
                return singleton(loadingIndicatorLarge());
            }
        })))))),
    });
}

