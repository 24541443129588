import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Helpers_ResultMessage$reflection, ProcessModule_CompanyOnedriveStatus, ProcessModule_SharePointSite$reflection, ProcessModule_CompanyOnedriveStatus$reflection, Company$reflection } from "../Shared/ApiDataTypes.js";
import { anonRecord_type, obj_type, union_type, record_type, string_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { sortBy, tryFind, append, map } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, toArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, equals, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { useReact_useReducer_2B9E6EA0, useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { empty as empty_1, append as append_1, singleton, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import Button from "@material-ui/core/Button";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { snackbarError, loadingIndicatorSmall } from "../ViewHelpers.js";
import Typography from "@material-ui/core/Typography";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { Company_fetchCompanies } from "../Promises.js";

class Model extends Record {
    constructor(Companies, CompanyStatus, Configure, ErrorMsg) {
        super();
        this.Companies = Companies;
        this.CompanyStatus = CompanyStatus;
        this.Configure = Configure;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("CompanyOnedrive.Model", [], Model, () => [["Companies", option_type(array_type(Company$reflection()))], ["CompanyStatus", option_type(array_type(ProcessModule_CompanyOnedriveStatus$reflection()))], ["Configure", option_type(Company$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompaniesResponse", "StatusResponse", "SetConfigure", "Configured", "SetError", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("CompanyOnedrive.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProcessModule_CompanyOnedriveStatus$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProcessModule_CompanyOnedriveStatus$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(Company$reflection())]], [["Item", ProcessModule_CompanyOnedriveStatus$reflection()]], [["Item", string_type]], []]);
}

function init() {
    return new Model(void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    let matchValue, z;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Companies, model.CompanyStatus, model.Configure, "Kunne ikke laste selskapsstatus. Vennligst prøv igjen senere");
            }
            else {
                return new Model(model.Companies, res_1.fields[0], model.Configure, model.ErrorMsg);
            }
        }
        case 2: {
            return new Model(model.Companies, model.CompanyStatus, msg.fields[0], model.ErrorMsg);
        }
        case 3: {
            const x_3 = msg.fields[0];
            return new Model(model.Companies, (matchValue = model.CompanyStatus, (matchValue != null) ? ((z = matchValue, z.some((a) => (a.Id === x_3.Id)) ? map((a_1) => {
                if (a_1.Id === x_3.Id) {
                    return x_3;
                }
                else {
                    return a_1;
                }
            }, z) : append([x_3], z))) : [x_3]), void 0, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.Companies, model.CompanyStatus, model.Configure, msg.fields[0]);
        }
        case 5: {
            return new Model(model.Companies, model.CompanyStatus, model.Configure, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Companies, model.CompanyStatus, model.Configure, "Kunne ikke laste selskap. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.CompanyStatus, model.Configure, model.ErrorMsg);
            }
        }
    }
}

function fetchStatuses(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProcessModule_CompanyOnedriveStatus$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/company/onedrive-status", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProcessModule_CompanyOnedriveStatus$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchSites(callback) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProcessModule_SharePointSite$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/company/sp-sites", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProcessModule_SharePointSite$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        callback(_arg1);
        return Promise.resolve();
    })))));
    pr.then();
}

class ConfigureView extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SiteSearch", "SiteList"];
    }
}

function ConfigureView$reflection() {
    return union_type("CompanyOnedrive.ConfigureView", [], ConfigureView, () => [[], []]);
}

function CompanyConfigure(companyConfigureInputProps) {
    const dispatch = companyConfigureInputProps.dispatch;
    const x = companyConfigureInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505(new ConfigureView(0));
    const setView = patternInput[1];
    const currentView = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([]);
    const setSearchResult = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const siteList = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_3[1];
    const processing = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(0);
    useReact_useEffectOnce_3A5B6456(() => {
        fetchSites((res) => {
            if (res.tag === 1) {
                dispatch(new Message(4, "Kunne ikke laste SharePoint-sites. Vennligst prøv igjen senere."));
            }
            else {
                patternInput_2[1](res.fields[0]);
            }
        });
    });
    const drawSite = (site_1) => MuiHelpers_createElement(Paper, [["elevation", 2], ["style", {
        maxWidth: 450,
        width: 100 + "%",
        padding: 10,
        margin: 5,
        display: "flex",
        justifyContent: "space-between",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            style: {
                marginBottom: 5,
                fontSize: 1.1 + "rem",
            },
            children: site_1.Name,
        }), createElement("a", {
            target: "_blank",
            href: site_1.WebUrl,
            children: Interop_reactApi.Children.toArray([createElement("span", {
                className: "fas fa-external-link-alt",
                style: {
                    marginRight: 5,
                },
            }), "Åpne SharePoint-site"]),
        })]),
    }), createElement("div", {
        style: {
            display: "flex",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!processing) ? singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", processing], ["children", "Velg"], ["onClick", (_arg2_1) => {
            const site = site_1;
            setProcessing(true);
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const req = new ProcessModule_CompanyOnedriveStatus(x.Id, x.Name, {
                    Name: site.Name,
                    SiteId: site.Id,
                });
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                        ResolveType: ProcessModule_CompanyOnedriveStatus$reflection,
                    })(req);
                    const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: Helpers_ResultMessage$reflection,
                    });
                    return Fetch_tryPost_5760677E("/api/company/onedrive-configure", some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                        ResolveType: Helpers_ResultMessage$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg2) => {
                    const res_2 = _arg2;
                    if (res_2.tag === 1) {
                        setProcessing(false);
                        dispatch(new Message(4, "Kunne ikke konfigurere selskap. Vennligst prøv igjen senere."));
                        return Promise.resolve();
                    }
                    else {
                        const r = res_2.fields[0];
                        if (r.Result === "success") {
                            setProcessing(false);
                            dispatch(new Message(3, req));
                            return Promise.resolve();
                        }
                        else {
                            setProcessing(false);
                            dispatch(new Message(4, r.Message));
                            return Promise.resolve();
                        }
                    }
                }));
            }));
            pr_1.then();
        }]])) : singleton(loadingIndicatorSmall())))))),
    })])]]);
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("div", {
            style: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", toText(printf("Konfigurer SharePoint/OneDrive for %s"))(x.Name)]]), MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg4) => {
                dispatch(new Message(2, void 0));
            }], ["children", react.createElement(Close, {})]])]),
        })), delay(() => append_1(singleton(createElement("span", {
            children: ["Hvilken SharePoint-site skal brukes for dette selskapet?"],
        })), delay(() => append_1(singleton(createElement("br", {})), delay(() => append_1(singleton(createElement("span", {
            style: {
                marginBottom: 20,
            },
            children: "Det opprettes en mappe kalt \"Prosjekter\" under siten om det ikke allerede ekisterer. Prosjekter for selskapet legges der.",
        })), delay(() => append_1(singleton(MuiHelpers_createElement(Tabs, [["value", equals(currentView, new ConfigureView(0)) ? 0 : 1], ["textColor", "primary"], ["onChange", (_arg38, v) => {
            if (v === 0) {
                setView(new ConfigureView(0));
            }
            else {
                setView(new ConfigureView(1));
            }
        }], ["indicatorColor", "primary"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tab, [["label", "Søk"]]), MuiHelpers_createElement(Tab, [["label", "Liste"]])])]])), delay(() => ((currentView.tag === 0) ? singleton(createElement("div", {
            style: {
                marginTop: 15,
                display: "flex",
                flexDirection: "column",
                width: 100 + "%",
                maxWidth: 470,
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["size", "small"], ["label", "Søk i SharePoint-sites"], ["variant", "outlined"], ["autoFocus", true], ["onChange", (e) => {
                const x_2 = Browser_Types_Event__Event_get_Value(e);
                window.clearTimeout(patternInput_4[0]);
                patternInput_4[1](window.setTimeout((_arg3) => {
                    if (x_2.length > 0) {
                        setProcessing(true);
                        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            let item;
                            return ((item = {
                                Term: x_2,
                            }, PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                    ResolveType: () => anonRecord_type(["Term", string_type]),
                                })(item);
                                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                    ResolveType: () => array_type(ProcessModule_SharePointSite$reflection()),
                                });
                                return Fetch_tryPost_5760677E("/api/company/search-sites", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                    ResolveType: () => array_type(ProcessModule_SharePointSite$reflection()),
                                }, {
                                    ResolveType: () => obj_type,
                                });
                            })))).then(((_arg1_1) => {
                                const res_1 = _arg1_1;
                                if (res_1.tag === 1) {
                                    dispatch(new Message(4, "Kunne ikke søke i SharePoint-sites. Vennligst prøv igjen senere."));
                                    setProcessing(false);
                                    return Promise.resolve();
                                }
                                else {
                                    setSearchResult(res_1.fields[0]);
                                    setProcessing(false);
                                    return Promise.resolve();
                                }
                            }));
                        }));
                        pr.then();
                    }
                    else {
                        setSearchResult([]);
                    }
                }, 500));
            }]]), createElement("div", {
                style: {
                    height: 30,
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (processing ? singleton(loadingIndicatorSmall()) : empty_1()))))),
            }), map(drawSite, patternInput_1[0])]),
        })) : singleton((siteList != null) ? toArray(singleton_1(map(drawSite, siteList))) : loadingIndicatorSmall())))))))))))))))),
    });
}

function CompanyEntry(companyEntryInputProps) {
    const dispatch = companyEntryInputProps.dispatch;
    const y = companyEntryInputProps.y;
    const x = companyEntryInputProps.x;
    return MuiHelpers_createElement(Paper, [["elevation", 2], ["style", {
        margin: 5,
        padding: 10,
        maxWidth: 450,
        width: 100 + "%",
        display: "flex",
        flexDirection: "column",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", x.Name]])), delay(() => {
        if (y != null) {
            const matchValue = y.Site;
            if (matchValue != null) {
                const s = matchValue;
                return singleton(createElement("span", {
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        className: "fas fa-check-circle",
                        style: {
                            color: "#008000",
                            marginRight: 5,
                        },
                    }), toText(printf("Prosjekter opprettes i site \"%s\""))(s.Name)]),
                }));
            }
            else {
                return singleton(createElement("span", {
                    children: "Selskap konfigurert, men ingne SharePoint site gitt",
                }));
            }
        }
        else {
            return append_1(singleton(createElement("span", {
                style: {
                    color: "#FFA500",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    className: "fas fa-exclamation-triangle",
                    style: {
                        marginRight: 5,
                    },
                }), "Selskap ikke konfigurert mot SharePoint/OneDrive"]),
            })), delay(() => singleton(createElement("div", {
                style: {
                    marginTop: 5,
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Konfigurer"], ["onClick", (_arg1) => {
                    dispatch(new Message(2, x));
                }]])]),
            }))));
        }
    }))))))]]);
}

export function CompanyOnedrive() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        Company_fetchCompanies((arg) => {
            dispatch(new Message(0, arg));
        });
        fetchStatuses(dispatch);
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model_1.ErrorMsg, () => {
            dispatch(new Message(5));
        })), delay(() => {
            const matchValue = model_1.Configure;
            if (matchValue == null) {
                const matchValue_1 = [model_1.Companies, model_1.CompanyStatus];
                let pattern_matching_result, companies, statuses;
                if (matchValue_1[0] != null) {
                    if (matchValue_1[1] != null) {
                        pattern_matching_result = 0;
                        companies = matchValue_1[0];
                        statuses = matchValue_1[1];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return singleton(map((c_2) => {
                            const status = tryFind((s) => (s.Id === c_2.Id), statuses);
                            return createElement(CompanyEntry, {
                                x: c_2,
                                y: status,
                                dispatch: dispatch,
                            });
                        }, sortBy((c_1) => c_1.Name, companies, {
                            Compare: (x, y) => comparePrimitives(x, y),
                        })));
                    }
                    case 1: {
                        return singleton(loadingIndicatorSmall());
                    }
                }
            }
            else {
                const c = matchValue;
                return singleton(createElement(CompanyConfigure, {
                    x: c,
                    dispatch: dispatch,
                }));
            }
        })))))),
    });
}

