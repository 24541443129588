import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { lambda_type, unit_type, tuple_type, obj_type, union_type, record_type, string_type, bool_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Quality_ProjectAnswer$reflection, Quality_ProjectAnswer, Quality_ProjectQuestion$reflection, Quality_ProjectQualityForm, Quality_ProjectQuestion, Quality_CommitInfo, Helpers_ResultMessage$reflection, Quality_CommitInfo$reflection, Quality_ProjectQualityForm$reflection, Quality_ProjectParticipant$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryDelete_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { Functionality, Functionality__ToInt } from "../Shared/PermissionMapping.js";
import { value as value_78, some } from "../.fable/fable-library.3.2.4/Option.js";
import { map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { item as item_1, mapIndexed, length, map as map_1, toArray, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, int32ToString, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Delete from "@material-ui/icons/Delete";
import { ParticipantFormProps, participantForm } from "./QualityParticipant.js";
import { snackbarError, loadingIndicatorMedium } from "../ViewHelpers.js";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Radio from "@material-ui/core/Radio";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import { Colors_QualityColors } from "../Design.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import { Parent, RegistrationComment } from "../Project/RegistrationComment.js";
import { month, year, now } from "../.fable/fable-library.3.2.4/Date.js";
import Button from "@material-ui/core/Button";
import Save from "@material-ui/icons/Save";

class Model extends Record {
    constructor(ProjectId, Participants, QualityForms, Commit, CanEdit, ErrorMsg) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Participants = Participants;
        this.QualityForms = QualityForms;
        this.Commit = Commit;
        this.CanEdit = CanEdit;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("QualityRegistration.Model", [], Model, () => [["ProjectId", int32_type], ["Participants", option_type(array_type(Quality_ProjectParticipant$reflection()))], ["QualityForms", option_type(array_type(Quality_ProjectQualityForm$reflection()))], ["Commit", option_type(option_type(Quality_CommitInfo$reflection()))], ["CanEdit", option_type(bool_type)], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FormResponse", "ParticipantsResponse", "PermissionsResponse", "CommitsResponse", "AddParticipant", "RemoveResponse", "CommitResponse", "SetError", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("QualityRegistration.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Quality_ProjectQualityForm$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Quality_ProjectQualityForm$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Quality_ProjectParticipant$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Quality_ProjectParticipant$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(int32_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(int32_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Quality_CommitInfo$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Quality_CommitInfo$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", Quality_ProjectParticipant$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Quality_ProjectParticipant$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Quality_ProjectParticipant$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], []]);
}

function init(projId) {
    return new Model(projId, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    let x_3, matchValue, matchValue_1, matchValue_2;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.ProjectId, model.Participants, model.QualityForms, model.Commit, model.CanEdit, "Kunne ikke laste deltakere.");
            }
            else {
                return new Model(model.ProjectId, res_1.fields[0], model.QualityForms, model.Commit, model.CanEdit, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.ProjectId, model.Participants, model.QualityForms, model.Commit, res_2.fields[0].some((x_3 = (Functionality__ToInt(new Functionality(13)) | 0), (y) => (x_3 === y))), model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                const e = res_3.fields[0];
                let pattern_matching_result;
                if (e.tag === 2) {
                    if ((e.fields[0].status) === 404) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return new Model(model.ProjectId, model.Participants, model.QualityForms, some(void 0), model.CanEdit, model.ErrorMsg);
                    }
                    case 1: {
                        return model;
                    }
                }
            }
            else {
                return new Model(model.ProjectId, model.Participants, model.QualityForms, some(res_3.fields[0]), model.CanEdit, model.ErrorMsg);
            }
        }
        case 4: {
            const x_5 = msg.fields[0];
            return new Model(model.ProjectId, (matchValue = model.Participants, (matchValue != null) ? append([x_5], matchValue) : [x_5]), model.QualityForms, model.Commit, model.CanEdit, model.ErrorMsg);
        }
        case 5: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.ProjectId, model.Participants, model.QualityForms, model.Commit, model.CanEdit, "Kunne ikke fjerne deltaker.");
            }
            else {
                return new Model(model.ProjectId, (matchValue_1 = model.Participants, (matchValue_1 != null) ? matchValue_1.filter((z) => (z.Id !== res_4.fields[0].Id)) : (void 0)), model.QualityForms, model.Commit, model.CanEdit, model.ErrorMsg);
            }
        }
        case 6: {
            const res_5 = msg.fields[0];
            if (res_5.tag === 1) {
                return new Model(model.ProjectId, model.Participants, model.QualityForms, model.Commit, model.CanEdit, "Noe gikk galt. Kunne ikke registrere prosjektkvalitet.");
            }
            else {
                const x_7 = res_5.fields[0];
                if (x_7.Result === "success") {
                    const y_5 = new Quality_CommitInfo("nå", loggedInUserId, loggedInUsername, "meg");
                    return new Model(model.ProjectId, model.Participants, (matchValue_2 = model.QualityForms, (matchValue_2 != null) ? map((f) => (new Quality_ProjectQualityForm(f.FormId, f.Name, f.Desc, f.LowerLimit, f.UpperLimit, f.AllowUnsure, map((q) => (new Quality_ProjectQuestion(q.Id, q.Question, q.HelperText, q.Answer, q.Unsure, q.RowPos, true)), f.Questions))), matchValue_2) : (void 0)), some(y_5), model.CanEdit, model.ErrorMsg);
                }
                else {
                    return new Model(model.ProjectId, model.Participants, model.QualityForms, model.Commit, model.CanEdit, x_7.Message);
                }
            }
        }
        case 7: {
            return new Model(model.ProjectId, model.Participants, model.QualityForms, model.Commit, model.CanEdit, msg.fields[0]);
        }
        case 8: {
            return new Model(model.ProjectId, model.Participants, model.QualityForms, model.Commit, model.CanEdit, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProjectId, model.Participants, model.QualityForms, model.Commit, model.CanEdit, "Noe gikk galt. Kunne ikke laste kvalitetsskjema");
            }
            else {
                return new Model(model.ProjectId, model.Participants, res.fields[0], model.Commit, model.CanEdit, model.ErrorMsg);
            }
        }
    }
}

function fetchForms(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/quality/project/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Quality_ProjectQualityForm$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Quality_ProjectQualityForm$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchParticipants(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/quality/project/participants/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Quality_ProjectParticipant$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Quality_ProjectParticipant$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchPermissions(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/permission/project/user/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(int32_type),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(int32_type),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function removeParticipant(projId, partId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: () => tuple_type(int32_type, int32_type),
        })([projId, partId]);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Quality_ProjectParticipant$reflection,
        });
        return Fetch_tryDelete_5760677E("/api/quality/project/participant", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Quality_ProjectParticipant$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(5, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchCommits(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/quality/project/commit/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Quality_CommitInfo$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Quality_CommitInfo$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function commit(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/quality/project/commit/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: () => string_type,
            })("");
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(6, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function DrawParticipants(drawParticipantsInputProps) {
    const dispatch = drawParticipantsInputProps.dispatch;
    const model = drawParticipantsInputProps.model;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const toggleForm = patternInput[1];
    const showForm = patternInput[0];
    const anchor = useReact_useRef_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setMenuOpen = patternInput_1[1];
    const menuOpen = patternInput_1[0];
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: 200,
            justifyContent: "center",
            marginBottom: 20,
            maxWidth: 400,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = model.Participants;
            if (matchValue != null) {
                const x = matchValue;
                return append_1(singleton(createElement("div", {
                    style: {
                        display: "flex",
                        alignItems: "center",
                        padding: ((((((6 + "px ") + 24) + "px ") + 6) + "px ") + 16) + "px",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("span", {
                        style: {
                            fontSize: 1.5 + "rem",
                            marginRight: 10,
                        },
                        children: "Deltakere",
                    })), delay(() => {
                        let children_1;
                        const matchValue_1 = model.Commit;
                        let pattern_matching_result;
                        if (matchValue_1 != null) {
                            if (value_78(matchValue_1) != null) {
                                pattern_matching_result = 0;
                            }
                            else {
                                pattern_matching_result = 1;
                            }
                        }
                        else {
                            pattern_matching_result = 0;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                return empty_1();
                            }
                            case 1: {
                                const matchValue_2 = model.CanEdit;
                                let pattern_matching_result_1;
                                if (matchValue_2 != null) {
                                    if (matchValue_2) {
                                        pattern_matching_result_1 = 0;
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                                switch (pattern_matching_result_1) {
                                    case 0: {
                                        return singleton((children_1 = ofArray([MuiHelpers_createElement(IconButton, [["size", "small"], ["aria-label", "Legg til"], ["ref", anchor], ["onClick", (_arg1) => {
                                            setMenuOpen(!menuOpen);
                                        }], ["children", react.createElement(Add, {})]]), MuiHelpers_createElement(Menu, [["open", menuOpen], ["onClose", (_arg16, v) => {
                                            setMenuOpen(false);
                                        }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["children", "Legg til deltaker fra systemet"], ["onClick", (_arg3) => {
                                            setMenuOpen(false);
                                            toggleForm(false);
                                        }]]), MuiHelpers_createElement(MenuItem, [["children", "Legg til ekstern deltaker"], ["onClick", (_arg4) => {
                                            setMenuOpen(false);
                                            toggleForm(true);
                                        }]])])]])]), createElement("div", {
                                            children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                                        })));
                                    }
                                    case 1: {
                                        return empty_1();
                                    }
                                }
                            }
                        }
                    })))))),
                })), delay(() => append_1((x.length === 0) ? singleton(createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                        marginLeft: 17,
                    },
                    children: "Ingen deltagere lagt til",
                })) : empty_1(), delay(() => append_1(singleton(MuiHelpers_createElement(List, [["dense", true], ["children", Interop_reactApi.Children.toArray([map((y) => MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let matchValue_3, uId;
                    return append_1(singleton(MuiHelpers_createElement(ListItemAvatar, [["children", (matchValue_3 = y.UserId, (matchValue_3 != null) ? ((uId = (matchValue_3 | 0), MuiHelpers_createElement(Avatar, [["alt", "Profilbilde"], ["src", toText(printf("/api/user/profilepicture/thumbnail/%i"))(uId)], ["width", 30], ["height", 30]]))) : MuiHelpers_createElement(Avatar, [["width", 30], ["height", 30], ["children", Interop_reactApi.Children.toArray([react.createElement(Person, {})])]]))]])), delay(() => append_1(singleton(MuiHelpers_createElement(ListItemText, [["primary", toText(printf("%s (%s)"))(y.Name)(y.Role)], ["secondary", y.Company]])), delay(() => {
                        const matchValue_4 = model.CanEdit;
                        let pattern_matching_result_2;
                        if (matchValue_4 != null) {
                            if (matchValue_4) {
                                pattern_matching_result_2 = 0;
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                        switch (pattern_matching_result_2) {
                            case 0: {
                                return singleton(MuiHelpers_createElement(ListItemSecondaryAction, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Delete, {})], ["onClick", (_arg5) => {
                                    removeParticipant(model.ProjectId, y.Id, dispatch);
                                }]])])]]));
                            }
                            case 1: {
                                return empty_1();
                            }
                        }
                    }))));
                }))))]]), x)])]])), delay(() => {
                    if (showForm != null) {
                        return singleton(participantForm(new ParticipantFormProps(model.ProjectId, showForm, (p) => {
                            if (p != null) {
                                dispatch(new Message(4, p));
                            }
                            toggleForm(void 0);
                        })));
                    }
                    else {
                        return empty_1();
                    }
                }))))));
            }
            else {
                return singleton(loadingIndicatorMedium());
            }
        })))),
    });
}

class QuestionProps extends Record {
    constructor(dispatch, allowUnsure, projId, lowerLimit, upperLimit, canEdit, committed, question) {
        super();
        this.dispatch = dispatch;
        this.allowUnsure = allowUnsure;
        this.projId = (projId | 0);
        this.lowerLimit = (lowerLimit | 0);
        this.upperLimit = (upperLimit | 0);
        this.canEdit = canEdit;
        this.committed = committed;
        this.question = question;
    }
}

function QuestionProps$reflection() {
    return record_type("QualityRegistration.QuestionProps", [], QuestionProps, () => [["dispatch", lambda_type(Message$reflection(), unit_type)], ["allowUnsure", bool_type], ["projId", int32_type], ["lowerLimit", int32_type], ["upperLimit", int32_type], ["canEdit", bool_type], ["committed", bool_type], ["question", Quality_ProjectQuestion$reflection()]]);
}

function Question(props) {
    const patternInput = useFeliz_React__React_useState_Static_1505(props.question);
    const setQuestion = patternInput[1];
    const question = patternInput[0];
    const answer = (x, u) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const req = new Quality_ProjectAnswer(props.projId, question.Id, x, u);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Quality_ProjectAnswer$reflection,
                })(req);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E("/api/quality/project/question", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 1) {
                    props.dispatch(new Message(7, "Noe gikk galt. Kunne ikke registrere svar."));
                    return Promise.resolve();
                }
                else {
                    const x_1 = res.fields[0];
                    if (x_1.Result !== "success") {
                        props.dispatch(new Message(7, x_1.Message));
                        return Promise.resolve();
                    }
                    else {
                        return Promise.resolve();
                    }
                }
            }));
        }));
        pr.then();
    };
    return MuiHelpers_createElement(TableRow, [["style", {}], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(TableCell, [["style", {}], ["children", question.Question]])), delay(() => append_1(singleton(toArray(map_1((z) => {
        let isChecked;
        const matchValue = question.Answer;
        let pattern_matching_result;
        if (matchValue != null) {
            if (matchValue === z) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                isChecked = true;
                break;
            }
            case 1: {
                isChecked = false;
                break;
            }
        }
        const disabled = ((!props.canEdit) ? true : question.Committed) ? true : props.committed;
        return MuiHelpers_createElement(TableCell, [["align", "center"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Radio, toList(delay(() => append_1(singleton(["checked", isChecked]), delay(() => append_1(singleton(["value", int32ToString(z)]), delay(() => append_1((!disabled) ? singleton(["style", createObj(toList(delay(() => (isChecked ? singleton(["color", "#2d3a48"]) : empty_1()))))]) : empty_1(), delay(() => append_1(singleton(["disabled", disabled]), delay(() => singleton(["onChange", (_arg1_1) => {
            setQuestion(new Quality_ProjectQuestion(question.Id, question.Question, question.HelperText, z, false, question.RowPos, question.Committed));
            answer(z, false);
        }]))))))))))))])]]);
    }, toList(rangeDouble(props.lowerLimit, 1, props.upperLimit))))), delay(() => {
        if (props.allowUnsure) {
            const disabled_1 = (!props.canEdit) ? true : question.Committed;
            return singleton(MuiHelpers_createElement(TableCell, [["align", "center"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Radio, toList(delay(() => append_1(singleton(["checked", question.Unsure]), delay(() => append_1(singleton(["disabled", disabled_1]), delay(() => append_1((!disabled_1) ? singleton(["style", createObj(toList(delay(() => (question.Unsure ? singleton(["color", "#2d3a48"]) : empty_1()))))]) : empty_1(), delay(() => singleton(["onChange", (_arg2) => {
                let matchValue_1;
                setQuestion(new Quality_ProjectQuestion(question.Id, question.Question, question.HelperText, void 0, true, question.RowPos, question.Committed));
                answer((matchValue_1 = question.Answer, (matchValue_1 == null) ? 0 : matchValue_1), true);
            }]))))))))))])]]));
        }
        else {
            return empty_1();
        }
    }))))))))]]);
}

function drawForm(model, dispatch, x, canEdit) {
    const limitDiff = (x.UpperLimit - x.LowerLimit) | 0;
    const r = ((limitDiff > 0) ? (~(~(length(Colors_QualityColors) / limitDiff))) : 0) | 0;
    return createElement("div", {
        style: {
            marginBottom: 30,
            maxWidth: 1400,
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                padding: "6px 24px 6px 16px",
                width: 30 + "%",
                display: "inline-block",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 1.5 + "rem",
                },
                children: x.Name,
            })]),
        }), createElement("span", {
            style: {
                fontSize: 1 + "rem",
            },
            children: "I hvilken grad vurderes",
        }), MuiHelpers_createElement(TableContainer, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(TableCell, [["style", {
            width: 30 + "%",
        }]])), delay(() => append_1(singleton(toArray(mapIndexed((i, y) => {
            let _arg1;
            return MuiHelpers_createElement(TableCell, [["style", {
                backgroundColor: item_1((_arg1 = ((i * r) | 0), (_arg1 < 0) ? 0 : ((_arg1 >= length(Colors_QualityColors)) ? (length(Colors_QualityColors) - 1) : _arg1)), Colors_QualityColors),
                textAlign: "center",
            }], ["children", y]]);
        }, toList(rangeDouble(x.LowerLimit, 1, x.UpperLimit))))), delay(() => (x.AllowUnsure ? singleton(MuiHelpers_createElement(TableCell, [["children", "Vet ikke"]])) : empty_1())))))))))]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((y_1) => {
            let matchValue;
            return createElement(Question, new QuestionProps(dispatch, x.AllowUnsure, model.ProjectId, x.LowerLimit, x.UpperLimit, canEdit, (matchValue = model.Commit, (matchValue != null) ? (value_78(matchValue) != null) : false), y_1));
        }, x.Questions)])]])])]])])]])]),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(8));
        })), delay(() => {
            const matchValue = [model.QualityForms, model.CanEdit];
            let pattern_matching_result, canEdit, forms;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    pattern_matching_result = 0;
                    canEdit = matchValue[1];
                    forms = matchValue[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return (forms.length === 0) ? append_1(singleton(MuiHelpers_createElement(Typography, [["variant", "h4"], ["style", {
                        marginBottom: 10,
                    }], ["children", "Ingen kvalitetsskjema registrert enda"]])), delay(() => singleton(createElement("span", {
                        children: ["Konfigurer kvalitetsskjema og spørsmål for å kunne registrere kvalitet for dette prosjektet"],
                    })))) : append_1(singleton(createElement("div", {
                        style: {
                            width: 100 + "%",
                            maxWidth: 1400,
                        },
                        children: Interop_reactApi.Children.toArray([createElement(DrawParticipants, {
                            model: model,
                            dispatch: dispatch,
                        })]),
                    })), delay(() => append_1(singleton(react.createElement(react.Fragment, {}, ...map((x) => drawForm(model, dispatch, x, canEdit), forms))), delay(() => {
                        let matchValue_1, canEdit_1, copyOfStruct, copyOfStruct_1;
                        return append_1((matchValue_1 = model.CanEdit, (matchValue_1 != null) ? ((canEdit_1 = matchValue_1, singleton(createElement("div", {
                            style: {
                                width: 100 + "%",
                                maxWidth: 1000,
                            },
                            children: Interop_reactApi.Children.toArray([createElement(RegistrationComment, {
                                src: new Parent(2, model.ProjectId),
                                year: (copyOfStruct = now(), year(copyOfStruct)),
                                month: (copyOfStruct_1 = now(), month(copyOfStruct_1)),
                                canEdit: canEdit_1,
                            })]),
                        })))) : ((empty_1()))), delay(() => singleton(createElement("div", {
                            style: {
                                width: 100 + "%",
                                maxWidth: 1400,
                                marginTop: 20,
                            },
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let x_1;
                                const matchValue_2 = model.Commit;
                                if (matchValue_2 != null) {
                                    return (value_78(matchValue_2) != null) ? ((x_1 = value_78(matchValue_2), singleton(createElement("div", {
                                        style: {
                                            display: "flex",
                                            alignItems: "center",
                                        },
                                        children: Interop_reactApi.Children.toArray([createElement("span", {
                                            style: {
                                                marginRight: 10,
                                            },
                                            children: toText(printf("Registrert %s av"))(x_1.Committed),
                                        }), createElement("div", {
                                            style: {
                                                display: "flex",
                                                alignItems: "center",
                                            },
                                            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Avatar, [["alt", "Profilbilde"], ["src", toText(printf("/api/user/profilepicture/thumbnail/%i"))(x_1.UserId)], ["width", 30], ["height", 30]]), createElement("span", {
                                                style: {
                                                    marginLeft: 7,
                                                },
                                                children: x_1.Fullname,
                                            })]),
                                        })]),
                                    })))) : singleton(MuiHelpers_createElement(Button, [["variant", "contained"], ["style", {
                                        backgroundColor: "#2d3a48",
                                        color: "#FFFFFF",
                                    }], ["startIcon", react.createElement(Save, {})], ["children", "Registrer"], ["onClick", (_arg2) => {
                                        commit(model.ProjectId, dispatch);
                                    }]]));
                                }
                                else {
                                    return empty_1();
                                }
                            })))),
                        }))));
                    }))));
                }
                case 1: {
                    return singleton(loadingIndicatorMedium());
                }
            }
        })))))),
    });
}

export function QualityRegistration(qualityRegistrationInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(qualityRegistrationInputProps.projectId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchParticipants(model_1.ProjectId, dispatch);
        fetchForms(model_1.ProjectId, dispatch);
        fetchPermissions(model_1.ProjectId, dispatch);
        fetchCommits(model_1.ProjectId, dispatch);
    });
    return view(model_1, dispatch);
}

