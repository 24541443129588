import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Helpers_IdName$reflection, Helpers_IdName, Company$reflection, Project$reflection, Office$reflection, Department$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, string_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { Project_GetProjectsByCompany, Office_ByCompany, Department_ByCompany } from "../Shared/Urls.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { toArray, ofArray, singleton, empty } from "../.fable/fable-library.3.2.4/List.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { join, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { defaultArg, some } from "../.fable/fable-library.3.2.4/Option.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { take, map as map_1, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import Save from "@material-ui/icons/Save";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { map } from "../.fable/fable-library.3.2.4/Array.js";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { ProjectStatus_FromInt_Z524259A4 } from "../Shared/Shared.js";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { Dimensions_DefaultPadding } from "../Design.js";
import { snackbarError } from "../ViewHelpers.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";

class Model extends Record {
    constructor(Departments, Offices, Projects, Company, ErrorMsg, OrgNrForm) {
        super();
        this.Departments = Departments;
        this.Offices = Offices;
        this.Projects = Projects;
        this.Company = Company;
        this.ErrorMsg = ErrorMsg;
        this.OrgNrForm = OrgNrForm;
    }
}

function Model$reflection() {
    return record_type("Company.Model", [], Model, () => [["Departments", option_type(array_type(Department$reflection()))], ["Offices", option_type(array_type(Office$reflection()))], ["Projects", option_type(array_type(Project$reflection()))], ["Company", Company$reflection()], ["ErrorMsg", option_type(string_type)], ["OrgNrForm", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DepartmentsResponse", "OfficesResponse", "ProjectsResponse", "OrgNrResponse", "DismissError", "UpdateOrgNr"];
    }
}

function Message$reflection() {
    return union_type("Company.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Department$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Department$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Office$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Office$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Project$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Project$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Company$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Company$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", string_type]]]);
}

function init(company) {
    return new Model(void 0, void 0, void 0, company, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Departments, res_1.fields[0], model.Projects, model.Company, model.ErrorMsg, model.OrgNrForm);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Departments, model.Offices, model.Projects, model.Company, "Noe gikk galt, kunne ikke hente selskaper", model.OrgNrForm);
            }
            else {
                return new Model(model.Departments, model.Offices, res_2.fields[0], model.Company, model.ErrorMsg, model.OrgNrForm);
            }
        }
        case 5: {
            return new Model(model.Departments, model.Offices, model.Projects, model.Company, model.ErrorMsg, msg.fields[0]);
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Departments, model.Offices, model.Projects, model.Company, "Noe gikk galt. \n                Kunne ikke oppdatere organisasjonsnummer", model.OrgNrForm);
            }
            else {
                return new Model(model.Departments, model.Offices, model.Projects, res_3.fields[0], model.ErrorMsg, void 0);
            }
        }
        case 4: {
            return new Model(model.Departments, model.Offices, model.Projects, model.Company, void 0, model.OrgNrForm);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return model;
            }
            else {
                return new Model(res.fields[0], model.Offices, model.Projects, model.Company, model.ErrorMsg, model.OrgNrForm);
            }
        }
    }
}

function getDepartments(companyId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const path = Department_ByCompany(companyId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Department$reflection()),
            });
            return Fetch_tryGet_5760677E(path, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Department$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function getOffices(companyId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const path = Office_ByCompany(companyId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Office$reflection()),
            });
            return Fetch_tryGet_5760677E(path, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Office$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function getProjects(companyId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const path = Project_GetProjectsByCompany(companyId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Project$reflection()),
            });
            return Fetch_tryGet_5760677E(path, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Project$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function updateOrgNr(companyId, orgNr, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(5, orgNr));
        const requestPath = toText(printf("/api/company/orgnr/update/%i"))(companyId);
        const updateForm = new Helpers_IdName(companyId, orgNr);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: Helpers_IdName$reflection,
            })(updateForm);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Company$reflection,
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Company$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
}

const drawCompanyStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    const tablehead = StyleCreator$1__create_4EAC9E1E(styles, singleton(["backgroundColor", "#778899"]));
    const headcell = StyleCreator$1__create_4EAC9E1E(styles, singleton(["color", "#ffffff"]));
    const root = StyleCreator$1__create_4EAC9E1E(styles, singleton(["flexGrow", 1]));
    const listroot = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["display", "flex"], ["flexWrap", "nowrap"], ["justifyContent", "space-around"], ["overflow", "hidden"], ["backgroundColor", arg.palette.background.paper], ["width", 100 + "%"], ["height", 300], ["padding", 10], ["overflowX", "auto"], ["overflowY", "hidden"]]));
    const gridlist = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["flexWrap", "nowrap"], ["transform", ("translateZ(" + 0) + "px)"]]));
    o = {
        cardImage: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["height", 160], ["maxWidth", 100 + "%"]])),
        cardRoot: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 170], ["height", 285]])),
        gridlist: gridlist,
        headcell: headcell,
        listroot: listroot,
        root: root,
        tablehead: tablehead,
    };
    return Object.assign({}, o);
});

function drawCompanyOverview(model, dispatch) {
    const s = drawCompanyStyles();
    const departments = defaultArg(model.Departments, []);
    const offices = defaultArg(model.Offices, []);
    const drawOrgNum = toArray(toList(delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["label", "Rediger Org.nummer"], ["variant", "outlined"], ["size", "small"], ["defaultValue", model.Company.OrgNr], ["onChange", (e) => {
        dispatch(new Message(5, Browser_Types_Event__Event_get_Value(e)));
    }]])), delay(() => {
        const matchValue = model.OrgNrForm;
        if (matchValue != null) {
            const orgNumForm = matchValue;
            return singleton_1(MuiHelpers_createElement(Button, [["style", {
                marginLeft: 1 + "rem",
            }], ["color", "primary"], ["variant", "contained"], ["size", "small"], ["startIcon", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Save, {})])]])], ["children", "Lagre"], ["onClick", (_arg1) => {
                const pr = updateOrgNr(model.Company.Id, orgNumForm, dispatch);
                pr.then();
            }]]));
        }
        else {
            return empty_1();
        }
    })))));
    const drawDepartments = MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["className", s.tablehead], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["className", s.headcell], ["children", "Avdelinger"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(map((x) => MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", x.Name]])])]]), departments)))]])])]]);
    const drawOffices = MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["className", s.tablehead], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["className", s.headcell], ["children", "Kontorer"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(map((x_1) => MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", x_1.Name]])])]]), offices)))]])])]]);
    let drawProjects;
    const noProjects = MuiHelpers_createElement(Typography, [["variant", "body2"], ["children", "Fant ingen aktive tilhørende prosjekter"]]);
    const matchValue_1 = model.Projects;
    if (matchValue_1 != null) {
        if (matchValue_1.length === 0) {
            const p_2 = matchValue_1;
            drawProjects = noProjects;
        }
        else if (matchValue_1 != null) {
            const projects = matchValue_1;
            drawProjects = createElement("div", {
                className: s.listroot,
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Grid, [["className", s.gridlist], ["container", true], ["spacing", 2], ["direction", "row"], ["justify", "flex-start"], ["alignItems", "flex-start"], ["children", Interop_reactApi.Children.toArray([map((p) => {
                    let n, s_1;
                    const projStatus = ProjectStatus_FromInt_Z524259A4(p.Status);
                    return MuiHelpers_createElement(Grid, [["item", true], ["xs", 12], ["sm", 6], ["md", 3], ["key", p.Id], ["children", Interop_reactApi.Children.toArray([createElement("a", {
                        href: toText(printf("/project/%i"))(p.Id),
                        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Card, [["className", s.cardRoot], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(CardMedia, [["image", toText(printf("/api/projectimage/thumbnail/%i"))(p.Id)], ["className", s.cardImage]]), MuiHelpers_createElement(CardContent, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", (n = 25, (s_1 = p.Name, ((s_1.length < n) ? true : (s_1.length <= 0)) ? s_1 : (join("", map_1((value_49) => value_49, take(n, s_1.split("")))) + "...")))]]), MuiHelpers_createElement(Typography, [["variant", "body2"], ["color", "textSecondary"], ["children", toText(printf("Prosjektinfo: %d%% "))(p.InfoScore)]]), MuiHelpers_createElement(Typography, [["variant", "body2"], ["color", "textSecondary"], ["children", toString(projStatus)]])])]])])]])]),
                    })])]]);
                }, projects)])]])]),
            });
        }
        else {
            throw (new Error("Match failure"));
        }
    }
    else {
        drawProjects = noProjects;
    }
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            padding: Dimensions_DefaultPadding,
        },
        children: Interop_reactApi.Children.toArray([snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(4));
        }), MuiHelpers_createElement(Grid, [["container", true], ["spacing", 2], ["style", {
            maxWidth: 1000,
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Grid, [["item", true], ["alignItems", "center"], ["xs", 12], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["children", "Aktive prosjekter"]]), drawProjects])]]), MuiHelpers_createElement(Grid, [["item", true], ["alignItems", "center"], ["xs", 12], ["children", Interop_reactApi.Children.toArray([drawOrgNum])]]), MuiHelpers_createElement(Grid, [["item", true], ["md", 6], ["xs", 12], ["children", Interop_reactApi.Children.toArray([drawDepartments])]]), MuiHelpers_createElement(Grid, [["item", true], ["md", 6], ["xs", 12], ["children", Interop_reactApi.Children.toArray([drawOffices])]])])]])]),
    });
}

export function Overview(company) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(company));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        getDepartments(company.Id, dispatch);
        getOffices(company.Id, dispatch);
        getProjects(company.Id, dispatch);
    });
    return drawCompanyOverview(patternInput[0], dispatch);
}

