import { length, item, ofArray } from "./.fable/fable-library.3.2.4/List.js";

export const Colors_QualityColors = ofArray(["#FF0000", "#FF1000", "#FF2000", "#FF3000", "#FF4000", "#FF5000", "#FF6000", "#FF7000", "#FF8000", "#FF9000", "#FFA000", "#FFB000", "#FFC000", "#FFD000", "#FFE000", "#FFF000", "#FFFF00", "#F0FF00", "#E0FF00", "#D0FF00", "#C0FF00", "#B0FF00", "#A0FF00", "#90FF00", "#80FF00", "#70FF00", "#60FF00", "#50FF00", "#40FF00", "#30FF00", "#20FF00", "#10FF00"]);

const Colors_colors = ofArray(["#962318", "#ffa500", "#59227a", "#2c6094", "#1a8a38", "#b33024", "#ffb01f", "#1dab43"]);

export function Colors_getColorWheel(idx) {
    return item(idx % length(Colors_colors), Colors_colors);
}

export const Dimensions_DefaultPadding = 1 + "vw";

