import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, bool_type, option_type, string_type, union_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { asMonth } from "../Utils.js";
import { RegComment, Helpers_ResultMessage$reflection, RegComment$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { loadingIndicatorSmall, loadingIndicatorCenteredSmall, snackbarError } from "../ViewHelpers.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { empty as empty_1, append, singleton, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import AccordionActions from "@material-ui/core/AccordionActions";

export class Parent extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Waste", "HMS", "Quality"];
    }
}

export function Parent$reflection() {
    return union_type("RegistrationComment.Parent", [], Parent, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export function Parent__GetPath(this$, year, month) {
    const x = (this$.tag === 1) ? toText(printf("/api/hms/project/comment?proj=%i"))(this$.fields[0]) : ((this$.tag === 2) ? toText(printf("/api/quality/comment?proj=%i"))(this$.fields[0]) : toText(printf("/api/waste/project/comment?proj=%i"))(this$.fields[0]));
    return toText(printf("%s\u0026year=%i\u0026month=%i"))(x)(year)(month);
}

export function Parent__CommitPath(this$) {
    switch (this$.tag) {
        case 1: {
            return toText(printf("/api/hms/project/comment/%i"))(this$.fields[0]);
        }
        case 2: {
            return toText(printf("/api/quality/comment/%i"))(this$.fields[0]);
        }
        default: {
            return toText(printf("/api/waste/project/comment/%i"))(this$.fields[0]);
        }
    }
}

export function Parent__ToTitle(this$, month, year) {
    switch (this$.tag) {
        case 1: {
            const arg10 = asMonth(month);
            return toText(printf("Kommentar for registreringsmåneden %s %i (valgfritt)"))(arg10)(year);
        }
        case 2: {
            return "Kommentar til registreringen";
        }
        default: {
            const arg10 = asMonth(month);
            return toText(printf("Kommentar for registreringsmåneden %s %i (valgfritt)"))(arg10)(year);
        }
    }
}

class Model extends Record {
    constructor(Source, Year, Month, ErrorMsg, Comment$, Processing, Altered, CanEdit) {
        super();
        this.Source = Source;
        this.Year = (Year | 0);
        this.Month = (Month | 0);
        this.ErrorMsg = ErrorMsg;
        this.Comment = Comment$;
        this.Processing = Processing;
        this.Altered = Altered;
        this.CanEdit = CanEdit;
    }
}

function Model$reflection() {
    return record_type("RegistrationComment.Model", [], Model, () => [["Source", Parent$reflection()], ["Year", int32_type], ["Month", int32_type], ["ErrorMsg", option_type(string_type)], ["Comment", option_type(RegComment$reflection())], ["Processing", bool_type], ["Altered", bool_type], ["CanEdit", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CommentResponse", "UpdateComment", "Commit", "CommitResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("RegistrationComment.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [RegComment$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", RegComment$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(x, y, m, c) {
    return new Model(x, y, m, void 0, void 0, false, false, c);
}

function update(model, msg) {
    let matchValue_1, c_1;
    switch (msg.tag) {
        case 1: {
            const matchValue = model.Comment;
            if (matchValue == null) {
                return model;
            }
            else {
                const c = matchValue;
                return new Model(model.Source, model.Year, model.Month, model.ErrorMsg, new RegComment(msg.fields[0], c.Registered, c.User, c.UserId), model.Processing, true, model.CanEdit);
            }
        }
        case 2: {
            return new Model(model.Source, model.Year, model.Month, void 0, model.Comment, true, model.Altered, model.CanEdit);
        }
        case 3: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Source, model.Year, model.Month, "Noe gikk galt. Kunne ikke oppdatere kommentar.", model.Comment, false, model.Altered, model.CanEdit);
            }
            else {
                const x_2 = res_1.fields[0];
                if (x_2.Result === "success") {
                    return new Model(model.Source, model.Year, model.Month, model.ErrorMsg, (matchValue_1 = model.Comment, (matchValue_1 == null) ? (void 0) : ((c_1 = matchValue_1, new RegComment(c_1.Comment, c_1.Registered, loggedInUsername, loggedInUserId)))), false, false, model.CanEdit);
                }
                else {
                    return new Model(model.Source, model.Year, model.Month, x_2.Message, model.Comment, false, model.Altered, model.CanEdit);
                }
            }
        }
        case 4: {
            return new Model(model.Source, model.Year, model.Month, void 0, model.Comment, model.Processing, model.Altered, model.CanEdit);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Source, model.Year, model.Month, "Noe gikk galt. Kunne ikke laste kommentar.", model.Comment, model.Processing, model.Altered, model.CanEdit);
            }
            else {
                return new Model(model.Source, model.Year, model.Month, model.ErrorMsg, res.fields[0], model.Processing, model.Altered, model.CanEdit);
            }
        }
    }
}

function fetchComment(x, year, month, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = Parent__GetPath(x, year, month);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: RegComment$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: RegComment$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function commitComment(x, y, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = Parent__CommitPath(x);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: RegComment$reflection,
            })(y);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function view(model, dispatch) {
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    return createElement("div", {
        style: {
            marginTop: 30,
            marginBottom: 30,
        },
        children: Interop_reactApi.Children.toArray([snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(4));
        }), MuiHelpers_createElement(Accordion, [["expanded", patternInput[0]], ["onChange", (_arg1_1, v) => {
            patternInput[1](v);
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(AccordionSummary, [["expandIcon", react.createElement(ExpandMore, {})], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["children", Parent__ToTitle(model.Source, model.Month, model.Year)]])])]]), MuiHelpers_createElement(AccordionDetails, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = model.Comment;
            if (matchValue != null) {
                const x = matchValue;
                return singleton(createElement("div", {
                    style: {
                        width: 100 + "%",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(TextField, [["fullWidth", true], ["variant", "outlined"], ["multiline", true], ["defaultValue", x.Comment], ["disabled", !model.CanEdit], ["onChange", (e) => {
                        const y = Browser_Types_Event__Event_get_Value(e);
                        window.clearTimeout(patternInput_1[0]);
                        patternInput_1[1](window.setTimeout((_arg2) => {
                            commitComment(model.Source, new RegComment(y, x.Registered, x.User, x.UserId), dispatch);
                            dispatch(new Message(1, y));
                        }, 1000));
                    }]])), delay(() => ((x.User.length > 0) ? singleton(createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                            fontStyle: "italic",
                        },
                        children: toText(printf("Sist oppdatert av %s"))(x.User),
                    })) : empty_1()))))))),
                }));
            }
            else {
                return singleton(loadingIndicatorCenteredSmall());
            }
        }))))]]), MuiHelpers_createElement(AccordionActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue_1 = model.Comment;
            if (matchValue_1 != null) {
                const x_1 = matchValue_1;
                return model.Processing ? singleton(loadingIndicatorSmall()) : empty_1();
            }
            else {
                return empty_1();
            }
        }))))]])])]])]),
    });
}

export function RegistrationComment(registrationCommentInputProps) {
    const initialModel = init(registrationCommentInputProps.src, registrationCommentInputProps.year, registrationCommentInputProps.month, registrationCommentInputProps.canEdit);
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), initialModel);
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchComment(initialModel.Source, initialModel.Year, initialModel.Month, dispatch);
    });
    return view(patternInput[0], dispatch);
}

