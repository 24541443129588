import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { union_type, record_type, option_type, bool_type, int32_type, obj_type, array_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Helpers_ResultMessage$reflection, Competence_UserCourse$reflection, Competence_UserCourse, Competence_Course, Competence_Course$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryDelete_5760677E, FetchError$reflection, Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import List from "@material-ui/core/List";
import { sortByDescending, append as append_1, map } from "../.fable/fable-library.3.2.4/Array.js";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Add from "@material-ui/icons/Add";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { fromUtcString, fromUtcToLocalDate } from "../Utils.js";
import { Competence_fetchUserCourses } from "../Promises.js";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { compare } from "../.fable/fable-library.3.2.4/Date.js";
import { loadingIndicatorSmall } from "../ViewHelpers.js";

export function UserCourseForm(userCourseFormInputProps) {
    const onClose = userCourseFormInputProps.onClose;
    const userId = userCourseFormInputProps.userId;
    const patternInput = useFeliz_React__React_useState_Static_1505([]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505({
        EndDate: void 0,
        SearchInput: "",
        SelectedCourse: void 0,
        StartDate: void 0,
    });
    const updateCourseForm = patternInput_2[1];
    const courseForm = patternInput_2[0];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til ferdighet"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
            minHeight: 200,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let selected;
            const matchValue_3 = courseForm.SelectedCourse;
            return (matchValue_3 == null) ? append(singleton(createElement("span", {
                style: {
                    display: "block",
                    fontSize: 0.8 + "rem",
                    marginBottom: 10,
                    maxWidth: 350,
                },
                children: "Søk og velg i eksisterende eller skriv inn og klikk \"Legg til\"",
            })), delay(() => append(singleton(MuiHelpers_createElement(TextField, [["fullWidth", true], ["size", "small"], ["variant", "outlined"], ["onChange", (e_2) => {
                const x_5 = Browser_Types_Event__Event_get_Value(e_2);
                updateCourseForm({
                    EndDate: courseForm.EndDate,
                    SearchInput: x_5,
                    SelectedCourse: courseForm.SelectedCourse,
                    StartDate: courseForm.StartDate,
                });
                window.clearTimeout(patternInput_1[0]);
                patternInput_1[1](window.setTimeout((_arg2_1) => {
                    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: () => string_type,
                        })(x_5);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: () => array_type(Competence_Course$reflection()),
                        });
                        return Fetch_tryPost_5760677E("/api/course/search", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: () => array_type(Competence_Course$reflection()),
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg1) => {
                        const res = _arg1;
                        if (res.tag === 0) {
                            patternInput[1](res.fields[0]);
                            return Promise.resolve();
                        }
                        else {
                            return Promise.resolve();
                        }
                    })))));
                    pr.then();
                }, 500));
            }], ["onKeyDown", (kc) => {
                if (kc.code === "Enter") {
                    updateCourseForm({
                        EndDate: courseForm.EndDate,
                        SearchInput: courseForm.SearchInput,
                        SelectedCourse: new Competence_Course(-1, courseForm.SearchInput),
                        StartDate: courseForm.StartDate,
                    });
                }
            }], ["label", "Kurs, f.eks ITIL4, PRINCE2 .."]])), delay(() => singleton(MuiHelpers_createElement(List, [["dense", true], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(map((s) => MuiHelpers_createElement(ListItem, [["dense", true], ["button", true], ["onClick", (_arg3) => {
                updateCourseForm({
                    EndDate: courseForm.EndDate,
                    SearchInput: courseForm.SearchInput,
                    SelectedCourse: s,
                    StartDate: courseForm.StartDate,
                });
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", s.Name]])])]]), patternInput[0])), delay(() => ((courseForm.SearchInput.length > 0) ? singleton(MuiHelpers_createElement(ListItem, [["dense", true], ["button", true], ["onClick", (_arg4) => {
                updateCourseForm({
                    EndDate: courseForm.EndDate,
                    SearchInput: courseForm.SearchInput,
                    SelectedCourse: new Competence_Course(-1, courseForm.SearchInput),
                    StartDate: courseForm.StartDate,
                });
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([react.createElement(Add, {})])]]), MuiHelpers_createElement(ListItemText, [["primary", toText(printf("Legg til %s"))(courseForm.SearchInput)]])])]])) : empty_1())))))))]])))))) : singleton((selected = matchValue_3, createElement("div", {
                style: {
                    display: "flex",
                    flexDirection: "column",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    children: toText(printf("Oppgi kursperiode for %s"))(selected.Name),
                }), createElement("div", {
                    style: {
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "column",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: "Startdato",
                    }), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["type", "date"]), delay(() => {
                        let matchValue_1;
                        return append((matchValue_1 = courseForm.StartDate, (matchValue_1 != null) ? singleton(["value", matchValue_1]) : ((empty_1()))), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["required", true]), delay(() => singleton(["onChange", (e) => {
                            updateCourseForm({
                                EndDate: courseForm.EndDate,
                                SearchInput: courseForm.SearchInput,
                                SelectedCourse: courseForm.SelectedCourse,
                                StartDate: Browser_Types_Event__Event_get_Value(e),
                            });
                        }])))))))))));
                    })))))]),
                }), createElement("div", {
                    style: {
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "column",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: "Sluttdato",
                    }), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["type", "date"]), delay(() => {
                        let matchValue_2;
                        return append((matchValue_2 = courseForm.EndDate, (matchValue_2 != null) ? singleton(["value", matchValue_2]) : ((empty_1()))), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["required", true]), delay(() => singleton(["onChange", (e_1) => {
                            updateCourseForm({
                                EndDate: Browser_Types_Event__Event_get_Value(e_1),
                                SearchInput: courseForm.SearchInput,
                                SelectedCourse: courseForm.SelectedCourse,
                                StartDate: courseForm.StartDate,
                            });
                        }])))))))))));
                    })))))]),
                })]),
            })));
        })))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg5) => {
        onClose(void 0);
    }]])), delay(() => ((courseForm.SelectedCourse != null) ? append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Velg annet"], ["onClick", (_arg6) => {
        updateCourseForm({
            EndDate: courseForm.EndDate,
            SearchInput: courseForm.SearchInput,
            SelectedCourse: void 0,
            StartDate: courseForm.StartDate,
        });
    }]])), delay(() => singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", ((courseForm.SelectedCourse == null) ? true : (courseForm.StartDate == null)) ? true : (courseForm.EndDate == null)], ["children", "Legg til"], ["onClick", (_arg7) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const matchValue = [courseForm.SelectedCourse, courseForm.StartDate, courseForm.EndDate];
            let pattern_matching_result, ed, sc, sd;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    if (matchValue[2] != null) {
                        pattern_matching_result = 0;
                        ed = matchValue[2];
                        sc = matchValue[0];
                        sd = matchValue[1];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const req = new Competence_UserCourse(-1, userId, sd, ed, sc, []);
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: Competence_UserCourse$reflection,
                        })(req);
                        const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: Competence_UserCourse$reflection,
                        });
                        return Fetch_tryPost_5760677E("/api/course/user", some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                            ResolveType: Competence_UserCourse$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg2) => {
                        const res_1 = _arg2;
                        if (res_1.tag === 0) {
                            onClose(res_1.fields[0]);
                            return Promise.resolve();
                        }
                        else {
                            return Promise.resolve();
                        }
                    }));
                }
                case 1: {
                    return Promise.resolve();
                }
            }
        }));
        pr_1.then();
    }]])))) : empty_1())))))))]])])]]);
}

class Model extends Record {
    constructor(UserId, CanEdit, Courses, SelectedCourse, Delete, ErrorMsg) {
        super();
        this.UserId = (UserId | 0);
        this.CanEdit = CanEdit;
        this.Courses = Courses;
        this.SelectedCourse = SelectedCourse;
        this.Delete = Delete;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Competence.Course.Model", [], Model, () => [["UserId", int32_type], ["CanEdit", bool_type], ["Courses", option_type(array_type(Competence_UserCourse$reflection()))], ["SelectedCourse", option_type(Competence_UserCourse$reflection())], ["Delete", option_type(Competence_UserCourse$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CoursesResponse", "SelectCourse", "CourseAdded", "DeleteCourse", "DeleteResponse", "SetError"];
    }
}

function Message$reflection() {
    return union_type("Competence.Course.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Competence_UserCourse$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Competence_UserCourse$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", Competence_UserCourse$reflection()]], [["Item", Competence_UserCourse$reflection()]], [["Item", option_type(Competence_UserCourse$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(string_type)]]]);
}

function init(userId, canEdit) {
    return new Model(userId, canEdit, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_2;
    switch (msg.tag) {
        case 1: {
            return new Model(model.UserId, model.CanEdit, model.Courses, msg.fields[0], model.Delete, model.ErrorMsg);
        }
        case 2: {
            const x_2 = msg.fields[0];
            return new Model(model.UserId, model.CanEdit, (matchValue = model.Courses, (matchValue != null) ? append_1([x_2], matchValue) : [x_2]), model.SelectedCourse, model.Delete, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.UserId, model.CanEdit, model.Courses, model.SelectedCourse, msg.fields[0], model.ErrorMsg);
        }
        case 4: {
            const res_1 = msg.fields[0];
            const matchValue_1 = model.Delete;
            if (matchValue_1 == null) {
                return model;
            }
            else {
                const d = matchValue_1;
                if (res_1.tag === 1) {
                    return new Model(model.UserId, model.CanEdit, model.Courses, model.SelectedCourse, model.Delete, "Noe gikk galt. Kunne ikke slette kurs.");
                }
                else {
                    const x_4 = res_1.fields[0];
                    if (x_4.Result === "success") {
                        return new Model(model.UserId, model.CanEdit, (matchValue_2 = model.Courses, (matchValue_2 != null) ? matchValue_2.filter((z) => (z.Id !== d.Id)) : (void 0)), model.SelectedCourse, void 0, model.ErrorMsg);
                    }
                    else {
                        return new Model(model.UserId, model.CanEdit, model.Courses, model.SelectedCourse, model.Delete, x_4.Message);
                    }
                }
            }
        }
        case 5: {
            return new Model(model.UserId, model.CanEdit, model.Courses, model.SelectedCourse, model.Delete, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.UserId, model.CanEdit, model.Courses, model.SelectedCourse, model.Delete, "Noe gikk galt. Kunne ikke laste kurs for bruker.");
            }
            else {
                return new Model(model.UserId, model.CanEdit, res.fields[0], model.SelectedCourse, model.Delete, model.ErrorMsg);
            }
        }
    }
}

function deleteCourse(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Competence_UserCourse$reflection,
        })(x);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_tryDelete_5760677E("/api/course", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(4, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function drawCourse(x) {
    return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["children", x.Course.Name]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["children", fromUtcToLocalDate(x.Start)]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["children", fromUtcToLocalDate(x.End)]])])]]);
}

export function UserCourses(userCoursesInputProps) {
    const canEdit = userCoursesInputProps.canEdit;
    const userId = userCoursesInputProps.userId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(userId, canEdit));
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setAddForm = patternInput_1[1];
    const addForm = patternInput_1[0];
    useReact_useEffectOnce_3A5B6456(() => {
        Competence_fetchUserCourses(userId, (arg) => {
            dispatch(new Message(0, arg));
        });
    });
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(addForm ? singleton(createElement(UserCourseForm, {
            userId: userId,
            onClose: (x) => {
                if (x != null) {
                    dispatch(new Message(2, x));
                }
                setAddForm(false);
            },
        })) : empty_1(), delay(() => append(singleton(createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", "Kurs og sertifiseringer"]])), delay(() => (canEdit ? singleton(MuiHelpers_createElement(IconButton, [["children", react.createElement(Add, {})], ["size", "small"], ["onClick", (_arg2) => {
                setAddForm(!addForm);
            }]])) : empty_1()))))))),
        })), delay(() => {
            const matchValue = patternInput[0].Courses;
            if (matchValue != null) {
                const x_1 = matchValue;
                return (x_1.length === 0) ? singleton(createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                        fontStyle: "italic",
                    },
                    children: "Ingen kurs registrert",
                })) : singleton(MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", "Startdato"]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", "Sluttdato"]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((c_2) => drawCourse(c_2), sortByDescending((c_1) => fromUtcString(c_1.End), x_1, {
                    Compare: (x_2, y) => compare(x_2, y),
                }))])]])])]]));
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        })))))))),
    });
}

