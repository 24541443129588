import { Record, Union } from "./.fable/fable-library.3.2.4/Types.js";
import { class_type, record_type, array_type, union_type, option_type, string_type, int32_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { view as view_1, update as update_1, init as init_1, Message$reflection as Message$reflection_1, Model$reflection as Model$reflection_1 } from "./Project/Project.js";
import { view as view_2, update as update_3, init as init_2, Message$reflection as Message$reflection_2, Model$reflection as Model$reflection_2 } from "./Administration/FileStore.js";
import { view as view_3, update as update_2, init as init_3, Message$reflection as Message$reflection_3, Model$reflection as Model$reflection_3 } from "./Search.js";
import { urlParser, toPath, Page, Page$reflection } from "./Pages.js";
import { KeyValue$reflection } from "./Shared/ApiDataTypes.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ProgramModule_toNavigable, Navigation_modifyUrl } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { ofArray, singleton } from "./.fable/fable-library.3.2.4/List.js";
import { Auto_unsafeFromString_Z5CB6BD } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { StyleImports_createMuiTheme_argsArray } from "./.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { fromFlatEntries } from "./.fable/Feliz.MaterialUI.1.2.6/Flatten.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import { ThemeProvider } from "@material-ui/core/styles";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import { createElement } from "react";
import { empty, append, singleton as singleton_1, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { Activation } from "./UserActivation.js";
import { RecoveryForm } from "./PasswordRecovery.js";
import { Login } from "./Login.js";
import { Navbar } from "./Navbar.js";
import { printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { StartPage } from "./StartPage.js";
import { HmsReg } from "./HMS/HmsRegistration.js";
import { HmsControl } from "./HMS/HmsControl.js";
import { ProjectList } from "./Project/ProjectList.js";
import { Report } from "./Report/Report.js";
import { AdminView } from "./Administration.js";
import { UserList } from "./UserList.js";
import { Company } from "./Company/CompanyAdmin.js";
import { Mypage } from "./MyPage/MyPage.js";
import { Calendar } from "./MyPage/Calendar.js";
import { Management } from "./Management/Management.js";
import { Document$ } from "./Management/DocumentView.js";
import { ResourceSearch } from "./Competence/ResourceSearch.js";
import { init as init_4 } from "./Sentry.js";
import { ProgramModule_mkProgram, ProgramModule_run } from "./.fable/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactBatched } from "./.fable/Fable.Elmish.React.3.0.1/react.fs.js";

export class SubModel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["StartPageModel", "AccessDeniedModel", "LoginModel", "HmsRegistrationModel", "ReportModel", "HmsControlModel", "ProjectsModel", "ProjectModel", "AdministrationModel", "UserListModel", "MyCompanyModel", "CompanyModel", "FileStoreModel", "MyPageModel", "ManagementModel", "DocumentModel", "UserProfileModel", "SearchModel", "CalendarModel", "ResourcesModel", "ActivationModel", "PasswordRecoveryModel"];
    }
}

export function SubModel$reflection() {
    return union_type("App.SubModel", [], SubModel, () => [[], [], [], [["Item", int32_type]], [], [], [], [["Item", Model$reflection_1()]], [], [], [], [["Item", int32_type]], [["Item", Model$reflection_2()]], [], [], [["Item", int32_type]], [["Item", int32_type]], [["Item", Model$reflection_3()]], [], [], [["Item", string_type]], [["Item1", string_type], ["Item2", option_type(string_type)]]]);
}

export class Model extends Record {
    constructor(SubMdl, CurrentPage, Configs) {
        super();
        this.SubMdl = SubMdl;
        this.CurrentPage = CurrentPage;
        this.Configs = Configs;
    }
}

export function Model$reflection() {
    return record_type("App.Model", [], Model, () => [["SubMdl", SubModel$reflection()], ["CurrentPage", Page$reflection()], ["Configs", option_type(array_type(KeyValue$reflection()))]]);
}

export class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProjectMessage", "FileStoreMessage", "SearchMessage", "ConfigResponse", "ConfigError"];
    }
}

export function Message$reflection() {
    return union_type("App.Message", [], Message, () => [[["Item", Message$reflection_1()]], [["Item", Message$reflection_2()]], [["Item", Message$reflection_3()]], [["Item", array_type(KeyValue$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export function urlUpdate(result, model) {
    const currentPage = (result == null) ? (new Page(0)) : result;
    let pattern_matching_result, token, token_1, uid, projId, projId_1, compId, userId, x, page_1;
    if (result != null) {
        if (result.tag === 0) {
            pattern_matching_result = 0;
        }
        else if (result.tag === 1) {
            pattern_matching_result = 1;
        }
        else if (result.tag === 44) {
            if (result.fields[0] != null) {
                pattern_matching_result = 2;
                token = result.fields[0];
            }
            else {
                pattern_matching_result = 22;
                page_1 = result;
            }
        }
        else if (result.tag === 45) {
            pattern_matching_result = 3;
            token_1 = result.fields[0][0];
            uid = result.fields[0][1];
        }
        else if (result.tag === 2) {
            pattern_matching_result = 4;
        }
        else if (result.tag === 8) {
            pattern_matching_result = 5;
            projId = result.fields[0];
        }
        else if (result.tag === 9) {
            pattern_matching_result = 6;
        }
        else if (result.tag === 14) {
            pattern_matching_result = 6;
        }
        else if (result.tag === 10) {
            pattern_matching_result = 6;
        }
        else if (result.tag === 11) {
            pattern_matching_result = 7;
        }
        else if (result.tag === 20) {
            pattern_matching_result = 8;
        }
        else if (result.tag === 21) {
            pattern_matching_result = 9;
            projId_1 = result.fields[0];
        }
        else if (result.tag === 31) {
            pattern_matching_result = 10;
        }
        else if (result.tag === 32) {
            pattern_matching_result = 11;
        }
        else if (result.tag === 39) {
            pattern_matching_result = 12;
            compId = result.fields[0];
        }
        else if (result.tag === 18) {
            pattern_matching_result = 13;
        }
        else if (result.tag === 33) {
            pattern_matching_result = 14;
        }
        else if (result.tag === 34) {
            pattern_matching_result = 15;
        }
        else if (result.tag === 40) {
            pattern_matching_result = 16;
            userId = result.fields[0];
        }
        else if (result.tag === 41) {
            pattern_matching_result = 17;
        }
        else if (result.tag === 42) {
            pattern_matching_result = 18;
        }
        else if (result.tag === 37) {
            pattern_matching_result = 19;
        }
        else if (result.tag === 38) {
            pattern_matching_result = 20;
            x = result.fields[0];
        }
        else if (result.tag === 43) {
            pattern_matching_result = 21;
        }
        else {
            pattern_matching_result = 22;
            page_1 = result;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new Model(model.SubMdl, currentPage, model.Configs), Cmd_batch(singleton(Navigation_modifyUrl(toPath(new Page(0)))))];
        }
        case 1: {
            return [new Model(new SubModel(2), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(1)))];
        }
        case 2: {
            return [new Model(new SubModel(20, token), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(44, token)))];
        }
        case 3: {
            return [new Model(new SubModel(21, token_1, uid), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(45, [token_1, uid])))];
        }
        case 4: {
            return [new Model(new SubModel(1), currentPage, model.Configs), Cmd_batch(singleton(Navigation_modifyUrl(toPath(new Page(2)))))];
        }
        case 5: {
            return [new Model(new SubModel(3, projId), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(7)))];
        }
        case 6: {
            return [new Model(new SubModel(4), currentPage, model.Configs), Cmd_batch(singleton(Navigation_modifyUrl(toPath(new Page(9)))))];
        }
        case 7: {
            return [new Model(new SubModel(5), currentPage, model.Configs), Cmd_batch(singleton(Navigation_modifyUrl(toPath(new Page(11)))))];
        }
        case 8: {
            return [new Model(new SubModel(6), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(20)))];
        }
        case 9: {
            const patternInput = init_1(projId_1);
            return [new Model(new SubModel(7, patternInput[0]), currentPage, model.Configs), Cmd_batch(ofArray([Cmd_map((arg0_1) => (new Message(0, arg0_1)), patternInput[1]), Navigation_modifyUrl(toPath(new Page(21, projId_1)))]))];
        }
        case 10: {
            return [new Model(new SubModel(8), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(31)))];
        }
        case 11: {
            return [new Model(new SubModel(9), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(32)))];
        }
        case 12: {
            return [new Model(new SubModel(11, compId), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(39, compId)))];
        }
        case 13: {
            return [new Model(new SubModel(10), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(18)))];
        }
        case 14: {
            const patternInput_1 = init_2();
            return [new Model(new SubModel(12, patternInput_1[0]), currentPage, model.Configs), Cmd_batch(ofArray([Cmd_map((arg0_2) => (new Message(1, arg0_2)), patternInput_1[1]), Navigation_modifyUrl(toPath(new Page(33)))]))];
        }
        case 15: {
            return [new Model(new SubModel(13), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(34)))];
        }
        case 16: {
            return [new Model(new SubModel(16, userId), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(34)))];
        }
        case 17: {
            const patternInput_2 = init_3();
            return [new Model(new SubModel(17, patternInput_2[0]), currentPage, model.Configs), Cmd_batch(ofArray([Cmd_map((arg0_3) => (new Message(2, arg0_3)), patternInput_2[1]), Navigation_modifyUrl(toPath(new Page(41)))]))];
        }
        case 18: {
            return [new Model(new SubModel(18), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(42)))];
        }
        case 19: {
            return [new Model(new SubModel(14), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(37)))];
        }
        case 20: {
            return [new Model(new SubModel(15, x), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(38, x)))];
        }
        case 21: {
            return [new Model(new SubModel(19), currentPage, model.Configs), Navigation_modifyUrl(toPath(new Page(43)))];
        }
        case 22: {
            return [new Model(new SubModel(8), page_1, model.Configs), Navigation_modifyUrl(toPath(new Page(31)))];
        }
    }
}

export function init(result) {
    const matchValue = [window.__INIT_MODEL__, result];
    let pattern_matching_result, json;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 0;
                json = matchValue[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [Auto_unsafeFromString_Z5CB6BD(json, void 0, void 0, {
                ResolveType: Model$reflection,
            }), Cmd_none()];
        }
        case 1: {
            return urlUpdate(result, new Model(new SubModel(0), new Page(0), void 0));
        }
    }
}

export function update(msg, model) {
    const matchValue = [msg, model.SubMdl];
    let pattern_matching_result, pmdl, pmsg, smdl, smsg, mdl_2, msg_1, res;
    if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 17) {
            pattern_matching_result = 1;
            smdl = matchValue[1].fields[0];
            smsg = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 5;
        }
    }
    else if (matchValue[0].tag === 1) {
        if (matchValue[1].tag === 12) {
            pattern_matching_result = 2;
            mdl_2 = matchValue[1].fields[0];
            msg_1 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 5;
        }
    }
    else if (matchValue[0].tag === 3) {
        pattern_matching_result = 3;
        res = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 4) {
        pattern_matching_result = 4;
    }
    else if (matchValue[1].tag === 7) {
        pattern_matching_result = 0;
        pmdl = matchValue[1].fields[0];
        pmsg = matchValue[0].fields[0];
    }
    else {
        pattern_matching_result = 5;
    }
    switch (pattern_matching_result) {
        case 0: {
            const patternInput = update_1(pmsg, pmdl);
            return [new Model(new SubModel(7, patternInput[0]), model.CurrentPage, model.Configs), Cmd_map((arg0) => (new Message(0, arg0)), patternInput[1])];
        }
        case 1: {
            const patternInput_1 = update_2(smsg, smdl);
            return [new Model(new SubModel(17, patternInput_1[0]), model.CurrentPage, model.Configs), Cmd_map((arg0_1) => (new Message(2, arg0_1)), patternInput_1[1])];
        }
        case 2: {
            const patternInput_2 = update_3(msg_1, mdl_2);
            return [new Model(new SubModel(12, patternInput_2[0]), model.CurrentPage, model.Configs), Cmd_map((arg0_2) => (new Message(1, arg0_2)), patternInput_2[1])];
        }
        case 3: {
            return [new Model(model.SubMdl, model.CurrentPage, res), Cmd_none()];
        }
        case 4: {
            return [model, Cmd_none()];
        }
        case 5: {
            return [model, Cmd_none()];
        }
    }
}

export const derigoTheme = (() => {
    const merge = [];
    return StyleImports_createMuiTheme_argsArray(fromFlatEntries(ofArray([["palette.primary.main", "#2d3a48"], ["palette.secondary.main", "#ffcf01"]])), ...merge);
})();

export function view(model, dispatch) {
    return MuiHelpers_createElement(ThemeProvider, [["theme", derigoTheme], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            width: 100 + "vw",
            height: 100 + "vh",
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            if (!(userIsLoggedIn)) {
                const matchValue = model.SubMdl;
                switch (matchValue.tag) {
                    case 20: {
                        return singleton_1(createElement(Activation, {
                            token: matchValue.fields[0],
                        }));
                    }
                    case 21: {
                        return singleton_1(createElement(RecoveryForm, {
                            token: matchValue.fields[0],
                            uid: matchValue.fields[1],
                        }));
                    }
                    default: {
                        return singleton_1(createElement(Login, null));
                    }
                }
            }
            else {
                return singleton_1(createElement("div", {
                    style: {
                        width: 100 + "vw",
                        display: "flex",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((userIsLoggedIn) ? singleton_1(createElement(Navbar, {
                        currentPage: model.CurrentPage,
                    })) : empty(), delay(() => singleton_1(createElement("main", {
                        style: {
                            width: toText(printf("calc(100vw - %ipx"))(70),
                            maxHeight: 100 + "vh",
                            overflowY: "auto",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            const matchValue_1 = model.SubMdl;
                            switch (matchValue_1.tag) {
                                case 20:
                                case 0: {
                                    return singleton_1(createElement(StartPage, null));
                                }
                                case 21:
                                case 1: {
                                    return singleton_1(createElement("div", {
                                        className: "col-md-6 col-md-offset-3",
                                        children: Interop_reactApi.Children.toArray([createElement("h2", {
                                            children: ["Du har ikke de nødvendige rettighetene til å utføre denne handlingen."],
                                        })]),
                                    }));
                                }
                                case 3: {
                                    return singleton_1(createElement(HmsReg, {
                                        projectId: matchValue_1.fields[0],
                                    }));
                                }
                                case 5: {
                                    return singleton_1(createElement(HmsControl, null));
                                }
                                case 6: {
                                    return singleton_1(createElement(ProjectList, null));
                                }
                                case 7: {
                                    return singleton_1(view_1(matchValue_1.fields[0], (arg) => {
                                        dispatch(new Message(0, arg));
                                    }));
                                }
                                case 4: {
                                    return singleton_1(createElement(Report, null));
                                }
                                case 8: {
                                    return singleton_1(createElement(AdminView, {
                                        page: model.CurrentPage,
                                    }));
                                }
                                case 9: {
                                    return singleton_1(createElement(UserList, null));
                                }
                                case 10: {
                                    return singleton_1(createElement(Company, {
                                        companyId: void 0,
                                    }));
                                }
                                case 11: {
                                    return singleton_1(createElement(Company, {
                                        companyId: matchValue_1.fields[0],
                                    }));
                                }
                                case 12: {
                                    return singleton_1(view_2(matchValue_1.fields[0], (arg_1) => {
                                        dispatch(new Message(1, arg_1));
                                    }));
                                }
                                case 13: {
                                    return singleton_1(createElement(Mypage, {
                                        userId: void 0,
                                    }));
                                }
                                case 16: {
                                    return singleton_1(createElement(Mypage, {
                                        userId: matchValue_1.fields[0],
                                    }));
                                }
                                case 17: {
                                    return singleton_1(view_3(matchValue_1.fields[0], (arg_2) => {
                                        dispatch(new Message(2, arg_2));
                                    }));
                                }
                                case 18: {
                                    return singleton_1(createElement(Calendar, {
                                        userId: void 0,
                                        canEdit: false,
                                    }));
                                }
                                case 14: {
                                    return singleton_1(createElement(Management, null));
                                }
                                case 15: {
                                    return singleton_1(createElement(Document$, {
                                        documentId: matchValue_1.fields[0],
                                    }));
                                }
                                case 19: {
                                    return singleton_1(createElement(ResourceSearch, null));
                                }
                                default: {
                                    return singleton_1(createElement(StartPage, null));
                                }
                            }
                        })))),
                    })))))))),
                }));
            }
        })))),
    })])]]);
}

init_4();

ProgramModule_run(Program_withReactBatched("elmish-app", ProgramModule_toNavigable((location) => urlParser(location), (result_1, model_2) => urlUpdate(result_1, model_2), ProgramModule_mkProgram((result) => init(result), (msg, model) => update(msg, model), (model_1, dispatch) => view(model_1, dispatch)))));

