import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { tuple_type, obj_type, record_type, lambda_type, unit_type, string_type, option_type, array_type, union_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { format, split, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { Company$reflection, Quality_FormStat$reflection, RegComment$reflection, ProjectMeta$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { length, item, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, createObj, int32ToString, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { append as append_1, sum, choose, sortBy, map } from "../.fable/fable-library.3.2.4/Array.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Avatar from "@material-ui/core/Avatar";
import { addTooltip, loadingIndicatorMedium, snackbarError, loadingIndicatorCenteredSmall } from "../ViewHelpers.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Colors_QualityColors } from "../Design.js";
import Tooltip from "@material-ui/core/Tooltip";
import { Tooltip as Tooltip_1, Cell, Pie, PieChart } from "recharts";
import Icon from "@material-ui/core/Icon";
import Help from "@material-ui/icons/Help";

export class ReportLevel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Company", "Group"];
    }
}

export function ReportLevel$reflection() {
    return union_type("QualityReport.ReportLevel", [], ReportLevel, () => [[["Item", int32_type]], []]);
}

export function ReportLevel__ToName(this$) {
    if (this$.tag === 1) {
        return "konsernnivå";
    }
    else {
        return "selskapsnivå";
    }
}

export function ReportLevel__ViewId(this$) {
    if (this$.tag === 1) {
        return 3;
    }
    else {
        return 2;
    }
}

export function ReportLevel__ToFormsUrl(this$) {
    if (this$.tag === 1) {
        return "/api/quality/report/group";
    }
    else {
        return toText(printf("/api/quality/report/company/%i"))(this$.fields[0]);
    }
}

class BlameList_Model extends Record {
    constructor(Level, QuestionId, AnswerId, Projects, ErrorMsg, OnClose) {
        super();
        this.Level = Level;
        this.QuestionId = (QuestionId | 0);
        this.AnswerId = (AnswerId | 0);
        this.Projects = Projects;
        this.ErrorMsg = ErrorMsg;
        this.OnClose = OnClose;
    }
}

function BlameList_Model$reflection() {
    return record_type("QualityReport.BlameList.Model", [], BlameList_Model, () => [["Level", ReportLevel$reflection()], ["QuestionId", int32_type], ["AnswerId", int32_type], ["Projects", option_type(array_type(ProjectMeta$reflection()))], ["ErrorMsg", option_type(string_type)], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

class BlameList_Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ListResponse", "SetError", "DismissError"];
    }
}

function BlameList_Message$reflection() {
    return union_type("QualityReport.BlameList.Message", [], BlameList_Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectMeta$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectMeta$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], []]);
}

function BlameList_init(x, y, z, c) {
    return new BlameList_Model(x, y, z, void 0, void 0, c);
}

function BlameList_update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new BlameList_Model(model.Level, model.QuestionId, model.AnswerId, model.Projects, msg.fields[0], model.OnClose);
        }
        case 2: {
            return new BlameList_Model(model.Level, model.QuestionId, model.AnswerId, model.Projects, void 0, model.OnClose);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new BlameList_Model(model.Level, model.QuestionId, model.AnswerId, model.Projects, "Kunne ikke laste liste over prosjekter.", model.OnClose);
            }
            else {
                return new BlameList_Model(model.Level, model.QuestionId, model.AnswerId, res.fields[0], model.ErrorMsg, model.OnClose);
            }
        }
    }
}

function BlameList_fetchBlameList(l, qId, aId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const compId = (l.tag === 1) ? "" : toText(printf("\u0026compId=%i"))(l.fields[0]);
        const requestPath = toText(printf("/api/quality/report/blame/%i?aId=%i%s"))(qId)(aId)(compId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProjectMeta$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProjectMeta$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new BlameList_Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

const BlameList_drawProjectEntry = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const expanded = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const comment = patternInput_1[0];
    return createElement("div", {
        style: {
            marginBottom: 10,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(expanded ? singleton(react.createElement(ExpandMore, {})) : singleton(react.createElement(ChevronRight, {})), delay(() => singleton(createElement("span", {
                style: {
                    cursor: "pointer",
                },
                children: toText(printf("%s (%s)"))(props.x.Name)(props.x.Company),
                onClick: (_arg1_1) => {
                    if ((!expanded) ? (comment == null) : false) {
                        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const requestPath = toText(printf("/api/quality/comment?proj=%i"))(props.x.Id);
                            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                    ResolveType: RegComment$reflection,
                                });
                                return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                    ResolveType: RegComment$reflection,
                                }, {
                                    ResolveType: () => obj_type,
                                });
                            })).then(((_arg1) => {
                                const res = _arg1;
                                if (res.tag === 1) {
                                    props.dispatch(new BlameList_Message(1, "Kunne ikke laste registreringskommentar,"));
                                    return Promise.resolve();
                                }
                                else {
                                    patternInput_1[1](res.fields[0]);
                                    return Promise.resolve();
                                }
                            }));
                        }));
                        pr.then();
                    }
                    patternInput[1](!expanded);
                },
            })))))))),
        })), delay(() => (expanded ? singleton(createElement("div", {
            style: {
                borderLeft: (((1 + "px ") + "dashed") + " ") + "#aaa",
                marginLeft: 10,
                padding: 15,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                if (comment != null) {
                    const c = comment;
                    return append((c.Comment.length === 0) ? singleton(createElement("p", {
                        style: {
                            fontSize: 0.9 + "rem",
                        },
                        children: "Ingen kommentar gitt",
                    })) : singleton(map((a_1) => createElement("p", {
                        style: {
                            fontSize: 0.9 + "rem",
                        },
                        children: a_1,
                    }), split(c.Comment, ["\n"], null, 0).filter((a) => (a.length > 0)))), delay(() => ((c.User.length > 0) ? singleton(createElement("div", {
                        style: {
                            display: "flex",
                            alignItems: "center",
                        },
                        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Avatar, [["alt", "Profilbilde"], ["src", toText(printf("/api/user/profilepicture/thumbnail/%i"))(c.UserId)], ["width", 30], ["height", 30]]), createElement("span", {
                            style: {
                                marginLeft: 7,
                            },
                            children: c.User,
                        })]),
                    })) : empty_1())));
                }
                else {
                    return singleton(loadingIndicatorCenteredSmall());
                }
            })))),
        })) : empty_1()))))))),
    });
}, void 0, uncurry(2, void 0), void 0, "drawProjectEntry", "/builds/serit/derigo/tenant/src/Client/Quality/QualityReport.fs", 94);

function BlameList_view(model, dispatch) {
    let arg10;
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        model.OnClose();
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", (arg10 = ((model.AnswerId === -1) ? "Vet ikke" : int32ToString(model.AnswerId)), toText(printf("Prosjekter som har registrert \"%s\" som svar"))(arg10))]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(snackbarError(model.ErrorMsg, () => {
        dispatch(new BlameList_Message(2));
    })), delay(() => {
        const matchValue = model.Projects;
        return (matchValue != null) ? singleton(map((y) => BlameList_drawProjectEntry({
            dispatch: dispatch,
            x: y,
        }), matchValue)) : singleton(createElement("div", {
            style: {
                minHeight: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
        }));
    }))))))]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Lukk"], ["onClick", (_arg3) => {
        model.OnClose();
    }]])])]])])]]);
}

export const BlameList_blameList = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => BlameList_update(model, msg), BlameList_init(props.lvl, props.qId, props.aId, props.onClose));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        BlameList_fetchBlameList(model_1.Level, model_1.QuestionId, model_1.AnswerId, dispatch);
    }, []);
    return BlameList_view(model_1, dispatch);
}, void 0, uncurry(2, void 0), void 0, "blameList", "/builds/serit/derigo/tenant/src/Client/Quality/QualityReport.fs", 239);

class Model extends Record {
    constructor(Level, Forms, Companies, ErrorMsg, Selected) {
        super();
        this.Level = Level;
        this.Forms = Forms;
        this.Companies = Companies;
        this.ErrorMsg = ErrorMsg;
        this.Selected = Selected;
    }
}

function Model$reflection() {
    return record_type("QualityReport.Model", [], Model, () => [["Level", ReportLevel$reflection()], ["Forms", option_type(array_type(Quality_FormStat$reflection()))], ["Companies", array_type(Company$reflection())], ["ErrorMsg", option_type(string_type)], ["Selected", option_type(tuple_type(int32_type, int32_type))]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FormsResponse", "CompaniesResponse", "SelectLevel", "SetError", "SetSelected", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("QualityReport.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Quality_FormStat$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Quality_FormStat$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", ReportLevel$reflection()]], [["Item", string_type]], [["Item", option_type(tuple_type(int32_type, int32_type))]], []]);
}

function init(x) {
    return new Model(x, void 0, [], void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Level, model.Forms, model.Companies, "Noe gikk galt. Kunne ikke laste selskap.", model.Selected);
            }
            else {
                return new Model(model.Level, model.Forms, res_1.fields[0], model.ErrorMsg, model.Selected);
            }
        }
        case 2: {
            return new Model(msg.fields[0], void 0, model.Companies, model.ErrorMsg, model.Selected);
        }
        case 3: {
            return new Model(model.Level, model.Forms, model.Companies, msg.fields[0], model.Selected);
        }
        case 4: {
            return new Model(model.Level, model.Forms, model.Companies, model.ErrorMsg, msg.fields[0]);
        }
        case 5: {
            return new Model(model.Level, model.Forms, model.Companies, void 0, model.Selected);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Level, model.Forms, model.Companies, "Noe gikk galt. Kunne ikke laste statistikk for kvalitetsskjema", model.Selected);
            }
            else {
                return new Model(model.Level, res.fields[0], model.Companies, model.ErrorMsg, model.Selected);
            }
        }
    }
}

function fetchForms(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = ReportLevel__ToFormsUrl(x);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Quality_FormStat$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Quality_FormStat$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

class ChartData extends Record {
    constructor(name, point, num) {
        super();
        this.name = name;
        this.point = (point | 0);
        this.num = (num | 0);
    }
}

function ChartData$reflection() {
    return record_type("QualityReport.ChartData", [], ChartData, () => [["name", string_type], ["point", int32_type], ["num", int32_type]]);
}

function getColorCode(idx, upper, lower) {
    if (idx === -1) {
        return "#aaa";
    }
    else {
        const limitDiff = (upper - lower) | 0;
        return item(idx * ((limitDiff > 0) ? (~(~(length(Colors_QualityColors) / limitDiff))) : 0), Colors_QualityColors);
    }
}

function drawInfoBars(dispatch, x, upper, lower) {
    let matchValue;
    return createElement("div", {
        style: {
            display: "flex",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray([map((tupledArg_1) => {
            const y_2 = tupledArg_1[0] | 0;
            const z = tupledArg_1[1] | 0;
            return createElement("div", {
                style: {
                    marginRight: 6,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", createElement("span", {
                    style: {
                        fontSize: 20 + "px",
                        padding: 5,
                        color: "#FFFFFF",
                    },
                    children: z,
                })], ["arrow", true], ["placement", "top"], ["children", createElement("div", {
                    onClick: (_arg2) => {
                        dispatch(new Message(4, [x.QuestionId, y_2]));
                    },
                    style: createObj(toList(delay(() => append(singleton(["width", 15]), delay(() => append(singleton(["height", 40]), delay(() => ((z > 0) ? singleton(["backgroundColor", getColorCode(y_2, upper, lower)]) : empty_1())))))))),
                })]]), createElement("div", {
                    style: {
                        textAlign: "center",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {},
                        children: y_2,
                    })]),
                })]),
            });
        }, sortBy((tupledArg) => tupledArg[0], x.Distribution, {
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        })), createElement("div", {
            style: {
                marginRight: 6,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", createElement("span", {
                style: {
                    fontSize: 20 + "px",
                    padding: 5,
                    color: "#FFFFFF",
                },
                children: (matchValue = x.NumUnsure, (matchValue == null) ? 0 : matchValue),
            })], ["arrow", true], ["placement", "top"], ["children", createElement("div", {
                onClick: (_arg3) => {
                    dispatch(new Message(4, [x.QuestionId, -1]));
                },
                style: createObj(toList(delay(() => append(singleton(["width", 15]), delay(() => append(singleton(["height", 40]), delay(() => {
                    const matchValue_1 = x.NumUnsure;
                    let pattern_matching_result;
                    if (matchValue_1 != null) {
                        if (matchValue_1 > 0) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                    switch (pattern_matching_result) {
                        case 0: {
                            return singleton(["backgroundColor", "#aaa"]);
                        }
                        case 1: {
                            return empty_1();
                        }
                    }
                }))))))),
            })]]), createElement("div", {
                style: {
                    textAlign: "center",
                    minWidth: 60,
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    children: "Vet ikke",
                })]),
            })]),
        })]),
    });
}

function coloredCircle(c, x) {
    return createElement("div", {
        style: {
            backgroundColor: c,
            padding: 10,
            borderRadius: 50 + "%",
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ["box-shadow"]: "0 1px 2px rgba(0, 0, 0, .1), 0 4px 6px rgba(0, 0, 0, .1)",
        },
        children: Interop_reactApi.Children.toArray(Array.from(x)),
    });
}

function circleText(x) {
    return createElement("span", {
        style: {
            fontSize: 16 + "px",
            height: 21,
            fontWeight: "bold",
            userSelect: "none",
        },
        children: x,
    });
}

export const radian = 3.141592653589793 / 180;

function distributionPie(data, questionId, upperLimit, lowerLimit, dispatch) {
    return createElement("div", {
        style: {
            width: 100,
            height: 100,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((data.length > 0) ? singleton(Interop_reactApi.createElement(PieChart, {
            width: 100,
            height: 100,
            children: ["children", Interop_reactApi.Children.toArray([Interop_reactApi.createElement(Pie, {
                dataKey: "num",
                fill: "#209cee",
                labelLine: false,
                label: false,
                cx: 50 + "%",
                cy: 50 + "%",
                data: data,
                nameKey: "name",
                outerRadius: 90 + "%",
                innerRadius: 40 + "%",
                children: ["children", Interop_reactApi.Children.toArray([map((z) => Interop_reactApi.createElement(Cell, {
                    cursor: "pointer",
                    key: int32ToString(z.point),
                    fill: getColorCode(z.point, upperLimit, lowerLimit),
                    onClick: (_arg1) => dispatch(new Message(4, [questionId, z.point])),
                }), data)])],
            }), Interop_reactApi.createElement(Tooltip_1, {})])],
        })) : singleton(createElement("span", {
            children: ["Ingen data"],
        }))))))),
    });
}

function drawFormEntry(model, dispatch, x) {
    const infoBox = (x_1) => createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: 100,
            minWidth: 65,
            paddingBottom: 20,
        },
        children: Interop_reactApi.Children.toArray(Array.from(x_1)),
    });
    let avg;
    if (x.Questions.length > 0) {
        const q = choose((y) => y.Average, x.Questions, Float64Array);
        avg = ((q.length > 0) ? (sum(q, {
            GetZero: () => 0,
            Add: (x_2, y_1) => (x_2 + y_1),
        }) / x.Questions.length) : (void 0));
    }
    else {
        avg = (void 0);
    }
    const avgColor = (avg == null) ? "#f6f6f6" : getColorCode(~(~avg), x.UpperLimit, x.LowerLimit);
    return createElement("div", {
        style: {
            margin: 20,
            backgroundColor: "#F5F5F5",
            padding: 10,
            borderRadius: 6,
            ["box-shadow"]: "rgba(10, 10, 10, 0.1) 0px 2px 3px, rgba(10, 10, 10, 0.1) 0px 0px 0px 1px",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                justifyContent: "space-between",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 1.6 + "rem",
                    ["border-left"]: "4px solid #363636",
                    padding: 5,
                    paddingLeft: 10,
                },
                children: x.FormName,
            }), createElement("div", {
                style: {
                    display: "flex",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let matchValue, goal;
                    return append((matchValue = x.Goal, (matchValue != null) ? ((goal = matchValue, singleton(createElement("div", {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            marginRight: 10,
                        },
                        children: Interop_reactApi.Children.toArray([coloredCircle("#f6f6f6", [circleText(format('{0:' + "0.0" + '}', goal))]), createElement("span", {
                            style: {
                                fontSize: 0.8 + "rem",
                            },
                            children: "Mål",
                        })]),
                    })))) : ((empty_1()))), delay(() => {
                        let a_1;
                        return singleton(coloredCircle(avgColor, [circleText((avg != null) ? ((a_1 = avg, format('{0:' + "0.0" + '}', a_1))) : "N/A")]));
                    }));
                })))),
            })]),
        }), createElement("div", {
            style: {},
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                },
                children: toText(printf("Det registreres svar fra %i til %i."))(x.LowerLimit)(x.UpperLimit),
            }), map((y_3) => {
                let matchValue_1;
                const data = sortBy((x_4) => x_4.point, map((tupledArg) => {
                    const x_3 = tupledArg[0] | 0;
                    return new ChartData((x_3 === -1) ? "Vet ikke" : int32ToString(x_3), x_3, tupledArg[1]);
                }, append_1([(matchValue_1 = y_3.NumUnsure, (matchValue_1 != null) ? [-1, matchValue_1] : [-1, 0])], y_3.Distribution)), {
                    Compare: (x_5, y_5) => comparePrimitives(x_5, y_5),
                });
                return createElement("div", {
                    style: {
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 10,
                        marginBottom: 10,
                        width: 100 + "%",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        style: {},
                        children: Interop_reactApi.Children.toArray([createElement("p", {
                            style: {
                                fontStyle: "italic",
                                margin: 0,
                                fontSize: 1.1 + "rem",
                            },
                            children: y_3.Question,
                        })]),
                    }), createElement("div", {
                        style: {
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            style: {
                                display: "flex",
                                justifyContent: "center",
                            },
                            children: Interop_reactApi.Children.toArray([infoBox(toList(delay(() => append(singleton(createElement("span", {
                                style: {
                                    fontSize: 0.8 + "rem",
                                },
                                children: "Gj.snitt",
                            })), delay(() => {
                                const matchValue_2 = y_3.Average;
                                if (matchValue_2 != null) {
                                    const a_2 = matchValue_2;
                                    return singleton(coloredCircle(getColorCode(~(~a_2), x.UpperLimit, x.LowerLimit), [circleText(format('{0:' + "0.0" + '}', a_2))]));
                                }
                                else {
                                    return singleton(coloredCircle("#f6f6f6", [circleText("N/A")]));
                                }
                            }))))), infoBox(toList(delay(() => append(singleton(createElement("span", {
                                style: {
                                    fontSize: 0.8 + "rem",
                                },
                                children: "Vet ikke",
                            })), delay(() => {
                                const matchValue_3 = y_3.NumUnsure;
                                return (matchValue_3 != null) ? singleton(coloredCircle("#f6f6f6", [circleText(int32ToString(matchValue_3))])) : singleton(createElement("span", {
                                    style: {
                                        textAlign: "center",
                                        fontSize: 0.8 + "rem",
                                    },
                                    children: "Ikke tillatt",
                                }));
                            }))))), infoBox([createElement("span", {
                                style: {
                                    fontSize: 0.8 + "rem",
                                },
                                children: "Antall svar",
                            }), coloredCircle("#f6f6f6", [circleText(int32ToString(y_3.NumAnswers))])])]),
                        }), createElement("div", {
                            style: {
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            },
                            children: Interop_reactApi.Children.toArray([drawInfoBars(dispatch, y_3, x.UpperLimit, x.LowerLimit), distributionPie(data, y_3.QuestionId, x.UpperLimit, x.LowerLimit, dispatch)]),
                        })]),
                    })]),
                });
            }, x.Questions)]),
        })]),
    });
}

function view(model, dispatch) {
    let avg;
    const matchValue = model.Forms;
    if (matchValue != null) {
        const x = matchValue;
        const y_3 = choose((x_2) => x_2, map((y) => {
            if (y.Questions.length > 0) {
                const q = choose((y_1) => y_1.Average, y.Questions, Float64Array);
                if (q.length > 0) {
                    return sum(q, {
                        GetZero: () => 0,
                        Add: (x_1, y_2) => (x_1 + y_2),
                    }) / q.length;
                }
                else {
                    return void 0;
                }
            }
            else {
                return void 0;
            }
        }, x), Float64Array);
        avg = ((y_3.length > 0) ? (sum(y_3, {
            GetZero: () => 0,
            Add: (x_3, y_4) => (x_3 + y_4),
        }) / x.length) : (void 0));
    }
    else {
        avg = (void 0);
    }
    const avgColor = (avg == null) ? "#f6f6f6" : getColorCode(~(~avg), 10, 1);
    return createElement("div", {
        className: "report-content",
        children: Interop_reactApi.Children.toArray([snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(5));
        }), createElement("div", {
            className: "report-content section-to-print",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let arg10, a_1;
                const matchValue_1 = model.Forms;
                if (matchValue_1 != null) {
                    const x_4 = matchValue_1;
                    return append(singleton(createElement("div", {
                        style: {
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: 10,
                        },
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            style: {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                style: {
                                    fontSize: 1.6 + "rem",
                                    marginRight: 5,
                                },
                                children: "Vektet gjennomsnitt",
                            }), createElement("div", {
                                style: {
                                    marginRight: 5,
                                    display: "flex",
                                    alignItems: "center",
                                },
                                children: Interop_reactApi.Children.toArray([addTooltip((arg10 = (x_4.length | 0), toText(printf("Gjennomsnittet av gjennomsnitt for de %i kvalitetsskjema under. \"Vet ikke\"- og \"N/A\"-verdier regnes ikke med."))(arg10)), MuiHelpers_createElement(Icon, [["style", {
                                    color: "#aaa",
                                }], ["children", react.createElement(Help, {})]]))]),
                            }), coloredCircle(avgColor, [circleText((avg != null) ? ((a_1 = avg, format('{0:' + "0.0" + '}', a_1))) : "N/A")])]),
                        })]),
                    })), delay(() => append(singleton(createElement("div", {
                        style: {
                            display: "flex",
                            justifyContent: "center",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            style: {},
                            children: Interop_reactApi.Children.toArray([]),
                        }), createElement("div", {
                            style: {
                                display: "flex",
                                flexWrap: "wrap",
                                width: 100 + "%",
                                justifyContent: "space-around",
                            },
                            children: Interop_reactApi.Children.toArray([map((y_5) => drawFormEntry(model, dispatch, y_5), x_4)]),
                        }), createElement("div", {
                            style: {},
                            children: Interop_reactApi.Children.toArray([]),
                        })]),
                    })), delay(() => {
                        const matchValue_2 = model.Selected;
                        if (matchValue_2 != null) {
                            return singleton(BlameList_blameList({
                                aId: matchValue_2[1],
                                lvl: model.Level,
                                onClose: () => {
                                    dispatch(new Message(4, void 0));
                                },
                                qId: matchValue_2[0],
                            }));
                        }
                        else {
                            return empty_1();
                        }
                    }))));
                }
                else {
                    return singleton(createElement("div", {
                        style: {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: 70 + "vh",
                        },
                    }));
                }
            })))),
        })]),
    });
}

export function QualityReport(qualityReportInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(qualityReportInputProps.lvl));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchForms(model_1.Level, dispatch);
    });
    return view(model_1, dispatch);
}

