import { toString, Union } from "./.fable/fable-library.3.2.4/Types.js";
import { union_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { hasPermission } from "./LoginState.js";
import { Functionality } from "./Shared/PermissionMapping.js";
import { createElement } from "react";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import { useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.68.0/React.fs.js";
import Tooltip from "@material-ui/core/Tooltip";
import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { equals } from "./.fable/fable-library.3.2.4/Util.js";
import { Page } from "./Pages.js";

export class MenuItem extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Home", "MyCompetence", "Resources", "Project", "Members", "Company", "Reports", "Administration", "Management", "Logout"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Min kompetanseprofil";
            }
            case 2: {
                return "Ressurser";
            }
            case 3: {
                return "Prosjekter";
            }
            case 4: {
                return "Ansatte";
            }
            case 5: {
                return "Selskap";
            }
            case 6: {
                return "Rapporter";
            }
            case 7: {
                return "Administrasjon";
            }
            case 8: {
                return "Ledelsessystem";
            }
            case 9: {
                return "Logg ut";
            }
            default: {
                return "Startside";
            }
        }
    }
}

export function MenuItem$reflection() {
    return union_type("Navbar.MenuItem", [], MenuItem, () => [[], [], [], [], [], [], [], [], [], []]);
}

export function MenuItem__Link(this$) {
    switch (this$.tag) {
        case 1: {
            return "/mypage";
        }
        case 2: {
            return "/resources";
        }
        case 3: {
            return "/projects";
        }
        case 4: {
            return "/members";
        }
        case 5: {
            return "/company";
        }
        case 6: {
            return "/report";
        }
        case 7: {
            return "/administration";
        }
        case 8: {
            return "/management";
        }
        case 9: {
            return "/logout";
        }
        default: {
            return "/";
        }
    }
}

export function MenuItem_hasPermissionToShow_Z62DC1EDB(x) {
    switch (x.tag) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 9: {
            return true;
        }
        case 6: {
            return hasPermission(new Functionality(44));
        }
        case 7: {
            return [hasPermission(new Functionality(35)), hasPermission(new Functionality(37)), hasPermission(new Functionality(42)), hasPermission(new Functionality(23)), hasPermission(new Functionality(24)), hasPermission(new Functionality(25)), hasPermission(new Functionality(26)), hasPermission(new Functionality(29)), hasPermission(new Functionality(34)), hasPermission(new Functionality(31))].some((y) => (true === y));
        }
        case 8: {
            return hasPermission(new Functionality(51));
        }
        default: {
            return true;
        }
    }
}

export function topBarLogo() {
    return createElement("img", {
        style: {
            height: 50,
            marginTop: 5,
            marginLeft: 10,
        },
        src: "/images/android-chrome-192x192.png",
    });
}

export function breadcrumbChevron() {
    return MuiHelpers_createElement(Typography, [["children", Interop_reactApi.Children.toArray([createElement("span", {
        style: {
            margin: ((0 + "px ") + 5) + "px",
            color: "#2d3a48",
        },
        className: "fas fa-chevron-right",
    })])]]);
}

export function breadcrumbTypography(t) {
    return MuiHelpers_createElement(Typography, [["style", {
        whiteSpace: "nowrap",
        userSelect: "none",
        color: "#2d3a48",
    }], ["children", t]]);
}

export function breadcrumbLink(t, href) {
    return MuiHelpers_createElement(Typography, [["component", "a"], ["style", {
        whiteSpace: "nowrap",
        color: "#2d3a48",
    }], ["href", href], ["children", t]]);
}

export function Navbar(navbarInputProps) {
    const currentPage = navbarInputProps.currentPage;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const docsAnchor = useReact_useRef_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const dnvAnchor = useReact_useRef_1505(void 0);
    const navbarItem = (icon, item, isActive) => createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", toString(item)], ["placement", "right"], ["arrow", true], ["children", createElement("a", {
            className: isActive ? "icon-nav-active" : "icon-nav",
            href: MenuItem__Link(item),
            children: Interop_reactApi.Children.toArray([createElement("span", {
                className: icon,
                style: {
                    fontSize: 23 + "px",
                },
            })]),
        })]])]),
    });
    return createElement("nav", {
        style: {
            display: "flex",
            flexDirection: "column",
            height: 100 + "vh",
            padding: 2,
            width: 70,
            backgroundColor: "#2d3a48",
            zIndex: 1250,
            overflowY: "auto",
            justifyContent: "space-between",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
                style: {
                    height: 64,
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
                children: Interop_reactApi.Children.toArray([createElement("img", {
                    style: {
                        height: 40,
                    },
                    src: "/images/android-chrome-192x192.png",
                })]),
            })), delay(() => append(singleton(navbarItem("fas fa-home", new MenuItem(0), equals(currentPage, new Page(0)))), delay(() => append(singleton(navbarItem("fas fa-graduation-cap", new MenuItem(1), equals(currentPage, new Page(34)))), delay(() => append(singleton(navbarItem("fas fa-users", new MenuItem(2), equals(currentPage, new Page(43)))), delay(() => append(singleton(navbarItem("fas fa-pencil-ruler", new MenuItem(3), (currentPage.tag === 20) ? true : ((currentPage.tag === 21) ? true : false))), delay(() => append(singleton(navbarItem("fas fa-address-book", new MenuItem(4), equals(currentPage, new Page(32)))), delay(() => append(singleton(navbarItem("fas fa-building", new MenuItem(5), equals(currentPage, new Page(18)))), delay(() => append(MenuItem_hasPermissionToShow_Z62DC1EDB(new MenuItem(6)) ? singleton(navbarItem("fas fa-chart-bar", new MenuItem(6), equals(currentPage, new Page(9)))) : empty(), delay(() => (MenuItem_hasPermissionToShow_Z62DC1EDB(new MenuItem(8)) ? singleton(navbarItem("fas fa-book", new MenuItem(8), equals(currentPage, new Page(37)))) : empty()))))))))))))))))))))),
        }), createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(MenuItem_hasPermissionToShow_Z62DC1EDB(new MenuItem(7)) ? singleton(navbarItem("fas fa-cog", new MenuItem(7), equals(currentPage, new Page(31)))) : empty(), delay(() => singleton(navbarItem("fas fa-sign-out-alt", new MenuItem(9), false)))))))),
        })]),
    });
}

