import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, bool_type, option_type, array_type, union_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Helpers_ResultMessage$reflection, BasicAuth_User, BasicAuth_Department$reflection, BasicAuth_Office$reflection, BasicAuth_Company$reflection, BasicAuth_User$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { sortBy, sort, map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, empty } from "../.fable/fable-library.3.2.4/List.js";
import { equals, createObj, comparePrimitives, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import TextField from "@material-ui/core/TextField";
import { tryFocus } from "../Utils.js";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { addTooltip, errorBanner } from "../ViewHelpers.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import Snackbar_1 from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Delete from "@material-ui/icons/Delete";

class Snackbar extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Saved", "InProgress", "Creating", "Unsaved", "Hide", "Failed"];
    }
}

function Snackbar$reflection() {
    return union_type("UserConfigure.Snackbar", [], Snackbar, () => [[], [], [], [], [], [["Item", string_type]]]);
}

class Model extends Record {
    constructor(Users, Companies, Offices, Departments, SelectedUser, SnackState, ShowAdd, DeleteForm) {
        super();
        this.Users = Users;
        this.Companies = Companies;
        this.Offices = Offices;
        this.Departments = Departments;
        this.SelectedUser = SelectedUser;
        this.SnackState = SnackState;
        this.ShowAdd = ShowAdd;
        this.DeleteForm = DeleteForm;
    }
}

function Model$reflection() {
    return record_type("UserConfigure.Model", [], Model, () => [["Users", array_type(BasicAuth_User$reflection())], ["Companies", array_type(BasicAuth_Company$reflection())], ["Offices", array_type(BasicAuth_Office$reflection())], ["Departments", array_type(BasicAuth_Department$reflection())], ["SelectedUser", option_type(BasicAuth_User$reflection())], ["SnackState", Snackbar$reflection()], ["ShowAdd", bool_type], ["DeleteForm", option_type(BasicAuth_User$reflection())]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UsersResponse", "CompaniesResponse", "OfficesResponse", "DepartmentsResponse", "UserAdded", "UserUpdated", "UserDeleted", "SelectUser", "SetSnackState", "SetShowAdd", "ToggleDelete", "DismissUser"];
    }
}

function Message$reflection() {
    return union_type("UserConfigure.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(BasicAuth_User$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(BasicAuth_User$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(BasicAuth_Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(BasicAuth_Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(BasicAuth_Office$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(BasicAuth_Office$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(BasicAuth_Department$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(BasicAuth_Department$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", BasicAuth_User$reflection()]], [["Item", BasicAuth_User$reflection()]], [["Item", BasicAuth_User$reflection()]], [["Item", BasicAuth_User$reflection()]], [["Item", Snackbar$reflection()]], [["Item", bool_type]], [["Item", option_type(BasicAuth_User$reflection())]], []]);
}

function init() {
    return new Model([], [], [], [], void 0, new Snackbar(4), false, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const resp_1 = msg.fields[0];
            if (resp_1.tag === 1) {
                return new Model(model.Users, model.Companies, model.Offices, model.Departments, model.SelectedUser, new Snackbar(5, "Noe gikk galt. Kunne ikke laste selskaper"), model.ShowAdd, model.DeleteForm);
            }
            else {
                return new Model(model.Users, resp_1.fields[0], model.Offices, model.Departments, model.SelectedUser, model.SnackState, model.ShowAdd, model.DeleteForm);
            }
        }
        case 2: {
            const resp_2 = msg.fields[0];
            if (resp_2.tag === 1) {
                return new Model(model.Users, model.Companies, model.Offices, model.Departments, model.SelectedUser, new Snackbar(5, "Noe gikk galt. Kunne ikke laste kontorer"), model.ShowAdd, model.DeleteForm);
            }
            else {
                return new Model(model.Users, model.Companies, resp_2.fields[0], model.Departments, model.SelectedUser, model.SnackState, model.ShowAdd, model.DeleteForm);
            }
        }
        case 3: {
            const resp_3 = msg.fields[0];
            if (resp_3.tag === 1) {
                return new Model(model.Users, model.Companies, model.Offices, model.Departments, model.SelectedUser, new Snackbar(5, "Noe gikk galt. Kunne ikke laste avdelinger"), model.ShowAdd, model.DeleteForm);
            }
            else {
                return new Model(model.Users, model.Companies, model.Offices, resp_3.fields[0], model.SelectedUser, model.SnackState, model.ShowAdd, model.DeleteForm);
            }
        }
        case 4: {
            return new Model(append([msg.fields[0]], model.Users), model.Companies, model.Offices, model.Departments, model.SelectedUser, new Snackbar(0), false, model.DeleteForm);
        }
        case 5: {
            const x_1 = msg.fields[0];
            return new Model(map((z) => {
                if (z.Uuid === x_1.Uuid) {
                    return x_1;
                }
                else {
                    return z;
                }
            }, model.Users), model.Companies, model.Offices, model.Departments, model.SelectedUser, new Snackbar(0), model.ShowAdd, model.DeleteForm);
        }
        case 6: {
            return new Model(model.Users.filter((z_1) => (z_1.Uuid !== msg.fields[0].Uuid)), model.Companies, model.Offices, model.Departments, model.SelectedUser, new Snackbar(0), model.ShowAdd, model.DeleteForm);
        }
        case 7: {
            return new Model(model.Users, model.Companies, model.Offices, model.Departments, msg.fields[0], model.SnackState, model.ShowAdd, model.DeleteForm);
        }
        case 11: {
            return new Model(model.Users, model.Companies, model.Offices, model.Departments, void 0, model.SnackState, model.ShowAdd, model.DeleteForm);
        }
        case 9: {
            return new Model(model.Users, model.Companies, model.Offices, model.Departments, model.SelectedUser, model.SnackState, msg.fields[0], model.DeleteForm);
        }
        case 10: {
            return new Model(model.Users, model.Companies, model.Offices, model.Departments, model.SelectedUser, model.SnackState, model.ShowAdd, msg.fields[0]);
        }
        case 8: {
            return new Model(model.Users, model.Companies, model.Offices, model.Departments, model.SelectedUser, msg.fields[0], model.ShowAdd, model.DeleteForm);
        }
        default: {
            const resp = msg.fields[0];
            if (resp.tag === 1) {
                return new Model(model.Users, model.Companies, model.Offices, model.Departments, model.SelectedUser, new Snackbar(5, "Noe gikk galt. Kunne ikke laste brukere"), model.ShowAdd, model.DeleteForm);
            }
            else {
                return new Model(resp.fields[0], model.Companies, model.Offices, model.Departments, model.SelectedUser, model.SnackState, model.ShowAdd, model.DeleteForm);
            }
        }
    }
}

function fetchCompanies(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(BasicAuth_Company$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/basicauth/companies", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(BasicAuth_Company$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchOffices(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(BasicAuth_Office$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/basicauth/offices", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(BasicAuth_Office$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchDepartments(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(BasicAuth_Department$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/basicauth/departments", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(BasicAuth_Department$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(3, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchUsers(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(BasicAuth_User$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/basicauth/user", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(BasicAuth_User$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

const countryCodes = ["+30", "+31", "+32", "+33", "+34", "+350", "+351", "+352", "+353", "+354", "+355", "+356", "+357", "+358", "+35818", "+359", "+36", "+370", "+371", "+372", "+373", "+374", "+375", "+376", "+377", "+378", "+379", "+380", "+381", "+382", "+383", "+385", "+387", "+387", "+389", "+39", "+40", "+41", "+420", "+421", "+423", "+43", "+44", "+45", "+46", "+47", "+4779", "+48", "+49"];

function AddForm(addFormInputProps) {
    let children, matchValue_1;
    const onClose = addFormInputProps.onClose;
    const departments = addFormInputProps.departments;
    const offices = addFormInputProps.offices;
    const companies = addFormInputProps.companies;
    const patternInput = useFeliz_React__React_useState_Static_1505({
        Company: void 0,
        Department: void 0,
        Email: "",
        ErrorMsg: void 0,
        Firstname: "",
        Lastname: "",
        Office: void 0,
        Phone: "",
        PhoneCC: "+47",
        Title: "",
        Username: "",
    });
    const updateState = patternInput[1];
    const state = patternInput[0];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til bruker"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["autoFocus", true], ["variant", "outlined"], ["onKeyUp", (k) => {
            if (k.key === "Enter") {
                k.preventDefault();
                tryFocus("firstname-input");
            }
        }], ["style", {
            margin: ((5 + "px ") + 0) + "px",
        }], ["fullWidth", true], ["size", "small"], ["label", "Brukernavn/innlogging"], ["onChange", (e_1) => {
            updateState({
                Company: state.Company,
                Department: state.Department,
                Email: state.Email,
                ErrorMsg: state.ErrorMsg,
                Firstname: state.Firstname,
                Lastname: state.Lastname,
                Office: state.Office,
                Phone: state.Phone,
                PhoneCC: state.PhoneCC,
                Title: state.Title,
                Username: Browser_Types_Event__Event_get_Value(e_1),
            });
        }], ["required", true], ["defaultValue", state.Username]]), MuiHelpers_createElement(TextField, [["variant", "outlined"], ["id", "firstname-input"], ["onKeyUp", (k_1) => {
            if (k_1.key === "Enter") {
                k_1.preventDefault();
                tryFocus("lastname-input");
            }
        }], ["style", {
            margin: ((5 + "px ") + 0) + "px",
        }], ["fullWidth", true], ["size", "small"], ["label", "Fornavn"], ["onChange", (e_2) => {
            updateState({
                Company: state.Company,
                Department: state.Department,
                Email: state.Email,
                ErrorMsg: state.ErrorMsg,
                Firstname: Browser_Types_Event__Event_get_Value(e_2),
                Lastname: state.Lastname,
                Office: state.Office,
                Phone: state.Phone,
                PhoneCC: state.PhoneCC,
                Title: state.Title,
                Username: state.Username,
            });
        }], ["defaultValue", state.Firstname]]), MuiHelpers_createElement(TextField, [["variant", "outlined"], ["id", "lastname-input"], ["onKeyUp", (k_2) => {
            if (k_2.key === "Enter") {
                k_2.preventDefault();
                tryFocus("email-input");
            }
        }], ["style", {
            margin: ((5 + "px ") + 0) + "px",
        }], ["fullWidth", true], ["size", "small"], ["label", "Etternavn"], ["onChange", (e_3) => {
            updateState({
                Company: state.Company,
                Department: state.Department,
                Email: state.Email,
                ErrorMsg: state.ErrorMsg,
                Firstname: state.Firstname,
                Lastname: Browser_Types_Event__Event_get_Value(e_3),
                Office: state.Office,
                Phone: state.Phone,
                PhoneCC: state.PhoneCC,
                Title: state.Title,
                Username: state.Username,
            });
        }], ["defaultValue", state.Lastname]]), MuiHelpers_createElement(TextField, [["variant", "outlined"], ["id", "email-input"], ["onKeyUp", (k_3) => {
            if (k_3.key === "Enter") {
                k_3.preventDefault();
                tryFocus("phone-input");
            }
        }], ["style", {
            margin: ((5 + "px ") + 0) + "px",
        }], ["fullWidth", true], ["size", "small"], ["label", "E-post"], ["onChange", (e_4) => {
            updateState({
                Company: state.Company,
                Department: state.Department,
                Email: Browser_Types_Event__Event_get_Value(e_4),
                ErrorMsg: state.ErrorMsg,
                Firstname: state.Firstname,
                Lastname: state.Lastname,
                Office: state.Office,
                Phone: state.Phone,
                PhoneCC: state.PhoneCC,
                Title: state.Title,
                Username: state.Username,
            });
        }], ["defaultValue", state.Email]]), createElement("div", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControl, [["required", true], ["size", "small"], ["style", {
                minWidth: 100,
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Landskode"]]), MuiHelpers_createElement(Select, [["value", state.PhoneCC], ["onChange", (e_5, _arg24) => {
                updateState({
                    Company: state.Company,
                    Department: state.Department,
                    Email: state.Email,
                    ErrorMsg: state.ErrorMsg,
                    Firstname: state.Firstname,
                    Lastname: state.Lastname,
                    Office: state.Office,
                    Phone: state.Phone,
                    PhoneCC: Browser_Types_Event__Event_get_Value(e_5),
                    Title: state.Title,
                    Username: state.Username,
                });
            }], ["children", Interop_reactApi.Children.toArray([map((c) => MuiHelpers_createElement(MenuItem, [["value", c], ["children", c]]), sort(countryCodes, {
                Compare: (x_5, y_4) => comparePrimitives(x_5, y_4),
            }))])]])])]]), MuiHelpers_createElement(TextField, [["variant", "outlined"], ["id", "phone-input"], ["style", {
                margin: ((((((5 + "px ") + 0) + "px ") + 5) + "px ") + 5) + "px",
            }], ["fullWidth", true], ["size", "small"], ["label", "Mobil"], ["onChange", (e_6) => {
                updateState({
                    Company: state.Company,
                    Department: state.Department,
                    Email: state.Email,
                    ErrorMsg: state.ErrorMsg,
                    Firstname: state.Firstname,
                    Lastname: state.Lastname,
                    Office: state.Office,
                    Phone: Browser_Types_Event__Event_get_Value(e_6),
                    PhoneCC: state.PhoneCC,
                    Title: state.Title,
                    Username: state.Username,
                });
            }], ["defaultValue", state.Phone]])]),
        }), createElement("div", {
            style: createObj(toList(delay(() => append_1(singleton(["display", "flex"]), delay(() => append_1((state.Company == null) ? singleton(["justifyContent", "center"]) : singleton(["justify-content", "space-evenly"]), delay(() => singleton(["flexWrap", "wrap"])))))))),
            children: Interop_reactApi.Children.toArray([(children = singleton_1(MuiHelpers_createElement(FormControl, [["required", true], ["style", {
                minWidth: 100,
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["id", "company-input-label"], ["children", "Selskap"]]), MuiHelpers_createElement(Select, [["value", (matchValue_1 = state.Company, (matchValue_1 != null) ? matchValue_1 : "")], ["onChange", (e_7, _arg24_1) => {
                updateState({
                    Company: Browser_Types_Event__Event_get_Value(e_7),
                    Department: state.Department,
                    Email: state.Email,
                    ErrorMsg: state.ErrorMsg,
                    Firstname: state.Firstname,
                    Lastname: state.Lastname,
                    Office: state.Office,
                    Phone: state.Phone,
                    PhoneCC: state.PhoneCC,
                    Title: state.Title,
                    Username: state.Username,
                });
                console.log(some(state));
            }], ["labelId", "company-input-label"], ["children", Interop_reactApi.Children.toArray([map((c_2) => MuiHelpers_createElement(MenuItem, [["value", c_2.Uuid], ["children", c_2.Name]]), sortBy((c_1) => c_1.Name, companies, {
                Compare: (x_9, y_5) => comparePrimitives(x_9, y_5),
            }))])]])])]])), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), createElement("div", {
                style: {
                    display: "flex",
                    alignItems: "center",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let matchValue_3;
                    const matchValue_2 = state.Company;
                    if (matchValue_2 != null) {
                        const company = matchValue_2;
                        const o_2 = sortBy((o_1) => o_1.Name, offices.filter((o) => (o.CompanyUuid === company)), {
                            Compare: (x_10, y_6) => comparePrimitives(x_10, y_6),
                        });
                        return (o_2.length === 0) ? singleton(createElement("span", {
                            children: "Ingen kontor registrert",
                        })) : singleton(MuiHelpers_createElement(FormControl, [["style", {
                            minWidth: 100,
                        }], ["required", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Kontor"]]), MuiHelpers_createElement(Select, [["value", (matchValue_3 = state.Office, (matchValue_3 != null) ? matchValue_3 : "")], ["onChange", (e_8, _arg24_2) => {
                            updateState({
                                Company: state.Company,
                                Department: state.Department,
                                Email: state.Email,
                                ErrorMsg: state.ErrorMsg,
                                Firstname: state.Firstname,
                                Lastname: state.Lastname,
                                Office: Browser_Types_Event__Event_get_Value(e_8),
                                Phone: state.Phone,
                                PhoneCC: state.PhoneCC,
                                Title: state.Title,
                                Username: state.Username,
                            });
                        }], ["children", Interop_reactApi.Children.toArray([map((o_3) => MuiHelpers_createElement(MenuItem, [["value", o_3.Uuid], ["children", o_3.Name]]), o_2)])]])])]]));
                    }
                    else {
                        return empty_1();
                    }
                })))),
            }), createElement("div", {
                style: {
                    display: "flex",
                    alignItems: "center",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let matchValue_5;
                    const matchValue_4 = state.Company;
                    if (matchValue_4 != null) {
                        const company_1 = matchValue_4;
                        const d = sortBy((o_4) => o_4.Name, departments, {
                            Compare: (x_13, y_7) => comparePrimitives(x_13, y_7),
                        });
                        return (d.length === 0) ? singleton(createElement("span", {
                            children: "Ingen avdelinger registrert",
                        })) : singleton(MuiHelpers_createElement(FormControl, [["style", {
                            minWidth: 100,
                        }], ["required", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Avdeling"]]), MuiHelpers_createElement(Select, [["value", (matchValue_5 = state.Department, (matchValue_5 != null) ? matchValue_5 : "")], ["onChange", (e_9, _arg24_3) => {
                            updateState({
                                Company: state.Company,
                                Department: Browser_Types_Event__Event_get_Value(e_9),
                                Email: state.Email,
                                ErrorMsg: state.ErrorMsg,
                                Firstname: state.Firstname,
                                Lastname: state.Lastname,
                                Office: state.Office,
                                Phone: state.Phone,
                                PhoneCC: state.PhoneCC,
                                Title: state.Title,
                                Username: state.Username,
                            });
                        }], ["children", Interop_reactApi.Children.toArray([map((d_1) => MuiHelpers_createElement(MenuItem, [["value", d_1.Uuid], ["children", d_1.Name]]), d)])]])])]]));
                    }
                    else {
                        return empty_1();
                    }
                })))),
            })]),
        }), errorBanner(state.ErrorMsg, () => {
            updateState({
                Company: state.Company,
                Department: state.Department,
                Email: state.Email,
                ErrorMsg: void 0,
                Firstname: state.Firstname,
                Lastname: state.Lastname,
                Office: state.Office,
                Phone: state.Phone,
                PhoneCC: state.PhoneCC,
                Title: state.Title,
                Username: state.Username,
            });
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["children", "Lukk"], ["onClick", (_arg3) => {
        onClose(void 0);
    }]]), MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["children", "Legg til"], ["onClick", (_arg4) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const u = (state.Username.length >= 3) ? (state.Username.length <= 16) : false;
            if (u ? (((state.Email.indexOf("@") >= 0) ? (state.Email.indexOf(".") >= 0) : false) ? (state.Email.length >= 6) : false) : false) {
                const matchValue = [state.Office, state.Department];
                if (matchValue[0] == null) {
                    updateState({
                        Company: state.Company,
                        Department: state.Department,
                        Email: state.Email,
                        ErrorMsg: "Velg kontor",
                        Firstname: state.Firstname,
                        Lastname: state.Lastname,
                        Office: state.Office,
                        Phone: state.Phone,
                        PhoneCC: state.PhoneCC,
                        Title: state.Title,
                        Username: state.Username,
                    });
                    return Promise.resolve();
                }
                else if (matchValue[1] == null) {
                    updateState({
                        Company: state.Company,
                        Department: state.Department,
                        Email: state.Email,
                        ErrorMsg: "Velg avdeling",
                        Firstname: state.Firstname,
                        Lastname: state.Lastname,
                        Office: state.Office,
                        Phone: state.Phone,
                        PhoneCC: state.PhoneCC,
                        Title: state.Title,
                        Username: state.Username,
                    });
                    return Promise.resolve();
                }
                else {
                    const departmentUuid = matchValue[1];
                    const officeUuid = matchValue[0];
                    const req = new BasicAuth_User("", state.Username, state.Firstname, state.Lastname, state.Title, state.Email, state.Phone, state.PhoneCC, departmentUuid, officeUuid, false);
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: BasicAuth_User$reflection,
                        })(req);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: BasicAuth_User$reflection,
                        });
                        return Fetch_tryPost_5760677E("/api/basicauth/user", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: BasicAuth_User$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg1) => {
                        const res = _arg1;
                        if (res.tag === 1) {
                            const err = res.fields[0];
                            let pattern_matching_result;
                            if (err.tag === 2) {
                                if ((err.fields[0].status) === 409) {
                                    pattern_matching_result = 0;
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                            }
                            else {
                                pattern_matching_result = 1;
                            }
                            switch (pattern_matching_result) {
                                case 0: {
                                    updateState({
                                        Company: state.Company,
                                        Department: state.Department,
                                        Email: state.Email,
                                        ErrorMsg: "Det eksisterer allerede en bruker med dette brukernavnet",
                                        Firstname: state.Firstname,
                                        Lastname: state.Lastname,
                                        Office: state.Office,
                                        Phone: state.Phone,
                                        PhoneCC: state.PhoneCC,
                                        Title: state.Title,
                                        Username: state.Username,
                                    });
                                    return Promise.resolve();
                                }
                                case 1: {
                                    let pattern_matching_result_1;
                                    if (err.tag === 2) {
                                        if ((err.fields[0].status) === 400) {
                                            pattern_matching_result_1 = 0;
                                        }
                                        else {
                                            pattern_matching_result_1 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                    switch (pattern_matching_result_1) {
                                        case 0: {
                                            updateState({
                                                Company: state.Company,
                                                Department: state.Department,
                                                Email: state.Email,
                                                ErrorMsg: "Ugyldig selskap/kontor/avdeling. Noe kan være fjernet. Vennligst last inn siden på nytt.",
                                                Firstname: state.Firstname,
                                                Lastname: state.Lastname,
                                                Office: state.Office,
                                                Phone: state.Phone,
                                                PhoneCC: state.PhoneCC,
                                                Title: state.Title,
                                                Username: state.Username,
                                            });
                                            return Promise.resolve();
                                        }
                                        case 1: {
                                            updateState({
                                                Company: state.Company,
                                                Department: state.Department,
                                                Email: state.Email,
                                                ErrorMsg: "Kunne ikke opprette bruker. Vennligst prøv igjen senere.",
                                                Firstname: state.Firstname,
                                                Lastname: state.Lastname,
                                                Office: state.Office,
                                                Phone: state.Phone,
                                                PhoneCC: state.PhoneCC,
                                                Title: state.Title,
                                                Username: state.Username,
                                            });
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            onClose(res.fields[0]);
                            return Promise.resolve();
                        }
                    }));
                }
            }
            else if (!u) {
                updateState({
                    Company: state.Company,
                    Department: state.Department,
                    Email: state.Email,
                    ErrorMsg: "Ugyldig brukernavn",
                    Firstname: state.Firstname,
                    Lastname: state.Lastname,
                    Office: state.Office,
                    Phone: state.Phone,
                    PhoneCC: state.PhoneCC,
                    Title: state.Title,
                    Username: state.Username,
                });
                return Promise.resolve();
            }
            else {
                updateState({
                    Company: state.Company,
                    Department: state.Department,
                    Email: state.Email,
                    ErrorMsg: "Ugyldig e-post",
                    Firstname: state.Firstname,
                    Lastname: state.Lastname,
                    Office: state.Office,
                    Phone: state.Phone,
                    PhoneCC: state.PhoneCC,
                    Title: state.Title,
                    Username: state.Username,
                });
                return Promise.resolve();
            }
        }));
        pr.then();
    }]])])]])])]]);
}

export function DeleteForm(deleteFormInputProps) {
    const onClose = deleteFormInputProps.onClose;
    const x = deleteFormInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505({
        ErrorMsg: void 0,
    });
    const updateState = patternInput[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(false);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Slett bruker"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([createElement("span", {
            children: toText(printf("Bekreft at du ønsker å slette brukeren %s (%s %s)"))(x.Username)(x.Firstname)(x.Lastname),
        }), errorBanner(patternInput[0].ErrorMsg, () => {
            updateState({
                ErrorMsg: void 0,
            });
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg3) => {
        onClose(false);
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Slett"], ["onClick", (_arg4) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const requestPath = toText(printf("/api/basicauth/user/%s"))(x.Uuid);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: () => string_type,
                })("");
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryDelete_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 1) {
                    const err = res.fields[0];
                    let pattern_matching_result;
                    if (err.tag === 2) {
                        if ((err.fields[0].status) === 401) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                    switch (pattern_matching_result) {
                        case 0: {
                            updateState({
                                ErrorMsg: "Du har ikke de nødvendige rettighetene til å slette denne brukeren.",
                            });
                            return Promise.resolve();
                        }
                        case 1: {
                            let pattern_matching_result_1;
                            if (err.tag === 2) {
                                if ((err.fields[0].status) === 404) {
                                    pattern_matching_result_1 = 0;
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                            switch (pattern_matching_result_1) {
                                case 0: {
                                    updateState({
                                        ErrorMsg: "Fant ikke brukeren som skal slettes. Kan allerede være slettet.",
                                    });
                                    return Promise.resolve();
                                }
                                case 1: {
                                    updateState({
                                        ErrorMsg: "Kunne ikke slette bruker. Vennligst prøv igjen senere.",
                                    });
                                    return Promise.resolve();
                                }
                            }
                        }
                    }
                }
                else {
                    const x_1 = res.fields[0];
                    if (x_1.Result === "success") {
                        onClose(true);
                        return Promise.resolve();
                    }
                    else {
                        updateState({
                            ErrorMsg: x_1.Message,
                        });
                        return Promise.resolve();
                    }
                }
            }));
        }));
        pr.then();
    }]])])]])])]]);
}

function view(model, dispatch) {
    return createElement("main", {
        style: {
            marginTop: 3 + "rem",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(Snackbar_1, [["open", equals(model.SnackState, new Snackbar(0))], ["onClose", (_arg33, v) => {
            dispatch(new Message(8, new Snackbar(4)));
        }], ["autoHideDuration", 6000], ["anchorOrigin", {
            vertical: "bottom",
            horizontal: "center",
        }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg2) => {
            dispatch(new Message(8, new Snackbar(4)));
        }], ["severity", "success"], ["variant", "filled"], ["children", "Lagret!"]])]])), delay(() => append_1(singleton(MuiHelpers_createElement(Snackbar_1, [["open", equals(model.SnackState, new Snackbar(1))], ["onClose", (_arg33_1, v_1) => {
            if (!equals(model.SnackState, new Snackbar(0))) {
                dispatch(new Message(8, new Snackbar(4)));
            }
        }], ["autoHideDuration", 4000], ["anchorOrigin", {
            vertical: "bottom",
            horizontal: "center",
        }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg4) => {
            dispatch(new Message(8, new Snackbar(4)));
        }], ["severity", "info"], ["variant", "filled"], ["children", "Lagrer endringer"]])]])), delay(() => {
            let matchValue_1;
            return append_1(singleton(MuiHelpers_createElement(Snackbar_1, [["open", (model.SnackState.tag === 5) ? true : false], ["onClose", (_arg33_2, v_2) => {
                if (!equals(model.SnackState, new Snackbar(0))) {
                    dispatch(new Message(8, new Snackbar(4)));
                }
            }], ["autoHideDuration", 10000], ["anchorOrigin", {
                vertical: "bottom",
                horizontal: "center",
            }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg6) => {
                dispatch(new Message(8, new Snackbar(4)));
            }], ["severity", "error"], ["variant", "filled"], ["children", (matchValue_1 = model.SnackState, (matchValue_1.tag === 5) ? toText(printf("Lagring feilet: %s"))(matchValue_1.fields[0]) : "Lagring feilet")]])]])), delay(() => append_1(singleton(createElement("div", {
                style: {},
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("div", {
                    style: {},
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["children", react.createElement(Add, {})], ["size", "small"], ["onClick", (_arg7) => {
                        dispatch(new Message(9, !model.ShowAdd));
                    }]])]),
                })), delay(() => append_1(singleton(MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["variant", "head"], ["children", "Brukernavn"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["children", "Fornavn"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["children", "Etternavn"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["children", "E-post"]]), MuiHelpers_createElement(TableCell, [])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((x_3) => MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", x_3.Username]]), MuiHelpers_createElement(TableCell, [["children", x_3.Firstname]]), MuiHelpers_createElement(TableCell, [["children", x_3.Lastname]]), MuiHelpers_createElement(TableCell, [["children", x_3.Email]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (x_3.Activated ? singleton(addTooltip("Brukeren er aktivert", createElement("span", {
                    style: {
                        color: "#9a9a9a",
                    },
                    className: "fas fa-check-circle",
                }))) : singleton(addTooltip("Brukeren er ikke aktivert", createElement("span", {
                    style: {
                        color: "#9a9a9a",
                    },
                    className: "fas fa-times-circle",
                }))))))))]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg8) => {
                    dispatch(new Message(10, x_3));
                }], ["children", react.createElement(Delete, {})]])])]])])]]), sortBy((x_1) => x_1.Username, model.Users, {
                    Compare: (x_2, y) => comparePrimitives(x_2, y),
                }))])]])])]])), delay(() => ((model.Users.length === 0) ? singleton(createElement("span", {
                    children: ["Ingen brukere"],
                })) : empty_1()))))))))),
            })), delay(() => append_1(model.ShowAdd ? singleton(createElement(AddForm, {
                companies: model.Companies,
                offices: model.Offices,
                departments: model.Departments,
                onClose: (user) => {
                    if (user != null) {
                        dispatch(new Message(4, user));
                    }
                    dispatch(new Message(9, false));
                },
            })) : empty_1(), delay(() => {
                const matchValue_2 = model.DeleteForm;
                if (matchValue_2 != null) {
                    const d = matchValue_2;
                    return singleton(createElement(DeleteForm, {
                        x: d,
                        onClose: (didDelete) => {
                            if (didDelete) {
                                dispatch(new Message(6, d));
                            }
                            dispatch(new Message(10, void 0));
                        },
                    }));
                }
                else {
                    return empty_1();
                }
            }))))));
        })))))))),
    });
}

export function ConfigureUsers() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchUsers(dispatch);
        fetchCompanies(dispatch);
        fetchOffices(dispatch);
        fetchDepartments(dispatch);
    });
    return view(patternInput[0], dispatch);
}

