import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Helpers_IdName, Helpers_ResultMessage$reflection, Helpers_IdName$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPut_5760677E, Fetch_tryPost_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { sortBy, map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_reactElement, MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Nonconformity_fetchCategories } from "../Promises.js";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { loadingIndicatorSmall, insertMaterialTableNorwegianLocalization, snackbarError } from "../ViewHelpers.js";
import { StyleImports_useTheme } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import material$002Dtable from "material-table";
import Icon from "@material-ui/core/Icon";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";

class Model extends Record {
    constructor(Categories, AddForm, DeleteForm, ErrorMsg) {
        super();
        this.Categories = Categories;
        this.AddForm = AddForm;
        this.DeleteForm = DeleteForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("NonconformityCategory.Model", [], Model, () => [["Categories", option_type(array_type(Helpers_IdName$reflection()))], ["AddForm", bool_type], ["DeleteForm", option_type(Helpers_IdName$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CategoriesResponse", "AddResponse", "UpdateResponse", "DeleteResponse", "ToggleAdd", "ToggleDelete", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("NonconformityCategory.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdName$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdName$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_IdName$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_IdName$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_IdName$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_IdName$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", option_type(Helpers_IdName$reflection())]], []]);
}

function init() {
    return new Model(void 0, false, void 0, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                const err = res_1.fields[0];
                return new Model(model.Categories, model.AddForm, model.DeleteForm, (err.tag === 2) ? (((err.fields[0].status) === 400) ? "Ugyldig avvikskategori navn. Må være mellom 1 og 63 tegn." : "Kunne ikke legge til avvikskategori") : "Kunne ikke legge til avvikskategori");
            }
            else {
                const x_1 = res_1.fields[0];
                return new Model((matchValue = model.Categories, (matchValue != null) ? append([x_1], matchValue) : [x_1]), false, model.DeleteForm, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Categories, model.AddForm, model.DeleteForm, "Kunne ikke oppdatere avvikskategori");
            }
            else {
                const x_2 = res_2.fields[0];
                return new Model((matchValue_1 = model.Categories, (matchValue_1 != null) ? map((y_3) => {
                    if (y_3.Id === x_2.Id) {
                        return x_2;
                    }
                    else {
                        return y_3;
                    }
                }, matchValue_1) : [x_2]), model.AddForm, model.DeleteForm, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Categories, model.AddForm, model.DeleteForm, "Kunne ikke slette avvikskategori. Vennligst prøv igjen senere.");
            }
            else {
                const x_3 = res_3.fields[0];
                if (x_3.Result === "success") {
                    const matchValue_2 = [model.Categories, model.DeleteForm];
                    let pattern_matching_result, c, d;
                    if (matchValue_2[0] != null) {
                        if (matchValue_2[1] != null) {
                            pattern_matching_result = 0;
                            c = matchValue_2[0];
                            d = matchValue_2[1];
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                    switch (pattern_matching_result) {
                        case 0: {
                            return new Model(c.filter((c$0027) => (c$0027.Id !== d.Id)), model.AddForm, void 0, model.ErrorMsg);
                        }
                        case 1: {
                            return model;
                        }
                    }
                }
                else {
                    return new Model(model.Categories, model.AddForm, model.DeleteForm, x_3.Message);
                }
            }
        }
        case 4: {
            return new Model(model.Categories, msg.fields[0], model.DeleteForm, model.ErrorMsg);
        }
        case 5: {
            return new Model(model.Categories, model.AddForm, msg.fields[0], model.ErrorMsg);
        }
        case 6: {
            return new Model(model.Categories, model.AddForm, model.DeleteForm, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Categories, model.AddForm, model.DeleteForm, "Kunne ikke laste avvikskategorier");
            }
            else {
                return new Model(res.fields[0], model.AddForm, model.DeleteForm, model.ErrorMsg);
            }
        }
    }
}

function tryAdd(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(x);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        });
        return Fetch_tryPost_5760677E("/api/nonconformity/category", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdName$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function tryUpdate(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(x);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        });
        return Fetch_tryPut_5760677E("/api/nonconformity/category", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdName$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
}

function tryDelete(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/nonconformity/category/%i"))(x);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: () => string_type,
            })("");
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryDelete_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function AddForm(addFormInputProps) {
    const dispatch = addFormInputProps.dispatch;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const name = patternInput[0];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        dispatch(new Message(4, false));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til avvikskategori"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            width: 300,
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["autoFocus", true], ["label", "Navn"], ["required", true], ["size", "small"], ["variant", "outlined"], ["onChange", (e) => {
            patternInput[1](Browser_Types_Event__Event_get_Value(e));
        }], ["fullWidth", true], ["defaultValue", name], ["style", {
            marginTop: 5,
            marginBottom: 5,
        }]])]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        dispatch(new Message(4, false));
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", name.length === 0], ["children", "Legg til"], ["onClick", (_arg3) => {
        if (name.length > 0) {
            tryAdd(new Helpers_IdName(-1, name), dispatch);
        }
    }]])])]])])]]);
}

function DeleteForm(deleteFormInputProps) {
    const dispatch = deleteFormInputProps.dispatch;
    const x = deleteFormInputProps.x;
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        dispatch(new Message(5, void 0));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Slett avvikskategori"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogContentText, [["children", toText(printf("Er du sikker på at du ønsker å slette avvikskategorien \"%s\"?"))(x.Name)]])])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Nei"], ["onClick", (_arg2) => {
        dispatch(new Message(5, void 0));
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Ja"], ["onClick", (_arg3) => {
        tryDelete(x.Id, dispatch);
    }]])])]])])]]);
}

export function NonconformityCategories() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        Nonconformity_fetchCategories((arg) => {
            dispatch(new Message(0, arg));
        });
    });
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: 3 + "rem",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model_1.ErrorMsg, () => {
            dispatch(new Message(6));
        })), delay(() => append_1(model_1.AddForm ? singleton(createElement(AddForm, {
            dispatch: dispatch,
        })) : empty_1(), delay(() => {
            let matchValue, df;
            return append_1((matchValue = model_1.DeleteForm, (matchValue != null) ? ((df = matchValue, singleton(createElement(DeleteForm, {
                x: df,
                dispatch: dispatch,
            })))) : ((empty_1()))), delay(() => {
                let value_27;
                const matchValue_1 = model_1.Categories;
                if (matchValue_1 != null) {
                    const x = matchValue_1;
                    const theme = StyleImports_useTheme();
                    return singleton(MuiHelpers_reactElement(material$002Dtable, createObj(ofArray([["style", {
                        backgroundColor: theme.palette.background.paper,
                        maxWidth: 1000,
                        padding: 2 + "vw",
                        width: 90 + "%",
                    }], ["title", "Avvikskategori"], ["data", Array.from(ofArray(sortBy((y) => y.Name, x, {
                        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
                    })))], ["columns", [{
                        title: "Navn",
                        field: "Name",
                    }]], ["editable", {
                        onRowUpdate: (delegateArg0, delegateArg1) => tryUpdate(delegateArg0, dispatch),
                    }], ["actions", [createObj(ofArray([(value_27 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBox, {})])]]), ["icon", () => value_27]), ["tooltip", "Legg til"], ["isFreeAction", true], ["onClick", (delegateArg0_1, delegateArg1_1) => {
                        dispatch(new Message(4, !model_1.AddForm));
                    }]])), (arg_1) => {
                        let value_35;
                        return createObj(ofArray([(value_35 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutline, {})])]]), ["icon", () => value_35]), ["tooltip", "Slett"], ["onClick", (delegateArg0_2, delegateArg1_2) => {
                            dispatch(new Message(5, arg_1));
                        }]]));
                    }]], insertMaterialTableNorwegianLocalization(), ["options", {
                        grouping: false,
                        search: false,
                        pageSize: (x.length > 5) ? 10 : 5,
                    }]]))));
                }
                else {
                    return singleton(loadingIndicatorSmall());
                }
            }));
        })))))))),
    });
}

