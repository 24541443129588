import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, string_type, int32_type, option_type, array_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ProcessModule_Process$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { saveManagementPrefView, saveManagementPrefProcess, loadManagementPref } from "../LocalStorage.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, singleton, empty } from "../.fable/fable-library.3.2.4/List.js";
import { int32ToString, comparePrimitives, equals, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { printf, interpolate, toText } from "../.fable/fable-library.3.2.4/String.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { op_UnaryNegation_Int32 } from "../.fable/fable-library.3.2.4/Int32.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/icons/Menu";
import { breadcrumbTypography } from "../Navbar.js";
import Drawer from "@material-ui/core/Drawer";
import { snackbarError, loadingIndicatorCenteredSmall, RotationAngle, rotatingChevronButton } from "../ViewHelpers.js";
import Divider from "@material-ui/core/Divider";
import Apps from "@material-ui/icons/Apps";
import AccountTree from "@material-ui/icons/AccountTree";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { sortBy, map } from "../.fable/fable-library.3.2.4/Array.js";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ProcessTree } from "./ProcessTree.js";
import { ProcessMap } from "./ProcessMap.js";

export class View extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Map", "Tree"];
    }
}

export function View$reflection() {
    return union_type("Management.View", [], View, () => [[], []]);
}

class Model extends Record {
    constructor(ManagementProcesses, SelectedProcess, SelectedView, ErrorMsg) {
        super();
        this.ManagementProcesses = ManagementProcesses;
        this.SelectedProcess = SelectedProcess;
        this.SelectedView = SelectedView;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Management.Model", [], Model, () => [["ManagementProcesses", option_type(array_type(ProcessModule_Process$reflection()))], ["SelectedProcess", option_type(int32_type)], ["SelectedView", View$reflection()], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProcessesResponse", "SelectProcess", "SetView", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Management.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProcessModule_Process$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProcessModule_Process$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", int32_type]], [["Item", View$reflection()]], []]);
}

function init() {
    let matchValue;
    return new Model(void 0, void 0, (matchValue = loadManagementPref(), (matchValue.tag === 0) ? ((matchValue.fields[0].View === 2) ? (new View(1)) : (new View(0))) : (new View(0))), void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1, pid, _arg1;
    switch (msg.tag) {
        case 1: {
            const x_1 = msg.fields[0] | 0;
            saveManagementPrefProcess(x_1);
            return new Model(model.ManagementProcesses, x_1, model.SelectedView, model.ErrorMsg);
        }
        case 2: {
            const x_2 = msg.fields[0];
            saveManagementPrefView((x_2.tag === 1) ? 2 : 1);
            return new Model(model.ManagementProcesses, model.SelectedProcess, x_2, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.ManagementProcesses, model.SelectedProcess, model.SelectedView, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ManagementProcesses, model.SelectedProcess, model.SelectedView, "Noe gikk galt. Kunne ikke laste prosesser.");
            }
            else {
                const x = res.fields[0];
                return new Model(x, (matchValue = loadManagementPref(), (matchValue.tag === 0) ? ((matchValue_1 = matchValue.fields[0].Process, (matchValue_1 != null) ? ((pid = (matchValue_1 | 0), (_arg1 = x.some((y) => (y.Id === pid)), _arg1 ? pid : (void 0)))) : (void 0))) : (void 0)), model.SelectedView, model.ErrorMsg);
            }
        }
    }
}

function fetchManagementProcesses(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProcessModule_Process$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/process/management", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProcessModule_Process$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let arg10, arg10_1;
    let o;
    const theme = arg;
    const styles = StyleCreator$1_$ctor();
    const root = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "flex"]));
    const appBar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["zIndex", 20], ["width", toText(interpolate("calc(100vw - %P()px)", [70]))], ["left", 70], ["boxShadow", "none"], ["borderBottom", (((1 + "px ") + "solid") + " ") + "#dedede"]]));
    const appBarShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", (arg10 = ((250 + 70) | 0), toText(printf("calc(100%% - %ipx)"))(arg10))], ["marginLeft", 250 + 70], ["left", 0]]));
    const toolbar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["backgroundColor", "#F5F5F5"], ["color", "#313131"]]));
    const menuButton = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginRight", theme.spacing(2)], ["color", "#2F4F4F"]]));
    const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "none"]));
    const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 250], ["flexShrink", 0]]));
    const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["overflowY", "hidden"], ["width", 250], ["left", 70]]));
    const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, toList(delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["alignItems", "center"]), delay(() => append(singleton_1(["padding", theme.spacing(0, 1)]), delay(() => append(Object.entries(theme.mixins.toolbar), delay(() => singleton_1(["justifyContent", "flex-end"]))))))))))));
    o = {
        appBar: appBar,
        appBarShift: appBarShift,
        content: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", op_UnaryNegation_Int32(250)], ["width", "calc(100vw - 30px)"], ["minWidth", 400], ["backgroundColor", "#fff"], ["overflowY", "auto"], ["height", 100 + "vh"]])),
        contentShift: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", 0], ["width", (arg10_1 = ((250 + 30) | 0), toText(printf("calc(100vw - %ipx)"))(arg10_1))]])),
        drawer: drawer,
        drawerHeader: drawerHeader,
        drawerPaper: drawerPaper,
        hide: hide,
        menuButton: menuButton,
        menuEntry: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["padding", 16], ["paddingBottom", 8], ["paddingTop", 8]])),
        root: root,
        toolbar: toolbar,
    };
    return Object.assign({}, o);
});

function view(model, dispatch) {
    const s = useStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setDrawerOpen = patternInput[1];
    const drawerOpen = patternInput[0];
    return createElement("div", {
        className: s.root,
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CssBaseline, []), MuiHelpers_createElement(AppBar, [["className", drawerOpen ? toText(printf("%s %s"))(s.appBar)(s.appBarShift) : s.appBar], ["position", "absolute"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Toolbar, [["className", s.toolbar], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["className", drawerOpen ? toText(printf("%s %s"))(s.menuButton)(s.hide) : s.menuButton], ["color", "inherit"], ["edge", "start"], ["onClick", (_arg1) => {
            setDrawerOpen(!drawerOpen);
        }], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Menu, {})])]])]]), breadcrumbTypography("Ledelsessystem")])]])])]]), MuiHelpers_createElement(Drawer, [["className", s.drawer], ["classes.paper", s.drawerPaper], ["variant", "persistent"], ["anchor", "left"], ["open", drawerOpen], ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: s.drawerHeader,
            children: Interop_reactApi.Children.toArray([rotatingChevronButton(new RotationAngle(3), () => {
                setDrawerOpen(false);
            })]),
        }), MuiHelpers_createElement(Divider, [["style", {
            width: 100 + "%",
        }]]), createElement("div", {
            style: {
                display: "flex",
                justifyContent: "space-evenly",
                paddingTop: 5,
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, toList(delay(() => append(singleton_1(["size", "small"]), delay(() => append(equals(model.SelectedView, new View(0)) ? singleton_1(["color", "primary"]) : empty_1(), delay(() => append(singleton_1(["children", react.createElement(Apps, {})]), delay(() => singleton_1(["onClick", (_arg3) => {
                dispatch(new Message(2, new View(0)));
            }])))))))))), MuiHelpers_createElement(IconButton, toList(delay(() => append(singleton_1(["size", "small"]), delay(() => append(equals(model.SelectedView, new View(1)) ? singleton_1(["color", "primary"]) : empty_1(), delay(() => append(singleton_1(["children", react.createElement(AccountTree, {})]), delay(() => singleton_1(["onClick", (_arg4) => {
                dispatch(new Message(2, new View(1)));
            }]))))))))))]),
        }), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(ListSubheader, [["children", "Prosesser"]])), delay(() => {
            const matchValue = model.ManagementProcesses;
            return (matchValue != null) ? singleton_1(map((y_2) => {
                let matchValue_1, p_1;
                return MuiHelpers_createElement(ListItem, [["selected", (matchValue_1 = model.SelectedProcess, (matchValue_1 != null) ? ((matchValue_1 === y_2.Id) ? ((p_1 = (matchValue_1 | 0), true)) : false) : false)], ["button", true], ["key", "hms"], ["onClick", (_arg5) => {
                    dispatch(new Message(1, y_2.Id));
                }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", (y_2.RowPrefix.length > 0) ? toText(printf("%s - %s"))(y_2.RowPrefix)(y_2.Name) : y_2.Name]])])]]);
            }, sortBy((y) => y.RowPos, matchValue, {
                Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
            }))) : singleton_1(loadingIndicatorCenteredSmall());
        }))))))]])])]]), createElement("main", {
            className: drawerOpen ? toText(printf("%s %s"))(s.content)(s.contentShift) : s.content,
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", {
                className: s.drawerHeader,
            })), delay(() => append(singleton_1(snackbarError(model.ErrorMsg, () => {
                dispatch(new Message(3));
            })), delay(() => {
                const matchValue_2 = model.SelectedProcess;
                if (matchValue_2 != null) {
                    const p_2 = matchValue_2 | 0;
                    return singleton_1(createElement("div", {
                        style: {},
                        key: int32ToString(p_2),
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((model.SelectedView.tag === 1) ? singleton_1(createElement(ProcessTree, {
                            processId: p_2,
                        })) : singleton_1(createElement(ProcessMap, {
                            processId: p_2,
                        }))))))),
                    }));
                }
                else {
                    return singleton_1(createElement("div", {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            minHeight: 70 + "vh",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            style: {
                                fontSize: 5 + "rem",
                                marginBottom: 10,
                                color: "#dedede",
                            },
                            className: "far fa-eye-slash",
                        }), createElement("span", {
                            style: {
                                fontSize: 0.8 + "rem",
                            },
                            children: "Ingen prosess valgt. Velg i sidemenyen.",
                        })]),
                    }));
                }
            })))))))),
        })]),
    });
}

export function Management() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchManagementProcesses(dispatch);
    });
    return view(patternInput[0], dispatch);
}

