import { Union } from "../.fable/fable-library.3.2.4/Types.js";
import { union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { replace, split } from "../.fable/fable-library.3.2.4/String.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import { year, now } from "../.fable/fable-library.3.2.4/Date.js";

export class ProjectStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Planning", "InProcess", "DelayedStart", "Overtime", "Completed"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Pågår";
            }
            case 2: {
                return "Forsinket start";
            }
            case 3: {
                return "På overtid";
            }
            case 4: {
                return "Avsluttet";
            }
            default: {
                return "Under planlegging";
            }
        }
    }
}

export function ProjectStatus$reflection() {
    return union_type("Shared.ProjectStatus", [], ProjectStatus, () => [[], [], [], [], []]);
}

export function ProjectStatus__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 2;
        }
        case 2: {
            return 3;
        }
        case 3: {
            return 4;
        }
        case 4: {
            return 5;
        }
        default: {
            return 1;
        }
    }
}

export function ProjectStatus__ColorCode(this$) {
    switch (this$.tag) {
        case 1: {
            return "#38afff";
        }
        case 2: {
            return "orange";
        }
        case 3: {
            return "#ff2e2e";
        }
        case 4: {
            return "#00d131";
        }
        default: {
            return "#858585";
        }
    }
}

export function ProjectStatus_FromInt_Z524259A4(psId) {
    switch (psId) {
        case 2: {
            return new ProjectStatus(1);
        }
        case 3: {
            return new ProjectStatus(2);
        }
        case 4: {
            return new ProjectStatus(3);
        }
        case 5: {
            return new ProjectStatus(4);
        }
        default: {
            return new ProjectStatus(0);
        }
    }
}

export function ProjectStatus__BackgroundColor(this$) {
    switch (this$.tag) {
        case 1: {
            return "rgb(56,175,255, 0.7)";
        }
        case 2: {
            return "rgb(227,169,7,0.7)";
        }
        case 3: {
            return "rgb(255,46,46, 0.7)";
        }
        case 4: {
            return "rgb(0,209,49,0.7)";
        }
        default: {
            return "rgba(230, 230, 230, 0.7)";
        }
    }
}

export class GrossAreaUnit extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SquareCentimeters", "MetersSquared", "KiloMetersSquared"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 2: {
                return "km²";
            }
            case 0: {
                return "cm²";
            }
            default: {
                return "m²";
            }
        }
    }
}

export function GrossAreaUnit$reflection() {
    return union_type("Shared.GrossAreaUnit", [], GrossAreaUnit, () => [[], [], []]);
}

export function GrossAreaUnit__ToInt(this$) {
    switch (this$.tag) {
        case 2: {
            return 2;
        }
        case 0: {
            return 3;
        }
        default: {
            return 1;
        }
    }
}

export function GrossAreaUnit_FromInt_Z524259A4(x) {
    switch (x) {
        case 2: {
            return new GrossAreaUnit(2);
        }
        case 3: {
            return new GrossAreaUnit(0);
        }
        default: {
            return new GrossAreaUnit(1);
        }
    }
}

export class RoleCategory extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Generic", "Company", "Project"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Selskap";
            }
            case 2: {
                return "Prosjekt";
            }
            default: {
                return "Ingen spesiell";
            }
        }
    }
}

export function RoleCategory$reflection() {
    return union_type("Shared.RoleCategory", [], RoleCategory, () => [[], [], []]);
}

export function RoleCategory__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 2;
        }
        case 2: {
            return 3;
        }
        default: {
            return 1;
        }
    }
}

export function RoleCategory_FromInt_Z524259A4(x) {
    switch (x) {
        case 2: {
            return new RoleCategory(1);
        }
        case 3: {
            return new RoleCategory(2);
        }
        default: {
            return new RoleCategory(0);
        }
    }
}

export function RoleCategory_AsArray() {
    return [new RoleCategory(0), new RoleCategory(1), new RoleCategory(2)];
}

export class Currency extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NOK", "USD", "SEK", "DKK", "ISK", "EUR", "GBP", "CAD", "CHF", "RUB", "JPY", "CNY"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "USD";
            }
            case 2: {
                return "SEK";
            }
            case 3: {
                return "DKK";
            }
            case 4: {
                return "ISK";
            }
            case 5: {
                return "EUR";
            }
            case 6: {
                return "GBP";
            }
            case 7: {
                return "CAD";
            }
            case 8: {
                return "CHF";
            }
            case 9: {
                return "RUB";
            }
            case 10: {
                return "JPY";
            }
            case 11: {
                return "CNY";
            }
            default: {
                return "NOK";
            }
        }
    }
}

export function Currency$reflection() {
    return union_type("Shared.Currency", [], Currency, () => [[], [], [], [], [], [], [], [], [], [], [], []]);
}

export function Currency__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 2;
        }
        case 2: {
            return 3;
        }
        case 3: {
            return 4;
        }
        case 4: {
            return 5;
        }
        case 5: {
            return 6;
        }
        case 6: {
            return 7;
        }
        case 7: {
            return 8;
        }
        case 8: {
            return 9;
        }
        case 9: {
            return 10;
        }
        case 10: {
            return 11;
        }
        case 11: {
            return 12;
        }
        default: {
            return 1;
        }
    }
}

export function Currency__ToLongName(this$) {
    switch (this$.tag) {
        case 1: {
            return "Amerikanske dollar";
        }
        case 2: {
            return "Svenske kroner";
        }
        case 3: {
            return "Danske kroner";
        }
        case 4: {
            return "Islandske kroner";
        }
        case 5: {
            return "Euro";
        }
        case 6: {
            return "Britiske pund";
        }
        case 7: {
            return "Kanadiske dollar";
        }
        case 8: {
            return "Sveitsiske franc";
        }
        case 9: {
            return "Russiske rubler";
        }
        case 10: {
            return "Japanske yen";
        }
        case 11: {
            return "Kinesiske yuan";
        }
        default: {
            return "Norske kroner";
        }
    }
}

export function Currency_FromInt_Z524259A4(x) {
    switch (x) {
        case 1: {
            return new Currency(0);
        }
        case 2: {
            return new Currency(1);
        }
        case 3: {
            return new Currency(2);
        }
        case 4: {
            return new Currency(3);
        }
        case 5: {
            return new Currency(4);
        }
        case 6: {
            return new Currency(5);
        }
        case 7: {
            return new Currency(6);
        }
        case 8: {
            return new Currency(7);
        }
        case 9: {
            return new Currency(8);
        }
        case 10: {
            return new Currency(9);
        }
        case 11: {
            return new Currency(10);
        }
        case 12: {
            return new Currency(11);
        }
        default: {
            return new Currency(0);
        }
    }
}

export class UpdateModes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Add", "Update"];
    }
}

export function UpdateModes$reflection() {
    return union_type("Shared.UpdateModes", [], UpdateModes, () => [[], []]);
}

export function UpdateModes__toString(this$) {
    if (this$.tag === 1) {
        return "Update";
    }
    else {
        return "Add";
    }
}

export class HmsControlSort extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DateDesc", "DateAsc", "NumDesc", "NumAsc", "ProjectDesc", "ProjectAsc", "CompanyDesc", "CompanyAsc", "ReportTypeDesc", "ReportTypeAsc"];
    }
}

export function HmsControlSort$reflection() {
    return union_type("Shared.HmsControlSort", [], HmsControlSort, () => [[], [], [], [], [], [], [], [], [], []]);
}

export function HmsControlSort__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 1;
        }
        case 2: {
            return 2;
        }
        case 3: {
            return 3;
        }
        case 4: {
            return 4;
        }
        case 5: {
            return 5;
        }
        case 6: {
            return 6;
        }
        case 7: {
            return 7;
        }
        case 8: {
            return 8;
        }
        case 9: {
            return 9;
        }
        default: {
            return 0;
        }
    }
}

export function HmsControlSort_FromInt_Z524259A4(x) {
    switch (x) {
        case 1: {
            return new HmsControlSort(1);
        }
        case 2: {
            return new HmsControlSort(2);
        }
        case 3: {
            return new HmsControlSort(3);
        }
        case 4: {
            return new HmsControlSort(4);
        }
        case 5: {
            return new HmsControlSort(5);
        }
        case 6: {
            return new HmsControlSort(6);
        }
        case 7: {
            return new HmsControlSort(7);
        }
        case 8: {
            return new HmsControlSort(8);
        }
        case 9: {
            return new HmsControlSort(9);
        }
        default: {
            return new HmsControlSort(0);
        }
    }
}

export class LimitValues_Codes_InputType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HmsRuh", "HmsSja", "HmsH1", "HmsH2"];
    }
}

export function LimitValues_Codes_InputType$reflection() {
    return union_type("Shared.LimitValues.Codes.InputType", [], LimitValues_Codes_InputType, () => [[], [], [], []]);
}

export function LimitValues_Codes_inputToLimitCode(inputType) {
    switch (inputType.tag) {
        case 1: {
            return "HMSSJA";
        }
        case 2: {
            return "HMSH1";
        }
        case 3: {
            return "HMSH2";
        }
        default: {
            return "HMSRUH";
        }
    }
}

export function LimitValues_Codes_codeToInput(limitCode) {
    switch (limitCode) {
        case "HMSRUH": {
            return new LimitValues_Codes_InputType(0);
        }
        case "HMSSJA": {
            return new LimitValues_Codes_InputType(1);
        }
        case "HMSH1": {
            return new LimitValues_Codes_InputType(2);
        }
        default: {
            return new LimitValues_Codes_InputType(3);
        }
    }
}

export function LimitValues_Codes_inputToTitle(inputType) {
    switch (inputType.tag) {
        case 1: {
            return "HMS registrering - SJA";
        }
        case 2: {
            return "HMS registrering - H1";
        }
        case 3: {
            return "HMS registrering - H2";
        }
        default: {
            return "HMS registrering - RUH";
        }
    }
}

export function LimitValues_Codes_codeToTitle(limitCode) {
    switch (limitCode) {
        case "HMSRUH": {
            return "HMS registrering - RUH";
        }
        case "HMSSJA": {
            return "HMS registrering - SJA";
        }
        case "HMSH1": {
            return "HMS registrering - H1";
        }
        case "HMSH2": {
            return "HMS registrering - H2";
        }
        default: {
            return "Ukjent";
        }
    }
}

export class Notification_NotificationTopic extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HMS", "Revision", "Waste", "Custom", "NonConformityAction"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Dokumentrevisjon";
            }
            case 2: {
                return "Avfall";
            }
            case 4: {
                return "Avvikstiltak";
            }
            case 3: {
                return "Egendefinert";
            }
            default: {
                return "HMS";
            }
        }
    }
}

export function Notification_NotificationTopic$reflection() {
    return union_type("Shared.Notification.NotificationTopic", [], Notification_NotificationTopic, () => [[], [], [], [], []]);
}

export function Notification_NotificationTopic__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 2;
        }
        case 2: {
            return 3;
        }
        case 4: {
            return 6;
        }
        case 3: {
            return 100;
        }
        default: {
            return 1;
        }
    }
}

export function Notification_NotificationTopic_FromInt_Z524259A4(x) {
    switch (x) {
        case 1: {
            return new Notification_NotificationTopic(0);
        }
        case 2: {
            return new Notification_NotificationTopic(1);
        }
        case 3: {
            return new Notification_NotificationTopic(2);
        }
        case 6: {
            return new Notification_NotificationTopic(4);
        }
        default: {
            return new Notification_NotificationTopic(3);
        }
    }
}

export class Notification_NotificationType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SMS", "Email"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Epost" : "SMS";
    }
}

export function Notification_NotificationType$reflection() {
    return union_type("Shared.Notification.NotificationType", [], Notification_NotificationType, () => [[], []]);
}

export function Notification_NotificationType__ToInt(this$) {
    if (this$.tag === 1) {
        return 2;
    }
    else {
        return 1;
    }
}

export function Notification_NotificationType_FromInt_Z524259A4(x) {
    if (x === 1) {
        return new Notification_NotificationType(0);
    }
    else {
        return new Notification_NotificationType(1);
    }
}

export class FormActions extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["View", "Edit"];
    }
}

export function FormActions$reflection() {
    return union_type("Shared.FormActions", [], FormActions, () => [[], []]);
}

export function tryParsePeriod(periodString) {
    const x = split(replace(periodString, " ", ""), ["-"], null, 0);
    if (x.length === 1) {
        try {
            return parse(x[0], 511, false, 32) | 0;
        }
        catch (matchValue) {
            return 0;
        }
    }
    else {
        const y = x[x.length - 1];
        if ((y.toLocaleLowerCase() === "dd") ? true : (y.toLocaleLowerCase() === "nå")) {
            let copyOfStruct = now();
            return year(copyOfStruct) | 0;
        }
        else {
            try {
                return parse(y, 511, false, 32) | 0;
            }
            catch (matchValue_1) {
                return 0;
            }
        }
    }
}

