import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, unit_type, bool_type, class_type, string_type, option_type, array_type, int32_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { UserTimelineEntry$reflection, Helpers_Date__ToLocalDate, Helpers_TimePeriod$reflection, Helpers_TimePeriod, Helpers_Date_FromDateTime_7F9DDECF, ProjectVacationRequest$reflection, ProjectVacationRequest, UserProjectVacation$reflection, UserAllocation$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { parse, addMonths, now } from "../.fable/fable-library.3.2.4/Date.js";
import { FSharpMap__Add, FSharpMap__Remove, FSharpMap__ContainsKey, empty } from "../.fable/fable-library.3.2.4/Map.js";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { collect, sortBy as sortBy_1, sortByDescending, map, tryFind, append } from "../.fable/fable-library.3.2.4/Array.js";
import { comparePrimitives, equals, createObj, uncurry, numberHash } from "../.fable/fable-library.3.2.4/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { defaultArg, some } from "../.fable/fable-library.3.2.4/Option.js";
import { ofArray, toArray, singleton, empty as empty_1 } from "../.fable/fable-library.3.2.4/List.js";
import { printf, interpolate, toText } from "../.fable/fable-library.3.2.4/String.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { createElement } from "react";
import * as react from "react";
import { PageElements_inlineCheckbox, snackbarError, drawAllocation, loadingIndicatorSmall } from "../ViewHelpers.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { empty as empty_2, singleton as singleton_1, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import { useReact_useReducer_2B9E6EA0, useReact_useEffect_Z101E1A95, useReact_useEffectOnce_3A5B6456, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import TableHead from "@material-ui/core/TableHead";
import { fromUtcToLocalDate } from "../Utils.js";
import { TimelineModule_timelineGroup, TimelineModule_timelineTooltip, TimelineModule_timelineOptions, TimelineModule_timelineItem } from "../Fable.Vis.Timeline.js";
import { Timeline } from "vis-timeline/standalone";
import { Dimensions_DefaultPadding } from "../Design.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { nb } from "date-fns/locale";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { addDays } from "date-fns";
import date$002Dfns from "@date-io/date-fns";

class Presentation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["List", "Timeline"];
    }
}

function Presentation$reflection() {
    return union_type("CompanyAllocation.Presentation", [], Presentation, () => [[], []]);
}

class Model extends Record {
    constructor(CompanyId, Users, CurrentPresentation, ErrorMsg, PeriodStart, PeriodEnd, FilterOverAllocated, UserAllocations, ExpandedUserAlloc) {
        super();
        this.CompanyId = (CompanyId | 0);
        this.Users = Users;
        this.CurrentPresentation = CurrentPresentation;
        this.ErrorMsg = ErrorMsg;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.FilterOverAllocated = FilterOverAllocated;
        this.UserAllocations = UserAllocations;
        this.ExpandedUserAlloc = ExpandedUserAlloc;
    }
}

function Model$reflection() {
    return record_type("CompanyAllocation.Model", [], Model, () => [["CompanyId", int32_type], ["Users", option_type(array_type(UserAllocation$reflection()))], ["CurrentPresentation", Presentation$reflection()], ["ErrorMsg", option_type(string_type)], ["PeriodStart", class_type("System.DateTime")], ["PeriodEnd", class_type("System.DateTime")], ["FilterOverAllocated", bool_type], ["UserAllocations", array_type(UserProjectVacation$reflection())], ["ExpandedUserAlloc", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int32_type, unit_type])]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UsersResponse", "UserAllocationResponse", "UpdatePeriodStart", "UpdatePeriodEnd", "ToggleFilterOverAllocated", "SetPresentation", "DismissError", "ToggleUserExpanded"];
    }
}

function Message$reflection() {
    return union_type("CompanyAllocation.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserAllocation$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserAllocation$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserProjectVacation$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserProjectVacation$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [], [["Item", Presentation$reflection()]], [], [["Item", int32_type]]]);
}

function init(compId) {
    let copyOfStruct;
    return new Model(compId, void 0, new Presentation(0), void 0, now(), (copyOfStruct = now(), addMonths(copyOfStruct, 3)), false, [], empty());
}

function update(model, msg) {
    switch (msg.tag) {
        case 2: {
            return new Model(model.CompanyId, void 0, model.CurrentPresentation, model.ErrorMsg, msg.fields[0], model.PeriodEnd, model.FilterOverAllocated, [], model.ExpandedUserAlloc);
        }
        case 3: {
            return new Model(model.CompanyId, void 0, model.CurrentPresentation, model.ErrorMsg, model.PeriodStart, msg.fields[0], model.FilterOverAllocated, [], model.ExpandedUserAlloc);
        }
        case 4: {
            return new Model(model.CompanyId, model.Users, model.CurrentPresentation, model.ErrorMsg, model.PeriodStart, model.PeriodEnd, !model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAlloc);
        }
        case 5: {
            return new Model(model.CompanyId, model.Users, msg.fields[0], model.ErrorMsg, model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAlloc);
        }
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                return new Model(model.CompanyId, model.Users, model.CurrentPresentation, model.ErrorMsg, model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, Array_distinctBy((e) => e.userid, append([res_1.fields[0]], model.UserAllocations), {
                    Equals: (x_2, y) => (x_2 === y),
                    GetHashCode: (x_2) => numberHash(x_2),
                }), model.ExpandedUserAlloc);
            }
            else {
                return new Model(model.CompanyId, model.Users, model.CurrentPresentation, "Kunne ikke laste prosjekter og ferie for ansatt. Vennligst prøv igjen senere.", model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAlloc);
            }
        }
        case 6: {
            return new Model(model.CompanyId, model.Users, model.CurrentPresentation, void 0, model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAlloc);
        }
        case 7: {
            const uid = msg.fields[0] | 0;
            return new Model(model.CompanyId, model.Users, model.CurrentPresentation, model.ErrorMsg, model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, FSharpMap__ContainsKey(model.ExpandedUserAlloc, uid) ? FSharpMap__Remove(model.ExpandedUserAlloc, uid) : FSharpMap__Add(model.ExpandedUserAlloc, uid, void 0));
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.CompanyId, model.Users, model.CurrentPresentation, "Noe gikk galt. Kunne ikke laste ansatte.", model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAlloc);
            }
            else {
                return new Model(model.CompanyId, res.fields[0], model.CurrentPresentation, model.ErrorMsg, model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAlloc);
            }
        }
    }
}

function getProjectsAndVacationsForUser(periodStart, periodEnd, userId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const vacationsRequest = new ProjectVacationRequest(userId, periodStart, periodEnd);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: ProjectVacationRequest$reflection,
            })(vacationsRequest);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserProjectVacation$reflection,
            });
            return Fetch_tryPost_5760677E("/api/user/allocation/period", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserProjectVacation$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function getCompanyUserAllocations(periodStart, periodEnd, compId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const timePeriod = new Helpers_TimePeriod(Helpers_Date_FromDateTime_7F9DDECF(periodStart), Helpers_Date_FromDateTime_7F9DDECF(periodEnd));
        const requestPath = toText(interpolate("/api/users/allocated/company/%P()", [compId]));
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: Helpers_TimePeriod$reflection,
            })(timePeriod);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserAllocation$reflection()),
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserAllocation$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function renderRow(model, dispatch, u) {
    let ref;
    const expanded = FSharpMap__ContainsKey(model.ExpandedUserAlloc, u.user.Id);
    let drawDetailsPanel;
    const notBorderedCell = (s) => MuiHelpers_createElement(TableCell, [["style", {
        borderStyle: "none",
    }], ["children", s]]);
    const _arg1 = tryFind((e) => (e.userid === u.user.Id), model.UserAllocations);
    if (_arg1 != null) {
        const children_3 = singleton((ref = _arg1, MuiHelpers_createElement(Table, [["size", "small"], ["style", {
            width: "initial",
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((x) => MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell("På ferie"), notBorderedCell(toText(interpolate("fra %P()", [Helpers_Date__ToLocalDate(x.PeriodStart)]))), notBorderedCell(toText(interpolate("til %P()", [Helpers_Date__ToLocalDate(x.PeriodEnd)])))])]]), ref.vacation), map((x_1) => MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell(toText(printf("På prosjekt %s som %s(%d%%)"))(x_1.project)(x_1.role)(x_1.allocated)), notBorderedCell(toText(interpolate("fra %P()", [Helpers_Date__ToLocalDate(x_1.period.PeriodStart)]))), notBorderedCell(toText(interpolate("til %P()", [Helpers_Date__ToLocalDate(x_1.period.PeriodEnd)])))])]]), ref.projects)])]])])]])));
        drawDetailsPanel = createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_3)),
        });
    }
    else {
        drawDetailsPanel = loadingIndicatorSmall();
    }
    const performExpand = (_arg2) => {
        if (!model.UserAllocations.some((e_1) => (e_1.userid === u.user.Id))) {
            getProjectsAndVacationsForUser(model.PeriodStart, model.PeriodEnd, u.user.Id, dispatch);
        }
        dispatch(new Message(7, u.user.Id));
    };
    return toArray(ofArray([MuiHelpers_createElement(TableRow, [["hover", true], ["style", {
        cursor: "pointer",
    }], ["onClick", performExpand], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["style", {
        width: 160,
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", "Vis detaljer"], ["children", MuiHelpers_createElement(IconButton, [["onClick", (e_2) => {
        e_2.stopPropagation();
        performExpand();
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(ChevronRight, {
        style: createObj(toList(delay(() => append_1(singleton_1(["transitionDuration", 300 + "ms"]), delay(() => append_1(singleton_1(["transitionProperty", "transform"]), delay(() => (expanded ? singleton_1(["transform", ("rotate(" + 90) + "deg)"]) : empty_2())))))))),
    })])]])])]])]]), MuiHelpers_createElement(Tooltip, [["title", "Gå til bruker"], ["children", MuiHelpers_createElement(IconButton, [["onClick", (e_3) => {
        e_3.stopPropagation();
    }], ["href", toText(interpolate("/user/%P()", [u.user.Id]))], ["children", MuiHelpers_createElement(Avatar, [["src", toText(interpolate("/api/user/profilepicture/thumbnail/%P()", [u.user.Id]))], ["alt", toText(interpolate("%P() %P()", [u.user.Firstname, u.user.Lastname]))]])]])]])])]]), MuiHelpers_createElement(TableCell, [["children", toText(interpolate("%P() %P()", [u.user.Firstname, u.user.Lastname]))]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([drawAllocation(u.allocated)])]]), MuiHelpers_createElement(TableCell, [["children", (u.overlapsproj ? u.overlapsvac : false) ? "Ferie og Prosjekt" : (u.overlapsvac ? "Ferie" : (u.overlapsproj ? "Prosjekt" : ""))]])])]]), MuiHelpers_createElement(TableRow, [["style", createObj(toList(delay(() => append_1(singleton_1(["padding", 0]), delay(() => ((!expanded) ? singleton_1(["display", "none"]) : empty_2()))))))], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["colSpan", 4], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Collapse, [["collapsedHeight", 0], ["in", expanded], ["timeout", "auto"], ["children", drawDetailsPanel]])])]])])]])]));
}

class RowType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Name", "Allocated"];
    }
}

function RowType$reflection() {
    return union_type("CompanyAllocation.RowType", [], RowType, () => [[], []]);
}

class SortByType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Asc", "Desc"];
    }
}

function SortByType$reflection() {
    return union_type("CompanyAllocation.SortByType", [], SortByType, () => [[["Item", RowType$reflection()]], [["Item", RowType$reflection()]]]);
}

function DrawAllocatedTable(drawAllocatedTableInputProps) {
    const dispatch = drawAllocatedTableInputProps.dispatch;
    const model = drawAllocatedTableInputProps.model;
    const patternInput = useFeliz_React__React_useState_Static_1505(new SortByType(0, new RowType(0)));
    const sortBy = patternInput[0];
    const setSortBy = patternInput[1];
    const sortArrow = (rowId) => react.createElement(ArrowDownward, {
        style: createObj(toList(delay(() => append_1(singleton_1(["fontSize", 15 + "px"]), delay(() => append_1(singleton_1(["position", "absolute"]), delay(() => append_1(singleton_1(["transitionDuration", 300 + "ms"]), delay(() => append_1(singleton_1(["transitionProperty", "transform"]), delay(() => ((sortBy.tag === 1) ? (equals(sortBy.fields[0], rowId) ? singleton_1(["transform", ("rotate(" + 180) + "deg)"]) : singleton_1(["display", "none"])) : (equals(sortBy.fields[0], rowId) ? singleton_1(["transform", ("rotate(" + 0) + "deg)"]) : singleton_1(["display", "none"])))))))))))))),
    });
    return MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["style", {
        border: (((0 + "px ") + "none") + " ") + "",
    }], ["children", ""]]), MuiHelpers_createElement(TableCell, [["style", {
        border: (((0 + "px ") + "none") + " ") + "",
        cursor: "pointer",
        userSelect: "none",
    }], ["children", Interop_reactApi.Children.toArray(["Navn", sortArrow(new RowType(0))])], ["onClick", (_arg1) => {
        if (sortBy.tag === 1) {
            if (equals(sortBy.fields[0], new RowType(0))) {
                setSortBy(new SortByType(0, new RowType(0)));
            }
            else {
                setSortBy(new SortByType(0, new RowType(0)));
            }
        }
        else if (equals(sortBy.fields[0], new RowType(0))) {
            setSortBy(new SortByType(1, new RowType(0)));
        }
        else {
            setSortBy(new SortByType(0, new RowType(0)));
        }
    }]]), MuiHelpers_createElement(TableCell, [["style", {
        border: (((0 + "px ") + "none") + " ") + "",
        cursor: "pointer",
        userSelect: "none",
    }], ["children", Interop_reactApi.Children.toArray(["Allokert", sortArrow(new RowType(1))])], ["onClick", (_arg2) => {
        if (sortBy.tag === 1) {
            if (equals(sortBy.fields[0], new RowType(1))) {
                setSortBy(new SortByType(0, new RowType(1)));
            }
            else {
                setSortBy(new SortByType(0, new RowType(1)));
            }
        }
        else if (equals(sortBy.fields[0], new RowType(1))) {
            setSortBy(new SortByType(1, new RowType(1)));
        }
        else {
            setSortBy(new SortByType(0, new RowType(1)));
        }
    }]]), MuiHelpers_createElement(TableCell, [["style", {
        border: (((0 + "px ") + "none") + " ") + "",
        userSelect: "none",
    }], ["children", "Overlapp"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let users;
        const matchValue = model.Users;
        if (matchValue != null) {
            const u = matchValue;
            const users_1 = model.FilterOverAllocated ? u.filter((e) => (e.allocated < 100)) : u;
            return (users_1.length === 0) ? singleton_1("Fant ingen brukere") : singleton_1(map((u_1) => renderRow(model, dispatch, u_1), (users = users_1, (sortBy.tag === 1) ? ((sortBy.fields[0].tag === 1) ? sortByDescending((x_6) => x_6.allocated, users, {
                Compare: (x_7, y_3) => comparePrimitives(x_7, y_3),
            }) : sortByDescending((x_4) => toText(interpolate("%P() %P()", [x_4.user.Firstname, x_4.user.Lastname])), users, {
                Compare: (x_5, y_2) => comparePrimitives(x_5, y_2),
            })) : ((sortBy.fields[0].tag === 1) ? sortBy_1((x_2) => x_2.allocated, users, {
                Compare: (x_3, y_1) => comparePrimitives(x_3, y_1),
            }) : sortBy_1((x) => toText(interpolate("%P() %P()", [x.user.Firstname, x.user.Lastname])), users, {
                Compare: (x_1, y) => comparePrimitives(x_1, y),
            })))));
        }
        else {
            return singleton_1("Laster...");
        }
    }))))]])])]]);
}

export function createProjectItem(groupId, userId, project) {
    const handleEmptyText = (project.Project.length === 0) ? "Mangler prosjektnavn" : project.Project;
    const projectTooltip = toText(interpolate("\n            \u003cdiv\u003e\n            \u003cp\u003e%P()\u003c/p\u003e\n            \u003cspan\u003e%P()\u003c/span\u003e\n            \u003cbr\u003e\n            \u003cspan\u003eFra %P() til %P()\n            \u003c/span\u003e\n            \u003c/div\u003e\n        ", [handleEmptyText, project.Role, fromUtcToLocalDate(project.PeriodStart), fromUtcToLocalDate(project.PeriodEnd)]));
    return TimelineModule_timelineItem([["id", toText(interpolate("project-%P()-role-%P()-user-%P()", [project.ProjectId, project.RoleId, userId]))], ["start", parse(project.PeriodStart)], ["end", parse(project.PeriodEnd)], ["group", groupId], ["content", handleEmptyText], ["title", projectTooltip], ["style", toText(interpolate("background-color: %P();border-color:%P(); color: white;", ["#2d3a48", "#2d3a48"]))]]);
}

function createVacationItem(userId, vacation) {
    const projectTooltip = toText(interpolate("\n            \u003cdiv\u003e\n            \u003cp\u003eFerie\u003c/p\u003e\n            \u003cspan\u003eFra %P() til %P()\n            \u003c/span\u003e\n            \u003c/div\u003e\n        ", [fromUtcToLocalDate(vacation.PeriodStart), fromUtcToLocalDate(vacation.PeriodEnd)]));
    return TimelineModule_timelineItem([["id", toText(interpolate("user-%P()-vacation-%P()", [userId, vacation.Id]))], ["start", parse(vacation.PeriodStart)], ["end", parse(vacation.PeriodEnd)], ["group", toText(interpolate("vacation-%P()", [userId]))], ["content", "Ferie"], ["title", projectTooltip], ["style", toText(interpolate("background-color: %P(); border-color:%P(); color: %P();", ["#ffcf01", "#2d3a48", "#2d3a48"]))]]);
}

function DrawTimeline(drawTimelineInputProps) {
    const maxDate = drawTimelineInputProps.maxDate;
    const minDate = drawTimelineInputProps.minDate;
    const companyId = drawTimelineInputProps.companyId;
    const timeline = useReact_useRef_1505(null);
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const users = patternInput[0];
    const projectGroupId = (projectId, userId_1) => toText(interpolate("project-%P()-user-%P()", [projectId, userId_1]));
    useReact_useEffectOnce_3A5B6456(() => {
        const t = new Timeline(document.getElementById("activity-timeline"), new Array(0), void 0, TimelineModule_timelineOptions([["zoomMin", 36000000], ["groupOrder", "title"], ["selectable", false], ["min", addMonths(minDate, -3)], ["max", addMonths(maxDate, 3)], ["tooltip", TimelineModule_timelineTooltip([["followMouse", true], ["overflowMethod", "cap"], ["delay", 200]])]]));
        timeline.current = t;
    });
    useReact_useEffect_Z101E1A95(() => {
        if (users != null) {
            let patternInput_1;
            const x_1 = users;
            const allProjectSubGroups = collect((u) => map((p_1) => TimelineModule_timelineGroup([["id", projectGroupId(p_1.ProjectId, u.User.Id)], ["content", p_1.Project], ["title", p_1.Project]]), Array_distinctBy((p) => p.ProjectId, u.Projects, {
                Equals: (x_2, y) => (x_2 === y),
                GetHashCode: (x_2) => numberHash(x_2),
            })), x_1);
            const allVacationSubGroups = map((u_2) => TimelineModule_timelineGroup([["id", toText(interpolate("vacation-%P()", [u_2.User.Id]))], ["content", "Ferie"], ["title", "Ferie"]]), x_1.filter((u_1) => (u_1.Vacations.length > 0)));
            const groups = map((u_3) => {
                const projectSubGroups = map((p_3) => projectGroupId(p_3.ProjectId, u_3.User.Id), Array_distinctBy((p_2) => p_2.ProjectId, u_3.Projects, {
                    Equals: (x_3, y_1) => (x_3 === y_1),
                    GetHashCode: (x_3) => numberHash(x_3),
                }));
                const vacationSubGroups = (u_3.Vacations.length > 0) ? [toText(interpolate("vacation-%P()", [u_3.User.Id]))] : [];
                return TimelineModule_timelineGroup([["id", toText(interpolate("user-%P()", [u_3.User.Id]))], ["content", toText(interpolate("%P() %P()", [u_3.User.Firstname, u_3.User.Lastname]))], ["title", toText(interpolate("%P() %P()", [u_3.User.Firstname, u_3.User.Lastname]))], ["nestedGroups", append(projectSubGroups, vacationSubGroups)]]);
            }, x_1);
            const items = append(collect((u_4) => map((p_4) => createProjectItem(projectGroupId(p_4.ProjectId, u_4.User.Id), u_4.User.Id, p_4), u_4.Projects), x_1), collect((u_6) => map((v) => createVacationItem(u_6.User.Id, v), u_6.Vacations), x_1.filter((u_5) => (u_5.Vacations.length > 0))));
            patternInput_1 = [append(allVacationSubGroups, append(groups, allProjectSubGroups)), items];
            timeline.current.setItems(patternInput_1[1]);
            timeline.current.setGroups(patternInput_1[0]);
            timeline.current.fit();
        }
    }, [users]);
    useReact_useEffect_Z101E1A95(() => {
        timeline.current.setOptions(TimelineModule_timelineOptions([["min", minDate], ["max", maxDate]]));
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const timePeriod = new Helpers_TimePeriod(Helpers_Date_FromDateTime_7F9DDECF(minDate), Helpers_Date_FromDateTime_7F9DDECF(maxDate));
            const requestPath = toText(interpolate("/api/users/allocated/company-timeline/%P()", [companyId]));
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Helpers_TimePeriod$reflection,
                })(timePeriod);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: () => array_type(UserTimelineEntry$reflection()),
                });
                return Fetch_tryPost_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: () => array_type(UserTimelineEntry$reflection()),
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    patternInput[1](res.fields[0]);
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            }));
        }));
        pr.then();
    }, [minDate, maxDate]);
    return createElement("div", {
        id: "activity-timeline",
    });
}

export function Allocations(allocationsInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(allocationsInputProps.companyId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        getCompanyUserAllocations(model_1.PeriodStart, model_1.PeriodEnd, model_1.CompanyId, dispatch);
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            padding: Dimensions_DefaultPadding,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(snackbarError(model_1.ErrorMsg, () => {
            dispatch(new Message(6));
        })), delay(() => append_1(singleton_1(MuiHelpers_createElement(Tabs, [["value", equals(model_1.CurrentPresentation, new Presentation(0)) ? 0 : 1], ["textColor", "primary"], ["onChange", (_arg38, v) => {
            dispatch(new Message(5, (v === 0) ? (new Presentation(0)) : (new Presentation(1))));
        }], ["indicatorColor", "primary"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tab, [["label", "Tabell"]]), MuiHelpers_createElement(Tab, [["label", "Tidslinje"]])])]])), delay(() => {
            let props_5, el;
            return append_1(singleton_1(createElement("div", {
                style: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                },
                children: Interop_reactApi.Children.toArray([(props_5 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DatePicker, [["label", "Fra dato"], ["autoOk", true], ["variant", "inline"], ["value", model_1.PeriodStart], ["format", "dd MMMM yyyy"], ["onChange", (d) => {
                    const newStart = defaultArg(d, now());
                    getCompanyUserAllocations(newStart, model_1.PeriodEnd, model_1.CompanyId, dispatch);
                    dispatch(new Message(2, newStart));
                }], ["style", {
                    margin: 5,
                }]]), MuiHelpers_createElement(DatePicker, [["label", "Til dato"], ["autoOk", true], ["value", model_1.PeriodEnd], ["variant", "inline"], ["format", "dd MMMM yyyy"], ["onChange", (dto) => {
                    const newEnd = defaultArg(dto, addDays(now(), 7));
                    getCompanyUserAllocations(model_1.PeriodStart, newEnd, model_1.CompanyId, dispatch);
                    dispatch(new Message(3, newEnd));
                }], ["style", {
                    margin: 5,
                }]])])]]), (el = MuiPickersUtilsProvider, MuiHelpers_createElement(el, toList(delay(() => {
                    let value_50;
                    return append_1(singleton_1((value_50 = date$002Dfns, ["utils", value_50])), delay(() => props_5));
                }))))), PageElements_inlineCheckbox((_arg3) => {
                    dispatch(new Message(4));
                }, "Filtrer bort overallokerte", model_1.FilterOverAllocated)]),
            })), delay(() => ((model_1.CurrentPresentation.tag === 1) ? singleton_1(createElement(DrawTimeline, {
                companyId: model_1.CompanyId,
                minDate: model_1.PeriodStart,
                maxDate: model_1.PeriodEnd,
            })) : singleton_1(createElement(DrawAllocatedTable, {
                model: model_1,
                dispatch: dispatch,
            })))));
        })))))))),
    });
}

