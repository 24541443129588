import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, array_type, string_type, option_type, bool_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Helpers_IdNameCodeDesc, Helpers_IdNameCodeDesc$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, Fetch_tryPost_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { append, map } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { MuiHelpers_createElement, MuiHelpers_reactElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import material$002Dtable from "material-table";
import Icon from "@material-ui/core/Icon";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AddBox from "@material-ui/icons/AddBox";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { genericDeleteDialog, loadingIndicatorCenteredSmall, snackbarError } from "../ViewHelpers.js";

class Model extends Record {
    constructor(AddForm, DelForm, ErrorMsg, HandoverStates) {
        super();
        this.AddForm = AddForm;
        this.DelForm = DelForm;
        this.ErrorMsg = ErrorMsg;
        this.HandoverStates = HandoverStates;
    }
}

function Model$reflection() {
    return record_type("Handover.Model", [], Model, () => [["AddForm", bool_type], ["DelForm", option_type(Helpers_IdNameCodeDesc$reflection())], ["ErrorMsg", option_type(string_type)], ["HandoverStates", option_type(array_type(Helpers_IdNameCodeDesc$reflection()))]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AddHandoverState", "RemoveHandoverState", "SetDelForm", "SetError", "SetHandoverStates", "ToggleAddForm", "UpdateHandoverState"];
    }
}

function Message$reflection() {
    return union_type("Handover.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_IdNameCodeDesc$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_IdNameCodeDesc$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_IdNameCodeDesc$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_IdNameCodeDesc$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(Helpers_IdNameCodeDesc$reflection())]], [["Item", option_type(string_type)]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdNameCodeDesc$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdNameCodeDesc$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_IdNameCodeDesc$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_IdNameCodeDesc$reflection()]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init() {
    return new Model(false, void 0, void 0, void 0);
}

function update(model, msg) {
    let matchValue_1, matchValue_2, matchValue;
    switch (msg.tag) {
        case 1: {
            const resp_1 = msg.fields[0];
            if (resp_1.tag === 1) {
                return new Model(model.AddForm, model.DelForm, "Noe gikk galt. Kunne ikke deaktivere overleveringstype", model.HandoverStates);
            }
            else {
                return new Model(model.AddForm, void 0, model.ErrorMsg, (matchValue_1 = model.HandoverStates, (matchValue_1 != null) ? matchValue_1.filter((x) => (x.id !== resp_1.fields[0].id)) : (void 0)));
            }
        }
        case 2: {
            return new Model(model.AddForm, msg.fields[0], model.ErrorMsg, model.HandoverStates);
        }
        case 3: {
            return new Model(model.AddForm, model.DelForm, msg.fields[0], model.HandoverStates);
        }
        case 4: {
            const resp_2 = msg.fields[0];
            if (resp_2.tag === 1) {
                return new Model(model.AddForm, model.DelForm, "Noe gikk galt. Kunne ikke hente overleveringstyper", model.HandoverStates);
            }
            else {
                return new Model(model.AddForm, model.DelForm, model.ErrorMsg, resp_2.fields[0]);
            }
        }
        case 5: {
            return new Model(!model.AddForm, model.DelForm, model.ErrorMsg, model.HandoverStates);
        }
        case 6: {
            const resp_3 = msg.fields[0];
            if (resp_3.tag === 1) {
                return new Model(model.AddForm, model.DelForm, "Noe gikk galt. Kunne ikke oppdatere overleveringstype", model.HandoverStates);
            }
            else {
                const updatedState = resp_3.fields[0];
                return new Model(model.AddForm, model.DelForm, model.ErrorMsg, (matchValue_2 = model.HandoverStates, (matchValue_2 != null) ? map((state_3) => {
                    if (state_3.id === updatedState.id) {
                        return updatedState;
                    }
                    else {
                        return state_3;
                    }
                }, matchValue_2) : [updatedState]));
            }
        }
        default: {
            const resp = msg.fields[0];
            if (resp.tag === 1) {
                return new Model(model.AddForm, model.DelForm, "Noe gikk galt. Kunne ikke legge til overleveringstype", model.HandoverStates);
            }
            else {
                return new Model(false, model.DelForm, model.ErrorMsg, (matchValue = model.HandoverStates, (matchValue != null) ? append(matchValue, [resp.fields[0]]) : (void 0)));
            }
        }
    }
}

function addHandoverState(entry, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameCodeDesc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameCodeDesc$reflection,
        });
        return Fetch_tryPost_5760677E("/api/handedoverstate/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameCodeDesc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function deleteHandoverState(state, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameCodeDesc$reflection,
        })(state);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameCodeDesc$reflection,
        });
        return Fetch_tryPost_5760677E("/api/handedoverstate/remove", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameCodeDesc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
}

function fetchHandoverStates(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdNameCodeDesc$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/handedoverstate/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdNameCodeDesc$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(4, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function updateHandoverState(newState, oldState, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        if (((newState.name !== oldState.name) ? true : (newState.code !== oldState.code)) ? true : (newState.desc !== oldState.desc)) {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Helpers_IdNameCodeDesc$reflection,
                })(newState);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_IdNameCodeDesc$reflection,
                });
                return Fetch_tryPost_5760677E("/api/handedoverstate/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_IdNameCodeDesc$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(6, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            dispatch(new Message(3, "Verdiene er de samme"));
            return Promise.resolve();
        }
    }));
}

function tableView(entries, dispatch) {
    let value_38;
    return MuiHelpers_reactElement(material$002Dtable, {
        style: {
            maxWidth: 1000,
            padding: 2 + "vw",
            width: 90 + "%",
        },
        options: {
            grouping: false,
            search: false,
        },
        title: "Overleveringstyper",
        data: Array.from(ofArray(entries)),
        columns: [{
            title: "Kode",
            field: "code",
        }, {
            title: "Status",
            field: "name",
        }, {
            title: "Beskrivelse",
            field: "desc",
        }],
        editable: {
            onRowUpdate: (delegateArg0, delegateArg1) => updateHandoverState(delegateArg0, delegateArg1, dispatch),
        },
        actions: [(arg) => {
            let value_30;
            return createObj(ofArray([(value_30 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutline, {})])]]), ["icon", () => value_30]), ["tooltip", "Deaktiver"], ["isFreeAction", false], ["onClick", (delegateArg0_1, delegateArg1_1) => {
                dispatch(new Message(2, arg));
            }]]));
        }, createObj(ofArray([(value_38 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBox, {})])]]), ["icon", () => value_38]), ["tooltip", "Legg til"], ["isFreeAction", true], ["onClick", (delegateArg0_2, delegateArg1_2) => {
            dispatch(new Message(5));
        }]]))],
    });
}

function AddFormDialog(addFormDialogInputProps) {
    const dispatch = addFormDialogInputProps.dispatch;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Helpers_IdNameCodeDesc(-1, "", "", ""));
    const setEntry = patternInput[1];
    const entry = patternInput[0];
    const content = createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["style", {
            marginBottom: 10,
        }], ["label", "Kode"], ["required", true], ["onChange", (e) => {
            setEntry(new Helpers_IdNameCodeDesc(entry.id, entry.name, Browser_Types_Event__Event_get_Value(e), entry.desc));
        }]]), MuiHelpers_createElement(TextField, [["style", {
            marginBottom: 20,
        }], ["label", "Navn"], ["required", true], ["onChange", (e_1) => {
            setEntry(new Helpers_IdNameCodeDesc(entry.id, Browser_Types_Event__Event_get_Value(e_1), entry.code, entry.desc));
        }]]), MuiHelpers_createElement(TextField, [["style", {
            marginBottom: 10,
        }], ["label", "Beskrivelse"], ["required", false], ["variant", "outlined"], ["multiline", true], ["rows", 4], ["onChange", (e_2) => {
            setEntry(new Helpers_IdNameCodeDesc(entry.id, entry.name, entry.code, Browser_Types_Event__Event_get_Value(e_2)));
        }]])]),
    });
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (delegateArg0, delegateArg1) => {
        dispatch(new Message(5));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til overtakelsestyper"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([content])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg3) => {
        dispatch(new Message(5));
    }], ["children", "Avbryt"], ["color", "primary"]]), MuiHelpers_createElement(Button, [["onClick", (_arg4) => {
        addHandoverState(new Helpers_IdNameCodeDesc(entry.id, entry.name, entry.code, entry.desc.trim()), dispatch);
    }], ["children", "Legg til"], ["color", "primary"], ["disabled", (entry.name.length === 0) ? true : (entry.code.length === 0)]])])]])])]]);
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: 3 + "rem",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(3, void 0));
        })), delay(() => {
            let matchValue;
            return append_1((matchValue = model.HandoverStates, (matchValue == null) ? singleton(loadingIndicatorCenteredSmall()) : singleton(tableView(matchValue, dispatch))), delay(() => append_1(model.AddForm ? singleton(createElement(AddFormDialog, {
                dispatch: dispatch,
            })) : empty_1(), delay(() => {
                const matchValue_1 = model.DelForm;
                if (matchValue_1 != null) {
                    const state = matchValue_1;
                    return singleton(genericDeleteDialog(() => {
                        dispatch(new Message(2, void 0));
                    }, () => deleteHandoverState(state, dispatch), []));
                }
                else {
                    return singleton(null);
                }
            }))));
        })))))),
    });
}

export function ControlView() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchHandoverStates(dispatch);
    });
    return view(patternInput[0], dispatch);
}

