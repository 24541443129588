import { Record, Union } from "./.fable/fable-library.3.2.4/Types.js";
import { SearchInput, UserSearchResult$reflection, User$reflection, DocumentSearchEntry$reflection } from "./Shared/ApiDataTypes.js";
import { class_type, record_type, int32_type, bool_type, string_type, union_type, option_type, array_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { Cmd_OfPromise_either, Cmd_none, Cmd_batch, Cmd_ofSub } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofArray, empty, singleton } from "./.fable/fable-library.3.2.4/List.js";
import { Search_searchDocuments, Search_searchUsers } from "./Promises.js";
import { empty as empty_1, toList, singleton as singleton_1, delay, toArray } from "./.fable/fable-library.3.2.4/Seq.js";
import { Bootstrap_Input_Option, Bootstrap_Input_text, Bootstrap_Button_Option, Bootstrap_Button_ButtonClass, Bootstrap_Button_button, errorHeader, PageElements_filenameToIcon, Bootstrap_Table_table, loadingIndicatorSmall } from "./ViewHelpers.js";
import * as react from "react";
import { map } from "./.fable/fable-library.3.2.4/Array.js";
import { toString } from "./.fable/fable-library.3.2.4/Date.js";
import { join, printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { Helpers_classList } from "./.fable/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";

export class SearchModel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DocumentModel", "UserModel"];
    }
}

export function SearchModel$reflection() {
    return union_type("Search.SearchModel", [], SearchModel, () => [[["Item", option_type(array_type(DocumentSearchEntry$reflection()))]], [["Item", option_type(array_type(User$reflection()))]]]);
}

export class Model extends Record {
    constructor(SearchTerm, ProcessingRequest, SearchMdl, ErrorMsg, RequestId) {
        super();
        this.SearchTerm = SearchTerm;
        this.ProcessingRequest = ProcessingRequest;
        this.SearchMdl = SearchMdl;
        this.ErrorMsg = ErrorMsg;
        this.RequestId = (RequestId | 0);
    }
}

export function Model$reflection() {
    return record_type("Search.Model", [], Model, () => [["SearchTerm", string_type], ["ProcessingRequest", bool_type], ["SearchMdl", SearchModel$reflection()], ["ErrorMsg", option_type(string_type)], ["RequestId", int32_type]]);
}

export class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateSearch", "GotoDocumentSearch", "GotoUserSearch", "TrySearch", "DocumentsResponse", "DocumentsError", "UsersResponse", "UsersError"];
    }
}

export function Message$reflection() {
    return union_type("Search.Message", [], Message, () => [[["Item", string_type]], [], [], [], [["Item", array_type(DocumentSearchEntry$reflection())]], [["Item", class_type("System.Exception")]], [["Item", UserSearchResult$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export function enterSub() {
    return Cmd_ofSub((dispatch) => {
        window.addEventListener("keypress", (ev) => {
            try {
                if (ev.keyCode === 13) {
                    dispatch(new Message(3));
                }
            }
            catch (matchValue_1) {
            }
        });
    });
}

export function init() {
    return [new Model("", false, new SearchModel(0, []), void 0, 1), Cmd_batch(singleton(enterSub()))];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1: {
            return [new Model(model.SearchTerm, model.ProcessingRequest, new SearchModel(0, []), model.ErrorMsg, model.RequestId), Cmd_none()];
        }
        case 2: {
            return [new Model(model.SearchTerm, model.ProcessingRequest, new SearchModel(1, []), model.ErrorMsg, model.RequestId), Cmd_none()];
        }
        case 3: {
            if (model.SearchTerm.length > 0) {
                const searchInput = new SearchInput(model.SearchTerm, model.RequestId + 1);
                if (model.SearchMdl.tag === 1) {
                    return [new Model(model.SearchTerm, true, new SearchModel(1, void 0), model.ErrorMsg, model.RequestId + 1), Cmd_OfPromise_either((entry_1) => Search_searchUsers(entry_1), searchInput, (arg0_2) => (new Message(6, arg0_2)), (arg0_3) => (new Message(7, arg0_3)))];
                }
                else {
                    return [new Model(model.SearchTerm, true, new SearchModel(0, void 0), model.ErrorMsg, model.RequestId + 1), Cmd_OfPromise_either((entry) => Search_searchDocuments(entry), searchInput, (arg0) => (new Message(4, arg0)), (arg0_1) => (new Message(5, arg0_1)))];
                }
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 4: {
            if (model.SearchMdl.tag === 0) {
                return [new Model(model.SearchTerm, false, new SearchModel(0, msg.fields[0]), model.ErrorMsg, model.RequestId), Cmd_none()];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 5: {
            return [new Model(model.SearchTerm, false, model.SearchMdl, "Noe gikk galt. Kunne ikke gjennomføre dokumentsøk.", model.RequestId), Cmd_none()];
        }
        case 6: {
            const resp_1 = msg.fields[0];
            if (model.SearchMdl.tag === 1) {
                if (resp_1.RequestId === model.RequestId) {
                    return [new Model(model.SearchTerm, false, new SearchModel(1, resp_1.Users), model.ErrorMsg, model.RequestId), Cmd_none()];
                }
                else {
                    return [model, Cmd_none()];
                }
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 7: {
            return [new Model(model.SearchTerm, false, model.SearchMdl, "Noe gikk galt. Kunne ikke gjennomføre brukersøk.", model.RequestId), Cmd_none()];
        }
        default: {
            return [new Model(msg.fields[0], model.ProcessingRequest, model.SearchMdl, model.ErrorMsg, model.RequestId), Cmd_none()];
        }
    }
}

export function drawDocumentSearch(docs) {
    return toArray(delay(() => {
        if (docs == null) {
            return singleton_1(loadingIndicatorSmall());
        }
        else {
            const entries = docs;
            return singleton_1(Bootstrap_Table_table(empty(), ofArray([react.createElement("thead", {}, react.createElement("tr", {}, react.createElement("td", {}, "Navn"), react.createElement("td", {}, "Filnavn"), react.createElement("td", {}, "Sist endret"), react.createElement("td", {
                style: {
                    maxWidth: "50px",
                },
            }))), react.createElement("tbody", {}, map((entry) => react.createElement("tr", {}, react.createElement("td", {}, entry.Filename), react.createElement("td", {}, PageElements_filenameToIcon(entry.Filename), entry.Filename), react.createElement("td", {}, toString(entry.LastChanged, "dd/MM/yyyy")), react.createElement("td", {}, react.createElement("a", {
                href: toText(printf("/management/%i"))(entry.ProcessActivityDocId),
            }, react.createElement("i", {
                className: join(" ", ["fas", "fa-eye"]),
            })))), entries))])));
        }
    }));
}

export function drawUserSearch(users) {
    return toArray(delay(() => {
        if (users == null) {
            return singleton_1(loadingIndicatorSmall());
        }
        else {
            const entries = users;
            return singleton_1(Bootstrap_Table_table(empty(), ofArray([react.createElement("thead", {}, react.createElement("tr", {}, react.createElement("td", {}, "Navn"), react.createElement("td", {}, "Selskap"), react.createElement("td", {}, "E-post"), react.createElement("td", {}, "Telefon"), react.createElement("td", {
                style: {
                    maxWidth: "50px",
                },
            }))), react.createElement("tbody", {}, map((entry) => react.createElement("tr", {}, react.createElement("td", {}, toText(printf("%s %s"))(entry.Firstname)(entry.Lastname)), react.createElement("td", {}, entry.Company), react.createElement("td", {}, entry.Email), react.createElement("td", {}, entry.Phone), react.createElement("td", {}, react.createElement("a", {
                href: toText(printf("/user/%i"))(entry.Id),
            }, react.createElement("i", {
                className: join(" ", ["fas", "fa-id-card"]),
            })))), entries))])));
        }
    }));
}

export function view(model, dispatch) {
    let matchValue_4;
    return react.createElement("div", {
        className: "mx-auto",
        style: {
            width: "50%",
        },
    }, react.createElement("div", {}, ...toList(delay(() => {
        const matchValue = model.ErrorMsg;
        if (matchValue == null) {
            return empty_1();
        }
        else {
            return singleton_1(errorHeader(matchValue));
        }
    }))), react.createElement("br", {}), react.createElement("h3", {}, toText(printf("Søk"))), react.createElement("br", {}), Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, (model.SearchMdl.tag === 0) ? (new Bootstrap_Button_ButtonClass(1)) : (new Bootstrap_Button_ButtonClass(0))), new Bootstrap_Button_Option(3, singleton(["style", {
        width: "250px",
    }])), new Bootstrap_Button_Option(2, (_arg1) => {
        dispatch(new Message(1));
    })]), singleton("Dokumenter")), Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, (model.SearchMdl.tag === 1) ? (new Bootstrap_Button_ButtonClass(1)) : (new Bootstrap_Button_ButtonClass(0))), new Bootstrap_Button_Option(3, singleton(["style", {
        width: "250px",
    }])), new Bootstrap_Button_Option(2, (_arg2) => {
        dispatch(new Message(2));
    })]), singleton("Brukere")), react.createElement("div", {
        style: {
            display: "grid",
            gridTemplateColumns: "90% 10%",
        },
    }, Bootstrap_Input_text(ofArray([new Bootstrap_Input_Option(8, ofArray([["style", {
        gridColumn: 1,
    }], Helpers_classList([["form-control", true], ["form-control-lg", true]])])), new Bootstrap_Input_Option(2, (ev) => {
        dispatch(new Message(0, Browser_Types_Event__Event_get_Value(ev)));
    }), new Bootstrap_Input_Option(5, model.SearchTerm), new Bootstrap_Input_Option(6, (model.SearchMdl.tag === 1) ? "Søk i brukere.." : "Søk i dokumenter.."), new Bootstrap_Input_Option(7, true)])), Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, new Bootstrap_Button_ButtonClass(1)), new Bootstrap_Button_Option(3, singleton(["style", {
        gridColumn: 2,
    }])), new Bootstrap_Button_Option(2, (_arg3) => {
        dispatch(new Message(3));
    })]), singleton(react.createElement("i", {
        className: join(" ", ["fas", "fa-search"]),
    })))), (matchValue_4 = model.SearchMdl, (matchValue_4.tag === 1) ? drawUserSearch(matchValue_4.fields[0]) : drawDocumentSearch(matchValue_4.fields[0])));
}

