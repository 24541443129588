import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, string_type, bool_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ProcessModule_Process$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { saveManagementPrefMapWrap, loadManagementPref } from "../LocalStorage.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { createElement } from "react";
import * as react from "react";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { sortBy, map } from "../.fable/fable-library.3.2.4/Array.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { loadingIndicatorMedium, snackbarError, PageElements_filenameToIcon } from "../ViewHelpers.js";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import WrapText from "@material-ui/icons/WrapText";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";

class Model extends Record {
    constructor(ProcessId, Processes, Scroll, ErrorMsg) {
        super();
        this.ProcessId = (ProcessId | 0);
        this.Processes = Processes;
        this.Scroll = Scroll;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ProcessMap.Model", [], Model, () => [["ProcessId", int32_type], ["Processes", option_type(array_type(ProcessModule_Process$reflection()))], ["Scroll", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProcessesResponse", "ToggleScroll", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProcessMap.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProcessModule_Process$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProcessModule_Process$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], []]);
}

function init(processId) {
    let matchValue;
    return new Model(processId, void 0, !((matchValue = loadManagementPref(), (matchValue.tag === 0) ? matchValue.fields[0].MapWrap : false)), void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const x_1 = msg.fields[0];
            saveManagementPrefMapWrap(!x_1);
            return new Model(model.ProcessId, model.Processes, x_1, model.ErrorMsg);
        }
        case 2: {
            return new Model(model.ProcessId, model.Processes, model.Scroll, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProcessId, model.Processes, model.Scroll, "Noe gikk galt. Kunne ikke laste prosesskart");
            }
            else {
                return new Model(model.ProcessId, res.fields[0], model.Scroll, model.ErrorMsg);
            }
        }
    }
}

function fetchMap(processId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/management/%i"))(processId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProcessModule_Process$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProcessModule_Process$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function DrawProcess(drawProcessInputProps) {
    const isChild = drawProcessInputProps.isChild;
    const x = drawProcessInputProps.x;
    return createElement("div", {
        style: createObj(toList(delay(() => append(isChild ? singleton(["margin", 5]) : singleton(["margin", 10]), delay(() => append(singleton(["display", "flex"]), delay(() => append(singleton(["flexDirection", "column"]), delay(() => append((x.NumChildren === 0) ? singleton(["minWidth", 250]) : empty_1(), delay(() => append(singleton(["border", (((1 + "px ") + "solid") + " ") + "gainsboro"]), delay(() => singleton(["borderRadius", 5])))))))))))))),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
            style: {
                backgroundColor: "#191970",
                padding: 5,
                ["border-top-right-radius"]: 5,
                ["border-top-left-radius"]: 5,
                color: "#FFFFFF",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((x.RowPrefix.length > 0) ? singleton(createElement("span", {
                style: {
                    marginRight: 5,
                },
                children: x.RowPrefix,
            })) : empty_1(), delay(() => singleton(createElement("span", {
                children: x.Name,
            })))))))),
        })), delay(() => append(((x.NumChildren === 0) ? (x.NumActivities === 0) : false) ? singleton(createElement("div", {
            style: {
                fontSize: 0.8 + "rem",
                fontStyle: "italic",
                padding: ((20 + "px ") + 5) + "px",
                textAlign: "center",
                margin: "auto",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Ingen aktiviteter/underprosesser definert for prosess"],
            })]),
        })) : empty_1(), delay(() => append(singleton(map((y_2) => createElement("div", {
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    padding: 5,
                    paddingLeft: 8,
                    backgroundColor: "#e6e6e6",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((y_2.RowPrefix.length > 0) ? singleton(createElement("span", {
                    style: {
                        marginRight: 5,
                    },
                    children: y_2.RowPrefix,
                })) : empty_1(), delay(() => singleton(createElement("span", {
                    children: y_2.Name,
                })))))))),
            }), MuiHelpers_createElement(List, [["style", {
                paddingTop: 0,
                paddingBottom: 4,
            }], ["dense", true], ["children", Interop_reactApi.Children.toArray([map((d) => MuiHelpers_createElement(ListItem, [["href", toText(printf("/document/%i"))(d.Id)], ["button", true], ["component", "a"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["style", {
                minWidth: 20,
            }], ["children", Interop_reactApi.Children.toArray([PageElements_filenameToIcon(d.Filename)])]]), MuiHelpers_createElement(ListItemText, [["style", {
                color: "#313131",
            }], ["primary", (d.RowPrefix.length > 0) ? toText(printf("%s - %s"))(d.RowPrefix)(d.Name) : d.Name]])])]]), y_2.Docs)])]])]),
        }), sortBy((y) => y.RowPos, x.Activities, {
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        }))), delay(() => singleton(map((p) => createElement(DrawProcess, {
            x: p,
            isChild: true,
        }), x.Children)))))))))))),
    });
}

function drawProcesses(model, dispatch, x) {
    return createElement("div", {
        style: createObj(toList(delay(() => append(singleton(["display", "flex"]), delay(() => (model.Scroll ? singleton(["overflowX", "auto"]) : singleton(["flexWrap", "wrap"]))))))),
        children: Interop_reactApi.Children.toArray([map((p) => createElement(DrawProcess, {
            x: p,
            isChild: false,
        }), sortBy((y) => y.RowPos, x, {
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        }))]),
    });
}

function displayOptions(model, dispatch) {
    const children_2 = ofArray([MuiHelpers_createElement(IconButton, toList(delay(() => append(singleton(["size", "small"]), delay(() => append(model.Scroll ? singleton(["color", "primary"]) : empty_1(), delay(() => append(singleton(["children", Interop_reactApi.Children.toArray([react.createElement(SwapHoriz, {})])]), delay(() => singleton(["onClick", (_arg1) => {
        dispatch(new Message(1, true));
    }])))))))))), MuiHelpers_createElement(IconButton, toList(delay(() => append(singleton(["size", "small"]), delay(() => append((!model.Scroll) ? singleton(["color", "primary"]) : empty_1(), delay(() => append(singleton(["children", Interop_reactApi.Children.toArray([react.createElement(WrapText, {})])]), delay(() => singleton(["onClick", (_arg2) => {
        dispatch(new Message(1, false));
    }]))))))))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            padding: 20,
            paddingBottom: 0,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(2));
        })), delay(() => {
            const matchValue = model.Processes;
            if (matchValue != null) {
                const x = matchValue;
                return append(singleton(displayOptions(model, dispatch)), delay(() => singleton(drawProcesses(model, dispatch, x))));
            }
            else {
                return singleton(createElement("div", {
                    style: {
                        display: "flex",
                        flexDirection: "column",
                        minHeight: 70 + "vh",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                    children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
                }));
            }
        })))))),
    });
}

export function ProcessMap(processMapInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(processMapInputProps.processId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchMap(model_1.ProcessId, dispatch);
    });
    return view(model_1, dispatch);
}

