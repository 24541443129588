import { Union } from "../.fable/fable-library.3.2.4/Types.js";
import { union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ofArray, singleton } from "../.fable/fable-library.3.2.4/List.js";

export class Functionality extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ViewHome", "EditHome", "EditProject", "DownloadAttestation", "DownloadReference", "DownloadReferenceAndAttest", "DeleteProject", "EditProjectRole", "EditProjectNotification", "EditProjectPermission", "EditProjectPlan", "RegisterProjectHMS", "RegisterProjectWaste", "RegisterProjectQuality", "RegisterProjectNonconformity", "CreateProject", "SetProjectRequireHMS", "SetProjectRequireWaste", "ControlHms", "ControlWaste", "DeleteProcess", "DeleteProcessActivity", "DownloadOriginalDocument", "EditActionClass", "EditBreeam", "EditBuilder", "EditCompany", "EditDocument", "EditEducationLevels", "EditEnergyClass", "EditFileStore", "EditFilingKey", "EditHandoverState", "EditHmsType", "EditInteraction", "EditProcess", "EditProcessActivity", "EditRole", "EditWasteType", "ViewDocument", "ViewProcess", "EditSkill", "EditCategories", "EditNonconformity", "ViewHmsReport", "ViewWasteReport", "EditUserPermission", "EditUserGroupPermission", "EditConfig", "EditLimitValues", "EditOneDriveSettings", "ViewManagement", "EditManagement", "ManagementRevision", "EditCompanyRoles", "EditCompanyPermission", "EditEmployeeProfile", "PrintEmployeeCV", "EditCompanyNotifications", "EditCompanyDocuments", "OverallEditProject", "OverallEditHms", "OverallDownloadDocument", "OverallEditWaste", "OverallCVPrint", "OverallEditQuality", "OverallEditCompany", "OverallEditNonconformity"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Rediger hjem";
            }
            case 2: {
                return "Rediger prosjekt";
            }
            case 3: {
                return "Last ned attester";
            }
            case 4: {
                return "Last ned referanser";
            }
            case 5: {
                return "Last ned referanser og attester";
            }
            case 6: {
                return "Slett prosjekt";
            }
            case 7: {
                return "Rediger prosjektroller";
            }
            case 8: {
                return "Rediger prosjektvarslinger";
            }
            case 9: {
                return "Rediger prosjektrettigheter";
            }
            case 10: {
                return "Rediger prosjektplan";
            }
            case 11: {
                return "Registrer HMS";
            }
            case 12: {
                return "Registrer avfall";
            }
            case 13: {
                return "Registrer kvalitet";
            }
            case 14: {
                return "Registrer avvik";
            }
            case 15: {
                return "Opprett prosjekt";
            }
            case 16: {
                return "Endre om prosjekt skal kreve HMS-registrering";
            }
            case 17: {
                return "Endre om prosjekt skal kreve sorteringsgrad-registrering";
            }
            case 39: {
                return "Se dokumenter";
            }
            case 27: {
                return "Legg til/rediger/slett dokumenter";
            }
            case 40: {
                return "Se prosesser";
            }
            case 35: {
                return "Legg til/rediger prosesser";
            }
            case 20: {
                return "Slett prosesser";
            }
            case 37: {
                return "Legg til/rediger/slett roller";
            }
            case 31: {
                return "Legg til/rediger/slett arkivnøkler";
            }
            case 23: {
                return "Legg til/rediger/slett tiltaksklasser";
            }
            case 24: {
                return "Legg til/rediger/slett breeams";
            }
            case 25: {
                return "Legg til/rediger/slett byggherrer";
            }
            case 26: {
                return "Legg til/rediger/slett selskap";
            }
            case 29: {
                return "Legg til/rediger/slett energiklasser";
            }
            case 32: {
                return "Legg til/rediger/slett overleveringsstatuser";
            }
            case 34: {
                return "Legg til/rediger/slett samspill";
            }
            case 30: {
                return "Legg til/erstatt/slett opplastede filer";
            }
            case 28: {
                return "Rediger utdanningsnivå";
            }
            case 36: {
                return "Legg til/rediger prosessaktivitet";
            }
            case 21: {
                return "Slett prosessaktivitet";
            }
            case 22: {
                return "Last ned original dokument";
            }
            case 38: {
                return "Legg til/rediger/slett avfallstyper";
            }
            case 19: {
                return "Etterkontroll sorteringsgrad";
            }
            case 41: {
                return "Rediger ferdigheter og nivå";
            }
            case 42: {
                return "Rediger kategorier";
            }
            case 43: {
                return "Rediger avvikskategorier";
            }
            case 44: {
                return "Se HMS-rapport";
            }
            case 33: {
                return "Legg til/rediger/slett HMS-typer";
            }
            case 18: {
                return "Etterkontroll HMS";
            }
            case 45: {
                return "Se sorteringsgrad-rapport";
            }
            case 46: {
                return "Gi/fjern brukerrettigheter";
            }
            case 47: {
                return "Rediger brukergrupper";
            }
            case 48: {
                return "Legg til/rediger/slett systemkonfigurasjon";
            }
            case 49: {
                return "Rediger grenseverdier";
            }
            case 50: {
                return "Rediger OneDrive/SharePoint innstillinger";
            }
            case 51: {
                return "Se ledelsessystem";
            }
            case 52: {
                return "Rediger ledesessystem";
            }
            case 53: {
                return "Last opp revisjoner i ledelsessystem";
            }
            case 54: {
                return "Rediger selskapsroller";
            }
            case 55: {
                return "Rediger selskapsrettigheter";
            }
            case 56: {
                return "Rediger profil til ansatt";
            }
            case 57: {
                return "Skriv ut CV til ansatt";
            }
            case 58: {
                return "Rediger varsling på selskapsnivå";
            }
            case 59: {
                return "Rediger dokumenter på selskapsnivå";
            }
            case 60: {
                return "Overordnet prosjekt redigering";
            }
            case 61: {
                return "Overordnet HMS redigering";
            }
            case 62: {
                return "Overordnet dokument nedlasting";
            }
            case 63: {
                return "Overordnet sorteringsgrad redigering";
            }
            case 64: {
                return "Utskrift av brukeres CV på tvers av selskap";
            }
            case 65: {
                return "Overordnet kvalitet redigering";
            }
            case 66: {
                return "Overordnet selskap redigering";
            }
            case 67: {
                return "Overordnet avviksredigering";
            }
            default: {
                return "Vis hjem";
            }
        }
    }
}

export function Functionality$reflection() {
    return union_type("PermissionMapping.Functionality", [], Functionality, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function Functionality__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 101;
        }
        case 2: {
            return 1001;
        }
        case 3: {
            return 1002;
        }
        case 4: {
            return 1003;
        }
        case 5: {
            return 1004;
        }
        case 6: {
            return 1005;
        }
        case 7: {
            return 1006;
        }
        case 8: {
            return 1007;
        }
        case 9: {
            return 1008;
        }
        case 10: {
            return 1009;
        }
        case 11: {
            return 1011;
        }
        case 13: {
            return 1017;
        }
        case 14: {
            return 1018;
        }
        case 15: {
            return 1010;
        }
        case 16: {
            return 1012;
        }
        case 12: {
            return 1013;
        }
        case 17: {
            return 1014;
        }
        case 39: {
            return 2001;
        }
        case 27: {
            return 2002;
        }
        case 40: {
            return 2003;
        }
        case 35: {
            return 2004;
        }
        case 37: {
            return 2006;
        }
        case 31: {
            return 2010;
        }
        case 23: {
            return 2012;
        }
        case 24: {
            return 2014;
        }
        case 25: {
            return 2016;
        }
        case 26: {
            return 2018;
        }
        case 29: {
            return 2022;
        }
        case 32: {
            return 2023;
        }
        case 34: {
            return 2026;
        }
        case 30: {
            return 2040;
        }
        case 28: {
            return 2042;
        }
        case 20: {
            return 2043;
        }
        case 36: {
            return 2045;
        }
        case 21: {
            return 2046;
        }
        case 22: {
            return 2047;
        }
        case 38: {
            return 2048;
        }
        case 19: {
            return 2049;
        }
        case 41: {
            return 2050;
        }
        case 42: {
            return 2051;
        }
        case 43: {
            return 2052;
        }
        case 44: {
            return 3009;
        }
        case 33: {
            return 3010;
        }
        case 18: {
            return 3011;
        }
        case 45: {
            return 3012;
        }
        case 46: {
            return 4001;
        }
        case 47: {
            return 4003;
        }
        case 48: {
            return 4007;
        }
        case 49: {
            return 4008;
        }
        case 50: {
            return 4009;
        }
        case 51: {
            return 5000;
        }
        case 52: {
            return 5001;
        }
        case 53: {
            return 5002;
        }
        case 54: {
            return 6001;
        }
        case 55: {
            return 6002;
        }
        case 56: {
            return 6003;
        }
        case 57: {
            return 6004;
        }
        case 58: {
            return 6005;
        }
        case 59: {
            return 6006;
        }
        case 60: {
            return 9001;
        }
        case 61: {
            return 9002;
        }
        case 62: {
            return 9003;
        }
        case 63: {
            return 9004;
        }
        case 64: {
            return 9005;
        }
        case 65: {
            return 9006;
        }
        case 66: {
            return 9007;
        }
        case 67: {
            return 9008;
        }
        default: {
            return 100;
        }
    }
}

export const OverallDownloadDocumentIncludes = singleton(new Functionality(22));

export const OverallEditProjectIncludes = ofArray([new Functionality(15), new Functionality(2), new Functionality(3), new Functionality(4), new Functionality(5), new Functionality(7), new Functionality(8), new Functionality(9), new Functionality(10), new Functionality(11), new Functionality(16), new Functionality(17), new Functionality(14)]);

export const OverallEditHmsIncludes = ofArray([new Functionality(44), new Functionality(11), new Functionality(16), new Functionality(17), new Functionality(45)]);

export const ProjectAdministrationList = ofArray([new Functionality(15), new Functionality(6), new Functionality(60)]);

export const ProjectList = [new Functionality(2), new Functionality(7), new Functionality(9), new Functionality(8), new Functionality(10), new Functionality(11), new Functionality(12), new Functionality(13), new Functionality(14), new Functionality(3), new Functionality(4), new Functionality(5), new Functionality(16), new Functionality(17)];

export const AdministrationList = ofArray([new Functionality(19), new Functionality(20), new Functionality(21), new Functionality(23), new Functionality(24), new Functionality(25), new Functionality(26), new Functionality(27), new Functionality(28), new Functionality(29), new Functionality(30), new Functionality(31), new Functionality(32), new Functionality(34), new Functionality(35), new Functionality(36), new Functionality(37), new Functionality(38), new Functionality(66), new Functionality(39), new Functionality(40), new Functionality(41), new Functionality(42), new Functionality(43)]);

export const HMSList = ofArray([new Functionality(44), new Functionality(33), new Functionality(18), new Functionality(61)]);

export const WasteList = ofArray([new Functionality(45), new Functionality(19), new Functionality(63)]);

export const SysConfigList = ofArray([new Functionality(46), new Functionality(47), new Functionality(48), new Functionality(49), new Functionality(50)]);

export const ManagementList = ofArray([new Functionality(51), new Functionality(52), new Functionality(53)]);

export const OtherList = singleton(new Functionality(64));

export const CompanyList = [new Functionality(54), new Functionality(55), new Functionality(56), new Functionality(57), new Functionality(58), new Functionality(59)];

