import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ProcessModule_Tag, ProcessModule_Tag$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { map } from "../.fable/fable-library.3.2.4/Array.js";
import Chip from "@material-ui/core/Chip";
import LocalOffer from "@material-ui/icons/LocalOffer";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { errorBanner, loadingIndicatorSmall } from "../ViewHelpers.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";

export function TagForm(tagFormInputProps) {
    const onClose = tagFormInputProps.onClose;
    const existing = tagFormInputProps.existing;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const tags = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const tagForm = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErr = patternInput_3[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProcessModule_Tag$reflection()),
            });
            return Fetch_tryGet_5760677E("/api/document-tag", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProcessModule_Tag$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            const res = _arg1;
            if (res.tag === 1) {
                setErr("Kunne ikke laste kategorier");
                return Promise.resolve();
            }
            else {
                patternInput[1](res.fields[0]);
                return Promise.resolve();
            }
        })))));
        pr.then();
    });
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Velg kategori"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            maxWidth: 500,
            width: 100 + "vw",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let x_1, filtered;
            return append((tags != null) ? ((x_1 = tags, (filtered = x_1.filter((t_2) => (!existing.some((y) => (t_2.Id === y)))), append(singleton(createElement("div", {
                style: {
                    display: "flex",
                    flexWrap: "wrap",
                },
                children: Interop_reactApi.Children.toArray([map((t_3) => MuiHelpers_createElement(Chip, [["variant", "outlined"], ["size", "small"], ["label", t_3.Name], ["onClick", (_arg3) => {
                    onClose(t_3);
                }], ["style", {
                    margin: 3,
                }], ["icon", react.createElement(LocalOffer, {})]]), filtered)]),
            })), delay(() => append(singleton(createElement("span", {
                children: (filtered.length === 0) ? ((x_1.length > 0) ? "Alle eksisterende kategorier registrert" : "Ingen kategorier tilgjengelig") : "... eller legg til ny",
            })), delay(() => singleton(MuiHelpers_createElement(TextField, [["style", {
                marginTop: 10,
            }], ["label", "Ny"], ["size", "small"], ["variant", "outlined"], ["fullWidth", true], ["defaultValue", tagForm], ["onChange", (e) => {
                patternInput_1[1](Browser_Types_Event__Event_get_Value(e));
            }]]))))))))) : singleton(loadingIndicatorSmall()), delay(() => singleton(errorBanner(patternInput_3[0], () => {
                setErr(void 0);
            }))));
        })))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Lukk"], ["onClick", (_arg5) => {
        onClose(void 0);
    }]])), delay(() => ((tagForm.length > 0) ? ((!patternInput_2[0]) ? singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Legg til ny"], ["onClick", (_arg6) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            setProcessing(true);
            const t = new ProcessModule_Tag(-1, tagForm);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ProcessModule_Tag$reflection,
                })(t);
                const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: ProcessModule_Tag$reflection,
                });
                return Fetch_tryPost_5760677E("/api/document-tag", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                    ResolveType: ProcessModule_Tag$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg2) => {
                const res_1 = _arg2;
                if (res_1.tag === 0) {
                    onClose(res_1.fields[0]);
                    return Promise.resolve();
                }
                else {
                    setProcessing(false);
                    setErr("Kunne ikke legge til ny kategori.");
                    return Promise.resolve();
                }
            }));
        }));
        pr_1.then();
    }]])) : singleton(loadingIndicatorSmall())) : empty_1())))))))]])])]]);
}

