import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, int32_type, option_type, array_type, union_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Helpers_ResultMessage$reflection, BasicAuth_Company, BasicAuth_Office, BasicAuth_Department, BasicAuth_Department$reflection, BasicAuth_Company$reflection, BasicAuth_Office$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPut_5760677E, Fetch_tryGet_5760677E, Fetch_tryPost_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { sortBy, map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { toText, printf, toConsole } from "../.fable/fable-library.3.2.4/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { equals, comparePrimitives, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import Typography from "@material-ui/core/Typography";
import { genericDeleteDialog, addTooltip } from "../ViewHelpers.js";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import AddBoxRounded from "@material-ui/icons/AddBoxRounded";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Paper from "@material-ui/core/Paper";
import Snackbar_1 from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

class Snackbar extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Saved", "InProgress", "Creating", "Unsaved", "Hide", "Failed"];
    }
}

function Snackbar$reflection() {
    return union_type("CompanyConfigure.Snackbar", [], Snackbar, () => [[], [], [], [], [], [["Item", string_type]]]);
}

class CreateNew extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Off", "Comp", "Dep"];
    }
}

function CreateNew$reflection() {
    return union_type("CompanyConfigure.CreateNew", [], CreateNew, () => [[["Item", BasicAuth_Office$reflection()]], [["Item", BasicAuth_Company$reflection()]], [["Item", BasicAuth_Department$reflection()]]]);
}

function CreateNew__get_Name(this$) {
    switch (this$.tag) {
        case 1: {
            return this$.fields[0].Name;
        }
        case 2: {
            return this$.fields[0].Name;
        }
        default: {
            return this$.fields[0].Name;
        }
    }
}

function CreateNew__get_Id(this$) {
    switch (this$.tag) {
        case 1: {
            return this$.fields[0].Uuid;
        }
        case 2: {
            return this$.fields[0].Uuid;
        }
        default: {
            return this$.fields[0].Uuid;
        }
    }
}

function CreateNew_get_EmptyDep() {
    return new CreateNew(2, new BasicAuth_Department("", "", true));
}

function CreateNew_EmptyOff(companyUuid, companyName) {
    return new CreateNew(0, new BasicAuth_Office("", companyName, companyUuid, "", true));
}

function CreateNew_get_EmptyComp() {
    return new CreateNew(1, new BasicAuth_Company("", "", [], true));
}

class Model extends Record {
    constructor(Companies, Offices, Departments, SelectedCompany, SnackState, SelectedView, SelectedCompanyView, CreateNew) {
        super();
        this.Companies = Companies;
        this.Offices = Offices;
        this.Departments = Departments;
        this.SelectedCompany = SelectedCompany;
        this.SnackState = SnackState;
        this.SelectedView = (SelectedView | 0);
        this.SelectedCompanyView = (SelectedCompanyView | 0);
        this.CreateNew = CreateNew;
    }
}

function Model$reflection() {
    return record_type("CompanyConfigure.Model", [], Model, () => [["Companies", array_type(BasicAuth_Company$reflection())], ["Offices", array_type(BasicAuth_Office$reflection())], ["Departments", array_type(BasicAuth_Department$reflection())], ["SelectedCompany", option_type(BasicAuth_Company$reflection())], ["SnackState", Snackbar$reflection()], ["SelectedView", int32_type], ["SelectedCompanyView", int32_type], ["CreateNew", option_type(CreateNew$reflection())]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompaniesResponse", "OfficesResponse", "DepartmentsResponse", "SelectCompany", "DismissCompany", "SelectView", "SelectCompanyView", "SetSnackState", "SetCreateNew", "CompanyAdded", "CompanyUpdated", "CompanyDeleted", "OfficeAdded", "OfficeUpdated", "OfficeDeleted", "DepartmentAdded", "DepartmentUpdated", "DepartmentDeleted"];
    }
}

function Message$reflection() {
    return union_type("CompanyConfigure.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(BasicAuth_Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(BasicAuth_Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(BasicAuth_Office$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(BasicAuth_Office$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(BasicAuth_Department$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(BasicAuth_Department$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", BasicAuth_Company$reflection()]], [], [["Item", int32_type]], [["Item", int32_type]], [["Item", Snackbar$reflection()]], [["Item", option_type(CreateNew$reflection())]], [["Item", BasicAuth_Company$reflection()]], [["Item", BasicAuth_Company$reflection()]], [["Item", BasicAuth_Company$reflection()]], [["Item", BasicAuth_Office$reflection()]], [["Item", BasicAuth_Office$reflection()]], [["Item", BasicAuth_Office$reflection()]], [["Item", BasicAuth_Department$reflection()]], [["Item", BasicAuth_Department$reflection()]], [["Item", BasicAuth_Department$reflection()]]]);
}

function init() {
    return new Model([], [], [], void 0, new Snackbar(4), 0, 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const resp_1 = msg.fields[0];
            if (resp_1.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Departments, model.SelectedCompany, new Snackbar(5, "Noe gikk galt. Kunne ikke laste kontorer"), model.SelectedView, model.SelectedCompanyView, model.CreateNew);
            }
            else {
                return new Model(model.Companies, resp_1.fields[0], model.Departments, model.SelectedCompany, model.SnackState, model.SelectedView, model.SelectedCompanyView, model.CreateNew);
            }
        }
        case 2: {
            const resp_2 = msg.fields[0];
            if (resp_2.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Departments, model.SelectedCompany, new Snackbar(5, "Noe gikk galt. Kunne ikke laste avdelinger"), model.SelectedView, model.SelectedCompanyView, model.CreateNew);
            }
            else {
                return new Model(model.Companies, model.Offices, resp_2.fields[0], model.SelectedCompany, model.SnackState, model.SelectedView, model.SelectedCompanyView, model.CreateNew);
            }
        }
        case 3: {
            return new Model(model.Companies, model.Offices, model.Departments, msg.fields[0], model.SnackState, model.SelectedView, model.SelectedCompanyView, model.CreateNew);
        }
        case 4: {
            return new Model(model.Companies, model.Offices, model.Departments, void 0, model.SnackState, model.SelectedView, model.SelectedCompanyView, model.CreateNew);
        }
        case 5: {
            return new Model(model.Companies, model.Offices, model.Departments, model.SelectedCompany, model.SnackState, msg.fields[0], model.SelectedCompanyView, model.CreateNew);
        }
        case 6: {
            return new Model(model.Companies, model.Offices, model.Departments, model.SelectedCompany, model.SnackState, model.SelectedView, msg.fields[0], model.CreateNew);
        }
        case 7: {
            return new Model(model.Companies, model.Offices, model.Departments, model.SelectedCompany, msg.fields[0], model.SelectedView, model.SelectedCompanyView, model.CreateNew);
        }
        case 8: {
            return new Model(model.Companies, model.Offices, model.Departments, model.SelectedCompany, model.SnackState, model.SelectedView, model.SelectedCompanyView, msg.fields[0]);
        }
        case 9: {
            return new Model(append([msg.fields[0]], model.Companies), model.Offices, model.Departments, model.SelectedCompany, new Snackbar(0), model.SelectedView, model.SelectedCompanyView, void 0);
        }
        case 10: {
            const x_1 = msg.fields[0];
            return new Model(map((z) => {
                if (z.Uuid === x_1.Uuid) {
                    return x_1;
                }
                else {
                    return z;
                }
            }, model.Companies), model.Offices, model.Departments, model.SelectedCompany, new Snackbar(0), model.SelectedView, model.SelectedCompanyView, void 0);
        }
        case 11: {
            return new Model(model.Companies.filter((z_1) => (z_1.Uuid !== msg.fields[0].Uuid)), model.Offices, model.Departments, void 0, new Snackbar(0), model.SelectedView, model.SelectedCompanyView, model.CreateNew);
        }
        case 12: {
            return new Model(model.Companies, append([msg.fields[0]], model.Offices), model.Departments, model.SelectedCompany, new Snackbar(0), model.SelectedView, model.SelectedCompanyView, void 0);
        }
        case 13: {
            const x_4 = msg.fields[0];
            return new Model(model.Companies, map((z_2) => {
                if (z_2.Uuid === x_4.Uuid) {
                    return x_4;
                }
                else {
                    return z_2;
                }
            }, model.Offices), model.Departments, model.SelectedCompany, new Snackbar(0), model.SelectedView, model.SelectedCompanyView, model.CreateNew);
        }
        case 14: {
            return new Model(model.Companies, model.Offices.filter((z_3) => (z_3.Uuid !== msg.fields[0].Uuid)), model.Departments, model.SelectedCompany, new Snackbar(0), model.SelectedView, model.SelectedCompanyView, model.CreateNew);
        }
        case 15: {
            return new Model(model.Companies, model.Offices, append([msg.fields[0]], model.Departments), model.SelectedCompany, new Snackbar(0), model.SelectedView, model.SelectedCompanyView, void 0);
        }
        case 16: {
            const x_7 = msg.fields[0];
            return new Model(model.Companies, model.Offices, map((z_4) => {
                if (z_4.Uuid === x_7.Uuid) {
                    return x_7;
                }
                else {
                    return z_4;
                }
            }, model.Departments), model.SelectedCompany, new Snackbar(0), model.SelectedView, model.SelectedCompanyView, model.CreateNew);
        }
        case 17: {
            return new Model(model.Companies, model.Offices, model.Departments.filter((z_5) => (z_5.Uuid !== msg.fields[0].Uuid)), model.SelectedCompany, new Snackbar(0), model.SelectedView, model.SelectedCompanyView, model.CreateNew);
        }
        default: {
            const resp = msg.fields[0];
            if (resp.tag === 1) {
                toConsole(printf("error: %A"))(resp.fields[0]);
                return new Model(model.Companies, model.Offices, model.Departments, model.SelectedCompany, new Snackbar(5, "Noe gikk galt. Kunne ikke laste selskaper"), model.SelectedView, model.SelectedCompanyView, model.CreateNew);
            }
            else {
                return new Model(resp.fields[0], model.Offices, model.Departments, model.SelectedCompany, model.SnackState, model.SelectedView, model.SelectedCompanyView, model.CreateNew);
            }
        }
    }
}

function createNewElem(newElem, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        if (CreateNew__get_Name(newElem).length === 0) {
            dispatch(new Message(7, new Snackbar(5, "Navn kan ikke være tomt")));
            return Promise.resolve();
        }
        else {
            switch (newElem.tag) {
                case 1: {
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: BasicAuth_Company$reflection,
                        })(newElem.fields[0]);
                        const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: BasicAuth_Company$reflection,
                        });
                        return Fetch_tryPost_5760677E("/api/basicauth/company", some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                            ResolveType: BasicAuth_Company$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg2) => {
                        const res_1 = _arg2;
                        if (res_1.tag === 1) {
                            const err_1 = res_1.fields[0];
                            let pattern_matching_result;
                            if (err_1.tag === 2) {
                                if ((err_1.fields[0].status) === 409) {
                                    pattern_matching_result = 0;
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                            }
                            else {
                                pattern_matching_result = 1;
                            }
                            switch (pattern_matching_result) {
                                case 0: {
                                    dispatch(new Message(7, new Snackbar(5, "Ett selskap med samme navn eksisterer allerede")));
                                    return Promise.resolve();
                                }
                                case 1: {
                                    dispatch(new Message(7, new Snackbar(5, "Kunne ikke opprette selskap")));
                                    return Promise.resolve();
                                }
                            }
                        }
                        else {
                            dispatch(new Message(9, res_1.fields[0]));
                            return Promise.resolve();
                        }
                    }));
                }
                case 0: {
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body_2 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: BasicAuth_Office$reflection,
                        })(newElem.fields[0]);
                        const decoder_2 = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: BasicAuth_Office$reflection,
                        });
                        return Fetch_tryPost_5760677E("/api/basicauth/office", some(body_2), empty(), void 0, void 0, void 0, uncurry(2, decoder_2), {
                            ResolveType: BasicAuth_Office$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg3) => {
                        const res_2 = _arg3;
                        if (res_2.tag === 1) {
                            const err_2 = res_2.fields[0];
                            let pattern_matching_result_1;
                            if (err_2.tag === 2) {
                                if ((err_2.fields[0].status) === 409) {
                                    pattern_matching_result_1 = 0;
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                            switch (pattern_matching_result_1) {
                                case 0: {
                                    dispatch(new Message(7, new Snackbar(5, "Det eksisterer allerede ett kontor med samme navn under dette selskapet")));
                                    return Promise.resolve();
                                }
                                case 1: {
                                    let pattern_matching_result_2;
                                    if (err_2.tag === 2) {
                                        if ((err_2.fields[0].status) === 404) {
                                            pattern_matching_result_2 = 0;
                                        }
                                        else {
                                            pattern_matching_result_2 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 1;
                                    }
                                    switch (pattern_matching_result_2) {
                                        case 0: {
                                            dispatch(new Message(7, new Snackbar(5, "Fant ikke selskapet nytt kontor skal legges under.")));
                                            return Promise.resolve();
                                        }
                                        case 1: {
                                            dispatch(new Message(7, new Snackbar(5, "Kunne ikke opprette kontor")));
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            dispatch(new Message(12, res_2.fields[0]));
                            return Promise.resolve();
                        }
                    }));
                }
                default: {
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: BasicAuth_Department$reflection,
                        })(newElem.fields[0]);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: BasicAuth_Department$reflection,
                        });
                        return Fetch_tryPost_5760677E("/api/basicauth/department", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: BasicAuth_Department$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg1) => {
                        const res = _arg1;
                        if (res.tag === 1) {
                            const err = res.fields[0];
                            let pattern_matching_result_3;
                            if (err.tag === 2) {
                                if ((err.fields[0].status) === 409) {
                                    pattern_matching_result_3 = 0;
                                }
                                else {
                                    pattern_matching_result_3 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                            switch (pattern_matching_result_3) {
                                case 0: {
                                    dispatch(new Message(7, new Snackbar(5, "Det eksisterer allerede en avdeling med samme navn under dette selskapet")));
                                    return Promise.resolve();
                                }
                                case 1: {
                                    let pattern_matching_result_4;
                                    if (err.tag === 2) {
                                        if ((err.fields[0].status) === 404) {
                                            pattern_matching_result_4 = 0;
                                        }
                                        else {
                                            pattern_matching_result_4 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_4 = 1;
                                    }
                                    switch (pattern_matching_result_4) {
                                        case 0: {
                                            dispatch(new Message(7, new Snackbar(5, "Fant ikke selskapet ny avdeling skal legges under")));
                                            return Promise.resolve();
                                        }
                                        case 1: {
                                            dispatch(new Message(7, new Snackbar(5, "Kunne ikke opprette avdeling")));
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            dispatch(new Message(15, res.fields[0]));
                            return Promise.resolve();
                        }
                    }));
                }
            }
        }
    }));
    pr.then();
}

function fetchCompanies(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(BasicAuth_Company$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/basicauth/companies", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(BasicAuth_Company$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchOffices(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(BasicAuth_Office$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/basicauth/offices", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(BasicAuth_Office$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchDepartments(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(BasicAuth_Department$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/basicauth/departments", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(BasicAuth_Department$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function NewElemDialog(newElemDialogInputProps) {
    const elemType = newElemDialogInputProps.elemType;
    const dispatch = newElemDialogInputProps.dispatch;
    const title = (elemType.tag === 2) ? "Opprett avdeling" : ((elemType.tag === 0) ? "Opprett kontor" : "Opprett selskap");
    const patternInput = useFeliz_React__React_useState_Static_1505({
        Name: "",
    });
    const state = patternInput[0];
    let getFinalElem;
    switch (elemType.tag) {
        case 0: {
            const o = elemType.fields[0];
            getFinalElem = (new CreateNew(0, new BasicAuth_Office(o.Uuid, o.Company, o.CompanyUuid, state.Name, o.Enabled)));
            break;
        }
        case 2: {
            const o_1 = elemType.fields[0];
            getFinalElem = (new CreateNew(2, new BasicAuth_Department(o_1.Uuid, state.Name, o_1.Enabled)));
            break;
        }
        default: {
            const c = elemType.fields[0];
            getFinalElem = (new CreateNew(1, new BasicAuth_Company(c.Uuid, state.Name, c.Offices, c.Enabled)));
        }
    }
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        dispatch(new Message(8, void 0));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", title]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20 + "px",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", state.Name], ["label", "Navn"], ["required", true], ["style", {
            marginBottom: 15,
        }], ["onChange", (e) => {
            patternInput[1]({
                Name: Browser_Types_Event__Event_get_Value(e),
            });
        }]])]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg2) => {
        dispatch(new Message(8, void 0));
    }], ["color", "primary"], ["children", "Avbryt"]]), MuiHelpers_createElement(Button, [["onClick", (_arg3) => {
        createNewElem(getFinalElem, dispatch);
    }], ["disabled", state.Name.length === 0], ["color", "primary"], ["children", "Legg til"]])])]])])]]);
}

function EditCompanyView(editCompanyViewInputProps) {
    const onChange = editCompanyViewInputProps.onChange;
    const dispatch = editCompanyViewInputProps.dispatch;
    const company = editCompanyViewInputProps.company;
    const patternInput = useFeliz_React__React_useState_Static_1505(company);
    const state = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showDelete = patternInput_2[0];
    const setShowDelete = patternInput_2[1];
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            gap: ((20 + "px ") + 20) + "px",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["label", "Selskapsnavn"], ["defaultValue", company.Name], ["size", "small"], ["onChange", (e) => {
            const newState = new BasicAuth_Company(state.Uuid, Browser_Types_Event__Event_get_Value(e), state.Offices, state.Enabled);
            patternInput[1](newState);
            dispatch(new Message(7, new Snackbar(1)));
            window.clearTimeout(patternInput_1[0]);
            patternInput_1[1](window.setTimeout((_arg1_1) => {
                const dispatch_1 = dispatch;
                const x = newState;
                const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    if (company.Name === x.Name) {
                        dispatch_1(new Message(7, new Snackbar(4)));
                        return Promise.resolve();
                    }
                    else {
                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: BasicAuth_Company$reflection,
                            })(x);
                            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: BasicAuth_Company$reflection,
                            });
                            return Fetch_tryPut_5760677E("/api/basicauth/company", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                ResolveType: BasicAuth_Company$reflection,
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg1) => {
                            const res = _arg1;
                            if (res.tag === 1) {
                                const err = res.fields[0];
                                let pattern_matching_result;
                                if (err.tag === 2) {
                                    if ((err.fields[0].status) === 404) {
                                        pattern_matching_result = 0;
                                    }
                                    else {
                                        pattern_matching_result = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                                switch (pattern_matching_result) {
                                    case 0: {
                                        dispatch_1(new Message(7, new Snackbar(5, "Fant ikke selskap som skal oppdateres")));
                                        return Promise.resolve();
                                    }
                                    case 1: {
                                        dispatch_1(new Message(7, new Snackbar(5, "Kunne ikke oppdatere selskap")));
                                        return Promise.resolve();
                                    }
                                }
                            }
                            else {
                                const c = res.fields[0];
                                onChange(c);
                                dispatch_1(new Message(10, c));
                                return Promise.resolve();
                            }
                        }));
                    }
                }));
                pr.then();
            }, 1000));
        }]]), createElement("div", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(Button, toList(delay(() => append_1(singleton(["size", "small"]), delay(() => append_1(singleton(["variant", "outlined"]), delay(() => append_1(showDelete ? append_1(singleton(["color", "secondary"]), delay(() => singleton(["variant", "contained"]))) : empty_1(), delay(() => append_1(singleton(["children", "Slett selskap"]), delay(() => singleton(["onClick", (_arg2_1) => {
                if (!showDelete) {
                    setShowDelete(true);
                }
                else {
                    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const requestPath_1 = toText(printf("/api/basicauth/company/%s"))(company.Uuid);
                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: () => string_type,
                            })("");
                            const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: Helpers_ResultMessage$reflection,
                            });
                            return Fetch_tryDelete_5760677E(requestPath_1, some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                                ResolveType: Helpers_ResultMessage$reflection,
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg2) => {
                            const res_1 = _arg2;
                            if (res_1.tag === 1) {
                                const err_1 = res_1.fields[0];
                                let pattern_matching_result_1;
                                if (err_1.tag === 2) {
                                    if ((err_1.fields[0].status) === 404) {
                                        pattern_matching_result_1 = 0;
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                                switch (pattern_matching_result_1) {
                                    case 0: {
                                        dispatch(new Message(7, new Snackbar(5, "Fant ikke selskap som skal slettes")));
                                        return Promise.resolve();
                                    }
                                    case 1: {
                                        dispatch(new Message(7, new Snackbar(5, "Kunne ikke slette selskap")));
                                        return Promise.resolve();
                                    }
                                }
                            }
                            else {
                                const x_1 = res_1.fields[0];
                                if (x_1.Result === "success") {
                                    dispatch(new Message(11, company));
                                    return Promise.resolve();
                                }
                                else {
                                    dispatch(new Message(7, new Snackbar(5, x_1.Message)));
                                    return Promise.resolve();
                                }
                            }
                        }));
                    }));
                    pr_1.then();
                }
            }]))))))))))))), delay(() => (showDelete ? singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["style", {
                marginLeft: 10,
            }], ["children", "Avbryt"], ["onClick", (_arg3) => {
                setShowDelete(false);
            }]])) : empty_1()))))))),
        })]),
    });
}

function ListDepartmentView(listDepartmentViewInputProps) {
    const dispatch = listDepartmentViewInputProps.dispatch;
    const departments = listDepartmentViewInputProps.departments;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setEdit = patternInput[1];
    const edit = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setDelete = patternInput_1[1];
    const delete$ = patternInput_1[0];
    return createElement("div", {
        style: {
            margin: "auto",
            maxWidth: 800,
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("div", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["variant", "h6"], ["style", {
                margin: 1 + "rem",
                flexGrow: 1,
            }], ["children", "Avdelinger"]]), addTooltip("Ny avdeling", MuiHelpers_createElement(IconButton, [["style", {
                float: "right",
            }], ["onClick", (_arg1_1) => {
                dispatch(new Message(8, CreateNew_get_EmptyDep()));
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBoxRounded, {})])]])])]]))]),
        })), delay(() => append_1(singleton(MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", "Rediger"]]), MuiHelpers_createElement(TableCell, [["children", "Avdeling"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((d_1) => {
            let pattern_matching_result, e_1;
            if (edit != null) {
                if (edit.Uuid === d_1.Uuid) {
                    pattern_matching_result = 0;
                    e_1 = edit;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return MuiHelpers_createElement(TableRow, [["selected", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([addTooltip("Lagre", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Check, {})], ["onClick", (_arg2_1) => {
                        const new$0027 = e_1;
                        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            if (d_1.Name === new$0027.Name) {
                                dispatch(new Message(7, new Snackbar(4)));
                                return Promise.resolve();
                            }
                            else {
                                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                    const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                        ResolveType: BasicAuth_Department$reflection,
                                    })(new$0027);
                                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                        ResolveType: BasicAuth_Department$reflection,
                                    });
                                    return Fetch_tryPut_5760677E("/api/basicauth/department", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                        ResolveType: BasicAuth_Department$reflection,
                                    }, {
                                        ResolveType: () => obj_type,
                                    });
                                })).then(((_arg1) => {
                                    const res = _arg1;
                                    if (res.tag === 1) {
                                        const err = res.fields[0];
                                        let pattern_matching_result_1;
                                        if (err.tag === 2) {
                                            if ((err.fields[0].status) === 404) {
                                                pattern_matching_result_1 = 0;
                                            }
                                            else {
                                                pattern_matching_result_1 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_1 = 1;
                                        }
                                        switch (pattern_matching_result_1) {
                                            case 0: {
                                                dispatch(new Message(7, new Snackbar(5, "Fant ikke avdeling som skal oppdateres")));
                                                return Promise.resolve();
                                            }
                                            case 1: {
                                                let pattern_matching_result_2;
                                                if (err.tag === 2) {
                                                    if ((err.fields[0].status) === 409) {
                                                        pattern_matching_result_2 = 0;
                                                    }
                                                    else {
                                                        pattern_matching_result_2 = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_2 = 1;
                                                }
                                                switch (pattern_matching_result_2) {
                                                    case 0: {
                                                        dispatch(new Message(7, new Snackbar(5, "Avdelingnavn allerede i bruk")));
                                                        return Promise.resolve();
                                                    }
                                                    case 1: {
                                                        dispatch(new Message(7, new Snackbar(5, "Kunne ikke oppdatere avdeling")));
                                                        return Promise.resolve();
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        dispatch(new Message(16, res.fields[0]));
                                        return Promise.resolve();
                                    }
                                }));
                            }
                        }));
                        pr.then();
                        setEdit(void 0);
                    }]])), addTooltip("Avbryt", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Clear, {})])]])])], ["onClick", (_arg3) => {
                        setEdit(void 0);
                    }]]))])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", e_1.Name], ["onChange", (e_2) => {
                        setEdit(new BasicAuth_Department(e_1.Uuid, Browser_Types_Event__Event_get_Value(e_2), e_1.Enabled));
                    }]])])]])])]]);
                }
                case 1: {
                    let pattern_matching_result_3;
                    if (edit == null) {
                        pattern_matching_result_3 = 0;
                    }
                    else {
                        pattern_matching_result_3 = 0;
                    }
                    switch (pattern_matching_result_3) {
                        case 0: {
                            return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([addTooltip("Rediger", MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg4) => {
                                setEdit(d_1);
                            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(EditOutlined, {})])]])])]])), addTooltip("Slett", MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg5) => {
                                setDelete(d_1);
                            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutlined, {})])]])])]]))])]]), MuiHelpers_createElement(TableCell, [["children", d_1.Name]])])]]);
                        }
                    }
                }
            }
        }, sortBy((d) => d.Name, departments, {
            Compare: (x_2, y_8) => comparePrimitives(x_2, y_8),
        }))])]])])]])), delay(() => {
            if (delete$ != null) {
                const del = delete$;
                return singleton(genericDeleteDialog(() => {
                    setDelete(void 0);
                }, () => {
                    setDelete(void 0);
                    const department = del;
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const requestPath_2 = toText(printf("/api/basicauth/department/%s"))(department.Uuid);
                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: () => string_type,
                            })("");
                            const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: Helpers_ResultMessage$reflection,
                            });
                            return Fetch_tryDelete_5760677E(requestPath_2, some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                                ResolveType: Helpers_ResultMessage$reflection,
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg2) => {
                            const res_1 = _arg2;
                            if (res_1.tag === 1) {
                                const err_1 = res_1.fields[0];
                                let pattern_matching_result_4;
                                if (err_1.tag === 2) {
                                    if ((err_1.fields[0].status) === 409) {
                                        pattern_matching_result_4 = 0;
                                    }
                                    else {
                                        pattern_matching_result_4 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_4 = 1;
                                }
                                switch (pattern_matching_result_4) {
                                    case 0: {
                                        dispatch(new Message(7, new Snackbar(5, "Kan ikke slette avdeling med brukere. Flytt brukere først.")));
                                        return Promise.resolve();
                                    }
                                    case 1: {
                                        let pattern_matching_result_5;
                                        if (err_1.tag === 2) {
                                            if ((err_1.fields[0].status) === 404) {
                                                pattern_matching_result_5 = 0;
                                            }
                                            else {
                                                pattern_matching_result_5 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_5 = 1;
                                        }
                                        switch (pattern_matching_result_5) {
                                            case 0: {
                                                dispatch(new Message(7, new Snackbar(5, "Fant ikke avdeling som skal slettes")));
                                                return Promise.resolve();
                                            }
                                            case 1: {
                                                dispatch(new Message(7, new Snackbar(5, "Kunne ikke slette avdeling")));
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                }
                            }
                            else {
                                const x_1 = res_1.fields[0];
                                if (x_1.Result === "success") {
                                    dispatch(new Message(17, department));
                                    return Promise.resolve();
                                }
                                else {
                                    dispatch(new Message(7, new Snackbar(5, x_1.Message)));
                                    return Promise.resolve();
                                }
                            }
                        }));
                    }));
                }, [toText(printf("Dette vil slette \"%s\" og fjerne avdelingen fra alle tilknyttede brukere"))(del.Name)]));
            }
            else {
                return singleton(null);
            }
        })))))))),
    });
}

function ListOfficeView(listOfficeViewInputProps) {
    const dispatch = listOfficeViewInputProps.dispatch;
    const offices = listOfficeViewInputProps.offices;
    const company = listOfficeViewInputProps.company;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setEdit = patternInput[1];
    const edit = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setDelete = patternInput_1[1];
    const delete$ = patternInput_1[0];
    const children_18 = toList(delay(() => append_1(singleton(addTooltip("Nytt kontor", MuiHelpers_createElement(IconButton, [["style", {
        float: "right",
    }], ["onClick", (_arg1_1) => {
        dispatch(new Message(8, CreateNew_EmptyOff(company.Uuid, company.Name)));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBoxRounded, {})])]])])]]))), delay(() => append_1(singleton(MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", "Rediger"]]), MuiHelpers_createElement(TableCell, [["children", "Kontor"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((o_1) => {
        let pattern_matching_result, e_1;
        if (edit != null) {
            if (edit.Uuid === o_1.Uuid) {
                pattern_matching_result = 0;
                e_1 = edit;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return MuiHelpers_createElement(TableRow, [["selected", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([addTooltip("Lagre", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Check, {})])]])])], ["onClick", (_arg2_1) => {
                    const new$0027 = e_1;
                    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        if (o_1.Name === new$0027.Name) {
                            dispatch(new Message(7, new Snackbar(4)));
                            return Promise.resolve();
                        }
                        else {
                            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                    ResolveType: BasicAuth_Office$reflection,
                                })(new$0027);
                                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                    ResolveType: BasicAuth_Office$reflection,
                                });
                                return Fetch_tryPut_5760677E("/api/basicauth/office", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                    ResolveType: BasicAuth_Office$reflection,
                                }, {
                                    ResolveType: () => obj_type,
                                });
                            })).then(((_arg1) => {
                                const res = _arg1;
                                if (res.tag === 1) {
                                    const err = res.fields[0];
                                    let pattern_matching_result_1;
                                    if (err.tag === 2) {
                                        if ((err.fields[0].status) === 404) {
                                            pattern_matching_result_1 = 0;
                                        }
                                        else {
                                            pattern_matching_result_1 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                    switch (pattern_matching_result_1) {
                                        case 0: {
                                            dispatch(new Message(7, new Snackbar(5, "Fant ikke kontor som skal oppdateres")));
                                            return Promise.resolve();
                                        }
                                        case 1: {
                                            let pattern_matching_result_2;
                                            if (err.tag === 2) {
                                                if ((err.fields[0].status) === 409) {
                                                    pattern_matching_result_2 = 0;
                                                }
                                                else {
                                                    pattern_matching_result_2 = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_2 = 1;
                                            }
                                            switch (pattern_matching_result_2) {
                                                case 0: {
                                                    dispatch(new Message(7, new Snackbar(5, "Kontornavn allerede i bruk")));
                                                    return Promise.resolve();
                                                }
                                                case 1: {
                                                    dispatch(new Message(7, new Snackbar(5, "Kunne ikke oppdatere kontor")));
                                                    return Promise.resolve();
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    dispatch(new Message(13, res.fields[0]));
                                    return Promise.resolve();
                                }
                            }));
                        }
                    }));
                    pr.then();
                    setEdit(void 0);
                }]])), addTooltip("Avbryt", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Clear, {})])]])])], ["onClick", (_arg3) => {
                    setEdit(void 0);
                }]]))])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", e_1.Name], ["onChange", (e_2) => {
                    setEdit(new BasicAuth_Office(e_1.Uuid, e_1.Company, e_1.CompanyUuid, Browser_Types_Event__Event_get_Value(e_2), e_1.Enabled));
                }]])])]])])]]);
            }
            case 1: {
                let pattern_matching_result_3;
                if (edit == null) {
                    pattern_matching_result_3 = 0;
                }
                else {
                    pattern_matching_result_3 = 0;
                }
                switch (pattern_matching_result_3) {
                    case 0: {
                        return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([addTooltip("Rediger", MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg4) => {
                            setEdit(o_1);
                        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(EditOutlined, {})])]])])]])), addTooltip("Slett", MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg5) => {
                            setDelete(o_1);
                        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutlined, {})])]])])]]))])]]), MuiHelpers_createElement(TableCell, [["children", o_1.Name]])])]]);
                    }
                }
            }
        }
    }, sortBy((o) => o.Name, offices, {
        Compare: (x_2, y_8) => comparePrimitives(x_2, y_8),
    }))])]])])]])), delay(() => {
        if (delete$ != null) {
            const del = delete$;
            return singleton(genericDeleteDialog(() => {
                setDelete(void 0);
            }, () => {
                setDelete(void 0);
                const office = del;
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const requestPath_2 = toText(printf("/api/basicauth/office/%s"))(office.Uuid);
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: () => string_type,
                        })("");
                        const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: Helpers_ResultMessage$reflection,
                        });
                        return Fetch_tryDelete_5760677E(requestPath_2, some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                            ResolveType: Helpers_ResultMessage$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg2) => {
                        const res_1 = _arg2;
                        if (res_1.tag === 1) {
                            const err_1 = res_1.fields[0];
                            let pattern_matching_result_4;
                            if (err_1.tag === 2) {
                                if ((err_1.fields[0].status) === 409) {
                                    pattern_matching_result_4 = 0;
                                }
                                else {
                                    pattern_matching_result_4 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_4 = 1;
                            }
                            switch (pattern_matching_result_4) {
                                case 0: {
                                    dispatch(new Message(7, new Snackbar(5, "Kan ikke slette kontor med brukere. Flytt brukere først.")));
                                    return Promise.resolve();
                                }
                                case 1: {
                                    let pattern_matching_result_5;
                                    if (err_1.tag === 2) {
                                        if ((err_1.fields[0].status) === 404) {
                                            pattern_matching_result_5 = 0;
                                        }
                                        else {
                                            pattern_matching_result_5 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_5 = 1;
                                    }
                                    switch (pattern_matching_result_5) {
                                        case 0: {
                                            dispatch(new Message(7, new Snackbar(5, "Fant ikke kontor som skal slettes")));
                                            return Promise.resolve();
                                        }
                                        case 1: {
                                            dispatch(new Message(7, new Snackbar(5, "Kunne ikke slette kontor")));
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            const x_1 = res_1.fields[0];
                            if (x_1.Result === "success") {
                                dispatch(new Message(14, office));
                                return Promise.resolve();
                            }
                            else {
                                dispatch(new Message(7, new Snackbar(5, x_1.Message)));
                                return Promise.resolve();
                            }
                        }
                    }));
                }));
            }, [toText(printf("Dette vil slette \"%s\" og fjerne kontoret fra alle tilknyttede brukere"))(del.Name)]));
        }
        else {
            return singleton(null);
        }
    }))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_18)),
    });
}

function DrawCompanyEdit(drawCompanyEditInputProps) {
    const dispatch = drawCompanyEditInputProps.dispatch;
    const model = drawCompanyEditInputProps.model;
    const company = drawCompanyEditInputProps.company;
    const patternInput = useFeliz_React__React_useState_Static_1505(company);
    const this$ = patternInput[0];
    return createElement("div", {
        style: {
            margin: "auto",
            maxWidth: 800,
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let children_1;
            return append_1(singleton((children_1 = ofArray([MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(IconButton, [["onClick", (_arg1) => {
                dispatch(new Message(4));
            }], ["children", Interop_reactApi.Children.toArray([react.createElement(ArrowBackIos, {})])]])], ["label", "Tilbake"]]), MuiHelpers_createElement(Typography, [["variant", "h6"], ["style", {
                margin: 1 + "rem",
            }], ["children", this$.Name]])]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            }))), delay(() => append_1(singleton(createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "center",
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tabs, [["value", model.SelectedCompanyView], ["variant", "scrollable"], ["textColor", "primary"], ["indicatorColor", "primary"], ["onChange", (_arg38, v) => {
                    dispatch(new Message(6, v));
                }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tab, [["label", "Selskap"]]), MuiHelpers_createElement(Tab, [["label", "Kontorer"]])])]])]),
            })), delay(() => {
                const companyOffices = model.Offices.filter((x) => (x.CompanyUuid === company.Uuid));
                return singleton(createElement("div", {
                    style: {
                        width: 90 + "%",
                        maxWidth: 600,
                        margin: "auto",
                        marginTop: 20,
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        const matchValue = model.SelectedCompanyView | 0;
                        switch (matchValue) {
                            case 0: {
                                return singleton(createElement(EditCompanyView, {
                                    company: this$,
                                    dispatch: dispatch,
                                    onChange: patternInput[1],
                                }));
                            }
                            case 1: {
                                return singleton(createElement(ListOfficeView, {
                                    company: this$,
                                    offices: companyOffices,
                                    dispatch: dispatch,
                                }));
                            }
                            default: {
                                return singleton(createElement("div", {}));
                            }
                        }
                    })))),
                }));
            }))));
        })))),
    });
}

function drawCompanyList(companies, dispatch) {
    return createElement("div", {
        style: {
            margin: "auto",
            maxWidth: 800,
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["variant", "h6"], ["style", {
                margin: 1 + "rem",
                flexGrow: 1,
            }], ["children", "Selskaper"]]), addTooltip("Nytt selskap", MuiHelpers_createElement(IconButton, [["style", {
                marginRight: 8,
                marginTop: 8,
                width: 48,
                height: 48,
            }], ["onClick", (_arg1) => {
                dispatch(new Message(8, CreateNew_get_EmptyComp()));
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBoxRounded, {})])]])])]]))]),
        }), MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["style", {
            border: (((0 + "px ") + "none") + " ") + "",
        }], ["children", "Selskapsnavn"]]), MuiHelpers_createElement(TableCell, [["style", {
            border: (((0 + "px ") + "none") + " ") + "",
        }], ["children", "Aktiv"]]), MuiHelpers_createElement(TableCell, [["style", {
            border: (((0 + "px ") + "none") + " ") + "",
        }], ["children", "Rediger"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((company_1) => MuiHelpers_createElement(TableRow, [["style", {
            cursor: "pointer",
        }], ["hover", true], ["onClick", (_arg2) => {
            dispatch(new Message(3, company_1));
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", company_1.Name]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (company_1.Enabled ? singleton(MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Check, {})])]])) : singleton(MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Clear, {})])]])))))))]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(ChevronRight, {})])]])])]])])]])])]]), sortBy((company) => company.Name, companies, {
            Compare: (x, y) => comparePrimitives(x, y),
        }))])]])])]])]),
    });
}

function wrapInPaper(child) {
    return MuiHelpers_createElement(Paper, [["elevation", 4], ["style", {
        overflow: "auto",
        padding: 2 + "vw",
        maxWidth: 1000,
        width: 90 + "%",
    }], ["children", Interop_reactApi.Children.toArray([child])]]);
}

function view(model, dispatch) {
    return createElement("main", {
        style: {
            marginTop: 3 + "rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(Snackbar_1, [["open", equals(model.SnackState, new Snackbar(0))], ["onClose", (_arg33, v) => {
            dispatch(new Message(7, new Snackbar(4)));
        }], ["autoHideDuration", 6000], ["anchorOrigin", {
            vertical: "bottom",
            horizontal: "center",
        }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg2) => {
            dispatch(new Message(7, new Snackbar(4)));
        }], ["severity", "success"], ["variant", "filled"], ["children", "Lagret!"]])]])), delay(() => append_1(singleton(MuiHelpers_createElement(Snackbar_1, [["open", equals(model.SnackState, new Snackbar(1))], ["onClose", (_arg33_1, v_1) => {
            if (!equals(model.SnackState, new Snackbar(0))) {
                dispatch(new Message(7, new Snackbar(4)));
            }
        }], ["autoHideDuration", 4000], ["anchorOrigin", {
            vertical: "bottom",
            horizontal: "center",
        }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg4) => {
            dispatch(new Message(7, new Snackbar(4)));
        }], ["severity", "info"], ["variant", "filled"], ["children", "Lagrer endringer"]])]])), delay(() => {
            let matchValue_1;
            return append_1(singleton(MuiHelpers_createElement(Snackbar_1, [["open", (model.SnackState.tag === 5) ? true : false], ["onClose", (_arg33_2, v_2) => {
                if (!equals(model.SnackState, new Snackbar(0))) {
                    dispatch(new Message(7, new Snackbar(4)));
                }
            }], ["autoHideDuration", 10000], ["anchorOrigin", {
                vertical: "bottom",
                horizontal: "center",
            }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg6) => {
                dispatch(new Message(7, new Snackbar(4)));
            }], ["severity", "error"], ["variant", "filled"], ["children", (matchValue_1 = model.SnackState, (matchValue_1.tag === 5) ? toText(printf("Lagring feilet: %s"))(matchValue_1.fields[0]) : "Lagring feilet")]])]])), delay(() => {
                let matchValue_2, c;
                return append_1((matchValue_2 = model.CreateNew, (matchValue_2 != null) ? ((c = matchValue_2, singleton(createElement(NewElemDialog, {
                    dispatch: dispatch,
                    elemType: c,
                })))) : singleton(null)), delay(() => append_1(singleton(createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "center",
                    },
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tabs, [["value", model.SelectedView], ["variant", "scrollable"], ["textColor", "primary"], ["indicatorColor", "primary"], ["onChange", (_arg38, v_3) => {
                        dispatch(new Message(5, v_3));
                    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tab, [["label", "Selskap"]]), MuiHelpers_createElement(Tab, [["label", "Avdelinger"]])])]])]),
                })), delay(() => {
                    if (model.SelectedView === 0) {
                        const matchValue_4 = model.SelectedCompany;
                        if (matchValue_4 != null) {
                            const company = matchValue_4;
                            return singleton(createElement(DrawCompanyEdit, {
                                company: company,
                                model: model,
                                dispatch: dispatch,
                            }));
                        }
                        else {
                            return singleton(drawCompanyList(model.Companies, dispatch));
                        }
                    }
                    else {
                        return singleton(createElement(ListDepartmentView, {
                            departments: model.Departments,
                            dispatch: dispatch,
                        }));
                    }
                }))));
            }));
        })))))))),
    });
}

export function CompanyConfigure() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchCompanies(dispatch);
        fetchOffices(dispatch);
        fetchDepartments(dispatch);
    });
    return view(patternInput[0], dispatch);
}

