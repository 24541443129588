import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Nonconformity_ProjectRegistration$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, string_type, option_type, bool_type, int32_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { uncurry, numberHash } from "../.fable/fable-library.3.2.4/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { interpolate, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { Text_secondaryText, snackbarError } from "../ViewHelpers.js";
import { drawRegistration, ImagePreview } from "./ProjectNonconformity.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";

class Model extends Record {
    constructor(Entries, LastIdx, IsMore, SelectedImage, ErrorMsg) {
        super();
        this.Entries = Entries;
        this.LastIdx = (LastIdx | 0);
        this.IsMore = IsMore;
        this.SelectedImage = SelectedImage;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("NonconformityFeed.Model", [], Model, () => [["Entries", array_type(Nonconformity_ProjectRegistration$reflection())], ["LastIdx", int32_type], ["IsMore", bool_type], ["SelectedImage", option_type(int32_type)], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EntriesResponse", "SelectImage", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("NonconformityFeed.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Nonconformity_ProjectRegistration$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Nonconformity_ProjectRegistration$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(int32_type)]], []]);
}

function init() {
    return new Model([], 0, true, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.Entries, model.LastIdx, model.IsMore, msg.fields[0], model.ErrorMsg);
        }
        case 2: {
            return new Model(model.Entries, model.LastIdx, model.IsMore, model.SelectedImage, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 0) {
                const x = res.fields[0];
                const y_1 = Array_distinctBy((z) => z.Id, append(model.Entries, x), {
                    Equals: (x_1, y) => (x_1 === y),
                    GetHashCode: (x_1) => numberHash(x_1),
                });
                return new Model(y_1, model.LastIdx + x.length, (x.length > 0) ? true : (y_1.length !== model.Entries.length), model.SelectedImage, model.ErrorMsg);
            }
            else {
                return new Model(model.Entries, model.LastIdx, model.IsMore, model.SelectedImage, "Kunne ikke laste registreringer");
            }
        }
    }
}

function fetchMore(lastIdx, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(interpolate("/api/nonconformity/feed?idx=%P()", [lastIdx]));
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Nonconformity_ProjectRegistration$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Nonconformity_ProjectRegistration$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

export function Feed() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchMore(model_1.LastIdx, dispatch);
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model_1.ErrorMsg, () => {
            dispatch(new Message(2));
        })), delay(() => {
            let matchValue, si;
            return append_1((matchValue = model_1.SelectedImage, (matchValue != null) ? ((si = (matchValue | 0), singleton(createElement(ImagePreview, {
                imageId: si,
                onClose: () => {
                    dispatch(new Message(1, void 0));
                },
            })))) : ((empty_1()))), delay(() => append_1(singleton(map((y) => createElement("div", {
                style: {
                    width: 100 + "%",
                    maxWidth: 900,
                },
                children: Interop_reactApi.Children.toArray([drawRegistration(y, false, true, (value_10) => {
                }, (i) => {
                    dispatch(new Message(1, i));
                })]),
            }), model_1.Entries)), delay(() => (model_1.IsMore ? singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Last mer"], ["onClick", (_arg4) => {
                fetchMore(model_1.LastIdx, dispatch);
            }]])) : singleton(Text_secondaryText("Alle registreringer lastet")))))));
        })))))),
    });
}

