import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Waste_EditForm$reflection, Waste_EditForm, Waste_ControlEntry, Waste_EditEntry, Waste_ProjectRegistration, Helpers_ResultMessage$reflection, Waste_EditEntry$reflection, Company$reflection, ProjectMeta$reflection, Waste_WasteType$reflection, Waste_ControlEntry$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, class_type, int32_type, array_type, record_type, option_type, bool_type, string_type, float64_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { parse as parse_1, toString, addMonths, now } from "../.fable/fable-library.3.2.4/Date.js";
import { fromUtcToLocalDate, asAbbrivatedMonth, toFirstDayOfMonth } from "../Utils.js";
import { parse } from "../.fable/fable-library.3.2.4/Double.js";
import { replace, printf, toText, format } from "../.fable/fable-library.3.2.4/String.js";
import { sortBy, tryFind, sort, tryHead, append, map } from "../.fable/fable-library.3.2.4/Array.js";
import { uncurry, comparePrimitives } from "../.fable/fable-library.3.2.4/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { empty as empty_1, singleton, append as append_1, delay, toList, fold } from "../.fable/fable-library.3.2.4/Seq.js";
import { some, toArray } from "../.fable/fable-library.3.2.4/Option.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { snackbarError, errorHeader, loadingIndicatorSmall, PageElements_modalNoSubmit, loadingIndicatorCenteredSmall } from "../ViewHelpers.js";
import { parse as parse_2 } from "../.fable/fable-library.3.2.4/Int32.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import Button from "@material-ui/core/Button";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { Company_fetchCompanies } from "../Promises.js";

class EditForm extends Record {
    constructor(Entry, NewQuantity, NewDirectQuantity, NewRecycled, Reason, ValidQuantity, ValidDirectQuantity, ValidRecycled, ErrorMsg, Processing) {
        super();
        this.Entry = Entry;
        this.NewQuantity = NewQuantity;
        this.NewDirectQuantity = NewDirectQuantity;
        this.NewRecycled = NewRecycled;
        this.Reason = Reason;
        this.ValidQuantity = ValidQuantity;
        this.ValidDirectQuantity = ValidDirectQuantity;
        this.ValidRecycled = ValidRecycled;
        this.ErrorMsg = ErrorMsg;
        this.Processing = Processing;
    }
}

function EditForm$reflection() {
    return record_type("WasteControl.EditForm", [], EditForm, () => [["Entry", Waste_ControlEntry$reflection()], ["NewQuantity", float64_type], ["NewDirectQuantity", float64_type], ["NewRecycled", float64_type], ["Reason", string_type], ["ValidQuantity", bool_type], ["ValidDirectQuantity", bool_type], ["ValidRecycled", bool_type], ["ErrorMsg", option_type(string_type)], ["Processing", bool_type]]);
}

class Model extends Record {
    constructor(Entries, Types, Projects, Companies, LastIdx, FromDate, ToDate, More, SelectedEdit, Edit, SelectedType, SelectedCompany, SelectedProject, ErrorMsg) {
        super();
        this.Entries = Entries;
        this.Types = Types;
        this.Projects = Projects;
        this.Companies = Companies;
        this.LastIdx = LastIdx;
        this.FromDate = FromDate;
        this.ToDate = ToDate;
        this.More = More;
        this.SelectedEdit = SelectedEdit;
        this.Edit = Edit;
        this.SelectedType = SelectedType;
        this.SelectedCompany = SelectedCompany;
        this.SelectedProject = SelectedProject;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("WasteControl.Model", [], Model, () => [["Entries", option_type(array_type(Waste_ControlEntry$reflection()))], ["Types", array_type(Waste_WasteType$reflection())], ["Projects", array_type(ProjectMeta$reflection())], ["Companies", array_type(Company$reflection())], ["LastIdx", option_type(int32_type)], ["FromDate", class_type("System.DateTime")], ["ToDate", class_type("System.DateTime")], ["More", bool_type], ["SelectedEdit", option_type(Waste_EditEntry$reflection())], ["Edit", option_type(EditForm$reflection())], ["SelectedType", option_type(Waste_WasteType$reflection())], ["SelectedCompany", option_type(Company$reflection())], ["SelectedProject", option_type(ProjectMeta$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EntriesResponse", "TypesResponse", "ProjectsResponse", "CompaniesResponse", "UpdateFrom", "UpdateTo", "ToggleShowEdit", "SelectReg", "AbortEdit", "UpdateQuantity", "UpdateDirectQuantity", "UpdateRecycled", "UpdateReason", "InvalidateQuantity", "InvalidateDirectQuantity", "InvalidateRecycled", "CommitEdit", "EditResponse", "SetType", "SetProject", "SetCompany", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("WasteControl.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Waste_ControlEntry$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Waste_ControlEntry$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Waste_WasteType$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Waste_WasteType$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectMeta$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectMeta$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", option_type(Waste_EditEntry$reflection())]], [["Item", Waste_ControlEntry$reflection()]], [], [["Item", float64_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", string_type]], [], [], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(Waste_WasteType$reflection())]], [["Item", option_type(ProjectMeta$reflection())]], [["Item", option_type(Company$reflection())]], []]);
}

function init() {
    let copyOfStruct;
    return new Model(void 0, [], [], [], void 0, now(), toFirstDayOfMonth((copyOfStruct = now(), addMonths(copyOfStruct, -2))), true, void 0, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    let matchValue_10;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Entries, res_1.fields[0], model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, "Noe gikk galt. Kunne ikke laste prosjekter.");
            }
            else {
                return new Model(model.Entries, model.Types, res_2.fields[0], model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, "Noe gikk galt. Kunne ikke laste selskap.");
            }
            else {
                return new Model(model.Entries, model.Types, model.Projects, res_3.fields[0], model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
        }
        case 4: {
            return new Model(void 0, model.Types, model.Projects, model.Companies, void 0, msg.fields[0], model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
        }
        case 5: {
            return new Model(void 0, model.Types, model.Projects, model.Companies, void 0, model.FromDate, msg.fields[0], model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
        }
        case 6: {
            return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, msg.fields[0], model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
        }
        case 7: {
            const x_8 = msg.fields[0];
            return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(x_8, parse(format('{0:' + "0.00" + '}', x_8.Registration.Quantity)), parse(format('{0:' + "0.00" + '}', x_8.Registration.DirectlyDelivered)), parse(format('{0:' + "0.00" + '}', x_8.Registration.Recycled)), "", true, true, true, void 0, false), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
        }
        case 8: {
            return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, void 0, model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
        }
        case 9: {
            const x_9 = msg.fields[0];
            const matchValue_1 = model.Edit;
            if (matchValue_1 != null) {
                const y_3 = matchValue_1;
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(y_3.Entry, x_9, y_3.NewDirectQuantity, y_3.NewRecycled, y_3.Reason, x_9 >= 0, y_3.ValidDirectQuantity, y_3.ValidRecycled, y_3.ErrorMsg, y_3.Processing), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 10: {
            const x_10 = msg.fields[0];
            const matchValue_2 = model.Edit;
            if (matchValue_2 != null) {
                const y_4 = matchValue_2;
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(y_4.Entry, y_4.NewQuantity, x_10, y_4.NewRecycled, y_4.Reason, y_4.ValidQuantity, x_10 >= 0, y_4.ValidRecycled, y_4.ErrorMsg, y_4.Processing), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 11: {
            const x_11 = msg.fields[0];
            const matchValue_3 = model.Edit;
            if (matchValue_3 != null) {
                const y_5 = matchValue_3;
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(y_5.Entry, y_5.NewQuantity, y_5.NewDirectQuantity, x_11, y_5.Reason, y_5.ValidQuantity, y_5.ValidDirectQuantity, x_11 >= 0, y_5.ErrorMsg, y_5.Processing), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 12: {
            const matchValue_4 = model.Edit;
            if (matchValue_4 == null) {
                return model;
            }
            else {
                const y_6 = matchValue_4;
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(y_6.Entry, y_6.NewQuantity, y_6.NewDirectQuantity, y_6.NewRecycled, msg.fields[0], y_6.ValidQuantity, y_6.ValidDirectQuantity, y_6.ValidRecycled, y_6.ErrorMsg, y_6.Processing), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
        }
        case 13: {
            const matchValue_5 = model.Edit;
            if (matchValue_5 == null) {
                return model;
            }
            else {
                const y_7 = matchValue_5;
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(y_7.Entry, y_7.NewQuantity, y_7.NewDirectQuantity, y_7.NewRecycled, y_7.Reason, false, y_7.ValidDirectQuantity, y_7.ValidRecycled, y_7.ErrorMsg, y_7.Processing), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
        }
        case 14: {
            const matchValue_6 = model.Edit;
            if (matchValue_6 == null) {
                return model;
            }
            else {
                const y_8 = matchValue_6;
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(y_8.Entry, y_8.NewQuantity, y_8.NewDirectQuantity, y_8.NewRecycled, y_8.Reason, y_8.ValidQuantity, false, y_8.ValidRecycled, y_8.ErrorMsg, y_8.Processing), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
        }
        case 15: {
            const matchValue_7 = model.Edit;
            if (matchValue_7 != null) {
                const y_9 = matchValue_7;
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(y_9.Entry, y_9.NewQuantity, y_9.NewDirectQuantity, y_9.NewRecycled, y_9.Reason, y_9.ValidQuantity, y_9.ValidDirectQuantity, false, y_9.ErrorMsg, y_9.Processing), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 16: {
            const matchValue_8 = model.Edit;
            if (matchValue_8 == null) {
                return model;
            }
            else {
                const x_13 = matchValue_8;
                if (x_13.ValidQuantity) {
                    return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(x_13.Entry, x_13.NewQuantity, x_13.NewDirectQuantity, x_13.NewRecycled, x_13.Reason, x_13.ValidQuantity, x_13.ValidDirectQuantity, x_13.ValidRecycled, void 0, true), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
                }
                else {
                    return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(x_13.Entry, x_13.NewQuantity, x_13.NewDirectQuantity, x_13.NewRecycled, x_13.Reason, x_13.ValidQuantity, x_13.ValidDirectQuantity, x_13.ValidRecycled, "Ugyldig ny verdi", x_13.Processing), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
                }
            }
        }
        case 17: {
            const res_4 = msg.fields[0];
            const matchValue_9 = model.Edit;
            if (matchValue_9 == null) {
                return model;
            }
            else {
                const edit = matchValue_9;
                if (res_4.tag === 1) {
                    const err_3 = res_4.fields[0];
                    return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(edit.Entry, edit.NewQuantity, edit.NewDirectQuantity, edit.NewRecycled, edit.Reason, edit.ValidQuantity, edit.ValidDirectQuantity, edit.ValidRecycled, (err_3.tag === 2) ? (((err_3.fields[0].status) === 401) ? "Du har ikke rettigheter til å endre denne registreringen." : "Noe gikk galt. Kunne ikke endre registrering.") : "Noe gikk galt. Kunne ikke endre registrering.", false), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
                }
                else {
                    const x_14 = res_4.fields[0];
                    if (x_14.Result === "success") {
                        return new Model((matchValue_10 = model.Entries, (matchValue_10 != null) ? map((e) => {
                            let inputRecord, copyOfStruct;
                            if (e.Registration.Id === edit.Entry.Registration.Id) {
                                return new Waste_ControlEntry((inputRecord = e.Registration, new Waste_ProjectRegistration(inputRecord.Id, inputRecord.ProjectId, inputRecord.Registered, edit.NewQuantity, edit.NewDirectQuantity, inputRecord.Recycled, inputRecord.Type)), e.Project, e.ProjectId, e.Company, e.CompanyId, e.Month, e.Year, new Waste_EditEntry(-1, e.Registration.Quantity, edit.NewQuantity, e.Registration.DirectlyDelivered, edit.NewDirectQuantity, e.Registration.Recycled, edit.NewRecycled, edit.Reason, loggedInUsername, (copyOfStruct = now(), toString(copyOfStruct, "yyyy-MM-dd")), 0), e.ByUser);
                            }
                            else {
                                return e;
                            }
                        }, matchValue_10) : (void 0)), model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, void 0, model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
                    }
                    else {
                        return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, new EditForm(edit.Entry, edit.NewQuantity, edit.NewDirectQuantity, edit.NewRecycled, edit.Reason, edit.ValidQuantity, edit.ValidDirectQuantity, edit.ValidRecycled, x_14.Message, false), model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
                    }
                }
            }
        }
        case 18: {
            return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, msg.fields[0], model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
        }
        case 19: {
            return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, msg.fields[0], model.ErrorMsg);
        }
        case 20: {
            const x_19 = msg.fields[0];
            if (x_19 == null) {
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, void 0, model.SelectedProject, model.ErrorMsg);
            }
            else {
                const c = x_19;
                const matchValue_11 = model.SelectedProject;
                let pattern_matching_result;
                if (matchValue_11 != null) {
                    if (matchValue_11.CompanyId !== c.Id) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, c, void 0, model.ErrorMsg);
                    }
                    case 1: {
                        return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, c, model.SelectedProject, model.ErrorMsg);
                    }
                }
            }
        }
        case 21: {
            return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Entries, model.Types, model.Projects, model.Companies, model.LastIdx, model.FromDate, model.ToDate, model.More, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, "Noe gikk galt. Kunne ikke laste avfallsregistreringer.");
            }
            else {
                const x = res.fields[0];
                let y;
                const matchValue = model.Entries;
                y = ((matchValue != null) ? append(matchValue, x) : x);
                return new Model(y, model.Types, model.Projects, model.Companies, tryHead(sort(map((z_1) => z_1.Registration.Id, y, Int32Array), {
                    Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
                })), model.FromDate, model.ToDate, x.length >= 20, model.SelectedEdit, model.Edit, model.SelectedType, model.SelectedCompany, model.SelectedProject, model.ErrorMsg);
            }
        }
    }
}

function fetchEntries(lastIdx, from, until, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const i = fold((_arg1, x) => toText(printf("\u0026idx=%i"))(x), "", toArray(lastIdx));
        let f;
        const arg10_1 = toString(from, "yyyy-MM-dd");
        f = toText(printf("\u0026from=%s"))(arg10_1);
        let t;
        const arg10_2 = toString(until, "yyyy-MM-dd");
        t = toText(printf("\u0026to=%s"))(arg10_2);
        const requestPath = toText(printf("/api/waste/control?%s%s%s"))(f)(t)(i);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Waste_ControlEntry$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Waste_ControlEntry$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1_1) => {
            dispatch(new Message(0, _arg1_1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchProjects(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProjectMeta$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/waste/projects", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProjectMeta$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchTypes(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Waste_WasteType$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/waste/types", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Waste_WasteType$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function commitEdit(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(16));
        if (x.ValidQuantity) {
            const req = new Waste_EditForm(x.Entry.Registration.Id, x.NewQuantity, x.NewDirectQuantity, x.NewRecycled, x.Reason);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Waste_EditForm$reflection,
                })(req);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E("/api/waste/control/edit", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(17, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            return Promise.resolve();
        }
    }));
    pr.then();
}

function drawEntries(model, dispatch) {
    const children_12 = toList(delay(() => {
        let children_2, children, children_10;
        const matchValue = model.Entries;
        if (matchValue != null) {
            let filtered;
            let y_2;
            let y_1;
            const y = matchValue;
            const matchValue_1 = model.SelectedCompany;
            if (matchValue_1 != null) {
                const c = matchValue_1;
                y_1 = y.filter((z) => (z.CompanyId === c.Id));
            }
            else {
                y_1 = y;
            }
            const matchValue_2 = model.SelectedProject;
            if (matchValue_2 != null) {
                const p = matchValue_2;
                y_2 = y_1.filter((z_1) => (z_1.ProjectId === p.Id));
            }
            else {
                y_2 = y_1;
            }
            const matchValue_3 = model.SelectedType;
            if (matchValue_3 != null) {
                const t = matchValue_3;
                filtered = y_2.filter((z_2) => (z_2.Registration.Type.Id === t.Id));
            }
            else {
                filtered = y_2;
            }
            return append_1(singleton(createElement("table", {
                className: "table",
                children: Interop_reactApi.Children.toArray([(children_2 = singleton_1((children = ofArray([createElement("th", {
                    children: ["Dato reg."],
                }), createElement("th", {
                    children: ["Til godkj. avfallsanlegg (tonn)"],
                }), createElement("th", {
                    children: ["Direkte til ombruk"],
                }), createElement("th", {
                    children: ["Dirkte til gjenvinning"],
                }), createElement("th", {
                    children: ["Prosjekt"],
                }), createElement("th", {
                    children: ["Selskap"],
                }), createElement("th", {
                    children: ["Avfallstype"],
                }), createElement("th", {
                    children: ["Registrert av"],
                }), createElement("th", {})]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), (children_10 = singleton_1(map((y_3) => {
                    let value_10, arg10, value_11, value_12, value_13, children_4, children_6;
                    const children_8 = ofArray([(value_10 = ((arg10 = asAbbrivatedMonth(y_3.Month), toText(printf("%s %i"))(arg10)(y_3.Year))), createElement("td", {
                        children: [value_10],
                    })), (value_11 = format('{0:' + "0.000" + '}', y_3.Registration.Quantity), createElement("td", {
                        children: [value_11],
                    })), (value_12 = format('{0:' + "0.000" + '}', y_3.Registration.DirectlyDelivered), createElement("td", {
                        children: [value_12],
                    })), (value_13 = format('{0:' + "0.000" + '}', y_3.Registration.Recycled), createElement("td", {
                        children: [value_13],
                    })), createElement("td", {
                        children: [y_3.Project],
                    }), createElement("td", {
                        children: [y_3.Company],
                    }), createElement("td", {
                        children: [y_3.Registration.Type.Name],
                    }), createElement("td", {
                        children: [y_3.ByUser],
                    }), (children_4 = toList(delay(() => {
                        const matchValue_4 = y_3.Edit;
                        if (matchValue_4 != null) {
                            const e = matchValue_4;
                            return singleton(createElement("a", {
                                style: {
                                    fontSize: 0.8 + "rem",
                                    cursor: "pointer",
                                    color: "blue",
                                    textDecorationLine: "underline",
                                },
                                onClick: (_arg1) => {
                                    dispatch(new Message(6, e));
                                },
                                children: "Redigert",
                            }));
                        }
                        else {
                            return empty_1();
                        }
                    })), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })), (children_6 = singleton_1(createElement("button", {
                        className: "btn btn-xs icon-button",
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: "fas fa-edit",
                        })]),
                        onClick: (_arg2) => {
                            dispatch(new Message(7, y_3));
                        },
                    })), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    }))]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                    });
                }, filtered)), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                }))]),
            })), delay(() => (model.More ? singleton(createElement("div", {
                style: {},
                children: Interop_reactApi.Children.toArray([createElement("button", {
                    className: "btn btn-default",
                    children: "Last mer",
                    onClick: (_arg3) => {
                        fetchEntries(model.LastIdx, model.FromDate, model.ToDate, dispatch);
                    },
                })]),
            })) : empty_1())));
        }
        else {
            return singleton(loadingIndicatorCenteredSmall());
        }
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_12)),
    });
}

function searchItem(lbl, children) {
    return createElement("div", {
        style: {
            display: "inline-block",
            marginRight: 5 + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {},
            children: Interop_reactApi.Children.toArray([createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                        fontWeight: "bold",
                    },
                    children: lbl,
                })]),
            }), createElement("div", {
                style: {},
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })]),
        })]),
    });
}

function searchBar(model, dispatch) {
    let matchValue_2, matchValue_4, x_7, matchValue_6, c_2, matchValue_7;
    return createElement("div", {
        style: {
            marginBottom: 20 + "px",
            display: "flex",
            flexWrap: "wrap",
        },
        children: Interop_reactApi.Children.toArray([searchItem("Fra", [createElement("input", {
            type: "date",
            className: "form-control",
            value: toString(model.FromDate, "yyyy-MM-dd"),
            style: {
                maxWidth: 150 + "px",
                ["grid-column"]: 1,
                ["grid-row"]: 2,
            },
            onChange: (ev) => {
                const x = ev.target.value;
                try {
                    const asDt = parse_1(x);
                    dispatch(new Message(4, asDt));
                    fetchEntries(void 0, asDt, model.ToDate, dispatch);
                }
                catch (matchValue) {
                }
            },
        })]), searchItem("Til", [createElement("input", {
            type: "date",
            className: "form-control",
            value: toString(model.ToDate, "yyyy-MM-dd"),
            style: {
                maxWidth: 150 + "px",
                ["grid-column"]: 2,
                ["grid-row"]: 2,
            },
            onChange: (ev_1) => {
                const x_1 = ev_1.target.value;
                try {
                    const asDt_1 = parse_1(x_1);
                    dispatch(new Message(5, asDt_1));
                    fetchEntries(void 0, model.FromDate, asDt_1, dispatch);
                }
                catch (matchValue_1) {
                }
            },
        })]), searchItem("Selskap", [createElement("select", {
            className: "form-control",
            value: (matchValue_2 = model.SelectedCompany, (matchValue_2 != null) ? matchValue_2.Id : -1),
            onChange: (ev_2) => {
                const x_2 = ev_2.target.value;
                try {
                    const asInt = parse_2(x_2, 511, false, 32) | 0;
                    if (asInt === -1) {
                        dispatch(new Message(20, void 0));
                    }
                    else {
                        const _arg1 = tryFind((y) => (y.Id === asInt), model.Companies);
                        if (_arg1 != null) {
                            dispatch(new Message(20, _arg1));
                        }
                    }
                }
                catch (matchValue_3) {
                }
            },
            children: Interop_reactApi.Children.toArray([createElement("option", {
                value: -1,
                children: "Ingen",
            }), map((x_5) => createElement("option", {
                value: x_5.Id,
                children: x_5.Name,
            }), sortBy((x_3) => x_3.Name, model.Companies, {
                Compare: (x_4, y_1) => comparePrimitives(x_4, y_1),
            }))]),
        })]), searchItem("Prosjekt", [createElement("select", {
            className: "form-control",
            value: (matchValue_4 = model.SelectedProject, (matchValue_4 != null) ? matchValue_4.Id : -1),
            onChange: (ev_3) => {
                const x_6 = ev_3.target.value;
                try {
                    const asInt_1 = parse_2(x_6, 511, false, 32) | 0;
                    if (asInt_1 === -1) {
                        dispatch(new Message(19, void 0));
                    }
                    else {
                        const _arg2 = tryFind((y_2) => (y_2.Id === asInt_1), model.Projects);
                        if (_arg2 != null) {
                            dispatch(new Message(19, _arg2));
                        }
                    }
                }
                catch (matchValue_5) {
                }
            },
            children: Interop_reactApi.Children.toArray([createElement("option", {
                value: -1,
                children: "Ingen",
            }), map((x_10) => createElement("option", {
                value: x_10.Id,
                children: x_10.Name,
            }), sortBy((x_8) => x_8.Name, (x_7 = model.Projects, (matchValue_6 = model.SelectedCompany, (matchValue_6 != null) ? ((c_2 = matchValue_6, x_7.filter((y_3) => (y_3.CompanyId === c_2.Id)))) : x_7)), {
                Compare: (x_9, y_4) => comparePrimitives(x_9, y_4),
            }))]),
        })]), searchItem("Type", [createElement("select", {
            className: "form-control",
            value: (matchValue_7 = model.SelectedType, (matchValue_7 != null) ? matchValue_7.Id : -1),
            onChange: (ev_4) => {
                const x_11 = ev_4.target.value;
                try {
                    const asInt_2 = parse_2(x_11, 511, false, 32) | 0;
                    if (asInt_2 === -1) {
                        dispatch(new Message(18, void 0));
                    }
                    else {
                        const _arg3 = tryFind((y_5) => (y_5.Id === asInt_2), model.Types);
                        if (_arg3 != null) {
                            dispatch(new Message(18, _arg3));
                        }
                    }
                }
                catch (matchValue_8) {
                }
            },
            children: Interop_reactApi.Children.toArray([createElement("option", {
                value: -1,
                children: "Ingen",
            }), map((x_14) => createElement("option", {
                value: x_14.Id,
                children: x_14.Name,
            }), sortBy((x_12) => x_12.Name, model.Types, {
                Compare: (x_13, y_6) => comparePrimitives(x_13, y_6),
            }))]),
        })])]),
    });
}

function drawEditInfo(x, dispatch) {
    let children_2, children, children_16, children_4, value_5, children_6, value_7, children_8, value_9, children_10, value_11, children_12, value_13, children_14, value_15, value_17, arg20, children_18;
    return PageElements_modalNoSubmit("", (_arg1) => {
        dispatch(new Message(6, void 0));
    }, [createElement("table", {
        className: "table",
        children: Interop_reactApi.Children.toArray([(children_2 = singleton_1((children = ofArray([createElement("th", {}), createElement("th", {
            children: ["Verdi"],
        }), createElement("th", {
            children: ["Registreringsdato"],
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_16 = ofArray([(children_4 = ofArray([createElement("th", {
            children: ["Gammel mengde levert til godkjent avfallsanlegg"],
        }), (value_5 = format('{0:' + "0.000" + '}', x.OldVal), createElement("td", {
            children: [value_5],
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        })), (children_6 = ofArray([createElement("th", {
            children: ["Ny mengde levert til godkjent avfallsanlegg"],
        }), (value_7 = format('{0:' + "0.000" + '}', x.NewVal), createElement("td", {
            children: [value_7],
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        })), (children_8 = ofArray([createElement("th", {
            children: ["Gammel mengde levert direkte til ombruk"],
        }), (value_9 = format('{0:' + "0.000" + '}', x.OldDirectVal), createElement("td", {
            children: [value_9],
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        })), (children_10 = ofArray([createElement("th", {
            children: ["Ny mengde levert direkte til ombruk"],
        }), (value_11 = format('{0:' + "0.000" + '}', x.NewDirectVal), createElement("td", {
            children: [value_11],
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
        })), (children_12 = ofArray([createElement("th", {
            children: ["Gammel mengde levert direkte til gjenvinning"],
        }), (value_13 = format('{0:' + "0.000" + '}', x.OldRecycled), createElement("td", {
            children: [value_13],
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_12)),
        })), (children_14 = ofArray([createElement("th", {
            children: ["Ny mengde levert direkte til gjenvinning"],
        }), (value_15 = format('{0:' + "0.000" + '}', x.NewRecycled), createElement("td", {
            children: [value_15],
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
        }))]), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_16)),
        }))]),
    }), (value_17 = ((arg20 = fromUtcToLocalDate(x.EditedAt), toText(printf("Redigert av %s %s"))(x.EditedBy)(arg20))), createElement("span", {
        children: [value_17],
    })), createElement("br", {}), createElement("br", {}), (children_18 = singleton_1(createElement("b", {
        children: ["Kommentar:"],
    })), createElement("span", {
        children: Interop_reactApi.Children.toArray(Array.from(children_18)),
    })), createElement("br", {}), createElement("p", {
        children: [x.Comment],
    })]);
}

function drawEdit(x, dispatch) {
    return PageElements_modalNoSubmit("Endre registrering", (_arg1) => {
        dispatch(new Message(8));
    }, [createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("span", {
            style: {
                fontWeight: "bold",
            },
            children: "Ny mengde levert til godkjent avfallsanlegg",
        })), delay(() => append_1(singleton(MuiHelpers_createElement(TextField, [["label", "Vekt i tonn"], ["defaultValue", format('{0:' + "0.000" + '}', x.NewQuantity)], ["onChange", (e) => {
            const x_1 = Browser_Types_Event__Event_get_Value(e);
            try {
                const asFloat = parse(replace(x_1, ",", "."));
                if (asFloat >= 0) {
                    dispatch(new Message(9, asFloat));
                }
                else {
                    dispatch(new Message(13));
                }
            }
            catch (matchValue) {
                dispatch(new Message(13));
            }
        }], ["error", !x.ValidQuantity], ["size", "small"], ["variant", "outlined"], ["style", {
            maxWidth: 200,
            marginBottom: 10,
        }]])), delay(() => append_1(singleton(createElement("span", {
            style: {
                fontWeight: "bold",
            },
            children: "Ny mengde levert direkte til ombruk",
        })), delay(() => append_1(singleton(MuiHelpers_createElement(TextField, [["label", "Vekt i tonn"], ["defaultValue", format('{0:' + "0.000" + '}', x.NewDirectQuantity)], ["onChange", (e_1) => {
            const x_2 = Browser_Types_Event__Event_get_Value(e_1);
            try {
                const asFloat_1 = parse(replace(x_2, ",", "."));
                if (asFloat_1 >= 0) {
                    dispatch(new Message(10, asFloat_1));
                }
                else {
                    dispatch(new Message(14));
                }
            }
            catch (matchValue_1) {
                dispatch(new Message(14));
            }
        }], ["error", !x.ValidDirectQuantity], ["size", "small"], ["variant", "outlined"], ["style", {
            maxWidth: 200,
            marginBottom: 10,
        }]])), delay(() => append_1(singleton(createElement("span", {
            style: {
                fontWeight: "bold",
            },
            children: "Ny mengde levert direkte til gjennvinning",
        })), delay(() => append_1(singleton(MuiHelpers_createElement(TextField, [["label", "Vekt i tonn"], ["defaultValue", format('{0:' + "0.000" + '}', x.NewRecycled)], ["onChange", (e_2) => {
            const x_3 = Browser_Types_Event__Event_get_Value(e_2);
            try {
                const asFloat_2 = parse(replace(x_3, ",", "."));
                if (asFloat_2 >= 0) {
                    dispatch(new Message(11, asFloat_2));
                }
                else {
                    dispatch(new Message(15));
                }
            }
            catch (matchValue_2) {
                dispatch(new Message(15));
            }
        }], ["error", !x.ValidRecycled], ["size", "small"], ["variant", "outlined"], ["style", {
            maxWidth: 200,
            marginBottom: 10,
        }]])), delay(() => append_1(singleton(MuiHelpers_createElement(TextField, [["size", "small"], ["label", "Begrunnelse"], ["variant", "outlined"], ["onChange", (e_3) => {
            dispatch(new Message(12, Browser_Types_Event__Event_get_Value(e_3)));
        }], ["multiline", true]])), delay(() => append_1(singleton(createElement("div", {
            style: {
                marginTop: 10 + "px",
                marginBottom: 10 + "px",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!x.Processing) ? singleton(MuiHelpers_createElement(Button, [["size", "small"], ["children", "Lagre endring"], ["onClick", (_arg2) => {
                commitEdit(x, dispatch);
            }]])) : singleton(loadingIndicatorSmall())))))),
        })), delay(() => {
            const matchValue_3 = x.ErrorMsg;
            if (matchValue_3 != null) {
                return singleton(errorHeader(matchValue_3));
            }
            else {
                return empty_1();
            }
        })))))))))))))))))))),
    })]);
}

export function WasteControl() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchEntries(void 0, model_1.FromDate, model_1.ToDate, dispatch);
        fetchProjects(dispatch);
        Company_fetchCompanies((arg) => {
            dispatch(new Message(3, arg));
        });
        fetchTypes(dispatch);
    });
    return createElement("div", {
        style: {
            padding: 10,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model_1.ErrorMsg, () => {
            dispatch(new Message(21));
        })), delay(() => append_1(singleton(createElement("h3", {
            style: {
                marginTop: 10 + "px",
            },
            children: "Kontrollering av sorteringsgrad registreringer",
        })), delay(() => append_1(singleton(searchBar(model_1, dispatch)), delay(() => append_1(singleton(drawEntries(model_1, dispatch)), delay(() => {
            let matchValue;
            return append_1((matchValue = model_1.Edit, (matchValue != null) ? singleton(drawEdit(matchValue, dispatch)) : ((empty_1()))), delay(() => {
                const matchValue_1 = model_1.SelectedEdit;
                if (matchValue_1 != null) {
                    return singleton(drawEditInfo(matchValue_1, dispatch));
                }
                else {
                    return empty_1();
                }
            }));
        })))))))))))),
    });
}

