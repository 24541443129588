import { StyleCreator$1_$ctor, StyleCreator$1__create_4EAC9E1E, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, item as item_2, length, singleton } from "../.fable/fable-library.3.2.4/List.js";
import { useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { compare, parse as parse_1, toString, addYears, now } from "../.fable/fable-library.3.2.4/Date.js";
import { Helpers_ResultMessage$reflection, ProcessModule_ProcessActivityDoc$reflection, ProcessModule_ProcessActivityDocForm$reflection, ProcessModule_ProcessActivityDocForm, ProcessModule_ProcessActivityDoc } from "../Shared/ApiDataTypes.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import { map, empty, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { errorBanner, loadingIndicatorSmall, PageElements_filenameToIcon, snackbarError } from "../ViewHelpers.js";
import { uncurry, createObj } from "../.fable/fable-library.3.2.4/Util.js";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPut_5760677E, Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { string_type, obj_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import IconButton from "@material-ui/core/IconButton";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = StyleImports_makeStyles_get((arg) => {
    const o = {
        tf: StyleCreator$1__create_4EAC9E1E(StyleCreator$1_$ctor(), singleton(["margin", 10])),
    };
    return Object.assign({}, o);
});

export function DocumentForm(documentFormInputProps) {
    let copyOfStruct_1, copyOfStruct;
    const onClose = documentFormInputProps.onClose;
    const defaultRowPos = documentFormInputProps.defaultRowPos;
    const activityId = documentFormInputProps.activityId;
    const updating = documentFormInputProps.updating;
    const patternInput = useFeliz_React__React_useState_Static_1505(new ProcessModule_ProcessActivityDocForm((updating == null) ? (new ProcessModule_ProcessActivityDoc(-1, activityId, "", "", void 0, "", "", (copyOfStruct_1 = ((copyOfStruct = now(), addYears(copyOfStruct, 1))), toString(copyOfStruct_1, "yyyy-MM-dd")), void 0, [], "", false, "", defaultRowPos, "1.0", [])) : updating, "", ""));
    const updateForm = patternInput[1];
    const documentForm = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const setValidName = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const validRowPos = patternInput_2[0];
    const setValidRowPos = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const result = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrorMsg = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const docSelected = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_6[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", (updating == null) ? "Legg til dokument" : "Oppdater dokument"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(snackbarError(patternInput_4[0], () => {
            setErrorMsg(void 0);
        })), delay(() => {
            let value_138;
            return (result == null) ? append(singleton_1(createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("label", {
                    style: {
                        cursor: "pointer",
                        textAlign: "center",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        style: createObj(toList(delay(() => append(singleton_1(["display", "block"]), delay(() => append(singleton_1(["fontSize", 2 + "rem"]), delay(() => ((updating != null) ? singleton_1(["color", "#acacac"]) : empty())))))))),
                        className: "fas fa-cloud-upload-alt",
                    }), (updating == null) ? ((!docSelected) ? "Velg fil" : "Velg en annen fil") : documentForm.Entry.Filename, createElement("input", {
                        type: "file",
                        disabled: updating != null,
                        onChange: (ev) => {
                            let arg10, arg20;
                            const fileList = ev.target.files;
                            if (!(fileList == null)) {
                                const x_2 = toList(delay(() => map((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                                try {
                                    if (length(x_2) > 0) {
                                        const file = item_2(0, x_2);
                                        const filename = file.name;
                                        console.log(some((arg10 = (file.size | 0), toText(printf("filesize: %i"))(arg10))));
                                        console.log(some((arg20 = file.name, toText(printf("filename: %s (%s)"))(filename)(arg20))));
                                        const reader = new FileReader();
                                        reader.addEventListener("load", (_arg3) => {
                                            let inputRecord;
                                            const content = reader.result;
                                            patternInput_5[1](true);
                                            if (documentForm.Entry.Name === "") {
                                                updateForm(new ProcessModule_ProcessActivityDocForm((inputRecord = documentForm.Entry, new ProcessModule_ProcessActivityDoc(inputRecord.Id, inputRecord.ActivityId, inputRecord.DriveId, inputRecord.DriveUrl, inputRecord.SupDoc, filename, inputRecord.Filename, inputRecord.NextRevision, inputRecord.LastRevision, inputRecord.Tags, inputRecord.Desc, inputRecord.CompanyVersions, inputRecord.RowPrefix, inputRecord.RowPos, inputRecord.Version, inputRecord.Revisions)), filename, content));
                                            }
                                            else {
                                                updateForm(new ProcessModule_ProcessActivityDocForm(documentForm.Entry, filename, content));
                                            }
                                        });
                                        const value_29 = reader.readAsDataURL(file);
                                    }
                                }
                                catch (matchValue) {
                                }
                            }
                        },
                        style: {
                            display: "none",
                        },
                    })]),
                })), delay(() => (((!docSelected) ? (updating == null) : false) ? singleton_1(createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                    },
                    children: "Ingen fil valgt",
                })) : ((docSelected ? (updating == null) : false) ? singleton_1(createElement("span", {
                    children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(documentForm.Filename), documentForm.Filename]),
                })) : empty())))))))),
            })), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["autoFocus", true], ["fullWidth", true], ["onChange", (e) => {
                let inputRecord_1;
                updateForm(new ProcessModule_ProcessActivityDocForm((inputRecord_1 = documentForm.Entry, new ProcessModule_ProcessActivityDoc(inputRecord_1.Id, inputRecord_1.ActivityId, inputRecord_1.DriveId, inputRecord_1.DriveUrl, inputRecord_1.SupDoc, inputRecord_1.Name, inputRecord_1.Filename, inputRecord_1.NextRevision, inputRecord_1.LastRevision, inputRecord_1.Tags, inputRecord_1.Desc, inputRecord_1.CompanyVersions, Browser_Types_Event__Event_get_Value(e), inputRecord_1.RowPos, inputRecord_1.Version, inputRecord_1.Revisions)), documentForm.Filename, documentForm.FileContent));
            }], ["label", "Dokumentkode/prefix (valgfritt)"], ["defaultValue", documentForm.Entry.RowPrefix], ["size", "small"], ["variant", "outlined"]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append(singleton_1(["onChange", (e_1) => {
                let inputRecord_2;
                const x_4 = Browser_Types_Event__Event_get_Value(e_1);
                updateForm(new ProcessModule_ProcessActivityDocForm((inputRecord_2 = documentForm.Entry, new ProcessModule_ProcessActivityDoc(inputRecord_2.Id, inputRecord_2.ActivityId, inputRecord_2.DriveId, inputRecord_2.DriveUrl, inputRecord_2.SupDoc, x_4, inputRecord_2.Filename, inputRecord_2.NextRevision, inputRecord_2.LastRevision, inputRecord_2.Tags, inputRecord_2.Desc, inputRecord_2.CompanyVersions, inputRecord_2.RowPrefix, inputRecord_2.RowPos, inputRecord_2.Version, inputRecord_2.Revisions)), documentForm.Filename, documentForm.FileContent));
                setValidName(x_4.length > 0);
            }]), delay(() => append(singleton_1(["label", "Dokumentnavn"]), delay(() => append(singleton_1(["defaultValue", documentForm.Entry.Name]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => ((!patternInput_1[0]) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Dokumentnavn kan ikke være tomt"]))) : empty()))))))))))))))))))))), delay(() => append((documentForm.Entry.RowPrefix.length > 0) ? singleton_1(createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    marginLeft: 10,
                    fontStyle: "italic",
                },
                children: toText(printf("%s - %s"))(documentForm.Entry.RowPrefix)(documentForm.Entry.Name),
            })) : empty(), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["size", "small"], ["fullWidth", true], ["multiline", true], ["onChange", (e_2) => {
                let inputRecord_3;
                updateForm(new ProcessModule_ProcessActivityDocForm((inputRecord_3 = documentForm.Entry, new ProcessModule_ProcessActivityDoc(inputRecord_3.Id, inputRecord_3.ActivityId, inputRecord_3.DriveId, inputRecord_3.DriveUrl, inputRecord_3.SupDoc, inputRecord_3.Name, inputRecord_3.Filename, inputRecord_3.NextRevision, inputRecord_3.LastRevision, inputRecord_3.Tags, Browser_Types_Event__Event_get_Value(e_2), inputRecord_3.CompanyVersions, inputRecord_3.RowPrefix, inputRecord_3.RowPos, inputRecord_3.Version, inputRecord_3.Revisions)), documentForm.Filename, documentForm.FileContent));
            }], ["label", "Beskrivelse"], ["defaultValue", documentForm.Entry.Desc], ["variant", "outlined"]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["onChange", (e_3) => {
                let inputRecord_4;
                const x_6 = Browser_Types_Event__Event_get_Value(e_3);
                try {
                    const asInt = parse(x_6, 511, false, 32) | 0;
                    updateForm(new ProcessModule_ProcessActivityDocForm((inputRecord_4 = documentForm.Entry, new ProcessModule_ProcessActivityDoc(inputRecord_4.Id, inputRecord_4.ActivityId, inputRecord_4.DriveId, inputRecord_4.DriveUrl, inputRecord_4.SupDoc, inputRecord_4.Name, inputRecord_4.Filename, inputRecord_4.NextRevision, inputRecord_4.LastRevision, inputRecord_4.Tags, inputRecord_4.Desc, inputRecord_4.CompanyVersions, inputRecord_4.RowPrefix, asInt, inputRecord_4.Version, inputRecord_4.Revisions)), documentForm.Filename, documentForm.FileContent));
                    setValidRowPos(asInt > 0);
                }
                catch (matchValue_1) {
                    setValidRowPos(false);
                }
            }]), delay(() => append(singleton_1(["label", "Radnummer"]), delay(() => append(singleton_1(["defaultValue", documentForm.Entry.RowPos]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["inputProps", {
                style: {
                    textAlign: "center",
                    maxWidth: 80,
                },
            }]), delay(() => ((!validRowPos) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Ugyldig nummer. Oppgi ett nummer større eller lik 1."]))) : empty()))))))))))))))))))), delay(() => singleton_1(createElement("div", {
                style: {
                    marginTop: 5,
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column",
                    padding: ((0 + "px ") + 10) + "px",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["checked", documentForm.Entry.NextRevision != null], ["onChange", (e_4) => {
                    let inputRecord_5, copyOfStruct_3, copyOfStruct_2, inputRecord_6;
                    if (Browser_Types_Event__Event_get_Checked(e_4)) {
                        updateForm(new ProcessModule_ProcessActivityDocForm((inputRecord_5 = documentForm.Entry, new ProcessModule_ProcessActivityDoc(inputRecord_5.Id, inputRecord_5.ActivityId, inputRecord_5.DriveId, inputRecord_5.DriveUrl, inputRecord_5.SupDoc, inputRecord_5.Name, inputRecord_5.Filename, (copyOfStruct_3 = ((copyOfStruct_2 = now(), addYears(copyOfStruct_2, 1))), toString(copyOfStruct_3, "yyyy-MM-dd")), inputRecord_5.LastRevision, inputRecord_5.Tags, inputRecord_5.Desc, inputRecord_5.CompanyVersions, inputRecord_5.RowPrefix, inputRecord_5.RowPos, inputRecord_5.Version, inputRecord_5.Revisions)), documentForm.Filename, documentForm.FileContent));
                    }
                    else {
                        updateForm(new ProcessModule_ProcessActivityDocForm((inputRecord_6 = documentForm.Entry, new ProcessModule_ProcessActivityDoc(inputRecord_6.Id, inputRecord_6.ActivityId, inputRecord_6.DriveId, inputRecord_6.DriveUrl, inputRecord_6.SupDoc, inputRecord_6.Name, inputRecord_6.Filename, void 0, inputRecord_6.LastRevision, inputRecord_6.Tags, inputRecord_6.Desc, inputRecord_6.CompanyVersions, inputRecord_6.RowPrefix, inputRecord_6.RowPos, inputRecord_6.Version, inputRecord_6.Revisions)), documentForm.Filename, documentForm.FileContent));
                    }
                }], ["color", "primary"]])], ["label", "Inkl. neste revisjonsdato"]])), delay(() => {
                    const matchValue_2 = documentForm.Entry.NextRevision;
                    if (matchValue_2 != null) {
                        return singleton_1(MuiHelpers_createElement(TextField, [["label", "Neste revisjon"], ["type", "date"], ["value", matchValue_2], ["style", {
                            maxWidth: 200,
                        }], ["onChange", (e_5) => {
                            let inputRecord_7;
                            const x_7 = Browser_Types_Event__Event_get_Value(e_5);
                            try {
                                const asDateTime = parse_1(x_7);
                                if (compare(asDateTime, now()) > 0) {
                                    updateForm(new ProcessModule_ProcessActivityDocForm((inputRecord_7 = documentForm.Entry, new ProcessModule_ProcessActivityDoc(inputRecord_7.Id, inputRecord_7.ActivityId, inputRecord_7.DriveId, inputRecord_7.DriveUrl, inputRecord_7.SupDoc, inputRecord_7.Name, inputRecord_7.Filename, toString(asDateTime, "yyyy-MM-dd"), inputRecord_7.LastRevision, inputRecord_7.Tags, inputRecord_7.Desc, inputRecord_7.CompanyVersions, inputRecord_7.RowPrefix, inputRecord_7.RowPos, inputRecord_7.Version, inputRecord_7.Revisions)), documentForm.Filename, documentForm.FileContent));
                                }
                            }
                            catch (matchValue_3) {
                            }
                        }]]));
                    }
                    else {
                        return empty();
                    }
                })))))),
            })))))))))))))) : singleton_1((value_138 = ((updating == null) ? "Dokument lagt til" : "Dokument oppdatert"), createElement("span", {
                children: [value_138],
            })));
        })))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (patternInput_6[0] ? singleton_1(loadingIndicatorSmall()) : append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg4) => {
        onClose(result);
    }]])), delay(() => ((result == null) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", (updating == null) ? "Legg til" : "Oppdater"], ["onClick", (_arg5) => {
        if ((documentForm.Entry.Name.length > 0) ? validRowPos : false) {
            if ((updating != null) ? true : ((updating == null) ? docSelected : false)) {
                let pr;
                const x = documentForm;
                pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    setProcessing(true);
                    return ((updating == null) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: ProcessModule_ProcessActivityDocForm$reflection,
                        })(x);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: ProcessModule_ProcessActivityDoc$reflection,
                        });
                        return Fetch_tryPost_5760677E("/api/processactivitydoc", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: ProcessModule_ProcessActivityDoc$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })) : PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: ProcessModule_ProcessActivityDoc$reflection,
                        })(x.Entry);
                        const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: ProcessModule_ProcessActivityDoc$reflection,
                        });
                        return Fetch_tryPut_5760677E("/api/processactivitydoc", some(body_1), empty_1(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                            ResolveType: ProcessModule_ProcessActivityDoc$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    }))).then(((_arg1) => {
                        const res = _arg1;
                        return ((res.tag === 0) ? ((patternInput_3[1](res.fields[0]), Promise.resolve())) : ((setErrorMsg((updating == null) ? "Noe gikk galt. Kunne ikke legge til dokument" : "Noe gikk galt. Kunne ikke oppdatere dokument."), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            setProcessing(false);
                            return Promise.resolve();
                        }));
                    }));
                }));
                pr.then();
            }
            else {
                setErrorMsg("Ingen dokument valgt");
            }
        }
        else {
            setValidName(false);
        }
    }]])) : empty()))))))))]])])]]);
}

function DeleteForm(deleteFormInputProps) {
    const onClose = deleteFormInputProps.onClose;
    const activityDocId = deleteFormInputProps.activityDocId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const result = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setError = patternInput_1[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Slett dokument"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((!result) ? append(singleton_1(createElement("span", {
            style: {
                fontWeight: "bold",
                fontSize: 1.1 + "rem",
            },
            children: "Er du sikker på at du vil slette dette dokumentet?",
        })), delay(() => singleton_1(createElement("p", {
            style: {
                marginTop: 10,
            },
            children: "Dette vil også slette alle tilhørende versjoner",
        })))) : singleton_1(createElement("span", {
            children: ["Dokument slettet"],
        })), delay(() => singleton_1(errorBanner(patternInput_1[0], () => {
            setError(void 0);
        })))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg3) => {
        onClose(result);
    }]])), delay(() => ((!result) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["color", "secondary"], ["children", "Slett"], ["onClick", (_arg4) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const requestPath = toText(printf("/api/processactivitydoc/%i"))(activityDocId);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: () => string_type,
                })("");
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryDelete_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    const x = res.fields[0];
                    if (x.Result === "success") {
                        patternInput[1](true);
                        return Promise.resolve();
                    }
                    else {
                        setError(x.Message);
                        return Promise.resolve();
                    }
                }
                else {
                    setError("Noe gikk galt. Kunne ikke slette dokument.");
                    return Promise.resolve();
                }
            }));
        }));
        pr.then();
    }]])) : empty())))))))]])])]]);
}

export function DrawDocumentTreeEntry(drawDocumentTreeEntryInputProps) {
    const onDelete = drawDocumentTreeEntryInputProps.onDelete;
    const x = drawDocumentTreeEntryInputProps.x;
    const anchor = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505({
        menuOpen: false,
        showDelete: false,
        showUpdate: false,
        this: x,
    });
    const updateState = patternInput[1];
    const state = patternInput[0];
    return createElement("div", {
        style: {
            marginTop: 10,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(state.showUpdate ? singleton_1(createElement(DocumentForm, {
            updating: state.this,
            activityId: x.ActivityId,
            defaultRowPos: state.this.RowPos,
            onClose: (u) => {
                if (u != null) {
                    updateState({
                        menuOpen: state.menuOpen,
                        showDelete: state.showDelete,
                        showUpdate: false,
                        this: u,
                    });
                }
                else {
                    updateState({
                        menuOpen: state.menuOpen,
                        showDelete: state.showDelete,
                        showUpdate: false,
                        this: state.this,
                    });
                }
            },
        })) : empty(), delay(() => append(state.showDelete ? singleton_1(createElement(DeleteForm, {
            activityDocId: x.Id,
            onClose: (didDelete) => {
                if (didDelete) {
                    onDelete();
                }
                updateState({
                    menuOpen: state.menuOpen,
                    showDelete: false,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
            },
        })) : empty(), delay(() => singleton_1(createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
                paddingLeft: 24,
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    display: "flex",
                },
                children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(x.Filename)]),
            }), createElement("span", {
                style: {
                    color: "#000080",
                },
                children: (state.this.RowPrefix.length > 0) ? toText(printf("%s - %s"))(state.this.RowPrefix)(state.this.Name) : state.this.Name,
            }), MuiHelpers_createElement(IconButton, [["size", "small"], ["ref", anchor], ["style", {
                marginLeft: 20,
            }], ["children", react.createElement(MoreHoriz, {})], ["onClick", (_arg1) => {
                updateState({
                    menuOpen: !state.menuOpen,
                    showDelete: state.showDelete,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
            }]]), MuiHelpers_createElement(Menu, [["open", state.menuOpen], ["onClose", (_arg16, v) => {
                updateState({
                    menuOpen: false,
                    showDelete: state.showDelete,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
            }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["children", "Endre dokument"], ["onClick", (_arg3) => {
                updateState({
                    menuOpen: false,
                    showDelete: state.showDelete,
                    showUpdate: true,
                    this: state.this,
                });
            }]]), MuiHelpers_createElement(MenuItem, [["children", "Slett dokument"], ["onClick", (_arg4) => {
                updateState({
                    menuOpen: false,
                    showDelete: true,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
            }]])])]])]),
        })))))))))),
    });
}

