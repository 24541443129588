import { toString, Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { anonRecord_type, obj_type, bool_type, class_type, option_type, array_type, int32_type, record_type, union_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Nonconformity_RegisterForm, Nonconformity_RegisterForm$reflection, Helpers_ResultMessage$reflection, Nonconformity_ImageForm$reflection, Nonconformity_ImageForm, User$reflection, Helpers_IdName$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { sortBy, map, equalsWith, append } from "../.fable/fable-library.3.2.4/Array.js";
import { comparePrimitives, uncurry, compare, numberHash } from "../.fable/fable-library.3.2.4/Util.js";
import { newGuid } from "../.fable/fable-library.3.2.4/Guid.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { join, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { defaultArg, some } from "../.fable/fable-library.3.2.4/Option.js";
import { iterate, length, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { nb } from "date-fns/locale";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { empty as empty_1, map as map_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import date$002Dfns from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { UserSearch } from "../UserSearch.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { Nonconformity_fetchCategories } from "../Promises.js";
import { Text_secondaryText, loadingIndicatorSmall, snackbarError } from "../ViewHelpers.js";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Close from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import * as imageresize from "../../imageresize.js";
import Add from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import Search from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toUtcString } from "../Utils.js";

export class RegistrationResult extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Abort", "Success", "SuccessWithWarning"];
    }
}

export function RegistrationResult$reflection() {
    return union_type("NonconformityRegistration.RegistrationResult", [], RegistrationResult, () => [[], [], [["Item", string_type]]]);
}

export class Image extends Record {
    constructor(Id, Filename, Content, Desc) {
        super();
        this.Id = Id;
        this.Filename = Filename;
        this.Content = Content;
        this.Desc = Desc;
    }
}

export function Image$reflection() {
    return record_type("NonconformityRegistration.Image", [], Image, () => [["Id", string_type], ["Filename", string_type], ["Content", string_type], ["Desc", string_type]]);
}

class Model extends Record {
    constructor(ProjectId, Categories, Description, ActionDescription, TimeOfIncident, TimeOfAction, ActionDeadline, ResponsibleUser, SelectedCategories, ActionTaken, Images, ErrorMsg, Processing) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Categories = Categories;
        this.Description = Description;
        this.ActionDescription = ActionDescription;
        this.TimeOfIncident = TimeOfIncident;
        this.TimeOfAction = TimeOfAction;
        this.ActionDeadline = ActionDeadline;
        this.ResponsibleUser = ResponsibleUser;
        this.SelectedCategories = SelectedCategories;
        this.ActionTaken = ActionTaken;
        this.Images = Images;
        this.ErrorMsg = ErrorMsg;
        this.Processing = Processing;
    }
}

function Model$reflection() {
    return record_type("NonconformityRegistration.Model", [], Model, () => [["ProjectId", int32_type], ["Categories", option_type(array_type(Helpers_IdName$reflection()))], ["Description", string_type], ["ActionDescription", string_type], ["TimeOfIncident", option_type(class_type("System.DateTime"))], ["TimeOfAction", option_type(class_type("System.DateTime"))], ["ActionDeadline", option_type(class_type("System.DateTime"))], ["ResponsibleUser", option_type(User$reflection())], ["SelectedCategories", array_type(Helpers_IdName$reflection())], ["ActionTaken", bool_type], ["Images", array_type(Image$reflection())], ["ErrorMsg", option_type(string_type)], ["Processing", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CategoriesResponse", "UpdateDescription", "UpdateTimeOfIncident", "SelectCategory", "RemoveCategory", "AddImage", "RemoveImage", "ToggleActionTaken", "UpdateActionDesc", "UpdateTimeOfAction", "UpdateActionDeadline", "SelectResponsible", "SetError", "DismissError", "ToggleProcessing"];
    }
}

function Message$reflection() {
    return union_type("NonconformityRegistration.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdName$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdName$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", Helpers_IdName$reflection()]], [["Item", int32_type]], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(User$reflection())]], [["Item", string_type]], [], [["Item", bool_type]]]);
}

function init(projectId) {
    return new Model(projectId, void 0, "", "", void 0, void 0, void 0, void 0, [], false, [], void 0, false);
}

function update(model, msg) {
    let copyOfStruct;
    switch (msg.tag) {
        case 1: {
            return new Model(model.ProjectId, model.Categories, msg.fields[0], model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, model.ErrorMsg, model.Processing);
        }
        case 2: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, msg.fields[0], model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, model.ErrorMsg, model.Processing);
        }
        case 3: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, Array_distinctBy((z) => z.Id, append([msg.fields[0]], model.SelectedCategories), {
                Equals: (x_4, y) => (x_4 === y),
                GetHashCode: (x_4) => numberHash(x_4),
            }), model.ActionTaken, model.Images, model.ErrorMsg, model.Processing);
        }
        case 4: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories.filter((z_1) => (z_1.Id !== msg.fields[0])), model.ActionTaken, model.Images, model.ErrorMsg, model.Processing);
        }
        case 5: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, append(model.Images, [new Image((copyOfStruct = newGuid(), copyOfStruct), msg.fields[1], msg.fields[0], "")]), model.ErrorMsg, model.Processing);
        }
        case 6: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images.filter((x_6) => (x_6.Id !== msg.fields[0])), model.ErrorMsg, model.Processing);
        }
        case 7: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, msg.fields[0], model.Images, model.ErrorMsg, model.Processing);
        }
        case 8: {
            return new Model(model.ProjectId, model.Categories, model.Description, msg.fields[0], model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, model.ErrorMsg, model.Processing);
        }
        case 9: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, msg.fields[0], model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, model.ErrorMsg, model.Processing);
        }
        case 10: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, msg.fields[0], model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, model.ErrorMsg, model.Processing);
        }
        case 11: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, msg.fields[0], model.SelectedCategories, model.ActionTaken, model.Images, model.ErrorMsg, model.Processing);
        }
        case 12: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, msg.fields[0], false);
        }
        case 13: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, void 0, model.Processing);
        }
        case 14: {
            return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, model.ErrorMsg, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 0) {
                return new Model(model.ProjectId, res.fields[0], model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, model.ErrorMsg, model.Processing);
            }
            else {
                return new Model(model.ProjectId, model.Categories, model.Description, model.ActionDescription, model.TimeOfIncident, model.TimeOfAction, model.ActionDeadline, model.ResponsibleUser, model.SelectedCategories, model.ActionTaken, model.Images, "Kunne ikke laste avvikskategorier. Vennligst prøv igjen senere.", model.Processing);
            }
        }
    }
}

function uploadImage(registrationId, images, onClose, hasFailed) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/nonconformity/project/image/%i"))(registrationId);
        if ((!equalsWith((x, y) => compare(x, y), images, null)) ? (images.length === 0) : false) {
            if (!hasFailed) {
                onClose(new RegistrationResult(1));
                return Promise.resolve();
            }
            else {
                onClose(new RegistrationResult(2, "Avvik registrert, men enkelte bilder ble ikke lastet opp"));
                return Promise.resolve();
            }
        }
        else if ((!equalsWith((x_1, y_1) => compare(x_1, y_1), images, null)) ? (images.length === 1) : false) {
            const image = images[0];
            const req = new Nonconformity_ImageForm(image.Content, image.Filename);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Nonconformity_ImageForm$reflection,
                })(req);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 1) {
                    onClose(new RegistrationResult(2, "Avvik registrert, men enkelte bilder ble ikke lastet opp"));
                    return Promise.resolve();
                }
                else if ((res.fields[0].Result === "success") ? (!hasFailed) : false) {
                    onClose(new RegistrationResult(1));
                    return Promise.resolve();
                }
                else {
                    onClose(new RegistrationResult(2, "Avvik registrert, men enkelte bilder ble ikke lastet opp"));
                    return Promise.resolve();
                }
            }));
        }
        else {
            const images_1 = images;
            const image_1 = images_1[0];
            const rest = images_1.slice(1, images_1.length);
            const req_1 = new Nonconformity_ImageForm(image_1.Content, image_1.Filename);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Nonconformity_ImageForm$reflection,
                })(req_1);
                const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E(requestPath, some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg2) => {
                const res_1 = _arg2;
                if (res_1.tag === 1) {
                    uploadImage(registrationId, rest, onClose, true);
                    return Promise.resolve();
                }
                else if (res_1.fields[0].Result === "success") {
                    uploadImage(registrationId, rest, onClose, hasFailed);
                    return Promise.resolve();
                }
                else {
                    uploadImage(registrationId, rest, onClose, true);
                    return Promise.resolve();
                }
            }));
        }
    }));
    pr.then();
}

function tryRegister(projectId, x, images, onClose, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(14, true));
        const requestPath = toText(printf("/api/nonconformity/project/%i"))(projectId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: Nonconformity_RegisterForm$reflection,
            })(x);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => anonRecord_type(["Id", option_type(int32_type)], ["Message", string_type], ["Result", string_type]),
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => anonRecord_type(["Id", option_type(int32_type)], ["Message", string_type], ["Result", string_type]),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            const res = _arg1;
            if (res.tag === 0) {
                const r = res.fields[0];
                if (r.Result !== "success") {
                    dispatch(new Message(12, r.Message));
                    return Promise.resolve();
                }
                else if (images.length === 0) {
                    onClose(new RegistrationResult(1));
                    return Promise.resolve();
                }
                else {
                    const matchValue = r.Id;
                    if (matchValue != null) {
                        uploadImage(matchValue, images, onClose, false);
                        return Promise.resolve();
                    }
                    else {
                        onClose(new RegistrationResult(2, "Avvik registrert, men det var problemer å laste opp ett eller flere bilder"));
                        return Promise.resolve();
                    }
                }
            }
            else {
                const err = res.fields[0];
                let pattern_matching_result;
                if (err.tag === 2) {
                    if ((err.fields[0].status) === 401) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        dispatch(new Message(12, "Du har ikke de nødvendige rettighetene eller er ikke logget inn for å kunne registrere avvik på dette prosjektet"));
                        return Promise.resolve();
                    }
                    case 1: {
                        dispatch(new Message(12, "Kunne ikke registrere avvik. Vennligst prøv igjen senere"));
                        return Promise.resolve();
                    }
                }
            }
        }));
    }));
    pr.then();
}

export function sectionTitle(x) {
    return createElement("div", {
        style: {
            padding: ((3 + "px ") + 8) + "px",
            backgroundColor: "#f6f6f6",
            marginTop: 20,
            marginBottom: 5,
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", x]])]),
    });
}

function actionForm(model, dispatch) {
    let props_3, el;
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray([sectionTitle("Beskrivelse av tiltak"), MuiHelpers_createElement(TextField, [["multiline", true], ["label", "Beskrivelse"], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["onChange", (e) => {
            dispatch(new Message(8, Browser_Types_Event__Event_get_Value(e)));
        }], ["defaultValue", model.ActionDescription]]), sectionTitle("Tidspunkt for gjennomført tiltak"), (props_3 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DateTimePicker, [["value", defaultArg(model.TimeOfAction, null)], ["autoOk", true], ["variant", "inline"], ["onChange", (d) => {
            if (d == null) {
            }
            else {
                dispatch(new Message(9, d));
            }
        }], ["inputVariant", "outlined"], ["inputProps", {
            className: "MuiInputBase-inputMarginDense \n                                MuiOutlinedInput-inputMarginDense",
        }]])])]]), (el = MuiPickersUtilsProvider, MuiHelpers_createElement(el, toList(delay(() => {
            let value_27;
            return append_1(singleton((value_27 = date$002Dfns, ["utils", value_27])), delay(() => props_3));
        })))))]),
    });
}

function ResponsibleForm(responsibleFormInputProps) {
    const onSelect = responsibleFormInputProps.onSelect;
    return MuiHelpers_createElement(Dialog, [["onClose", (_arg14, v) => {
        onSelect(void 0);
    }], ["open", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Velg ansvarlig for gjennomføring av tiltak"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement(UserSearch, {
        onSelect: onSelect,
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["onClick", (_arg2) => {
        onSelect(void 0);
    }], ["children", "Avbryt"]])])]])])]]);
}

export function ProjectRegistration(projectRegistrationInputProps) {
    const onClose = projectRegistrationInputProps.onClose;
    const projectId = projectRegistrationInputProps.projectId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const userForm = patternInput_1[0];
    const setUserForm = patternInput_1[1];
    useReact_useEffectOnce_3A5B6456(() => {
        Nonconformity_fetchCategories((arg) => {
            dispatch(new Message(0, arg));
        });
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            height: toText(printf("calc(100vh - %ipx)"))(64),
            alignItems: "center",
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray([snackbarError(model_1.ErrorMsg, () => {
            dispatch(new Message(13));
        }), createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                maxWidth: 600,
                width: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "space-between",
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["variant", "h4"], ["children", "Registrer avvik"]]), MuiHelpers_createElement(IconButton, [["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Close, {})])]])], ["onClick", (_arg3) => {
                    onClose(new RegistrationResult(0));
                }]])]),
            })), delay(() => append_1(singleton(sectionTitle("Beskrivelse av avvik*")), delay(() => append_1(singleton(MuiHelpers_createElement(TextField, [["multiline", true], ["label", "Beskrivelse"], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["onChange", (e) => {
                dispatch(new Message(1, Browser_Types_Event__Event_get_Value(e)));
            }], ["defaultValue", model_1.Description]])), delay(() => append_1(singleton(sectionTitle("Tidspunkt for hendelse*")), delay(() => {
                let props_7, el;
                return append_1(singleton((props_7 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DateTimePicker, [["value", defaultArg(model_1.TimeOfIncident, null)], ["autoOk", true], ["variant", "inline"], ["onChange", (d) => {
                    if (d == null) {
                    }
                    else {
                        dispatch(new Message(2, d));
                    }
                }], ["inputVariant", "outlined"], ["inputProps", {
                    className: "MuiInputBase-inputMarginDense \n                                        MuiOutlinedInput-inputMarginDense",
                }]])])]]), (el = MuiPickersUtilsProvider, MuiHelpers_createElement(el, toList(delay(() => {
                    let value_52;
                    return append_1(singleton((value_52 = date$002Dfns, ["utils", value_52])), delay(() => props_7));
                })))))), delay(() => append_1(singleton(sectionTitle("Avvikskategori*")), delay(() => {
                    let matchValue, c_1;
                    return append_1((matchValue = model_1.Categories, (matchValue != null) ? ((matchValue.length === 0) ? ((c_1 = matchValue, singleton(createElement("span", {
                        style: {
                            fontStyle: "italic",
                            fontSize: 0.8 + "rem",
                        },
                        children: "Ingen avvikskategorier registrert enda. Kontakt administrator for å legge til.",
                    })))) : ((matchValue != null) ? singleton(MuiHelpers_createElement(List, [["dense", true], ["children", Interop_reactApi.Children.toArray([map((y_3) => {
                        const isChecked = model_1.SelectedCategories.some((z) => (z.Id === y_3.Id));
                        return MuiHelpers_createElement(ListItem, [["button", true], ["dense", true], ["onClick", (_arg4) => {
                            if (isChecked) {
                                dispatch(new Message(4, y_3.Id));
                            }
                            else {
                                dispatch(new Message(3, y_3));
                            }
                        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Checkbox, [["edge", "start"], ["tabIndex", -1], ["disableRipple", true], ["checked", isChecked], ["style", {
                            color: "#2d3a48",
                            paddingTop: 2,
                            paddingBottom: 2,
                        }], ["inputProps", {
                            ["aria-labelledby"]: join(" ", ["labelid"]),
                        }]])])]]), MuiHelpers_createElement(ListItemText, [["primary", y_3.Name]])])]]);
                    }, sortBy((y_1) => y_1.Name, matchValue, {
                        Compare: (x, y_2) => comparePrimitives(x, y_2),
                    }))])]])) : (() => {
                        throw (new Error("Match failure"));
                    })())) : singleton(loadingIndicatorSmall())), delay(() => append_1(singleton(sectionTitle("Bilder (valgfritt)")), delay(() => append_1(singleton(createElement("div", {
                        style: {
                            display: "flex",
                            flexWrap: "wrap",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(map((i) => createElement("div", {
                            style: {
                                width: 280,
                                height: 200,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                                margin: 5,
                            },
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                style: {
                                    position: "absolute",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 100 + "%",
                                },
                                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", "Fjern bilde"], ["children", MuiHelpers_createElement(IconButton, [["style", {
                                    backgroundColor: "#FFFFFF",
                                    border: (((2 + "px ") + "solid") + " ") + "#FF0000",
                                }], ["size", "small"], ["onClick", (_arg5) => {
                                    dispatch(new Message(6, i.Id));
                                }], ["children", react.createElement(Delete, {
                                    style: {
                                        color: "#FF0000",
                                    },
                                })]])]])]),
                            }), createElement("img", {
                                style: {
                                    maxWidth: 100 + "%",
                                    maxHeight: 100 + "%",
                                },
                                src: i.Content,
                            })]),
                        }), model_1.Images)), delay(() => {
                            let value_160;
                            return (model_1.Images.length < 5) ? append_1(singleton(createElement("input", {
                                id: "image-select-input",
                                type: "file",
                                accept: "image/png, image/jpeg",
                                onChange: (ev) => {
                                    const fileList = ev.target.files;
                                    if (!(fileList == null)) {
                                        const x_1 = toList(delay(() => map_1((i_1) => fileList.item(i_1), rangeDouble(0, 1, fileList.length - 1))));
                                        const available = (5 - model_1.Images.length) | 0;
                                        if ((length(x_1) > 0) ? (length(x_1) <= available) : false) {
                                            const reader = new FileReader();
                                            iterate((y_4) => {
                                                reader.onload = ((_arg6) => {
                                                    imageresize.imgScale(toString(reader.result), 4096, 4096, (scaled) => {
                                                        let tupledArg;
                                                        dispatch((tupledArg = [scaled, y_4.name], new Message(5, tupledArg[0], tupledArg[1])));
                                                    });
                                                });
                                                reader.readAsDataURL(y_4);
                                            }, x_1);
                                        }
                                        else if (length(x_1) > available) {
                                            dispatch(new Message(12, "For mange bilder valgt."));
                                        }
                                    }
                                },
                                style: {
                                    display: "none",
                                },
                            })), delay(() => singleton(createElement("label", {
                                htmlFor: "image-select-input",
                                style: {
                                    margin: 10,
                                    textAlign: "center",
                                    cursor: "pointer",
                                },
                                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", "Velg bilde(r)"], ["children", react.createElement(Add, {})]])]),
                            })))) : singleton(createElement("div", {
                                style: {
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                    width: 100 + "%",
                                },
                                children: Interop_reactApi.Children.toArray([(value_160 = toText(printf("Maks %i bilder"))(5), createElement("span", {
                                    children: [value_160],
                                }))]),
                            }));
                        })))))),
                    })), delay(() => append_1(singleton(sectionTitle("Frist for tiltak (valgfritt)")), delay(() => append_1(singleton(createElement("div", {
                        style: {
                            display: "flex",
                            alignItems: "center",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let props_28, el_1;
                            return append_1(singleton((props_28 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DateTimePicker, [["fullWidth", true], ["value", defaultArg(model_1.ActionDeadline, null)], ["autoOk", true], ["variant", "inline"], ["onChange", (d_1) => {
                                if (d_1 == null) {
                                }
                                else {
                                    dispatch(new Message(10, d_1));
                                }
                            }], ["inputVariant", "outlined"], ["inputProps", {
                                className: "MuiInputBase-inputMarginDense \n                                                MuiOutlinedInput-inputMarginDense",
                            }]])])]]), (el_1 = MuiPickersUtilsProvider, MuiHelpers_createElement(el_1, toList(delay(() => {
                                let value_181;
                                return append_1(singleton((value_181 = date$002Dfns, ["utils", value_181])), delay(() => props_28));
                            })))))), delay(() => ((model_1.ActionDeadline != null) ? singleton(MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Delete, {})], ["onClick", (_arg7) => {
                                dispatch(new Message(10, void 0));
                            }]])) : empty_1())));
                        })))),
                    })), delay(() => append_1(singleton(sectionTitle("Ansvarlig for tiltak (valgfritt)")), delay(() => append_1(userForm ? singleton(createElement(ResponsibleForm, {
                        onSelect: (usr) => {
                            if (usr != null) {
                                dispatch(new Message(11, usr));
                            }
                            setUserForm(false);
                        },
                    })) : empty_1(), delay(() => append_1(singleton(createElement("div", {
                        style: {
                            display: "flex",
                            alignItems: "center",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let matchValue_1, usr_2;
                            return append_1((matchValue_1 = model_1.ResponsibleUser, (matchValue_1 != null) ? ((usr_2 = matchValue_1, append_1(singleton(createElement("div", {
                                style: {
                                    display: "flex",
                                    alignItems: "center",
                                },
                                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Avatar, [["alt", toText(printf("Bilde %s"))(usr_2.Firstname)], ["src", toText(printf("/api/user/profilepicture/%i"))(usr_2.Id)]]), createElement("span", {
                                    style: {
                                        marginLeft: 10,
                                    },
                                    children: toText(printf("%s %s"))(usr_2.Firstname)(usr_2.Lastname),
                                })]),
                            })), delay(() => singleton(MuiHelpers_createElement(Tooltip, [["title", "Fjern valgt ansvalig"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["style", {
                                marginLeft: 5,
                            }], ["children", react.createElement(Delete, {})], ["onClick", (_arg8) => {
                                dispatch(new Message(11, void 0));
                            }]])]])))))) : singleton(Text_secondaryText("Ingen valgt"))), delay(() => singleton(MuiHelpers_createElement(Tooltip, [["title", "Velg ansvalig"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["style", {
                                marginLeft: 5,
                            }], ["children", react.createElement(Search, {})], ["onClick", (_arg9) => {
                                setUserForm(!userForm);
                            }]])]]))));
                        })))),
                    })), delay(() => append_1(singleton(sectionTitle("Tiltak (valgfritt)")), delay(() => append_1(singleton(MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["style", {
                        color: "#2d3a48",
                    }], ["checked", model_1.ActionTaken], ["onChange", (e_1) => {
                        dispatch(new Message(7, Browser_Types_Event__Event_get_Checked(e_1)));
                    }], ["color", "primary"]])], ["style", {
                        marginLeft: 4,
                        marginRight: 0,
                    }], ["label", "Tiltak gjennomført"]])), delay(() => append_1(model_1.ActionTaken ? singleton(actionForm(model_1, dispatch)) : empty_1(), delay(() => singleton(MuiHelpers_createElement(Button, [["variant", "contained"], ["color", "primary"], ["disabled", (((model_1.Description.length === 0) ? true : (model_1.SelectedCategories.length === 0)) ? true : (model_1.TimeOfIncident == null)) ? true : model_1.Processing], ["children", model_1.Processing ? loadingIndicatorSmall() : "Registrer"], ["style", {
                        margin: ((10 + "px ") + 0) + "px",
                    }], ["onClick", (_arg10) => {
                        const validCats = model_1.SelectedCategories.length > 0;
                        if (!(model_1.Description.length > 0)) {
                            dispatch(new Message(12, "Ugyldig beskrivelse."));
                        }
                        else if (!validCats) {
                            dispatch(new Message(12, "Ingen kategorier valgt"));
                        }
                        else {
                            const matchValue_2 = model_1.TimeOfIncident;
                            if (matchValue_2 != null) {
                                const incidentTime = matchValue_2;
                                if (!((!model_1.ActionTaken) ? true : ((model_1.TimeOfAction != null) ? (model_1.ActionDescription.length > 0) : false))) {
                                    dispatch(new Message(12, "Oppgi tiltak beskrivelse og tidspunkt"));
                                }
                                else {
                                    let action_1;
                                    if (!model_1.ActionTaken) {
                                        action_1 = (void 0);
                                    }
                                    else {
                                        const matchValue_3 = model_1.TimeOfAction;
                                        action_1 = ((matchValue_3 != null) ? {
                                            ActionTime: toUtcString(matchValue_3),
                                            Desc: model_1.Description,
                                        } : (void 0));
                                    }
                                    let responsible;
                                    const matchValue_4 = model_1.ResponsibleUser;
                                    responsible = ((matchValue_4 != null) ? matchValue_4.Id : (void 0));
                                    let deadline;
                                    const matchValue_5 = model_1.ActionDeadline;
                                    deadline = ((matchValue_5 != null) ? toUtcString(matchValue_5) : (void 0));
                                    tryRegister(projectId, new Nonconformity_RegisterForm(model_1.SelectedCategories, toUtcString(incidentTime), model_1.Description, deadline, responsible, action_1), model_1.Images, onClose, dispatch);
                                }
                            }
                            else {
                                dispatch(new Message(12, "Tidspunkt for hendelse ikke valgt"));
                            }
                        }
                    }]]))))))))))))))))))))))));
                }))));
            })))))))))))),
        })]),
    });
}

