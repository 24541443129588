import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { ProjectForm as ProjectForm_1, ProjectForm$reflection, Helpers_ResultMessage$reflection, ProjectPlan_PlanTemplate$reflection, Helpers_IdNameDesc$reflection, Office$reflection, Company$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, int32_type, union_type, record_type, lambda_type, unit_type, class_type, bool_type, string_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryGet_5760677E, Fetch_tryPost_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { parse, toString, addMonths, addDays, now } from "../.fable/fable-library.3.2.4/Date.js";
import { sortBy, map, tryFind } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { singleton, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, int32ToString, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import Check from "@material-ui/icons/Check";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { tryFocus } from "../Utils.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { snackbarError, errorBanner, loadingIndicatorSmall } from "../ViewHelpers.js";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { ProjectPlan_fetchTemplates, Builder_fetchBuilders, Office_fetchOffices, Company_fetchCompanies } from "../Promises.js";

class Model extends Record {
    constructor(Companies, Offices, Builders, PlanTemplates, Name, ProjectNumber, Company, Office, Template, OnedriveValid, Start, End, ErrorMsg, OnClose, Created) {
        super();
        this.Companies = Companies;
        this.Offices = Offices;
        this.Builders = Builders;
        this.PlanTemplates = PlanTemplates;
        this.Name = Name;
        this.ProjectNumber = ProjectNumber;
        this.Company = Company;
        this.Office = Office;
        this.Template = Template;
        this.OnedriveValid = OnedriveValid;
        this.Start = Start;
        this.End = End;
        this.ErrorMsg = ErrorMsg;
        this.OnClose = OnClose;
        this.Created = Created;
    }
}

function Model$reflection() {
    return record_type("ProjectForm.Model", [], Model, () => [["Companies", option_type(array_type(Company$reflection()))], ["Offices", option_type(array_type(Office$reflection()))], ["Builders", option_type(array_type(Helpers_IdNameDesc$reflection()))], ["PlanTemplates", option_type(array_type(ProjectPlan_PlanTemplate$reflection()))], ["Name", string_type], ["ProjectNumber", string_type], ["Company", option_type(Company$reflection())], ["Office", option_type(Office$reflection())], ["Template", option_type(ProjectPlan_PlanTemplate$reflection())], ["OnedriveValid", option_type(bool_type)], ["Start", class_type("System.DateTime")], ["End", class_type("System.DateTime")], ["ErrorMsg", option_type(string_type)], ["OnClose", lambda_type(bool_type, unit_type)], ["Created", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompaniesResponse", "OfficesResponse", "BuildersResponse", "TemplatesResponse", "OnedriveResponse", "UpdateName", "UpdateProjectNumber", "SelectCompany", "SelectOffice", "SelectTemplate", "UpdateStart", "UpdateEnd", "CreateResponse", "SetError", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProjectForm.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Office$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Office$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdNameDesc$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdNameDesc$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectPlan_PlanTemplate$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectPlan_PlanTemplate$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], []]);
}

function init(x) {
    let copyOfStruct, copyOfStruct_1;
    return new Model(void 0, void 0, void 0, void 0, "", "", void 0, void 0, void 0, void 0, (copyOfStruct = now(), addDays(copyOfStruct, 7)), (copyOfStruct_1 = now(), addMonths(copyOfStruct_1, 2)), void 0, x, false);
}

function update(model, msg) {
    let matchValue_1, off_1;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Noe gikk galt. Kunne ikke laste kontorer.", model.OnClose, model.Created);
            }
            else {
                return new Model(model.Companies, res_1.fields[0], model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Noe gikk galt. Kunne ikke laste byggherrer.", model.OnClose, model.Created);
            }
            else {
                return new Model(model.Companies, model.Offices, res_2.fields[0], model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Kunne ikke laste prosjektplan maler", model.OnClose, model.Created);
            }
            else {
                return new Model(model.Companies, model.Offices, model.Builders, res_3.fields[0], model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
            }
        }
        case 4: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Kunne ikke verifisere OneDrive konfigurasjon for valgt selskap", model.OnClose, model.Created);
            }
            else if (res_4.fields[0].Result === "success") {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, true, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
            }
            else {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, false, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
            }
        }
        case 5: {
            return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, msg.fields[0], model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
        }
        case 6: {
            return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, msg.fields[0], model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
        }
        case 7: {
            const matchValue = model.Companies;
            if (matchValue != null) {
                const _arg1 = tryFind((y) => (y.Id === msg.fields[0]), matchValue);
                if (_arg1 != null) {
                    const y_1 = _arg1;
                    return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, y_1, (matchValue_1 = model.Office, (matchValue_1 != null) ? ((matchValue_1.CompanyId !== y_1.Id) ? ((off_1 = matchValue_1, void 0)) : matchValue_1) : matchValue_1), model.Template, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
                }
                else {
                    return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Ugyldig selskap", model.OnClose, model.Created);
                }
            }
            else {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Kunne ikke velge selskap", model.OnClose, model.Created);
            }
        }
        case 8: {
            const matchValue_2 = model.Offices;
            if (matchValue_2 != null) {
                const _arg2 = tryFind((y_2) => (y_2.Id === msg.fields[0]), matchValue_2);
                if (_arg2 != null) {
                    return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, _arg2, model.Template, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
                }
                else {
                    return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Ugyldig kontor", model.OnClose, model.Created);
                }
            }
            else {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Kunne ikke velge kontor", model.OnClose, model.Created);
            }
        }
        case 9: {
            const matchValue_3 = model.PlanTemplates;
            if (matchValue_3 != null) {
                const _arg3 = tryFind((y_4) => (y_4.Id === msg.fields[0]), matchValue_3);
                if (_arg3 != null) {
                    return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, _arg3, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
                }
                else {
                    return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Ugyldig prosjektplan", model.OnClose, model.Created);
                }
            }
            else {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Ingen prosjektplaner", model.OnClose, model.Created);
            }
        }
        case 10: {
            return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, msg.fields[0], model.End, model.ErrorMsg, model.OnClose, model.Created);
        }
        case 11: {
            return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, msg.fields[0], model.ErrorMsg, model.OnClose, model.Created);
        }
        case 12: {
            const res_5 = msg.fields[0];
            if (res_5.tag === 1) {
                const err_2 = res_5.fields[0];
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, (err_2.tag === 2) ? (((err_2.fields[0].status) === 401) ? "Autentisering feilet" : "Noe gikk galt. Kunne ikke opprette prosjekt. Prøv laste siden på nytt.") : "Noe gikk galt. Kunne ikke opprette prosjekt. Prøv laste siden på nytt.", model.OnClose, model.Created);
            }
            else {
                const x_12 = res_5.fields[0];
                if (x_12.Result === "success") {
                    return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, true);
                }
                else {
                    return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, x_12.Message, model.OnClose, model.Created);
                }
            }
        }
        case 13: {
            return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, msg.fields[0], model.OnClose, model.Created);
        }
        case 14: {
            return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, void 0, model.OnClose, model.Created);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, "Noe gikk galt. Kunne ikke laste selskap.", model.OnClose, model.Created);
            }
            else {
                return new Model(res.fields[0], model.Offices, model.Builders, model.PlanTemplates, model.Name, model.ProjectNumber, model.Company, model.Office, model.Template, model.OnedriveValid, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
            }
        }
    }
}

function tryCreate(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        if ((x.Name.length > 0) ? (x.ProjectNumber.length > 0) : false) {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ProjectForm$reflection,
                })(x);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E("/api/project/create", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(12, _arg1));
                return Promise.resolve();
            }));
        }
        else if (x.Name.length === 0) {
            dispatch(new Message(13, "Ugyldig prosjektnavn"));
            return Promise.resolve();
        }
        else {
            dispatch(new Message(13, "Ugyldig prosjektnummer"));
            return Promise.resolve();
        }
    }));
    pr.then();
}

export function checkOnedriveStatus(companyId, callback) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/company/onedrive-configured/%i"))(companyId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            callback(_arg1);
            return Promise.resolve();
        }));
    }));
    pr.then();
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    const textField = StyleCreator$1__create_4EAC9E1E(styles, singleton(["marginBottom", 15]));
    o = {
        selectBox: StyleCreator$1__create_4EAC9E1E(styles, singleton(["minWidth", 200])),
        textField: textField,
    };
    return Object.assign({}, o);
});

function drawForm(model, dispatch) {
    const s = useStyles();
    const children_9 = toList(delay(() => (model.Created ? singleton_1(createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: 150,
        },
        children: Interop_reactApi.Children.toArray([react.createElement(Check, {
            style: {
                fontSize: 50 + "px",
                color: "#32CD32",
            },
        }), createElement("span", {
            style: {
                fontSize: 1.2 + "rem",
            },
            children: "Prosjekt opprettet",
        })]),
    })) : append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.textField], ["id", "project-form-name"], ["variant", "outlined"], ["size", "small"], ["autoFocus", true], ["fullWidth", true], ["defaultValue", model.Name], ["label", "Prosjektnavn"], ["onChange", (e) => {
        dispatch(new Message(5, Browser_Types_Event__Event_get_Value(e)));
    }], ["required", true], ["onKeyUp", (e_1) => {
        if (e_1.key === "Enter") {
            tryFocus("project-form-projnr");
        }
    }]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.textField], ["id", "project-form-projnr"], ["variant", "outlined"], ["size", "small"], ["fullWidth", true], ["defaultValue", model.ProjectNumber], ["label", "Prosjektnummer"], ["onChange", (e_2) => {
        dispatch(new Message(6, Browser_Types_Event__Event_get_Value(e_2)));
    }], ["required", true]])), delay(() => {
        let children_1, children_3;
        return append(singleton_1(createElement("div", {
            style: createObj(toList(delay(() => append(singleton_1(["display", "flex"]), delay(() => append((model.Company == null) ? singleton_1(["justifyContent", "center"]) : singleton_1(["justify-content", "space-evenly"]), delay(() => singleton_1(["flexWrap", "wrap"])))))))),
            children: Interop_reactApi.Children.toArray([(children_1 = toList(delay(() => {
                let matchValue_1;
                const matchValue = model.Companies;
                if (matchValue != null) {
                    const companies = matchValue;
                    return singleton_1(MuiHelpers_createElement(FormControl, [["className", s.selectBox], ["required", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Selskap"]]), MuiHelpers_createElement(Select, [["value", (matchValue_1 = model.Company, (matchValue_1 != null) ? int32ToString(matchValue_1.Id) : "")], ["onChange", (e_3, _arg24) => {
                        const c = Browser_Types_Event__Event_get_Value(e_3);
                        dispatch(new Message(7, c));
                        checkOnedriveStatus(c, (arg_2) => {
                            dispatch(new Message(4, arg_2));
                        });
                    }], ["displayEmpty", true], ["children", Interop_reactApi.Children.toArray([map((c_2) => MuiHelpers_createElement(MenuItem, [["value", c_2.Id], ["children", c_2.Name]]), sortBy((c_1) => c_1.Name, companies, {
                        Compare: (x_1, y) => comparePrimitives(x_1, y),
                    }))])]])])]]));
                }
                else {
                    return singleton_1(loadingIndicatorSmall());
                }
            })), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            })), (children_3 = toList(delay(() => {
                let matchValue_4;
                const matchValue_2 = model.Offices;
                if (matchValue_2 != null) {
                    const offices = matchValue_2;
                    const matchValue_3 = model.Company;
                    if (matchValue_3 != null) {
                        const company = matchValue_3;
                        return singleton_1(MuiHelpers_createElement(FormControl, [["className", s.selectBox], ["required", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Kontor"]]), MuiHelpers_createElement(Select, [["value", (matchValue_4 = model.Office, (matchValue_4 != null) ? int32ToString(matchValue_4.Id) : "")], ["onChange", (e_4, _arg24_1) => {
                            dispatch(new Message(8, Browser_Types_Event__Event_get_Value(e_4)));
                        }], ["displayEmpty", true], ["children", Interop_reactApi.Children.toArray([map((o_2) => MuiHelpers_createElement(MenuItem, [["value", o_2.Id], ["children", o_2.Name]]), sortBy((o_1) => o_1.Name, offices.filter((o) => (o.CompanyId === company.Id)), {
                            Compare: (x_3, y_1) => comparePrimitives(x_3, y_1),
                        }))])]])])]]));
                    }
                    else {
                        return empty_1();
                    }
                }
                else {
                    return singleton_1(loadingIndicatorSmall());
                }
            })), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_3)),
            }))]),
        })), delay(() => {
            let matchValue_5;
            return append((matchValue_5 = model.OnedriveValid, (matchValue_5 != null) ? (matchValue_5 ? ((empty_1())) : singleton_1(errorBanner("SharePoint/OneDrive område ikke konfigurert for dette selskapet enda. Vennligst kontakt systemadministrator.", () => {
            }))) : ((empty_1()))), delay(() => {
                let children_5, children_7;
                return append(singleton_1(createElement("div", {
                    style: {
                        display: "flex",
                        ["justify-content"]: "space-evenly",
                        flexWrap: "wrap",
                        marginTop: 20,
                    },
                    children: Interop_reactApi.Children.toArray([(children_5 = singleton(MuiHelpers_createElement(TextField, [["type", "date"], ["defaultValue", toString(model.Start, "yyyy-MM-dd")], ["onChange", (e_5) => {
                        const x_4 = Browser_Types_Event__Event_get_Value(e_5);
                        try {
                            dispatch(new Message(10, parse(x_4)));
                        }
                        catch (matchValue_6) {
                        }
                    }], ["label", "Planlagt start"]])), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_5)),
                    })), (children_7 = singleton(MuiHelpers_createElement(TextField, [["type", "date"], ["defaultValue", toString(model.End, "yyyy-MM-dd")], ["onChange", (e_6) => {
                        const x_5 = Browser_Types_Event__Event_get_Value(e_6);
                        try {
                            dispatch(new Message(11, parse(x_5)));
                        }
                        catch (matchValue_7) {
                        }
                    }], ["label", "Planlagt slutt"]])), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_7)),
                    }))]),
                })), delay(() => singleton_1(createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10,
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let matchValue_9;
                        const matchValue_8 = model.PlanTemplates;
                        if (matchValue_8 != null) {
                            const templates = matchValue_8;
                            return append(singleton_1(MuiHelpers_createElement(FormControl, [["className", s.selectBox], ["required", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Prosjektplan mal"]]), MuiHelpers_createElement(Select, [["value", (matchValue_9 = model.Template, (matchValue_9 != null) ? int32ToString(matchValue_9.Id) : "")], ["onChange", (e_7, _arg24_2) => {
                                dispatch(new Message(9, Browser_Types_Event__Event_get_Value(e_7)));
                            }], ["displayEmpty", true], ["children", Interop_reactApi.Children.toArray([map((c_4) => MuiHelpers_createElement(MenuItem, [["value", c_4.Id], ["children", c_4.Name]]), sortBy((c_3) => c_3.Name, templates, {
                                Compare: (x_7, y_2) => comparePrimitives(x_7, y_2),
                            }))])]])])]])), delay(() => singleton_1(MuiHelpers_createElement(Tooltip, [["title", "Hvilken prosjektplan mal som skal brukes under oppsett av prosjektet."], ["placement", "right"], ["children", createElement("span", {
                                style: {
                                    marginLeft: 5,
                                    fontSize: 1.1 + "rem",
                                    color: "#9a9a9a",
                                },
                                className: "fas fa-question-circle",
                            })]]))));
                        }
                        else {
                            return singleton_1(loadingIndicatorSmall());
                        }
                    })))),
                }))));
            }));
        }));
    })))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_9)),
    });
}

function view(model, dispatch) {
    let validOnedrive;
    const matchValue = model.OnedriveValid;
    validOnedrive = ((matchValue != null) ? matchValue : false);
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        model.OnClose(model.Created);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Nytt prosjekt"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minHeight: 200,
        },
        children: Interop_reactApi.Children.toArray([snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(14));
        }), drawForm(model, dispatch)]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["children", "Lukk"], ["onClick", (_arg3) => {
        model.OnClose(model.Created);
    }]])), delay(() => ((!model.Created) ? singleton_1(MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["color", "primary"], ["disabled", (((((model.Name.length === 0) ? true : (model.ProjectNumber.length === 0)) ? true : (model.Company == null)) ? true : (model.Office == null)) ? true : (model.Template == null)) ? true : (!validOnedrive)], ["children", "Opprett"], ["onClick", (_arg4) => {
        const matchValue_1 = [model.Company, model.Office, model.Template];
        if (matchValue_1[0] == null) {
            dispatch(new Message(13, "Velg selskap før du kan opprette prosjekt"));
        }
        else if (matchValue_1[1] == null) {
            dispatch(new Message(13, "Velg kontor før du kan opprette prosjekt"));
        }
        else if (matchValue_1[2] == null) {
            dispatch(new Message(13, "Velg prosjektplan mal før du kan opprette prosjekt"));
        }
        else {
            const c = matchValue_1[0];
            const o = matchValue_1[1];
            const t = matchValue_1[2];
            tryCreate(new ProjectForm_1(model.Name, model.ProjectNumber, c.Id, o.Id, t.Id, toString(model.Start, "yyyy-MM-dd"), toString(model.End, "yyyy-MM-dd")), dispatch);
        }
    }]])) : empty_1())))))))]])])]]);
}

export function ProjectForm(projectFormInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectFormInputProps.onClose));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        Company_fetchCompanies((arg) => {
            dispatch(new Message(0, arg));
        });
        Office_fetchOffices((arg_1) => {
            dispatch(new Message(1, arg_1));
        });
        Builder_fetchBuilders((arg_2) => {
            dispatch(new Message(2, arg_2));
        });
        ProjectPlan_fetchTemplates((arg_3) => {
            dispatch(new Message(3, arg_3));
        });
    });
    return view(patternInput[0], dispatch);
}

