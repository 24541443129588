import { Record, Union } from "./.fable/fable-library.3.2.4/Types.js";
import { int32_type, option_type, obj_type, record_type, lambda_type, unit_type, bool_type, union_type, string_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { LoginForm$reflection, LoginForm, Helpers_ResultMessage$reflection } from "./Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, Fetch_tryPost_5760677E, FetchError$reflection } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.4/Choice.js";
import { tryFocus, validateEmail } from "./Utils.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "./.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { some } from "./.fable/fable-library.3.2.4/Option.js";
import { ofArray, singleton, empty } from "./.fable/fable-library.3.2.4/List.js";
import { createObj, equals, uncurry } from "./.fable/fable-library.3.2.4/Util.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import { printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { StyleCreator$1_$ctor, StyleCreator$1__create_4EAC9E1E, StyleImports_makeStyles_get } from "./.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import TextField from "@material-ui/core/TextField";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { createElement } from "react";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505, useReact_useReducer_2B9E6EA0 } from "./.fable/Feliz.1.68.0/React.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { deleteRememberMe, storeRememberMe, tryGetRememberMe } from "./LocalStorage.js";
import { Page, toPath } from "./Pages.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { loadingIndicatorSmallColored } from "./ViewHelpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";

class PasswordRecoveryForm_Step extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailStep", "FailedStep", "CompletedStep"];
    }
}

function PasswordRecoveryForm_Step$reflection() {
    return union_type("Login.PasswordRecoveryForm.Step", [], PasswordRecoveryForm_Step, () => [[], [["Item", string_type]], []]);
}

class PasswordRecoveryForm_Model extends Record {
    constructor(Email, CurrentStep, ValidEmail, Processing, OnClose) {
        super();
        this.Email = Email;
        this.CurrentStep = CurrentStep;
        this.ValidEmail = ValidEmail;
        this.Processing = Processing;
        this.OnClose = OnClose;
    }
}

function PasswordRecoveryForm_Model$reflection() {
    return record_type("Login.PasswordRecoveryForm.Model", [], PasswordRecoveryForm_Model, () => [["Email", string_type], ["CurrentStep", PasswordRecoveryForm_Step$reflection()], ["ValidEmail", bool_type], ["Processing", bool_type], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

class PasswordRecoveryForm_Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateEmail", "GotoStep", "InvalidateEmail", "Commit", "ResetResponse", "SetError", "DismissError"];
    }
}

function PasswordRecoveryForm_Message$reflection() {
    return union_type("Login.PasswordRecoveryForm.Message", [], PasswordRecoveryForm_Message, () => [[["Item", string_type]], [["Item", PasswordRecoveryForm_Step$reflection()]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], []]);
}

function PasswordRecoveryForm_init(x) {
    return new PasswordRecoveryForm_Model("", new PasswordRecoveryForm_Step(0), true, false, x);
}

function PasswordRecoveryForm_update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new PasswordRecoveryForm_Model(model.Email, msg.fields[0], model.ValidEmail, model.Processing, model.OnClose);
        }
        case 2: {
            return new PasswordRecoveryForm_Model(model.Email, model.CurrentStep, false, model.Processing, model.OnClose);
        }
        case 3: {
            if (validateEmail(model.Email)) {
                return new PasswordRecoveryForm_Model(model.Email, model.CurrentStep, true, true, model.OnClose);
            }
            else {
                return new PasswordRecoveryForm_Model(model.Email, model.CurrentStep, false, model.Processing, model.OnClose);
            }
        }
        case 4: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(1, "Kunne ikke starte tilbakestilling av passord. Vennligst prøv igjen senere."), model.ValidEmail, false, model.OnClose);
            }
            else {
                const x_2 = res.fields[0];
                if (x_2.Result === "success") {
                    return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(2), model.ValidEmail, model.Processing, model.OnClose);
                }
                else {
                    return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(1, x_2.Message), model.ValidEmail, false, model.OnClose);
                }
            }
        }
        case 5: {
            return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(1, msg.fields[0]), model.ValidEmail, model.Processing, model.OnClose);
        }
        case 6: {
            return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(0), model.ValidEmail, model.Processing, model.OnClose);
        }
        default: {
            return new PasswordRecoveryForm_Model(msg.fields[0], model.CurrentStep, model.ValidEmail, model.Processing, model.OnClose);
        }
    }
}

function PasswordRecoveryForm_requestReset(email, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: () => string_type,
        })(email);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_tryPost_5760677E("/api/auth/request-recovery", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new PasswordRecoveryForm_Message(4, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function PasswordRecoveryForm_requested(model) {
    return MuiHelpers_createElement(DialogContentText, [["children", Interop_reactApi.Children.toArray([toText(printf("Tilbakestilling av passord er forespurt for %s. Om det eksisterer en bruker med dette passordet vil du motta en e-post med instrukser for tilbakestilling."))(model.Email)])]]);
}

function PasswordRecoveryForm_failed(errMsg) {
    return MuiHelpers_createElement(DialogContentText, [["children", Interop_reactApi.Children.toArray([errMsg])]]);
}

const PasswordRecoveryForm_useStyles = StyleImports_makeStyles_get((arg) => {
    const o = {
        paper: StyleCreator$1__create_4EAC9E1E(StyleCreator$1_$ctor(), singleton(["backgroundColor", "#FFFFFF"])),
    };
    return Object.assign({}, o);
});

function PasswordRecoveryForm_emailStep(model, dispatch) {
    const children = ofArray([MuiHelpers_createElement(DialogContentText, [["children", "Forespør tilbakestilling av passord ved å skrive inn e-post adressen tilknyttet bruker i feltet under."]]), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["id", "recovery-email"]), delay(() => append(singleton_1(["defaultValue", model.Email]), delay(() => append(singleton_1(["error", !model.ValidEmail]), delay(() => append((!model.ValidEmail) ? singleton_1(["helperText", "Ugyldig e-post"]) : empty_1(), delay(() => append(singleton_1(["onChange", (e) => {
        dispatch(new PasswordRecoveryForm_Message(0, Browser_Types_Event__Event_get_Value(e)));
    }]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["autoFocus", true]), delay(() => append(singleton_1(["label", "E-post"]), delay(() => append(singleton_1(["type", "email"]), delay(() => singleton_1(["fullWidth", true]))))))))))))))))))))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function PasswordRecoveryForm_PasswordRecoveryForm(passwordRecoveryForm_PasswordRecoveryFormInputProps) {
    const onClose = passwordRecoveryForm_PasswordRecoveryFormInputProps.onClose;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => PasswordRecoveryForm_update(model, msg), PasswordRecoveryForm_init(onClose));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const s = PasswordRecoveryForm_useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        model_1.OnClose();
    }], ["PaperProps", {
        className: s.paper,
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Glemt passord"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        const matchValue = model_1.CurrentStep;
        switch (matchValue.tag) {
            case 1: {
                return singleton_1(PasswordRecoveryForm_failed(matchValue.fields[0]));
            }
            case 2: {
                return singleton_1(PasswordRecoveryForm_requested(model_1));
            }
            default: {
                return singleton_1(PasswordRecoveryForm_emailStep(model_1, dispatch));
            }
        }
    }))))]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        onClose();
    }]])), delay(() => (equals(model_1.CurrentStep, new PasswordRecoveryForm_Step(0)) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", model_1.Email.length === 0], ["children", "Neste"], ["onClick", (_arg3) => {
        if (validateEmail(model_1.Email)) {
            PasswordRecoveryForm_requestReset(model_1.Email, dispatch);
        }
        else {
            dispatch(new PasswordRecoveryForm_Message(2));
        }
    }]])) : empty_1())))))))]])])]]);
}

class Model extends Record {
    constructor(Username, Password, Persist, AutoFocusPwd, ErrorMsg, Processing, ValidUsername, ValidPassword, AuthModule) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.Persist = Persist;
        this.AutoFocusPwd = AutoFocusPwd;
        this.ErrorMsg = ErrorMsg;
        this.Processing = Processing;
        this.ValidUsername = ValidUsername;
        this.ValidPassword = ValidPassword;
        this.AuthModule = AuthModule;
    }
}

function Model$reflection() {
    return record_type("Login.Model", [], Model, () => [["Username", string_type], ["Password", string_type], ["Persist", bool_type], ["AutoFocusPwd", bool_type], ["ErrorMsg", option_type(string_type)], ["Processing", bool_type], ["ValidUsername", bool_type], ["ValidPassword", bool_type], ["AuthModule", option_type(int32_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateUsername", "UpdatePassword", "TogglePersist", "TryLogin", "LoginResponse", "DismissError", "ModuleResponse"];
    }
}

function Message$reflection() {
    return union_type("Login.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [int32_type, FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", int32_type]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init() {
    const rememberMe = tryGetRememberMe();
    return new Model((rememberMe.tag === 0) ? rememberMe.fields[0] : "", "", false, (rememberMe.tag === 0) ? true : false, void 0, false, true, true, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const x_1 = msg.fields[0];
            return new Model(model.Username, x_1, model.Persist, model.AutoFocusPwd, model.ErrorMsg, model.Processing, model.ValidUsername, x_1.length > 0, model.AuthModule);
        }
        case 2: {
            return new Model(model.Username, model.Password, msg.fields[0], model.AutoFocusPwd, model.ErrorMsg, model.Processing, model.ValidUsername, model.ValidPassword, model.AuthModule);
        }
        case 3: {
            const validUsername = model.Username.length > 0;
            const validPassword = model.Password.length > 0;
            if (validUsername ? validPassword : false) {
                return new Model(model.Username, model.Password, model.Persist, model.AutoFocusPwd, void 0, true, true, true, model.AuthModule);
            }
            else {
                return new Model(model.Username, model.Password, model.Persist, model.AutoFocusPwd, model.ErrorMsg, model.Processing, validUsername, validPassword, model.AuthModule);
            }
        }
        case 4: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                if (err.tag === 2) {
                    const x_4 = err.fields[0];
                    if (((x_4.status) === 301) ? true : ((x_4.status) === 302)) {
                        window.location.reload(true);
                        return new Model(model.Username, model.Password, model.Persist, model.AutoFocusPwd, "Allerede logget inn. Tøm buffer med CTRL + SHIFT + R.", false, model.ValidUsername, model.ValidPassword, model.AuthModule);
                    }
                    else {
                        return new Model(model.Username, model.Password, model.Persist, model.AutoFocusPwd, "Noe gikk galt. Kunne ikke gjennomføre innlogging.", false, model.ValidUsername, model.ValidPassword, model.AuthModule);
                    }
                }
                else {
                    return new Model(model.Username, model.Password, model.Persist, model.AutoFocusPwd, "Noe gikk galt. Kunne ikke logge deg inn.", false, model.ValidUsername, model.ValidPassword, model.AuthModule);
                }
            }
            else {
                const x_3 = res.fields[0];
                if (x_3.Result === "success") {
                    window.location.replace(toPath(new Page(34)));
                    window.location.reload();
                    return model;
                }
                else {
                    return new Model(model.Username, model.Password, model.Persist, model.AutoFocusPwd, x_3.Message, false, model.ValidUsername, model.ValidPassword, model.AuthModule);
                }
            }
        }
        case 5: {
            return new Model(model.Username, model.Password, model.Persist, model.AutoFocusPwd, void 0, model.Processing, model.ValidUsername, model.ValidPassword, model.AuthModule);
        }
        case 6: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                return new Model(model.Username, model.Password, model.Persist, model.AutoFocusPwd, model.ErrorMsg, model.Processing, model.ValidUsername, model.ValidPassword, res_1.fields[0]);
            }
            else {
                return model;
            }
        }
        default: {
            const x = msg.fields[0];
            return new Model(x, model.Password, model.Persist, model.AutoFocusPwd, model.ErrorMsg, model.Processing, x.length > 0, model.ValidPassword, model.AuthModule);
        }
    }
}

function fetchAuthModule(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => int32_type,
        });
        return Fetch_tryGet_5760677E("/api/auth/module", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => int32_type,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(6, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function tryLogin(username, password, persist, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(3));
        if ((username.length > 0) ? (password.length > 0) : false) {
            const req = new LoginForm(username, password, persist);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: LoginForm$reflection,
                })(req);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E("/api/auth/login", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(4, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            return Promise.resolve();
        }
    }));
    pr.then();
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    const submit = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#FFFFFF"], ["borderColor", "#2d3a48"], ["backgroundColor", "#2d3a48"], ["marginTop", 10]]));
    o = {
        inpt: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginTop", 10], ["marginBottom", 10]])),
        submit: submit,
    };
    return Object.assign({}, o);
});

function authModuleText(x) {
    return createElement("span", {
        style: {
            color: "#777",
            fontSize: 0.8 + "rem",
        },
        children: (x === 2) ? "Logg inn med din AD bruker" : ((x === 3) ? "Logg inn med din Office-365 bruker" : "Logg inn med din Derigo bruker"),
    });
}

function loginForm(model, dispatch) {
    let children_4;
    const s = useStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505((tryGetRememberMe().tag === 0) ? true : false);
    const remember = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setForgotPasswordForm = patternInput_1[1];
    const forgotPasswordForm = patternInput_1[0];
    const elms = ofArray([createElement("div", {
        style: {
            textAlign: "center",
        },
        children: Interop_reactApi.Children.toArray([createElement("img", {
            src: "/images/logo1155x419.png",
            style: {
                marginBottom: 15 + "px",
            },
        })]),
    }), createElement("div", {
        style: {
            minHeight: 20,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue_1 = model.AuthModule;
            if (matchValue_1 != null) {
                return singleton_1(authModuleText(matchValue_1));
            }
            else {
                return empty_1();
            }
        })))),
    }), (children_4 = toList(delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.inpt]), delay(() => append(singleton_1(["id", "login-username"]), delay(() => append(singleton_1(["autoFocus", !model.AutoFocusPwd]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["label", "Brukernavn"]), delay(() => append(singleton_1(["onChange", (e) => {
        dispatch(new Message(0, Browser_Types_Event__Event_get_Value(e)));
    }]), delay(() => append(singleton_1(["defaultValue", model.Username]), delay(() => append((!model.ValidUsername) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Ugyldig brukernavn"]))) : empty_1(), delay(() => singleton_1(["onKeyUp", (kc) => {
        kc.preventDefault();
        if (kc.key === "Enter") {
            tryFocus("login-pwd");
        }
    }]))))))))))))))))))))))))))), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.inpt]), delay(() => append(singleton_1(["id", "login-pwd"]), delay(() => append(singleton_1(["autoFocus", model.AutoFocusPwd]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["label", "Passord"]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append(singleton_1(["type", "password"]), delay(() => append(singleton_1(["onChange", (e_1) => {
        dispatch(new Message(1, Browser_Types_Event__Event_get_Value(e_1)));
    }]), delay(() => append((!model.ValidPassword) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Ugyldig passord"]))) : empty_1(), delay(() => singleton_1(["onKeyUp", (kc_1) => {
        kc_1.preventDefault();
        if ((kc_1.key === "Enter") ? (!model.Processing) : false) {
            if (remember) {
                storeRememberMe(model.Username);
            }
            tryLogin(model.Username, model.Password, model.Persist, dispatch);
        }
    }]))))))))))))))))))))))))))), delay(() => {
        let children;
        return append(singleton_1((children = singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Forbli innlogget"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
            color: "#2d3a48",
        }], ["checked", model.Persist], ["onChange", (e_2) => {
            dispatch(new Message(2, Browser_Types_Event__Event_get_Checked(e_2)));
        }]])]])), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => {
            let children_2;
            return append(singleton_1((children_2 = singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Husk meg"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
                color: "#2d3a48",
            }], ["checked", remember], ["onChange", (e_3) => {
                const x = Browser_Types_Event__Event_get_Checked(e_3);
                if (x) {
                    storeRememberMe(model.Username);
                }
                else {
                    deleteRememberMe();
                }
                patternInput[1](x);
            }]])]])), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["className", s.submit], ["type", "submit"], ["fullWidth", true], ["variant", "contained"], ["color", "primary"], ["children", "Logg inn"], ["onClick", (e_4) => {
                e_4.preventDefault();
                if (!model.Processing) {
                    if (remember) {
                        storeRememberMe(model.Username);
                    }
                    tryLogin(model.Username, model.Password, model.Persist, dispatch);
                }
            }], ["style", {
                height: 37,
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!model.Processing) ? singleton_1("Logg inn") : singleton_1(loadingIndicatorSmallColored("#fff")))))))]])), delay(() => {
                let matchValue_2, am_2;
                return append((matchValue_2 = model.AuthModule, (matchValue_2 != null) ? ((matchValue_2 === 1) ? ((am_2 = (matchValue_2 | 0), append(singleton_1(createElement("div", {
                    style: {
                        marginTop: 10,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("a", {
                        onClick: (_arg1) => {
                            setForgotPasswordForm(!forgotPasswordForm);
                        },
                        children: "Glemt passord?",
                    })]),
                })), delay(() => (forgotPasswordForm ? singleton_1(createElement(PasswordRecoveryForm_PasswordRecoveryForm, {
                    onClose: () => {
                        setForgotPasswordForm(false);
                    },
                })) : empty_1()))))) : ((empty_1()))) : ((empty_1()))), delay(() => {
                    let matchValue_3;
                    return singleton_1(MuiHelpers_createElement(Snackbar, [["open", model.ErrorMsg != null], ["onClose", (_arg33, v) => {
                        dispatch(new Message(5));
                    }], ["autoHideDuration", 20000], ["anchorOrigin", {
                        vertical: "top",
                        horizontal: "center",
                    }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg4) => {
                        dispatch(new Message(5));
                    }], ["severity", "error"], ["variant", "filled"], ["children", (matchValue_3 = model.ErrorMsg, (matchValue_3 == null) ? "" : matchValue_3)]])]]));
                }));
            }))));
        }));
    })))))), createElement("form", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    }))]);
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function Login() {
    let elms_1, elms, props_2, props;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchAuthModule(dispatch);
    });
    const props_6 = ofArray([["className", "is-fullheight"], ["className", "is-light"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton((elms = singleton((props_2 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-one-third"], ["children", Interop_reactApi.Children.toArray([loginForm(patternInput[0], dispatch)])]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_2))))), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "hero-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]);
    return createElement("section", createObj(Helpers_combineClasses("hero", props_6)));
}

