import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, bool_type, array_type, class_type, option_type, union_type, record_type, int32_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Helpers_Date__ToLocalDate, UserVacation, Helpers_Date__ToDateTime, Helpers_TimePeriod, Helpers_Date_FromDateTime_7F9DDECF, UserInfo$reflection, UserVacation$reflection, UserInfoProjectRole$reflection, Helpers_TimePeriod$reflection, User$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { day as day_4, month, date as date_18, compare, addMonths, addDays, now } from "../.fable/fable-library.3.2.4/Date.js";
import { nb } from "date-fns/locale";
import { map2, some, defaultArg } from "../.fable/fable-library.3.2.4/Option.js";
import { addMinutes, endOfWeek, endOfMonth, startOfMonth, isSameDay, differenceInDays, areIntervalsOverlapping, addDays as addDays_1, startOfWeek as startOfWeek_1, format as format_5, subMonths } from "date-fns";
import { map as map_3, equalsWith, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { item as item_1, mapIndexed, tail, head, isEmpty, singleton, append as append_1, map as map_1, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { compare as compare_1, safeHash, equals, max, comparePrimitives, min, int32ToString, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { toArray, empty as empty_1, append as append_2, singleton as singleton_1, delay, toList, map as map_2 } from "../.fable/fable-library.3.2.4/Seq.js";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import { tryFind, add } from "../.fable/fable-library.3.2.4/Map.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Popover from "@material-ui/core/Popover";
import { Array_distinct } from "../.fable/fable-library.3.2.4/Seq2.js";
import Divider from "@material-ui/core/Divider";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import date$002Dfns from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";

class EventSegment extends Record {
    constructor(Title, Span, Left, Right, Color) {
        super();
        this.Title = Title;
        this.Span = (Span | 0);
        this.Left = (Left | 0);
        this.Right = (Right | 0);
        this.Color = Color;
    }
}

function EventSegment$reflection() {
    return record_type("Calendar.EventSegment", [], EventSegment, () => [["Title", string_type], ["Span", int32_type], ["Left", int32_type], ["Right", int32_type], ["Color", string_type]]);
}

class UpdateTarget extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Vacation", "Project"];
    }
}

function UpdateTarget$reflection() {
    return union_type("Calendar.UpdateTarget", [], UpdateTarget, () => [[], []]);
}

class Model extends Record {
    constructor(ErrorMsg, CurrentMonth, SelectedDate, SelectedStart, SelectedEnd, CurrentUser, Locale, VacationForm, UserId, UserProjects, Vacations, CanEdit, ShowVacations) {
        super();
        this.ErrorMsg = ErrorMsg;
        this.CurrentMonth = CurrentMonth;
        this.SelectedDate = SelectedDate;
        this.SelectedStart = SelectedStart;
        this.SelectedEnd = SelectedEnd;
        this.CurrentUser = CurrentUser;
        this.Locale = Locale;
        this.VacationForm = VacationForm;
        this.UserId = (UserId | 0);
        this.UserProjects = UserProjects;
        this.Vacations = Vacations;
        this.CanEdit = CanEdit;
        this.ShowVacations = ShowVacations;
    }
}

function Model$reflection() {
    return record_type("Calendar.Model", [], Model, () => [["ErrorMsg", option_type(string_type)], ["CurrentMonth", class_type("System.DateTime")], ["SelectedDate", option_type(class_type("System.DateTime"))], ["SelectedStart", option_type(class_type("System.DateTime"))], ["SelectedEnd", option_type(class_type("System.DateTime"))], ["CurrentUser", option_type(User$reflection())], ["Locale", class_type("Fable.DateFunctions.ILocale")], ["VacationForm", option_type(Helpers_TimePeriod$reflection())], ["UserId", int32_type], ["UserProjects", option_type(array_type(UserInfoProjectRole$reflection()))], ["Vacations", option_type(array_type(UserVacation$reflection()))], ["CanEdit", bool_type], ["ShowVacations", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NextMonth", "PreviousMonth", "UserVacationsResponse", "UserInfoResponse", "AddVacationStart", "AddVacationClose", "AddVacationResponse", "ToggleShowVacations", "RemoveVacationResponse", "SetError"];
    }
}

function Message$reflection() {
    return union_type("Calendar.Message", [], Message, () => [[], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserVacation$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserVacation$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserInfo$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserInfo$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserVacation$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserVacation$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserVacation$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserVacation$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(string_type)]]]);
}

function init(userId, canEdit) {
    return new Model(void 0, now(), void 0, void 0, void 0, void 0, nb, void 0, defaultArg(userId, 0), void 0, void 0, canEdit, false);
}

function update(model, msg) {
    let copyOfStruct, matchValue, matchValue_1;
    switch (msg.tag) {
        case 1: {
            return new Model(model.ErrorMsg, subMonths(model.CurrentMonth, 1), model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, model.Vacations, model.CanEdit, model.ShowVacations);
        }
        case 4: {
            return new Model(model.ErrorMsg, model.CurrentMonth, model.SelectedDate, void 0, void 0, model.CurrentUser, model.Locale, new Helpers_TimePeriod(Helpers_Date_FromDateTime_7F9DDECF(now()), Helpers_Date_FromDateTime_7F9DDECF((copyOfStruct = now(), addDays(copyOfStruct, 7)))), model.UserId, model.UserProjects, model.Vacations, model.CanEdit, model.ShowVacations);
        }
        case 5: {
            return new Model(model.ErrorMsg, model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, void 0, model.UserId, model.UserProjects, model.Vacations, model.CanEdit, model.ShowVacations);
        }
        case 3: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model("Noe gikk galt. Kunne ikke laste ned brukerinfo", model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, model.Vacations, model.CanEdit, model.ShowVacations);
            }
            else {
                const uInfo = res.fields[0];
                return new Model(void 0, model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, uInfo.Id, uInfo.Projects, model.Vacations, model.CanEdit, model.ShowVacations);
            }
        }
        case 2: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model("Noe gikk galt. Kunne ikke laste ferier", model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, model.Vacations, model.CanEdit, model.ShowVacations);
            }
            else {
                return new Model(model.ErrorMsg, model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, res_1.fields[0], model.CanEdit, model.ShowVacations);
            }
        }
        case 6: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model("Noe gikk galt. Kunne ikke legge til ferie", model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, model.Vacations, model.CanEdit, model.ShowVacations);
            }
            else {
                const newEntry = res_2.fields[0];
                return new Model(model.ErrorMsg, model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, void 0, model.UserId, model.UserProjects, (matchValue = model.Vacations, (matchValue == null) ? [newEntry] : append([newEntry], matchValue)), model.CanEdit, model.ShowVacations);
            }
        }
        case 7: {
            return new Model(model.ErrorMsg, model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, model.Vacations, model.CanEdit, !model.ShowVacations);
        }
        case 8: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model("Noe gikk galt. Kunne ikke fjerne ferie", model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, model.Vacations, model.CanEdit, model.ShowVacations);
            }
            else {
                return new Model(model.ErrorMsg, model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, (matchValue_1 = model.Vacations, (matchValue_1 != null) ? matchValue_1.filter((v) => (v.id !== res_3.fields[0].id)) : (void 0)), model.CanEdit, model.ShowVacations);
            }
        }
        case 9: {
            return new Model(msg.fields[0], model.CurrentMonth, model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, model.Vacations, model.CanEdit, model.ShowVacations);
        }
        default: {
            return new Model(model.ErrorMsg, addMonths(model.CurrentMonth, 1), model.SelectedDate, model.SelectedStart, model.SelectedEnd, model.CurrentUser, model.Locale, model.VacationForm, model.UserId, model.UserProjects, model.Vacations, model.CanEdit, model.ShowVacations);
        }
    }
}

function fetchUserVacations(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/calendar/vacations/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserVacation$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserVacation$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchUserInfo(userId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let infoPath;
        if (userId == null) {
            infoPath = "/api/mypage/userinfo";
        }
        else {
            const userid = userId | 0;
            infoPath = toText(printf("/api/mypage/userinfo/%i"))(userid);
        }
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserInfo$reflection,
            });
            return Fetch_tryGet_5760677E(infoPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserInfo$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            const res = _arg1;
            dispatch(new Message(3, res));
            if (res.tag === 0) {
                return fetchUserVacations(res.fields[0].Id, dispatch).then((() => (Promise.resolve(undefined))));
            }
            else {
                return Promise.resolve();
            }
        }));
    }));
    pr.then();
}

function removeVacation(vac, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: UserVacation$reflection,
        })(vac);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserVacation$reflection,
        });
        return Fetch_tryPost_5760677E("/api/user/vacation/remove", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserVacation$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(8, _arg1));
        return Promise.resolve();
    })))));
}

function addVacation(userId, dispatch, per) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const pstart = Helpers_Date__ToDateTime(per.PeriodStart);
        const pend = Helpers_Date__ToDateTime(per.PeriodEnd);
        if (compare(date_18(pstart), date_18(pend)) <= 0) {
            const vac = new UserVacation(-1, userId, per);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: UserVacation$reflection,
                })(vac);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: UserVacation$reflection,
                });
                return Fetch_tryPost_5760677E("/api/user/vacation/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: UserVacation$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(6, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            dispatch(new Message(9, "Start-dato kan ikke være senere enn slutt-dato"));
            return Promise.resolve();
        }
    }));
}

function drawHeader(model, dispatch) {
    let props_1, value_13, formatOptions, props_7;
    return createElement("div", {
        className: "header row flex-middle",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "cal cal-start",
            children: Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "is-small"], ["onClick", (_arg1) => {
                dispatch(new Message(1));
            }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-angle-left",
            })])]]), createElement("button", createObj(Helpers_combineClasses("button", props_1))))]),
        }), createElement("div", {
            className: "cal cal-center",
            children: Interop_reactApi.Children.toArray([(value_13 = ((formatOptions = {}, (formatOptions.locale = model.Locale, format_5(model.CurrentMonth, "MMMM yyyy", formatOptions)))), createElement("span", {
                children: [value_13],
            }))]),
        }), createElement("div", {
            className: "cal cal-end",
            children: Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-small"], ["onClick", (_arg2) => {
                dispatch(new Message(0));
            }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-angle-right",
            })])]]), createElement("button", createObj(Helpers_combineClasses("button", props_7))))]),
        })]),
    });
}

const drawWeekDays = (() => {
    const days = map_1((i) => createElement("div", {
        className: "cal cal-center",
        key: i,
        children: i,
    }), ofArray(["man", "tir", "ons", "tor", "fre", "lør", "søn"]));
    return createElement("div", {
        className: "days row",
        children: Interop_reactApi.Children.toArray(Array.from(days)),
    });
})();

function drawDays(model, dispatch) {
    const startDate = startOfWeek_1(model.CurrentMonth);
    const days_1 = map_2((i) => {
        let date_2, formatOptions;
        return createElement("div", {
            className: "cal cal-center",
            key: int32ToString(i),
            children: (date_2 = addDays_1(startDate, i), (formatOptions = {}, (formatOptions.locale = model.Locale, format_5(date_2, "dd", formatOptions)))),
        });
    }, rangeDouble(1, 1, 7));
    return createElement("div", {
        className: "days row",
        children: Interop_reactApi.Children.toArray(Array.from(days_1)),
    });
}

function inTheSameWeekAs(startOfWeek, startTime, endTime) {
    try {
        const patternInput = [startOfWeek, addDays_1(startOfWeek, 7)];
        return areIntervalsOverlapping({
            end: patternInput[1],
            start: patternInput[0],
        }, {
            end: endTime,
            start: startTime,
        });
    }
    catch (e) {
        return false;
    }
}

function overlaps(startX, endX, startY, endY) {
    try {
        return areIntervalsOverlapping({
            end: endX,
            start: startX,
        }, {
            end: endY,
            start: startY,
        });
    }
    catch (e) {
        return false;
    }
}

function addOrAppend(key, value, map) {
    return add(key, append_1(defaultArg(tryFind(key, map), empty()), singleton(value)), map);
}

function projectSegments(startDay, endDay, color, project) {
    const slots = differenceInDays(project.plannedEnd, project.plannedStart) | 0;
    const start = (compare(startDay, project.plannedStart) > 0) ? startDay : project.plannedStart;
    let span;
    const tupledArg = [1, min((x, y) => comparePrimitives(x, y), differenceInDays((compare(endDay, project.plannedEnd) < 0) ? endDay : project.plannedEnd, start), slots)];
    span = max((x_1, y_1) => comparePrimitives(x_1, y_1), tupledArg[0], tupledArg[1]);
    const padding = differenceInDays(start, startDay) | 0;
    return new EventSegment(project.projectname, span, padding + 1, max((x_2, y_2) => comparePrimitives(x_2, y_2), padding + span, 1), color);
}

function segsOverlap(seg_mut, segList_mut) {
    let x;
    segsOverlap:
    while (true) {
        const seg = seg_mut, segList = segList_mut;
        if (!isEmpty(segList)) {
            if ((x = head(segList), (x.Left <= seg.Right) ? (x.Right >= seg.Left) : false)) {
                return head(segList);
            }
            else if (!isEmpty(segList)) {
                seg_mut = seg;
                segList_mut = tail(segList);
                continue segsOverlap;
            }
            else {
                throw (new Error("Match failure"));
            }
        }
        else {
            return void 0;
        }
        break;
    }
}

function foldEventSegments(map_mut, seg_mut, ctr_mut) {
    foldEventSegments:
    while (true) {
        const map = map_mut, seg = seg_mut, ctr = ctr_mut;
        const list = tryFind(ctr, map);
        if (list != null) {
            const l = list;
            const matchValue = segsOverlap(seg, l);
            if (matchValue != null) {
                const x = matchValue;
                map_mut = map;
                seg_mut = seg;
                ctr_mut = (ctr + 1);
                continue foldEventSegments;
            }
            else {
                return add(ctr, append_1(l, singleton(seg)), map);
            }
        }
        else {
            return add(ctr, append_1(empty(), singleton(seg)), map);
        }
        break;
    }
}

function drawEventRow(event, index, lastEnd) {
    const gap = (event.Left - (index + lastEnd)) | 0;
    if (gap === 0) {
        const per = ((event.Span / 6) * 100).toString() + "%";
        return createElement("div", {
            className: "rbc-row-segment",
            style: {
                ["flex-basis"]: per,
                ["max-width"]: per,
                lineHeight: 0.3 + "px",
            },
            children: Interop_reactApi.Children.toArray([createElement("button", {
                className: "rbc-event rbc-event-allday",
                style: {
                    width: 100 + "%",
                    backgroundColor: event.Color,
                },
                children: Interop_reactApi.Children.toArray([event.Title, createElement("div", {
                    className: "rbc-event-content",
                })]),
            })]),
        });
    }
    else {
        const per_1 = ((gap / 6) * 100).toString() + "%";
        return createElement("div", {
            className: "rbc-row-segment",
            style: {
                ["flex-basis"]: per_1,
                ["max-width"]: per_1,
                lineHeight: 0.3 + "px",
            },
        });
    }
}

function drawEvents(events) {
    return mapIndexed((index, ev) => drawEventRow(ev, index, 1), events);
}

function daysToList(list_mut, day_mut, lastDay_mut) {
    daysToList:
    while (true) {
        const list = list_mut, day = day_mut, lastDay = lastDay_mut;
        if (isSameDay(day, addDays_1(lastDay, 1))) {
            return list;
        }
        else {
            const nextDay = addDays_1(day, 1);
            list_mut = append_1(list, singleton(nextDay));
            day_mut = nextDay;
            lastDay_mut = lastDay;
            continue daysToList;
        }
        break;
    }
}

function DrawMonth(model, dispatch) {
    const patternInput = useFeliz_React__React_useState_Static_1505([void 0, void 0]);
    const state = patternInput[0];
    const setState = patternInput[1];
    const monthStart = startOfMonth(model.CurrentMonth);
    const monthEnd = endOfMonth(monthStart);
    const days = daysToList(empty(), startOfWeek_1(monthStart), endOfWeek(monthEnd));
    const popover$0027 = MuiHelpers_createElement(Popover, [["open", state[0] != null], ["onClose", (_arg19, v_1) => {
        setState([void 0, void 0]);
    }], ["anchorEl", state[0]], ["anchorOrigin", {
        vertical: "bottom",
        horizontal: "center",
    }], ["transformOrigin", {
        vertical: "top",
        horizontal: "center",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            width: 15 + "rem",
            height: 15 + "rem",
            margin: 1 + "rem",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let day_2, renderVacation, matchValue, matchValue_1, day, renderProject, matchValue_2, matchValue_3, day_1, children_10, value_17, arg10_2, formatOptions_4;
            const matchValue_4 = state[1];
            return (matchValue_4 != null) ? singleton_1((day_2 = matchValue_4, (renderVacation = ((matchValue = model.Vacations, (matchValue != null) ? ((matchValue_1 = ((day = day_2, Array_distinct(matchValue.filter((x) => {
                if (compare(Helpers_Date__ToDateTime(x.period.PeriodStart), day) <= 0) {
                    return compare(addMinutes(Helpers_Date__ToDateTime(x.period.PeriodEnd), 1), day) >= 0;
                }
                else {
                    return false;
                }
            }), {
                Equals: (x_1, y) => equals(x_1, y),
                GetHashCode: (x_1) => safeHash(x_1),
            }))), ((!equalsWith((x_4, y_2) => compare_1(x_4, y_2), matchValue_1, null)) ? (matchValue_1.length === 0) : false) ? null : map_3((v) => {
                let children, arg20, date_7, formatOptions_1, arg10, date_6, formatOptions;
                return createElement("div", {
                    style: {
                        marginBottom: 1 + "rem",
                    },
                    children: Interop_reactApi.Children.toArray([(children = ofArray([createElement("b", {
                        children: ["Ferie: "],
                    }), (arg20 = ((date_7 = Helpers_Date__ToDateTime(v.period.PeriodEnd), (formatOptions_1 = {}, (formatOptions_1.locale = model.Locale, format_5(date_7, "dd. MMMM", formatOptions_1))))), (arg10 = ((date_6 = Helpers_Date__ToDateTime(v.period.PeriodStart), (formatOptions = {}, (formatOptions.locale = model.Locale, format_5(date_6, "dd. MMMM", formatOptions))))), toText(printf("%s - %s"))(arg10)(arg20)))]), createElement("p", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))]),
                });
            }, matchValue_1))) : null)), (renderProject = ((matchValue_2 = model.UserProjects, (matchValue_2 != null) ? ((matchValue_3 = ((day_1 = day_2, Array_distinct(matchValue_2.filter((x_2) => {
                if (compare(x_2.plannedStart, day_1) <= 0) {
                    return compare(addMinutes(x_2.plannedEnd, 1), day_1) >= 0;
                }
                else {
                    return false;
                }
            }), {
                Equals: (x_3, y_1) => equals(x_3, y_1),
                GetHashCode: (x_3) => safeHash(x_3),
            }))), ((!equalsWith((x_5, y_3) => compare_1(x_5, y_3), matchValue_3, null)) ? (matchValue_3.length === 0) : false) ? null : map_3((p) => {
                let children_2, children_4, arg20_1, formatOptions_3, arg10_1, formatOptions_2, children_6;
                const children_8 = ofArray([(children_2 = ofArray([createElement("b", {
                    children: ["På prosjekt:  "],
                }), p.projectname]), createElement("p", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), (children_4 = ofArray([createElement("b", {
                    children: ["Periode:  "],
                }), (arg20_1 = ((formatOptions_3 = {}, (formatOptions_3.locale = model.Locale, format_5(p.plannedEnd, "dd. MMMM", formatOptions_3)))), (arg10_1 = ((formatOptions_2 = {}, (formatOptions_2.locale = model.Locale, format_5(p.plannedStart, "dd. MMMM", formatOptions_2)))), toText(printf("%s - %s"))(arg10_1)(arg20_1)))]), createElement("p", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                })), (children_6 = ofArray([createElement("b", {
                    children: ["Rolle:  "],
                }), p.rolename]), createElement("p", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                }))]);
                return createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                });
            }, matchValue_3))) : null)), (children_10 = ofArray([createElement("h1", {
                style: {
                    textAlign: "center",
                    fontSize: 1.2 + "rem",
                },
                children: Interop_reactApi.Children.toArray([(value_17 = ((arg10_2 = ((formatOptions_4 = {}, (formatOptions_4.locale = model.Locale, format_5(day_2, "dd. MMMM", formatOptions_4)))), toText(printf("Dato:  %A"))(arg10_2))), createElement("b", {
                    children: [value_17],
                }))]),
            }), MuiHelpers_createElement(Divider, [["variant", "middle"], ["style", {
                marginBottom: 1 + "rem",
            }]]), renderVacation, renderProject]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            })))))) : singleton_1(null);
        })))),
    })])]]);
    const rows = map_2((i) => {
        const day_3 = item_1(i * 7, days);
        const lastDay = addDays(day_3, 6.9);
        let vacations_1;
        const matchValue_5 = model.Vacations;
        vacations_1 = ((matchValue_5 != null) ? matchValue_5.filter((e) => {
            const pStart = Helpers_Date__ToDateTime(e.period.PeriodStart);
            const pEnd = Helpers_Date__ToDateTime(e.period.PeriodEnd);
            return compare(date_18(pStart), date_18(pEnd)) <= 0;
        }).filter((e_1) => {
            const pStart_1 = Helpers_Date__ToDateTime(e_1.period.PeriodStart);
            const pEnd_1 = Helpers_Date__ToDateTime(e_1.period.PeriodEnd);
            return overlaps(date_18(pStart_1), date_18(pEnd_1), date_18(day_3), date_18(lastDay));
        }) : []);
        let projects;
        const matchValue_6 = model.UserProjects;
        projects = ((matchValue_6 != null) ? matchValue_6.filter((e_2) => overlaps(date_18(e_2.plannedStart), addDays_1(date_18(e_2.plannedEnd), 1), date_18(day_3), date_18(lastDay))) : []);
        const dayRow = createElement("div", {
            className: "rbc-row-bg",
            style: {
                zIndex: 100,
            },
            children: Interop_reactApi.Children.toArray(Array.from(map_2((j) => {
                const cloneDay = addDays_1(day_3, j);
                return createElement("div", {
                    className: "rbc-day-bg",
                    onClick: (ev) => {
                        const matchValue_7 = ev.target;
                        if (equals(matchValue_7, null)) {
                        }
                        else {
                            setState([matchValue_7, cloneDay]);
                        }
                    },
                    style: createObj(toList(delay(() => append_2((month(model.CurrentMonth) !== month(cloneDay)) ? singleton_1(["backgroundColor", "#eee"]) : empty_1(), delay(() => singleton_1(["cursor", "pointer"])))))),
                });
            }, rangeDouble(0, 1, 6)))),
        });
        const contentRow = createElement("div", {
            className: "rbc-row-content",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "rbc-row",
                children: Interop_reactApi.Children.toArray(Array.from(map_2((j_1) => {
                    let copyOfStruct;
                    return createElement("div", {
                        className: "rbc-date-cell",
                        children: Interop_reactApi.Children.toArray([int32ToString((copyOfStruct = addDays_1(day_3, j_1), day_4(copyOfStruct)))]),
                    });
                }, rangeDouble(0, 1, 6)))),
            }), createElement("div", {
                className: "rbc-row",
                children: Interop_reactApi.Children.toArray([map_3((j_2) => {
                    const d = addDays_1(day_3, j_2);
                    return map_3((e_3) => {
                        const pStart_2 = Helpers_Date__ToDateTime(e_3.period.PeriodStart);
                        const pEnd_2 = Helpers_Date__ToDateTime(e_3.period.PeriodEnd);
                        return createElement("div", {
                            className: "rbc-date-cell",
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (((compare(pStart_2, d) <= 0) ? (compare(pEnd_2, d) >= 0) : false) ? singleton_1(createElement("div", {
                                style: {
                                    width: 100 + "%",
                                    height: 4 + "px",
                                    borderRadius: 45 + "px",
                                    margin: 3 + "px",
                                    backgroundColor: "#eea4a4",
                                },
                            })) : empty_1()))))),
                        });
                    }, vacations_1);
                }, toArray(rangeDouble(0, 1, 6)))]),
            }), createElement("div", {
                className: "rbc-row",
                children: Interop_reactApi.Children.toArray([map_3((j_3) => {
                    const d_1 = addDays_1(day_3, j_3);
                    return createElement("div", {
                        className: "rbc-date-cell",
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (projects.some((e_4) => {
                            if (compare(e_4.plannedStart, d_1) <= 0) {
                                return compare(addMinutes(e_4.plannedEnd, 1), d_1) >= 0;
                            }
                            else {
                                return false;
                            }
                        }) ? singleton_1(createElement("div", {
                            style: {
                                width: 100 + "%",
                                height: 4 + "px",
                                borderRadius: 45 + "px",
                                margin: 3 + "px",
                                backgroundColor: "#17a2b8",
                            },
                        })) : empty_1()))))),
                    });
                }, toArray(rangeDouble(0, 1, 6)))]),
            })]),
        });
        return createElement("div", {
            className: "rbc-month-row",
            key: toString(day_3),
            children: Interop_reactApi.Children.toArray([dayRow, contentRow]),
        });
    }, rangeDouble(0, 1, 4));
    return createElement("div", {
        className: "rbc-month-view",
        children: Interop_reactApi.Children.toArray(Array.from(append_2(rows, singleton_1(popover$0027)))),
    });
}

function AddVacationForm(addVacationFormInputProps) {
    let copyOfStruct, copyOfStruct_1, props_5, props_3;
    const userId = addVacationFormInputProps.userId;
    const dispatch = addVacationFormInputProps.dispatch;
    const initStart = (copyOfStruct = now(), date_18(copyOfStruct));
    const initEnd = addDays_1((copyOfStruct_1 = now(), date_18(copyOfStruct_1)), 7);
    const patternInput = useFeliz_React__React_useState_Static_1505(initStart);
    const startDate = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(initEnd);
    const endDate = patternInput_1[0];
    let datePickers;
    const props_2 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DatePicker, [["label", "Start Dato"], ["autoOk", true], ["value", defaultArg(startDate, null)], ["onChange", patternInput[1]], ["format", "dd. MMMM yyyy"], ["variant", "inline"], ["style", {
        margin: 5,
    }]]), MuiHelpers_createElement(DatePicker, [["label", "Slutt Dato"], ["autoOk", true], ["value", defaultArg(endDate, null)], ["variant", "inline"], ["onChange", patternInput_1[1]], ["format", "dd. MMMM yyyy"], ["style", {
        margin: 5,
    }]])])]]);
    const el = MuiPickersUtilsProvider;
    datePickers = MuiHelpers_createElement(el, toList(delay(() => {
        let value_29;
        return append_2(singleton_1((value_29 = date$002Dfns, ["utils", value_29])), delay(() => props_2));
    })));
    return MuiHelpers_createElement(Dialog, [["onClose", (delegateArg0, delegateArg1) => {
        dispatch(new Message(5));
    }], ["open", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til ferie"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["style", {
        flexDirection: "column",
        justifyContent: "space-between",
        marginBottom: 20 + "px",
    }], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-full"], ["children", Interop_reactApi.Children.toArray([datePickers])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_5))))])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg3) => {
        dispatch(new Message(5));
    }], ["color", "primary"], ["children", "Avbryt"]]), MuiHelpers_createElement(Button, [["onClick", (_arg4) => {
        const matchValue = map2((s, e) => (new Helpers_TimePeriod(Helpers_Date_FromDateTime_7F9DDECF(s), Helpers_Date_FromDateTime_7F9DDECF(e))), startDate, endDate);
        if (matchValue != null) {
            const pr = addVacation(userId, dispatch, matchValue);
            pr.then();
        }
    }], ["disabled", (startDate == null) ? (startDate == null) : false], ["color", "primary"], ["children", "Legg til"]])])]])])]]);
}

function drawVacationList(vacations, dispatch) {
    const innerbody = MuiHelpers_createElement(Table, [["style", {
        maxWidth: 27 + "rem",
        width: 27 + "vw",
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["children", "Fra"]])])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["children", "Til"]])])]]), MuiHelpers_createElement(TableCell, [["children", ""]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from((vacations != null) ? ofArray(map_3((i) => {
        let children_1;
        return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Helpers_Date__ToLocalDate(i.period.PeriodStart)]]), MuiHelpers_createElement(TableCell, [["children", Helpers_Date__ToLocalDate(i.period.PeriodEnd)]]), MuiHelpers_createElement(TableCell, [["align", "right"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg1) => {
            const pr = removeVacation(i, dispatch);
            pr.then();
        }], ["children", Interop_reactApi.Children.toArray([(children_1 = singleton(react.createElement(DeleteOutlined, {})), createElement("i", {
            children: Interop_reactApi.Children.toArray(Array.from(children_1)),
        }))])]])])]])])]]);
    }, vacations)) : singleton("Ingen ferier registrert for øyeblikket.")))]])])]]);
    return MuiHelpers_createElement(Dialog, [["onClose", (delegateArg0, delegateArg1) => {
        dispatch(new Message(7));
    }], ["open", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Ferier"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([innerbody])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg4) => {
        dispatch(new Message(7));
    }], ["color", "primary"], ["children", "Lukk"]])])]])])]]);
}

function drawControlMenu(model, dispatch) {
    const refr = (color, title) => createElement("div", {
        style: {
            display: "inline-block",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "grid",
                ["grid-template-columns"]: "15px auto",
                marginRight: 5 + "px",
                fontSize: 0.8 + "rem",
            },
            children: Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-circle",
                style: {
                    ["grid-column"]: 1,
                    color: color,
                    margin: "auto",
                    marginRight: 5 + "px",
                },
            }), createElement("span", {
                style: {
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: 5 + "px",
                    color: color,
                },
                children: title,
            })]),
        })]),
    });
    return createElement("div", {
        style: {
            marginTop: 5,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_2(singleton_1(refr("#eea4a4", "Ferie")), delay(() => append_2(singleton_1(refr("#17a2b8", "På prosjekt")), delay(() => {
            let matchValue, vacationForm;
            return model.CanEdit ? append_2((matchValue = model.VacationForm, (matchValue != null) ? ((vacationForm = matchValue, singleton_1(createElement(AddVacationForm, {
                dispatch: dispatch,
                userId: model.UserId,
            })))) : singleton_1(null)), delay(() => append_2(model.ShowVacations ? singleton_1(drawVacationList(model.Vacations, dispatch)) : empty_1(), delay(() => {
                let children;
                return singleton_1((children = ofArray([MuiHelpers_createElement(Button, [["style", {
                    marginRight: 5 + "px",
                }], ["onClick", (_arg1) => {
                    dispatch(new Message(4));
                }], ["size", "small"], ["children", "Legg til ferie"]]), MuiHelpers_createElement(Button, [["onClick", (_arg2) => {
                    dispatch(new Message(7));
                }], ["size", "small"], ["children", "Vis ferier"]])]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                })));
            })))) : empty_1();
        })))))))),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        className: "rbc-calendar",
        style: {
            minHeight: 400 + "px",
            maxWidth: 400 + "px",
            height: 400 + "px",
            margin: "auto",
        },
        children: Interop_reactApi.Children.toArray([drawHeader(model, dispatch), drawWeekDays, DrawMonth(model, dispatch), createElement("br", {}), drawControlMenu(model, dispatch)]),
    });
}

export function Calendar(calendarInputProps) {
    const userId = calendarInputProps.userId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(userId, calendarInputProps.canEdit));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchUserInfo(userId, dispatch);
    });
    return view(patternInput[0], dispatch);
}

