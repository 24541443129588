import { toString, Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, array_type, bool_type, float64_type, string_type, option_type, int32_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ProjectCardInfo, ProjectGalleryMeta$reflection, ProjectCardInfo$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { item, length, singleton as singleton_1, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { equals, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { map as map_1, empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { map } from "../.fable/fable-library.3.2.4/Array.js";
import { loadingIndicatorSmall, snackbarError, loadingIndicatorCenteredSmall } from "../ViewHelpers.js";
import Divider from "@material-ui/core/Divider";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

export class Orientation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Portrait", "Landscape"];
    }
}

export function Orientation$reflection() {
    return union_type("ProjectCard.Orientation", [], Orientation, () => [[], []]);
}

export function Orientation__ToWidth(this$) {
    if (this$.tag === 1) {
        return 1158;
    }
    else {
        return 819;
    }
}

export function Orientation__ToHeight(this$) {
    if (this$.tag === 1) {
        return 819;
    }
    else {
        return 1158;
    }
}

class Model extends Record {
    constructor(ProjectId, Info, Orientation, BackgroundColor, TextColor, BoxColor, BoxTextColor, BoxOpacity, UseImage, SelectedImage, ImageLeft, ImageRight, LogoBackground, ExpandMenu, Gallery, ErrorMsg) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Info = Info;
        this.Orientation = Orientation;
        this.BackgroundColor = BackgroundColor;
        this.TextColor = TextColor;
        this.BoxColor = BoxColor;
        this.BoxTextColor = BoxTextColor;
        this.BoxOpacity = BoxOpacity;
        this.UseImage = UseImage;
        this.SelectedImage = SelectedImage;
        this.ImageLeft = ImageLeft;
        this.ImageRight = ImageRight;
        this.LogoBackground = LogoBackground;
        this.ExpandMenu = ExpandMenu;
        this.Gallery = Gallery;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ProjectCard.Model", [], Model, () => [["ProjectId", int32_type], ["Info", option_type(ProjectCardInfo$reflection())], ["Orientation", Orientation$reflection()], ["BackgroundColor", string_type], ["TextColor", string_type], ["BoxColor", string_type], ["BoxTextColor", string_type], ["BoxOpacity", float64_type], ["UseImage", bool_type], ["SelectedImage", option_type(string_type)], ["ImageLeft", option_type(string_type)], ["ImageRight", option_type(string_type)], ["LogoBackground", bool_type], ["ExpandMenu", bool_type], ["Gallery", option_type(array_type(ProjectGalleryMeta$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InfoResponse", "GalleryResponse", "SetOrientation", "SetBackgroundColor", "SetTextColor", "SetBoxColor", "SetBoxTextColor", "SetBoxOpacity", "SetUseImage", "SelectImage", "SelectLeftImage", "SelectRightImage", "DismissError", "UpdateBuilder", "UpdateName", "UpdateCategory", "UpdateAreal", "UpdateContractSum", "UpdateEnterpriseForm", "UpdatePeriod", "UpdateRole", "UpdateDesc", "ToggleLogoBackground", "ToggleExpandMenu"];
    }
}

function Message$reflection() {
    return union_type("ProjectCard.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ProjectCardInfo$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ProjectCardInfo$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectGalleryMeta$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectGalleryMeta$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", Orientation$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", float64_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]]]);
}

function init(projId) {
    return new Model(projId, void 0, new Orientation(1), "#f6f6f6", "#131313", "#f2f2f2", "#131313", 0.7, true, void 0, void 0, void 0, false, false, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return model;
            }
            else {
                return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, res_1.fields[0], model.ErrorMsg);
            }
        }
        case 2: {
            return new Model(model.ProjectId, model.Info, msg.fields[0], model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.ProjectId, model.Info, model.Orientation, msg.fields[0], model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, msg.fields[0], model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 5: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, msg.fields[0], model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 6: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, msg.fields[0], model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 7: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, msg.fields[0], model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 8: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, msg.fields[0], model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 9: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, msg.fields[0], model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 10: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, msg.fields[0], model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 11: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, msg.fields[0], model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 12: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, void 0);
        }
        case 13: {
            const matchValue = model.Info;
            if (matchValue != null) {
                const i = matchValue;
                return new Model(model.ProjectId, new ProjectCardInfo(i.Name, msg.fields[0], i.Category, i.Areal, i.ContractSum, i.EnterpriseForm, i.Period, i.Role, i.Desc), model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 14: {
            const matchValue_1 = model.Info;
            if (matchValue_1 != null) {
                const i_1 = matchValue_1;
                return new Model(model.ProjectId, new ProjectCardInfo(msg.fields[0], i_1.Builder, i_1.Category, i_1.Areal, i_1.ContractSum, i_1.EnterpriseForm, i_1.Period, i_1.Role, i_1.Desc), model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 15: {
            const matchValue_2 = model.Info;
            if (matchValue_2 != null) {
                const i_2 = matchValue_2;
                return new Model(model.ProjectId, new ProjectCardInfo(i_2.Name, i_2.Builder, msg.fields[0], i_2.Areal, i_2.ContractSum, i_2.EnterpriseForm, i_2.Period, i_2.Role, i_2.Desc), model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 16: {
            const matchValue_3 = model.Info;
            if (matchValue_3 != null) {
                const i_3 = matchValue_3;
                return new Model(model.ProjectId, new ProjectCardInfo(i_3.Name, i_3.Builder, i_3.Category, msg.fields[0], i_3.ContractSum, i_3.EnterpriseForm, i_3.Period, i_3.Role, i_3.Desc), model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 17: {
            const matchValue_4 = model.Info;
            if (matchValue_4 != null) {
                const i_4 = matchValue_4;
                return new Model(model.ProjectId, new ProjectCardInfo(i_4.Name, i_4.Builder, i_4.Category, i_4.Areal, msg.fields[0], i_4.EnterpriseForm, i_4.Period, i_4.Role, i_4.Desc), model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 18: {
            const matchValue_5 = model.Info;
            if (matchValue_5 != null) {
                const i_5 = matchValue_5;
                return new Model(model.ProjectId, new ProjectCardInfo(i_5.Name, i_5.Builder, i_5.Category, i_5.Areal, i_5.ContractSum, msg.fields[0], i_5.Period, i_5.Role, i_5.Desc), model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 19: {
            const matchValue_6 = model.Info;
            if (matchValue_6 != null) {
                const i_6 = matchValue_6;
                return new Model(model.ProjectId, new ProjectCardInfo(i_6.Name, i_6.Builder, i_6.Category, i_6.Areal, i_6.ContractSum, i_6.EnterpriseForm, msg.fields[0], i_6.Role, i_6.Desc), model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 20: {
            const matchValue_7 = model.Info;
            if (matchValue_7 != null) {
                const i_7 = matchValue_7;
                return new Model(model.ProjectId, new ProjectCardInfo(i_7.Name, i_7.Builder, i_7.Category, i_7.Areal, i_7.ContractSum, i_7.EnterpriseForm, i_7.Period, msg.fields[0], i_7.Desc), model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 21: {
            const matchValue_8 = model.Info;
            if (matchValue_8 != null) {
                const i_8 = matchValue_8;
                return new Model(model.ProjectId, new ProjectCardInfo(i_8.Name, i_8.Builder, i_8.Category, i_8.Areal, i_8.ContractSum, i_8.EnterpriseForm, i_8.Period, i_8.Role, msg.fields[0]), model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 22: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, msg.fields[0], model.ExpandMenu, model.Gallery, model.ErrorMsg);
        }
        case 23: {
            return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, msg.fields[0], model.Gallery, model.ErrorMsg);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProjectId, model.Info, model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, "Noe gikk galt. Kunne ikke laste prosjektinfo.");
            }
            else {
                return new Model(model.ProjectId, res.fields[0], model.Orientation, model.BackgroundColor, model.TextColor, model.BoxColor, model.BoxTextColor, model.BoxOpacity, model.UseImage, model.SelectedImage, model.ImageLeft, model.ImageRight, model.LogoBackground, model.ExpandMenu, model.Gallery, model.ErrorMsg);
            }
        }
    }
}

function fetchInfo(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/project/cardinfo/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: ProjectCardInfo$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: ProjectCardInfo$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchGallery(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/project/gallery/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProjectGalleryMeta$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProjectGalleryMeta$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function drawPrint(model, info) {
    let children_2, children;
    const infoText = (x, y) => createElement("tr", {
        style: {
            fontSize: 18 + "px",
            color: model.BoxTextColor,
        },
        children: Interop_reactApi.Children.toArray([createElement("td", {
            style: {
                padding: ((((((5 + "px ") + 10) + "px ") + 5) + "px ") + 0) + "px",
            },
            children: x,
        }), createElement("th", {
            children: [y],
        })]),
    });
    return MuiHelpers_createElement(Paper, [["elevation", 2], ["style", {
        width: Orientation__ToWidth(model.Orientation),
        height: Orientation__ToHeight(model.Orientation),
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: createObj(toList(delay(() => append(singleton(["width", 100 + "%"]), delay(() => append(singleton(["height", 100 + "%"]), delay(() => append(singleton(["backgroundColor", model.BackgroundColor]), delay(() => append(singleton(["background-repeat", "round"]), delay(() => append(singleton(["backgroundSize", "cover"]), delay(() => {
            let matchValue, i;
            return append(model.UseImage ? ((matchValue = model.SelectedImage, (matchValue != null) ? ((i = matchValue, (console.log(some(i)), singleton(["backgroundImage", toText(printf("url(%s)"))(i)])))) : singleton(["backgroundImage", toText(printf("url(\"/api/projectimage/%i\")"))(model.ProjectId)]))) : empty_1(), delay(() => append(singleton(["padding", 20]), delay(() => singleton(["display", "flex"])))));
        }))))))))))))),
        className: "section-to-print @page",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                width: 50 + "%",
                display: "flex",
                flexDirection: "column",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    display: "flex",
                    position: "relative",
                    maxHeight: 60 + "%",
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        zIndex: 0,
                        position: "absolute",
                        height: 100 + "%",
                        width: 100 + "%",
                        backgroundColor: model.BoxColor,
                        opacity: model.BoxOpacity,
                    },
                }), createElement("div", {
                    style: {
                        width: 50 + "%",
                        padding: 15,
                        display: "flex",
                        flexDirection: "column",
                        zIndex: 1,
                        textAlign: "left",
                    },
                    children: Interop_reactApi.Children.toArray([(children_2 = singleton_1((children = ofArray([infoText("Prosjekt: ", info.Name), createElement("tr", {
                        style: {
                            fontSize: 18 + "px",
                            color: model.BoxTextColor,
                        },
                        children: Interop_reactApi.Children.toArray([createElement("td", {
                            style: {
                                padding: ((((((5 + "px ") + 10) + "px ") + 30) + "px ") + 0) + "px",
                            },
                            children: "Byggherre:",
                        }), createElement("th", {
                            style: {
                                paddingBottom: 30,
                            },
                            children: info.Builder,
                        })]),
                    }), infoText("Type: ", info.Category), infoText("Areal: ", info.Areal), infoText("Kostnad: ", info.ContractSum), infoText("Kontrakt: ", info.EnterpriseForm), infoText("Byggetid: ", info.Period)]), createElement("tbody", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))), createElement("table", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    }))]),
                }), createElement("div", {
                    style: {
                        width: 50 + "%",
                        padding: 10,
                        zIndex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        overflowY: "auto",
                        maxHeight: 100 + "%",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (((model.ImageLeft != null) ? true : (model.ImageRight != null)) ? singleton(createElement("div", {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            maxHeight: 100 + "%",
                            height: 100 + "%",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let matchValue_1, il;
                            return append((matchValue_1 = model.ImageLeft, (matchValue_1 != null) ? ((il = matchValue_1, singleton(createElement("div", {
                                style: {
                                    padding: 10,
                                    maxWidth: 100 + "%",
                                    maxHeight: 50 + "%",
                                    textAlign: "center",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("img", {
                                    style: {
                                        maxWidth: 100 + "%",
                                        maxHeight: 100 + "%",
                                    },
                                    src: il,
                                })]),
                            })))) : ((empty_1()))), delay(() => {
                                const matchValue_2 = model.ImageRight;
                                if (matchValue_2 != null) {
                                    const ir = matchValue_2;
                                    return singleton(createElement("div", {
                                        style: {
                                            padding: 10,
                                            maxWidth: 100 + "%",
                                            maxHeight: 50 + "%",
                                            textAlign: "center",
                                        },
                                        children: Interop_reactApi.Children.toArray([createElement("img", {
                                            style: {
                                                maxWidth: 100 + "%",
                                                maxHeight: 100 + "%",
                                            },
                                            src: ir,
                                        })]),
                                    }));
                                }
                                else {
                                    return empty_1();
                                }
                            }));
                        })))),
                    })) : empty_1()))))),
                })]),
            })]),
        }), createElement("div", {
            style: {
                width: 50 + "%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    position: "relative",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(model.LogoBackground ? singleton(createElement("div", {
                    style: {
                        backgroundColor: "#FFFFFF",
                        borderRadius: 10,
                        width: 470,
                        height: 70,
                        position: "absolute",
                        left: 10,
                        bottom: 31,
                    },
                })) : empty_1(), delay(() => singleton(createElement("img", {
                    style: {
                        width: 90 + "%",
                        zIndex: 5,
                        position: "relative",
                    },
                    src: "/images/logo.png",
                })))))))),
            })]),
        })]),
    })])]]);
}

function DrawExpandedMenu(drawExpandedMenuInputProps) {
    let matchValue, matchValue_1, matchValue_2, matchValue_3, matchValue_4, matchValue_5, matchValue_6, matchValue_7, matchValue_8;
    const dispatch = drawExpandedMenuInputProps.dispatch;
    const model = drawExpandedMenuInputProps.model;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showBuilderForm = patternInput[0];
    const setShowBuilderForm = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const showProjectForm = patternInput_1[0];
    const setshowProjectForm = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const showCategoryForm = patternInput_2[0];
    const setShowCategoryForm = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const showArealForm = patternInput_3[0];
    const setShowArealForm = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const showSumForm = patternInput_4[0];
    const setShowSumForm = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const showEnterpriseForm = patternInput_5[0];
    const setShowEnterpriseForm = patternInput_5[1];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(false);
    const showPeriodForm = patternInput_6[0];
    const setShowPeriodForm = patternInput_6[1];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(false);
    const button$0027 = (title, onClick) => MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["style", {
        marginBottom: 10,
    }], ["children", title], ["onClick", (_arg1) => {
        onClick();
    }]]);
    const dialog$0027 = (isActive, setIsActive, content) => MuiHelpers_createElement(Dialog, [["open", isActive], ["onClose", (_arg14, v) => {
        setIsActive(false);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray([content]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Lukk"], ["onClick", (_arg3) => {
        setIsActive(false);
    }]])])]])])]]);
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            padding: 5,
        },
        children: Interop_reactApi.Children.toArray([button$0027("Endre byggherre", () => {
            setShowBuilderForm(!showBuilderForm);
        }), button$0027("Endre prosjekt", () => {
            setshowProjectForm(!showProjectForm);
        }), button$0027("Endre type", () => {
            setShowCategoryForm(!showCategoryForm);
        }), button$0027("Endre areal", () => {
            setShowArealForm(!showArealForm);
        }), button$0027("Endre kostnad", () => {
            setShowSumForm(!showSumForm);
        }), button$0027("Endre kontrakt", () => {
            setShowEnterpriseForm(!showEnterpriseForm);
        }), button$0027("Endre byggetid", () => {
            setShowPeriodForm(!showPeriodForm);
        }), dialog$0027(showBuilderForm, setShowBuilderForm, MuiHelpers_createElement(TextField, [["autoFocus", true], ["defaultValue", (matchValue = model.Info, (matchValue != null) ? matchValue.Builder : "")], ["onChange", (e) => {
            dispatch(new Message(13, Browser_Types_Event__Event_get_Value(e)));
        }], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["label", "Byggherre"]])), dialog$0027(showProjectForm, setshowProjectForm, MuiHelpers_createElement(TextField, [["autoFocus", true], ["defaultValue", (matchValue_1 = model.Info, (matchValue_1 != null) ? matchValue_1.Name : "")], ["onChange", (e_1) => {
            dispatch(new Message(14, Browser_Types_Event__Event_get_Value(e_1)));
        }], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["label", "Prosjekt"]])), dialog$0027(showCategoryForm, setShowCategoryForm, MuiHelpers_createElement(TextField, [["autoFocus", true], ["defaultValue", (matchValue_2 = model.Info, (matchValue_2 != null) ? matchValue_2.Category : "")], ["onChange", (e_2) => {
            dispatch(new Message(15, Browser_Types_Event__Event_get_Value(e_2)));
        }], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["label", "Kategori"]])), dialog$0027(showArealForm, setShowArealForm, MuiHelpers_createElement(TextField, [["autoFocus", true], ["defaultValue", (matchValue_3 = model.Info, (matchValue_3 != null) ? matchValue_3.Areal : "")], ["onChange", (e_3) => {
            dispatch(new Message(16, Browser_Types_Event__Event_get_Value(e_3)));
        }], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["label", "Areal"]])), dialog$0027(showSumForm, setShowSumForm, MuiHelpers_createElement(TextField, [["autoFocus", true], ["defaultValue", (matchValue_4 = model.Info, (matchValue_4 != null) ? matchValue_4.ContractSum : "")], ["onChange", (e_4) => {
            dispatch(new Message(17, Browser_Types_Event__Event_get_Value(e_4)));
        }], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["label", "Kontraksum"]])), dialog$0027(showEnterpriseForm, setShowEnterpriseForm, MuiHelpers_createElement(TextField, [["autoFocus", true], ["defaultValue", (matchValue_5 = model.Info, (matchValue_5 != null) ? matchValue_5.EnterpriseForm : "")], ["onChange", (e_5) => {
            dispatch(new Message(18, Browser_Types_Event__Event_get_Value(e_5)));
        }], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["label", "Enterpriseform"]])), dialog$0027(showPeriodForm, setShowPeriodForm, MuiHelpers_createElement(TextField, [["autoFocus", true], ["defaultValue", (matchValue_6 = model.Info, (matchValue_6 != null) ? matchValue_6.Period : "")], ["onChange", (e_6) => {
            dispatch(new Message(19, Browser_Types_Event__Event_get_Value(e_6)));
        }], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["label", "Tidsperiode"]])), dialog$0027(patternInput_7[0], patternInput_7[1], MuiHelpers_createElement(TextField, [["multiline", true], ["autoFocus", true], ["defaultValue", (matchValue_7 = model.Info, (matchValue_7 != null) ? matchValue_7.Role : "")], ["onChange", (e_7) => {
            dispatch(new Message(20, Browser_Types_Event__Event_get_Value(e_7)));
        }], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["label", "Rolle"]])), dialog$0027(patternInput_8[0], patternInput_8[1], MuiHelpers_createElement(TextField, [["multiline", true], ["autoFocus", true], ["defaultValue", (matchValue_8 = model.Info, (matchValue_8 != null) ? matchValue_8.Desc : "")], ["onChange", (e_8) => {
            dispatch(new Message(21, Browser_Types_Event__Event_get_Value(e_8)));
        }], ["size", "small"], ["fullWidth", true], ["variant", "outlined"], ["label", "Kort beskrivelse"]]))]),
    });
}

function drawSideMenu(model, dispatch) {
    const imageSelector = (isOpen, onClose, onSelect) => MuiHelpers_createElement(Dialog, [["open", isOpen], ["onClose", (_arg14, v) => {
        onClose();
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const matchValue = model.Gallery;
                if (matchValue != null) {
                    const gallery = matchValue;
                    return append(singleton(map((g) => createElement("div", {
                        style: {
                            width: 200,
                            height: 200,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: 5,
                            cursor: "pointer",
                        },
                        onClick: (_arg2) => {
                            onSelect(toText(printf("/api/project/gallery/image/%i"))(g.Id));
                            onClose();
                        },
                        children: Interop_reactApi.Children.toArray([createElement("img", {
                            style: {
                                maxWidth: 100 + "%",
                                maxHeight: 100 + "%",
                            },
                            src: toText(printf("/api/project/gallery/thumbnail/%i"))(g.Id),
                        })]),
                    }), gallery)), delay(() => ((gallery.length === 0) ? singleton(createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: "Ingen bilder i prosjektgalleri.",
                    })) : empty_1())));
                }
                else {
                    return singleton(loadingIndicatorCenteredSmall());
                }
            })))),
        }), MuiHelpers_createElement(Divider, [["variant", "fullWidth"], ["style", {
            width: 100 + "%",
        }]]), createElement("div", {
            style: {
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("input", {
                id: "image-left-select-input",
                type: "file",
                accept: "image/png, image/jpeg",
                onChange: (ev) => {
                    const fileList = ev.target.files;
                    if (!(fileList == null)) {
                        const x = toList(delay(() => map_1((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                        if (length(x) > 0) {
                            const reader = new FileReader();
                            reader.onload = ((_arg3) => {
                                onSelect(toString(reader.result));
                                onClose();
                            });
                            reader.readAsDataURL(item(0, x));
                        }
                    }
                },
                style: {
                    display: "none",
                },
            }), createElement("label", {
                htmlFor: "image-left-select-input",
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["component", "span"], ["startIcon", createElement("span", {
                    className: "fas fa-upload",
                })], ["children", "Eller velg fra disk"]])]),
            })]),
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Lukk"], ["onClick", (_arg4) => {
        onClose();
    }]])])]])])]]);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setMainImageForm = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setLeftImageForm = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setRightImageForm = patternInput_2[1];
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            padding: 5,
            marginLeft: 2 + "vw",
            marginRight: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
            style: {
                marginBottom: 10,
                display: "flex",
                flexDirection: "column",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, toList(delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["variant", "contained"]), delay(() => append(singleton(["children", "Portrett"]), delay(() => append(equals(model.Orientation, new Orientation(0)) ? singleton(["color", "secondary"]) : empty_1(), delay(() => singleton(["onClick", (_arg5) => {
                dispatch(new Message(2, new Orientation(0)));
            }])))))))))))), MuiHelpers_createElement(Button, toList(delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["variant", "contained"]), delay(() => append(singleton(["children", "Landskap"]), delay(() => append(equals(model.Orientation, new Orientation(1)) ? singleton(["color", "secondary"]) : empty_1(), delay(() => singleton(["onClick", (_arg6) => {
                dispatch(new Message(2, new Orientation(1)));
            }]))))))))))))]),
        })), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Bakgrunnsbilde"], ["control", MuiHelpers_createElement(Checkbox, [["size", "small"], ["checked", model.UseImage], ["onChange", (e) => {
            dispatch(new Message(8, Browser_Types_Event__Event_get_Checked(e)));
        }]])]])), delay(() => append(model.UseImage ? singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["component", "span"], ["children", "Velg annet bilde"], ["onClick", (_arg7) => {
            setMainImageForm(true);
        }]])) : empty_1(), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Bakgrunnsfarge"], ["control", createElement("div", {
            style: {
                width: 15,
                height: 15,
                marginRight: 11,
                marginLeft: 11,
                backgroundColor: model.BackgroundColor,
                cursor: "pointer",
            },
            children: Interop_reactApi.Children.toArray([createElement("input", {
                type: "color",
                style: {
                    display: "none",
                },
                value: model.BackgroundColor,
                onChange: (ev_1) => {
                    dispatch(new Message(3, ev_1.target.value));
                },
            })]),
        })]])), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Tekstfarge"], ["control", createElement("div", {
            style: {
                width: 15,
                height: 15,
                marginRight: 11,
                marginLeft: 11,
                backgroundColor: model.TextColor,
                cursor: "pointer",
            },
            children: Interop_reactApi.Children.toArray([createElement("input", {
                type: "color",
                style: {
                    display: "none",
                },
                value: model.TextColor,
                onChange: (ev_2) => {
                    dispatch(new Message(4, ev_2.target.value));
                },
            })]),
        })]])), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Infoboks bakgrunnsfarge"], ["control", createElement("div", {
            style: {
                width: 15,
                height: 15,
                marginRight: 11,
                marginLeft: 11,
                backgroundColor: model.BoxColor,
                cursor: "pointer",
            },
            children: Interop_reactApi.Children.toArray([createElement("input", {
                type: "color",
                style: {
                    display: "none",
                },
                value: model.BoxColor,
                onChange: (ev_3) => {
                    dispatch(new Message(5, ev_3.target.value));
                },
            })]),
        })]])), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Infoboks tekstfarge"], ["control", createElement("div", {
            style: {
                width: 15,
                height: 15,
                marginRight: 11,
                marginLeft: 11,
                backgroundColor: model.BoxTextColor,
                cursor: "pointer",
            },
            children: Interop_reactApi.Children.toArray([createElement("input", {
                type: "color",
                style: {
                    display: "none",
                },
                value: model.BoxTextColor,
                onChange: (ev_4) => {
                    dispatch(new Message(6, ev_4.target.value));
                },
            })]),
        })]])), delay(() => append(singleton(MuiHelpers_createElement(Typography, [["id", "opacity-slider"], ["children", "Infoboks gjennomsiktighet"]])), delay(() => append(singleton(MuiHelpers_createElement(Slider, [["defaultValue", model.BoxOpacity], ["min", 0], ["max", 1], ["aria-labelledby", "opacity-slider"], ["onChange", (_arg26, v_1) => {
            dispatch(new Message(7, v_1));
        }], ["step", 0.05]])), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Logo bakgrunn"], ["control", MuiHelpers_createElement(Checkbox, [["size", "small"], ["checked", model.LogoBackground], ["onChange", (e_1) => {
            dispatch(new Message(22, Browser_Types_Event__Event_get_Checked(e_1)));
        }]])]])), delay(() => append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["component", "span"], ["onClick", (_arg8) => {
            setLeftImageForm(true);
        }], ["children", "Ekstra bilde topp"]])), delay(() => append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["style", {
            marginTop: 5,
        }], ["component", "span"], ["onClick", (_arg9) => {
            setRightImageForm(true);
        }], ["children", "Ekstra bilde bunn"]])), delay(() => append(singleton(imageSelector(patternInput[0], () => {
            setMainImageForm(false);
        }, (arg_7) => {
            dispatch(new Message(9, arg_7));
        })), delay(() => append(singleton(imageSelector(patternInput_1[0], () => {
            setLeftImageForm(false);
        }, (arg_8) => {
            dispatch(new Message(10, arg_8));
        })), delay(() => append(singleton(imageSelector(patternInput_2[0], () => {
            setRightImageForm(false);
        }, (arg_9) => {
            dispatch(new Message(11, arg_9));
        })), delay(() => append(singleton(createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: ((((((10 + "px ") + 5) + "px ") + 5) + "px ") + 5) + "px",
            },
            onClick: (_arg13) => {
                dispatch(new Message(23, !model.ExpandMenu));
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    marginRight: 5,
                },
                className: model.ExpandMenu ? "fas fa-chevron-down" : "fas fa-chevron-right",
            }), createElement("span", {
                style: {},
                children: model.ExpandMenu ? "Vis mindre" : "Vis mer",
            })]),
        })), delay(() => append(model.ExpandMenu ? singleton(createElement(DrawExpandedMenu, {
            model: model,
            dispatch: dispatch,
        })) : empty_1(), delay(() => {
            let children_2;
            return append(singleton(createElement("div", {
                style: {
                    marginTop: 10,
                    maxWidth: 200,
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    style: {},
                    children: "Tips til utskrift:",
                }), (children_2 = ofArray([createElement("li", {
                    children: ["Fjern marg under utskriftsinnstillinger."],
                }), createElement("li", {
                    children: ["Sett orientasjon til å være lik den brukt i menyen over (landskap/portrett)."],
                }), createElement("li", {
                    children: ["Inkluder \"Print bakgrunn\" i innstillinger."],
                })]), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                }))]),
            })), delay(() => singleton(createElement("div", {
                style: {
                    marginTop: 10,
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Skriv ut"], ["onClick", (_arg14_1) => {
                    window.print();
                }]])]),
            }))));
        })))))))))))))))))))))))))))))))))))))),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            padding: 1 + "vw",
            display: "flex",
        },
        children: Interop_reactApi.Children.toArray([snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(12));
        }), createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let matchValue;
                return append((matchValue = model.Info, (matchValue != null) ? singleton(drawPrint(model, matchValue)) : singleton(createElement("div", {
                    style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 400,
                        height: 400,
                    },
                    children: Interop_reactApi.Children.toArray([loadingIndicatorSmall()]),
                }))), delay(() => singleton(drawSideMenu(model, dispatch))));
            })))),
        })]),
    });
}

export function ProjectCard(projectCardInputProps) {
    const projectId = projectCardInputProps.projectId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectId));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchInfo(projectId, dispatch);
        fetchGallery(projectId, dispatch);
    });
    return view(patternInput[0], dispatch);
}

