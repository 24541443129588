import { class_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { keyValueList } from "./.fable/fable-library.3.2.4/MapUtil.js";

export class TimelineModule_group {
    constructor() {
    }
}

export function TimelineModule_group$reflection() {
    return class_type("Fable.Vis.Timeline.TimelineModule.group", void 0, TimelineModule_group);
}

export class TimelineModule_tooltip$0027 {
    constructor() {
    }
}

export function TimelineModule_tooltip$0027$reflection() {
    return class_type("Fable.Vis.Timeline.TimelineModule.tooltip\u0027", void 0, TimelineModule_tooltip$0027);
}

export class TimelineModule_item {
    constructor() {
    }
}

export function TimelineModule_item$reflection() {
    return class_type("Fable.Vis.Timeline.TimelineModule.item", void 0, TimelineModule_item);
}

export class TimelineModule_options {
    constructor() {
    }
}

export function TimelineModule_options$reflection() {
    return class_type("Fable.Vis.Timeline.TimelineModule.options", void 0, TimelineModule_options);
}

export class TimelineModule_editable {
    constructor() {
    }
}

export function TimelineModule_editable$reflection() {
    return class_type("Fable.Vis.Timeline.TimelineModule.editable", void 0, TimelineModule_editable);
}

function TimelineModule_createTimelineGroup(options) {
    return options;
}

export function TimelineModule_timelineGroup(opts) {
    return TimelineModule_createTimelineGroup(keyValueList(opts, 1));
}

function TimelineModule_createTooltip(options) {
    return options;
}

export function TimelineModule_timelineTooltip(opts) {
    return TimelineModule_createTooltip(keyValueList(opts, 1));
}

function TimelineModule_createTimelineItem(options) {
    return options;
}

export function TimelineModule_timelineItem(opts) {
    return TimelineModule_createTimelineItem(keyValueList(opts, 1));
}

function TimelineModule_createTimelineOptions(options) {
    return options;
}

export function TimelineModule_timelineOptions(opts) {
    return TimelineModule_createTimelineOptions(keyValueList(opts, 1));
}

function TimelineModule_createEditable(options) {
    return options;
}

export function TimelineModule_itemEditable(opts) {
    return TimelineModule_createEditable(keyValueList(opts, 1));
}

