import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { displayColor, DefaultColors, Picker, DefaultBlue } from "../ColorPicker.js";
import { Competence_SkillLevel$reflection, Competence_SkillLevel } from "../Shared/ApiDataTypes.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import { empty, append, singleton, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { loadingIndicatorMedium, snackbarError, errorBanner } from "../ViewHelpers.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { singleton as singleton_1, empty as empty_1 } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { obj_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Competence_fetchSkillLevels } from "../Promises.js";
import { sortByDescending, map, append as append_1 } from "../.fable/fable-library.3.2.4/Array.js";
import Add from "@material-ui/icons/Add";

export function skillPill(name, level, bgColor, txtColor) {
    return createElement("div", {
        style: {
            display: "flex",
            borderRadius: 12,
            ["box-shadow"]: "0px 2px 2px rgba(0,0,0,0.2)",
            margin: 5,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                textAlign: "center",
                ["border-top-left-radius"]: 12,
                ["border-bottom-left-radius"]: 12,
                backgroundColor: "#f1f1f1",
                padding: ((3 + "px ") + 8) + "px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    whiteSpace: "nowrap",
                    userSelect: "none",
                },
                children: name,
            })]),
        }), createElement("div", {
            style: {
                textAlign: "center",
                ["border-top-right-radius"]: 12,
                ["border-bottom-right-radius"]: 12,
                backgroundColor: bgColor,
                padding: ((3 + "px ") + 8) + "px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    color: txtColor,
                    whiteSpace: "nowrap",
                    userSelect: "none",
                },
                children: level,
            })]),
        })]),
    });
}

export function skillPillNoLevelDelete(name, onDismiss) {
    return createElement("div", {
        style: {
            display: "flex",
            borderRadius: 12,
            ["box-shadow"]: "0px 2px 2px rgba(0,0,0,0.2)",
            margin: 5,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                textAlign: "center",
                borderRadius: 12,
                backgroundColor: "#f1f1f1",
                padding: ((3 + "px ") + 8) + "px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    whiteSpace: "nowrap",
                    userSelect: "none",
                    marginRight: 5,
                },
                children: name,
            }), MuiHelpers_createElement(IconButton, [["size", "small"], ["style", {
                height: 20,
                padding: 0,
            }], ["children", react.createElement(Delete, {})], ["onClick", (_arg1) => {
                onDismiss();
            }]])]),
        })]),
    });
}

export function skillPillNoLevel(name) {
    return createElement("div", {
        style: {
            display: "flex",
            borderRadius: 12,
            ["box-shadow"]: "0px 2px 2px rgba(0,0,0,0.2)",
            margin: 5,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                textAlign: "center",
                borderRadius: 12,
                backgroundColor: "#f1f1f1",
                padding: ((3 + "px ") + 8) + "px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    whiteSpace: "nowrap",
                    userSelect: "none",
                    marginRight: 5,
                },
                children: name,
            })]),
        })]),
    });
}

export function SkillLevelForm(skillLevelFormInputProps) {
    const onClose = skillLevelFormInputProps.onClose;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Competence_SkillLevel(-1, "", 1, DefaultBlue, "#ffffff"));
    const updateForm = patternInput[1];
    const addForm = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const validWeight = patternInput_1[0];
    const setValidWeight = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setSelectColor = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrorMsg = patternInput_3[1];
    return MuiHelpers_createElement(Dialog, [["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["open", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til ferdighetsnivå"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            width: 250,
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["autoFocus", true], ["defaultValue", addForm.Name], ["fullWidth", true], ["label", "Navn (f.eks. nybegynner)"], ["onChange", (e) => {
            updateForm(new Competence_SkillLevel(addForm.Id, Browser_Types_Event__Event_get_Value(e), addForm.Weight, addForm.ColorCode, addForm.TextColorCode));
        }], ["variant", "outlined"], ["size", "small"], ["required", true]]), MuiHelpers_createElement(TextField, [["defaultValue", addForm.Weight], ["fullWidth", true], ["label", "Vekt"], ["error", !validWeight], ["onChange", (e_1) => {
            const x_2 = Browser_Types_Event__Event_get_Value(e_1);
            try {
                const asInt = parse(x_2, 511, false, 32) | 0;
                if (asInt >= 0) {
                    updateForm(new Competence_SkillLevel(addForm.Id, addForm.Name, asInt, addForm.ColorCode, addForm.TextColorCode));
                    setValidWeight(true);
                }
                else {
                    setValidWeight(false);
                }
            }
            catch (matchValue) {
                setValidWeight(false);
            }
        }], ["variant", "outlined"], ["size", "small"], ["required", true], ["style", {
            marginTop: 10,
        }]]), createElement("div", {
            style: {
                margin: ((5 + "px ") + 0) + "px",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (patternInput_2[0] ? singleton(createElement(Picker, {
                colors: DefaultColors,
                onSelect: (c) => {
                    if (c != null) {
                        updateForm(new Competence_SkillLevel(addForm.Id, addForm.Name, addForm.Weight, c[0], c[1]));
                    }
                    setSelectColor(false);
                },
            })) : append(singleton(createElement("span", {
                children: "Fargekode",
            })), delay(() => singleton(displayColor(addForm.ColorCode, (_arg2) => {
                setSelectColor(true);
            }))))))))),
        }), createElement("div", {
            style: {
                minHeight: 30,
                display: "flex",
                justifyContent: "center",
                marginTop: 5,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((addForm.Name.length > 0) ? singleton(skillPill("Eksempelferdighet", addForm.Name, addForm.ColorCode, addForm.TextColorCode)) : empty()))))),
        }), errorBanner(patternInput_3[0], () => {
            setErrorMsg(void 0);
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg4) => {
        onClose(void 0);
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", ((addForm.Name === "") ? true : (addForm.Weight < 0)) ? true : (!validWeight)], ["children", "Legg til"], ["onClick", (_arg5) => {
        if (((addForm.Name !== "") ? (addForm.Weight >= 0) : false) ? validWeight : false) {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Competence_SkillLevel$reflection,
                })(addForm);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Competence_SkillLevel$reflection,
                });
                return Fetch_tryPost_5760677E("/api/skill/level", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Competence_SkillLevel$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 1) {
                    const err = res.fields[0];
                    let pattern_matching_result;
                    if (err.tag === 2) {
                        if ((err.fields[0].status) === 409) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                    switch (pattern_matching_result) {
                        case 0: {
                            setErrorMsg("Det eksisterer allerede ett nivå med samme vekt");
                            return Promise.resolve();
                        }
                        case 1: {
                            setErrorMsg("Kunne ikke opprette ferdighetsnivå.");
                            return Promise.resolve();
                        }
                    }
                }
                else {
                    onClose(res.fields[0]);
                    return Promise.resolve();
                }
            })))));
            pr.then();
        }
    }]])])]])])]]);
}

export function SkillLevel() {
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setLevels = patternInput[1];
    const levels = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setAddForm = patternInput_2[1];
    const addForm = patternInput_2[0];
    useReact_useEffectOnce_3A5B6456(() => {
        Competence_fetchSkillLevels((res) => {
            if (res.tag === 1) {
                setErrMsg("Kunne ikke laste ferdighetsnivå");
            }
            else {
                setLevels(res.fields[0]);
            }
        });
    });
    return createElement("div", {
        style: {
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(snackbarError(patternInput_1[0], () => {
            setErrMsg(void 0);
        })), delay(() => append(singleton(createElement("span", {
            style: {
                fontSize: 1.5 + "rem",
                marginBottom: 10,
                textAlign: "center",
            },
            children: "Ferdighetsnivå",
        })), delay(() => append(singleton(createElement("span", {
            style: {
                textAlign: "center",
                marginBottom: 20,
            },
            children: "\n                    Her kan du definere de forskjellige nivåene som brukerene kan registrere sine ferdigheter under. \n                    Vekt-verdien kan du bruke for å indikere kompetansenivå, hvor høyere vektverdi betyr høyere kompetanse. \n                    F.eks. kan du registrere 2 nivåer: nybegynner (vekt 1) og viderekommen (vekt 2), eller du kan f.eks. finjustere dette med 10 forskjellige nivå med vekt 1-10\n                    ",
        })), delay(() => {
            if (levels != null) {
                const x_1 = levels;
                return append(addForm ? singleton(createElement(SkillLevelForm, {
                    onClose: (s) => {
                        if (s != null) {
                            const lvl = s;
                            if (levels != null) {
                                setLevels(append_1([lvl], levels));
                            }
                            else {
                                setLevels([lvl]);
                            }
                        }
                        setAddForm(false);
                    },
                })) : empty(), delay(() => append((x_1.length === 0) ? singleton(createElement("span", {
                    style: {
                        fontStyle: "italic",
                        textAlign: "center",
                    },
                    children: "Ingen ferdighetsnivå registrert",
                })) : singleton(createElement("div", {
                    style: {
                        display: "flex",
                        flexWrap: "wrap",
                    },
                    children: Interop_reactApi.Children.toArray([map((y_3) => createElement("div", {
                        style: {
                            margin: 5,
                        },
                        children: Interop_reactApi.Children.toArray([skillPill("Eksempelferdighet", y_3.Name, y_3.ColorCode, y_3.TextColorCode)]),
                    }), sortByDescending((y_1) => y_1.Weight, x_1, {
                        Compare: (x_2, y_2) => comparePrimitives(x_2, y_2),
                    }))]),
                })), delay(() => {
                    let children_1;
                    return singleton((children_1 = singleton_1(MuiHelpers_createElement(IconButton, [["onClick", (_arg3) => {
                        setAddForm(!addForm);
                    }], ["children", react.createElement(Add, {})]])), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    })));
                }))));
            }
            else {
                return singleton(createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "center",
                    },
                    children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
                }));
            }
        })))))))))),
    });
}

export function CombinedView() {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
        },
        children: Interop_reactApi.Children.toArray([createElement(SkillLevel, null)]),
    });
}

