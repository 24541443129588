import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, string_type, option_type, array_type, class_type, union_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { toString } from "../.fable/fable-library.3.2.4/Date.js";
import { format, interpolate, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { Nonconformity_MonthCount$reflection, Nonconformity_CategoryCount$reflection, Nonconformity_NonconformityStat$reflection, Helpers_IdName$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, append as append_1, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, compareArrays, int32ToString, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import { Text_secondaryText } from "../ViewHelpers.js";
import { monthsInPeriod, toDateStringNO } from "../Utils.js";
import Skeleton from "@material-ui/lab/Skeleton";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { sumBy, sortByDescending, mapIndexed, sortBy, tryFind, map } from "../.fable/fable-library.3.2.4/Array.js";
import { object } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Cell, Pie, PieChart, Bar, Legend, Tooltip, YAxis, XAxis, BarChart, ResponsiveContainer } from "recharts";
import { Colors_getColorWheel } from "../Design.js";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { Nonconformity_fetchCategories } from "../Promises.js";
import { Feed } from "./NonconformityFeed.js";

export class ReportLevel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Project", "Company", "Group", "Feed"];
    }
}

export function ReportLevel$reflection() {
    return union_type("NonconformityReport.ReportLevel", [], ReportLevel, () => [[["Item", int32_type]], [["Item", int32_type]], [], []]);
}

export function ReportLevel__ToName(this$) {
    switch (this$.tag) {
        case 1: {
            return "selskapsnivå";
        }
        case 2: {
            return "konsernnivå";
        }
        case 3: {
            return "registreringsliste";
        }
        default: {
            return "prosjektnivå";
        }
    }
}

export function ReportLevel__ToStatsPath(this$, s, e) {
    switch (this$.tag) {
        case 0: {
            const arg20 = toString(e, "yyyy-MM-dd");
            const arg10 = toString(s, "yyyy-MM-dd");
            return toText(printf("/api/nonconformity/report/project/stats?from=%s\u0026to=%s\u0026project=%i"))(arg10)(arg20)(this$.fields[0]);
        }
        case 1: {
            const arg20_1 = toString(e, "yyyy-MM-dd");
            const arg10_1 = toString(s, "yyyy-MM-dd");
            return toText(printf("/api/nonconformity/report/company/stats?from=%s\u0026to=%s\u0026company=%i"))(arg10_1)(arg20_1)(this$.fields[0]);
        }
        default: {
            const arg20_2 = toString(e, "yyyy-MM-dd");
            const arg10_2 = toString(s, "yyyy-MM-dd");
            return toText(printf("/api/nonconformity/report/group/stats?from=%s\u0026to=%s"))(arg10_2)(arg20_2);
        }
    }
}

export function ReportLevel__PeriodSpreadPath(this$, s, e) {
    switch (this$.tag) {
        case 0: {
            const arg20 = toString(e, "yyyy-MM-dd");
            const arg10 = toString(s, "yyyy-MM-dd");
            return toText(printf("/api/nonconformity/report/project/spread?from=%s\u0026to=%s\u0026project=%i"))(arg10)(arg20)(this$.fields[0]);
        }
        case 1: {
            const arg20_1 = toString(e, "yyyy-MM-dd");
            const arg10_1 = toString(s, "yyyy-MM-dd");
            return toText(printf("/api/nonconformity/report/company/spread?from=%s\u0026to=%s\u0026company=%i"))(arg10_1)(arg20_1)(this$.fields[0]);
        }
        default: {
            const arg20_2 = toString(e, "yyyy-MM-dd");
            const arg10_2 = toString(s, "yyyy-MM-dd");
            return toText(printf("/api/nonconformity/report/group/spread?from=%s\u0026to=%s"))(arg10_2)(arg20_2);
        }
    }
}

export function ReportLevel__MonthlyPath(this$, s, e) {
    switch (this$.tag) {
        case 0: {
            const arg20 = toString(e, "yyyy-MM-dd");
            const arg10 = toString(s, "yyyy-MM-dd");
            return toText(printf("/api/nonconformity/report/project/monthly?from=%s\u0026to=%s\u0026project=%i"))(arg10)(arg20)(this$.fields[0]);
        }
        case 1: {
            const arg20_1 = toString(e, "yyyy-MM-dd");
            const arg10_1 = toString(s, "yyyy-MM-dd");
            return toText(printf("/api/nonconformity/report/company/monthly?from=%s\u0026to=%s\u0026company=%i"))(arg10_1)(arg20_1)(this$.fields[0]);
        }
        default: {
            const arg20_2 = toString(e, "yyyy-MM-dd");
            const arg10_2 = toString(s, "yyyy-MM-dd");
            return toText(printf("/api/nonconformity/report/group/monthly?from=%s\u0026to=%s"))(arg10_2)(arg20_2);
        }
    }
}

class Model extends Record {
    constructor(Level, PeriodStart, PeriodEnd, Categories, Stats, PeriodSpread, Monthly, ErrorMsg) {
        super();
        this.Level = Level;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.Categories = Categories;
        this.Stats = Stats;
        this.PeriodSpread = PeriodSpread;
        this.Monthly = Monthly;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("NonconformityReport.Model", [], Model, () => [["Level", ReportLevel$reflection()], ["PeriodStart", class_type("System.DateTime")], ["PeriodEnd", class_type("System.DateTime")], ["Categories", option_type(array_type(Helpers_IdName$reflection()))], ["Stats", option_type(Nonconformity_NonconformityStat$reflection())], ["PeriodSpread", option_type(array_type(Nonconformity_CategoryCount$reflection()))], ["Monthly", option_type(array_type(Nonconformity_MonthCount$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CategoriesResponse", "StatsResponse", "PeriodSpreadResponse", "MonthlyResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("NonconformityReport.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdName$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdName$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Nonconformity_NonconformityStat$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Nonconformity_NonconformityStat$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Nonconformity_CategoryCount$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Nonconformity_CategoryCount$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Nonconformity_MonthCount$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Nonconformity_MonthCount$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(lvl, pStart, pEnd) {
    return new Model(lvl, pStart, pEnd, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 0: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, res_1.fields[0], model.Stats, model.PeriodSpread, model.Monthly, model.ErrorMsg);
            }
        }
        case 1: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.Categories, res_2.fields[0], model.PeriodSpread, model.Monthly, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.Categories, model.Stats, model.PeriodSpread, res_3.fields[0], model.ErrorMsg);
            }
        }
        case 4: {
            return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.Categories, model.Stats, model.PeriodSpread, model.Monthly, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.Categories, model.Stats, res.fields[0], model.Monthly, model.ErrorMsg);
            }
        }
    }
}

function fetchStats(lvl, s, e, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = ReportLevel__ToStatsPath(lvl, s, e);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Nonconformity_NonconformityStat$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Nonconformity_NonconformityStat$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchPeriodSpread(lvl, s, e, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = ReportLevel__PeriodSpreadPath(lvl, s, e);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Nonconformity_CategoryCount$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Nonconformity_CategoryCount$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchMonthly(lvl, s, e, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = ReportLevel__MonthlyPath(lvl, s, e);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Nonconformity_MonthCount$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Nonconformity_MonthCount$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function section(x) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            border: (((1 + "px ") + "solid") + " ") + "#dedede",
            borderRadius: 5,
            padding: 20,
        },
        children: Interop_reactApi.Children.toArray(Array.from(x)),
    });
}

function sectionTitle(x) {
    return MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", x]]);
}

export function fromToText(s, e) {
    let arg20, arg10;
    return Text_secondaryText((arg20 = toDateStringNO(e), (arg10 = toDateStringNO(s), toText(printf("Fra %s til %s"))(arg10)(arg20))));
}

export function notEnoughData() {
    return createElement("div", {
        style: {
            margin: ((10 + "px ") + 0) + "px",
            userSelect: "none",
        },
        children: Interop_reactApi.Children.toArray([Text_secondaryText("Ikke nok data")]),
    });
}

function textLoading() {
    return MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
        width: 150,
        height: 50,
    }]]);
}

function summaryText(x) {
    return createElement("span", {
        style: {
            fontSize: 2.2 + "rem",
        },
        children: x,
    });
}

function minutesToDuration(minutes) {
    if (minutes < 60) {
        return toText(interpolate("%P()min", [minutes]));
    }
    else if (minutes < 1440) {
        return toText(interpolate("%P()t %P()min", [~(~(minutes / 60)), minutes % 60]));
    }
    else {
        const days = (minutes / 1440) / 24;
        const arg10 = format('{0:' + "0.0" + '}', days);
        return toText(printf("%s dager"))(arg10);
    }
}

function drawStats(model) {
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            gap: 10 + "px ",
        },
        children: Interop_reactApi.Children.toArray([section(toList(delay(() => append(singleton(sectionTitle("Antall avvik")), delay(() => append(singleton(fromToText(model.PeriodStart, model.PeriodEnd)), delay(() => {
            const matchValue = model.Stats;
            return (matchValue != null) ? singleton(summaryText(int32ToString(matchValue.PeriodTotal))) : singleton(textLoading());
        }))))))), section(toList(delay(() => append(singleton(sectionTitle("Tiltak %")), delay(() => {
            let arg20, arg10;
            return append(singleton(Text_secondaryText((arg20 = toDateStringNO(model.PeriodEnd), (arg10 = toDateStringNO(model.PeriodStart), toText(printf("Avvik med tiltak registrert. For %s til %s"))(arg10)(arg20))))), delay(() => {
                const matchValue_1 = model.Stats;
                if (matchValue_1 != null) {
                    const s_1 = matchValue_1;
                    return (s_1.PeriodTotal === 0) ? singleton(notEnoughData()) : singleton(summaryText(toText(interpolate("%P()%%", [~(~((s_1.WithAction / s_1.PeriodTotal) * 100))]))));
                }
                else {
                    return singleton(textLoading());
                }
            }));
        }))))), section(toList(delay(() => append(singleton(sectionTitle("Gj.snittlig tid for tiltak")), delay(() => {
            let arg20_1, arg10_1;
            return append(singleton(Text_secondaryText((arg20_1 = toDateStringNO(model.PeriodEnd), (arg10_1 = toDateStringNO(model.PeriodStart), toText(printf("Fra hendelse til tiltak. For %s til %s"))(arg10_1)(arg20_1))))), delay(() => {
                const matchValue_2 = model.Stats;
                if (matchValue_2 != null) {
                    const matchValue_3 = matchValue_2.AverageResolveTime;
                    return (matchValue_3 != null) ? singleton(summaryText(minutesToDuration(matchValue_3))) : singleton(notEnoughData());
                }
                else {
                    return singleton(textLoading());
                }
            }));
        })))))]),
    });
}

function drawMonthly(model) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            border: (((1 + "px ") + "solid") + " ") + "#dedede",
            borderRadius: 5,
            padding: 20,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(sectionTitle("Månedlig fordeling")), delay(() => append(singleton(fromToText(model.PeriodStart, model.PeriodEnd)), delay(() => {
            const matchValue = [model.Monthly, model.Categories];
            let pattern_matching_result, categories, monthly;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    pattern_matching_result = 0;
                    categories = matchValue[1];
                    monthly = matchValue[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const data = map((tupledArg_1) => {
                        const year_1 = tupledArg_1[0] | 0;
                        const month_1 = tupledArg_1[1] | 0;
                        const cats = ofArray(map((c) => {
                            let num;
                            const _arg1 = tryFind((x_1) => {
                                if ((x_1.Month === month_1) ? (x_1.Year === year_1) : false) {
                                    return x_1.Count.CategoryId === c.Id;
                                }
                                else {
                                    return false;
                                }
                            }, monthly);
                            num = ((_arg1 != null) ? _arg1.Count.Num : 0);
                            return [int32ToString(c.Id), num];
                        }, categories));
                        return object(append_1(singleton_1(["Name", toText(interpolate("%P()/%P()", [month_1, year_1]))]), cats));
                    }, sortBy((tupledArg) => [tupledArg[0], tupledArg[1]], monthsInPeriod(model.PeriodStart, model.PeriodEnd), {
                        Compare: (x, y) => compareArrays(x, y),
                    }));
                    return singleton(Interop_reactApi.createElement(ResponsiveContainer, {
                        minWidth: 50 + "vw",
                        minHeight: 300 + "px",
                        children: Interop_reactApi.createElement(BarChart, {
                            data: data,
                            height: 300,
                            children: ["children", Interop_reactApi.Children.toArray([Interop_reactApi.createElement(XAxis, {
                                dataKey: "Name",
                            }), Interop_reactApi.createElement(YAxis, {}), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(Legend, {}), mapIndexed((i, c_2) => {
                                const fill = Colors_getColorWheel(i);
                                return Interop_reactApi.createElement(Bar, {
                                    dataKey: int32ToString(c_2.Id),
                                    fill: fill,
                                    name: c_2.Name,
                                });
                            }, sortByDescending((c_1) => c_1.Id, categories, {
                                Compare: (x_2, y_1) => comparePrimitives(x_2, y_1),
                            }))])],
                        }),
                    }));
                }
                case 1: {
                    return singleton(MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                        width: 100 + "%",
                        minHeight: 300,
                    }]]));
                }
            }
        })))))))),
    });
}

function drawPeriodSpread(model) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            border: (((1 + "px ") + "solid") + " ") + "#dedede",
            borderRadius: 5,
            padding: 20,
        },
        children: Interop_reactApi.Children.toArray([sectionTitle("Kategorifordeling"), fromToText(model.PeriodStart, model.PeriodEnd), createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const matchValue = model.PeriodSpread;
                if (matchValue != null) {
                    const x = matchValue;
                    return append((sumBy((y) => y.Num, x, {
                        GetZero: () => 0,
                        Add: (x_1, y_1) => (x_1 + y_1),
                    }) === 0) ? singleton(Text_secondaryText("Mangler data for valgt periode")) : empty_1(), delay(() => {
                        const data = sortByDescending((y_2) => y_2.CategoryId, x, {
                            Compare: (x_2, y_3) => comparePrimitives(x_2, y_3),
                        });
                        return append(singleton(Interop_reactApi.createElement(PieChart, {
                            height: 200,
                            width: 250,
                            children: ["children", Interop_reactApi.Children.toArray([Interop_reactApi.createElement(Pie, {
                                cx: 50 + "%",
                                cy: 50 + "%",
                                label: true,
                                data: data,
                                dataKey: "Num",
                                nameKey: "Category",
                                outerRadius: 80 + "%",
                                innerRadius: 30 + "%",
                                children: ["children", Interop_reactApi.Children.toArray([mapIndexed((i, _arg1) => {
                                    const fill = Colors_getColorWheel(i);
                                    return Interop_reactApi.createElement(Cell, {
                                        key: int32ToString(i),
                                        fill: fill,
                                    });
                                }, data)])],
                            })])],
                        })), delay(() => singleton(createElement("table", {
                            size: "small",
                            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([mapIndexed((i_1, y_4) => {
                                const fill_1 = Colors_getColorWheel(i_1);
                                return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["style", {
                                    color: fill_1,
                                }], ["children", y_4.Category]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["style", {
                                    color: fill_1,
                                }], ["children", y_4.Num]])])]]);
                            }, data)])]])]),
                        }))));
                    }));
                }
                else {
                    return singleton(MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                        minWidth: 350,
                        minHeight: 200,
                    }]]));
                }
            })))),
        })]),
    });
}

export function NonconformityReport(nonconformityReportInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(nonconformityReportInputProps.level, nonconformityReportInputProps.periodStart, nonconformityReportInputProps.periodEnd));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        Nonconformity_fetchCategories((arg) => {
            dispatch(new Message(0, arg));
        });
        if (model_1.Level.tag === 3) {
        }
        else {
            fetchStats(model_1.Level, model_1.PeriodStart, model_1.PeriodEnd, dispatch);
            fetchPeriodSpread(model_1.Level, model_1.PeriodStart, model_1.PeriodEnd, dispatch);
            fetchMonthly(model_1.Level, model_1.PeriodStart, model_1.PeriodEnd, dispatch);
        }
    });
    return createElement("div", {
        className: "report-content",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((model_1.Level.tag === 3) ? singleton(createElement(Feed, null)) : append(singleton(drawStats(model_1)), delay(() => append(singleton(drawMonthly(model_1)), delay(() => singleton(drawPeriodSpread(model_1))))))))))),
    });
}

