import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Helpers_ResultMessage$reflection, Quality_QualityQuestion$reflection, Quality_QualityQuestion, Quality_QualityForm, Quality_QualityForm$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPut_5760677E, Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { sortBy, map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { format, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { empty as empty_1 } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { useReact_useReducer_2B9E6EA0, useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import { empty as empty_2, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { loadingIndicatorMedium, loadingIndicatorCenteredSmall, errorBanner, addTooltip } from "../ViewHelpers.js";
import Icon from "@material-ui/core/Icon";
import Help from "@material-ui/icons/Help";
import { parse as parse_1 } from "../.fable/fable-library.3.2.4/Double.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";

class Model extends Record {
    constructor(Forms, AddForm, ErrorMsg) {
        super();
        this.Forms = Forms;
        this.AddForm = AddForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("QualityForm.Model", [], Model, () => [["Forms", option_type(array_type(Quality_QualityForm$reflection()))], ["AddForm", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FormsResponse", "ToggleAddForm", "AddResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("QualityForm.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Quality_QualityForm$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Quality_QualityForm$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Quality_QualityForm$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Quality_QualityForm$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init() {
    return new Model(void 0, false, void 0);
}

function update(model, msg) {
    let matchValue;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Forms, !model.AddForm, model.ErrorMsg);
        }
        case 2: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Forms, model.AddForm, "Noe gikk galt. Kunne ikke opprette kvalitetsskjema");
            }
            else {
                const x_1 = res_1.fields[0];
                return new Model((matchValue = model.Forms, (matchValue != null) ? append(matchValue, [x_1]) : [x_1]), false, model.ErrorMsg);
            }
        }
        case 3: {
            return new Model(model.Forms, model.AddForm, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Forms, model.AddForm, "Kunne ikke laste kvalitetsundersøkelser");
            }
            else {
                return new Model(res.fields[0], model.AddForm, model.ErrorMsg);
            }
        }
    }
}

function fetchForms(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/quality/forms"));
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Quality_QualityForm$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Quality_QualityForm$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function tryAdd(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Quality_QualityForm$reflection,
        })(x);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Quality_QualityForm$reflection,
        });
        return Fetch_tryPost_5760677E("/api/quality/form", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Quality_QualityForm$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function AddForm(addFormInputProps) {
    const dispatch = addFormInputProps.dispatch;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Quality_QualityForm(-1, "", "", 1, 10, true, void 0, true));
    const x = patternInput[0];
    const updateForm = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const validLower = patternInput_1[0];
    const setValidLower = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const validUpper = patternInput_2[0];
    const setValidUpper = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(true);
    const includeGoal = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(7);
    const goal = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(true);
    const validGoal = patternInput_5[0];
    const setValidGoal = patternInput_5[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        dispatch(new Message(1));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til kvalitetsskjema"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(TextField, [["defaultValue", x.Name], ["onChange", (e) => {
            updateForm(new Quality_QualityForm(x.FormId, Browser_Types_Event__Event_get_Value(e), x.Desc, x.LowerLimit, x.UpperLimit, x.AllowUnsure, x.Goal, x.Enabled));
        }], ["variant", "outlined"], ["label", "Navn på skjema"], ["size", "small"], ["fullWidth", true], ["autoFocus", true], ["required", true]])), delay(() => append_1(singleton(createElement("div", {
            style: {
                display: "flex",
                marginTop: 20,
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    maxWidth: 50 + "%",
                    paddingRight: 5,
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", x.LowerLimit], ["onChange", (e_1) => {
                    const y_1 = Browser_Types_Event__Event_get_Value(e_1);
                    try {
                        const asInt = parse(y_1, 511, false, 32) | 0;
                        if (asInt > 0) {
                            updateForm(new Quality_QualityForm(x.FormId, x.Name, x.Desc, asInt, x.UpperLimit, x.AllowUnsure, x.Goal, x.Enabled));
                            setValidLower(true);
                        }
                        else {
                            setValidLower(false);
                        }
                    }
                    catch (matchValue) {
                        setValidLower(false);
                    }
                }], ["variant", "outlined"], ["label", "Svaralternativ fra"], ["size", "small"], ["fullWidth", true], ["required", true], ["error", !validLower]])]),
            }), createElement("div", {
                style: {
                    maxWidth: 50 + "%",
                    paddingLeft: 5,
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", x.UpperLimit], ["onChange", (e_2) => {
                    const y_2 = Browser_Types_Event__Event_get_Value(e_2);
                    try {
                        const asInt_1 = parse(y_2, 511, false, 32) | 0;
                        if (asInt_1 > 0) {
                            updateForm(new Quality_QualityForm(x.FormId, x.Name, x.Desc, x.LowerLimit, asInt_1, x.AllowUnsure, x.Goal, x.Enabled));
                            setValidUpper(true);
                        }
                        else {
                            setValidUpper(false);
                        }
                    }
                    catch (matchValue_1) {
                        setValidUpper(false);
                    }
                }], ["variant", "outlined"], ["label", "Svaralternativ til"], ["size", "small"], ["fullWidth", true], ["required", true], ["error", !validUpper]])]),
            })]),
        })), delay(() => append_1(singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                color: "#aaa",
            },
            children: toText(printf("Brukerne kan gi ett svar på spørsmål i intervallet %i til %i. Hvor %i er dårligst rangert og %i er best."))(x.LowerLimit)(x.UpperLimit)(x.LowerLimit)(x.UpperLimit),
        })), delay(() => append_1(singleton(createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControlLabel, [["label", "Måltall"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
                color: "#2d3a48",
            }], ["checked", includeGoal], ["onChange", (e_3) => {
                const x_1 = Browser_Types_Event__Event_get_Checked(e_3);
                if (x_1 === false) {
                    setValidGoal(true);
                }
                patternInput_3[1](x_1);
            }]])]]), addTooltip("Inkluder ett måltall som brukes i rapporter for gjennomsnittlig vurdering på tvers av selskaper.", MuiHelpers_createElement(Icon, [["style", {
                color: "#aaa",
            }], ["children", react.createElement(Help, {})]]))]),
        })), delay(() => append_1(includeGoal ? singleton(createElement("div", {
            style: {
                maxWidth: 50 + "%",
                paddingLeft: 5,
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", format('{0:' + "0.0" + '}', goal)], ["onChange", (e_4) => {
                const y_3 = Browser_Types_Event__Event_get_Value(e_4);
                try {
                    const asFloat = parse_1(y_3);
                    if ((asFloat >= x.LowerLimit) ? (asFloat <= x.UpperLimit) : false) {
                        patternInput_4[1](asFloat);
                        setValidGoal(true);
                    }
                    else {
                        setValidGoal(false);
                    }
                }
                catch (matchValue_2) {
                    setValidGoal(false);
                }
            }], ["variant", "outlined"], ["label", "Måltall"], ["size", "small"], ["fullWidth", true], ["required", true], ["error", !validGoal]])]),
        })) : empty_2(), delay(() => singleton(createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControlLabel, [["label", "Tillat \"Vet ikke\" svar"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
                color: "#2d3a48",
            }], ["checked", x.AllowUnsure], ["onChange", (e_5) => {
                updateForm(new Quality_QualityForm(x.FormId, x.Name, x.Desc, x.LowerLimit, x.UpperLimit, Browser_Types_Event__Event_get_Checked(e_5), x.Goal, x.Enabled));
            }]])]]), addTooltip("Gir brukerne muligheten til å registrere \"Vet ikke\" som svar for spørsmålene i dette kvalitetsskjemaet.", MuiHelpers_createElement(Icon, [["style", {
                color: "#aaa",
            }], ["children", react.createElement(Help, {})]]))]),
        })))))))))))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        dispatch(new Message(1));
    }]]), MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["disabled", ((x.Name.length === 0) ? true : (x.LowerLimit >= x.UpperLimit)) ? true : (!validGoal)], ["children", "Opprett"], ["onClick", (_arg3) => {
        if ((validGoal ? validLower : false) ? validUpper : false) {
            tryAdd(new Quality_QualityForm(x.FormId, x.Name, x.Desc, x.LowerLimit, x.UpperLimit, x.AllowUnsure, includeGoal ? goal : (void 0), x.Enabled), dispatch);
        }
    }]])])]])])]]);
}

function QuestionForm(questionFormInputProps) {
    const onClose = questionFormInputProps.onClose;
    const existing = questionFormInputProps.existing;
    const x = questionFormInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const updateQuestionForm = patternInput[1];
    const questionForm = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["children", react.createElement(Add, {})], ["size", "small"], ["onClick", (_arg1_1) => {
            updateQuestionForm(new Quality_QualityQuestion(-1, "", "", 1));
        }]]), MuiHelpers_createElement(Dialog, [["open", questionForm != null], ["onClose", (_arg14, v) => {
            updateQuestionForm(void 0);
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", (existing != null) ? "Oppdater spørsmål" : "Legg til kvalitetsspørsmål"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let y;
            return append_1((questionForm != null) ? ((y = questionForm, singleton(createElement("div", {
                style: {
                    minWidth: 270,
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", y.Question], ["variant", "outlined"], ["size", "small"], ["label", "Spørsmål"], ["fullWidth", true], ["onChange", (e) => {
                    updateQuestionForm(new Quality_QualityQuestion(y.Id, Browser_Types_Event__Event_get_Value(e), y.HelperText, y.RowPos));
                }]])]),
            })))) : ((empty_2())), delay(() => singleton(errorBanner(patternInput_1[0], () => {
                setErrMsg(void 0);
            }))));
        }))))]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg4) => {
            updateQuestionForm(void 0);
        }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", (questionForm != null) ? (questionForm.Question.length === 0) : true], ["onClick", (_arg5) => {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                if (questionForm != null) {
                    const qf = questionForm;
                    const requestPath = toText(printf("/api/quality/question/%i"))(x.FormId);
                    return ((existing != null) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: Quality_QualityQuestion$reflection,
                        })(qf);
                        const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: Quality_QualityQuestion$reflection,
                        });
                        return Fetch_tryPut_5760677E(requestPath, some(body_1), empty_1(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                            ResolveType: Quality_QualityQuestion$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })) : PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: Quality_QualityQuestion$reflection,
                        })(qf);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: Quality_QualityQuestion$reflection,
                        });
                        return Fetch_tryPost_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: Quality_QualityQuestion$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    }))).then(((_arg1) => {
                        const res = _arg1;
                        if (res.tag === 0) {
                            onClose(res.fields[0]);
                            updateQuestionForm(void 0);
                            return Promise.resolve();
                        }
                        else if (existing != null) {
                            setErrMsg("Noe gikk galt. Kunne ikke oppdatere spørsmål.");
                            return Promise.resolve();
                        }
                        else {
                            setErrMsg("Noe gikk galt. Kunne ikke opprette spørsmål for kvalitetsskjema.");
                            return Promise.resolve();
                        }
                    }));
                }
                else {
                    return Promise.resolve();
                }
            }));
            pr.then();
        }], ["children", "Legg til"]])])]])])]])]),
    });
}

function QuestionDeleteForm(questionDeleteFormInputProps) {
    const onClose = questionDeleteFormInputProps.onClose;
    const x = questionDeleteFormInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            maxWidth: 350,
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            style: {
                fontSize: 1 + "rem",
            },
            children: "Er du sikker på at du ønsker å fjerne spørsmålet fra dette kvalitetsskjemaet?",
        }), errorBanner(patternInput[0], () => {
            setErrMsg(void 0);
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Nei"], ["onClick", (_arg3) => {
        onClose(void 0);
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Ja"], ["onClick", (_arg4) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const requestPath = toText(printf("/api/quality/question/%i"))(x.Id);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: () => string_type,
                })("");
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryDelete_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    const r = res.fields[0];
                    if (r.Result === "success") {
                        onClose(x.Id);
                        return Promise.resolve();
                    }
                    else {
                        setErrMsg(r.Message);
                        return Promise.resolve();
                    }
                }
                else {
                    setErrMsg("Kunne ikke fjerne spørsmål fra kvalitetsskjema");
                    return Promise.resolve();
                }
            }));
        }));
        pr.then();
    }]])])]])])]]);
}

function DrawForm(x) {
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setQuestions = patternInput[1];
    const questions = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setDeleteQuestion = patternInput_1[1];
    const deleteQuestion = patternInput_1[0];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const requestPath = toText(printf("/api/quality/questions/%i"))(x.FormId);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: () => array_type(Quality_QualityQuestion$reflection()),
                });
                return Fetch_tryGet_5760677E(requestPath, void 0, empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: () => array_type(Quality_QualityQuestion$reflection()),
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 1) {
                    return Promise.resolve();
                }
                else {
                    setQuestions(res.fields[0]);
                    return Promise.resolve();
                }
            }));
        }));
        pr.then();
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 1.5 + "rem",
                },
                children: x.Name,
            })]),
        })), delay(() => {
            let arg30;
            return append_1(singleton(createElement("span", {
                style: {
                    marginBottom: 20,
                    marginTop: 10,
                    fontStyle: "italic",
                    fontSize: 0.8 + "rem",
                    textAlign: "center",
                },
                children: (arg30 = (x.AllowUnsure ? "Brukere kan velge å svare \"Vet ikke\"" : "Brukere kan ikke svare \"Vet ikke\""), toText(printf("Dette kvalitetsskjemaet gir brukerne svaralternativ i intervallet %i til %i. %s."))(x.LowerLimit)(x.UpperLimit)(arg30)),
            })), delay(() => {
                let matchValue, g, arg10_2;
                return append_1((matchValue = x.Goal, (matchValue != null) ? ((g = matchValue, singleton(createElement("span", {
                    style: {
                        marginBottom: 20,
                        marginTop: 10,
                        fontStyle: "italic",
                        fontSize: 0.8 + "rem",
                        textAlign: "center",
                    },
                    children: (arg10_2 = format('{0:' + "0.0" + '}', g), toText(printf("Mål om gjennomsnittlig vurdering på %s"))(arg10_2)),
                })))) : ((empty_2()))), delay(() => append_1(singleton(createElement("div", {
                    style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 10,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {},
                        children: "Kvalitetsspørsmål for dette skjema:",
                    }), createElement(QuestionForm, {
                        x: x,
                        existing: void 0,
                        onClose: (q) => {
                            if (q != null) {
                                const q$0027 = q;
                                setQuestions((questions != null) ? append(questions, [q$0027]) : [q$0027]);
                            }
                        },
                    })]),
                })), delay(() => append_1(singleton(createElement("div", {
                    style: {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((questions != null) ? singleton(map((q_2) => createElement("div", {
                        style: {
                            marginBottom: 10,
                            display: "flex",
                            alignItems: "center",
                        },
                        key: q_2.Id,
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            style: {
                                fontSize: 1.1 + "rem",
                                fontStyle: "italic",
                            },
                            children: q_2.Question,
                        }), MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg2) => {
                            setDeleteQuestion(q_2);
                        }], ["children", react.createElement(Delete, {})]])]),
                    }), sortBy((q_1) => q_1.RowPos, questions, {
                        Compare: (x_2, y_1) => comparePrimitives(x_2, y_1),
                    }))) : singleton(loadingIndicatorCenteredSmall())))))),
                })), delay(() => {
                    if (deleteQuestion != null) {
                        const dq = deleteQuestion;
                        return singleton(createElement(QuestionDeleteForm, {
                            x: dq,
                            onClose: (qId) => {
                                if (qId != null) {
                                    const qId$0027 = qId | 0;
                                    setQuestions((questions != null) ? questions.filter((z_1) => (z_1.Id !== qId$0027)) : (void 0));
                                }
                                setDeleteQuestion(void 0);
                            },
                        }));
                    }
                    else {
                        return empty_2();
                    }
                }))))));
            }));
        })))))),
    });
}

function listForms(dispatch, x) {
    return createElement("div", {
        style: {
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 1 + "rem",
                },
                children: "Kvalitetsskjema",
            }), MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Add, {})], ["onClick", (_arg1) => {
                dispatch(new Message(1));
            }]])]),
        }), createElement("p", {
            style: {
                marginBottom: 20,
            },
            children: "Her kan du registrere kvalitetsskjema med spørsmål som må fylles ut for hvert prosjekt før prosjektslutt.",
        }), map((f) => MuiHelpers_createElement(Paper, [["key", f.FormId], ["style", {
            display: "flex",
            flexDirection: "column",
            padding: 10,
            minWidth: 270,
            marginBottom: 15,
        }], ["elevation", 1], ["children", Interop_reactApi.Children.toArray([createElement(DrawForm, f)])]]), x)]),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            padding: 2 + "vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = model.Forms;
            if (matchValue != null) {
                const forms = matchValue;
                return append_1(model.AddForm ? singleton(createElement(AddForm, {
                    dispatch: dispatch,
                })) : empty_2(), delay(() => singleton(listForms(dispatch, forms))));
            }
            else {
                return singleton(loadingIndicatorMedium());
            }
        })))),
    });
}

export function QualityForm() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchForms(dispatch);
    });
    return view(patternInput[0], dispatch);
}

