import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { Competence_fetchUserSkills, Competence_fetchSkillLevels } from "../Promises.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { obj_type, array_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Competence_UserSkill$reflection, Competence_UserSkill, Competence_Skill, Competence_Skill$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { compareArrays, numberHash, comparePrimitives, int32ToString, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import List from "@material-ui/core/List";
import { append as append_1, sortByDescending, tryFind, map } from "../.fable/fable-library.3.2.4/Array.js";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Add from "@material-ui/icons/Add";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { loadingIndicatorSmall } from "../ViewHelpers.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { skillPill } from "../Administration/Skill.js";
import IconButton from "@material-ui/core/IconButton";

export function UserSkillForm(userSkillFormInputProps) {
    const onClose = userSkillFormInputProps.onClose;
    const userId = userSkillFormInputProps.userId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const levels = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([]);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505({
        SearchInput: "",
        SelectedLevel: void 0,
        SelectedSkill: void 0,
    });
    const updateSkillForm = patternInput_3[1];
    const skillForm = patternInput_3[0];
    useReact_useEffectOnce_3A5B6456(() => {
        Competence_fetchSkillLevels((res_2) => {
            if (res_2.tag === 1) {
            }
            else {
                patternInput[1](res_2.fields[0]);
            }
        });
    });
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til ferdighet"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
            minHeight: 200,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let selected, l;
            const matchValue_2 = skillForm.SelectedSkill;
            return (matchValue_2 == null) ? append(singleton(createElement("span", {
                style: {
                    display: "block",
                    fontSize: 0.8 + "rem",
                    marginBottom: 5,
                    maxWidth: 350,
                },
                children: "Søk og velg i eksisterende eller skriv inn og klikk \"Legg til\"",
            })), delay(() => append(singleton(MuiHelpers_createElement(TextField, [["fullWidth", true], ["size", "small"], ["variant", "outlined"], ["onChange", (e_1) => {
                const x_6 = Browser_Types_Event__Event_get_Value(e_1);
                updateSkillForm({
                    SearchInput: x_6,
                    SelectedLevel: skillForm.SelectedLevel,
                    SelectedSkill: skillForm.SelectedSkill,
                });
                window.clearTimeout(patternInput_2[0]);
                patternInput_2[1](window.setTimeout((_arg3_1) => {
                    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: () => string_type,
                        })(x_6);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: () => array_type(Competence_Skill$reflection()),
                        });
                        return Fetch_tryPost_5760677E("/api/skill/search", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: () => array_type(Competence_Skill$reflection()),
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg1) => {
                        const res = _arg1;
                        if (res.tag === 0) {
                            patternInput_1[1](res.fields[0]);
                            return Promise.resolve();
                        }
                        else {
                            return Promise.resolve();
                        }
                    })))));
                    pr.then();
                }, 500));
            }], ["onKeyDown", (kc) => {
                if (kc.code === "Enter") {
                    updateSkillForm({
                        SearchInput: skillForm.SearchInput,
                        SelectedLevel: skillForm.SelectedLevel,
                        SelectedSkill: new Competence_Skill(-1, skillForm.SearchInput),
                    });
                }
            }], ["label", "Kompetanse, f.eks Excel, prosjektledelse .."]])), delay(() => singleton(MuiHelpers_createElement(List, [["dense", true], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(map((s) => MuiHelpers_createElement(ListItem, [["dense", true], ["button", true], ["onClick", (_arg4) => {
                updateSkillForm({
                    SearchInput: skillForm.SearchInput,
                    SelectedLevel: skillForm.SelectedLevel,
                    SelectedSkill: s,
                });
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", s.Name]])])]]), patternInput_1[0])), delay(() => ((skillForm.SearchInput.length > 0) ? singleton(MuiHelpers_createElement(ListItem, [["dense", true], ["button", true], ["onClick", (_arg5) => {
                updateSkillForm({
                    SearchInput: skillForm.SearchInput,
                    SelectedLevel: skillForm.SelectedLevel,
                    SelectedSkill: new Competence_Skill(-1, skillForm.SearchInput),
                });
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([react.createElement(Add, {})])]]), MuiHelpers_createElement(ListItemText, [["primary", toText(printf("Legg til %s"))(skillForm.SearchInput)]])])]])) : empty_1())))))))]])))))) : singleton((selected = matchValue_2, (levels != null) ? ((l = levels, createElement("div", {
                style: {
                    display: "flex",
                    flexDirection: "column",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    children: toText(printf("Velg kompetansenivå for %s"))(selected.Name),
                }), MuiHelpers_createElement(FormControl, [["component", "fieldset"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(RadioGroup, toList(delay(() => append(singleton(["aria-label", "level"]), delay(() => append(singleton(["name", "radio-group"]), delay(() => append(singleton(["onChange", (e) => {
                    const x_4 = Browser_Types_Event__Event_get_Value(e);
                    const _arg3 = tryFind((y) => (int32ToString(y.Id) === x_4), l);
                    if (_arg3 != null) {
                        updateSkillForm({
                            SearchInput: skillForm.SearchInput,
                            SelectedLevel: _arg3,
                            SelectedSkill: skillForm.SelectedSkill,
                        });
                    }
                    else {
                        console.log(some("not found"));
                    }
                }]), delay(() => {
                    let matchValue_1;
                    return append((matchValue_1 = skillForm.SelectedLevel, (matchValue_1 != null) ? singleton(["value", int32ToString(matchValue_1.Id)]) : ((empty_1()))), delay(() => singleton(["children", Interop_reactApi.Children.toArray([map((lvl_1) => MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Radio, [["style", {
                        color: "#2d3a48",
                    }]])], ["value", int32ToString(lvl_1.Id)], ["label", lvl_1.Name]]), sortByDescending((lvl) => lvl.Weight, l, {
                        Compare: (x_5, y_1) => comparePrimitives(x_5, y_1),
                    }))])])));
                })))))))))])]])]),
            }))) : loadingIndicatorSmall()));
        })))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg6) => {
        onClose(void 0);
    }]])), delay(() => ((skillForm.SelectedSkill != null) ? append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Velg annen"], ["onClick", (_arg7) => {
        updateSkillForm({
            SearchInput: skillForm.SearchInput,
            SelectedLevel: skillForm.SelectedLevel,
            SelectedSkill: void 0,
        });
    }]])), delay(() => singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", (skillForm.SelectedLevel == null) ? true : (skillForm.SelectedSkill == null)], ["children", "Legg til"], ["onClick", (_arg8) => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const matchValue = [skillForm.SelectedSkill, skillForm.SelectedLevel];
            let pattern_matching_result, sl, ss;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    pattern_matching_result = 0;
                    sl = matchValue[1];
                    ss = matchValue[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const req = new Competence_UserSkill(-1, userId, sl, ss);
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: Competence_UserSkill$reflection,
                        })(req);
                        const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: Competence_UserSkill$reflection,
                        });
                        return Fetch_tryPost_5760677E("/api/skill/user", some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                            ResolveType: Competence_UserSkill$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg2) => {
                        const res_1 = _arg2;
                        if (res_1.tag === 0) {
                            onClose(res_1.fields[0]);
                            return Promise.resolve();
                        }
                        else {
                            return Promise.resolve();
                        }
                    }));
                }
                case 1: {
                    return Promise.resolve();
                }
            }
        }));
        pr_1.then();
    }]])))) : empty_1())))))))]])])]]);
}

export function UserSkills(userSkillsInputProps) {
    const canEdit = userSkillsInputProps.canEdit;
    const userId = userSkillsInputProps.userId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const skills = patternInput[0];
    const setSkills = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setAddForm = patternInput_1[1];
    const addForm = patternInput_1[0];
    useReact_useEffectOnce_3A5B6456(() => {
        Competence_fetchUserSkills(userId, (res) => {
            if (res.tag === 0) {
                setSkills(res.fields[0]);
            }
        });
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(addForm ? singleton(createElement(UserSkillForm, {
            userId: userId,
            onClose: (x_1) => {
                if (x_1 != null) {
                    const s = x_1;
                    setSkills(Array_distinctBy((y) => y.Id, (skills != null) ? append_1([s], skills) : [s], {
                        Equals: (x_2, y_1) => (x_2 === y_1),
                        GetHashCode: (x_2) => numberHash(x_2),
                    }));
                }
                setAddForm(false);
            },
        })) : empty_1(), delay(() => {
            if (skills != null) {
                const x_3 = skills;
                return append((x_3.length === 0) ? singleton(createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                        fontStyle: "italic",
                        color: "#9a9a9a",
                    },
                    children: "Ingen ferdigheter registrert",
                })) : empty_1(), delay(() => append(singleton(map((y_4) => skillPill(y_4.Skill.Name, y_4.Level.Name, y_4.Level.ColorCode, y_4.Level.TextColorCode), sortByDescending((y_2) => [y_2.Level.Weight, y_2.Skill.Name], x_3, {
                    Compare: (x_4, y_3) => compareArrays(x_4, y_3),
                }))), delay(() => (canEdit ? singleton(createElement("div", {
                    style: {
                        margin: 5,
                    },
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Add, {})], ["onClick", (_arg2) => {
                        setAddForm(!addForm);
                    }]])]),
                })) : empty_1())))));
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        })))))),
    });
}

