import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, option_type, int32_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Waste_ProjectInfo$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { equals, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { singleton, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { WastePlan } from "../Waste/WastePlan.js";
import { WasteReg } from "../Waste/WasteRegistration.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { loadingIndicatorCenteredSmall } from "../ViewHelpers.js";

class RegForm extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Waste", "WastePlan"];
    }
}

function RegForm$reflection() {
    return union_type("WasteView.RegForm", [], RegForm, () => [[], []]);
}

class Model extends Record {
    constructor(ProjectId, ProjectInfo, SelectedForm) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.ProjectInfo = ProjectInfo;
        this.SelectedForm = SelectedForm;
    }
}

function Model$reflection() {
    return record_type("WasteView.Model", [], Model, () => [["ProjectId", int32_type], ["ProjectInfo", option_type(Waste_ProjectInfo$reflection())], ["SelectedForm", RegForm$reflection()]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InfoResponse", "SelectForm"];
    }
}

function Message$reflection() {
    return union_type("WasteView.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Waste_ProjectInfo$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Waste_ProjectInfo$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", RegForm$reflection()]]]);
}

function init(x) {
    return new Model(x, void 0, new RegForm(0));
}

function update(model, msg) {
    if (msg.tag === 1) {
        return new Model(model.ProjectId, model.ProjectInfo, msg.fields[0]);
    }
    else {
        const res = msg.fields[0];
        if (res.tag === 1) {
            return model;
        }
        else {
            return new Model(model.ProjectId, res.fields[0], model.SelectedForm);
        }
    }
}

function fetchProjInfo(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/waste/project/info/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Waste_ProjectInfo$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Waste_ProjectInfo$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function nonRegisterProject() {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 200,
            textAlign: "center",
        },
        children: Interop_reactApi.Children.toArray([createElement("h3", {
            className: "title is-3",
            children: "Dette prosjektet skal ikke registrere avfall",
        }), createElement("p", {
            children: ["Dette prosjektet er satt til å ikke registrere avfall. Dersom dette er galt kan dette endres under prosjektredigering."],
        })]),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            width: 100 + "%",
            maxWidth: 900,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tabs, [["value", equals(model.SelectedForm, new RegForm(0)) ? 0 : 1], ["textColor", "primary"], ["onChange", (_arg38, v) => {
                if (v === 0) {
                    dispatch(new Message(1, new RegForm(0)));
                }
                else {
                    dispatch(new Message(1, new RegForm(1)));
                }
            }], ["indicatorColor", "primary"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tab, [["label", "Registrering"]]), MuiHelpers_createElement(Tab, [["label", "Avfallsplan"]])])]])]),
        }), createElement("div", {
            style: {},
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((model.SelectedForm.tag === 1) ? singleton(createElement(WastePlan, {
                projectId: model.ProjectId,
            })) : singleton(createElement(WasteReg, {
                projectId: model.ProjectId,
            }))))))),
        })]),
    });
}

export function WasteView(wasteViewInputProps) {
    const projectId = wasteViewInputProps.projectId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchProjInfo(projectId, dispatch);
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = model_1.ProjectInfo;
            if (matchValue != null) {
                if (!matchValue.Register) {
                    const wasteInfo_1 = matchValue;
                    return singleton(nonRegisterProject());
                }
                else if (matchValue != null) {
                    return singleton(view(model_1, dispatch));
                }
                else {
                    throw (new Error("Match failure"));
                }
            }
            else {
                return singleton(loadingIndicatorCenteredSmall());
            }
        })))),
    });
}

