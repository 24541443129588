import { useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { User$reflection, SearchInput$reflection, SearchInput } from "./Shared/ApiDataTypes.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "./.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { obj_type, array_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { some } from "./.fable/fable-library.3.2.4/Option.js";
import { empty } from "./.fable/fable-library.3.2.4/List.js";
import { uncurry } from "./.fable/fable-library.3.2.4/Util.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { map } from "./.fable/fable-library.3.2.4/Array.js";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { printf, toText } from "./.fable/fable-library.3.2.4/String.js";

export function UserSearch(userSearchInputProps) {
    const onSelect = userSearchInputProps.onSelect;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([]);
    const updateResult = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            width: 450,
            maxWidth: 60 + "vw",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["autoFocus", true], ["onChange", (e) => {
            const x_2 = Browser_Types_Event__Event_get_Value(e);
            window.clearTimeout(patternInput[0]);
            patternInput[1](window.setTimeout((_arg1_1) => {
                if (x_2.length > 0) {
                    const x = new SearchInput(x_2, 1);
                    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: SearchInput$reflection,
                        })(x);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: () => array_type(User$reflection()),
                        });
                        return Fetch_tryPost_5760677E("/api/user/search", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: () => array_type(User$reflection()),
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg1) => {
                        const res = _arg1;
                        if (res.tag === 1) {
                            const err = res.fields[0];
                            patternInput_2[1]((err.tag === 2) ? (((err.fields[0].status) === 401) ? "Du er ikke logget inn. Vennligst last siden på nytt" : "Kunne ikke søke i brukere. Vennligst prøv igjen senere.") : "Kunne ikke søke i brukere. Vennligst prøv igjen senere.");
                            return Promise.resolve();
                        }
                        else {
                            updateResult(res.fields[0]);
                            return Promise.resolve();
                        }
                    })))));
                    pr.then();
                }
                else {
                    updateResult([]);
                }
            }, 500));
        }], ["variant", "outlined"], ["size", "small"], ["label", "Søk i brukere"], ["fullWidth", true]]), MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((x_3) => MuiHelpers_createElement(TableRow, [["onClick", (_arg2) => {
            onSelect(x_3);
        }], ["style", {
            cursor: "pointer",
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", toText(printf("%s %s"))(x_3.Firstname)(x_3.Lastname)]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", x_3.Username]]), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", x_3.Company]])])]]), patternInput_1[0])])]])])]])]),
    });
}

