import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Helpers_IdNameDesc, Helpers_IdNameDesc$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, bool_type, string_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { append, map } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { genericDeleteDialog, snackbarError, insertMaterialTableNorwegianLocalization, loadingIndicatorSmall } from "../ViewHelpers.js";
import { StyleImports_useTheme } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { MuiHelpers_createElement, MuiHelpers_reactElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import material$002Dtable from "material-table";
import Icon from "@material-ui/core/Icon";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";

class Model extends Record {
    constructor(Entries, ErrorMsg, AddForm, DelForm) {
        super();
        this.Entries = Entries;
        this.ErrorMsg = ErrorMsg;
        this.AddForm = AddForm;
        this.DelForm = DelForm;
    }
}

function Model$reflection() {
    return record_type("Interaction.Model", [], Model, () => [["Entries", option_type(array_type(Helpers_IdNameDesc$reflection()))], ["ErrorMsg", option_type(string_type)], ["AddForm", bool_type], ["DelForm", option_type(Helpers_IdNameDesc$reflection())]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EntriesResponse", "UpdateResponse", "AddResponse", "RemoveResponse", "SetError", "DismissError", "ToggleAddForm", "SetDelForm"];
    }
}

function Message$reflection() {
    return union_type("Interaction.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdNameDesc$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdNameDesc$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_IdNameDesc$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_IdNameDesc$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_IdNameDesc$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_IdNameDesc$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_IdNameDesc$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_IdNameDesc$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [], [], [["Item", option_type(Helpers_IdNameDesc$reflection())]]]);
}

function init() {
    return new Model(void 0, void 0, false, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1, matchValue_2;
    switch (msg.tag) {
        case 1: {
            const resp_1 = msg.fields[0];
            if (resp_1.tag === 1) {
                return new Model(model.Entries, "Noe gikk galt. Kunne ikke oppdatere samspill", model.AddForm, model.DelForm);
            }
            else {
                const updated = resp_1.fields[0];
                return new Model((matchValue = model.Entries, (matchValue == null) ? [updated] : map((elem) => {
                    if (elem.id === updated.id) {
                        return updated;
                    }
                    else {
                        return elem;
                    }
                }, matchValue)), model.ErrorMsg, model.AddForm, model.DelForm);
            }
        }
        case 2: {
            const resp_2 = msg.fields[0];
            if (resp_2.tag === 1) {
                return new Model(model.Entries, "Noe gikk galt. Kunne ikke legge til samspill", model.AddForm, model.DelForm);
            }
            else {
                const toAdd = resp_2.fields[0];
                return new Model((matchValue_1 = model.Entries, (matchValue_1 == null) ? [toAdd] : append([toAdd], matchValue_1)), model.ErrorMsg, false, model.DelForm);
            }
        }
        case 3: {
            const resp_3 = msg.fields[0];
            if (resp_3.tag === 1) {
                return new Model(model.Entries, "Noe gikk galt. Kunne ikke slette samspill", model.AddForm, model.DelForm);
            }
            else {
                return new Model((matchValue_2 = model.Entries, (matchValue_2 == null) ? (void 0) : matchValue_2.filter((elem_1) => (elem_1.id !== resp_3.fields[0].id))), model.ErrorMsg, model.AddForm, model.DelForm);
            }
        }
        case 4: {
            return new Model(model.Entries, msg.fields[0], model.AddForm, model.DelForm);
        }
        case 5: {
            return new Model(model.Entries, void 0, model.AddForm, model.DelForm);
        }
        case 6: {
            return new Model(model.Entries, model.ErrorMsg, !model.AddForm, model.DelForm);
        }
        case 7: {
            return new Model(model.Entries, model.ErrorMsg, model.AddForm, msg.fields[0]);
        }
        default: {
            const resp = msg.fields[0];
            if (resp.tag === 1) {
                return new Model(model.Entries, "Noe gikk galt. Kunne ikke laste samspiller", model.AddForm, model.DelForm);
            }
            else {
                return new Model(resp.fields[0], model.ErrorMsg, model.AddForm, model.DelForm);
            }
        }
    }
}

function fetchInteractions(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/interaction/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
}

function addInteraction(interact, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let item;
        if (interact.name.length > 0) {
            return ((item = (new Helpers_IdNameDesc(-1, interact.name, interact.desc)), PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Helpers_IdNameDesc$reflection,
                })(item);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_IdNameDesc$reflection,
                });
                return Fetch_tryPost_5760677E("/api/interaction/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_IdNameDesc$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })))).then(((_arg1) => {
                dispatch(new Message(2, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            dispatch(new Message(4, "Navn kan ikke være tomt"));
            return Promise.resolve();
        }
    }));
}

function updateInteraction(oldInteraction, newInteraction, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const interact = new Helpers_IdNameDesc(oldInteraction.id, newInteraction.name, newInteraction.desc);
        if (interact.name.length > 0) {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Helpers_IdNameDesc$reflection,
                })(interact);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_IdNameDesc$reflection,
                });
                return Fetch_tryPost_5760677E("/api/interaction/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_IdNameDesc$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(1, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            dispatch(new Message(4, "Navn kan ikke være tomt"));
            return Promise.resolve();
        }
    }));
}

function deleteInteraction(interact, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameDesc$reflection,
        })(interact);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameDesc$reflection,
        });
        return Fetch_tryPost_5760677E("/api/interaction/remove", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameDesc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(3, _arg1));
        return Promise.resolve();
    })))));
}

function drawMaterialTable(model, dispatch) {
    let value_23;
    const matchValue = model.Entries;
    if (matchValue == null) {
        return loadingIndicatorSmall();
    }
    else {
        const entries = matchValue;
        const theme = StyleImports_useTheme();
        return MuiHelpers_reactElement(material$002Dtable, createObj(ofArray([["style", {
            backgroundColor: theme.palette.background.paper,
            maxWidth: 1000,
            padding: 2 + "vw",
            width: 90 + "%",
        }], ["title", "Samspill"], ["data", Array.from(ofArray(entries))], ["columns", [{
            title: "Navn",
            field: "name",
        }, {
            title: "Beskrivelse",
            field: "desc",
        }]], ["editable", {
            onRowUpdate: (delegateArg0, delegateArg1) => updateInteraction(delegateArg1, delegateArg0, dispatch),
        }], ["actions", [createObj(ofArray([(value_23 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBox, {})])]]), ["icon", () => value_23]), ["tooltip", "Legg til"], ["isFreeAction", true], ["onClick", (delegateArg0_1, delegateArg1_1) => {
            dispatch(new Message(6));
        }]])), (arg) => {
            let value_31;
            return createObj(ofArray([(value_31 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutline, {})])]]), ["icon", () => value_31]), ["tooltip", "Slett"], ["onClick", (delegateArg0_2, delegateArg1_2) => {
                dispatch(new Message(7, arg));
            }]]));
        }]], insertMaterialTableNorwegianLocalization(), ["options", {
            grouping: false,
            search: true,
            pageSize: (entries.length > 5) ? 10 : 5,
        }]])));
    }
}

function addFormDialog(dispatch$0027) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const patternInput = useFeliz_React__React_useState_Static_1505(new Helpers_IdNameDesc(-1, "", ""));
        const updateState = patternInput[1];
        const state = patternInput[0];
        const innerBody = createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20 + "px",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", state.name], ["label", "Navn"], ["style", {
                marginBottom: 15,
            }], ["onChange", (e) => {
                updateState(new Helpers_IdNameDesc(state.id, Browser_Types_Event__Event_get_Value(e), state.desc));
            }]]), MuiHelpers_createElement(TextField, [["defaultValue", state.desc], ["label", "Beskrivelse"], ["style", {
                marginBottom: 15,
            }], ["onChange", (e_1) => {
                updateState(new Helpers_IdNameDesc(state.id, state.name, Browser_Types_Event__Event_get_Value(e_1)));
            }]])]),
        });
        return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
            props.dispatch(new Message(6));
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til Samspill"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([innerBody])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg2) => {
            props.dispatch(new Message(6));
        }], ["color", "primary"], ["children", "Avbryt"]]), MuiHelpers_createElement(Button, [["onClick", (_arg3) => {
            const pr = addInteraction(state, props.dispatch);
            pr.then();
        }], ["disabled", state.name.length === 0], ["color", "primary"], ["children", "Legg til"]])])]])])]]);
    }, void 0, uncurry(2, void 0), void 0, "addFormDialog", "/builds/serit/derigo/tenant/src/Client/Administration/Interaction.fs", 208)({
        dispatch: dispatch$0027,
    });
}

function view(model, dispatch) {
    return createElement("main", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: 3 + "rem",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(5));
        })), delay(() => append_1(singleton(drawMaterialTable(model, dispatch)), delay(() => append_1(model.AddForm ? singleton(addFormDialog(dispatch)) : empty_1(), delay(() => {
            let value_7;
            const matchValue = model.DelForm;
            if (matchValue == null) {
                return empty_1();
            }
            else {
                const delRow = matchValue;
                return singleton(genericDeleteDialog(() => {
                    dispatch(new Message(7, void 0));
                }, () => deleteInteraction(delRow, dispatch), [(value_7 = toText(printf("Valgt navn: %A"))(delRow.name), createElement("h3", {
                    children: [value_7],
                }))]));
            }
        })))))))))),
    });
}

export const interaction = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchInteractions(dispatch);
        pr.then();
    }, []);
    return view(patternInput[0], dispatch);
}, void 0, uncurry(2, void 0), void 0, "interaction", "/builds/serit/derigo/tenant/src/Client/Administration/Interaction.fs", 295);

