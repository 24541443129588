import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { empty as empty_1, map, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Avatar from "@material-ui/core/Avatar";
import { toConsole, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import { empty, item as item_1, length } from "../.fable/fable-library.3.2.4/List.js";
import * as imageresize from "../../imageresize.js";
import { toString } from "../.fable/fable-library.3.2.4/Types.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { UserPictureForm$reflection, UserPictureForm } from "../Shared/ApiDataTypes.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { obj_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { newGuid } from "../.fable/fable-library.3.2.4/Guid.js";

export function ProfilePicture(props) {
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const rand = patternInput[0];
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let arg20_1;
            return append(singleton(MuiHelpers_createElement(Avatar, [["style", {
                width: 280,
                height: 280,
            }], ["src", (arg20_1 = ((rand.length > 0) ? toText(printf("?r=%s"))(rand) : ""), toText(printf("/api/user/profilepicture/%i%s"))(props.uid)(arg20_1))], ["alt", "Profilbilde"]])), delay(() => (props.canEdit ? singleton(createElement("label", {
                style: {
                    marginTop: "auto",
                    marginBottom: "auto",
                    cursor: "pointer",
                    display: "block",
                    userSelect: "none",
                },
                children: Interop_reactApi.Children.toArray(["Endre profilbilde", createElement("input", {
                    type: "file",
                    accept: "image/png, image/jpeg",
                    onChange: (ev_1) => {
                        const fileList = ev_1.target.files;
                        if (!(fileList == null)) {
                            const ev = toList(delay(() => map((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                            if (length(ev) > 0) {
                                const file = item_1(0, ev);
                                const reader = new FileReader();
                                reader.onload = ((_arg1_1) => {
                                    imageresize.imgScale(toString(reader.result), 1048, 1048, (scaled) => {
                                        const filename = file.name;
                                        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                            const uploadForm = new UserPictureForm(props.uid, scaled, filename);
                                            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                                    ResolveType: UserPictureForm$reflection,
                                                })(uploadForm);
                                                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                                    ResolveType: UserPictureForm$reflection,
                                                });
                                                return Fetch_tryPost_5760677E("/api/user/uploadpicture", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                                    ResolveType: UserPictureForm$reflection,
                                                }, {
                                                    ResolveType: () => obj_type,
                                                });
                                            })).then(((_arg1) => {
                                                let copyOfStruct;
                                                const res = _arg1;
                                                if (res.tag === 1) {
                                                    toConsole(printf("error on post: exn: %A"))(res.fields[0]);
                                                    return Promise.resolve();
                                                }
                                                else {
                                                    patternInput[1]((copyOfStruct = newGuid(), copyOfStruct));
                                                    return Promise.resolve();
                                                }
                                            }));
                                        }));
                                        pr.then();
                                    });
                                });
                                reader.readAsDataURL(file);
                            }
                        }
                    },
                    style: {
                        display: "none",
                    },
                })]),
            })) : empty_1())));
        })))),
    });
}

