import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { ExternalProjectReference, ExternalProjectReference$reflection, UserReferenceProject$reflection, UserInfo$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { join, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, comparePrimitives, min, int32ToString, equals, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { toDateString, tryParseDateString } from "../Utils.js";
import { addMonths, now, month, year, compare } from "../.fable/fable-library.3.2.4/Date.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { defaultArg, value as value_130, some } from "../.fable/fable-library.3.2.4/Option.js";
import { MuiHelpers_createElement, MuiHelpers_reactElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import material$002Dtable from "material-table";
import Paper from "@material-ui/core/Paper";
import { empty as empty_1, fold, reverse, map as map_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { genericDeleteDialog, snackbarError, insertMaterialTableNorwegianLocalization, loadingIndicatorSmall } from "../ViewHelpers.js";
import Icon from "@material-ui/core/Icon";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { nb } from "date-fns/locale";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import date$002Dfns from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class Model extends Record {
    constructor(UserInfo, Internal, External, AddForm, ErrorMsg, DelForm, EditPerm, EditForm) {
        super();
        this.UserInfo = UserInfo;
        this.Internal = Internal;
        this.External = External;
        this.AddForm = AddForm;
        this.ErrorMsg = ErrorMsg;
        this.DelForm = DelForm;
        this.EditPerm = EditPerm;
        this.EditForm = EditForm;
    }
}

function Model$reflection() {
    return record_type("ProjectReference.Model", [], Model, () => [["UserInfo", UserInfo$reflection()], ["Internal", option_type(array_type(UserReferenceProject$reflection()))], ["External", option_type(array_type(ExternalProjectReference$reflection()))], ["AddForm", bool_type], ["ErrorMsg", option_type(string_type)], ["DelForm", option_type(ExternalProjectReference$reflection())], ["EditPerm", bool_type], ["EditForm", option_type(ExternalProjectReference$reflection())]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InternalProjectsResponse", "ExternalProjectsResponse", "ExternalProjectAddResponse", "ExternalProjectUpdateResponse", "DeleteExternalProjectResponse", "SetError", "SetDelForm", "SetAddForm", "SetEditForm"];
    }
}

function Message$reflection() {
    return union_type("ProjectReference.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserReferenceProject$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserReferenceProject$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ExternalProjectReference$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ExternalProjectReference$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ExternalProjectReference$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ExternalProjectReference$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ExternalProjectReference$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ExternalProjectReference$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ExternalProjectReference$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ExternalProjectReference$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(string_type)]], [["Item", option_type(ExternalProjectReference$reflection())]], [["Item", bool_type]], [["Item", option_type(ExternalProjectReference$reflection())]]]);
}

function init(userInfo, editperm) {
    return new Model(userInfo, void 0, void 0, false, void 0, void 0, editperm, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1, matchValue_2;
    switch (msg.tag) {
        case 1: {
            const resp_1 = msg.fields[0];
            if (resp_1.tag === 1) {
                return new Model(model.UserInfo, model.Internal, model.External, model.AddForm, "Noe gikk galt. Kunne ikke laste prosjektreferanser", model.DelForm, model.EditPerm, model.EditForm);
            }
            else {
                return new Model(model.UserInfo, model.Internal, resp_1.fields[0], model.AddForm, model.ErrorMsg, model.DelForm, model.EditPerm, model.EditForm);
            }
        }
        case 2: {
            const resp_2 = msg.fields[0];
            if (resp_2.tag === 1) {
                return new Model(model.UserInfo, model.Internal, model.External, model.AddForm, "Noe gikk galt. Kunne ikke legge til prosjektreferanse", model.DelForm, model.EditPerm, model.EditForm);
            }
            else {
                const newEntry = resp_2.fields[0];
                return new Model(model.UserInfo, model.Internal, (matchValue = model.External, (matchValue == null) ? [newEntry] : append([newEntry], matchValue)), false, model.ErrorMsg, model.DelForm, model.EditPerm, model.EditForm);
            }
        }
        case 3: {
            const resp_3 = msg.fields[0];
            if (resp_3.tag === 1) {
                return new Model(model.UserInfo, model.Internal, model.External, model.AddForm, "Noe gikk galt. Kunne ikke oppdatere prosjektreferanse", model.DelForm, model.EditPerm, model.EditForm);
            }
            else {
                const updatedEntry = resp_3.fields[0];
                return new Model(model.UserInfo, model.Internal, (matchValue_1 = model.External, (matchValue_1 == null) ? [updatedEntry] : map((e) => {
                    if (e.Id === updatedEntry.Id) {
                        return updatedEntry;
                    }
                    else {
                        return e;
                    }
                }, matchValue_1)), model.AddForm, model.ErrorMsg, model.DelForm, model.EditPerm, void 0);
            }
        }
        case 4: {
            const resp_4 = msg.fields[0];
            if (resp_4.tag === 1) {
                return new Model(model.UserInfo, model.Internal, model.External, model.AddForm, "Noe gikk galt. Kunne ikke slette prosjektreferanse", model.DelForm, model.EditPerm, model.EditForm);
            }
            else {
                return new Model(model.UserInfo, model.Internal, (matchValue_2 = model.External, (matchValue_2 == null) ? (void 0) : matchValue_2.filter((e_1) => (e_1.Id !== resp_4.fields[0].Id))), model.AddForm, model.ErrorMsg, void 0, model.EditPerm, model.EditForm);
            }
        }
        case 6: {
            return new Model(model.UserInfo, model.Internal, model.External, model.AddForm, model.ErrorMsg, msg.fields[0], model.EditPerm, model.EditForm);
        }
        case 5: {
            return new Model(model.UserInfo, model.Internal, model.External, model.AddForm, msg.fields[0], model.DelForm, model.EditPerm, model.EditForm);
        }
        case 7: {
            return new Model(model.UserInfo, model.Internal, model.External, msg.fields[0], model.ErrorMsg, model.DelForm, model.EditPerm, model.EditForm);
        }
        case 8: {
            return new Model(model.UserInfo, model.Internal, model.External, model.AddForm, model.ErrorMsg, model.DelForm, model.EditPerm, msg.fields[0]);
        }
        default: {
            const resp = msg.fields[0];
            if (resp.tag === 1) {
                return new Model(model.UserInfo, model.Internal, model.External, model.AddForm, "Noe gikk galt. Kunne ikke laste referanseprosjekt", model.DelForm, model.EditPerm, model.EditForm);
            }
            else {
                return new Model(model.UserInfo, resp.fields[0], model.External, model.AddForm, model.ErrorMsg, model.DelForm, model.EditPerm, model.EditForm);
            }
        }
    }
}

function fetchExternalRefs(dispatch, userId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/mypage/externalreferences/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ExternalProjectReference$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ExternalProjectReference$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchInternalRefs(dispatch, userId) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/mypage/referenceprojects/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserReferenceProject$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserReferenceProject$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
}

function updateExternalRefs(oldRef, newRef, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let validDates;
        const matchValue = tryParseDateString(newRef.PeriodStart);
        if (matchValue != null) {
            const startdt = matchValue;
            const matchValue_1 = tryParseDateString(newRef.PeriodEnd);
            validDates = ((matchValue_1 != null) ? (compare(startdt, matchValue_1) <= 0) : false);
        }
        else {
            validDates = false;
        }
        if (equals(oldRef, newRef)) {
            dispatch(new Message(8, void 0));
            return Promise.resolve();
        }
        else if (!validDates) {
            dispatch(new Message(5, "Ugyldige datoer. Pass på at start dato er før start dato."));
            return Promise.resolve();
        }
        else if (newRef.Project.length < 0) {
            dispatch(new Message(5, "Prosjekt kan ikke være tom"));
            return Promise.resolve();
        }
        else {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ExternalProjectReference$reflection,
                })(newRef);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: ExternalProjectReference$reflection,
                });
                return Fetch_tryPost_5760677E("/api/mypage/externalreference/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: ExternalProjectReference$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(3, _arg1));
                return Promise.resolve();
            }));
        }
    }));
}

function addExternalRef(newRef, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let matchValue, startdt, matchValue_1;
        if (!((matchValue = tryParseDateString(newRef.PeriodStart), (matchValue != null) ? ((startdt = matchValue, (matchValue_1 = tryParseDateString(newRef.PeriodEnd), (matchValue_1 != null) ? (compare(startdt, matchValue_1) <= 0) : false))) : false))) {
            dispatch(new Message(5, "Ugyldige datoer. Pass på at start dato er før start dato."));
            return Promise.resolve();
        }
        else if (newRef.Project.length < 0) {
            dispatch(new Message(5, "Prosjekt kan ikke være tom"));
            return Promise.resolve();
        }
        else {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ExternalProjectReference$reflection,
                })(newRef);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: ExternalProjectReference$reflection,
                });
                return Fetch_tryPost_5760677E("/api/mypage/externalreference/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: ExternalProjectReference$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(2, _arg1));
                return Promise.resolve();
            }));
        }
    }));
}

function deleteExternalRef(oldRef, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ExternalProjectReference$reflection,
        })(oldRef);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ExternalProjectReference$reflection,
        });
        return Fetch_tryPost_5760677E("/api/mypage/externalreference/remove", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ExternalProjectReference$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(4, _arg1));
        return Promise.resolve();
    })))));
}

function drawMaterialTableInternal(references, dispatch) {
    if (references != null) {
        const refs = references;
        return MuiHelpers_reactElement(material$002Dtable, {
            components: {
                Container: (properties) => MuiHelpers_createElement(Paper, toList(delay(() => append_1(Object.entries(properties), delay(() => singleton(["elevation", 0])))))),
            },
            style: {
                maxWidth: 1000,
                width: 100 + "%",
                marginBottom: 2 + "em",
            },
            title: "Interne referanser",
            data: Array.from(ofArray(refs)),
            columns: [{
                title: "Projekt",
                field: "ProjectName",
            }, {
                title: "Rolle",
                field: "Role",
            }, {
                title: "Periode (Mnd/År)",
                render: (delegateArg0, delegateArg1) => {
                    const ref_2 = delegateArg0;
                    let startStr;
                    const arg20 = year(ref_2.PeriodStart) | 0;
                    const arg10 = month(ref_2.PeriodStart) | 0;
                    startStr = toText(printf("%d/%d"))(arg10)(arg20);
                    let endStr;
                    const arg20_1 = year(ref_2.PeriodEnd) | 0;
                    const arg10_1 = month(ref_2.PeriodEnd) | 0;
                    endStr = toText(printf("%d/%d"))(arg10_1)(arg20_1);
                    return toText(printf("%s - %s"))(startStr)(endStr);
                },
                customSort: (delegateArg0_1, delegateArg1_1, delegateArg2) => compare(delegateArg0_1.PeriodStart, delegateArg1_1.PeriodStart),
            }],
            detailPanel: [{
                tooltip: "Vis detaljer",
                render: (ref_3) => createElement("div", {
                    style: {
                        marginTop: 1 + "em",
                        marginBottom: 1 + "em",
                        width: 90 + "%",
                        marginLeft: 5 + "%",
                        marginRight: 5 + "%",
                    },
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("b", {
                        children: ["Kontrakt sum:"],
                    })])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => singleton(join("", map_1((value_51) => value_51, reverse(fold((s, c) => {
                        if (s[0] === 2) {
                            return [0, (s[1] + c) + " "];
                        }
                        else {
                            return [s[0] + 1, s[1] + c];
                        }
                    }, [0, ""], reverse(int32ToString(ref_3.ContractSum).split("")))[1].split("")))))))))]])])]]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("b", {
                        children: ["Byggherre:"],
                    })])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([ref_3.Builder])]])])]]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("b", {
                        children: ["Beskrivelse:"],
                    })])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([ref_3.Desc])]])])]])])]])])]])]),
                }),
            }],
            onRowClick: (delegateArg0_2, delegateArg1_2, delegateArg2_1) => {
                delegateArg2_1();
            },
            localization: {
                body: {
                    emptyDataSourceMessage: "Ingen data tilgjengelig.",
                },
                toolbar: {
                    searchTooltip: "Søk",
                    searchPlaceholder: "Søk",
                    addRemoveColumns: "Rediger",
                },
                pagination: {
                    labelRowsSelect: "Rader",
                    labelDisplayedRows: " {from}-{to} av {count}",
                    firstTooltip: "Første side",
                    previousTooltip: "Forrige side",
                    nextTooltip: "Neste side",
                    lastTooltip: "Siste side",
                },
            },
            options: {
                grouping: false,
                search: false,
                paging: true,
                pageSize: min((x, y) => comparePrimitives(x, y), refs.length, 5),
            },
        });
    }
    else {
        return loadingIndicatorSmall();
    }
}

export function formatDateStrings(startDate, endDate) {
    const startDateTime = tryParseDateString(startDate);
    const endDateTime = tryParseDateString(endDate);
    if (startDateTime != null) {
        const s = startDateTime;
        let startStr;
        const arg20 = year(s) | 0;
        const arg10 = month(s) | 0;
        startStr = toText(printf("%d/%d"))(arg10)(arg20);
        if (endDateTime != null) {
            const e = endDateTime;
            let endStr;
            const arg20_1 = year(e) | 0;
            const arg10_2 = month(e) | 0;
            endStr = toText(printf("%d/%d"))(arg10_2)(arg20_1);
            return toText(printf("%s - %s"))(startStr)(endStr);
        }
        else {
            return toText(printf("%s -"))(startStr);
        }
    }
    else {
        return "";
    }
}

function drawMaterialTableExternal(editPerm, references, dispatch) {
    if (references != null) {
        const refs = references;
        return MuiHelpers_reactElement(material$002Dtable, createObj(toList(delay(() => append_1(singleton(["components", {
            Container: (properties) => MuiHelpers_createElement(Paper, toList(delay(() => append_1(Object.entries(properties), delay(() => singleton(["elevation", 0])))))),
        }]), delay(() => append_1(singleton(["style", {
            maxWidth: 1000,
            width: 100 + "%",
            marginBottom: 2 + "em",
        }]), delay(() => append_1(singleton(["title", "Externe referanser"]), delay(() => append_1(singleton(["data", Array.from(ofArray(refs))]), delay(() => append_1(singleton(["columns", [{
            title: "Projekt",
            field: "Project",
        }, {
            title: "Rolle",
            field: "ExternalRole",
        }, {
            title: "Periode (Mnd/År)",
            render: (delegateArg0, delegateArg1) => {
                const ref_2 = delegateArg0;
                return formatDateStrings(ref_2.PeriodStart, ref_2.PeriodEnd);
            },
            customSort: (delegateArg0_1, delegateArg1_1, delegateArg2) => {
                const date1 = tryParseDateString(delegateArg0_1.PeriodStart);
                const date2 = tryParseDateString(delegateArg1_1.PeriodStart);
                return (((date1 != null) ? (date2 != null) : false) ? compare(value_130(date1), value_130(date2)) : 0) | 0;
            },
        }]]), delay(() => append_1(singleton(["detailPanel", [{
            tooltip: "Vis detaljer",
            render: (ref_3) => createElement("div", {
                style: {
                    marginTop: 1 + "em",
                    marginBottom: 1 + "em",
                    width: 90 + "%",
                    marginLeft: 5 + "%",
                    marginRight: 5 + "%",
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("b", {
                    children: ["Selskap:"],
                })])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([ref_3.ExternalCompany])]])])]]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("b", {
                    children: ["Byggherre:"],
                })])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([ref_3.Builder])]])])]]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("b", {
                    children: ["Kontaktperson:"],
                })])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([ref_3.ContactPerson])]])])]]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("b", {
                    children: ["Beskrivelse:"],
                })])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([ref_3.Desc])]])])]])])]])])]])]),
            }),
        }]]), delay(() => append_1(singleton(["onRowClick", (delegateArg0_2, delegateArg1_2, delegateArg2_1) => {
            delegateArg2_1();
        }]), delay(() => {
            let value_74;
            return append_1(editPerm ? singleton(["actions", [createObj(ofArray([(value_74 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBox, {})])]]), ["icon", () => value_74]), ["tooltip", "Legg til"], ["isFreeAction", true], ["onClick", (delegateArg0_3, delegateArg1_3) => {
                dispatch(new Message(7, true));
            }]])), (arg) => {
                let value_82;
                return createObj(ofArray([(value_82 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutline, {})])]]), ["icon", () => value_82]), ["tooltip", "Slett"], ["onClick", (delegateArg0_4, delegateArg1_4) => {
                    dispatch(new Message(6, arg));
                }]]));
            }, (arg_1) => {
                let value_88;
                return createObj(ofArray([(value_88 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Edit, {})])]]), ["icon", () => value_88]), ["tooltip", "Rediger"], ["onClick", (delegateArg0_5, delegateArg1_5) => {
                    dispatch(new Message(8, arg_1));
                }]]));
            }]]) : empty_1(), delay(() => append_1(singleton(insertMaterialTableNorwegianLocalization()), delay(() => singleton(["options", {
                grouping: false,
                search: false,
                paging: true,
                pageSize: min((x, y) => comparePrimitives(x, y), refs.length, 5),
                defaultExpanded: true,
            }])))));
        }))))))))))))))))));
    }
    else {
        return loadingIndicatorSmall();
    }
}

function FormDialog(props) {
    let props_15, elms, elms_1, elms_2, elms_3, props_13;
    let startRef;
    const matchValue = props.initState;
    startRef = ((matchValue == null) ? (new ExternalProjectReference(-1, props.userId, "", "", "", "", "", "", "", "")) : matchValue);
    const patternInput = useFeliz_React__React_useState_Static_1505(startRef);
    const updateState = patternInput[1];
    const state = patternInput[0];
    let initStartDate;
    const matchValue_1 = tryParseDateString(startRef.PeriodStart);
    if (matchValue_1 == null) {
        let copyOfStruct = now();
        initStartDate = addMonths(copyOfStruct, -4);
    }
    else {
        initStartDate = matchValue_1;
    }
    let initEndDate;
    const matchValue_2 = tryParseDateString(startRef.PeriodEnd);
    if (matchValue_2 == null) {
        let copyOfStruct_1 = now();
        initEndDate = addMonths(copyOfStruct_1, -2);
    }
    else {
        initEndDate = matchValue_2;
    }
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(initStartDate);
    const startDate = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(initEndDate);
    const endDate = patternInput_2[0];
    let innerBody;
    const props_17 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-vcentered"], ["style", {
        flexDirection: "column",
        justifyContent: "space-between",
        marginBottom: 20 + "px",
    }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(MuiHelpers_createElement(TextField, [["defaultValue", state.Project], ["label", "Prosjekt"], ["style", {
        margin: 5,
    }], ["onChange", (e) => {
        updateState(new ExternalProjectReference(state.Id, state.UserId, state.ExternalRole, state.ExternalCompany, Browser_Types_Event__Event_get_Value(e), state.Desc, state.Builder, state.ContactPerson, state.PeriodStart, state.PeriodEnd));
    }], ["required", true]])), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = ofArray([MuiHelpers_createElement(TextField, [["defaultValue", state.ExternalRole], ["label", "Rolle"], ["style", {
        margin: 5,
    }], ["onChange", (e_1) => {
        updateState(new ExternalProjectReference(state.Id, state.UserId, Browser_Types_Event__Event_get_Value(e_1), state.ExternalCompany, state.Project, state.Desc, state.Builder, state.ContactPerson, state.PeriodStart, state.PeriodEnd));
    }]]), MuiHelpers_createElement(TextField, [["defaultValue", state.ExternalCompany], ["label", "Selskap"], ["style", {
        margin: 5,
    }], ["onChange", (e_2) => {
        updateState(new ExternalProjectReference(state.Id, state.UserId, state.ExternalRole, Browser_Types_Event__Event_get_Value(e_2), state.Project, state.Desc, state.Builder, state.ContactPerson, state.PeriodStart, state.PeriodEnd));
    }]])]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_2 = ofArray([MuiHelpers_createElement(TextField, [["defaultValue", state.Builder], ["label", "Byggeherre"], ["style", {
        margin: 5,
    }], ["onChange", (e_3) => {
        updateState(new ExternalProjectReference(state.Id, state.UserId, state.ExternalRole, state.ExternalCompany, state.Project, state.Desc, Browser_Types_Event__Event_get_Value(e_3), state.ContactPerson, state.PeriodStart, state.PeriodEnd));
    }]]), MuiHelpers_createElement(TextField, [["defaultValue", state.ContactPerson], ["label", "Kontaktperson"], ["style", {
        margin: 5,
    }], ["onChange", (e_4) => {
        updateState(new ExternalProjectReference(state.Id, state.UserId, state.ExternalRole, state.ExternalCompany, state.Project, state.Desc, state.Builder, Browser_Types_Event__Event_get_Value(e_4), state.PeriodStart, state.PeriodEnd));
    }]])]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (elms_3 = ofArray([MuiHelpers_createElement(DatePicker, [["label", "Start Dato"], ["autoOk", true], ["value", defaultArg(startDate, null)], ["onChange", patternInput_1[1]], ["views", ["year", "month"]], ["variant", "inline"], ["format", "MMMM yyyy"], ["style", {
        margin: 5,
    }]]), MuiHelpers_createElement(DatePicker, [["label", "Slutt Dato"], ["value", defaultArg(endDate, null)], ["autoOk", true], ["variant", "inline"], ["views", ["year", "month"]], ["onChange", patternInput_2[1]], ["format", "MMMM yyyy"], ["style", {
        margin: 5,
    }]])]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (props_13 = ofArray([["className", "is-full"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", state.Desc], ["label", "Beskrivelse"], ["multiline", true], ["fullWidth", true], ["style", {
        margin: 5,
    }], ["onChange", (e_5) => {
        updateState(new ExternalProjectReference(state.Id, state.UserId, state.ExternalRole, state.ExternalCompany, state.Project, Browser_Types_Event__Event_get_Value(e_5), state.Builder, state.ContactPerson, state.PeriodStart, state.PeriodEnd));
    }]])])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_15))))])]]);
    const el = MuiPickersUtilsProvider;
    innerBody = MuiHelpers_createElement(el, toList(delay(() => {
        let value_111;
        return append_1(singleton((value_111 = date$002Dfns, ["utils", value_111])), delay(() => props_17));
    })));
    const onclose = (props.initState == null) ? (new Message(7, false)) : (new Message(8, void 0));
    const confirmFunc = (props.initState == null) ? ((newRef_1) => ((dispatch_1) => addExternalRef(newRef_1, dispatch_1))) : ((newRef) => ((dispatch) => updateExternalRefs(startRef, newRef, dispatch)));
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (delegateArg0, delegateArg1) => {
        props.dispatch(onclose);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", (props.initState == null) ? "Legg til Referanse" : "Rediger referanse"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([innerBody])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg3) => {
        props.dispatch(onclose);
    }], ["color", "primary"], ["children", "Avbryt"]]), MuiHelpers_createElement(Button, [["onClick", (_arg4) => {
        const matchValue_6 = [startDate, endDate];
        let pattern_matching_result, end$0027, start;
        if (matchValue_6[0] != null) {
            if (matchValue_6[1] != null) {
                pattern_matching_result = 0;
                end$0027 = matchValue_6[1];
                start = matchValue_6[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const pr = confirmFunc(new ExternalProjectReference(state.Id, state.UserId, state.ExternalRole, state.ExternalCompany, state.Project, state.Desc, state.Builder, state.ContactPerson, toDateString(start), toDateString(end$0027)))(props.dispatch);
                pr.then();
                break;
            }
            case 1: {
                break;
            }
        }
    }], ["disabled", state.Project.length === 0], ["color", "primary"], ["children", "Bekreft"]])])]])])]]);
}

function view(model, dispatch) {
    return createElement("main", {
        style: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignContent: "center",
            marginTop: 3 + "rem",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(5, void 0));
        })), delay(() => append_1(singleton(drawMaterialTableInternal(model.Internal, dispatch)), delay(() => append_1(singleton(drawMaterialTableExternal(model.EditPerm, model.External, dispatch)), delay(() => append_1(model.AddForm ? singleton(createElement(FormDialog, {
            dispatch: dispatch,
            initState: void 0,
            userId: model.UserInfo.Id,
        })) : empty_1(), delay(() => {
            let matchValue;
            return append_1((matchValue = model.EditForm, (matchValue != null) ? singleton(createElement(FormDialog, {
                dispatch: dispatch,
                initState: matchValue,
                userId: model.UserInfo.Id,
            })) : ((empty_1()))), delay(() => {
                let value_8;
                const matchValue_1 = model.DelForm;
                if (matchValue_1 == null) {
                    return empty_1();
                }
                else {
                    const delRow = matchValue_1;
                    return singleton(genericDeleteDialog(() => {
                        dispatch(new Message(6, void 0));
                    }, () => deleteExternalRef(delRow, dispatch), [(value_8 = toText(printf("Valgt prosjekt: %A"))(delRow.Project), createElement("h3", {
                        children: [value_8],
                    }))]));
                }
            }));
        })))))))))))),
    });
}

export function ProjectReferences(props) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.userInfo, props.editPerm));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = fetchExternalRefs(dispatch, props.userInfo.Id);
        pr.then();
        const pr_1 = fetchInternalRefs(dispatch, props.userInfo.Id);
        pr_1.then();
    });
    return view(patternInput[0], dispatch);
}

