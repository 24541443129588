import { Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, lambda_type, unit_type, option_type, bool_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { UserSearchResult$reflection, SearchInput$reflection, SearchInput, Quality_ProjectParticipant, Quality_ProjectParticipant$reflection } from "../Shared/ApiDataTypes.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, singleton, ofArray } from "../.fable/fable-library.3.2.4/List.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { empty, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { createElement } from "react";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { tryFocus } from "../Utils.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { map } from "../.fable/fable-library.3.2.4/Array.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export class ParticipantFormProps extends Record {
    constructor(projId, external, onClose) {
        super();
        this.projId = (projId | 0);
        this.external = external;
        this.onClose = onClose;
    }
}

export function ParticipantFormProps$reflection() {
    return record_type("QualityParticipant.ParticipantFormProps", [], ParticipantFormProps, () => [["projId", int32_type], ["external", bool_type], ["onClose", lambda_type(option_type(Quality_ProjectParticipant$reflection()), unit_type)]]);
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    const txtField = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginTop", 10], ["marginBottom", 10]]));
    const searchRoot = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["padding", 10], ["minWidth", 500], ["minHeight", 150]]));
    const root = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["padding", 10], ["minWidth", 500]]));
    o = {
        roleSelect: StyleCreator$1__create_4EAC9E1E(styles, singleton(["marginBottom", 20])),
        root: root,
        searchRoot: searchRoot,
        txtField: txtField,
    };
    return Object.assign({}, o);
});

export const participantForm = FunctionComponent_Of_Z5A158BBF((props) => {
    const s = useStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505(new Quality_ProjectParticipant(-1, "", "", (!props.external) ? "Prosjektleder" : "", props.external, void 0, void 0, props.external ? "Byggherre" : ""));
    const updateParticipant = patternInput[1];
    const participant = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    const errMsg = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505([]);
    const setSearchResult = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const selectedUser = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(1);
    const internalRole = patternInput_5[0] | 0;
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        props.onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", props.external ? "Legg til ekstern deltager" : "Legg til deltager fra Derigo"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let u_1;
        return append(props.external ? singleton_1(createElement("div", {
            className: s.root,
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["id", "participant-name"], ["className", s.txtField], ["autoFocus", true], ["fullWidth", true], ["onChange", (e) => {
                updateParticipant(new Quality_ProjectParticipant(participant.Id, Browser_Types_Event__Event_get_Value(e), participant.Email, participant.Role, participant.External, participant.UserId, participant.Username, participant.Company));
            }], ["label", "Navn"], ["variant", "outlined"], ["size", "small"], ["defaultValue", participant.Name], ["onKeyDown", (ke) => {
                if (ke.key === "Enter") {
                    tryFocus("participant-email");
                }
            }]]), MuiHelpers_createElement(TextField, [["id", "participant-email"], ["className", s.txtField], ["fullWidth", true], ["onChange", (e_1) => {
                updateParticipant(new Quality_ProjectParticipant(participant.Id, participant.Name, Browser_Types_Event__Event_get_Value(e_1), participant.Role, participant.External, participant.UserId, participant.Username, participant.Company));
            }], ["label", "E-post"], ["variant", "outlined"], ["size", "small"], ["defaultValue", participant.Email], ["type", "email"], ["helperText", "Kvalitetskvittering sendes til denne e-post adressen"], ["onKeyDown", (ke_1) => {
                if (ke_1.key === "Enter") {
                    tryFocus("participant-role");
                }
            }]]), MuiHelpers_createElement(TextField, [["id", "participant-role"], ["className", s.txtField], ["fullWidth", true], ["onChange", (e_2) => {
                updateParticipant(new Quality_ProjectParticipant(participant.Id, participant.Name, participant.Email, Browser_Types_Event__Event_get_Value(e_2), participant.External, participant.UserId, participant.Username, participant.Company));
            }], ["label", "Rolle"], ["variant", "outlined"], ["size", "small"], ["defaultValue", participant.Role]])]),
        })) : ((selectedUser != null) ? singleton_1((u_1 = selectedUser, createElement("div", {
            className: s.root,
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["id", "participant-name"], ["className", s.txtField], ["autoFocus", true], ["fullWidth", true], ["disabled", true], ["onChange", (e_4) => {
                updateParticipant(new Quality_ProjectParticipant(participant.Id, Browser_Types_Event__Event_get_Value(e_4), participant.Email, participant.Role, participant.External, participant.UserId, participant.Username, participant.Company));
            }], ["label", "Navn"], ["variant", "outlined"], ["size", "small"], ["value", participant.Name]]), MuiHelpers_createElement(TextField, [["id", "participant-email"], ["className", s.txtField], ["fullWidth", true], ["disabled", true], ["onChange", (e_5) => {
                updateParticipant(new Quality_ProjectParticipant(participant.Id, participant.Name, Browser_Types_Event__Event_get_Value(e_5), participant.Role, participant.External, participant.UserId, participant.Username, participant.Company));
            }], ["label", "E-post"], ["variant", "outlined"], ["size", "small"], ["value", participant.Email], ["type", "email"], ["helperText", "Kvalitetskvittering sendes til denne e-post adressen"]]), createElement("div", {
                style: {},
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(FormControl, [["fullWidth", true], ["variant", "outlined"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["id", "role-select-label"], ["children", "Rolle"]]), MuiHelpers_createElement(Select, [["value", internalRole], ["labelId", "role-select-label"], ["fullWidth", true], ["label", "Rolle"], ["className", s.roleSelect], ["onChange", (e_6, _arg24) => {
                    const r = Browser_Types_Event__Event_get_Value(e_6);
                    if (r !== 4) {
                        updateParticipant(new Quality_ProjectParticipant(participant.Id, participant.Name, participant.Email, (r === 1) ? "Prosjektleder" : ((r === 2) ? "Prosjektsjef" : "Daglig leder"), participant.External, participant.UserId, participant.Username, participant.Company));
                    }
                    patternInput_5[1](r);
                }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["value", 1], ["children", "Prosjektleder"]]), MuiHelpers_createElement(MenuItem, [["value", 2], ["children", "Prosjektsjef"]]), MuiHelpers_createElement(MenuItem, [["value", 3], ["children", "Daglig leder"]]), MuiHelpers_createElement(MenuItem, [["value", 4], ["children", "Fritekst"]])])]])])]])), delay(() => ((internalRole === 4) ? singleton_1(MuiHelpers_createElement(TextField, [["id", "participant-role"], ["className", s.txtField], ["autoFocus", true], ["fullWidth", true], ["onChange", (e_7) => {
                    updateParticipant(new Quality_ProjectParticipant(participant.Id, participant.Name, participant.Email, Browser_Types_Event__Event_get_Value(e_7), participant.External, participant.UserId, participant.Username, participant.Company));
                }], ["label", "Rolle fritekst"], ["variant", "outlined"], ["size", "small"], ["defaultValue", participant.Role]])) : empty()))))))),
            })]),
        }))) : singleton_1(createElement("div", {
            className: s.searchRoot,
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["id", "participant-search"], ["className", s.txtField], ["autoFocus", true], ["fullWidth", true], ["size", "small"], ["variant", "outlined"], ["label", "Brukersøk"], ["onChange", (e_3) => {
                const x_5 = Browser_Types_Event__Event_get_Value(e_3);
                window.clearTimeout(patternInput_3[0]);
                patternInput_3[1](window.setTimeout((_arg1_1) => {
                    if (x_5.length > 0) {
                        const req = new SearchInput(x_5, 1);
                        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: SearchInput$reflection,
                            })(req);
                            const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: UserSearchResult$reflection,
                            });
                            return Fetch_tryPost_5760677E("/api/search/users", some(body_1), empty_1(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                                ResolveType: UserSearchResult$reflection,
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg2) => {
                            const res_1 = _arg2;
                            if (res_1.tag === 0) {
                                setSearchResult(res_1.fields[0].Users);
                                return Promise.resolve();
                            }
                            else {
                                setErrMsg("Kunne ikke gjennomføre brukersøk");
                                return Promise.resolve();
                            }
                        })))));
                        pr_1.then();
                    }
                    else {
                        setSearchResult([]);
                    }
                }, 500));
            }]]), createElement("div", {
                style: {},
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableContainer, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["variant", "head"], ["children", "Navn"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["children", "Selskap"]]), MuiHelpers_createElement(TableCell, [["variant", "head"], ["children", "Kontor"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((u) => MuiHelpers_createElement(TableRow, [["onClick", (_arg2_1) => {
                    updateParticipant(new Quality_ProjectParticipant(participant.Id, toText(printf("%s %s"))(u.Firstname)(u.Lastname), u.Email, participant.Role, participant.External, u.Id, u.Username, u.Company));
                    patternInput_4[1](u);
                }], ["style", {
                    cursor: "pointer",
                }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", toText(printf("%s %s"))(u.Firstname)(u.Lastname)]]), MuiHelpers_createElement(TableCell, [["children", u.Company]]), MuiHelpers_createElement(TableCell, [["children", u.Office]])])]]), patternInput_2[0])])]])])]])])]])]),
            })]),
        }))), delay(() => {
            if (errMsg != null) {
                return singleton_1(MuiHelpers_createElement(Alert, [["severity", "error"], ["variant", "outlined"], ["onClose", (_arg4) => {
                    setErrMsg(void 0);
                }], ["children", errMsg]]));
            }
            else {
                return empty();
            }
        }));
    }))))]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg5) => {
        props.onClose(void 0);
    }]])), delay(() => ((props.external ? true : (selectedUser != null)) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Legg til"], ["onClick", (_arg6) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (participant.Name.length === 0) {
                setErrMsg("Ugyldig navn");
                return Promise.resolve();
            }
            else if (participant.Role.length === 0) {
                setErrMsg("Ugyldig rolle");
                return Promise.resolve();
            }
            else {
                const requestPath = toText(printf("/api/quality/project/participant/%i"))(props.projId);
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                        ResolveType: Quality_ProjectParticipant$reflection,
                    })(participant);
                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: Quality_ProjectParticipant$reflection,
                    });
                    return Fetch_tryPost_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                        ResolveType: Quality_ProjectParticipant$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg1) => {
                    const res = _arg1;
                    if (res.tag === 1) {
                        setErrMsg("Noe gikk galt. Kunne ikke legge til deltager.");
                        return Promise.resolve();
                    }
                    else {
                        props.onClose(res.fields[0]);
                        return Promise.resolve();
                    }
                }));
            }
        }));
        pr.then();
    }]])) : empty())))))))]])])]]);
}, void 0, uncurry(2, void 0), void 0, "participantForm", "/builds/serit/derigo/tenant/src/Client/Quality/QualityParticipant.fs", 34);

