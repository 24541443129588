import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { tuple_type, obj_type, bool_type, array_type, int32_type, record_type, option_type, union_type, anonRecord_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ProcessModule_Tag, Helpers_ResultMessage$reflection, ProcessModule_ProcessActivityDoc, ProcessModule_Tag$reflection, ProcessModule_PreviewState$reflection, ProcessModule_DocVersion$reflection, ProcessModule_Process$reflection, ProcessModule_ProcessActivityDoc$reflection, ProcessModule_DocRevision$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { sortByDescending, sortBy, map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, comparePrimitives, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { loadingIndicatorMedium, snackbarError, loadingIndicatorSmall, PageElements_filenameToIcon } from "../ViewHelpers.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Alert from "@material-ui/lab/Alert";
import Icon from "@material-ui/core/Icon";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import Paper from "@material-ui/core/Paper";
import { hasPermission } from "../LoginState.js";
import { Functionality } from "../Shared/PermissionMapping.js";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import { Form } from "./RevisionForm.js";
import { fromUtcToLocalDate } from "../Utils.js";
import Chip from "@material-ui/core/Chip";
import LocalOffer from "@material-ui/icons/LocalOffer";
import { TagForm as TagForm_1 } from "./DocumentTagForm.js";

export class Preview extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Loading", "NotAvailable", "Pdf", "Image"];
    }
}

export function Preview$reflection() {
    return union_type("DocumentView.Preview", [], Preview, () => [[], [], [["Item", anonRecord_type(["Content", string_type])]], [["Item", string_type]]]);
}

export class PreviousPreview extends Record {
    constructor(Revision, Url) {
        super();
        this.Revision = Revision;
        this.Url = Url;
    }
}

export function PreviousPreview$reflection() {
    return record_type("DocumentView.PreviousPreview", [], PreviousPreview, () => [["Revision", ProcessModule_DocRevision$reflection()], ["Url", option_type(string_type)]]);
}

class Model extends Record {
    constructor(DocumentId, Document$, Related, Preview, ErrorMsg, Versions, SelectedPrevious, TagForm, Revision) {
        super();
        this.DocumentId = (DocumentId | 0);
        this.Document = Document$;
        this.Related = Related;
        this.Preview = Preview;
        this.ErrorMsg = ErrorMsg;
        this.Versions = Versions;
        this.SelectedPrevious = SelectedPrevious;
        this.TagForm = TagForm;
        this.Revision = Revision;
    }
}

function Model$reflection() {
    return record_type("DocumentView.Model", [], Model, () => [["DocumentId", int32_type], ["Document", option_type(ProcessModule_ProcessActivityDoc$reflection())], ["Related", option_type(ProcessModule_Process$reflection())], ["Preview", Preview$reflection()], ["ErrorMsg", option_type(string_type)], ["Versions", option_type(array_type(ProcessModule_DocVersion$reflection()))], ["SelectedPrevious", option_type(PreviousPreview$reflection())], ["TagForm", bool_type], ["Revision", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DocumentResponse", "PreviewResponse", "OldPreviewResponse", "RelatedResponse", "ToggleTagForm", "ToggleRevision", "AddTag", "RemoveTag", "VersionsResponse", "SelectPrevious", "SetError", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("DocumentView.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ProcessModule_ProcessActivityDoc$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ProcessModule_ProcessActivityDoc$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ProcessModule_PreviewState$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ProcessModule_PreviewState$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ProcessModule_Process$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", ProcessModule_Process$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", bool_type]], [["Item", ProcessModule_Tag$reflection()]], [["Item", int32_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProcessModule_DocVersion$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProcessModule_DocVersion$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", ProcessModule_DocRevision$reflection()]], [["Item", string_type]], []]);
}

function init(x) {
    return new Model(x, void 0, void 0, new Preview(0), void 0, void 0, void 0, false, false);
}

function update(model, msg) {
    let matchValue_2, doc, matchValue_3, doc_1;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.DocumentId, model.Document, model.Related, new Preview(1), model.ErrorMsg, model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
            }
            else {
                const x_1 = res_1.fields[0];
                if (!x_1.Applicable) {
                    return new Model(model.DocumentId, model.Document, model.Related, new Preview(1), model.ErrorMsg, model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
                }
                else {
                    const matchValue = x_1.FileExt;
                    switch (matchValue) {
                        case "pdf": {
                            return new Model(model.DocumentId, model.Document, model.Related, new Preview(2, {
                                Content: x_1.Link,
                            }), model.ErrorMsg, model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
                        }
                        case "png":
                        case "jpg": {
                            return new Model(model.DocumentId, model.Document, model.Related, new Preview(3, x_1.Link), model.ErrorMsg, model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
                        }
                        default: {
                            return new Model(model.DocumentId, model.Document, model.Related, new Preview(1), model.ErrorMsg, model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
                        }
                    }
                }
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.DocumentId, model.Document, model.Related, model.Preview, "Kunne ikke laste forhåndsvisning for tidligere versjon", model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
            }
            else {
                const matchValue_1 = model.SelectedPrevious;
                if (matchValue_1 != null) {
                    return new Model(model.DocumentId, model.Document, model.Related, model.Preview, model.ErrorMsg, model.Versions, new PreviousPreview(matchValue_1.Revision, res_2.fields[0]), model.TagForm, model.Revision);
                }
                else {
                    return model;
                }
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return model;
            }
            else {
                return new Model(model.DocumentId, model.Document, res_3.fields[0], model.Preview, model.ErrorMsg, model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
            }
        }
        case 4: {
            return new Model(model.DocumentId, model.Document, model.Related, model.Preview, model.ErrorMsg, model.Versions, model.SelectedPrevious, msg.fields[0], model.Revision);
        }
        case 5: {
            return new Model(model.DocumentId, model.Document, model.Related, model.Preview, model.ErrorMsg, model.Versions, model.SelectedPrevious, model.TagForm, msg.fields[0]);
        }
        case 6: {
            return new Model(model.DocumentId, (matchValue_2 = model.Document, (matchValue_2 != null) ? ((doc = matchValue_2, new ProcessModule_ProcessActivityDoc(doc.Id, doc.ActivityId, doc.DriveId, doc.DriveUrl, doc.SupDoc, doc.Name, doc.Filename, doc.NextRevision, doc.LastRevision, append([msg.fields[0]], doc.Tags), doc.Desc, doc.CompanyVersions, doc.RowPrefix, doc.RowPos, doc.Version, doc.Revisions))) : (void 0)), model.Related, model.Preview, model.ErrorMsg, model.Versions, model.SelectedPrevious, false, model.Revision);
        }
        case 7: {
            return new Model(model.DocumentId, (matchValue_3 = model.Document, (matchValue_3 != null) ? ((doc_1 = matchValue_3, new ProcessModule_ProcessActivityDoc(doc_1.Id, doc_1.ActivityId, doc_1.DriveId, doc_1.DriveUrl, doc_1.SupDoc, doc_1.Name, doc_1.Filename, doc_1.NextRevision, doc_1.LastRevision, doc_1.Tags.filter((t_1) => (t_1.Id !== msg.fields[0])), doc_1.Desc, doc_1.CompanyVersions, doc_1.RowPrefix, doc_1.RowPos, doc_1.Version, doc_1.Revisions))) : (void 0)), model.Related, model.Preview, model.ErrorMsg, model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
        }
        case 8: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return model;
            }
            else {
                return new Model(model.DocumentId, model.Document, model.Related, model.Preview, model.ErrorMsg, res_4.fields[0], model.SelectedPrevious, model.TagForm, model.Revision);
            }
        }
        case 9: {
            return new Model(model.DocumentId, model.Document, model.Related, model.Preview, model.ErrorMsg, model.Versions, new PreviousPreview(msg.fields[0], void 0), model.TagForm, model.Revision);
        }
        case 11: {
            return new Model(model.DocumentId, model.Document, model.Related, model.Preview, void 0, model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
        }
        case 10: {
            return new Model(model.DocumentId, model.Document, model.Related, model.Preview, msg.fields[0], model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.DocumentId, model.Document, model.Related, model.Preview, "Kunne ikke laste dokument. Vennligst prøv igjen senere", model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
            }
            else {
                return new Model(model.DocumentId, res.fields[0], model.Related, model.Preview, model.ErrorMsg, model.Versions, model.SelectedPrevious, model.TagForm, model.Revision);
            }
        }
    }
}

function fetchDocument(docId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/processactivitydoc/%i"))(docId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: ProcessModule_ProcessActivityDoc$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: ProcessModule_ProcessActivityDoc$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchPreview(documentId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/processactivitydoc/preview/%i"))(documentId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: ProcessModule_PreviewState$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: ProcessModule_PreviewState$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchOldPreview(driveItemId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/processactivitydoc/preview/old/%s"))(driveItemId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => string_type,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => string_type,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchRelated(documentId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/management/related/%i"))(documentId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: ProcessModule_Process$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: ProcessModule_Process$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function assignTag(documentId, tagId, tagName, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: () => tuple_type(int32_type, int32_type),
        })([documentId, tagId]);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_tryPost_5760677E("/api/document-tag/assign", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        const res = _arg1;
        if (res.tag === 0) {
            const x = res.fields[0];
            if (x.Result === "success") {
                dispatch(new Message(6, new ProcessModule_Tag(tagId, tagName)));
                return Promise.resolve();
            }
            else {
                dispatch(new Message(10, x.Message));
                return Promise.resolve();
            }
        }
        else {
            dispatch(new Message(10, "Kunne ikke legge til kategori for dette dokumentet"));
            return Promise.resolve();
        }
    })))));
    pr.then();
}

function removeTag(documentId, tagId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: () => tuple_type(int32_type, int32_type),
        })([documentId, tagId]);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_tryPost_5760677E("/api/document-tag/remove", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        const res = _arg1;
        if (res.tag === 0) {
            const x = res.fields[0];
            if (x.Result === "success") {
                dispatch(new Message(7, tagId));
                return Promise.resolve();
            }
            else {
                dispatch(new Message(10, x.Message));
                return Promise.resolve();
            }
        }
        else {
            dispatch(new Message(10, "Kunne ikke fjerne kategori for dette dokumentet"));
            return Promise.resolve();
        }
    })))));
    pr.then();
}

function fetchVersions(documentId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/processactivitydoc/versions/%i"))(documentId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProcessModule_DocVersion$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProcessModule_DocVersion$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(8, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function drawRelatedProcess(model, dispatch) {
    return createElement("div", {
        style: {
            width: 350,
            display: "flex",
            flexDirection: "column",
            marginTop: 10,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let matchValue, x;
            return append_1((matchValue = model.Related, (matchValue != null) ? ((x = matchValue, append_1(singleton(createElement("div", {
                style: {
                    padding: 5,
                    margin: 5,
                    backgroundColor: "#363636",
                    color: "#FFFFFF",
                    textAlign: "center",
                    marginTop: 0,
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    style: {
                        fontSize: 1.2 + "rem",
                    },
                    children: (x.RowPrefix.length > 0) ? toText(printf("%s - %s"))(x.RowPrefix)(x.Name) : x.Name,
                })]),
            })), delay(() => singleton(map((a_1) => createElement("div", {
                style: {
                    display: "flex",
                    flexDirection: "column",
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        backgroundColor: "#ddd",
                        borderRadius: 5,
                        padding: 5,
                        margin: ((2 + "px ") + 5) + "px",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {},
                        children: (a_1.RowPrefix.length > 0) ? toText(printf("%s - %s"))(a_1.RowPrefix)(a_1.Name) : a_1.Name,
                    })]),
                }), map((d) => createElement("div", {
                    style: {
                        backgroundColor: "#f6f6f6",
                        padding: 5,
                        margin: ((2 + "px ") + 5) + "px",
                        borderRadius: 5,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("a", {
                        href: toText(printf("/document/%i"))(d.Id),
                        style: {
                            display: "flex",
                            alignItems: "center",
                        },
                        children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(d.Filename), createElement("span", {
                            style: {
                                marginLeft: 5,
                                fontSize: 0.8 + "rem",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                color: "#313131",
                            },
                            children: d.Name,
                        })]),
                    })]),
                }), a_1.Docs)]),
            }), sortBy((a) => a.RowPos, x.Activities, {
                Compare: (x_1, y) => comparePrimitives(x_1, y),
            }))))))) : singleton(loadingIndicatorSmall())), delay(() => singleton(createElement("a", {
                href: "/management",
                className: "gradient-link-button",
                children: "Tilbake",
            }))));
        })))),
    });
}

function drawPreview(model, x) {
    const centeredLoader = () => createElement("div", {
        style: {
            width: 100 + "%",
            height: 100 + "%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray([loadingIndicatorSmall()]),
    });
    return createElement("div", {
        className: "document-preview-holder",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = model.SelectedPrevious;
            if (matchValue != null) {
                const prev = matchValue;
                return append_1(singleton(createElement("div", {
                    style: {
                        marginBottom: 10,
                    },
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Alert, [["severity", "warning"], ["variant", "filled"], ["children", toText(printf("Forhåndsviser eldre versjon %s"))(prev.Revision.Version)]])]),
                })), delay(() => {
                    const matchValue_2 = prev.Url;
                    if (matchValue_2 != null) {
                        const previewUrl = matchValue_2;
                        return singleton(createElement("iframe", {
                            key: toText(printf("previous-preview-%i"))(prev.Revision.Id),
                            className: "document-preview",
                            src: previewUrl,
                        }));
                    }
                    else {
                        return singleton(centeredLoader());
                    }
                }));
            }
            else {
                const matchValue_1 = model.Preview;
                switch (matchValue_1.tag) {
                    case 1: {
                        return singleton(createElement("div", {
                            style: {
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: 100 + "%",
                                height: 100 + "%",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                style: {
                                    fontSize: 3.5 + "rem",
                                    color: "#9a9a9a",
                                },
                                className: "fas fa-eye-slash",
                            }), createElement("span", {
                                style: {
                                    fontSize: 1.5 + "rem",
                                },
                                children: "Forhåndsvisning for denne typen fil er ikke tilgjengelig.",
                            }), createElement("span", {
                                children: ["Koble Derigo opp mot SharePoint for å støtte forhåndsvisning av flere filtyper."],
                            })]),
                        }));
                    }
                    case 3: {
                        return singleton(createElement("div", {
                            style: {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("img", {
                                style: {
                                    width: 100 + "%",
                                    maxWidth: 50 + "vw",
                                },
                                src: matchValue_1.fields[0],
                            })]),
                        }));
                    }
                    case 2: {
                        return singleton(createElement("embed", {
                            style: {
                                width: 100 + "%",
                                height: 100 + "%",
                            },
                            type: "application/json",
                            src: toText(printf("data:application/pdf;base64,%s"))(matchValue_1.fields[0].Content),
                        }));
                    }
                    default: {
                        return singleton(centeredLoader());
                    }
                }
            }
        })))),
    });
}

function collapseTitle(title, expanded, onClick) {
    return createElement("div", {
        style: {
            cursor: "pointer",
            width: 100 + "%",
            display: "flex",
            height: 38,
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(ChevronRight, {
            style: createObj(toList(delay(() => append_1(singleton(["transitionProperty", "transform"]), delay(() => append_1(singleton(["transitionDuration", 300 + "ms"]), delay(() => append_1(singleton(["display", "flex"]), delay(() => (expanded ? singleton(["transform", ("rotate(" + 90) + "deg)"]) : empty_1())))))))))),
        })])]]), MuiHelpers_createElement(Typography, [["style", {
            userSelect: "none",
        }], ["variant", "subtitle1"], ["children", title]])]),
        onClick: onClick,
    });
}

function collapseSection(expanded, children) {
    return MuiHelpers_createElement(Collapse, [["in", expanded], ["timeout", "auto"], ["collapsedHeight", 0], ["style", {
        margin: ((((((0 + "px ") + 10) + "px ") + 0) + "px ") + 20) + "px",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

function DrawInfo(drawInfoInputProps) {
    const x = drawInfoInputProps.x;
    const dispatch = drawInfoInputProps.dispatch;
    const model = drawInfoInputProps.model;
    const patternInput = useFeliz_React__React_useState_Static_1505({
        categoryExpanded: true,
        changeExpanded: false,
        infoExpanded: true,
        versionsExpanded: false,
    });
    const state = patternInput[0];
    const setState = patternInput[1];
    return MuiHelpers_createElement(Paper, [["elevation", 2], ["style", {
        display: "flex",
        flexDirection: "column",
        minWidth: 300,
        padding: 10,
        margin: 10,
    }], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
            fontWeight: "bold",
        },
        children: x.Name,
    }), createElement("div", {
        style: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
            marginLeft: 6,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
            },
            children: toText(printf("Versjon %s"))(x.Version),
        })), delay(() => (hasPermission(new Functionality(53)) ? append_1(singleton(MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Add, {})], ["onClick", (_arg1) => {
            dispatch(new Message(5, true));
        }]])), delay(() => (model.Revision ? singleton(createElement(Form, {
            documentId: x.Id,
            currentVersion: x.Version,
            onClose: (y) => {
                if (y) {
                    window.location.reload();
                }
                dispatch(new Message(5, false));
            },
        })) : empty_1()))) : empty_1()))))))),
    }), createElement("a", {
        href: x.DriveUrl,
        target: "_blank",
        style: {
            marginBottom: 10,
            marginLeft: 6,
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                color: "#000080",
            },
            children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(x.Filename), x.Filename]),
        })]),
    }), createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
        },
        children: Interop_reactApi.Children.toArray([collapseTitle("Informasjon", state.infoExpanded, (_arg2) => {
            setState({
                categoryExpanded: state.categoryExpanded,
                changeExpanded: state.changeExpanded,
                infoExpanded: !state.infoExpanded,
                versionsExpanded: state.versionsExpanded,
            });
        }), collapseSection(state.infoExpanded, toList(delay(() => append_1((x.Desc.length === 0) ? singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                fontStyle: "italic",
                color: "#999",
            },
            children: "Ingen informasjon gitt",
        })) : empty_1(), delay(() => append_1(singleton(createElement("span", {
            style: {
                fontStyle: "italic",
            },
            children: x.Desc,
        })), delay(() => {
            let matchValue;
            return append_1(singleton(createElement("div", {
                style: {
                    display: "flex",
                    flexDirection: "column",
                    margin: ((10 + "px ") + 0) + "px",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                        fontWeight: "bold",
                    },
                    children: "Forrige revisjon",
                }), createElement("span", {
                    style: createObj(toList(delay(() => ((x.LastRevision == null) ? append_1(singleton(["fontSize", 0.8 + "rem"]), delay(() => append_1(singleton(["fontStyle", "italic"]), delay(() => singleton(["color", "#999"]))))) : empty_1())))),
                    children: (matchValue = x.LastRevision, (matchValue != null) ? fromUtcToLocalDate(matchValue) : "Ingen tidligere revisjoner"),
                })]),
            })), delay(() => {
                let matchValue_1;
                return singleton(createElement("div", {
                    style: {
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 10,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                            fontWeight: "bold",
                        },
                        children: "Neste revisjon",
                    }), createElement("span", {
                        style: {},
                        children: (matchValue_1 = x.NextRevision, (matchValue_1 != null) ? fromUtcToLocalDate(matchValue_1) : "Ingne dato satt"),
                    })]),
                }));
            }));
        })))))))]),
    }), createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
        },
        children: Interop_reactApi.Children.toArray([collapseTitle("Kategorisering", state.categoryExpanded, (_arg3) => {
            setState({
                categoryExpanded: !state.categoryExpanded,
                changeExpanded: state.changeExpanded,
                infoExpanded: state.infoExpanded,
                versionsExpanded: state.versionsExpanded,
            });
        }), collapseSection(state.categoryExpanded, toList(delay(() => append_1(singleton(createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
                maxWidth: 300,
            },
            children: Interop_reactApi.Children.toArray([map((t) => MuiHelpers_createElement(Chip, [["variant", "outlined"], ["size", "small"], ["icon", react.createElement(LocalOffer, {})], ["label", t.Name], ["style", {
                margin: 3,
            }], ["onDelete", (_arg4) => {
                removeTag(x.Id, t.Id, dispatch);
            }]]), x.Tags)]),
        })), delay(() => append_1(model.TagForm ? singleton(createElement(TagForm_1, {
            existing: map((t_1) => t_1.Id, x.Tags, Int32Array),
            onClose: (tag) => {
                if (tag != null) {
                    const t_2 = tag;
                    dispatch(new Message(4, false));
                    assignTag(x.Id, t_2.Id, t_2.Name, dispatch);
                }
                else {
                    dispatch(new Message(4, false));
                }
            },
        })) : empty_1(), delay(() => append_1((x.Tags.length === 0) ? singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                fontStyle: "italic",
                color: "#999",
            },
            children: "Ingen kategorisering gitt",
        })) : empty_1(), delay(() => (hasPermission(new Functionality(52)) ? singleton(MuiHelpers_createElement(IconButton, [["onClick", (_arg5) => {
            dispatch(new Message(4, true));
        }], ["size", "small"], ["children", react.createElement(Add, {})]])) : empty_1()))))))))))]),
    }), createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
        },
        children: Interop_reactApi.Children.toArray([collapseTitle("Tidligere versjoner", state.versionsExpanded, (_arg6) => {
            setState({
                categoryExpanded: state.categoryExpanded,
                changeExpanded: state.changeExpanded,
                infoExpanded: state.infoExpanded,
                versionsExpanded: !state.versionsExpanded,
            });
        }), collapseSection(state.versionsExpanded, toList(delay(() => ((x.Revisions.length === 0) ? singleton(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                fontStyle: "italic",
                color: "#999",
            },
            children: "Ingen tidligere versjoner",
        })) : singleton(map((y_3) => createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                width: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "space-between",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    style: {
                        fontSize: 0.9 + "rem",
                    },
                    children: Interop_reactApi.Children.toArray(["Versjon ", createElement("b", {
                        children: [y_3.Version],
                    })]),
                }), MuiHelpers_createElement(IconButton, [["size", "small"], ["children", createElement("span", {
                    className: "fas fa-eye",
                })], ["onClick", (_arg7) => {
                    dispatch(new Message(9, y_3));
                    fetchOldPreview(y_3.DriveId, dispatch);
                }]])]),
            }), createElement("div", {
                style: {},
                children: Interop_reactApi.Children.toArray([]),
            })]),
        }), sortByDescending((y_1) => y_1.Id, x.Revisions, {
            Compare: (x_1, y_2) => comparePrimitives(x_1, y_2),
        })))))))]),
    }), createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
        },
        children: Interop_reactApi.Children.toArray([collapseTitle("Endringer", state.changeExpanded, (_arg8) => {
            setState({
                categoryExpanded: state.categoryExpanded,
                changeExpanded: !state.changeExpanded,
                infoExpanded: state.infoExpanded,
                versionsExpanded: state.versionsExpanded,
            });
        }), collapseSection(state.changeExpanded, toList(delay(() => {
            const matchValue_2 = model.Versions;
            return (matchValue_2 != null) ? singleton(map((y_6) => createElement("div", {
                style: {
                    display: "flex",
                    flexDirection: "column",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                    },
                    children: fromUtcToLocalDate(y_6.Modified),
                }), createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                    },
                    children: (y_6.ModifiedBy === "SharePoint App") ? "Endret gjennom styringssystemet" : toText(printf("Endret av %s"))(y_6.ModifiedBy),
                })]),
            }), sortByDescending((y_4) => y_4.Version, matchValue_2, {
                Compare: (x_3, y_5) => comparePrimitives(x_3, y_5),
            }))) : singleton(loadingIndicatorSmall());
        })))]),
    }), createElement("a", {
        href: x.DriveUrl,
        className: "gradient-link-button",
        style: {
            margin: 0,
            marginTop: 10,
        },
        target: "_blank",
        children: "Åpne i OneDrive/SharePoint",
    })])]]);
}

function drawDocument(model, dispatch, x) {
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            maxWidth: 100 + "vw",
            justifyContent: "space-around",
            height: "calc(100vh - 80px)",
        },
        children: Interop_reactApi.Children.toArray([drawRelatedProcess(model, dispatch), drawPreview(model, x), createElement(DrawInfo, {
            model: model,
            dispatch: dispatch,
            x: x,
        })]),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(11));
        })), delay(() => {
            const matchValue = model.Document;
            return (matchValue != null) ? singleton(drawDocument(model, dispatch, matchValue)) : singleton(createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: 80 + "vh",
                },
                children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
            }));
        })))))),
    });
}

export function Document$(document$InputProps) {
    const documentId = document$InputProps.documentId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(documentId));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchDocument(documentId, dispatch);
        fetchPreview(documentId, dispatch);
        fetchRelated(documentId, dispatch);
        fetchVersions(documentId, dispatch);
    });
    return view(patternInput[0], dispatch);
}

