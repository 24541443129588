import { StyleCreator$1_$ctor, StyleCreator$1__create_4EAC9E1E, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { ofArray, empty as empty_1, item as item_3, length, singleton } from "../.fable/fable-library.3.2.4/List.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Tooltip from "@material-ui/core/Tooltip";
import { createElement } from "react";
import * as react from "react";
import { comparePrimitives, uncurry, createObj } from "../.fable/fable-library.3.2.4/Util.js";
import { map, empty, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { record_type, bool_type, option_type, array_type, string_type, obj_type, union_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { ProjectPlan_PlanTemplate$reflection, ProjectPlan_PlanTemplate, ProjectPlan_ProjectProcess, ProjectPlan_ProjectProcess$reflection, ProjectPlan_ProjectActivity$reflection, ProjectPlan_ProjectActivity, Helpers_ResultMessage$reflection, ProjectPlan_ProjectDoc$reflection, ProjectPlan_ProjectDocForm$reflection, ProjectPlan_ProjectDocForm, ProjectPlan_ProjectDoc } from "../Shared/ApiDataTypes.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { snackbarError, loadingIndicatorSmall, errorBanner, PageElements_filenameToIcon } from "../ViewHelpers.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryGet_5760677E, FetchError$reflection, Fetch_tryDelete_5760677E, Fetch_tryPut_5760677E, Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import IconButton from "@material-ui/core/IconButton";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import { tryFind, sortBy, map as map_1, append as append_1 } from "../.fable/fable-library.3.2.4/Array.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import Add from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ProjectPlan_fetchTemplates } from "../Promises.js";

const useStyles = StyleImports_makeStyles_get((arg) => {
    const o = {
        tf: StyleCreator$1__create_4EAC9E1E(StyleCreator$1_$ctor(), singleton(["margin", 10])),
    };
    return Object.assign({}, o);
});

export function Document_categoryTag(x) {
    return MuiHelpers_createElement(Tooltip, [["title", (x === 2) ? "Kategori: Veiledningsdokument" : ((x === 3) ? "Kategori: Støttedokument" : "Kategori: Arbeidsdokument")], ["children", createElement("div", {
        style: createObj(toList(delay(() => append(singleton_1(["fontSize", 0.7 + "rem"]), delay(() => append(singleton_1(["padding", ((3 + "px ") + 5) + "px"]), delay(() => append(singleton_1(["borderRadius", 8]), delay(() => append((x === 2) ? append(singleton_1(["backgroundColor", "#6A5ACD"]), delay(() => singleton_1(["margin", ((2 + "px ") + 7) + "px"]))) : ((x === 3) ? append(singleton_1(["backgroundColor", "#191970"]), delay(() => singleton_1(["margin", ((2 + "px ") + 13) + "px"]))) : append(singleton_1(["backgroundColor", "#4682B4"]), delay(() => singleton_1(["margin", ((2 + "px ") + 11) + "px"])))), delay(() => append(singleton_1(["color", "#FFFFFF"]), delay(() => append(singleton_1(["fontWeight", "bold"]), delay(() => append(singleton_1(["whiteSpace", "nowrap"]), delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["alignItems", "center"]), delay(() => singleton_1(["height", 17])))))))))))))))))))))),
        children: Interop_reactApi.Children.toArray([createElement("span", {
            style: {
                userSelect: "none",
            },
            children: (x === 2) ? "Veileder" : ((x === 3) ? "Støtte" : "Arbeid"),
        })]),
    })]]);
}

export class Document_AddFormTarget extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Template", "ProjectPlan"];
    }
}

export function Document_AddFormTarget$reflection() {
    return union_type("ProjectPlanTemplate.Document.AddFormTarget", [], Document_AddFormTarget, () => [[], [["Item", int32_type]]]);
}

export function Document_AddForm(document_AddFormInputProps) {
    const onClose = document_AddFormInputProps.onClose;
    const defaultRowPos = document_AddFormInputProps.defaultRowPos;
    const activityId = document_AddFormInputProps.activityId;
    const updating = document_AddFormInputProps.updating;
    const target = document_AddFormInputProps.target;
    const patternInput = useFeliz_React__React_useState_Static_1505(new ProjectPlan_ProjectDocForm((updating == null) ? (new ProjectPlan_ProjectDoc(-1, activityId, "", "", "", "", "", "", defaultRowPos, [], 1)) : updating, "", ""));
    const updateForm = patternInput[1];
    const documentForm = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const setValidName = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const validRowPos = patternInput_2[0];
    const setValidRowPos = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const setResult = patternInput_3[1];
    const result = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrorMsg = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const docSelected = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_6[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", (updating == null) ? "Legg til dokument" : "Oppdater dokument"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let value_140;
            return append((result == null) ? append(singleton_1(createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("label", {
                    style: {
                        cursor: "pointer",
                        textAlign: "center",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        style: createObj(toList(delay(() => append(singleton_1(["display", "block"]), delay(() => append(singleton_1(["fontSize", 2 + "rem"]), delay(() => ((updating != null) ? singleton_1(["color", "#acacac"]) : empty())))))))),
                        className: "fas fa-cloud-upload-alt",
                    }), (updating == null) ? ((!docSelected) ? "Velg fil" : "Velg en annen fil") : documentForm.Entry.Filename, createElement("input", {
                        type: "file",
                        disabled: updating != null,
                        onChange: (ev) => {
                            let arg10_1, arg20;
                            const fileList = ev.target.files;
                            if (!(fileList == null)) {
                                const x_4 = toList(delay(() => map((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                                try {
                                    if (length(x_4) > 0) {
                                        const file = item_3(0, x_4);
                                        const filename = file.name;
                                        console.log(some((arg10_1 = (file.size | 0), toText(printf("filesize: %i"))(arg10_1))));
                                        console.log(some((arg20 = file.name, toText(printf("filename: %s (%s)"))(filename)(arg20))));
                                        const reader = new FileReader();
                                        reader.addEventListener("load", (_arg2_1) => {
                                            let inputRecord;
                                            const content = reader.result;
                                            patternInput_5[1](true);
                                            if (documentForm.Entry.Name === "") {
                                                updateForm(new ProjectPlan_ProjectDocForm((inputRecord = documentForm.Entry, new ProjectPlan_ProjectDoc(inputRecord.Id, inputRecord.ActivityId, inputRecord.DriveId, inputRecord.DriveUrl, inputRecord.Prefix, filename, inputRecord.Filename, inputRecord.Desc, inputRecord.RowPos, inputRecord.Responsible, inputRecord.Category)), filename, content));
                                            }
                                            else {
                                                updateForm(new ProjectPlan_ProjectDocForm(documentForm.Entry, filename, content));
                                            }
                                        });
                                        const value_29 = reader.readAsDataURL(file);
                                    }
                                }
                                catch (matchValue) {
                                }
                            }
                        },
                        style: {
                            display: "none",
                        },
                    })]),
                })), delay(() => (((!docSelected) ? (updating == null) : false) ? singleton_1(createElement("span", {
                    style: {
                        fontSize: 0.8 + "rem",
                    },
                    children: "Ingen fil valgt",
                })) : ((docSelected ? (updating == null) : false) ? singleton_1(createElement("span", {
                    children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(documentForm.Filename), documentForm.Filename]),
                })) : empty())))))))),
            })), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["autoFocus", true], ["fullWidth", true], ["onChange", (e) => {
                let inputRecord_1;
                updateForm(new ProjectPlan_ProjectDocForm((inputRecord_1 = documentForm.Entry, new ProjectPlan_ProjectDoc(inputRecord_1.Id, inputRecord_1.ActivityId, inputRecord_1.DriveId, inputRecord_1.DriveUrl, Browser_Types_Event__Event_get_Value(e), inputRecord_1.Name, inputRecord_1.Filename, inputRecord_1.Desc, inputRecord_1.RowPos, inputRecord_1.Responsible, inputRecord_1.Category)), documentForm.Filename, documentForm.FileContent));
            }], ["label", "Dokumentkode/prefix (valgfritt)"], ["defaultValue", documentForm.Entry.Prefix], ["size", "small"], ["variant", "outlined"]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append(singleton_1(["onChange", (e_1) => {
                let inputRecord_2;
                const x_6 = Browser_Types_Event__Event_get_Value(e_1);
                updateForm(new ProjectPlan_ProjectDocForm((inputRecord_2 = documentForm.Entry, new ProjectPlan_ProjectDoc(inputRecord_2.Id, inputRecord_2.ActivityId, inputRecord_2.DriveId, inputRecord_2.DriveUrl, inputRecord_2.Prefix, x_6, inputRecord_2.Filename, inputRecord_2.Desc, inputRecord_2.RowPos, inputRecord_2.Responsible, inputRecord_2.Category)), documentForm.Filename, documentForm.FileContent));
                setValidName(x_6.length > 0);
            }]), delay(() => append(singleton_1(["label", "Dokumentnavn"]), delay(() => append(singleton_1(["defaultValue", documentForm.Entry.Name]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => ((!patternInput_1[0]) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Dokumentnavn kan ikke være tomt"]))) : empty()))))))))))))))))))))), delay(() => append((documentForm.Entry.Prefix.length > 0) ? singleton_1(createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    marginLeft: 10,
                    fontStyle: "italic",
                },
                children: toText(printf("%s - %s"))(documentForm.Entry.Prefix)(documentForm.Entry.Name),
            })) : empty(), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["size", "small"], ["fullWidth", true], ["multiline", true], ["onChange", (e_2) => {
                let inputRecord_3;
                updateForm(new ProjectPlan_ProjectDocForm((inputRecord_3 = documentForm.Entry, new ProjectPlan_ProjectDoc(inputRecord_3.Id, inputRecord_3.ActivityId, inputRecord_3.DriveId, inputRecord_3.DriveUrl, inputRecord_3.Prefix, inputRecord_3.Name, inputRecord_3.Filename, Browser_Types_Event__Event_get_Value(e_2), inputRecord_3.RowPos, inputRecord_3.Responsible, inputRecord_3.Category)), documentForm.Filename, documentForm.FileContent));
            }], ["label", "Beskrivelse"], ["defaultValue", documentForm.Entry.Desc], ["variant", "outlined"]])), delay(() => singleton_1(createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "space-between",
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["onChange", (e_3) => {
                    let inputRecord_4;
                    const x_8 = Browser_Types_Event__Event_get_Value(e_3);
                    try {
                        const asInt = parse(x_8, 511, false, 32) | 0;
                        updateForm(new ProjectPlan_ProjectDocForm((inputRecord_4 = documentForm.Entry, new ProjectPlan_ProjectDoc(inputRecord_4.Id, inputRecord_4.ActivityId, inputRecord_4.DriveId, inputRecord_4.DriveUrl, inputRecord_4.Prefix, inputRecord_4.Name, inputRecord_4.Filename, inputRecord_4.Desc, asInt, inputRecord_4.Responsible, inputRecord_4.Category)), documentForm.Filename, documentForm.FileContent));
                        setValidRowPos(asInt > 0);
                    }
                    catch (matchValue_1) {
                        setValidRowPos(false);
                    }
                }]), delay(() => append(singleton_1(["label", "Radnummer"]), delay(() => append(singleton_1(["defaultValue", documentForm.Entry.RowPos]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["inputProps", {
                    style: {
                        textAlign: "center",
                        maxWidth: 80,
                    },
                }]), delay(() => ((!validRowPos) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Ugyldig nummer. Oppgi ett nummer større eller lik 1."]))) : empty())))))))))))))))))), MuiHelpers_createElement(FormControl, [["required", true], ["style", {
                    width: 150,
                }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Kategori"]]), MuiHelpers_createElement(Select, [["value", documentForm.Entry.Category], ["onChange", (e_4, _arg24) => {
                    let inputRecord_5;
                    updateForm(new ProjectPlan_ProjectDocForm((inputRecord_5 = documentForm.Entry, new ProjectPlan_ProjectDoc(inputRecord_5.Id, inputRecord_5.ActivityId, inputRecord_5.DriveId, inputRecord_5.DriveUrl, inputRecord_5.Prefix, inputRecord_5.Name, inputRecord_5.Filename, inputRecord_5.Desc, inputRecord_5.RowPos, inputRecord_5.Responsible, Browser_Types_Event__Event_get_Value(e_4))), documentForm.Filename, documentForm.FileContent));
                }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["value", 1], ["children", "Arbeid"]]), MuiHelpers_createElement(MenuItem, [["value", 2], ["children", "Veileder"]]), MuiHelpers_createElement(MenuItem, [["value", 3], ["children", "Støtte"]])])]])])]])]),
            })))))))))))) : singleton_1((value_140 = ((updating == null) ? "Dokument lagt til" : "Dokument oppdatert"), createElement("span", {
                children: [value_140],
            }))), delay(() => singleton_1(errorBanner(patternInput_4[0], () => {
                setErrorMsg(void 0);
            }))));
        })))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (patternInput_6[0] ? singleton_1(loadingIndicatorSmall()) : append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg4) => {
        onClose(result);
    }]])), delay(() => ((result == null) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", (updating == null) ? "Legg til" : "Oppdater"], ["onClick", (_arg5) => {
        if ((documentForm.Entry.Name.length > 0) ? validRowPos : false) {
            if ((updating != null) ? true : ((updating == null) ? docSelected : false)) {
                if (target.tag === 1) {
                    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        setProcessing(true);
                        const requestPath_2 = toText(printf("/api/project-plan/document/%i"))(target.fields[0]);
                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body_2 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: ProjectPlan_ProjectDocForm$reflection,
                            })(documentForm);
                            const decoder_2 = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: ProjectPlan_ProjectDoc$reflection,
                            });
                            return Fetch_tryPost_5760677E(requestPath_2, some(body_2), empty_1(), void 0, void 0, void 0, uncurry(2, decoder_2), {
                                ResolveType: ProjectPlan_ProjectDoc$reflection,
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg2) => {
                            const res_1 = _arg2;
                            return ((res_1.tag === 0) ? ((setResult(res_1.fields[0]), Promise.resolve())) : ((setErrorMsg("Kunne ikke legge til dokument"), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                setProcessing(false);
                                return Promise.resolve();
                            }));
                        }));
                    }));
                    pr_1.then();
                }
                else {
                    const x = documentForm;
                    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        setProcessing(true);
                        return ((updating == null) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: ProjectPlan_ProjectDocForm$reflection,
                            })(x);
                            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: ProjectPlan_ProjectDoc$reflection,
                            });
                            return Fetch_tryPost_5760677E("/api/project-plan/template/document", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                                ResolveType: ProjectPlan_ProjectDoc$reflection,
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })) : PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: ProjectPlan_ProjectDoc$reflection,
                            })(x.Entry);
                            const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: ProjectPlan_ProjectDoc$reflection,
                            });
                            return Fetch_tryPut_5760677E("/api/project-plan/template/document", some(body_1), empty_1(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                                ResolveType: ProjectPlan_ProjectDoc$reflection,
                            }, {
                                ResolveType: () => obj_type,
                            });
                        }))).then(((_arg1) => {
                            const res = _arg1;
                            return ((res.tag === 0) ? ((setResult(res.fields[0]), Promise.resolve())) : ((setErrorMsg((updating == null) ? "Noe gikk galt. Kunne ikke legge til dokument" : "Noe gikk galt. Kunne ikke oppdatere dokument."), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                setProcessing(false);
                                return Promise.resolve();
                            }));
                        }));
                    }));
                    pr.then();
                }
            }
            else {
                setErrorMsg("Ingen dokument valgt");
            }
        }
        else {
            setValidName(false);
        }
    }]])) : empty()))))))))]])])]]);
}

function Document_DeleteForm(document_DeleteFormInputProps) {
    const onClose = document_DeleteFormInputProps.onClose;
    const documentId = document_DeleteFormInputProps.documentId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const result = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Slett dokument"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((!result) ? append(singleton_1(createElement("span", {
            style: {
                fontSize: 1.1 + "rem",
                fontWeight: "bold",
            },
            children: "Er du sikker på at du vil slette dette dokumentet?",
        })), delay(() => singleton_1(createElement("p", {
            style: {
                marginTop: 10,
            },
            children: "Dette påvirker kun malen, ikke eksisterende prosjekter.",
        })))) : singleton_1(createElement("span", {
            children: ["Dokument slettet"],
        })), delay(() => singleton_1(errorBanner(patternInput_1[0], () => {
            setErrMsg(void 0);
        })))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        onClose(result);
    }]])), delay(() => ((!result) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["color", "secondary"], ["children", "Slett"], ["onClick", (_arg3) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const requestPath = toText(printf("/api/project-plan/template/document/%i"))(documentId);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: () => string_type,
                })("");
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryDelete_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    const x = res.fields[0];
                    if (x.Result === "success") {
                        patternInput[1](true);
                        return Promise.resolve();
                    }
                    else {
                        setErrMsg(x.Message);
                        return Promise.resolve();
                    }
                }
                else {
                    setErrMsg("Noe gikk galt. Kunne ikke slette dokument");
                    return Promise.resolve();
                }
            }));
        }));
        pr.then();
    }]])) : empty())))))))]])])]]);
}

export function Document_DrawDocument(document_DrawDocumentInputProps) {
    const onDelete = document_DrawDocumentInputProps.onDelete;
    const x = document_DrawDocumentInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505({
        menuOpen: false,
        showDelete: false,
        showUpdate: false,
        this: x,
    });
    const updateState = patternInput[1];
    const state = patternInput[0];
    const anchor = useReact_useRef_1505(void 0);
    return createElement("div", {
        style: {
            display: "flex",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(state.showUpdate ? singleton_1(createElement(Document_AddForm, {
            target: new Document_AddFormTarget(0),
            updating: state.this,
            activityId: x.ActivityId,
            defaultRowPos: state.this.RowPos,
            onClose: (u) => {
                if (u != null) {
                    updateState({
                        menuOpen: state.menuOpen,
                        showDelete: state.showDelete,
                        showUpdate: false,
                        this: u,
                    });
                }
                else {
                    updateState({
                        menuOpen: state.menuOpen,
                        showDelete: state.showDelete,
                        showUpdate: false,
                        this: state.this,
                    });
                }
            },
        })) : empty(), delay(() => append(state.showDelete ? singleton_1(createElement(Document_DeleteForm, {
            documentId: x.Id,
            onClose: (didDelete) => {
                updateState({
                    menuOpen: state.menuOpen,
                    showDelete: false,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
                if (didDelete) {
                    onDelete();
                }
            },
        })) : empty(), delay(() => {
            let children_1;
            return singleton_1(createElement("div", {
                style: {
                    padding: 3,
                    paddingLeft: 8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexGrow: 1,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        display: "flex",
                    },
                    children: Interop_reactApi.Children.toArray([Document_categoryTag(state.this.Category), PageElements_filenameToIcon(state.this.Filename), createElement("a", {
                        href: x.DriveUrl,
                        target: "_blank",
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: (state.this.Prefix.length > 0) ? toText(printf("%s - %s"))(state.this.Prefix)(state.this.Name) : state.this.Name,
                    })]),
                }), (children_1 = ofArray([MuiHelpers_createElement(IconButton, [["size", "small"], ["ref", anchor], ["style", {
                    marginLeft: 20,
                }], ["children", react.createElement(MoreHoriz, {})], ["onClick", (_arg1) => {
                    updateState({
                        menuOpen: !state.menuOpen,
                        showDelete: state.showDelete,
                        showUpdate: state.showUpdate,
                        this: state.this,
                    });
                }]]), MuiHelpers_createElement(Menu, [["open", state.menuOpen], ["onClose", (_arg16, v) => {
                    updateState({
                        menuOpen: false,
                        showDelete: state.showDelete,
                        showUpdate: state.showUpdate,
                        this: state.this,
                    });
                }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["children", "Endre dokument"], ["onClick", (_arg3) => {
                    updateState({
                        menuOpen: false,
                        showDelete: state.showDelete,
                        showUpdate: true,
                        this: state.this,
                    });
                }]]), MuiHelpers_createElement(MenuItem, [["children", "Slett dokument"], ["onClick", (_arg4) => {
                    updateState({
                        menuOpen: false,
                        showDelete: true,
                        showUpdate: state.showUpdate,
                        this: state.this,
                    });
                }]])])]])]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                }))]),
            }));
        })))))))),
    });
}

export function Activity_AddForm(activity_AddFormInputProps) {
    const onClose = activity_AddFormInputProps.onClose;
    const defaultRowPos = activity_AddFormInputProps.defaultRowPos;
    const parentId = activity_AddFormInputProps.parentId;
    const updating = activity_AddFormInputProps.updating;
    const patternInput = useFeliz_React__React_useState_Static_1505((updating == null) ? (new ProjectPlan_ProjectActivity(-1, parentId, "", "", "", defaultRowPos, [], [], [], 0, "", "", void 0, 1, [])) : updating);
    const updateForm = patternInput[1];
    const activityForm = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const setValidName = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const validRowPos = patternInput_2[0];
    const setValidRowPos = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const result = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_5[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", (updating == null) ? "Legg til aktivitet" : "Oppdater aktivitet"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let value_78;
            return append((result == null) ? append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["autoFocus", true], ["fullWidth", true], ["onChange", (e) => {
                updateForm(new ProjectPlan_ProjectActivity(activityForm.Id, activityForm.ProcessId, Browser_Types_Event__Event_get_Value(e), activityForm.Name, activityForm.Desc, activityForm.RowPos, activityForm.Docs, activityForm.Comments, activityForm.Links, activityForm.NumDocs, activityForm.DriveId, activityForm.DriveUrl, activityForm.Deadline, activityForm.State, activityForm.Responsible));
            }], ["label", "Aktivitetkode/prefix (valgfritt)"], ["defaultValue", activityForm.Prefix], ["size", "small"], ["variant", "outlined"]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append(singleton_1(["onChange", (e_1) => {
                const x_3 = Browser_Types_Event__Event_get_Value(e_1);
                updateForm(new ProjectPlan_ProjectActivity(activityForm.Id, activityForm.ProcessId, activityForm.Prefix, x_3, activityForm.Desc, activityForm.RowPos, activityForm.Docs, activityForm.Comments, activityForm.Links, activityForm.NumDocs, activityForm.DriveId, activityForm.DriveUrl, activityForm.Deadline, activityForm.State, activityForm.Responsible));
                setValidName(x_3.length > 0);
            }]), delay(() => append(singleton_1(["label", "Aktivitetnavn"]), delay(() => append(singleton_1(["defaultValue", activityForm.Name]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => ((!patternInput_1[0]) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Aktivitetnavn kan ikke være tomt"]))) : empty()))))))))))))))))))))), delay(() => append(singleton_1(createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    marginLeft: 10,
                    fontStyle: "italic",
                },
                children: (activityForm.Prefix.length > 0) ? toText(printf("%s - %s"))(activityForm.Prefix)(activityForm.Name) : activityForm.Name,
            })), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["size", "small"], ["fullWidth", true], ["multiline", true], ["onChange", (e_2) => {
                updateForm(new ProjectPlan_ProjectActivity(activityForm.Id, activityForm.ProcessId, activityForm.Prefix, activityForm.Name, Browser_Types_Event__Event_get_Value(e_2), activityForm.RowPos, activityForm.Docs, activityForm.Comments, activityForm.Links, activityForm.NumDocs, activityForm.DriveId, activityForm.DriveUrl, activityForm.Deadline, activityForm.State, activityForm.Responsible));
            }], ["label", "Beskrivelse"], ["defaultValue", activityForm.Desc], ["variant", "outlined"]])), delay(() => singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["onChange", (e_3) => {
                const x_5 = Browser_Types_Event__Event_get_Value(e_3);
                try {
                    const asInt = parse(x_5, 511, false, 32) | 0;
                    updateForm(new ProjectPlan_ProjectActivity(activityForm.Id, activityForm.ProcessId, activityForm.Prefix, activityForm.Name, activityForm.Desc, asInt, activityForm.Docs, activityForm.Comments, activityForm.Links, activityForm.NumDocs, activityForm.DriveId, activityForm.DriveUrl, activityForm.Deadline, activityForm.State, activityForm.Responsible));
                    setValidRowPos(asInt > 0);
                }
                catch (matchValue) {
                    setValidRowPos(false);
                }
            }]), delay(() => append(singleton_1(["label", "Radnummer"]), delay(() => append(singleton_1(["defaultValue", activityForm.RowPos]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["inputProps", {
                style: {
                    textAlign: "center",
                    maxWidth: 80,
                },
            }]), delay(() => ((!validRowPos) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Ugyldig nummer. Oppgi ett nummer større eller lik 1."]))) : empty()))))))))))))))))))))))))))) : singleton_1((value_78 = ((updating == null) ? "Aktivitet lagt til" : "Aktivitet oppdatert"), createElement("span", {
                children: [value_78],
            }))), delay(() => singleton_1(snackbarError(patternInput_5[0], () => {
                setErrMsg(void 0);
            }))));
        })))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!patternInput_4[0]) ? append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        onClose(result);
    }]])), delay(() => ((result == null) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", (updating == null) ? "Legg til" : "Oppdater"], ["onClick", (_arg3) => {
        if ((activityForm.Name.length > 0) ? validRowPos : false) {
            const x = activityForm;
            setProcessing(true);
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (((updating == null) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ProjectPlan_ProjectActivity$reflection,
                })(x);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: ProjectPlan_ProjectActivity$reflection,
                });
                return Fetch_tryPost_5760677E("/api/project-plan/template/activity", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: ProjectPlan_ProjectActivity$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })) : PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ProjectPlan_ProjectActivity$reflection,
                })(x);
                const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: ProjectPlan_ProjectActivity$reflection,
                });
                return Fetch_tryPut_5760677E("/api/project-plan/template/activity", some(body_1), empty_1(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                    ResolveType: ProjectPlan_ProjectActivity$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            }))).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    setProcessing(false);
                    patternInput_3[1](res.fields[0]);
                    return Promise.resolve();
                }
                else {
                    setProcessing(false);
                    setErrMsg((updating == null) ? "Noe gikk galt. Kunne ikke legge til aktivitet" : "Noe gikk galt. Kunne ikke oppdatere aktivitet");
                    return Promise.resolve();
                }
            })))));
            pr.then();
        }
        else {
            setValidName(false);
        }
    }]])) : empty()))) : singleton_1(loadingIndicatorSmall()))))))]])])]]);
}

function Activity_DeleteForm(activity_DeleteFormInputProps) {
    const onClose = activity_DeleteFormInputProps.onClose;
    const activityId = activity_DeleteFormInputProps.activityId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const result = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Slett aktivitet"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((!result) ? append(singleton_1(createElement("span", {
            style: {
                fontWeight: "bold",
                fontSize: 1.1 + "rem",
            },
            children: "Er du sikker på at du vil slette denne aktiviteten?",
        })), delay(() => singleton_1(createElement("p", {
            style: {
                marginTop: 10,
            },
            children: "Dette vil også slette alle eventuelle tilhørende dokumenter. Dette påvirker kun malen, ikke eksisterende prosjekter.",
        })))) : singleton_1(createElement("span", {
            children: ["Aktivitet slettet"],
        })), delay(() => singleton_1(snackbarError(patternInput_1[0], () => {
            setErrMsg(void 0);
        })))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        onClose(result);
    }]])), delay(() => ((!result) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["color", "secondary"], ["children", "Slett"], ["onClick", (_arg3) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const requestPath = toText(printf("/api/project-plan/template/activity/%i"))(activityId);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: () => string_type,
                })("");
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryDelete_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    const x = res.fields[0];
                    if (x.Result === "success") {
                        patternInput[1](true);
                        return Promise.resolve();
                    }
                    else {
                        setErrMsg(x.Message);
                        return Promise.resolve();
                    }
                }
                else {
                    setErrMsg("Noe gikk galt. Kunne ikke slette aktivitet");
                    return Promise.resolve();
                }
            }));
        }));
        pr.then();
    }]])) : empty())))))))]])])]]);
}

export function Activity_DrawActivity(activity_DrawActivityInputProps) {
    const onDelete = activity_DrawActivityInputProps.onDelete;
    const x = activity_DrawActivityInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505(x.Docs);
    const setDocuments = patternInput[1];
    const documents = patternInput[0];
    const anchor = useReact_useRef_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505({
        menuOpen: false,
        showAdd: false,
        showDelete: false,
        showUpdate: false,
        this: x,
    });
    const updateState = patternInput_1[1];
    const state = patternInput_1[0];
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(state.showAdd ? singleton_1(createElement(Document_AddForm, {
            target: new Document_AddFormTarget(0),
            updating: void 0,
            activityId: x.Id,
            defaultRowPos: documents.length + 1,
            onClose: (d) => {
                if (d != null) {
                    setDocuments(append_1([d], documents));
                }
                updateState({
                    menuOpen: state.menuOpen,
                    showAdd: false,
                    showDelete: state.showDelete,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
            },
        })) : empty(), delay(() => append(state.showUpdate ? singleton_1(createElement(Activity_AddForm, {
            updating: state.this,
            parentId: x.ProcessId,
            defaultRowPos: state.this.RowPos,
            onClose: (u) => {
                if (u != null) {
                    updateState({
                        menuOpen: state.menuOpen,
                        showAdd: state.showAdd,
                        showDelete: state.showDelete,
                        showUpdate: false,
                        this: u,
                    });
                }
                else {
                    updateState({
                        menuOpen: state.menuOpen,
                        showAdd: state.showAdd,
                        showDelete: state.showDelete,
                        showUpdate: false,
                        this: state.this,
                    });
                }
            },
        })) : empty(), delay(() => append(state.showDelete ? singleton_1(createElement(Activity_DeleteForm, {
            activityId: x.Id,
            onClose: (x_1) => {
                updateState({
                    menuOpen: state.menuOpen,
                    showAdd: state.showAdd,
                    showDelete: false,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
                if (x_1) {
                    onDelete();
                }
            },
        })) : empty(), delay(() => {
            let children_1;
            return append(singleton_1(createElement("div", {
                style: {
                    display: "flex",
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        padding: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 25,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        className: "fas fa-level-up-alt fa-rotate-90",
                    })]),
                }), createElement("div", {
                    style: {
                        border: (((2 + "px ") + "solid") + " ") + "#ddd",
                        borderLeft: (((3 + "px ") + "solid") + " ") + "#87CEEB",
                        ["border-bottom"]: "none",
                        padding: 3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexGrow: 1,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            fontSize: 1 + "rem",
                            fontWeight: "bold",
                        },
                        children: (state.this.Prefix.length > 0) ? toText(printf("%s - %s"))(state.this.Prefix)(state.this.Name) : state.this.Name,
                    }), (children_1 = ofArray([MuiHelpers_createElement(IconButton, [["size", "small"], ["ref", anchor], ["style", {
                        marginLeft: 20,
                    }], ["children", react.createElement(MoreHoriz, {})], ["onClick", (_arg1) => {
                        updateState({
                            menuOpen: !state.menuOpen,
                            showAdd: state.showAdd,
                            showDelete: state.showDelete,
                            showUpdate: state.showUpdate,
                            this: state.this,
                        });
                    }]]), MuiHelpers_createElement(Menu, [["open", state.menuOpen], ["onClose", (_arg16, v) => {
                        updateState({
                            menuOpen: false,
                            showAdd: state.showAdd,
                            showDelete: state.showDelete,
                            showUpdate: state.showUpdate,
                            this: state.this,
                        });
                    }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["children", "Legg til dokument"], ["onClick", (_arg3) => {
                        updateState({
                            menuOpen: false,
                            showAdd: true,
                            showDelete: state.showDelete,
                            showUpdate: state.showUpdate,
                            this: state.this,
                        });
                    }]]), MuiHelpers_createElement(MenuItem, [["children", "Endre aktivitet"], ["onClick", (_arg4) => {
                        updateState({
                            menuOpen: false,
                            showAdd: state.showAdd,
                            showDelete: state.showDelete,
                            showUpdate: true,
                            this: state.this,
                        });
                    }]]), MuiHelpers_createElement(MenuItem, [["children", "Slett aktivitet"], ["onClick", (_arg5) => {
                        updateState({
                            menuOpen: false,
                            showAdd: state.showAdd,
                            showDelete: true,
                            showUpdate: state.showUpdate,
                            this: state.this,
                        });
                    }]])])]])]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    }))]),
                })]),
            })), delay(() => {
                let children_3;
                return singleton_1(createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: 25,
                        border: (((2 + "px ") + "solid") + " ") + "#ddd",
                        borderLeft: (((3 + "px ") + "solid") + " ") + "#87CEEB",
                        ["border-top"]: "none",
                        padding: 3,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        style: {},
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: state.this.Desc,
                        })]),
                    }), (children_3 = singleton(map_1((d_2) => createElement(Document_DrawDocument, {
                        x: d_2,
                        onDelete: () => {
                            setDocuments(documents.filter((y_1) => (y_1.Id !== d_2.Id)));
                        },
                    }), sortBy((d_1) => d_1.RowPos, documents, {
                        Compare: (x_2, y) => comparePrimitives(x_2, y),
                    }))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_3)),
                    }))]),
                }));
            }));
        })))))))))),
    });
}

class Model extends Record {
    constructor(TemplateId, Processes, AddForm, ErrorMsg) {
        super();
        this.TemplateId = (TemplateId | 0);
        this.Processes = Processes;
        this.AddForm = AddForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ProjectPlanTemplate.Model", [], Model, () => [["TemplateId", int32_type], ["Processes", option_type(array_type(ProjectPlan_ProjectProcess$reflection()))], ["AddForm", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TemplateResponse", "ToggleAddForm", "AppendProcess", "RemoveProcess", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProjectPlanTemplate.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectPlan_ProjectProcess$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectPlan_ProjectProcess$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", ProjectPlan_ProjectProcess$reflection()]], [["Item", int32_type]], []]);
}

function init(x) {
    return new Model(x, void 0, false, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1;
    switch (msg.tag) {
        case 1: {
            return new Model(model.TemplateId, model.Processes, !model.AddForm, model.ErrorMsg);
        }
        case 2: {
            return new Model(model.TemplateId, (matchValue = model.Processes, (matchValue != null) ? append_1([msg.fields[0]], matchValue) : (void 0)), false, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.TemplateId, (matchValue_1 = model.Processes, (matchValue_1 != null) ? matchValue_1.filter((z) => (z.Id !== msg.fields[0])) : (void 0)), model.AddForm, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.TemplateId, model.Processes, model.AddForm, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.TemplateId, model.Processes, model.AddForm, "Noe gikk galt. Kunne ikke laste prosesser");
            }
            else {
                return new Model(model.TemplateId, res.fields[0], model.AddForm, model.ErrorMsg);
            }
        }
    }
}

function fetchTemplate(templateId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/project-plan/template/%i"))(templateId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProjectPlan_ProjectProcess$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProjectPlan_ProjectProcess$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function AddForm(addFormInputProps) {
    const onClose = addFormInputProps.onClose;
    const defaultRowPos = addFormInputProps.defaultRowPos;
    const templateId = addFormInputProps.templateId;
    const parentId = addFormInputProps.parentId;
    const updating = addFormInputProps.updating;
    const patternInput = useFeliz_React__React_useState_Static_1505((updating == null) ? (new ProjectPlan_ProjectProcess(-1, templateId, "", "", "", defaultRowPos, parentId, [], [], 0, 0, "", "")) : updating);
    const updateForm = patternInput[1];
    const processForm = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const setValidName = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const validRowPos = patternInput_2[0];
    const setValidRowPos = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const result = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_5[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", (updating == null) ? ((processForm.ParentId != null) ? "Legg til underprosess" : "Legg til prosess") : "Oppdater prosess"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let value_78;
            return append((result == null) ? append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["autoFocus", true], ["fullWidth", true], ["onChange", (e) => {
                updateForm(new ProjectPlan_ProjectProcess(processForm.Id, processForm.TemplateId, Browser_Types_Event__Event_get_Value(e), processForm.Name, processForm.Desc, processForm.RowPos, processForm.ParentId, processForm.Children, processForm.Activities, processForm.NumChildren, processForm.NumActivities, processForm.DriveId, processForm.DriveUrl));
            }], ["label", "Prosesskode/prefix (valgfritt)"], ["defaultValue", processForm.Prefix], ["size", "small"], ["variant", "outlined"]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append(singleton_1(["onChange", (e_1) => {
                const x_3 = Browser_Types_Event__Event_get_Value(e_1);
                updateForm(new ProjectPlan_ProjectProcess(processForm.Id, processForm.TemplateId, processForm.Prefix, x_3, processForm.Desc, processForm.RowPos, processForm.ParentId, processForm.Children, processForm.Activities, processForm.NumChildren, processForm.NumActivities, processForm.DriveId, processForm.DriveUrl));
                setValidName(x_3.length > 0);
            }]), delay(() => append(singleton_1(["label", "Prosessnavn"]), delay(() => append(singleton_1(["defaultValue", processForm.Name]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => ((!patternInput_1[0]) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Prosessnavn kan ikke være tomt"]))) : empty()))))))))))))))))))))), delay(() => append((processForm.Prefix.length > 0) ? singleton_1(createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                    marginLeft: 10,
                    fontStyle: "italic",
                },
                children: toText(printf("%s - %s"))(processForm.Prefix)(processForm.Name),
            })) : empty(), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["size", "small"], ["fullWidth", true], ["multiline", true], ["onChange", (e_2) => {
                updateForm(new ProjectPlan_ProjectProcess(processForm.Id, processForm.TemplateId, processForm.Prefix, processForm.Name, Browser_Types_Event__Event_get_Value(e_2), processForm.RowPos, processForm.ParentId, processForm.Children, processForm.Activities, processForm.NumChildren, processForm.NumActivities, processForm.DriveId, processForm.DriveUrl));
            }], ["label", "Beskrivelse"], ["defaultValue", processForm.Desc], ["variant", "outlined"]])), delay(() => singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["onChange", (e_3) => {
                const x_5 = Browser_Types_Event__Event_get_Value(e_3);
                try {
                    const asInt = parse(x_5, 511, false, 32) | 0;
                    updateForm(new ProjectPlan_ProjectProcess(processForm.Id, processForm.TemplateId, processForm.Prefix, processForm.Name, processForm.Desc, asInt, processForm.ParentId, processForm.Children, processForm.Activities, processForm.NumChildren, processForm.NumActivities, processForm.DriveId, processForm.DriveUrl));
                    setValidRowPos(asInt > 0);
                }
                catch (matchValue) {
                    setValidRowPos(false);
                }
            }]), delay(() => append(singleton_1(["label", "Radnummer"]), delay(() => append(singleton_1(["defaultValue", processForm.RowPos]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["inputProps", {
                style: {
                    textAlign: "center",
                    maxWidth: 80,
                },
            }]), delay(() => ((!validRowPos) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Ugyldig nummer. Oppgi ett nummer større eller lik 1."]))) : empty()))))))))))))))))))))))))))) : singleton_1((value_78 = ((updating == null) ? "Prosess lagt til" : "Prosess oppdatert"), createElement("span", {
                children: [value_78],
            }))), delay(() => singleton_1(errorBanner(patternInput_5[0], () => {
                setErrMsg(void 0);
            }))));
        })))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!patternInput_4[0]) ? append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg3) => {
        onClose(result);
    }]])), delay(() => ((result == null) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", (updating == null) ? "Legg til" : "Oppdater"], ["onClick", (_arg4) => {
        if ((processForm.Name.length > 0) ? validRowPos : false) {
            const x = processForm;
            setProcessing(true);
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (((updating == null) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ProjectPlan_ProjectProcess$reflection,
                })(x);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: ProjectPlan_ProjectProcess$reflection,
                });
                return Fetch_tryPost_5760677E("/api/project-plan/template/process", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: ProjectPlan_ProjectProcess$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })) : PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ProjectPlan_ProjectProcess$reflection,
                })(x);
                const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: ProjectPlan_ProjectProcess$reflection,
                });
                return Fetch_tryPut_5760677E("/api/project-plan/template/process", some(body_1), empty_1(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                    ResolveType: ProjectPlan_ProjectProcess$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            }))).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    setProcessing(false);
                    patternInput_3[1](res.fields[0]);
                    return Promise.resolve();
                }
                else {
                    setProcessing(false);
                    setErrMsg((updating == null) ? "Noe gikk galt. Kunne ikke legge til prosess" : "Noe gikk galt. Kunne ikke oppdatere prosess.");
                    return Promise.resolve();
                }
            })))));
            pr.then();
        }
        else {
            setValidName(false);
        }
    }]])) : empty()))) : singleton_1(loadingIndicatorSmall()))))))]])])]]);
}

function DeleteForm(deleteFormInputProps) {
    const onClose = deleteFormInputProps.onClose;
    const processId = deleteFormInputProps.processId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const result = patternInput[0];
    const setErrMsg = useFeliz_React__React_useState_Static_1505(void 0)[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Slett prosess"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!result) ? append(singleton_1(createElement("span", {
            style: {
                fontWeight: "bold",
                fontSize: 1.1 + "rem",
            },
            children: "Er du sikker på at du vil slette denne prosessen?",
        })), delay(() => singleton_1(createElement("p", {
            style: {
                marginTop: 10,
            },
            children: "Dette vil også slette alle eventuelle underprosesser, aktiviteter og tilhørende dokumenter. Dette påvirker kun malen, ikke eksisterende prosjekter.",
        })))) : singleton_1(createElement("span", {
            children: ["Prosess slettet"],
        }))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        onClose(result);
    }]])), delay(() => ((!result) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["color", "secondary"], ["children", "Slett"], ["onClick", (_arg3) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const requestPath = toText(printf("/api/project-plan/template/process/%i"))(processId);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: () => string_type,
                })("");
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryDelete_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    const x = res.fields[0];
                    if (x.Result === "success") {
                        patternInput[1](true);
                        return Promise.resolve();
                    }
                    else {
                        setErrMsg(x.Message);
                        return Promise.resolve();
                    }
                }
                else {
                    setErrMsg("Noe gikk galt. Kunne ikke slette prosess");
                    return Promise.resolve();
                }
            }));
        }));
        pr.then();
    }]])) : empty())))))))]])])]]);
}

function DrawProcess(drawProcessInputProps) {
    const onDelete = drawProcessInputProps.onDelete;
    const x = drawProcessInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505(x.Children);
    const setChildProcesses = patternInput[1];
    const childProcesses = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(x.Activities);
    const setActivities = patternInput_1[1];
    const activities = patternInput_1[0];
    const anchor = useReact_useRef_1505(void 0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505({
        activityForm: false,
        menuOpen: false,
        processForm: false,
        showDelete: false,
        showUpdate: false,
        this: x,
    });
    const updateState = patternInput_2[1];
    const state = patternInput_2[0];
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(state.processForm ? singleton_1(createElement(AddForm, {
            updating: void 0,
            parentId: x.Id,
            templateId: x.TemplateId,
            defaultRowPos: childProcesses.length + 1,
            onClose: (proc) => {
                if (proc != null) {
                    setChildProcesses(append_1([proc], childProcesses));
                }
                updateState({
                    activityForm: state.activityForm,
                    menuOpen: state.menuOpen,
                    processForm: false,
                    showDelete: state.showDelete,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
            },
        })) : empty(), delay(() => append(state.showUpdate ? singleton_1(createElement(AddForm, {
            updating: state.this,
            parentId: x.ParentId,
            templateId: x.TemplateId,
            defaultRowPos: state.this.RowPos,
            onClose: (u) => {
                if (u != null) {
                    updateState({
                        activityForm: state.activityForm,
                        menuOpen: state.menuOpen,
                        processForm: state.processForm,
                        showDelete: state.showDelete,
                        showUpdate: false,
                        this: u,
                    });
                }
                else {
                    updateState({
                        activityForm: state.activityForm,
                        menuOpen: state.menuOpen,
                        processForm: state.processForm,
                        showDelete: state.showDelete,
                        showUpdate: false,
                        this: state.this,
                    });
                }
            },
        })) : empty(), delay(() => append(state.showDelete ? singleton_1(createElement(DeleteForm, {
            processId: x.Id,
            onClose: (didDelete) => {
                updateState({
                    activityForm: state.activityForm,
                    menuOpen: state.menuOpen,
                    processForm: state.processForm,
                    showDelete: false,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
                if (didDelete) {
                    onDelete();
                }
            },
        })) : empty(), delay(() => append(state.activityForm ? singleton_1(createElement(Activity_AddForm, {
            updating: void 0,
            parentId: x.Id,
            defaultRowPos: activities.length + 1,
            onClose: (act) => {
                if (act != null) {
                    setActivities(append_1([act], activities));
                }
                updateState({
                    activityForm: false,
                    menuOpen: state.menuOpen,
                    processForm: state.processForm,
                    showDelete: state.showDelete,
                    showUpdate: state.showUpdate,
                    this: state.this,
                });
            },
        })) : empty(), delay(() => append(singleton_1(createElement("div", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((x.ParentId != null) ? singleton_1(createElement("div", {
                style: {
                    padding: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 25,
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    className: "fas fa-level-up-alt fa-rotate-90",
                })]),
            })) : empty(), delay(() => {
                let children_1;
                return singleton_1(createElement("div", {
                    style: {
                        backgroundColor: "#f1f1f1",
                        color: "#000000",
                        border: (((2 + "px ") + "solid") + " ") + "#ddd",
                        borderLeft: (((4 + "px ") + "solid") + " ") + "#1E90FF",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexGrow: 1,
                        padding: 5,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            fontSize: 1.2 + "rem",
                            fontWeight: "bold",
                        },
                        children: (state.this.Prefix.length > 0) ? toText(printf("%s - %s"))(state.this.Prefix)(state.this.Name) : state.this.Name,
                    }), (children_1 = ofArray([MuiHelpers_createElement(IconButton, [["size", "small"], ["ref", anchor], ["style", {
                        marginLeft: 20,
                    }], ["children", react.createElement(MoreHoriz, {})], ["onClick", (_arg1) => {
                        updateState({
                            activityForm: state.activityForm,
                            menuOpen: !state.menuOpen,
                            processForm: state.processForm,
                            showDelete: state.showDelete,
                            showUpdate: state.showUpdate,
                            this: state.this,
                        });
                    }]]), MuiHelpers_createElement(Menu, [["open", state.menuOpen], ["onClose", (_arg16, v) => {
                        updateState({
                            activityForm: state.activityForm,
                            menuOpen: false,
                            processForm: state.processForm,
                            showDelete: state.showDelete,
                            showUpdate: state.showUpdate,
                            this: state.this,
                        });
                    }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["children", "Legg til underprosess"], ["onClick", (_arg3) => {
                        updateState({
                            activityForm: state.activityForm,
                            menuOpen: false,
                            processForm: true,
                            showDelete: state.showDelete,
                            showUpdate: state.showUpdate,
                            this: state.this,
                        });
                    }]]), MuiHelpers_createElement(MenuItem, [["children", "Legg til aktivitet"], ["onClick", (_arg4) => {
                        updateState({
                            activityForm: true,
                            menuOpen: false,
                            processForm: state.processForm,
                            showDelete: state.showDelete,
                            showUpdate: state.showUpdate,
                            this: state.this,
                        });
                    }]]), MuiHelpers_createElement(MenuItem, [["children", "Endre prosess"], ["onClick", (_arg5) => {
                        updateState({
                            activityForm: state.activityForm,
                            menuOpen: false,
                            processForm: state.processForm,
                            showDelete: state.showDelete,
                            showUpdate: true,
                            this: state.this,
                        });
                    }]]), MuiHelpers_createElement(MenuItem, [["children", "Slett prosess"], ["onClick", (_arg6) => {
                        updateState({
                            activityForm: state.activityForm,
                            menuOpen: false,
                            processForm: state.processForm,
                            showDelete: true,
                            showUpdate: state.showUpdate,
                            this: state.this,
                        });
                    }]])])]])]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    }))]),
                }));
            })))))),
        })), delay(() => singleton_1(createElement("div", {
            style: createObj(toList(delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["flexDirection", "column"]), delay(() => ((x.ParentId != null) ? singleton_1(["marginLeft", 25]) : empty())))))))),
            children: Interop_reactApi.Children.toArray([map_1((a_2) => createElement(Activity_DrawActivity, {
                x: a_2,
                onDelete: () => {
                    setActivities(activities.filter((y_1) => (y_1.Id !== a_2.Id)));
                },
            }), sortBy((a_1) => a_1.RowPos, activities, {
                Compare: (x_1, y) => comparePrimitives(x_1, y),
            })), map_1((c_1) => createElement(DrawProcess, {
                x: c_1,
                onDelete: () => {
                    setChildProcesses(childProcesses.filter((y_3) => (y_3.Id !== c_1.Id)));
                },
            }), sortBy((c) => c.RowPos, childProcesses, {
                Compare: (x_2, y_2) => comparePrimitives(x_2, y_2),
            }))]),
        })))))))))))))))),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        className: "content-column",
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(4));
        })), delay(() => {
            const matchValue = model.Processes;
            if (matchValue != null) {
                const x = matchValue;
                return append(model.AddForm ? singleton_1(createElement(AddForm, {
                    updating: void 0,
                    parentId: void 0,
                    templateId: model.TemplateId,
                    defaultRowPos: x.length + 1,
                    onClose: (x_1) => {
                        if (x_1 != null) {
                            dispatch(new Message(2, x_1));
                        }
                        else {
                            dispatch(new Message(1));
                        }
                    },
                })) : empty(), delay(() => append(singleton_1(map_1((y_3) => createElement(DrawProcess, {
                    x: y_3,
                    onDelete: () => {
                        dispatch(new Message(3, y_3.Id));
                    },
                }), sortBy((y_1) => y_1.RowPos, x, {
                    Compare: (x_2, y_2) => comparePrimitives(x_2, y_2),
                }))), delay(() => singleton_1(createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "center",
                        width: 100 + "%",
                    },
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["children", react.createElement(Add, {})], ["onClick", (_arg2) => {
                        dispatch(new Message(1));
                    }]])]),
                }))))));
            }
            else {
                return singleton_1(MuiHelpers_createElement(CircularProgress, []));
            }
        })))))),
    });
}

export function ProjectPlan(projectPlanInputProps) {
    const templateId = projectPlanInputProps.templateId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(templateId));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchTemplate(templateId, dispatch);
    });
    return view(patternInput[0], dispatch);
}

export function TemplateForm(templateFormInputProps) {
    const onClose = templateFormInputProps.onClose;
    const updating = templateFormInputProps.updating;
    const patternInput = useFeliz_React__React_useState_Static_1505((updating != null) ? updating.Name : "");
    const name = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setError = patternInput_1[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", (updating == null) ? "Legg til kvalitetsplan mal" : "Oppdater kvalitetsplan mal"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 250,
            minHeight: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["autoFocus", true], ["fullWidth", true], ["defaultValue", name], ["onChange", (e) => {
            patternInput[1](Browser_Types_Event__Event_get_Value(e));
        }], ["size", "small"], ["variant", "outlined"], ["label", "Navn"]]), errorBanner(patternInput_1[0], () => {
            setError(void 0);
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg3) => {
        onClose(void 0);
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", name.trim().length === 0], ["children", "Opprett"], ["onClick", (_arg4) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let item_1, req;
            if (name.trim().length > 0) {
                return ((updating != null) ? ((item_1 = (new ProjectPlan_PlanTemplate(updating.Id, name)), PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                        ResolveType: ProjectPlan_PlanTemplate$reflection,
                    })(item_1);
                    const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: ProjectPlan_PlanTemplate$reflection,
                    });
                    return Fetch_tryPut_5760677E("/api/project-plan/template", some(body_1), empty_1(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                        ResolveType: ProjectPlan_PlanTemplate$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                })))) : ((req = (new ProjectPlan_PlanTemplate(-1, name)), PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                        ResolveType: ProjectPlan_PlanTemplate$reflection,
                    })(req);
                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: ProjectPlan_PlanTemplate$reflection,
                    });
                    return Fetch_tryPost_5760677E("/api/project-plan/template", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                        ResolveType: ProjectPlan_PlanTemplate$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                }))))).then(((_arg1) => {
                    const res = _arg1;
                    if (res.tag === 1) {
                        if (updating == null) {
                            setError("Kunne ikke opprette mal");
                            return Promise.resolve();
                        }
                        else {
                            setError("Kunne ikke oppdatere mal");
                            return Promise.resolve();
                        }
                    }
                    else {
                        onClose(res.fields[0]);
                        return Promise.resolve();
                    }
                }));
            }
            else {
                setError("Ugyldig navn");
                return Promise.resolve();
            }
        }));
        pr.then();
    }]])])]])])]]);
}

export function Templates() {
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const templates = patternInput[0];
    const setTemplates = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setSelected = patternInput_1[1];
    const selectedTemplate = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setAddForm = patternInput_2[1];
    const addForm = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const updateForm = patternInput_3[0];
    const setUpdateForm = patternInput_3[1];
    useReact_useEffectOnce_3A5B6456(() => {
        ProjectPlan_fetchTemplates((res) => {
            if (res.tag === 0) {
                setTemplates(res.fields[0]);
            }
        });
    });
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(addForm ? singleton_1(createElement(TemplateForm, {
            updating: void 0,
            onClose: (t) => {
                if (t != null) {
                    const added = t;
                    setTemplates((templates != null) ? append_1([added], templates) : [added]);
                    setSelected(added);
                }
                setAddForm(false);
            },
        })) : empty(), delay(() => {
            let uf;
            return append((updateForm != null) ? ((uf = updateForm, singleton_1(createElement(TemplateForm, {
                updating: uf,
                onClose: (t_1) => {
                    if (t_1 != null) {
                        const updated = t_1;
                        setTemplates((templates != null) ? map_1((z) => {
                            if (z.Id === updated.Id) {
                                return updated;
                            }
                            else {
                                return z;
                            }
                        }, templates) : [updated]);
                    }
                    setUpdateForm(void 0);
                },
            })))) : ((empty())), delay(() => append(singleton_1(createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    if (templates != null) {
                        const t_2 = templates;
                        return singleton_1(createElement("div", {
                            style: {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            },
                            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControl, [["style", {
                                minWidth: 200,
                                marginBottom: 10,
                            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Velg mal"]]), MuiHelpers_createElement(Select, toList(delay(() => append(singleton_1(["onChange", (e, _arg24) => {
                                const y_2 = Browser_Types_Event__Event_get_Value(e);
                                const _arg1_1 = tryFind((z_1) => (z_1.Id === y_2), t_2);
                                if (_arg1_1 != null) {
                                    setSelected(_arg1_1);
                                }
                            }]), delay(() => append(singleton_1(["displayEmpty", true]), delay(() => append((selectedTemplate != null) ? singleton_1(["value", selectedTemplate.Id]) : ((empty())), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([map_1((y_3) => MuiHelpers_createElement(MenuItem, [["value", y_3.Id], ["children", y_3.Name]]), t_2)])]))))))))))])]]), createElement("div", {
                                style: {
                                    minWidth: 150,
                                    minHeight: 50,
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: 10,
                                },
                                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Add, {})], ["onClick", (_arg2) => {
                                    setAddForm(!addForm);
                                }]])), delay(() => {
                                    if (selectedTemplate != null) {
                                        const s = selectedTemplate;
                                        return singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Endre navn"], ["style", {
                                            marginLeft: 10,
                                        }], ["onClick", (_arg3) => {
                                            setUpdateForm(s);
                                        }]]));
                                    }
                                    else {
                                        return empty();
                                    }
                                })))))),
                            })]),
                        }));
                    }
                    else {
                        return singleton_1(loadingIndicatorSmall());
                    }
                })))),
            })), delay(() => {
                if (selectedTemplate == null) {
                    return singleton_1(createElement("div", {
                        style: {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: 200,
                            width: 100 + "%",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: "Ingen mal valgt",
                        })]),
                    }));
                }
                else {
                    const s_1 = selectedTemplate;
                    return singleton_1(createElement("div", {
                        key: s_1.Id,
                        children: Interop_reactApi.Children.toArray([createElement(ProjectPlan, {
                            templateId: s_1.Id,
                        })]),
                    }));
                }
            }))));
        })))))),
    });
}

