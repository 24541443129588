import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, string_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ProcessModule_Process$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { sortBy, map } from "../.fable/fable-library.3.2.4/Array.js";
import { snackbarError, PageElements_filenameToIcon } from "../ViewHelpers.js";
import { saveManagementPrefAutoExpand, loadManagementPref } from "../LocalStorage.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

class Model extends Record {
    constructor(ProcessId, Processes, ErrorMsg) {
        super();
        this.ProcessId = (ProcessId | 0);
        this.Processes = Processes;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ProcessTree.Model", [], Model, () => [["ProcessId", int32_type], ["Processes", option_type(array_type(ProcessModule_Process$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProcessesResponse", "DismissError", "SetError"];
    }
}

function Message$reflection() {
    return union_type("ProcessTree.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProcessModule_Process$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProcessModule_Process$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", string_type]]]);
}

function init(x) {
    return new Model(x, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.ProcessId, model.Processes, void 0);
        }
        case 2: {
            return new Model(model.ProcessId, model.Processes, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProcessId, model.Processes, "Noe gikk galt. Kunne ikke laste prosesser");
            }
            else {
                return new Model(model.ProcessId, res.fields[0], model.ErrorMsg);
            }
        }
    }
}

function fetchTree(processId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/management/%i"))(processId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProcessModule_Process$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProcessModule_Process$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

export function DrawActivityTreeEntry(x) {
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setOpen = patternInput[1];
    const isOpen = patternInput[0];
    return createElement("div", {
        style: {
            marginTop: 10,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: createObj(toList(delay(() => append(singleton(["cursor", "pointer"]), delay(() => append(singleton(["display", "flex"]), delay(() => append(singleton(["alignItems", "center"]), delay(() => ((x.NumDocs === 0) ? singleton(["paddingLeft", 24]) : empty_1())))))))))),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                onClick: (_arg1) => {
                    setOpen(!isOpen);
                },
                style: {
                    display: "flex",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((x.NumDocs > 0) ? (isOpen ? singleton(react.createElement(ExpandMore, {})) : singleton(react.createElement(ChevronRight, {}))) : empty_1()))))),
            }), createElement("span", {
                onClick: (_arg2) => {
                    setOpen(!isOpen);
                },
                style: {
                    color: "#1E90FF",
                },
                children: (x.RowPrefix.length > 0) ? toText(printf("%s - %s"))(x.RowPrefix)(x.Name) : x.Name,
            })]),
        }), createElement("div", {
            style: {
                marginLeft: 12,
                borderLeft: (((1 + "px ") + "dashed") + " ") + "#aaa",
                paddingLeft: 10,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                if (!isOpen) {
                    return empty_1();
                }
                else {
                    return (x.NumDocs > 0) ? singleton(map((y) => createElement("div", {
                        style: {
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 24,
                            marginTop: 5,
                        },
                        children: Interop_reactApi.Children.toArray([createElement("a", {
                            href: toText(printf("/document/%i"))(y.Id),
                            children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(y.Filename), createElement("span", {
                                style: {
                                    color: "#000080",
                                },
                                children: (y.RowPrefix.length > 0) ? toText(printf("%s - %s"))(y.RowPrefix)(y.Name) : y.Name,
                            })]),
                        })]),
                    }), x.Docs)) : empty_1();
                }
            })))),
        })]),
    });
}

function DrawSubTree(drawSubTreeInputProps) {
    const autoExpand = drawSubTreeInputProps.autoExpand;
    const x = drawSubTreeInputProps.x;
    const dispatch = drawSubTreeInputProps.dispatch;
    const patternInput = useFeliz_React__React_useState_Static_1505(autoExpand);
    const setOpen = patternInput[1];
    const isOpen = patternInput[0];
    return createElement("div", {
        style: {
            marginTop: 10,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const hasChildren = x.NumChildren > 0;
            const hasActivities = x.NumActivities > 0;
            return append(singleton(createElement("div", {
                style: createObj(toList(delay(() => append(singleton(["cursor", "pointer"]), delay(() => append(singleton(["display", "flex"]), delay(() => append(singleton(["alignItems", "center"]), delay(() => (((!hasChildren) ? (!hasActivities) : false) ? singleton(["paddingLeft", 24]) : empty_1())))))))))),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    onClick: (_arg1) => {
                        setOpen(!isOpen);
                    },
                    style: {
                        display: "flex",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((hasChildren ? true : hasActivities) ? (isOpen ? singleton(react.createElement(ExpandMore, {})) : singleton(react.createElement(ChevronRight, {}))) : empty_1()))))),
                }), createElement("span", {
                    onClick: (_arg2) => {
                        setOpen(!isOpen);
                    },
                    children: (x.RowPrefix.length > 0) ? toText(printf("%s - %s"))(x.RowPrefix)(x.Name) : x.Name,
                })]),
            })), delay(() => singleton(createElement("div", {
                style: {
                    marginLeft: 12,
                    borderLeft: (((1 + "px ") + "dashed") + " ") + "#aaa",
                    paddingLeft: 10,
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    if (!isOpen) {
                        return empty_1();
                    }
                    else {
                        return append(singleton(map((a_1) => createElement("div", {
                            key: toText(printf("activity-%i"))(a_1.Id),
                            children: Interop_reactApi.Children.toArray([createElement(DrawActivityTreeEntry, a_1)]),
                        }), sortBy((a) => a.RowPos, x.Activities, {
                            Compare: (x_1, y) => comparePrimitives(x_1, y),
                        }))), delay(() => singleton(map((y_3) => createElement("div", {
                            key: toText(printf("process-%i"))(y_3.Id),
                            children: Interop_reactApi.Children.toArray([createElement(DrawSubTree, {
                                dispatch: dispatch,
                                x: y_3,
                                autoExpand: autoExpand,
                            })]),
                        }), sortBy((y_1) => y_1.RowPos, x.Children, {
                            Compare: (x_2, y_2) => comparePrimitives(x_2, y_2),
                        })))));
                    }
                })))),
            }))));
        })))),
    });
}

function view(model, dispatch) {
    let matchValue;
    const patternInput = useFeliz_React__React_useState_Static_1505((matchValue = loadManagementPref(), (matchValue.tag === 1) ? false : matchValue.fields[0].AutoExpand));
    const autoExpand = patternInput[0];
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(1));
        })), delay(() => {
            const matchValue_1 = model.Processes;
            if (matchValue_1 != null) {
                const x_1 = matchValue_1;
                return singleton(createElement("div", {
                    style: {
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 50,
                        padding: 20,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        style: {
                            display: "flex",
                            justifyContent: "flex-end",
                        },
                        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControlLabel, [["label", "Automatisk åpne alle prosesser"], ["control", MuiHelpers_createElement(Switch, [["color", "primary"], ["size", "small"], ["checked", autoExpand], ["onChange", (ev) => {
                            const x_2 = ev.target.checked;
                            saveManagementPrefAutoExpand(x_2);
                            patternInput[1](x_2);
                        }]])]]), MuiHelpers_createElement(Tooltip, [["title", "Når slått på vil hele prosess-treet automatisk brettes ut når du laster denne siden."], ["children", createElement("span", {
                            className: "icon-button fas fa-question-circle",
                        })]])]),
                    }), map((y_2) => createElement("div", {
                        key: toText(printf("process-%i"))(y_2.Id),
                        children: Interop_reactApi.Children.toArray([createElement(DrawSubTree, {
                            dispatch: dispatch,
                            x: y_2,
                            autoExpand: autoExpand,
                        })]),
                    }), sortBy((y) => y.RowPos, x_1, {
                        Compare: (x_3, y_1) => comparePrimitives(x_3, y_1),
                    }))]),
                }));
            }
            else {
                return singleton(MuiHelpers_createElement(CircularProgress, []));
            }
        })))))),
    });
}

export function ProcessTree(processTreeInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(processTreeInputProps.processId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchTree(model_1.ProcessId, dispatch);
    });
    return view(model_1, dispatch);
}

