import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, option_type, int32_type, union_type, string_type, bool_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Waste_Schema$reflection, Waste_SimpleSchema, Helpers_ResultMessage$reflection, Waste_SimpleSchema$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { empty as empty_1, singleton, append, delay, toList, fold } from "../.fable/fable-library.3.2.4/Seq.js";
import { some, toArray } from "../.fable/fable-library.3.2.4/Option.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { format, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { sortBy, map } from "../.fable/fable-library.3.2.4/Array.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import { loadingIndicatorMedium } from "../ViewHelpers.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";

class GenerationStep extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Waiting", "Failed", "Success"];
    }
}

function GenerationStep$reflection() {
    return union_type("WastePDF.GenerationStep", [], GenerationStep, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", string_type]]]);
}

class Model extends Record {
    constructor(ProjectId, Schema, Step, ErrorMsg) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Schema = Schema;
        this.Step = Step;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("WastePDF.Model", [], Model, () => [["ProjectId", int32_type], ["Schema", option_type(Waste_SimpleSchema$reflection())], ["Step", GenerationStep$reflection()], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SchemaResponse", "UpdateGnr", "UpdateBnr", "UpdateFnr", "UpdateSnr", "UpdateByggnr", "UpdateBolignr", "UpdateAddress", "UpdatePostalCode", "UpdatePostalAddress", "UpdateCounty", "UpdateRemaining", "UpdateName", "UpdatePhone", "UpdateEmail", "UpdateDate", "UpdateCompany", "Generate", "SetStep", "GenerateResponse"];
    }
}

function Message$reflection() {
    return union_type("WastePDF.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Waste_SimpleSchema$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Waste_SimpleSchema$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", GenerationStep$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init(x) {
    return new Model(x, void 0, new GenerationStep(0, false), void 0);
}

function upd$0027(model, f$0027) {
    return new Model(model.ProjectId, fold((_arg1, z) => f$0027(z), void 0, toArray(model.Schema)), model.Step, model.ErrorMsg);
}

function update(model, msg) {
    switch (msg.tag) {
        case 2: {
            return upd$0027(model, (y) => (new Waste_SimpleSchema(y.Gnr, msg.fields[0], y.Fnr, y.Snr, y.Byggnr, y.Bolignr, y.Address, y.ContactName, y.Phone, y.Email, y.PostalCode, y.PostalAddress, y.County, y.MeterSqrt, y.Entries, y.Remaining, y.Company, y.Date)));
        }
        case 1: {
            return upd$0027(model, (y_1) => (new Waste_SimpleSchema(msg.fields[0], y_1.Bnr, y_1.Fnr, y_1.Snr, y_1.Byggnr, y_1.Bolignr, y_1.Address, y_1.ContactName, y_1.Phone, y_1.Email, y_1.PostalCode, y_1.PostalAddress, y_1.County, y_1.MeterSqrt, y_1.Entries, y_1.Remaining, y_1.Company, y_1.Date)));
        }
        case 3: {
            return upd$0027(model, (y_2) => (new Waste_SimpleSchema(y_2.Gnr, y_2.Bnr, msg.fields[0], y_2.Snr, y_2.Byggnr, y_2.Bolignr, y_2.Address, y_2.ContactName, y_2.Phone, y_2.Email, y_2.PostalCode, y_2.PostalAddress, y_2.County, y_2.MeterSqrt, y_2.Entries, y_2.Remaining, y_2.Company, y_2.Date)));
        }
        case 4: {
            return upd$0027(model, (y_3) => (new Waste_SimpleSchema(y_3.Gnr, y_3.Bnr, y_3.Fnr, msg.fields[0], y_3.Byggnr, y_3.Bolignr, y_3.Address, y_3.ContactName, y_3.Phone, y_3.Email, y_3.PostalCode, y_3.PostalAddress, y_3.County, y_3.MeterSqrt, y_3.Entries, y_3.Remaining, y_3.Company, y_3.Date)));
        }
        case 5: {
            return upd$0027(model, (y_4) => (new Waste_SimpleSchema(y_4.Gnr, y_4.Bnr, y_4.Fnr, y_4.Snr, msg.fields[0], y_4.Bolignr, y_4.Address, y_4.ContactName, y_4.Phone, y_4.Email, y_4.PostalCode, y_4.PostalAddress, y_4.County, y_4.MeterSqrt, y_4.Entries, y_4.Remaining, y_4.Company, y_4.Date)));
        }
        case 6: {
            return upd$0027(model, (y_5) => (new Waste_SimpleSchema(y_5.Gnr, y_5.Bnr, y_5.Fnr, y_5.Snr, y_5.Byggnr, msg.fields[0], y_5.Address, y_5.ContactName, y_5.Phone, y_5.Email, y_5.PostalCode, y_5.PostalAddress, y_5.County, y_5.MeterSqrt, y_5.Entries, y_5.Remaining, y_5.Company, y_5.Date)));
        }
        case 7: {
            return upd$0027(model, (y_6) => (new Waste_SimpleSchema(y_6.Gnr, y_6.Bnr, y_6.Fnr, y_6.Snr, y_6.Byggnr, y_6.Bolignr, msg.fields[0], y_6.ContactName, y_6.Phone, y_6.Email, y_6.PostalCode, y_6.PostalAddress, y_6.County, y_6.MeterSqrt, y_6.Entries, y_6.Remaining, y_6.Company, y_6.Date)));
        }
        case 8: {
            return upd$0027(model, (y_7) => (new Waste_SimpleSchema(y_7.Gnr, y_7.Bnr, y_7.Fnr, y_7.Snr, y_7.Byggnr, y_7.Bolignr, y_7.Address, y_7.ContactName, y_7.Phone, y_7.Email, msg.fields[0], y_7.PostalAddress, y_7.County, y_7.MeterSqrt, y_7.Entries, y_7.Remaining, y_7.Company, y_7.Date)));
        }
        case 9: {
            return upd$0027(model, (y_8) => (new Waste_SimpleSchema(y_8.Gnr, y_8.Bnr, y_8.Fnr, y_8.Snr, y_8.Byggnr, y_8.Bolignr, y_8.Address, y_8.ContactName, y_8.Phone, y_8.Email, y_8.PostalCode, msg.fields[0], y_8.County, y_8.MeterSqrt, y_8.Entries, y_8.Remaining, y_8.Company, y_8.Date)));
        }
        case 10: {
            return upd$0027(model, (y_9) => (new Waste_SimpleSchema(y_9.Gnr, y_9.Bnr, y_9.Fnr, y_9.Snr, y_9.Byggnr, y_9.Bolignr, y_9.Address, y_9.ContactName, y_9.Phone, y_9.Email, y_9.PostalCode, y_9.PostalAddress, msg.fields[0], y_9.MeterSqrt, y_9.Entries, y_9.Remaining, y_9.Company, y_9.Date)));
        }
        case 11: {
            return upd$0027(model, (y_10) => (new Waste_SimpleSchema(y_10.Gnr, y_10.Bnr, y_10.Fnr, y_10.Snr, y_10.Byggnr, y_10.Bolignr, y_10.Address, y_10.ContactName, y_10.Phone, y_10.Email, y_10.PostalCode, y_10.PostalAddress, y_10.County, y_10.MeterSqrt, y_10.Entries, msg.fields[0], y_10.Company, y_10.Date)));
        }
        case 12: {
            return upd$0027(model, (y_11) => (new Waste_SimpleSchema(y_11.Gnr, y_11.Bnr, y_11.Fnr, y_11.Snr, y_11.Byggnr, y_11.Bolignr, y_11.Address, msg.fields[0], y_11.Phone, y_11.Email, y_11.PostalCode, y_11.PostalAddress, y_11.County, y_11.MeterSqrt, y_11.Entries, y_11.Remaining, y_11.Company, y_11.Date)));
        }
        case 13: {
            return upd$0027(model, (y_12) => (new Waste_SimpleSchema(y_12.Gnr, y_12.Bnr, y_12.Fnr, y_12.Snr, y_12.Byggnr, y_12.Bolignr, y_12.Address, y_12.ContactName, msg.fields[0], y_12.Email, y_12.PostalCode, y_12.PostalAddress, y_12.County, y_12.MeterSqrt, y_12.Entries, y_12.Remaining, y_12.Company, y_12.Date)));
        }
        case 14: {
            return upd$0027(model, (y_13) => (new Waste_SimpleSchema(y_13.Gnr, y_13.Bnr, y_13.Fnr, y_13.Snr, y_13.Byggnr, y_13.Bolignr, y_13.Address, y_13.ContactName, y_13.Phone, msg.fields[0], y_13.PostalCode, y_13.PostalAddress, y_13.County, y_13.MeterSqrt, y_13.Entries, y_13.Remaining, y_13.Company, y_13.Date)));
        }
        case 15: {
            return upd$0027(model, (y_14) => (new Waste_SimpleSchema(y_14.Gnr, y_14.Bnr, y_14.Fnr, y_14.Snr, y_14.Byggnr, y_14.Bolignr, y_14.Address, y_14.ContactName, y_14.Phone, y_14.Email, y_14.PostalCode, y_14.PostalAddress, y_14.County, y_14.MeterSqrt, y_14.Entries, y_14.Remaining, y_14.Company, msg.fields[0])));
        }
        case 16: {
            return upd$0027(model, (y_15) => (new Waste_SimpleSchema(y_15.Gnr, y_15.Bnr, y_15.Fnr, y_15.Snr, y_15.Byggnr, y_15.Bolignr, y_15.Address, y_15.ContactName, y_15.Phone, y_15.Email, y_15.PostalCode, y_15.PostalAddress, y_15.County, y_15.MeterSqrt, y_15.Entries, y_15.Remaining, msg.fields[0], y_15.Date)));
        }
        case 17: {
            return new Model(model.ProjectId, model.Schema, new GenerationStep(0, true), void 0);
        }
        case 18: {
            return new Model(model.ProjectId, model.Schema, msg.fields[0], model.ErrorMsg);
        }
        case 19: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.ProjectId, model.Schema, new GenerationStep(1, "Noe gikk galt. Kunne ikke generere sluttrapport."), model.ErrorMsg);
            }
            else {
                const x_18 = res_1.fields[0];
                if (x_18.Result === "success") {
                    return new Model(model.ProjectId, model.Schema, new GenerationStep(2, x_18.Message), model.ErrorMsg);
                }
                else {
                    return new Model(model.ProjectId, model.Schema, new GenerationStep(1, x_18.Message), model.ErrorMsg);
                }
            }
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProjectId, model.Schema, model.Step, "Noe gikk galt. Kunne ikke laste prosjektdata.");
            }
            else {
                return new Model(model.ProjectId, res.fields[0], model.Step, model.ErrorMsg);
            }
        }
    }
}

function fetchPreSchema(projId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/waste/schema/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Waste_SimpleSchema$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Waste_SimpleSchema$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
}

function generate(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Waste_Schema$reflection,
        })(x);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_tryPost_5760677E("/api/waste/schema", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(19, _arg1));
        return Promise.resolve();
    })))));
}

function sectionHeader(title) {
    return createElement("div", {
        style: {
            padding: 5 + "px",
            backgroundColor: "#eee",
            marginTop: 25 + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            style: {
                fontWeight: "bold",
            },
            children: title,
        })]),
    });
}

function hdr$0027(title) {
    return createElement("span", {
        style: {
            fontWeight: "bold",
            fontSize: 0.8 + "rem",
        },
        children: title,
    });
}

function sectionHolder(children) {
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function input$0027(dflt, mw, onChange) {
    return createElement("input", {
        type: "text",
        defaultValue: dflt,
        className: "print-input",
        style: {
            maxWidth: mw + "px",
        },
        onChange: (ev) => {
            onChange(ev.target.value);
        },
    });
}

function box$0027(children) {
    return createElement("div", {
        style: {
            textAlign: "left",
            margin: 5,
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function drawInfo(dispatch, x) {
    return sectionHolder([box$0027([hdr$0027("Gårdsnummer"), input$0027(x.Gnr, 150, (arg) => {
        dispatch(new Message(1, arg));
    })]), box$0027([hdr$0027("Bruksnummer"), input$0027(x.Bnr, 150, (arg_1) => {
        dispatch(new Message(2, arg_1));
    })]), box$0027([hdr$0027("Festenummer"), input$0027(x.Fnr, 150, (arg_2) => {
        dispatch(new Message(3, arg_2));
    })]), box$0027([hdr$0027("Seksjonsnummer"), input$0027(x.Snr, 150, (arg_3) => {
        dispatch(new Message(4, arg_3));
    })]), box$0027([hdr$0027("Bygningsnummer"), input$0027(x.Byggnr, 150, (arg_4) => {
        dispatch(new Message(5, arg_4));
    })]), box$0027([hdr$0027("Bolignummer"), input$0027(x.Bolignr, 150, (arg_5) => {
        dispatch(new Message(6, arg_5));
    })]), box$0027([hdr$0027("Kommune"), input$0027(x.County, 300, (arg_6) => {
        dispatch(new Message(10, arg_6));
    })]), box$0027([hdr$0027("Adresse"), input$0027(x.Address, 300, (arg_7) => {
        dispatch(new Message(7, arg_7));
    })]), box$0027([hdr$0027("Postnummer"), input$0027(x.PostalCode, 300, (arg_8) => {
        dispatch(new Message(8, arg_8));
    })]), box$0027([hdr$0027("Poststed"), input$0027(x.PostalAddress, 300, (arg_9) => {
        dispatch(new Message(9, arg_9));
    })])]);
}

function Row$0027(x) {
    let children, matchValue, p, children_2, children_4, children_6, children_8, copyOfStruct;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const updateValidPlanned = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const updateValidQuantity = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const updateValidRecycle = patternInput_2[1];
    const children_10 = ofArray([createElement("td", {
        style: {
            width: 250 + "px",
            fontSize: 0.8 + "rem",
        },
        children: toText(printf("%s %s"))(x.Code)(x.Name),
    }), (children = singleton_1(createElement("input", {
        style: createObj(toList(delay(() => append(singleton(["textAlign", "center"]), delay(() => append((!patternInput[0]) ? singleton(["color", "red"]) : empty_1(), delay(() => singleton(["minWidth", 80])))))))),
        defaultValue: (matchValue = x.Planned, (matchValue != null) ? ((p = matchValue, format('{0:' + "0.000" + '}', p))) : ""),
        className: "form-control print-input",
        onChange: (ev) => {
            const e = ev.target.value;
            try {
                updateValidPlanned(true);
            }
            catch (matchValue_1) {
                updateValidPlanned(false);
            }
        },
    })), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = singleton_1(createElement("input", {
        style: createObj(toList(delay(() => append(singleton(["textAlign", "center"]), delay(() => append((!patternInput_1[0]) ? singleton(["color", "red"]) : empty_1(), delay(() => singleton(["minWidth", 80])))))))),
        defaultValue: format('{0:' + "0.000" + '}', x.Quantity),
        className: "form-control print-input",
        onChange: (ev_1) => {
            const e_1 = ev_1.target.value;
            try {
                updateValidQuantity(true);
            }
            catch (matchValue_2) {
                updateValidQuantity(false);
            }
        },
    })), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = singleton_1(createElement("input", {
        style: {
            textAlign: "center",
            minWidth: 80,
        },
        defaultValue: x.Location,
        className: "form-control print-input",
    })), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = singleton_1(createElement("input", {
        style: createObj(toList(delay(() => append(singleton(["textAlign", "center"]), delay(() => append((!patternInput_2[0]) ? singleton(["color", "red"]) : empty_1(), delay(() => singleton(["minWidth", 80])))))))),
        defaultValue: format('{0:' + "0.000" + '}', x.Recycle),
        className: "form-control print-input",
        onChange: (ev_2) => {
            const e_2 = ev_2.target.value;
            try {
                updateValidRecycle(true);
            }
            catch (matchValue_3) {
                updateValidRecycle(false);
            }
        },
    })), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    })), (children_8 = singleton_1(createElement("input", {
        style: {
            textAlign: "center",
            minWidth: 80,
        },
        defaultValue: x.RecycleLocation,
        className: "form-control print-input",
    })), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    })), createElement("td", {
        style: {
            textAlign: "center",
        },
        children: (copyOfStruct = (x.Quantity + x.Recycle), format('{0:' + "0.000" + '}', copyOfStruct)),
    })]);
    return createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    });
}

function tableHeader() {
    const children = singleton_1(createElement("tr", {
        style: {
            fontSize: 0.8 + "rem",
            textAlign: "center",
        },
        children: Interop_reactApi.Children.toArray([createElement("th", {
            style: {
                maxWidth: 250 + "px",
                textAlign: "left",
            },
            children: "Avfallstype",
        }), createElement("th", {
            children: ["Planlagt"],
        }), createElement("th", {
            children: ["Til godkj. avfallsanlegg"],
        }), createElement("th", {
            children: ["Leveringssted"],
        }), createElement("th", {
            children: ["Direkte til ombruk/gjenvinning"],
        }), createElement("th", {
            children: ["Leveringssted"],
        }), createElement("th", {
            style: {
                width: 80 + "px",
            },
            children: "Faktisk mengde",
        })]),
    }));
    return createElement("thead", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function drawOrdinaryWaste(x) {
    let children;
    return sectionHolder([createElement("table", {
        className: "table",
        children: Interop_reactApi.Children.toArray([tableHeader(), (children = singleton_1(map((x_1) => createElement(Row$0027, x_1), x)), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))]),
    })]);
}

function drawDangerousWaste(x) {
    let children;
    return sectionHolder([createElement("table", {
        className: "table",
        children: Interop_reactApi.Children.toArray([tableHeader(), (children = singleton_1(map((x_2) => createElement(Row$0027, x_2), sortBy((y) => y.Code, x, {
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        }))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))]),
    })]);
}

function drawMixed(x) {
    let children;
    return sectionHolder([createElement("table", {
        className: "table",
        children: Interop_reactApi.Children.toArray([tableHeader(), (children = singleton_1(map((x_2) => createElement(Row$0027, x_2), sortBy((y) => y.Code, x, {
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        }))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))]),
    })]);
}

function drawContact(model, dispatch, x) {
    return sectionHolder([box$0027([hdr$0027("Foretak"), input$0027(x.Company, 300, (arg) => {
        dispatch(new Message(16, arg));
    })]), box$0027([hdr$0027("Navn"), input$0027(x.ContactName, 300, (arg_1) => {
        dispatch(new Message(12, arg_1));
    })]), box$0027([hdr$0027("Telefon"), input$0027(x.Phone, 150, (arg_2) => {
        dispatch(new Message(13, arg_2));
    })]), box$0027([hdr$0027("E-post"), input$0027(x.Email, 300, (arg_3) => {
        dispatch(new Message(14, arg_3));
    })]), box$0027([hdr$0027("Dato"), input$0027(x.Date, 150, (arg_4) => {
        dispatch(new Message(15, arg_4));
    })])]);
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            padding: 2 + "vw",
            display: "flex",
            justifyContent: "center",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                maxWidth: 1200,
            },
            className: "section-to-print @page",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("h3", {
                style: {
                    textAlign: "center",
                },
                children: "Sluttrapport for avfall",
            })), delay(() => {
                const matchValue = model.Schema;
                if (matchValue != null) {
                    const schema = matchValue;
                    const ordinary = schema.Entries.filter((y) => {
                        if (y.Sorted) {
                            return !y.Dangerous;
                        }
                        else {
                            return false;
                        }
                    });
                    const dangerous = schema.Entries.filter((y_1) => y_1.Dangerous);
                    const mixed = schema.Entries.filter((y_2) => {
                        if (!y_2.Sorted) {
                            return !y_2.Dangerous;
                        }
                        else {
                            return false;
                        }
                    });
                    return append(singleton(sectionHeader("Rapporten gjelder")), delay(() => append(singleton(drawInfo(dispatch, schema)), delay(() => append((ordinary.length > 0) ? append(singleton(sectionHeader("Ordinært avfall")), delay(() => singleton(drawOrdinaryWaste(ordinary)))) : empty_1(), delay(() => append((dangerous.length > 0) ? append(singleton(sectionHeader("Farlig avfall")), delay(() => singleton(drawDangerousWaste(dangerous)))) : empty_1(), delay(() => append((mixed.length > 0) ? append(singleton(sectionHeader("Blandet avfall/restavfall")), delay(() => singleton(drawMixed(mixed)))) : empty_1(), delay(() => append(singleton(sectionHeader("Gjenstående avfall")), delay(() => append(singleton(box$0027([createElement("textarea", {
                        defaultValue: schema.Remaining,
                        className: "form-control print-input",
                        onChange: (ev) => {
                            dispatch(new Message(11, ev.target.value));
                        },
                    })])), delay(() => append(singleton(sectionHeader("Kontaktperson")), delay(() => append(singleton(drawContact(model, dispatch, schema)), delay(() => singleton(createElement("div", {
                        className: "non-print-section",
                        children: Interop_reactApi.Children.toArray([sectionHeader("Generer sluttrapport PDF"), createElement("div", {
                            style: {
                                padding: 5 + "px",
                                textAlign: "center",
                                marginTop: 20 + "px",
                            },
                            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Skriv ut"], ["onClick", (_arg1) => {
                                window.print();
                            }]])]),
                        })]),
                    }))))))))))))))))))));
                }
                else {
                    return singleton(createElement("div", {
                        style: {
                            textAlign: "center",
                        },
                        children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
                    }));
                }
            })))))),
        })]),
    });
}

export function wastePDF(x) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.projectId));
        const dispatch = patternInput[1];
        useReact_useEffect_Z101E1A95(() => {
            const pr = fetchPreSchema(props.projectId, dispatch);
            pr.then();
        }, []);
        return view(patternInput[0], dispatch);
    }, void 0, uncurry(2, void 0), void 0, "wastePDF", "/builds/serit/derigo/tenant/src/Client/Waste/WastePDF.fs", 584)({
        projectId: x,
    });
}

