import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Role, DeleteResponse$reflection, Role$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, bool_type, string_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { sortBy, append, map } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { int32ToString, compareArrays, createObj, equals, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { genericDeleteDialog, snackbarError, insertMaterialTableNorwegianLocalization, loadingIndicatorSmall } from "../ViewHelpers.js";
import { StyleImports_useTheme } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { MuiHelpers_createElement, MuiHelpers_reactElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import material$002Dtable from "material-table";
import { RoleCategory, RoleCategory_AsArray, RoleCategory__ToInt } from "../Shared/Shared.js";
import Icon from "@material-ui/core/Icon";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import NativeSelect from "@material-ui/core/NativeSelect";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";

class Model extends Record {
    constructor(Entries, ErrorMsg, AddForm, DelForm) {
        super();
        this.Entries = Entries;
        this.ErrorMsg = ErrorMsg;
        this.AddForm = AddForm;
        this.DelForm = DelForm;
    }
}

function Model$reflection() {
    return record_type("Role.Model", [], Model, () => [["Entries", option_type(array_type(Role$reflection()))], ["ErrorMsg", option_type(string_type)], ["AddForm", bool_type], ["DelForm", option_type(Role$reflection())]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EntriesResponse", "UpdateResponse", "AddResponse", "RemoveResponse", "SetError", "DismissError", "ToggleAddForm", "SetDelForm"];
    }
}

function Message$reflection() {
    return union_type("Role.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Role$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Role$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Role$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Role$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Role$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Role$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DeleteResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", DeleteResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [], [], [["Item", option_type(Role$reflection())]]]);
}

function init() {
    return new Model(void 0, void 0, false, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1, matchValue_3;
    switch (msg.tag) {
        case 1: {
            const resp_1 = msg.fields[0];
            if (resp_1.tag === 1) {
                return new Model(model.Entries, "Noe gikk galt. Kunne ikke oppdatere rolle", model.AddForm, model.DelForm);
            }
            else {
                const updated = resp_1.fields[0];
                return new Model((matchValue = model.Entries, (matchValue == null) ? [updated] : map((elem) => {
                    if (elem.id === updated.id) {
                        return updated;
                    }
                    else {
                        return elem;
                    }
                }, matchValue)), model.ErrorMsg, model.AddForm, model.DelForm);
            }
        }
        case 2: {
            const resp_2 = msg.fields[0];
            if (resp_2.tag === 1) {
                return new Model(model.Entries, "Noe gikk galt. Kunne ikke legge til rolle", model.AddForm, model.DelForm);
            }
            else {
                const added = resp_2.fields[0];
                return new Model((matchValue_1 = model.Entries, (matchValue_1 == null) ? [added] : append([added], matchValue_1)), model.ErrorMsg, false, model.DelForm);
            }
        }
        case 3: {
            const resp_3 = msg.fields[0];
            if (resp_3.tag === 1) {
                return new Model(model.Entries, "Noe gikk galt. Kunne ikke slette rolle", model.AddForm, model.DelForm);
            }
            else {
                const res = resp_3.fields[0];
                if (res.result === "success") {
                    return new Model((matchValue_3 = model.Entries, (matchValue_3 == null) ? (void 0) : matchValue_3.filter((elem_1) => (elem_1.id !== res.id))), model.ErrorMsg, model.AddForm, void 0);
                }
                else {
                    return new Model(model.Entries, res.message, model.AddForm, void 0);
                }
            }
        }
        case 4: {
            return new Model(model.Entries, msg.fields[0], model.AddForm, model.DelForm);
        }
        case 5: {
            return new Model(model.Entries, void 0, model.AddForm, model.DelForm);
        }
        case 6: {
            return new Model(model.Entries, model.ErrorMsg, !model.AddForm, model.DelForm);
        }
        case 7: {
            return new Model(model.Entries, model.ErrorMsg, model.AddForm, msg.fields[0]);
        }
        default: {
            const resp = msg.fields[0];
            if (resp.tag === 1) {
                return new Model(model.Entries, "Noe gikk galt. Kunne ikke laste roller", model.AddForm, model.DelForm);
            }
            else {
                return new Model(resp.fields[0], model.ErrorMsg, model.AddForm, model.DelForm);
            }
        }
    }
}

function fetchRoles(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Role$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/role/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Role$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
}

function addRole(role, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let item;
        if (role.code.length > 0) {
            return ((item = (new Role(-1, role.code, role.title, role.desc, role.mandatory, role.compmandatory, role.category)), PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Role$reflection,
                })(item);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Role$reflection,
                });
                return Fetch_tryPost_5760677E("/api/role/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Role$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })))).then(((_arg1) => {
                dispatch(new Message(2, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            dispatch(new Message(4, "Navn kan ikke være tomt"));
            return Promise.resolve();
        }
    }));
}

function updateRole(oldRole, newRole, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        if (newRole.code.length === 0) {
            dispatch(new Message(4, "Kode kan ikke være tom"));
            return Promise.resolve();
        }
        else {
            return (!equals(newRole, oldRole)) ? (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Role$reflection,
                })(newRole);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Role$reflection,
                });
                return Fetch_tryPost_5760677E("/api/role/add", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Role$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(1, _arg1));
                return Promise.resolve();
            }))) : (Promise.resolve());
        }
    }));
}

function deleteRole(role, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Role$reflection,
        })(role);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: DeleteResponse$reflection,
        });
        return Fetch_tryPost_5760677E("/api/role/remove", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: DeleteResponse$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(3, _arg1));
        return Promise.resolve();
    })))));
}

function drawMaterialTableRole(model, dispatch) {
    let value_38;
    const matchValue = model.Entries;
    if (matchValue == null) {
        return loadingIndicatorSmall();
    }
    else {
        const entries = matchValue;
        const theme = StyleImports_useTheme();
        return MuiHelpers_reactElement(material$002Dtable, createObj(ofArray([["style", {
            backgroundColor: theme.palette.background.paper,
            maxWidth: 1000,
            padding: 2 + "vw",
            width: 90 + "%",
        }], ["title", "Roller"], ["data", Array.from(ofArray(sortBy((e) => [e.category, e.code], entries, {
            Compare: (x, y) => compareArrays(x, y),
        })))], ["columns", [{
            title: "Kode",
            field: "code",
        }, {
            title: "Tittel",
            field: "title",
        }, {
            title: "Beskrivelse",
            field: "desc",
        }, {
            title: "Påkrevd i prosjekt",
            field: "mandatory",
            type: "boolean",
        }, {
            title: "Påkrevd i selskap",
            field: "compmandatory",
            type: "boolean",
        }, {
            title: "Kategori",
            field: "category",
            lookup: createObj(ofArray(map((x_1) => [RoleCategory__ToInt(x_1), toString(x_1)], RoleCategory_AsArray()))),
        }]], ["editable", {
            onRowUpdate: (delegateArg0, delegateArg1) => updateRole(delegateArg1, delegateArg0, dispatch),
        }], ["actions", [createObj(ofArray([(value_38 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBox, {})])]]), ["icon", () => value_38]), ["tooltip", "Legg til"], ["isFreeAction", true], ["onClick", (delegateArg0_1, delegateArg1_1) => {
            dispatch(new Message(6));
        }]])), (arg) => {
            let value_46;
            return createObj(ofArray([(value_46 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutline, {})])]]), ["icon", () => value_46]), ["tooltip", "Deaktiver"], ["onClick", (delegateArg0_2, delegateArg1_2) => {
                dispatch(new Message(7, arg));
            }]]));
        }]], insertMaterialTableNorwegianLocalization(), ["options", {
            grouping: false,
            search: false,
            pageSize: (entries.length > 5) ? 10 : 5,
        }]])));
    }
}

const addFormDialog = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Role(-1, "", "", "", false, false, RoleCategory__ToInt(new RoleCategory(0))));
    const updateState = patternInput[1];
    const state = patternInput[0];
    const innerBody = createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20 + "px",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", state.code], ["label", "Kode"], ["style", {
            marginBottom: 15,
        }], ["onChange", (e) => {
            updateState(new Role(state.id, Browser_Types_Event__Event_get_Value(e), state.title, state.desc, state.mandatory, state.compmandatory, state.category));
        }], ["required", true]]), MuiHelpers_createElement(TextField, [["defaultValue", state.title], ["label", "Tittel"], ["style", {
            marginBottom: 15,
        }], ["onChange", (e_1) => {
            updateState(new Role(state.id, state.code, Browser_Types_Event__Event_get_Value(e_1), state.desc, state.mandatory, state.compmandatory, state.category));
        }]]), MuiHelpers_createElement(TextField, [["defaultValue", state.desc], ["label", "Beskrivelse"], ["style", {
            marginBottom: 15,
        }], ["onChange", (e_2) => {
            updateState(new Role(state.id, state.code, state.title, Browser_Types_Event__Event_get_Value(e_2), state.mandatory, state.compmandatory, state.category));
        }]]), createElement("div", {
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControlLabel, [["label", "Påkrevd i prosjekt"], ["control", MuiHelpers_createElement(Checkbox, [["checked", state.mandatory], ["color", "primary"], ["onChange", (e_3) => {
                updateState(new Role(state.id, state.code, state.title, state.desc, Browser_Types_Event__Event_get_Checked(e_3), state.compmandatory, state.category));
            }]])]]), MuiHelpers_createElement(FormControlLabel, [["label", "Påkrevd i selskap"], ["control", MuiHelpers_createElement(Checkbox, [["checked", state.compmandatory], ["color", "primary"], ["onChange", (e_4) => {
                updateState(new Role(state.id, state.code, state.title, state.desc, state.mandatory, Browser_Types_Event__Event_get_Checked(e_4), state.category));
            }], ["children", "Påkrevd i selskap"]])]])]),
        }), MuiHelpers_createElement(NativeSelect, [["name", "Kategori"], ["value", int32ToString(state.category)], ["onChange", (e_5) => {
            updateState(new Role(state.id, state.code, state.title, state.desc, state.mandatory, state.compmandatory, parse(Browser_Types_Event__Event_get_Value(e_5), 511, false, 32)));
        }], ["children", map((x) => createElement("option", {
            value: RoleCategory__ToInt(x),
            children: toString(x),
        }), RoleCategory_AsArray())]])]),
    });
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        props.dispatch(new Message(6));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til Rolle"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([innerBody])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg2) => {
        props.dispatch(new Message(6));
    }], ["color", "primary"], ["children", "Avbryt"]]), MuiHelpers_createElement(Button, [["onClick", (_arg3) => {
        const pr = addRole(state, props.dispatch);
        pr.then();
    }], ["disabled", state.code.length === 0], ["color", "primary"], ["children", "Legg til"]])])]])])]]);
}, void 0, uncurry(2, void 0), void 0, "addFormDialog", "/builds/serit/derigo/tenant/src/Client/Administration/Role.fs", 238);

function view(model, dispatch) {
    return createElement("main", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: 3 + "rem",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(5));
        })), delay(() => append_1(singleton(drawMaterialTableRole(model, dispatch)), delay(() => append_1(model.AddForm ? singleton(addFormDialog({
            dispatch: dispatch,
        })) : empty_1(), delay(() => {
            let value_7;
            const matchValue = model.DelForm;
            if (matchValue == null) {
                return empty_1();
            }
            else {
                const delRow = matchValue;
                return singleton(genericDeleteDialog(() => {
                    dispatch(new Message(7, void 0));
                }, () => deleteRole(delRow, dispatch), [createElement("h3", {
                    children: Interop_reactApi.Children.toArray([(value_7 = toText(printf("Valgt rolle: %A"))(delRow.title), createElement("b", {
                        children: [value_7],
                    }))]),
                }), createElement("hr", {
                    style: {
                        width: 100 + "%",
                    },
                }), createElement("p", {
                    style: {
                        maxWidth: 20 + "rem",
                    },
                    children: "NB: Dette vil deaktivere rollen, men beholdes i prosjekter og selskaper der den er allerede brukt.",
                })]));
            }
        })))))))))),
    });
}

export const roles = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchRoles(dispatch);
        pr.then();
    }, []);
    return view(patternInput[0], dispatch);
}, void 0, uncurry(2, void 0), void 0, "roles", "/builds/serit/derigo/tenant/src/Client/Administration/Role.fs", 385);

