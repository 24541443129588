import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import { singleton } from "./.fable/fable-library.3.2.4/List.js";
import { map } from "./.fable/fable-library.3.2.4/Array.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";

export const DefaultBlue = "#2c6094";

export const DefaultColors = [[["#1a8a38", "#ffffff"], ["#1dab43", "#ffffff"], ["#28c752", "#ffffff"], ["#4bdb71", "#131313"], ["#8ceda6", "#131313"], ["#c2ffd2", "#131313"]], [[DefaultBlue, "#ffffff"], ["#3973ad", "#ffffff"], ["#4f8cc9", "#ffffff"], ["#75abe0", "#131313"], ["#8dbff0", "#131313"], ["#b4d7fa", "#131313"]], [["#59227a", "#ffffff"], ["#7833a1", "#ffffff"], ["#9748c7", "#ffffff"], ["#b664e8", "#131313"], ["#c982f5", "#131313"], ["#deb1fa", "#131313"]], [["#ffa500", "#ffffff"], ["#ffb01f", "#ffffff"], ["#ffb836", "#ffffff"], ["#ffc761", "#131313"], ["#ffd382", "#131313"], ["#ffe1ab", "#131313"]], [["#962318", "#ffffff"], ["#b33024", "#ffffff"], ["#c93f32", "#ffffff"], ["#de584b", "#131313"], ["#f57a6e", "#131313"], ["#ffa299", "#131313"]]];

export function displayColor(color, onClick) {
    return createElement("div", {
        style: {
            width: 30,
            height: 30,
            backgroundColor: color,
            margin: 5,
            borderRadius: 5,
            cursor: "pointer",
        },
        onClick: (_arg1) => {
            onClick(color);
        },
    });
}

export function Picker(pickerInputProps) {
    let children, children_2;
    const onSelect = pickerInputProps.onSelect;
    const colors = pickerInputProps.colors;
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray([(children = singleton(createElement("span", {
            children: "Velg farge",
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
            },
            children: Interop_reactApi.Children.toArray([map((row) => createElement("div", {
                style: {
                    display: "flex",
                    flexWrap: "wrap",
                },
                children: Interop_reactApi.Children.toArray([map((tupledArg) => {
                    const c = tupledArg[0];
                    return displayColor(c, (_arg1) => {
                        onSelect([c, tupledArg[1]]);
                    });
                }, row)]),
            }), colors)]),
        }), (children_2 = singleton(MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["children", "Avbryt"], ["onClick", (_arg2) => {
            onSelect(void 0);
        }]])), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))]),
    });
}

