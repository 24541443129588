import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { compare, parse, toString, addYears, now } from "../.fable/fable-library.3.2.4/Date.js";
import { Helpers_ResultMessage$reflection, ProcessModule_RevisionForm$reflection, ProcessModule_RevisionForm } from "../Shared/ApiDataTypes.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import { empty, map, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import { empty as empty_1, item as item_1, length } from "../.fable/fable-library.3.2.4/List.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { loadingIndicatorSmall, errorBanner, PageElements_filenameToIcon } from "../ViewHelpers.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { obj_type } from "../.fable/fable-library.3.2.4/Reflection.js";

export function Form(formInputProps) {
    let copyOfStruct_1, copyOfStruct;
    const onClose = formInputProps.onClose;
    const currentVersion = formInputProps.currentVersion;
    const patternInput = useFeliz_React__React_useState_Static_1505(new ProcessModule_RevisionForm(formInputProps.documentId, "", (copyOfStruct_1 = ((copyOfStruct = now(), addYears(copyOfStruct, 1))), toString(copyOfStruct_1, "yyyy-MM-dd")), "", ""));
    const updateRevision = patternInput[1];
    const revision = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const docSelected = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_3[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(false);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Last opp dokumentrevisjon"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("label", {
                style: {
                    cursor: "pointer",
                    textAlign: "center",
                },
                children: Interop_reactApi.Children.toArray([createElement("i", {
                    style: {
                        display: "block",
                        fontSize: 2 + "rem",
                    },
                    className: "fas fa-cloud-upload-alt",
                }), (docSelected == null) ? "Velg fil" : "Velg en annen fil", createElement("input", {
                    type: "file",
                    onChange: (ev) => {
                        let arg10, arg20;
                        const fileList = ev.target.files;
                        if (!(fileList == null)) {
                            const x_1 = toList(delay(() => map((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                            try {
                                if (length(x_1) > 0) {
                                    const file = item_1(0, x_1);
                                    const filename = file.name;
                                    console.log(some((arg10 = (file.size | 0), toText(printf("filesize: %i"))(arg10))));
                                    console.log(some((arg20 = file.name, toText(printf("filename: %s (%s)"))(filename)(arg20))));
                                    const reader = new FileReader();
                                    reader.addEventListener("load", (_arg2) => {
                                        const content = reader.result;
                                        patternInput_1[1](filename);
                                        updateRevision(new ProcessModule_RevisionForm(revision.DocumentId, revision.Version, revision.NextRevision, content, filename));
                                    });
                                    const value_24 = reader.readAsDataURL(file);
                                }
                            }
                            catch (matchValue) {
                            }
                        }
                    },
                    style: {
                        display: "none",
                    },
                })]),
            })), delay(() => {
                if (docSelected != null) {
                    const filename_1 = docSelected;
                    return singleton(createElement("span", {
                        children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(filename_1), filename_1]),
                    }));
                }
                else {
                    return singleton(createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: "Ingen fil valgt",
                    }));
                }
            })))))),
        }), createElement("div", {
            style: {
                marginTop: 15,
                width: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", revision.Version], ["helperText", toText(printf("Nåværende versjon: %s"))(currentVersion)], ["label", "Versjon (f.eks. 2.0)"], ["onChange", (e) => {
                updateRevision(new ProcessModule_RevisionForm(revision.DocumentId, Browser_Types_Event__Event_get_Value(e), revision.NextRevision, revision.FileContent, revision.Filename));
            }], ["size", "small"], ["variant", "outlined"], ["fullWidth", true]])]),
        }), createElement("div", {
            style: {
                marginTop: 5,
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["checked", revision.NextRevision != null], ["onChange", (e_1) => {
                let copyOfStruct_3, copyOfStruct_2;
                if (Browser_Types_Event__Event_get_Checked(e_1)) {
                    updateRevision(new ProcessModule_RevisionForm(revision.DocumentId, revision.Version, (copyOfStruct_3 = ((copyOfStruct_2 = now(), addYears(copyOfStruct_2, 1))), toString(copyOfStruct_3, "yyyy-MM-dd")), revision.FileContent, revision.Filename));
                }
                else {
                    updateRevision(new ProcessModule_RevisionForm(revision.DocumentId, revision.Version, void 0, revision.FileContent, revision.Filename));
                }
            }], ["color", "primary"]])], ["label", "Inkl. neste revisjonsdato"]])), delay(() => {
                const matchValue_1 = revision.NextRevision;
                if (matchValue_1 != null) {
                    return singleton(MuiHelpers_createElement(TextField, [["label", "Neste revisjon"], ["type", "date"], ["value", matchValue_1], ["onChange", (e_2) => {
                        const x_3 = Browser_Types_Event__Event_get_Value(e_2);
                        try {
                            const asDateTime = parse(x_3);
                            if (compare(asDateTime, now()) > 0) {
                                updateRevision(new ProcessModule_RevisionForm(revision.DocumentId, revision.Version, toString(asDateTime, "yyyy-MM-dd"), revision.FileContent, revision.Filename));
                            }
                        }
                        catch (matchValue_2) {
                        }
                    }]]));
                }
                else {
                    return empty();
                }
            })))))),
        })]),
    }), errorBanner(patternInput_2[0], () => {
        setErrMsg(void 0);
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!patternInput_3[0]) ? append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg4) => {
        onClose(false);
    }]])), delay(() => singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", ((revision.Version.length === 0) ? true : (docSelected == null)) ? true : (revision.FileContent.length === 0)], ["children", "Last opp"], ["onClick", (_arg5) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            if (((docSelected != null) ? (revision.FileContent.length > 0) : false) ? (revision.Version.length > 0) : false) {
                setProcessing(true);
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                        ResolveType: ProcessModule_RevisionForm$reflection,
                    })(revision);
                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: Helpers_ResultMessage$reflection,
                    });
                    return Fetch_tryPost_5760677E("/api/processactivitydoc/revision", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                        ResolveType: Helpers_ResultMessage$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg1) => {
                    const res = _arg1;
                    if (res.tag === 1) {
                        setErrMsg("Kunne ikke laste opp revisjon");
                        setProcessing(false);
                        return Promise.resolve();
                    }
                    else {
                        const x = res.fields[0];
                        return ((x.Result === "success") ? ((onClose(true), Promise.resolve())) : ((setErrMsg(x.Message), Promise.resolve()))).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            setProcessing(false);
                            return Promise.resolve();
                        }));
                    }
                }));
            }
            else {
                return Promise.resolve();
            }
        }));
        pr.then();
    }]])))) : singleton(loadingIndicatorSmall()))))))]])])]]);
}

