import { Record, Union } from "./.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, bool_type, option_type, union_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { map, toArray, singleton, empty, ofArray, sortBy } from "./.fable/fable-library.3.2.4/List.js";
import { equals, uncurry, comparePrimitives } from "./.fable/fable-library.3.2.4/Util.js";
import { Modules$reflection } from "./Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.4/Choice.js";
import { Tab_SaveSelectedTab_Z721C83C5, Tab_GetSelectedTab } from "./LocalStorage.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { op_UnaryNegation_Int32 } from "./.fable/fable-library.3.2.4/Int32.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/icons/Menu";
import { breadcrumbTypography } from "./Navbar.js";
import Drawer from "@material-ui/core/Drawer";
import { RotationAngle, rotatingChevronButton } from "./ViewHelpers.js";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { actionClass } from "./Administration/ActionClass.js";
import { Breeams } from "./Administration/Breeam.js";
import { Builders } from "./Administration/Builder.js";
import { CategoryAdmin } from "./Administration/Category.js";
import { CompanyConfigure } from "./Administration/CompanyConfigure.js";
import { CompanyOnedrive } from "./Administration/CompanyOnedrive.js";
import { educations } from "./Administration/EducationLevel.js";
import { energyclasss } from "./Administration/EnergyClass.js";
import { ControlView } from "./Administration/HandedOver.js";
import { HmsControl } from "./HMS/HmsControl.js";
import { hmsTypes } from "./HMS/HmsType.js";
import { interaction } from "./Administration/Interaction.js";
import { NonconformityCategories } from "./Administration/NonconformityCategory.js";
import { ProcessTree } from "./Administration/Process.js";
import { Templates } from "./Administration/ProjectPlanTemplate.js";
import { QualityForm } from "./Administration/QualityForm.js";
import { roles } from "./Administration/Role.js";
import { CombinedView } from "./Administration/Skill.js";
import { ConfigureUsers } from "./Administration/UserConfigure.js";
import { WasteControl } from "./Waste/WasteControl.js";
import { WasteTypes } from "./Waste/WasteType.js";
import { AccessControl } from "./Administration/AccessControl.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./.fable/Feliz.1.68.0/React.fs.js";

class SubModel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AccessControlModel", "ActionClassModel", "BreeamModel", "BuilderModel", "CategoryModel", "EducationLevelModel", "EnergyClassModel", "HandoverModel", "HmsControlModel", "HmsTypeModel", "InteractionModel", "NonconformityCategoryModel", "ProcessModel", "ProjectPlanTemplateModel", "RoleModel", "WasteTypeModel", "WasteControlModel", "QualityFormsModel", "SkillModel", "CompanyOnedriveModel", "UserConfigureModel", "CompanyConfigureModel"];
    }
}

function SubModel$reflection() {
    return union_type("Administration.SubModel", [], SubModel, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

function SubModel__ToTitle(this$) {
    switch (this$.tag) {
        case 1: {
            return "Ansvarsretter";
        }
        case 2: {
            return "Breeams";
        }
        case 3: {
            return "Byggherrer";
        }
        case 4: {
            return "Prosjektkategorier";
        }
        case 5: {
            return "Utdanningsnivå";
        }
        case 6: {
            return "Energiklasser";
        }
        case 7: {
            return "Overleveringer";
        }
        case 8: {
            return "HMS kontroll";
        }
        case 9: {
            return "HMS typer";
        }
        case 10: {
            return "Samspill";
        }
        case 11: {
            return "Avvikskategori";
        }
        case 12: {
            return "Prosesser";
        }
        case 13: {
            return "Prosjektplan mal";
        }
        case 14: {
            return "Roller";
        }
        case 18: {
            return "Ferdigheter";
        }
        case 16: {
            return "Avfallskontroll";
        }
        case 15: {
            return "Avfallstyper";
        }
        case 17: {
            return "Kvalitetsskjema";
        }
        case 19: {
            return "SharePoint/OneDrive";
        }
        case 20: {
            return "Brukere";
        }
        case 21: {
            return "Selskap";
        }
        default: {
            return "Brukerrettigheter";
        }
    }
}

const getAllSubModels = sortBy((x) => SubModel__ToTitle(x), ofArray([new SubModel(0), new SubModel(1), new SubModel(2), new SubModel(3), new SubModel(4), new SubModel(21), new SubModel(19), new SubModel(5), new SubModel(6), new SubModel(8), new SubModel(7), new SubModel(9), new SubModel(10), new SubModel(11), new SubModel(12), new SubModel(13), new SubModel(14), new SubModel(18), new SubModel(20), new SubModel(15), new SubModel(16), new SubModel(17)]), {
    Compare: (x_1, y) => comparePrimitives(x_1, y),
});

class Model extends Record {
    constructor(SubMdl, Modules, DrawerOpen) {
        super();
        this.SubMdl = SubMdl;
        this.Modules = Modules;
        this.DrawerOpen = DrawerOpen;
    }
}

function Model$reflection() {
    return record_type("Administration.Model", [], Model, () => [["SubMdl", SubModel$reflection()], ["Modules", option_type(Modules$reflection())], ["DrawerOpen", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GotoView", "ModulesResponse", "ToggleDrawer"];
    }
}

function Message$reflection() {
    return union_type("Administration.Message", [], Message, () => [[["Item", SubModel$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Modules$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Modules$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function getSelectedTab() {
    const matchValue = Tab_GetSelectedTab();
    if (matchValue.tag === 1) {
        return new SubModel(12);
    }
    else {
        const matchValue_1 = matchValue.fields[0].toLocaleLowerCase();
        switch (matchValue_1) {
            case "brukerrettigheter": {
                return new SubModel(0);
            }
            case "ansvarsretter": {
                return new SubModel(1);
            }
            case "breeams": {
                return new SubModel(2);
            }
            case "byggherrer": {
                return new SubModel(3);
            }
            case "utdanningsnivå": {
                return new SubModel(5);
            }
            case "energiklasser": {
                return new SubModel(6);
            }
            case "overleveringer": {
                return new SubModel(7);
            }
            case "hms kontroll": {
                return new SubModel(8);
            }
            case "hms typer": {
                return new SubModel(9);
            }
            case "samspill": {
                return new SubModel(10);
            }
            case "avvikskategori": {
                return new SubModel(11);
            }
            case "prosesser": {
                return new SubModel(12);
            }
            case "kategorier": {
                return new SubModel(4);
            }
            case "roller": {
                return new SubModel(14);
            }
            case "avfallskontroll": {
                return new SubModel(16);
            }
            case "avfallstyper": {
                return new SubModel(15);
            }
            case "ferdigheter": {
                return new SubModel(18);
            }
            case "prosjektkategorier": {
                return new SubModel(4);
            }
            case "sharepoint-onedrive": {
                return new SubModel(19);
            }
            default: {
                return new SubModel(12);
            }
        }
    }
}

function init(page) {
    const pageModel = (page.tag === 4) ? (new SubModel(0)) : ((page.tag === 29) ? (new SubModel(1)) : ((page.tag === 27) ? (new SubModel(2)) : ((page.tag === 26) ? (new SubModel(3)) : ((page.tag === 36) ? (new SubModel(5)) : ((page.tag === 28) ? (new SubModel(6)) : ((page.tag === 6) ? (new SubModel(7)) : ((page.tag === 11) ? (new SubModel(8)) : ((page.tag === 12) ? (new SubModel(9)) : ((page.tag === 19) ? (new SubModel(10)) : ((page.tag === 23) ? (new SubModel(12)) : ((page.tag === 30) ? (new SubModel(4)) : ((page.tag === 24) ? (new SubModel(14)) : ((page.tag === 13) ? (new SubModel(16)) : ((page.tag === 15) ? (new SubModel(15)) : getSelectedTab()))))))))))))));
    Tab_SaveSelectedTab_Z721C83C5(SubModel__ToTitle(pageModel));
    return new Model(pageModel, void 0, true);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return model;
            }
            else {
                return new Model(model.SubMdl, res.fields[0], model.DrawerOpen);
            }
        }
        case 2: {
            return new Model(model.SubMdl, model.Modules, !model.DrawerOpen);
        }
        default: {
            const x = msg.fields[0];
            Tab_SaveSelectedTab_Z721C83C5(SubModel__ToTitle(x));
            return new Model(x, model.Modules, model.DrawerOpen);
        }
    }
}

function fetchModules(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Modules$reflection,
        });
        return Fetch_tryGet_5760677E("/api/modules", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Modules$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let arg10_1, arg10_2;
    let o;
    const theme = arg;
    const styles = StyleCreator$1_$ctor();
    const root = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "flex"]));
    const appBar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["zIndex", 20], ["width", toText(printf("calc(100vw - %ipx)"))(70)], ["left", 70]]));
    const appBarShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", (arg10_1 = ((250 + 70) | 0), toText(printf("calc(100%% - %ipx)"))(arg10_1))], ["marginLeft", 250 + 70], ["left", 0]]));
    const toolbar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#2F4F4F"], ["backgroundColor", "#F5F5F5"]]));
    const menuButton = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginRight", theme.spacing(2)], ["color", "#2F4F4F"]]));
    const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "none"]));
    const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 250], ["flexShrink", 0]]));
    const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["overflowY", "hidden"], ["width", 250], ["left", 70]]));
    const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, toList(delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["alignItems", "center"]), delay(() => append(singleton_1(["padding", theme.spacing(0, 1)]), delay(() => append(Object.entries(theme.mixins.toolbar), delay(() => singleton_1(["justifyContent", "flex-end"]))))))))))));
    const content = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", op_UnaryNegation_Int32(250)], ["width", "calc(100vw - 30px)"], ["minWidth", 400], ["backgroundColor", "#fff"], ["overflowY", "auto"], ["height", 100 + "vh"]]));
    const contentShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", 0], ["width", (arg10_2 = ((250 + 30) | 0), toText(printf("calc(100vw - %ipx)"))(arg10_2))]]));
    o = {
        appBar: appBar,
        appBarShift: appBarShift,
        closeButton: StyleCreator$1__create_4EAC9E1E(styles, singleton(["\u0026:hover", ["backgroundColor", "#ffffff"]])),
        content: content,
        contentShift: contentShift,
        drawer: drawer,
        drawerHeader: drawerHeader,
        drawerPaper: drawerPaper,
        hide: hide,
        menuButton: menuButton,
        root: root,
        toolbar: toolbar,
    };
    return Object.assign({}, o);
});

function view(model, dispatch) {
    let matchValue;
    const s = useStyles();
    return createElement("div", {
        className: s.root,
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CssBaseline, []), MuiHelpers_createElement(AppBar, [["className", model.DrawerOpen ? toText(printf("%s %s"))(s.appBar)(s.appBarShift) : s.appBar], ["position", "absolute"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Toolbar, [["className", s.toolbar], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["className", model.DrawerOpen ? toText(printf("%s %s"))(s.menuButton)(s.hide) : s.menuButton], ["edge", "start"], ["onClick", (_arg2) => {
            dispatch(new Message(2));
        }], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Menu, {})])]])]]), breadcrumbTypography("Administrasjon")])]])])]]), MuiHelpers_createElement(Drawer, [["className", s.drawer], ["classes.paper", s.drawerPaper], ["variant", "persistent"], ["anchor", "left"], ["open", model.DrawerOpen], ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: s.drawerHeader,
            children: Interop_reactApi.Children.toArray([rotatingChevronButton(new RotationAngle(1), () => {
                dispatch(new Message(2));
            })]),
        }), MuiHelpers_createElement(Divider, []), createElement("div", {
            style: {
                overflowY: "auto",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray([toArray(map((x) => {
                const isActive = equals(model.SubMdl, x);
                const title = SubModel__ToTitle(x);
                return MuiHelpers_createElement(ListItem, [["button", true], ["dense", false], ["selected", isActive], ["key", title], ["onClick", (_arg1) => {
                    dispatch(new Message(0, x));
                }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", title]])])]]);
            }, getAllSubModels))])]])]),
        })])]]), createElement("main", {
            className: model.DrawerOpen ? toText(printf("%s %s"))(s.content)(s.contentShift) : s.content,
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: s.drawerHeader,
            }), createElement("div", {
                style: {
                    alignContent: "center",
                    overflowX: "auto",
                    maxWidth: 100 + "vw",
                    paddingBottom: 1 + "rem",
                    height: toText(printf("calc(100vh - %ipx)"))(64),
                    overflowY: "auto",
                },
                children: Interop_reactApi.Children.toArray([(matchValue = model.SubMdl, (matchValue.tag === 1) ? actionClass() : ((matchValue.tag === 2) ? createElement(Breeams, null) : ((matchValue.tag === 3) ? createElement(Builders, null) : ((matchValue.tag === 4) ? createElement(CategoryAdmin, null) : ((matchValue.tag === 21) ? createElement(CompanyConfigure, null) : ((matchValue.tag === 19) ? createElement(CompanyOnedrive, null) : ((matchValue.tag === 5) ? educations() : ((matchValue.tag === 6) ? energyclasss() : ((matchValue.tag === 7) ? createElement(ControlView, null) : ((matchValue.tag === 8) ? createElement(HmsControl, null) : ((matchValue.tag === 9) ? hmsTypes() : ((matchValue.tag === 10) ? interaction() : ((matchValue.tag === 11) ? createElement(NonconformityCategories, null) : ((matchValue.tag === 12) ? createElement(ProcessTree, null) : ((matchValue.tag === 13) ? createElement(Templates, null) : ((matchValue.tag === 17) ? createElement(QualityForm, null) : ((matchValue.tag === 14) ? roles() : ((matchValue.tag === 18) ? CombinedView() : ((matchValue.tag === 20) ? createElement(ConfigureUsers, null) : ((matchValue.tag === 16) ? createElement(WasteControl, null) : ((matchValue.tag === 15) ? createElement(WasteTypes, null) : createElement(AccessControl, null))))))))))))))))))))))]),
            })]),
        })]),
    });
}

export function AdminView(props) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.page));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchModules(dispatch);
    });
    return view(patternInput[0], dispatch);
}

