import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, option_type, class_type, array_type, union_type, int32_type, record_type, bool_type, float64_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { join, format, interpolate, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { RegistrationStatus$reflection, Waste_TypeSummary$reflection, Waste_PeriodSummary$reflection, Waste_WasteType$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { sortByDescending, map } from "../.fable/fable-library.3.2.4/Array.js";
import { stringHash, int32ToString, comparePrimitives, createObj, uncurry, compareArrays } from "../.fable/fable-library.3.2.4/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, ofArray, toArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { toString } from "../.fable/fable-library.3.2.4/Date.js";
import { createElement } from "react";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import Button from "@material-ui/core/Button";
import { snackbarError, loadingIndicatorSmall } from "../ViewHelpers.js";
import Skeleton from "@material-ui/lab/Skeleton";
import { map as map_1 } from "../.fable/fable-library.3.2.4/Option.js";
import { Cell, Bar, Tooltip, YAxis, XAxis, CartesianGrid, BarChart, ResponsiveContainer } from "recharts";
import { monthsInPeriod } from "../Utils.js";
import { Array_groupBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import Table from "@material-ui/core/Table";

export class ChartData extends Record {
    constructor(name, quantity, sorted, dangerous) {
        super();
        this.name = name;
        this.quantity = quantity;
        this.sorted = sorted;
        this.dangerous = dangerous;
    }
}

export function ChartData$reflection() {
    return record_type("WasteReport.ChartData", [], ChartData, () => [["name", string_type], ["quantity", float64_type], ["sorted", bool_type], ["dangerous", bool_type]]);
}

export class ReportLevel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Project", "Company", "Group"];
    }
}

export function ReportLevel$reflection() {
    return union_type("WasteReport.ReportLevel", [], ReportLevel, () => [[["Item", int32_type]], [["Item", int32_type]], []]);
}

export function ReportLevel__ToName(this$) {
    switch (this$.tag) {
        case 1: {
            return "selskapsnivå";
        }
        case 2: {
            return "konsernnivå";
        }
        default: {
            return "prosjektnivå";
        }
    }
}

export function ReportLevel__ToPeriodSummaryUrl(this$) {
    switch (this$.tag) {
        case 1: {
            return toText(printf("/api/waste/report/company/period/%i"))(this$.fields[0]);
        }
        case 2: {
            return "/api/waste/report/group/period";
        }
        default: {
            return toText(printf("/api/waste/report/project/period/%i"))(this$.fields[0]);
        }
    }
}

export function ReportLevel__ToTypeSummaryUrl(this$) {
    switch (this$.tag) {
        case 1: {
            return toText(printf("/api/waste/report/company/types/%i"))(this$.fields[0]);
        }
        case 2: {
            return "/api/waste/report/group/types";
        }
        default: {
            return toText(printf("/api/waste/report/project/types/%i"))(this$.fields[0]);
        }
    }
}

export function ReportLevel__ToStatusUrl(this$) {
    switch (this$.tag) {
        case 1: {
            return toText(printf("/api/waste/report/company/registration/%i"))(this$.fields[0]);
        }
        case 2: {
            return "/api/waste/report/group/registration";
        }
        default: {
            return toText(printf("/api/waste/report/project/registration/%i"))(this$.fields[0]);
        }
    }
}

class Model extends Record {
    constructor(Level, Types, PeriodStart, PeriodEnd, PeriodSummary, Chart, TypeSummary, Status, ErrorMsg) {
        super();
        this.Level = Level;
        this.Types = Types;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.PeriodSummary = PeriodSummary;
        this.Chart = Chart;
        this.TypeSummary = TypeSummary;
        this.Status = Status;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("WasteReport.Model", [], Model, () => [["Level", ReportLevel$reflection()], ["Types", array_type(Waste_WasteType$reflection())], ["PeriodStart", class_type("System.DateTime")], ["PeriodEnd", class_type("System.DateTime")], ["PeriodSummary", option_type(Waste_PeriodSummary$reflection())], ["Chart", option_type(array_type(ChartData$reflection()))], ["TypeSummary", option_type(array_type(Waste_TypeSummary$reflection()))], ["Status", option_type(array_type(RegistrationStatus$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TypesResponse", "PeriodSummaryResponse", "TypeSummaryResponse", "RegStatusResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("WasteReport.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Waste_WasteType$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Waste_WasteType$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Waste_PeriodSummary$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Waste_PeriodSummary$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Waste_TypeSummary$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Waste_TypeSummary$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(RegistrationStatus$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(RegistrationStatus$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(x, y, z) {
    return new Model(x, [], y, z, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Level, model.Types, model.PeriodStart, model.PeriodEnd, model.PeriodSummary, model.Chart, model.TypeSummary, model.Status, "Noe gikk galt. Kunne ikke laste oppsummering.");
            }
            else {
                return new Model(model.Level, model.Types, model.PeriodStart, model.PeriodEnd, res_1.fields[0], model.Chart, model.TypeSummary, model.Status, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Level, model.Types, model.PeriodStart, model.PeriodEnd, model.PeriodSummary, model.Chart, model.TypeSummary, model.Status, "Noe gikk galt. Kunne ikke laste oppsummering.");
            }
            else {
                const x_2 = res_2.fields[0];
                return new Model(model.Level, model.Types, model.PeriodStart, model.PeriodEnd, model.PeriodSummary, map((y_2) => (new ChartData(y_2.Type.Name, y_2.Quantity, y_2.Type.Sorted, y_2.Type.Dangerous)), sortByDescending((y) => [!y.Type.Dangerous, y.Type.Sorted], x_2, {
                    Compare: (x_3, y_1) => compareArrays(x_3, y_1),
                })), x_2, model.Status, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Level, model.Types, model.PeriodStart, model.PeriodEnd, model.PeriodSummary, model.Chart, model.TypeSummary, model.Status, "Noe gikk galt. Kunne ikke laste registreringsstatus");
            }
            else {
                return new Model(model.Level, model.Types, model.PeriodStart, model.PeriodEnd, model.PeriodSummary, model.Chart, model.TypeSummary, res_3.fields[0], model.ErrorMsg);
            }
        }
        case 4: {
            return new Model(model.Level, model.Types, model.PeriodStart, model.PeriodEnd, model.PeriodSummary, model.Chart, model.TypeSummary, model.Status, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Level, model.Types, model.PeriodStart, model.PeriodEnd, model.PeriodSummary, model.Chart, model.TypeSummary, model.Status, "Noe gikk galt. Kunne ikke laste avfallstyper.");
            }
            else {
                return new Model(model.Level, res.fields[0], model.PeriodStart, model.PeriodEnd, model.PeriodSummary, model.Chart, model.TypeSummary, model.Status, model.ErrorMsg);
            }
        }
    }
}

function fetchTypes(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Waste_WasteType$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/waste/types", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Waste_WasteType$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchPeriodSummary(level, ps, pe, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let p;
        const arg20 = toString(pe, "yyyy-MM-dd");
        const arg10 = toString(ps, "yyyy-MM-dd");
        p = toText(printf("?start=%s\u0026end=%s"))(arg10)(arg20);
        let requestPath;
        const arg10_1 = ReportLevel__ToPeriodSummaryUrl(level);
        requestPath = toText(printf("%s%s"))(arg10_1)(p);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Waste_PeriodSummary$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Waste_PeriodSummary$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchTypeSummary(level, ps, pe, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let p;
        const arg20 = toString(pe, "yyyy-MM-dd");
        const arg10 = toString(ps, "yyyy-MM-dd");
        p = toText(printf("?start=%s\u0026end=%s"))(arg10)(arg20);
        let requestPath;
        const arg10_1 = ReportLevel__ToTypeSummaryUrl(level);
        requestPath = toText(printf("%s%s"))(arg10_1)(p);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Waste_TypeSummary$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Waste_TypeSummary$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchRegStatus(level, ps, pe, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let p;
        const arg20 = toString(pe, "yyyy-MM-dd");
        const arg10 = toString(ps, "yyyy-MM-dd");
        p = toText(printf("?start=%s\u0026end=%s"))(arg10)(arg20);
        let requestPath;
        const arg10_1 = ReportLevel__ToStatusUrl(level);
        requestPath = toText(printf("%s%s"))(arg10_1)(p);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(RegistrationStatus$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(RegistrationStatus$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function sectionHolder(grow, x) {
    return createElement("div", {
        style: createObj(toList(delay(() => append(singleton(["border", (((1 + "px ") + "solid") + " ") + "#dedede"]), delay(() => append(singleton(["borderRadius", 5]), delay(() => append(singleton(["padding", 20]), delay(() => (grow ? singleton(["flexGrow", 1]) : empty_1())))))))))),
        children: Interop_reactApi.Children.toArray(Array.from(x)),
    });
}

function sectionTitle(x) {
    return MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", x]]);
}

function registration(x) {
    return createElement("div", {
        style: {
            display: "inline-block",
            marginRight: 8 + "px",
            marginBottom: 8 + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                width: 120 + "px",
                height: 70 + "px",
                alignItems: "center",
                border: "solid 2px #ddd",
                borderRadius: 10,
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    flex: 0.5,
                    width: 100 + "%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: x.Type.Dangerous ? "#962318" : ((!x.Type.Sorted) ? "#ffa500" : "#1a8a38"),
                    ["border-top-left-radius"]: 8 + "px",
                    ["border-top-right-radius"]: 8 + "px",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("span", {
                    style: {
                        fontWeight: "bold",
                        fontSize: 0.8 + "rem",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                    },
                    children: (x.Type.Name.length > 11) ? toText(interpolate("%P() %P()..", [x.Type.Code, x.Type.Name.slice(0, 14 + 1)])) : toText(interpolate("%P() %P()", [x.Type.Code, x.Type.Name])),
                })), delay(() => ((x.Type.Name.length > 16) ? singleton(createElement("span", {
                    ["data-toggle"]: "tooltip",
                    ["data-placement"]: "top",
                    title: x.Type.Name,
                    style: {
                        fontSize: 0.8 + "rem",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: "fas fa-question-circle",
                    })]),
                })) : empty_1()))))))),
            }), createElement("div", {
                style: {
                    flex: 0.5,
                    width: 100 + "%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#edf1f2",
                    ["border-bottom-left-radius"]: 10,
                    ["border-bottom-right-radius"]: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    style: {
                        fontWeight: "bold",
                    },
                    children: format('{0:' + "0.00" + '}', x.Quantity),
                })]),
            })]),
        })]),
    });
}

function drawWasteDistribution(typeSummary) {
    return createElement("div", {
        style: {
            paddingLeft: 15 + "px",
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            if (typeSummary != null) {
                const x = typeSummary;
                return singleton(FunctionComponent_Of_Z5A158BBF(() => {
                    const patternInput = useFeliz_React__React_useState_Static_1505(false);
                    const showSummary = patternInput[0];
                    return toArray(toList(delay(() => append(singleton(MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["startIcon", createElement("span", {
                        className: "fas fa-balance-scale",
                    })], ["children", showSummary ? "Skjul avfallstype fordeling" : "Vis avfallstype fordeling"], ["style", {
                        marginBottom: 5 + "px",
                    }], ["onClick", (_arg1) => {
                        patternInput[1](!showSummary);
                    }]])), delay(() => (showSummary ? append(singleton(createElement("div", {
                        style: {
                            display: "flex",
                            flexWrap: "wrap",
                        },
                        children: Interop_reactApi.Children.toArray([map((x_2) => registration(x_2), sortByDescending((y_1) => y_1.Type.Sorted, x.filter((y) => (!y.Type.Dangerous)), {
                            Compare: (x_1, y_2) => comparePrimitives(x_1, y_2),
                        }))]),
                    })), delay(() => singleton(createElement("div", {
                        style: {
                            display: "flex",
                            flexWrap: "wrap",
                        },
                        children: Interop_reactApi.Children.toArray([map((x_4) => registration(x_4), sortByDescending((y_4) => y_4.Type.Sorted, x.filter((y_3) => y_3.Type.Dangerous), {
                            Compare: (x_3, y_5) => comparePrimitives(x_3, y_5),
                        }))]),
                    })))) : empty_1()))))));
                }, void 0, uncurry(2, void 0), void 0, "drawWasteDistribution", "/builds/serit/derigo/tenant/src/Client/Report/WasteReport.fs", 302)());
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        })))),
    });
}

function drawPeriodSummary(periodSummary, typeSummary) {
    const summaryTitle = (ttl) => createElement("span", {
        style: {
            fontSize: 0.9 + "rem",
            color: "#444",
        },
        children: ttl,
    });
    const summaryValue = (vl) => {
        if (vl != null) {
            const vl$0027 = vl;
            return createElement("span", {
                style: {
                    fontSize: 3 + "rem",
                    fontWeight: "bold",
                    fontStyle: "italic",
                },
                children: vl$0027,
            });
        }
        else {
            return MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                width: 150,
                minHeight: 60,
            }]]);
        }
    };
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            gap: 10 + "px ",
            flexGrow: 1,
        },
        children: Interop_reactApi.Children.toArray([sectionHolder(true, [createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                padding: 10,
                height: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    flex: 0.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                },
                children: Interop_reactApi.Children.toArray([summaryTitle("Sorteringsgrad valgt periode"), summaryValue(map_1((ps) => {
                    const arg10 = format('{0:' + "0.00" + '}', ps.Rate);
                    return toText(printf("%s%%"))(arg10);
                }, periodSummary))]),
            }), createElement("div", {
                style: {
                    flex: 0.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                },
                children: Interop_reactApi.Children.toArray([summaryTitle("Avfall/kvadratmeter(kg/m2)"), summaryValue(map_1((ps_1) => {
                    if (ps_1.ShowWasteSqrMtr) {
                        const arg10_1 = format('{0:' + "0.00" + '}', ps_1.WasteSqrMeter);
                        return toText(printf("%s"))(arg10_1);
                    }
                    else {
                        return "Avfall/kvadratmeter regnes ikke ut for dette prosjektet. Enten fordi det er kun ett anleggsprosjekt eller fordi areal ikke oppgis.";
                    }
                }, periodSummary))]),
            })]),
        })]), sectionHolder(false, toList(delay(() => {
            let children_12, children, value_66, children_2, value_68, children_4, value_70, children_6, value_72, children_8, value_74, children_10, value_76;
            if (periodSummary != null) {
                const x = periodSummary;
                return singleton(createElement("table", {
                    className: "table",
                    style: {
                        maxWidth: 500,
                        minWidth: 250,
                        margin: 0,
                    },
                    children: Interop_reactApi.Children.toArray([(children_12 = ofArray([(children = ofArray([createElement("td", {
                        children: ["Sum sortert ordinært avfall (tonn)"],
                    }), (value_66 = format('{0:' + "0.00" + '}', x.Sorted), createElement("td", {
                        children: [value_66],
                    }))]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    })), (children_2 = ofArray([createElement("td", {
                        children: ["Sum usortert ordinært avfall (tonn)"],
                    }), (value_68 = format('{0:' + "0.00" + '}', x.Unsorted), createElement("td", {
                        children: [value_68],
                    }))]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    })), (children_4 = ofArray([createElement("td", {
                        children: ["Sum ordinært avfall (tonn)"],
                    }), (value_70 = format('{0:' + "0.00" + '}', x.Ordinary), createElement("td", {
                        children: [value_70],
                    }))]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })), (children_6 = ofArray([createElement("td", {
                        children: ["Sum farlig avfall (tonn)"],
                    }), (value_72 = format('{0:' + "0.00" + '}', x.Dangerous), createElement("td", {
                        children: [value_72],
                    }))]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    })), (children_8 = ofArray([createElement("td", {
                        children: ["Sum avfall i alt (tonn)"],
                    }), (value_74 = format('{0:' + "0.00" + '}', x.Total), createElement("td", {
                        children: [value_74],
                    }))]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                    })), (children_10 = ofArray([createElement("td", {
                        children: ["Sum avfall ikke inkl. i sorteringsgrad (tonn)"],
                    }), (value_76 = format('{0:' + "0.00" + '}', x.NonCalculate), createElement("td", {
                        children: [value_76],
                    }))]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                    }))]), createElement("tbody", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                    }))]),
                }));
            }
            else {
                return singleton(MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                    minWidth: 250,
                    minHeight: 209,
                }]]));
            }
        })))]),
    });
}

function drawGraph(data) {
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([Interop_reactApi.createElement(ResponsiveContainer, {
            minWidth: 50 + "vw",
            minHeight: 300 + "px",
            children: Interop_reactApi.createElement(BarChart, {
                width: 800,
                height: 300,
                data: data,
                children: ["children", Interop_reactApi.Children.toArray([Interop_reactApi.createElement(CartesianGrid, {
                    strokeDasharray: join(" ", map((value_12) => int32ToString(value_12), new Int32Array([3, 3]))),
                }), Interop_reactApi.createElement(XAxis, {
                    dataKey: (point) => point.name,
                    tickCount: 0,
                    tick: (_arg1) => null,
                }), Interop_reactApi.createElement(YAxis, {}), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(Bar, {
                    dataKey: (point_1) => point_1.quantity,
                    children: ["children", Interop_reactApi.Children.toArray([map((x) => Interop_reactApi.createElement(Cell, {
                        fill: x.dangerous ? "#962318" : ((!x.sorted) ? "#ffa500" : "#1a8a38"),
                    }), data)])],
                })])],
            }),
        })]),
    });
}

function drawSummary(model) {
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            gap: 10 + "px ",
        },
        children: Interop_reactApi.Children.toArray([drawPeriodSummary(model.PeriodSummary, model.TypeSummary)]),
    });
}

function greenCheck() {
    return createElement("i", {
        style: {
            color: "green",
        },
        className: "fas fa-check",
    });
}

function redX() {
    return createElement("i", {
        style: {
            color: "red",
        },
        className: "fas fa-times",
    });
}

export function helperText(txt, isValid) {
    return createElement("span", {
        style: {
            fontSize: 0.7 + "rem",
            marginRight: 15,
            display: "block",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(isValid ? singleton(greenCheck()) : singleton(redX()), delay(() => singleton(createElement("span", {
            style: {
                marginLeft: 5,
            },
            children: txt,
        })))))))),
    });
}

function registrationStatus(status, periodStart, periodEnd) {
    const months = monthsInPeriod(periodStart, periodEnd);
    return createElement("div", {
        style: {
            flexGrow: 1,
            overflowX: "auto",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let children_2, children, children_6;
            if (status != null) {
                const grouped = map((tupledArg) => [tupledArg[0], sortByDescending((a) => [a.Year, a.Month], tupledArg[1], {
                    Compare: (x_2, y_3) => compareArrays(x_2, y_3),
                })], Array_groupBy((y) => y.Source, status, {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: (x_1) => stringHash(x_1),
                }));
                return singleton(MuiHelpers_createElement(Table, [["size", "small"], ["style", {
                    margin: 0,
                }], ["children", Interop_reactApi.Children.toArray([(children_2 = singleton_1((children = ofArray([createElement("td", {}), map((tupledArg_1) => createElement("td", {
                    style: {
                        textAlign: "center",
                    },
                    children: toText(interpolate("%P()/%P()", [tupledArg_1[1], tupledArg_1[0]])),
                }), months)]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), (children_6 = singleton_1(map((tupledArg_2) => {
                    const children_4 = ofArray([createElement("th", {
                        children: [tupledArg_2[0]],
                    }), map((z_1) => createElement("td", {
                        style: {
                            textAlign: "center",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (z_1.Registrered ? singleton(greenCheck()) : singleton(redX())))))),
                    }), tupledArg_2[1])]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    });
                }, grouped)), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                }))])]]));
            }
            else {
                return singleton(MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                    width: 100 + "%",
                    minHeight: 100,
                }]]));
            }
        })))),
    });
}

function drawColorCodes() {
    const circle = (col, txt) => createElement("div", {
        style: {
            marginRight: 5 + "px",
            fontSize: 0.8 + "rem",
        },
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: "fas fa-circle",
            style: {
                color: col,
                marginRight: 5 + "px",
            },
        }), createElement("span", {
            children: [txt],
        })]),
    });
    return createElement("div", {
        className: "non-print-section",
        style: {
            display: "flex",
            flexWrap: "wrap",
        },
        children: Interop_reactApi.Children.toArray([circle("#962318", "Farlig avfall"), circle("#1a8a38", "Sortert avfall"), circle("#ffa500", "Usortert avfall")]),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        className: "report-content section-to-print",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(4));
        })), delay(() => append((model.Level.tag === 0) ? ((empty_1())) : singleton(sectionHolder(true, [createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
            },
            children: Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-info-circle",
                style: {
                    marginRight: 5 + "px",
                    fontSize: 1 + "rem",
                },
            }), "Prosjekter kun kategorisert som anleggsprosjekt inkluderes ikke i avfall/kvadratmeter utregning. Heller ikke prosjekter uten oppgitt areal."]),
        })])), delay(() => append(singleton(drawSummary(model)), delay(() => append(singleton(sectionHolder(true, toList(delay(() => append(singleton(sectionTitle("Fordeling avfallstyper")), delay(() => append(singleton(drawColorCodes()), delay(() => {
            const matchValue_1 = model.Chart;
            return (matchValue_1 == null) ? singleton(MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                width: 100 + "%",
                minHeight: 200,
            }]])) : singleton(drawGraph(matchValue_1));
        })))))))), delay(() => {
            let children;
            return singleton(sectionHolder(true, [sectionTitle("Registreringsstatus"), (children = toList(delay(() => {
                const matchValue_2 = model.Level;
                switch (matchValue_2.tag) {
                    case 1: {
                        return append(singleton(helperText("Alle påkrevde Avfallstyper registrert for gitt prosjekt for gitt måned", true)), delay(() => singleton(helperText("En eller flere påkrevde Avfallstyper er ikke registrert for gitt prosjekt for gitt måned", false))));
                    }
                    case 2: {
                        return append(singleton(helperText("Alle påkrevde Avfallstyper er registrert for alle prosjekter for gitt selskap for gitt måned", true)), delay(() => singleton(helperText("En eller flere påkrevde Avfallstyper er ikke registrert for en eller flere prosjekter for gitt selskap for gitt måned", false))));
                    }
                    default: {
                        return append(singleton(helperText("Avfallstype registrert for gitt måned", true)), delay(() => singleton(helperText("Avfallstype ikke registrert for gitt måned", false))));
                    }
                }
            })), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "center",
                },
                children: Interop_reactApi.Children.toArray([registrationStatus(model.Status, model.PeriodStart, model.PeriodEnd)]),
            })]));
        })))))))))))),
    });
}

export function WasteReport(wasteReportInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(wasteReportInputProps.level, wasteReportInputProps.pStart, wasteReportInputProps.pEnd));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchTypes(dispatch);
        fetchPeriodSummary(model_1.Level, model_1.PeriodStart, model_1.PeriodEnd, dispatch);
        fetchTypeSummary(model_1.Level, model_1.PeriodStart, model_1.PeriodEnd, dispatch);
        fetchRegStatus(model_1.Level, model_1.PeriodStart, model_1.PeriodEnd, dispatch);
    });
    return view(model_1, dispatch);
}

