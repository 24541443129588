import { Record } from "../.fable/fable-library.3.2.4/Types.js";
import { tuple_type, anonRecord_type, option_type, list_type, float64_type, class_type, array_type, bool_type, record_type, string_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { now, day, month, year, create } from "../.fable/fable-library.3.2.4/Date.js";

export class Helpers_IdName extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function Helpers_IdName$reflection() {
    return record_type("ApiDataTypes.Helpers.IdName", [], Helpers_IdName, () => [["Id", int32_type], ["Name", string_type]]);
}

export function Helpers_IdName_get_Empty() {
    return new Helpers_IdName(0, "");
}

export class Helpers_IdNameDesc extends Record {
    constructor(id, name, desc) {
        super();
        this.id = (id | 0);
        this.name = name;
        this.desc = desc;
    }
}

export function Helpers_IdNameDesc$reflection() {
    return record_type("ApiDataTypes.Helpers.IdNameDesc", [], Helpers_IdNameDesc, () => [["id", int32_type], ["name", string_type], ["desc", string_type]]);
}

export class Helpers_IdNameCodeDesc extends Record {
    constructor(id, name, code, desc) {
        super();
        this.id = (id | 0);
        this.name = name;
        this.code = code;
        this.desc = desc;
    }
}

export function Helpers_IdNameCodeDesc$reflection() {
    return record_type("ApiDataTypes.Helpers.IdNameCodeDesc", [], Helpers_IdNameCodeDesc, () => [["id", int32_type], ["name", string_type], ["code", string_type], ["desc", string_type]]);
}

export class Helpers_IdNameRowpos extends Record {
    constructor(id, name, rowpos) {
        super();
        this.id = (id | 0);
        this.name = name;
        this.rowpos = (rowpos | 0);
    }
}

export function Helpers_IdNameRowpos$reflection() {
    return record_type("ApiDataTypes.Helpers.IdNameRowpos", [], Helpers_IdNameRowpos, () => [["id", int32_type], ["name", string_type], ["rowpos", int32_type]]);
}

export class Helpers_ResultMessage extends Record {
    constructor(Result, Message) {
        super();
        this.Result = Result;
        this.Message = Message;
    }
}

export function Helpers_ResultMessage$reflection() {
    return record_type("ApiDataTypes.Helpers.ResultMessage", [], Helpers_ResultMessage, () => [["Result", string_type], ["Message", string_type]]);
}

export class Helpers_Date extends Record {
    constructor(Year, Month, Day) {
        super();
        this.Year = (Year | 0);
        this.Month = (Month | 0);
        this.Day = (Day | 0);
    }
    toString() {
        const this$ = this;
        return toText(printf("%i-%i-%i"))(this$.Year)(this$.Month)(this$.Day);
    }
}

export function Helpers_Date$reflection() {
    return record_type("ApiDataTypes.Helpers.Date", [], Helpers_Date, () => [["Year", int32_type], ["Month", int32_type], ["Day", int32_type]]);
}

export function Helpers_Date__ToLocalDate(this$) {
    return toText(printf("%i/%i/%i"))(this$.Day)(this$.Month)(this$.Year);
}

export function Helpers_Date__ToDateTime(this$) {
    return create(this$.Year, this$.Month, this$.Day, 0, 0, 0);
}

export function Helpers_Date_FromDateTime_7F9DDECF(x) {
    return new Helpers_Date(year(x), month(x), day(x));
}

export class Helpers_TimePeriod extends Record {
    constructor(PeriodStart, PeriodEnd) {
        super();
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
    }
}

export function Helpers_TimePeriod$reflection() {
    return record_type("ApiDataTypes.Helpers.TimePeriod", [], Helpers_TimePeriod, () => [["PeriodStart", Helpers_Date$reflection()], ["PeriodEnd", Helpers_Date$reflection()]]);
}

export class LoginForm extends Record {
    constructor(Username, Password, Persist) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.Persist = Persist;
    }
}

export function LoginForm$reflection() {
    return record_type("ApiDataTypes.LoginForm", [], LoginForm, () => [["Username", string_type], ["Password", string_type], ["Persist", bool_type]]);
}

export class LoginResponse extends Record {
    constructor(Username, Firstname, Lastname, Company, Department, Office, PhoneCountryCode, Phone, Email, Title) {
        super();
        this.Username = Username;
        this.Firstname = Firstname;
        this.Lastname = Lastname;
        this.Company = Company;
        this.Department = Department;
        this.Office = Office;
        this.PhoneCountryCode = PhoneCountryCode;
        this.Phone = Phone;
        this.Email = Email;
        this.Title = Title;
    }
}

export function LoginResponse$reflection() {
    return record_type("ApiDataTypes.LoginResponse", [], LoginResponse, () => [["Username", string_type], ["Firstname", string_type], ["Lastname", string_type], ["Company", string_type], ["Department", string_type], ["Office", string_type], ["PhoneCountryCode", string_type], ["Phone", string_type], ["Email", string_type], ["Title", string_type]]);
}

export class KeyValue extends Record {
    constructor(Key, Value) {
        super();
        this.Key = Key;
        this.Value = Value;
    }
}

export function KeyValue$reflection() {
    return record_type("ApiDataTypes.KeyValue", [], KeyValue, () => [["Key", string_type], ["Value", string_type]]);
}

export function KeyValue_get_Empty() {
    return new KeyValue("", "");
}

export class Modules extends Record {
    constructor(Auth, Process) {
        super();
        this.Auth = Auth;
        this.Process = Process;
    }
}

export function Modules$reflection() {
    return record_type("ApiDataTypes.Modules", [], Modules, () => [["Auth", string_type], ["Process", string_type]]);
}

export class BasicAuth_Office extends Record {
    constructor(Uuid, Company, CompanyUuid, Name, Enabled) {
        super();
        this.Uuid = Uuid;
        this.Company = Company;
        this.CompanyUuid = CompanyUuid;
        this.Name = Name;
        this.Enabled = Enabled;
    }
}

export function BasicAuth_Office$reflection() {
    return record_type("ApiDataTypes.BasicAuth.Office", [], BasicAuth_Office, () => [["Uuid", string_type], ["Company", string_type], ["CompanyUuid", string_type], ["Name", string_type], ["Enabled", bool_type]]);
}

export class BasicAuth_Department extends Record {
    constructor(Uuid, Name, Enabled) {
        super();
        this.Uuid = Uuid;
        this.Name = Name;
        this.Enabled = Enabled;
    }
}

export function BasicAuth_Department$reflection() {
    return record_type("ApiDataTypes.BasicAuth.Department", [], BasicAuth_Department, () => [["Uuid", string_type], ["Name", string_type], ["Enabled", bool_type]]);
}

export class BasicAuth_Company extends Record {
    constructor(Uuid, Name, Offices, Enabled) {
        super();
        this.Uuid = Uuid;
        this.Name = Name;
        this.Offices = Offices;
        this.Enabled = Enabled;
    }
}

export function BasicAuth_Company$reflection() {
    return record_type("ApiDataTypes.BasicAuth.Company", [], BasicAuth_Company, () => [["Uuid", string_type], ["Name", string_type], ["Offices", array_type(BasicAuth_Office$reflection())], ["Enabled", bool_type]]);
}

export class BasicAuth_User extends Record {
    constructor(Uuid, Username, Firstname, Lastname, Title, Email, Phone, PhoneCountryCode, DepartmentUuid, OfficeUuid, Activated) {
        super();
        this.Uuid = Uuid;
        this.Username = Username;
        this.Firstname = Firstname;
        this.Lastname = Lastname;
        this.Title = Title;
        this.Email = Email;
        this.Phone = Phone;
        this.PhoneCountryCode = PhoneCountryCode;
        this.DepartmentUuid = DepartmentUuid;
        this.OfficeUuid = OfficeUuid;
        this.Activated = Activated;
    }
}

export function BasicAuth_User$reflection() {
    return record_type("ApiDataTypes.BasicAuth.User", [], BasicAuth_User, () => [["Uuid", string_type], ["Username", string_type], ["Firstname", string_type], ["Lastname", string_type], ["Title", string_type], ["Email", string_type], ["Phone", string_type], ["PhoneCountryCode", string_type], ["DepartmentUuid", string_type], ["OfficeUuid", string_type], ["Activated", bool_type]]);
}

export class User extends Record {
    constructor(Id, Username, Firstname, Lastname, Company, CompanyId, Office, OfficeId, Title, Email, Phone) {
        super();
        this.Id = (Id | 0);
        this.Username = Username;
        this.Firstname = Firstname;
        this.Lastname = Lastname;
        this.Company = Company;
        this.CompanyId = (CompanyId | 0);
        this.Office = Office;
        this.OfficeId = (OfficeId | 0);
        this.Title = Title;
        this.Email = Email;
        this.Phone = Phone;
    }
}

export function User$reflection() {
    return record_type("ApiDataTypes.User", [], User, () => [["Id", int32_type], ["Username", string_type], ["Firstname", string_type], ["Lastname", string_type], ["Company", string_type], ["CompanyId", int32_type], ["Office", string_type], ["OfficeId", int32_type], ["Title", string_type], ["Email", string_type], ["Phone", string_type]]);
}

export class UserAllocation extends Record {
    constructor(user, allocated, overlapsproj, overlapsvac) {
        super();
        this.user = user;
        this.allocated = (allocated | 0);
        this.overlapsproj = overlapsproj;
        this.overlapsvac = overlapsvac;
    }
}

export function UserAllocation$reflection() {
    return record_type("ApiDataTypes.UserAllocation", [], UserAllocation, () => [["user", User$reflection()], ["allocated", int32_type], ["overlapsproj", bool_type], ["overlapsvac", bool_type]]);
}

export class UserTimelineProject extends Record {
    constructor(ProjectId, Project, PeriodStart, PeriodEnd, Allocated, Role, RoleId) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Project = Project;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.Allocated = (Allocated | 0);
        this.Role = Role;
        this.RoleId = (RoleId | 0);
    }
}

export function UserTimelineProject$reflection() {
    return record_type("ApiDataTypes.UserTimelineProject", [], UserTimelineProject, () => [["ProjectId", int32_type], ["Project", string_type], ["PeriodStart", string_type], ["PeriodEnd", string_type], ["Allocated", int32_type], ["Role", string_type], ["RoleId", int32_type]]);
}

export class UserTimelineVacation extends Record {
    constructor(Id, PeriodStart, PeriodEnd) {
        super();
        this.Id = (Id | 0);
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
    }
}

export function UserTimelineVacation$reflection() {
    return record_type("ApiDataTypes.UserTimelineVacation", [], UserTimelineVacation, () => [["Id", int32_type], ["PeriodStart", string_type], ["PeriodEnd", string_type]]);
}

export class UserTimelineEntry extends Record {
    constructor(User, Projects, Vacations) {
        super();
        this.User = User;
        this.Projects = Projects;
        this.Vacations = Vacations;
    }
}

export function UserTimelineEntry$reflection() {
    return record_type("ApiDataTypes.UserTimelineEntry", [], UserTimelineEntry, () => [["User", User$reflection()], ["Projects", array_type(UserTimelineProject$reflection())], ["Vacations", array_type(UserTimelineVacation$reflection())]]);
}

export class UserInfoProjectRole extends Record {
    constructor(projectid, projectname, rolename, allocated, active, plannedStart, plannedEnd) {
        super();
        this.projectid = (projectid | 0);
        this.projectname = projectname;
        this.rolename = rolename;
        this.allocated = (allocated | 0);
        this.active = active;
        this.plannedStart = plannedStart;
        this.plannedEnd = plannedEnd;
    }
}

export function UserInfoProjectRole$reflection() {
    return record_type("ApiDataTypes.UserInfoProjectRole", [], UserInfoProjectRole, () => [["projectid", int32_type], ["projectname", string_type], ["rolename", string_type], ["allocated", int32_type], ["active", bool_type], ["plannedStart", class_type("System.DateTime")], ["plannedEnd", class_type("System.DateTime")]]);
}

export class UserInfo extends Record {
    constructor(Id, FirstName, LastName, Company, Office, Department, Email, Phone, Title, Username, Projects, Education, Courses) {
        super();
        this.Id = (Id | 0);
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.Company = Company;
        this.Office = Office;
        this.Department = Department;
        this.Email = Email;
        this.Phone = Phone;
        this.Title = Title;
        this.Username = Username;
        this.Projects = Projects;
        this.Education = Education;
        this.Courses = Courses;
    }
}

export function UserInfo$reflection() {
    return record_type("ApiDataTypes.UserInfo", [], UserInfo, () => [["Id", int32_type], ["FirstName", string_type], ["LastName", string_type], ["Company", string_type], ["Office", string_type], ["Department", string_type], ["Email", string_type], ["Phone", string_type], ["Title", string_type], ["Username", string_type], ["Projects", array_type(UserInfoProjectRole$reflection())], ["Education", array_type(string_type)], ["Courses", array_type(string_type)]]);
}

export class UserIdentity extends Record {
    constructor(userid, desc, keycompetencies, language, birthday, position) {
        super();
        this.userid = (userid | 0);
        this.desc = desc;
        this.keycompetencies = keycompetencies;
        this.language = language;
        this.birthday = birthday;
        this.position = position;
    }
}

export function UserIdentity$reflection() {
    return record_type("ApiDataTypes.UserIdentity", [], UserIdentity, () => [["userid", int32_type], ["desc", string_type], ["keycompetencies", string_type], ["language", string_type], ["birthday", string_type], ["position", string_type]]);
}

export class UserReferenceProject extends Record {
    constructor(ProjectId, ProjectName, PeriodStart, PeriodEnd, Desc, Categories, ContractSum, Role, Builder) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.ProjectName = ProjectName;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.Desc = Desc;
        this.Categories = Categories;
        this.ContractSum = (ContractSum | 0);
        this.Role = Role;
        this.Builder = Builder;
    }
}

export function UserReferenceProject$reflection() {
    return record_type("ApiDataTypes.UserReferenceProject", [], UserReferenceProject, () => [["ProjectId", int32_type], ["ProjectName", string_type], ["PeriodStart", class_type("System.DateTime")], ["PeriodEnd", class_type("System.DateTime")], ["Desc", string_type], ["Categories", array_type(string_type)], ["ContractSum", int32_type], ["Role", string_type], ["Builder", string_type]]);
}

export class ExternalProjectReference extends Record {
    constructor(Id, UserId, ExternalRole, ExternalCompany, Project, Desc, Builder, ContactPerson, PeriodStart, PeriodEnd) {
        super();
        this.Id = (Id | 0);
        this.UserId = (UserId | 0);
        this.ExternalRole = ExternalRole;
        this.ExternalCompany = ExternalCompany;
        this.Project = Project;
        this.Desc = Desc;
        this.Builder = Builder;
        this.ContactPerson = ContactPerson;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
    }
}

export function ExternalProjectReference$reflection() {
    return record_type("ApiDataTypes.ExternalProjectReference", [], ExternalProjectReference, () => [["Id", int32_type], ["UserId", int32_type], ["ExternalRole", string_type], ["ExternalCompany", string_type], ["Project", string_type], ["Desc", string_type], ["Builder", string_type], ["ContactPerson", string_type], ["PeriodStart", string_type], ["PeriodEnd", string_type]]);
}

export class AttestationForm extends Record {
    constructor(ParentId, Content, Filename, FileExt, OwnerId) {
        super();
        this.ParentId = (ParentId | 0);
        this.Content = Content;
        this.Filename = Filename;
        this.FileExt = FileExt;
        this.OwnerId = (OwnerId | 0);
    }
}

export function AttestationForm$reflection() {
    return record_type("ApiDataTypes.AttestationForm", [], AttestationForm, () => [["ParentId", int32_type], ["Content", string_type], ["Filename", string_type], ["FileExt", string_type], ["OwnerId", int32_type]]);
}

export class Attestation extends Record {
    constructor(Id, ParentId, Filename, OwnerId) {
        super();
        this.Id = (Id | 0);
        this.ParentId = (ParentId | 0);
        this.Filename = Filename;
        this.OwnerId = (OwnerId | 0);
    }
}

export function Attestation$reflection() {
    return record_type("ApiDataTypes.Attestation", [], Attestation, () => [["Id", int32_type], ["ParentId", int32_type], ["Filename", string_type], ["OwnerId", int32_type]]);
}

export class UserEmployment extends Record {
    constructor(Id, UserId, Company, StartMonth, StartYear, EndMonth, EndYear, Desc, Attestations) {
        super();
        this.Id = (Id | 0);
        this.UserId = (UserId | 0);
        this.Company = Company;
        this.StartMonth = (StartMonth | 0);
        this.StartYear = (StartYear | 0);
        this.EndMonth = (EndMonth | 0);
        this.EndYear = (EndYear | 0);
        this.Desc = Desc;
        this.Attestations = Attestations;
    }
}

export function UserEmployment$reflection() {
    return record_type("ApiDataTypes.UserEmployment", [], UserEmployment, () => [["Id", int32_type], ["UserId", int32_type], ["Company", string_type], ["StartMonth", int32_type], ["StartYear", int32_type], ["EndMonth", int32_type], ["EndYear", int32_type], ["Desc", string_type], ["Attestations", array_type(Attestation$reflection())]]);
}

export class UserEducation extends Record {
    constructor(Id, UserId, Education, StartMonth, StartYear, EndMonth, EndYear, Level, Attestations) {
        super();
        this.Id = (Id | 0);
        this.UserId = (UserId | 0);
        this.Education = Education;
        this.StartMonth = (StartMonth | 0);
        this.StartYear = (StartYear | 0);
        this.EndMonth = (EndMonth | 0);
        this.EndYear = (EndYear | 0);
        this.Level = Level;
        this.Attestations = Attestations;
    }
}

export function UserEducation$reflection() {
    return record_type("ApiDataTypes.UserEducation", [], UserEducation, () => [["Id", int32_type], ["UserId", int32_type], ["Education", string_type], ["StartMonth", int32_type], ["StartYear", int32_type], ["EndMonth", int32_type], ["EndYear", int32_type], ["Level", Helpers_IdName$reflection()], ["Attestations", array_type(Attestation$reflection())]]);
}

export class UserListEntry extends Record {
    constructor(Id, Username, Fullname, Phone, Company, CompanyId, Office, OfficeId, Title, Department, Education, Allocated) {
        super();
        this.Id = (Id | 0);
        this.Username = Username;
        this.Fullname = Fullname;
        this.Phone = Phone;
        this.Company = Company;
        this.CompanyId = (CompanyId | 0);
        this.Office = Office;
        this.OfficeId = (OfficeId | 0);
        this.Title = Title;
        this.Department = Department;
        this.Education = Education;
        this.Allocated = (Allocated | 0);
    }
}

export function UserListEntry$reflection() {
    return record_type("ApiDataTypes.UserListEntry", [], UserListEntry, () => [["Id", int32_type], ["Username", string_type], ["Fullname", string_type], ["Phone", string_type], ["Company", string_type], ["CompanyId", int32_type], ["Office", string_type], ["OfficeId", int32_type], ["Title", string_type], ["Department", string_type], ["Education", array_type(UserEducation$reflection())], ["Allocated", int32_type]]);
}

export class UserPictureForm extends Record {
    constructor(UserId, Content, Filename) {
        super();
        this.UserId = (UserId | 0);
        this.Content = Content;
        this.Filename = Filename;
    }
}

export function UserPictureForm$reflection() {
    return record_type("ApiDataTypes.UserPictureForm", [], UserPictureForm, () => [["UserId", int32_type], ["Content", string_type], ["Filename", string_type]]);
}

export class UserVacation extends Record {
    constructor(id, userid, period) {
        super();
        this.id = (id | 0);
        this.userid = (userid | 0);
        this.period = period;
    }
}

export function UserVacation$reflection() {
    return record_type("ApiDataTypes.UserVacation", [], UserVacation, () => [["id", int32_type], ["userid", int32_type], ["period", Helpers_TimePeriod$reflection()]]);
}

export class ReportProject extends Record {
    constructor(Id, Name, CompanyId, Company, Hms, Waste, Start, End) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.CompanyId = (CompanyId | 0);
        this.Company = Company;
        this.Hms = Hms;
        this.Waste = Waste;
        this.Start = Start;
        this.End = End;
    }
}

export function ReportProject$reflection() {
    return record_type("ApiDataTypes.ReportProject", [], ReportProject, () => [["Id", int32_type], ["Name", string_type], ["CompanyId", int32_type], ["Company", string_type], ["Hms", bool_type], ["Waste", bool_type], ["Start", string_type], ["End", string_type]]);
}

export class ProjectMeta extends Record {
    constructor(Id, Name, OfficeId, Office, CompanyId, Company, Start, End) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.OfficeId = (OfficeId | 0);
        this.Office = Office;
        this.CompanyId = (CompanyId | 0);
        this.Company = Company;
        this.Start = Start;
        this.End = End;
    }
}

export function ProjectMeta$reflection() {
    return record_type("ApiDataTypes.ProjectMeta", [], ProjectMeta, () => [["Id", int32_type], ["Name", string_type], ["OfficeId", int32_type], ["Office", string_type], ["CompanyId", int32_type], ["Company", string_type], ["Start", string_type], ["End", string_type]]);
}

export class ProjectInfo extends Record {
    constructor(projectname, contractsum, bruttoareal, shortdescription, projectperiod, description, climategoal, images) {
        super();
        this.projectname = projectname;
        this.contractsum = contractsum;
        this.bruttoareal = (bruttoareal | 0);
        this.shortdescription = shortdescription;
        this.projectperiod = projectperiod;
        this.description = description;
        this.climategoal = climategoal;
        this.images = images;
    }
}

export function ProjectInfo$reflection() {
    return record_type("ApiDataTypes.ProjectInfo", [], ProjectInfo, () => [["projectname", string_type], ["contractsum", float64_type], ["bruttoareal", int32_type], ["shortdescription", string_type], ["projectperiod", string_type], ["description", string_type], ["climategoal", string_type], ["images", list_type(string_type)]]);
}

export class ProjectCardInfo extends Record {
    constructor(Name, Builder, Category, Areal, ContractSum, EnterpriseForm, Period, Role, Desc) {
        super();
        this.Name = Name;
        this.Builder = Builder;
        this.Category = Category;
        this.Areal = Areal;
        this.ContractSum = ContractSum;
        this.EnterpriseForm = EnterpriseForm;
        this.Period = Period;
        this.Role = Role;
        this.Desc = Desc;
    }
}

export function ProjectCardInfo$reflection() {
    return record_type("ApiDataTypes.ProjectCardInfo", [], ProjectCardInfo, () => [["Name", string_type], ["Builder", string_type], ["Category", string_type], ["Areal", string_type], ["ContractSum", string_type], ["EnterpriseForm", string_type], ["Period", string_type], ["Role", string_type], ["Desc", string_type]]);
}

export class UserPermission extends Record {
    constructor(user, permissions) {
        super();
        this.user = user;
        this.permissions = permissions;
    }
}

export function UserPermission$reflection() {
    return record_type("ApiDataTypes.UserPermission", [], UserPermission, () => [["user", User$reflection()], ["permissions", list_type(int32_type)]]);
}

export class UserGroupUsers extends Record {
    constructor(groupid, userpermissions) {
        super();
        this.groupid = (groupid | 0);
        this.userpermissions = userpermissions;
    }
}

export function UserGroupUsers$reflection() {
    return record_type("ApiDataTypes.UserGroupUsers", [], UserGroupUsers, () => [["groupid", int32_type], ["userpermissions", list_type(UserPermission$reflection())]]);
}

export class SearchInput extends Record {
    constructor(Term, RequestId) {
        super();
        this.Term = Term;
        this.RequestId = (RequestId | 0);
    }
}

export function SearchInput$reflection() {
    return record_type("ApiDataTypes.SearchInput", [], SearchInput, () => [["Term", string_type], ["RequestId", int32_type]]);
}

export class PermissionSearchResult extends Record {
    constructor(requestid, users) {
        super();
        this.requestid = (requestid | 0);
        this.users = users;
    }
}

export function PermissionSearchResult$reflection() {
    return record_type("ApiDataTypes.PermissionSearchResult", [], PermissionSearchResult, () => [["requestid", int32_type], ["users", list_type(UserPermission$reflection())]]);
}

export class PermissionEntry extends Record {
    constructor(userid, groupid) {
        super();
        this.userid = (userid | 0);
        this.groupid = (groupid | 0);
    }
}

export function PermissionEntry$reflection() {
    return record_type("ApiDataTypes.PermissionEntry", [], PermissionEntry, () => [["userid", int32_type], ["groupid", int32_type]]);
}

export class UserGroup extends Record {
    constructor(Id, Name, Desc, Enabled, AutoAdd) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.Enabled = Enabled;
        this.AutoAdd = AutoAdd;
    }
}

export function UserGroup$reflection() {
    return record_type("ApiDataTypes.UserGroup", [], UserGroup, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["Enabled", bool_type], ["AutoAdd", bool_type]]);
}

export class UserGroupAccess extends Record {
    constructor(groupid, functionality) {
        super();
        this.groupid = (groupid | 0);
        this.functionality = functionality;
    }
}

export function UserGroupAccess$reflection() {
    return record_type("ApiDataTypes.UserGroupAccess", [], UserGroupAccess, () => [["groupid", int32_type], ["functionality", list_type(int32_type)]]);
}

export class ProjectPermission extends Record {
    constructor(Id, ProjectRoleId, PermissionId) {
        super();
        this.Id = (Id | 0);
        this.ProjectRoleId = (ProjectRoleId | 0);
        this.PermissionId = (PermissionId | 0);
    }
}

export function ProjectPermission$reflection() {
    return record_type("ApiDataTypes.ProjectPermission", [], ProjectPermission, () => [["Id", int32_type], ["ProjectRoleId", int32_type], ["PermissionId", int32_type]]);
}

export class CompanyPermission extends Record {
    constructor(id, companyroleid, permissionid) {
        super();
        this.id = (id | 0);
        this.companyroleid = (companyroleid | 0);
        this.permissionid = (permissionid | 0);
    }
}

export function CompanyPermission$reflection() {
    return record_type("ApiDataTypes.CompanyPermission", [], CompanyPermission, () => [["id", int32_type], ["companyroleid", int32_type], ["permissionid", int32_type]]);
}

export class RegComment extends Record {
    constructor(Comment$, Registered, User, UserId) {
        super();
        this.Comment = Comment$;
        this.Registered = Registered;
        this.User = User;
        this.UserId = (UserId | 0);
    }
}

export function RegComment$reflection() {
    return record_type("ApiDataTypes.RegComment", [], RegComment, () => [["Comment", string_type], ["Registered", string_type], ["User", string_type], ["UserId", int32_type]]);
}

export class RegistrationStatus extends Record {
    constructor(Source, SourceId, SourceType, Month, Year, Registrered) {
        super();
        this.Source = Source;
        this.SourceId = (SourceId | 0);
        this.SourceType = SourceType;
        this.Month = (Month | 0);
        this.Year = (Year | 0);
        this.Registrered = Registrered;
    }
}

export function RegistrationStatus$reflection() {
    return record_type("ApiDataTypes.RegistrationStatus", [], RegistrationStatus, () => [["Source", string_type], ["SourceId", int32_type], ["SourceType", string_type], ["Month", int32_type], ["Year", int32_type], ["Registrered", bool_type]]);
}

export class Startpage_UserProject extends Record {
    constructor(Id, Name, Role, Allocated, Status) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Role = Role;
        this.Allocated = (Allocated | 0);
        this.Status = (Status | 0);
    }
}

export function Startpage_UserProject$reflection() {
    return record_type("ApiDataTypes.Startpage.UserProject", [], Startpage_UserProject, () => [["Id", int32_type], ["Name", string_type], ["Role", string_type], ["Allocated", int32_type], ["Status", int32_type]]);
}

export class Startpage_MissingHms extends Record {
    constructor(ProjectId, Project, Month, Year, HmsTypes) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Project = Project;
        this.Month = (Month | 0);
        this.Year = (Year | 0);
        this.HmsTypes = HmsTypes;
    }
}

export function Startpage_MissingHms$reflection() {
    return record_type("ApiDataTypes.Startpage.MissingHms", [], Startpage_MissingHms, () => [["ProjectId", int32_type], ["Project", string_type], ["Month", int32_type], ["Year", int32_type], ["HmsTypes", array_type(string_type)]]);
}

export class Hms_HmsType extends Record {
    constructor(Id, Name, Code, Required, WarnLimitMin, WarnLimitMax) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Code = Code;
        this.Required = Required;
        this.WarnLimitMin = (WarnLimitMin | 0);
        this.WarnLimitMax = (WarnLimitMax | 0);
    }
}

export function Hms_HmsType$reflection() {
    return record_type("ApiDataTypes.Hms.HmsType", [], Hms_HmsType, () => [["Id", int32_type], ["Name", string_type], ["Code", string_type], ["Required", bool_type], ["WarnLimitMin", int32_type], ["WarnLimitMax", int32_type]]);
}

export class Hms_ProjectInfo extends Record {
    constructor(Id, Name, PlannedStart, Start, Register) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.PlannedStart = PlannedStart;
        this.Start = Start;
        this.Register = Register;
    }
}

export function Hms_ProjectInfo$reflection() {
    return record_type("ApiDataTypes.Hms.ProjectInfo", [], Hms_ProjectInfo, () => [["Id", int32_type], ["Name", string_type], ["PlannedStart", string_type], ["Start", option_type(string_type)], ["Register", bool_type]]);
}

export class Hms_ProjectRegistration extends Record {
    constructor(Id, ProjectId, Registered, Quantity, Type) {
        super();
        this.Id = (Id | 0);
        this.ProjectId = (ProjectId | 0);
        this.Registered = Registered;
        this.Quantity = (Quantity | 0);
        this.Type = Type;
    }
}

export function Hms_ProjectRegistration$reflection() {
    return record_type("ApiDataTypes.Hms.ProjectRegistration", [], Hms_ProjectRegistration, () => [["Id", int32_type], ["ProjectId", int32_type], ["Registered", string_type], ["Quantity", int32_type], ["Type", Hms_HmsType$reflection()]]);
}

export class Hms_ProjectRegistrationListing extends Record {
    constructor(Registered, Sum, Quantity, Type) {
        super();
        this.Registered = Registered;
        this.Sum = (Sum | 0);
        this.Quantity = (Quantity | 0);
        this.Type = Type;
    }
}

export function Hms_ProjectRegistrationListing$reflection() {
    return record_type("ApiDataTypes.Hms.ProjectRegistrationListing", [], Hms_ProjectRegistrationListing, () => [["Registered", bool_type], ["Sum", int32_type], ["Quantity", int32_type], ["Type", Hms_HmsType$reflection()]]);
}

export class Hms_ReportEntry extends Record {
    constructor(Id, HmsTypeId, HmsTypeName, ReportDate, ByUser, Num, ProjectId) {
        super();
        this.Id = (Id | 0);
        this.HmsTypeId = (HmsTypeId | 0);
        this.HmsTypeName = HmsTypeName;
        this.ReportDate = ReportDate;
        this.ByUser = ByUser;
        this.Num = (Num | 0);
        this.ProjectId = (ProjectId | 0);
    }
}

export function Hms_ReportEntry$reflection() {
    return record_type("ApiDataTypes.Hms.ReportEntry", [], Hms_ReportEntry, () => [["Id", int32_type], ["HmsTypeId", int32_type], ["HmsTypeName", string_type], ["ReportDate", class_type("System.DateTime")], ["ByUser", string_type], ["Num", int32_type], ["ProjectId", int32_type]]);
}

export class Hms_EditEntry extends Record {
    constructor(Id, OldVal, NewVal, OldDate, NewDate, Comment$, EditedBy, EditedAt, NumEdits) {
        super();
        this.Id = (Id | 0);
        this.OldVal = (OldVal | 0);
        this.NewVal = (NewVal | 0);
        this.OldDate = OldDate;
        this.NewDate = NewDate;
        this.Comment = Comment$;
        this.EditedBy = EditedBy;
        this.EditedAt = EditedAt;
        this.NumEdits = (NumEdits | 0);
    }
}

export function Hms_EditEntry$reflection() {
    return record_type("ApiDataTypes.Hms.EditEntry", [], Hms_EditEntry, () => [["Id", int32_type], ["OldVal", int32_type], ["NewVal", int32_type], ["OldDate", string_type], ["NewDate", string_type], ["Comment", string_type], ["EditedBy", string_type], ["EditedAt", string_type], ["NumEdits", int32_type]]);
}

export class Hms_ControlEntry extends Record {
    constructor(Registration, Project, ProjectId, Company, CompanyId, Month, Year, Date$, Edit, ByUser) {
        super();
        this.Registration = Registration;
        this.Project = Project;
        this.ProjectId = (ProjectId | 0);
        this.Company = Company;
        this.CompanyId = (CompanyId | 0);
        this.Month = (Month | 0);
        this.Year = (Year | 0);
        this.Date = (Date$ | 0);
        this.Edit = Edit;
        this.ByUser = ByUser;
    }
}

export function Hms_ControlEntry$reflection() {
    return record_type("ApiDataTypes.Hms.ControlEntry", [], Hms_ControlEntry, () => [["Registration", Hms_ReportEntry$reflection()], ["Project", string_type], ["ProjectId", int32_type], ["Company", string_type], ["CompanyId", int32_type], ["Month", int32_type], ["Year", int32_type], ["Date", int32_type], ["Edit", option_type(Hms_EditEntry$reflection())], ["ByUser", string_type]]);
}

export class Hms_EditForm extends Record {
    constructor(RegId, NewNum, NewDate, Reason) {
        super();
        this.RegId = (RegId | 0);
        this.NewNum = (NewNum | 0);
        this.NewDate = NewDate;
        this.Reason = Reason;
    }
}

export function Hms_EditForm$reflection() {
    return record_type("ApiDataTypes.Hms.EditForm", [], Hms_EditForm, () => [["RegId", int32_type], ["NewNum", int32_type], ["NewDate", string_type], ["Reason", string_type]]);
}

export class Hms_ControlRequest extends Record {
    constructor(PeriodStart, PeriodEnd, Projects, Companies, ReportTypes, SortOrder) {
        super();
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.Projects = Projects;
        this.Companies = Companies;
        this.ReportTypes = ReportTypes;
        this.SortOrder = (SortOrder | 0);
    }
}

export function Hms_ControlRequest$reflection() {
    return record_type("ApiDataTypes.Hms.ControlRequest", [], Hms_ControlRequest, () => [["PeriodStart", class_type("System.DateTime")], ["PeriodEnd", class_type("System.DateTime")], ["Projects", array_type(int32_type)], ["Companies", array_type(int32_type)], ["ReportTypes", array_type(int32_type)], ["SortOrder", int32_type]]);
}

export class Hms_MonthEntryType extends Record {
    constructor(Type, Quantity) {
        super();
        this.Type = Type;
        this.Quantity = (Quantity | 0);
    }
}

export function Hms_MonthEntryType$reflection() {
    return record_type("ApiDataTypes.Hms.MonthEntryType", [], Hms_MonthEntryType, () => [["Type", Hms_HmsType$reflection()], ["Quantity", int32_type]]);
}

export class Hms_MonthEntry extends Record {
    constructor(Month, Year, Entries) {
        super();
        this.Month = (Month | 0);
        this.Year = (Year | 0);
        this.Entries = Entries;
    }
}

export function Hms_MonthEntry$reflection() {
    return record_type("ApiDataTypes.Hms.MonthEntry", [], Hms_MonthEntry, () => [["Month", int32_type], ["Year", int32_type], ["Entries", array_type(Hms_MonthEntryType$reflection())]]);
}

export class Hms_PeriodSpread extends Record {
    constructor(Source, SourceId, SourceType, Quantity) {
        super();
        this.Source = Source;
        this.SourceId = (SourceId | 0);
        this.SourceType = SourceType;
        this.Quantity = (Quantity | 0);
    }
}

export function Hms_PeriodSpread$reflection() {
    return record_type("ApiDataTypes.Hms.PeriodSpread", [], Hms_PeriodSpread, () => [["Source", string_type], ["SourceId", int32_type], ["SourceType", string_type], ["Quantity", int32_type]]);
}

export class Nonconformity_ProjectAction extends Record {
    constructor(Id, Desc, ActionTime) {
        super();
        this.Id = (Id | 0);
        this.Desc = Desc;
        this.ActionTime = ActionTime;
    }
}

export function Nonconformity_ProjectAction$reflection() {
    return record_type("ApiDataTypes.Nonconformity.ProjectAction", [], Nonconformity_ProjectAction, () => [["Id", int32_type], ["Desc", string_type], ["ActionTime", string_type]]);
}

export class Nonconformity_ProjectRegistration extends Record {
    constructor(Id, ProjectId, Project, Categories, User, Responsible, IncidentTime, ActionDeadline, Registered, Desc, Images, Action) {
        super();
        this.Id = (Id | 0);
        this.ProjectId = (ProjectId | 0);
        this.Project = Project;
        this.Categories = Categories;
        this.User = User;
        this.Responsible = Responsible;
        this.IncidentTime = IncidentTime;
        this.ActionDeadline = ActionDeadline;
        this.Registered = Registered;
        this.Desc = Desc;
        this.Images = Images;
        this.Action = Action;
    }
}

export function Nonconformity_ProjectRegistration$reflection() {
    return record_type("ApiDataTypes.Nonconformity.ProjectRegistration", [], Nonconformity_ProjectRegistration, () => [["Id", int32_type], ["ProjectId", int32_type], ["Project", string_type], ["Categories", array_type(Helpers_IdName$reflection())], ["User", User$reflection()], ["Responsible", option_type(User$reflection())], ["IncidentTime", string_type], ["ActionDeadline", option_type(string_type)], ["Registered", string_type], ["Desc", string_type], ["Images", array_type(int32_type)], ["Action", option_type(Nonconformity_ProjectAction$reflection())]]);
}

export class Nonconformity_RegisterForm extends Record {
    constructor(Categories, IncidentTime, Desc, ActionDeadline, Responsible, Action) {
        super();
        this.Categories = Categories;
        this.IncidentTime = IncidentTime;
        this.Desc = Desc;
        this.ActionDeadline = ActionDeadline;
        this.Responsible = Responsible;
        this.Action = Action;
    }
}

export function Nonconformity_RegisterForm$reflection() {
    return record_type("ApiDataTypes.Nonconformity.RegisterForm", [], Nonconformity_RegisterForm, () => [["Categories", array_type(Helpers_IdName$reflection())], ["IncidentTime", string_type], ["Desc", string_type], ["ActionDeadline", option_type(string_type)], ["Responsible", option_type(int32_type)], ["Action", option_type(anonRecord_type(["ActionTime", string_type], ["Desc", string_type]))]]);
}

export class Nonconformity_ImageForm extends Record {
    constructor(Content, Filename) {
        super();
        this.Content = Content;
        this.Filename = Filename;
    }
}

export function Nonconformity_ImageForm$reflection() {
    return record_type("ApiDataTypes.Nonconformity.ImageForm", [], Nonconformity_ImageForm, () => [["Content", string_type], ["Filename", string_type]]);
}

export class Nonconformity_CategoryCount extends Record {
    constructor(CategoryId, Category, Num) {
        super();
        this.CategoryId = (CategoryId | 0);
        this.Category = Category;
        this.Num = (Num | 0);
    }
}

export function Nonconformity_CategoryCount$reflection() {
    return record_type("ApiDataTypes.Nonconformity.CategoryCount", [], Nonconformity_CategoryCount, () => [["CategoryId", int32_type], ["Category", string_type], ["Num", int32_type]]);
}

export class Nonconformity_MonthCount extends Record {
    constructor(Year, Month, Count) {
        super();
        this.Year = (Year | 0);
        this.Month = (Month | 0);
        this.Count = Count;
    }
}

export function Nonconformity_MonthCount$reflection() {
    return record_type("ApiDataTypes.Nonconformity.MonthCount", [], Nonconformity_MonthCount, () => [["Year", int32_type], ["Month", int32_type], ["Count", Nonconformity_CategoryCount$reflection()]]);
}

export class Nonconformity_NonconformityStat extends Record {
    constructor(PeriodTotal, WithAction, AverageResolveTime) {
        super();
        this.PeriodTotal = (PeriodTotal | 0);
        this.WithAction = (WithAction | 0);
        this.AverageResolveTime = AverageResolveTime;
    }
}

export function Nonconformity_NonconformityStat$reflection() {
    return record_type("ApiDataTypes.Nonconformity.NonconformityStat", [], Nonconformity_NonconformityStat, () => [["PeriodTotal", int32_type], ["WithAction", int32_type], ["AverageResolveTime", option_type(int32_type)]]);
}

export class Nonconformity_UserResponsible extends Record {
    constructor(Id, ProjectId, Project, Categories, IncidentTime, ActionDeadline, Registered, Desc, Action) {
        super();
        this.Id = (Id | 0);
        this.ProjectId = (ProjectId | 0);
        this.Project = Project;
        this.Categories = Categories;
        this.IncidentTime = IncidentTime;
        this.ActionDeadline = ActionDeadline;
        this.Registered = Registered;
        this.Desc = Desc;
        this.Action = Action;
    }
}

export function Nonconformity_UserResponsible$reflection() {
    return record_type("ApiDataTypes.Nonconformity.UserResponsible", [], Nonconformity_UserResponsible, () => [["Id", int32_type], ["ProjectId", int32_type], ["Project", string_type], ["Categories", array_type(Helpers_IdName$reflection())], ["IncidentTime", string_type], ["ActionDeadline", option_type(string_type)], ["Registered", string_type], ["Desc", string_type], ["Action", option_type(Nonconformity_ProjectAction$reflection())]]);
}

export class Config extends Record {
    constructor(id, name, value, description) {
        super();
        this.id = (id | 0);
        this.name = name;
        this.value = value;
        this.description = description;
    }
}

export function Config$reflection() {
    return record_type("ApiDataTypes.Config", [], Config, () => [["id", int32_type], ["name", string_type], ["value", string_type], ["description", string_type]]);
}

export class Company extends Record {
    constructor(Id, Name, Desc, OrgNr, Enabled) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.OrgNr = OrgNr;
        this.Enabled = Enabled;
    }
}

export function Company$reflection() {
    return record_type("ApiDataTypes.Company", [], Company, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["OrgNr", string_type], ["Enabled", bool_type]]);
}

export function Company_get_Empty() {
    return new Company(0, "", "", "", false);
}

export class SyncedCompany extends Record {
    constructor(Id, Name, Desc, OrgNr, Enabled, MustReportHms, MustReportWaste) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.OrgNr = OrgNr;
        this.Enabled = Enabled;
        this.MustReportHms = MustReportHms;
        this.MustReportWaste = MustReportWaste;
    }
}

export function SyncedCompany$reflection() {
    return record_type("ApiDataTypes.SyncedCompany", [], SyncedCompany, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["OrgNr", string_type], ["Enabled", bool_type], ["MustReportHms", bool_type], ["MustReportWaste", bool_type]]);
}

export class Department extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function Department$reflection() {
    return record_type("ApiDataTypes.Department", [], Department, () => [["Id", int32_type], ["Name", string_type]]);
}

export class Office extends Record {
    constructor(Id, Name, CompanyId, Company) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.CompanyId = (CompanyId | 0);
        this.Company = Company;
    }
}

export function Office$reflection() {
    return record_type("ApiDataTypes.Office", [], Office, () => [["Id", int32_type], ["Name", string_type], ["CompanyId", int32_type], ["Company", string_type]]);
}

export class Waste_WasteType extends Record {
    constructor(Id, Code, Name, Desc, Required, Sorted, Dangerous, Calculate, SchemaField, DirectOnly) {
        super();
        this.Id = (Id | 0);
        this.Code = Code;
        this.Name = Name;
        this.Desc = Desc;
        this.Required = Required;
        this.Sorted = Sorted;
        this.Dangerous = Dangerous;
        this.Calculate = Calculate;
        this.SchemaField = (SchemaField | 0);
        this.DirectOnly = DirectOnly;
    }
}

export function Waste_WasteType$reflection() {
    return record_type("ApiDataTypes.Waste.WasteType", [], Waste_WasteType, () => [["Id", int32_type], ["Code", string_type], ["Name", string_type], ["Desc", string_type], ["Required", bool_type], ["Sorted", bool_type], ["Dangerous", bool_type], ["Calculate", bool_type], ["SchemaField", int32_type], ["DirectOnly", bool_type]]);
}

export class Waste_ProjectInfo extends Record {
    constructor(Id, Name, PlannedStart, Start, Register, Area) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.PlannedStart = PlannedStart;
        this.Start = Start;
        this.Register = Register;
        this.Area = Area;
    }
}

export function Waste_ProjectInfo$reflection() {
    return record_type("ApiDataTypes.Waste.ProjectInfo", [], Waste_ProjectInfo, () => [["Id", int32_type], ["Name", string_type], ["PlannedStart", string_type], ["Start", option_type(string_type)], ["Register", bool_type], ["Area", option_type(anonRecord_type(["Area", int32_type], ["Unit", int32_type]))]]);
}

export class Waste_WastePlan extends Record {
    constructor(Id, Type, Quantity) {
        super();
        this.Id = Id;
        this.Type = Type;
        this.Quantity = Quantity;
    }
}

export function Waste_WastePlan$reflection() {
    return record_type("ApiDataTypes.Waste.WastePlan", [], Waste_WastePlan, () => [["Id", option_type(int32_type)], ["Type", Waste_WasteType$reflection()], ["Quantity", float64_type]]);
}

export class Waste_ProjectRegistration extends Record {
    constructor(Id, ProjectId, Registered, Quantity, DirectlyDelivered, Recycled, Type) {
        super();
        this.Id = (Id | 0);
        this.ProjectId = (ProjectId | 0);
        this.Registered = Registered;
        this.Quantity = Quantity;
        this.DirectlyDelivered = DirectlyDelivered;
        this.Recycled = Recycled;
        this.Type = Type;
    }
}

export function Waste_ProjectRegistration$reflection() {
    return record_type("ApiDataTypes.Waste.ProjectRegistration", [], Waste_ProjectRegistration, () => [["Id", int32_type], ["ProjectId", int32_type], ["Registered", string_type], ["Quantity", float64_type], ["DirectlyDelivered", float64_type], ["Recycled", float64_type], ["Type", Waste_WasteType$reflection()]]);
}

export class Waste_ProjectRegistrationListing extends Record {
    constructor(Sum, SumDirectlyDelivered, SumRecycled, Quantity, DirectlyDelivered, Recycled, Registered, Type) {
        super();
        this.Sum = Sum;
        this.SumDirectlyDelivered = SumDirectlyDelivered;
        this.SumRecycled = SumRecycled;
        this.Quantity = Quantity;
        this.DirectlyDelivered = DirectlyDelivered;
        this.Recycled = Recycled;
        this.Registered = Registered;
        this.Type = Type;
    }
}

export function Waste_ProjectRegistrationListing$reflection() {
    return record_type("ApiDataTypes.Waste.ProjectRegistrationListing", [], Waste_ProjectRegistrationListing, () => [["Sum", float64_type], ["SumDirectlyDelivered", float64_type], ["SumRecycled", float64_type], ["Quantity", float64_type], ["DirectlyDelivered", float64_type], ["Recycled", float64_type], ["Registered", bool_type], ["Type", Waste_WasteType$reflection()]]);
}

export class Waste_EditEntry extends Record {
    constructor(Id, OldVal, NewVal, OldDirectVal, NewDirectVal, OldRecycled, NewRecycled, Comment$, EditedBy, EditedAt, NumEdits) {
        super();
        this.Id = (Id | 0);
        this.OldVal = OldVal;
        this.NewVal = NewVal;
        this.OldDirectVal = OldDirectVal;
        this.NewDirectVal = NewDirectVal;
        this.OldRecycled = OldRecycled;
        this.NewRecycled = NewRecycled;
        this.Comment = Comment$;
        this.EditedBy = EditedBy;
        this.EditedAt = EditedAt;
        this.NumEdits = (NumEdits | 0);
    }
}

export function Waste_EditEntry$reflection() {
    return record_type("ApiDataTypes.Waste.EditEntry", [], Waste_EditEntry, () => [["Id", int32_type], ["OldVal", float64_type], ["NewVal", float64_type], ["OldDirectVal", float64_type], ["NewDirectVal", float64_type], ["OldRecycled", float64_type], ["NewRecycled", float64_type], ["Comment", string_type], ["EditedBy", string_type], ["EditedAt", string_type], ["NumEdits", int32_type]]);
}

export class Waste_ControlEntry extends Record {
    constructor(Registration, Project, ProjectId, Company, CompanyId, Month, Year, Edit, ByUser) {
        super();
        this.Registration = Registration;
        this.Project = Project;
        this.ProjectId = (ProjectId | 0);
        this.Company = Company;
        this.CompanyId = (CompanyId | 0);
        this.Month = (Month | 0);
        this.Year = (Year | 0);
        this.Edit = Edit;
        this.ByUser = ByUser;
    }
}

export function Waste_ControlEntry$reflection() {
    return record_type("ApiDataTypes.Waste.ControlEntry", [], Waste_ControlEntry, () => [["Registration", Waste_ProjectRegistration$reflection()], ["Project", string_type], ["ProjectId", int32_type], ["Company", string_type], ["CompanyId", int32_type], ["Month", int32_type], ["Year", int32_type], ["Edit", option_type(Waste_EditEntry$reflection())], ["ByUser", string_type]]);
}

export class Waste_EditForm extends Record {
    constructor(RegId, NewQuantity, NewDirectQuantity, NewRecycled, Reason) {
        super();
        this.RegId = (RegId | 0);
        this.NewQuantity = NewQuantity;
        this.NewDirectQuantity = NewDirectQuantity;
        this.NewRecycled = NewRecycled;
        this.Reason = Reason;
    }
}

export function Waste_EditForm$reflection() {
    return record_type("ApiDataTypes.Waste.EditForm", [], Waste_EditForm, () => [["RegId", int32_type], ["NewQuantity", float64_type], ["NewDirectQuantity", float64_type], ["NewRecycled", float64_type], ["Reason", string_type]]);
}

export class Waste_TypeSummary extends Record {
    constructor(Type, Quantity, PeriodStart, PeriodEnd) {
        super();
        this.Type = Type;
        this.Quantity = Quantity;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
    }
}

export function Waste_TypeSummary$reflection() {
    return record_type("ApiDataTypes.Waste.TypeSummary", [], Waste_TypeSummary, () => [["Type", Waste_WasteType$reflection()], ["Quantity", float64_type], ["PeriodStart", string_type], ["PeriodEnd", string_type]]);
}

export class Waste_PeriodSummary extends Record {
    constructor(Sorted, Unsorted, Ordinary, Total, Dangerous, NonCalculate, Rate, Area, WasteSqrMeter, ShowWasteSqrMtr, PeriodStart, PeriodEnd) {
        super();
        this.Sorted = Sorted;
        this.Unsorted = Unsorted;
        this.Ordinary = Ordinary;
        this.Total = Total;
        this.Dangerous = Dangerous;
        this.NonCalculate = NonCalculate;
        this.Rate = Rate;
        this.Area = Area;
        this.WasteSqrMeter = WasteSqrMeter;
        this.ShowWasteSqrMtr = ShowWasteSqrMtr;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
    }
}

export function Waste_PeriodSummary$reflection() {
    return record_type("ApiDataTypes.Waste.PeriodSummary", [], Waste_PeriodSummary, () => [["Sorted", float64_type], ["Unsorted", float64_type], ["Ordinary", float64_type], ["Total", float64_type], ["Dangerous", float64_type], ["NonCalculate", float64_type], ["Rate", float64_type], ["Area", option_type(anonRecord_type(["Area", int32_type], ["Unit", int32_type]))], ["WasteSqrMeter", float64_type], ["ShowWasteSqrMtr", bool_type], ["PeriodStart", string_type], ["PeriodEnd", string_type]]);
}

export class Waste_SchemaEntry extends Record {
    constructor(Planned, Quantity, Location, Recycle, RecycleLocation) {
        super();
        this.Planned = Planned;
        this.Quantity = Quantity;
        this.Location = Location;
        this.Recycle = Recycle;
        this.RecycleLocation = RecycleLocation;
    }
}

export function Waste_SchemaEntry$reflection() {
    return record_type("ApiDataTypes.Waste.SchemaEntry", [], Waste_SchemaEntry, () => [["Planned", float64_type], ["Quantity", float64_type], ["Location", string_type], ["Recycle", float64_type], ["RecycleLocation", string_type]]);
}

export class Waste_ExtraSchemaEntry extends Record {
    constructor(Name, Planned, Quantity, Location, Recycle, RecycleLocation) {
        super();
        this.Name = Name;
        this.Planned = Planned;
        this.Quantity = Quantity;
        this.Location = Location;
        this.Recycle = Recycle;
        this.RecycleLocation = RecycleLocation;
    }
}

export function Waste_ExtraSchemaEntry$reflection() {
    return record_type("ApiDataTypes.Waste.ExtraSchemaEntry", [], Waste_ExtraSchemaEntry, () => [["Name", string_type], ["Planned", float64_type], ["Quantity", float64_type], ["Location", string_type], ["Recycle", float64_type], ["RecycleLocation", string_type]]);
}

export class Waste_Schema extends Record {
    constructor(Gnr, Bnr, Fnr, Snr, Byggnr, Bolignr, Address, ContactName, Phone, Email, PostalCode, PostalAddress, County, MeterSqrt, Wood, Cardboard, Glass, Iron, Plaster, Plastic, Concrete, ContConcrete, EE, OrdExtraOne, OrdExtraTwo, OrdExtraThree, OrganicSolvents, PaintGlue, Quicksilver, FluorecentTubes, PIWood, PolymerizingSubs, OrgWasteNonHalo, KreosotImpWood, WithPhthalates, BlowingAgents, PCBPCT, PCBGlass, KFKHKFKHFKFluor, Asbest, DangerousExtraOne, DangerousExtraTwo, DangerousExtraThree, Mixed, Remaining, Company, Date$) {
        super();
        this.Gnr = Gnr;
        this.Bnr = Bnr;
        this.Fnr = Fnr;
        this.Snr = Snr;
        this.Byggnr = Byggnr;
        this.Bolignr = Bolignr;
        this.Address = Address;
        this.ContactName = ContactName;
        this.Phone = Phone;
        this.Email = Email;
        this.PostalCode = PostalCode;
        this.PostalAddress = PostalAddress;
        this.County = County;
        this.MeterSqrt = (MeterSqrt | 0);
        this.Wood = Wood;
        this.Cardboard = Cardboard;
        this.Glass = Glass;
        this.Iron = Iron;
        this.Plaster = Plaster;
        this.Plastic = Plastic;
        this.Concrete = Concrete;
        this.ContConcrete = ContConcrete;
        this.EE = EE;
        this.OrdExtraOne = OrdExtraOne;
        this.OrdExtraTwo = OrdExtraTwo;
        this.OrdExtraThree = OrdExtraThree;
        this.OrganicSolvents = OrganicSolvents;
        this.PaintGlue = PaintGlue;
        this.Quicksilver = Quicksilver;
        this.FluorecentTubes = FluorecentTubes;
        this.PIWood = PIWood;
        this.PolymerizingSubs = PolymerizingSubs;
        this.OrgWasteNonHalo = OrgWasteNonHalo;
        this.KreosotImpWood = KreosotImpWood;
        this.WithPhthalates = WithPhthalates;
        this.BlowingAgents = BlowingAgents;
        this.PCBPCT = PCBPCT;
        this.PCBGlass = PCBGlass;
        this.KFKHKFKHFKFluor = KFKHKFKHFKFluor;
        this.Asbest = Asbest;
        this.DangerousExtraOne = DangerousExtraOne;
        this.DangerousExtraTwo = DangerousExtraTwo;
        this.DangerousExtraThree = DangerousExtraThree;
        this.Mixed = Mixed;
        this.Remaining = Remaining;
        this.Company = Company;
        this.Date = Date$;
    }
}

export function Waste_Schema$reflection() {
    return record_type("ApiDataTypes.Waste.Schema", [], Waste_Schema, () => [["Gnr", string_type], ["Bnr", string_type], ["Fnr", string_type], ["Snr", string_type], ["Byggnr", string_type], ["Bolignr", string_type], ["Address", string_type], ["ContactName", string_type], ["Phone", string_type], ["Email", string_type], ["PostalCode", string_type], ["PostalAddress", string_type], ["County", string_type], ["MeterSqrt", int32_type], ["Wood", Waste_SchemaEntry$reflection()], ["Cardboard", Waste_SchemaEntry$reflection()], ["Glass", Waste_SchemaEntry$reflection()], ["Iron", Waste_SchemaEntry$reflection()], ["Plaster", Waste_SchemaEntry$reflection()], ["Plastic", Waste_SchemaEntry$reflection()], ["Concrete", Waste_SchemaEntry$reflection()], ["ContConcrete", Waste_SchemaEntry$reflection()], ["EE", Waste_SchemaEntry$reflection()], ["OrdExtraOne", Waste_SchemaEntry$reflection()], ["OrdExtraTwo", Waste_SchemaEntry$reflection()], ["OrdExtraThree", Waste_SchemaEntry$reflection()], ["OrganicSolvents", Waste_SchemaEntry$reflection()], ["PaintGlue", Waste_SchemaEntry$reflection()], ["Quicksilver", Waste_SchemaEntry$reflection()], ["FluorecentTubes", Waste_SchemaEntry$reflection()], ["PIWood", Waste_SchemaEntry$reflection()], ["PolymerizingSubs", Waste_SchemaEntry$reflection()], ["OrgWasteNonHalo", Waste_SchemaEntry$reflection()], ["KreosotImpWood", Waste_SchemaEntry$reflection()], ["WithPhthalates", Waste_SchemaEntry$reflection()], ["BlowingAgents", Waste_SchemaEntry$reflection()], ["PCBPCT", Waste_SchemaEntry$reflection()], ["PCBGlass", Waste_SchemaEntry$reflection()], ["KFKHKFKHFKFluor", Waste_SchemaEntry$reflection()], ["Asbest", Waste_SchemaEntry$reflection()], ["DangerousExtraOne", Waste_SchemaEntry$reflection()], ["DangerousExtraTwo", Waste_SchemaEntry$reflection()], ["DangerousExtraThree", Waste_SchemaEntry$reflection()], ["Mixed", Waste_SchemaEntry$reflection()], ["Remaining", string_type], ["Company", string_type], ["Date", string_type]]);
}

export class Waste_SimpleSchemaEntry extends Record {
    constructor(Code, Name, Dangerous, Sorted, Planned, Quantity, Location, Recycle, RecycleLocation) {
        super();
        this.Code = Code;
        this.Name = Name;
        this.Dangerous = Dangerous;
        this.Sorted = Sorted;
        this.Planned = Planned;
        this.Quantity = Quantity;
        this.Location = Location;
        this.Recycle = Recycle;
        this.RecycleLocation = RecycleLocation;
    }
}

export function Waste_SimpleSchemaEntry$reflection() {
    return record_type("ApiDataTypes.Waste.SimpleSchemaEntry", [], Waste_SimpleSchemaEntry, () => [["Code", string_type], ["Name", string_type], ["Dangerous", bool_type], ["Sorted", bool_type], ["Planned", option_type(float64_type)], ["Quantity", float64_type], ["Location", string_type], ["Recycle", float64_type], ["RecycleLocation", string_type]]);
}

export class Waste_SimpleSchema extends Record {
    constructor(Gnr, Bnr, Fnr, Snr, Byggnr, Bolignr, Address, ContactName, Phone, Email, PostalCode, PostalAddress, County, MeterSqrt, Entries, Remaining, Company, Date$) {
        super();
        this.Gnr = Gnr;
        this.Bnr = Bnr;
        this.Fnr = Fnr;
        this.Snr = Snr;
        this.Byggnr = Byggnr;
        this.Bolignr = Bolignr;
        this.Address = Address;
        this.ContactName = ContactName;
        this.Phone = Phone;
        this.Email = Email;
        this.PostalCode = PostalCode;
        this.PostalAddress = PostalAddress;
        this.County = County;
        this.MeterSqrt = (MeterSqrt | 0);
        this.Entries = Entries;
        this.Remaining = Remaining;
        this.Company = Company;
        this.Date = Date$;
    }
}

export function Waste_SimpleSchema$reflection() {
    return record_type("ApiDataTypes.Waste.SimpleSchema", [], Waste_SimpleSchema, () => [["Gnr", string_type], ["Bnr", string_type], ["Fnr", string_type], ["Snr", string_type], ["Byggnr", string_type], ["Bolignr", string_type], ["Address", string_type], ["ContactName", string_type], ["Phone", string_type], ["Email", string_type], ["PostalCode", string_type], ["PostalAddress", string_type], ["County", string_type], ["MeterSqrt", int32_type], ["Entries", array_type(Waste_SimpleSchemaEntry$reflection())], ["Remaining", string_type], ["Company", string_type], ["Date", string_type]]);
}

export class Quality_QuestionStat extends Record {
    constructor(QuestionId, Question, Average, NumUnsure, NumAnswers, RowPos, Distribution) {
        super();
        this.QuestionId = (QuestionId | 0);
        this.Question = Question;
        this.Average = Average;
        this.NumUnsure = NumUnsure;
        this.NumAnswers = (NumAnswers | 0);
        this.RowPos = (RowPos | 0);
        this.Distribution = Distribution;
    }
}

export function Quality_QuestionStat$reflection() {
    return record_type("ApiDataTypes.Quality.QuestionStat", [], Quality_QuestionStat, () => [["QuestionId", int32_type], ["Question", string_type], ["Average", option_type(float64_type)], ["NumUnsure", option_type(int32_type)], ["NumAnswers", int32_type], ["RowPos", int32_type], ["Distribution", array_type(tuple_type(int32_type, int32_type))]]);
}

export class Quality_FormStat extends Record {
    constructor(FormId, FormName, LowerLimit, UpperLimit, Questions, Goal) {
        super();
        this.FormId = (FormId | 0);
        this.FormName = FormName;
        this.LowerLimit = (LowerLimit | 0);
        this.UpperLimit = (UpperLimit | 0);
        this.Questions = Questions;
        this.Goal = Goal;
    }
}

export function Quality_FormStat$reflection() {
    return record_type("ApiDataTypes.Quality.FormStat", [], Quality_FormStat, () => [["FormId", int32_type], ["FormName", string_type], ["LowerLimit", int32_type], ["UpperLimit", int32_type], ["Questions", array_type(Quality_QuestionStat$reflection())], ["Goal", option_type(float64_type)]]);
}

export class Quality_ProjectAnswer extends Record {
    constructor(ProjectId, QuestionId, Answer, Unsure) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.QuestionId = (QuestionId | 0);
        this.Answer = (Answer | 0);
        this.Unsure = Unsure;
    }
}

export function Quality_ProjectAnswer$reflection() {
    return record_type("ApiDataTypes.Quality.ProjectAnswer", [], Quality_ProjectAnswer, () => [["ProjectId", int32_type], ["QuestionId", int32_type], ["Answer", int32_type], ["Unsure", bool_type]]);
}

export class Quality_ProjectQuestion extends Record {
    constructor(Id, Question, HelperText, Answer, Unsure, RowPos, Committed) {
        super();
        this.Id = (Id | 0);
        this.Question = Question;
        this.HelperText = HelperText;
        this.Answer = Answer;
        this.Unsure = Unsure;
        this.RowPos = (RowPos | 0);
        this.Committed = Committed;
    }
}

export function Quality_ProjectQuestion$reflection() {
    return record_type("ApiDataTypes.Quality.ProjectQuestion", [], Quality_ProjectQuestion, () => [["Id", int32_type], ["Question", string_type], ["HelperText", string_type], ["Answer", option_type(int32_type)], ["Unsure", bool_type], ["RowPos", int32_type], ["Committed", bool_type]]);
}

export class Quality_QualityForm extends Record {
    constructor(FormId, Name, Desc, LowerLimit, UpperLimit, AllowUnsure, Goal, Enabled) {
        super();
        this.FormId = (FormId | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.LowerLimit = (LowerLimit | 0);
        this.UpperLimit = (UpperLimit | 0);
        this.AllowUnsure = AllowUnsure;
        this.Goal = Goal;
        this.Enabled = Enabled;
    }
}

export function Quality_QualityForm$reflection() {
    return record_type("ApiDataTypes.Quality.QualityForm", [], Quality_QualityForm, () => [["FormId", int32_type], ["Name", string_type], ["Desc", string_type], ["LowerLimit", int32_type], ["UpperLimit", int32_type], ["AllowUnsure", bool_type], ["Goal", option_type(float64_type)], ["Enabled", bool_type]]);
}

export class Quality_QualityQuestion extends Record {
    constructor(Id, Question, HelperText, RowPos) {
        super();
        this.Id = (Id | 0);
        this.Question = Question;
        this.HelperText = HelperText;
        this.RowPos = (RowPos | 0);
    }
}

export function Quality_QualityQuestion$reflection() {
    return record_type("ApiDataTypes.Quality.QualityQuestion", [], Quality_QualityQuestion, () => [["Id", int32_type], ["Question", string_type], ["HelperText", string_type], ["RowPos", int32_type]]);
}

export class Quality_ProjectQualityForm extends Record {
    constructor(FormId, Name, Desc, LowerLimit, UpperLimit, AllowUnsure, Questions) {
        super();
        this.FormId = (FormId | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.LowerLimit = (LowerLimit | 0);
        this.UpperLimit = (UpperLimit | 0);
        this.AllowUnsure = AllowUnsure;
        this.Questions = Questions;
    }
}

export function Quality_ProjectQualityForm$reflection() {
    return record_type("ApiDataTypes.Quality.ProjectQualityForm", [], Quality_ProjectQualityForm, () => [["FormId", int32_type], ["Name", string_type], ["Desc", string_type], ["LowerLimit", int32_type], ["UpperLimit", int32_type], ["AllowUnsure", bool_type], ["Questions", array_type(Quality_ProjectQuestion$reflection())]]);
}

export class Quality_ProjectParticipant extends Record {
    constructor(Id, Name, Email, Role, External, UserId, Username, Company) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Email = Email;
        this.Role = Role;
        this.External = External;
        this.UserId = UserId;
        this.Username = Username;
        this.Company = Company;
    }
}

export function Quality_ProjectParticipant$reflection() {
    return record_type("ApiDataTypes.Quality.ProjectParticipant", [], Quality_ProjectParticipant, () => [["Id", int32_type], ["Name", string_type], ["Email", string_type], ["Role", string_type], ["External", bool_type], ["UserId", option_type(int32_type)], ["Username", option_type(string_type)], ["Company", string_type]]);
}

export class Quality_CommitInfo extends Record {
    constructor(Committed, UserId, Username, Fullname) {
        super();
        this.Committed = Committed;
        this.UserId = (UserId | 0);
        this.Username = Username;
        this.Fullname = Fullname;
    }
}

export function Quality_CommitInfo$reflection() {
    return record_type("ApiDataTypes.Quality.CommitInfo", [], Quality_CommitInfo, () => [["Committed", string_type], ["UserId", int32_type], ["Username", string_type], ["Fullname", string_type]]);
}

export class Role extends Record {
    constructor(id, code, title, desc, mandatory, compmandatory, category) {
        super();
        this.id = (id | 0);
        this.code = code;
        this.title = title;
        this.desc = desc;
        this.mandatory = mandatory;
        this.compmandatory = compmandatory;
        this.category = (category | 0);
    }
}

export function Role$reflection() {
    return record_type("ApiDataTypes.Role", [], Role, () => [["id", int32_type], ["code", string_type], ["title", string_type], ["desc", string_type], ["mandatory", bool_type], ["compmandatory", bool_type], ["category", int32_type]]);
}

export class ProjectRoleNotification extends Record {
    constructor(Id, ProjectRoleId, Topic, NotificationType) {
        super();
        this.Id = (Id | 0);
        this.ProjectRoleId = (ProjectRoleId | 0);
        this.Topic = (Topic | 0);
        this.NotificationType = (NotificationType | 0);
    }
}

export function ProjectRoleNotification$reflection() {
    return record_type("ApiDataTypes.ProjectRoleNotification", [], ProjectRoleNotification, () => [["Id", int32_type], ["ProjectRoleId", int32_type], ["Topic", int32_type], ["NotificationType", int32_type]]);
}

export class UserSearchResult extends Record {
    constructor(Users, RequestId) {
        super();
        this.Users = Users;
        this.RequestId = (RequestId | 0);
    }
}

export function UserSearchResult$reflection() {
    return record_type("ApiDataTypes.UserSearchResult", [], UserSearchResult, () => [["Users", array_type(User$reflection())], ["RequestId", int32_type]]);
}

export class RoleSearchResult extends Record {
    constructor(roles, requestid) {
        super();
        this.roles = roles;
        this.requestid = (requestid | 0);
    }
}

export function RoleSearchResult$reflection() {
    return record_type("ApiDataTypes.RoleSearchResult", [], RoleSearchResult, () => [["roles", array_type(Role$reflection())], ["requestid", int32_type]]);
}

export class LimitValue extends Record {
    constructor(code, minimum, maximum) {
        super();
        this.code = code;
        this.minimum = (minimum | 0);
        this.maximum = (maximum | 0);
    }
}

export function LimitValue$reflection() {
    return record_type("ApiDataTypes.LimitValue", [], LimitValue, () => [["code", string_type], ["minimum", int32_type], ["maximum", int32_type]]);
}

export class AttestationMeta extends Record {
    constructor(Id, Name, Desc, Filename, RowPos) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.Filename = Filename;
        this.RowPos = (RowPos | 0);
    }
}

export function AttestationMeta$reflection() {
    return record_type("ApiDataTypes.AttestationMeta", [], AttestationMeta, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["Filename", string_type], ["RowPos", int32_type]]);
}

export class AttestationContent extends Record {
    constructor(Id, Name, Desc, Filename, RowPos, Content, ProjectId) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.Filename = Filename;
        this.RowPos = (RowPos | 0);
        this.Content = Content;
        this.ProjectId = (ProjectId | 0);
    }
}

export function AttestationContent$reflection() {
    return record_type("ApiDataTypes.AttestationContent", [], AttestationContent, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["Filename", string_type], ["RowPos", int32_type], ["Content", string_type], ["ProjectId", int32_type]]);
}

export class ProjectGalleryMeta extends Record {
    constructor(Id, Name, Desc, Filename) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.Filename = Filename;
    }
}

export function ProjectGalleryMeta$reflection() {
    return record_type("ApiDataTypes.ProjectGalleryMeta", [], ProjectGalleryMeta, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["Filename", string_type]]);
}

export class ProjectGalleryContent extends Record {
    constructor(Id, Name, Desc, Content, Filename, ProjectId) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.Content = Content;
        this.Filename = Filename;
        this.ProjectId = (ProjectId | 0);
    }
}

export function ProjectGalleryContent$reflection() {
    return record_type("ApiDataTypes.ProjectGalleryContent", [], ProjectGalleryContent, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["Content", string_type], ["Filename", string_type], ["ProjectId", int32_type]]);
}

export class ProjectContact extends Record {
    constructor(Name, Email, Phone, UserId) {
        super();
        this.Name = Name;
        this.Email = Email;
        this.Phone = Phone;
        this.UserId = (UserId | 0);
    }
}

export function ProjectContact$reflection() {
    return record_type("ApiDataTypes.ProjectContact", [], ProjectContact, () => [["Name", string_type], ["Email", string_type], ["Phone", string_type], ["UserId", int32_type]]);
}

export class ProjectCategory extends Record {
    constructor(Id, Category) {
        super();
        this.Id = (Id | 0);
        this.Category = Category;
    }
}

export function ProjectCategory$reflection() {
    return record_type("ApiDataTypes.ProjectCategory", [], ProjectCategory, () => [["Id", int32_type], ["Category", Helpers_IdName$reflection()]]);
}

export class ProjectForm extends Record {
    constructor(Name, ProjectNumber, CompanyId, OfficeId, TemplateId, Start, End) {
        super();
        this.Name = Name;
        this.ProjectNumber = ProjectNumber;
        this.CompanyId = (CompanyId | 0);
        this.OfficeId = (OfficeId | 0);
        this.TemplateId = (TemplateId | 0);
        this.Start = Start;
        this.End = End;
    }
}

export function ProjectForm$reflection() {
    return record_type("ApiDataTypes.ProjectForm", [], ProjectForm, () => [["Name", string_type], ["ProjectNumber", string_type], ["CompanyId", int32_type], ["OfficeId", int32_type], ["TemplateId", int32_type], ["Start", string_type], ["End", string_type]]);
}

export class Project extends Record {
    constructor(Id, Name, Code, Office, Company, Active, StartDate, Builders, Status, InfoScore, Categories, ContactPerson) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Code = Code;
        this.Office = (Office | 0);
        this.Company = (Company | 0);
        this.Active = Active;
        this.StartDate = StartDate;
        this.Builders = Builders;
        this.Status = (Status | 0);
        this.InfoScore = (InfoScore | 0);
        this.Categories = Categories;
        this.ContactPerson = ContactPerson;
    }
}

export function Project$reflection() {
    return record_type("ApiDataTypes.Project", [], Project, () => [["Id", int32_type], ["Name", string_type], ["Code", string_type], ["Office", int32_type], ["Company", int32_type], ["Active", bool_type], ["StartDate", class_type("System.DateTime")], ["Builders", array_type(int32_type)], ["Status", int32_type], ["InfoScore", int32_type], ["Categories", array_type(ProjectCategory$reflection())], ["ContactPerson", option_type(ProjectContact$reflection())]]);
}

export function Project_get_Empty() {
    return new Project(0, "", "", 0, 0, false, now(), new Int32Array([]), 0, 0, [], void 0);
}

export class ActionClass extends Record {
    constructor(Id, Code, Function$, Class) {
        super();
        this.Id = (Id | 0);
        this.Code = Code;
        this.Function = Function$;
        this.Class = (Class | 0);
    }
}

export function ActionClass$reflection() {
    return record_type("ApiDataTypes.ActionClass", [], ActionClass, () => [["Id", int32_type], ["Code", string_type], ["Function", string_type], ["Class", int32_type]]);
}

export class EnvironmentalProfile extends Record {
    constructor(Id, EnergyClassId, BreeamId, PassiveHouse, Desc) {
        super();
        this.Id = (Id | 0);
        this.EnergyClassId = (EnergyClassId | 0);
        this.BreeamId = (BreeamId | 0);
        this.PassiveHouse = PassiveHouse;
        this.Desc = Desc;
    }
}

export function EnvironmentalProfile$reflection() {
    return record_type("ApiDataTypes.EnvironmentalProfile", [], EnvironmentalProfile, () => [["Id", int32_type], ["EnergyClassId", int32_type], ["BreeamId", int32_type], ["PassiveHouse", bool_type], ["Desc", string_type]]);
}

export class ProjectDateForm extends Record {
    constructor(ProjectId, Date$) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Date = Date$;
    }
}

export function ProjectDateForm$reflection() {
    return record_type("ApiDataTypes.ProjectDateForm", [], ProjectDateForm, () => [["ProjectId", int32_type], ["Date", string_type]]);
}

export class BuilderContact extends Record {
    constructor(Id, Name, Phone, Email) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Phone = Phone;
        this.Email = Email;
    }
}

export function BuilderContact$reflection() {
    return record_type("ApiDataTypes.BuilderContact", [], BuilderContact, () => [["Id", int32_type], ["Name", string_type], ["Phone", string_type], ["Email", string_type]]);
}

export class ProjectBuilder extends Record {
    constructor(BuilderId, Contacts) {
        super();
        this.BuilderId = (BuilderId | 0);
        this.Contacts = Contacts;
    }
}

export function ProjectBuilder$reflection() {
    return record_type("ApiDataTypes.ProjectBuilder", [], ProjectBuilder, () => [["BuilderId", int32_type], ["Contacts", array_type(BuilderContact$reflection())]]);
}

export class ProjectPicture extends Record {
    constructor(Content, Filename) {
        super();
        this.Content = Content;
        this.Filename = Filename;
    }
}

export function ProjectPicture$reflection() {
    return record_type("ApiDataTypes.ProjectPicture", [], ProjectPicture, () => [["Content", string_type], ["Filename", string_type]]);
}

export class ProjectDetails extends Record {
    constructor(Id, Name, Office, Company, Active, Categories, ShortDesc, Desc, InteractionModel, InteractionModelText, EnterpriseForm, Areal, EnvProfile, ContractProvisions, ContractSum, ContractCurrency, Builders, ActionClasses, ClimateGoal, ClimateGoalText, PlannedStart, PlannedEnd, ProjectNumber, Attestations, HandedOver, HandedOverState, RegisterHms, RegisterWaste, Status, IsStarted, IsCompleted, Start, Ended) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Office = (Office | 0);
        this.Company = (Company | 0);
        this.Active = Active;
        this.Categories = Categories;
        this.ShortDesc = ShortDesc;
        this.Desc = Desc;
        this.InteractionModel = InteractionModel;
        this.InteractionModelText = InteractionModelText;
        this.EnterpriseForm = EnterpriseForm;
        this.Areal = Areal;
        this.EnvProfile = EnvProfile;
        this.ContractProvisions = ContractProvisions;
        this.ContractSum = (ContractSum | 0);
        this.ContractCurrency = (ContractCurrency | 0);
        this.Builders = Builders;
        this.ActionClasses = ActionClasses;
        this.ClimateGoal = ClimateGoal;
        this.ClimateGoalText = ClimateGoalText;
        this.PlannedStart = PlannedStart;
        this.PlannedEnd = PlannedEnd;
        this.ProjectNumber = ProjectNumber;
        this.Attestations = Attestations;
        this.HandedOver = HandedOver;
        this.HandedOverState = (HandedOverState | 0);
        this.RegisterHms = RegisterHms;
        this.RegisterWaste = RegisterWaste;
        this.Status = (Status | 0);
        this.IsStarted = IsStarted;
        this.IsCompleted = IsCompleted;
        this.Start = Start;
        this.Ended = Ended;
    }
}

export function ProjectDetails$reflection() {
    return record_type("ApiDataTypes.ProjectDetails", [], ProjectDetails, () => [["Id", int32_type], ["Name", string_type], ["Office", int32_type], ["Company", int32_type], ["Active", bool_type], ["Categories", array_type(ProjectCategory$reflection())], ["ShortDesc", string_type], ["Desc", string_type], ["InteractionModel", bool_type], ["InteractionModelText", string_type], ["EnterpriseForm", string_type], ["Areal", option_type(anonRecord_type(["Area", int32_type], ["Unit", int32_type]))], ["EnvProfile", option_type(EnvironmentalProfile$reflection())], ["ContractProvisions", string_type], ["ContractSum", int32_type], ["ContractCurrency", int32_type], ["Builders", array_type(ProjectBuilder$reflection())], ["ActionClasses", array_type(ActionClass$reflection())], ["ClimateGoal", bool_type], ["ClimateGoalText", string_type], ["PlannedStart", string_type], ["PlannedEnd", string_type], ["ProjectNumber", string_type], ["Attestations", array_type(AttestationMeta$reflection())], ["HandedOver", option_type(string_type)], ["HandedOverState", int32_type], ["RegisterHms", bool_type], ["RegisterWaste", bool_type], ["Status", int32_type], ["IsStarted", bool_type], ["IsCompleted", bool_type], ["Start", option_type(string_type)], ["Ended", option_type(string_type)]]);
}

export class ProjectInfoScore extends Record {
    constructor(Total, Role, Desc, Image, ActionClass, Value, Areal, Tips) {
        super();
        this.Total = (Total | 0);
        this.Role = (Role | 0);
        this.Desc = (Desc | 0);
        this.Image = Image;
        this.ActionClass = ActionClass;
        this.Value = Value;
        this.Areal = Areal;
        this.Tips = Tips;
    }
}

export function ProjectInfoScore$reflection() {
    return record_type("ApiDataTypes.ProjectInfoScore", [], ProjectInfoScore, () => [["Total", int32_type], ["Role", int32_type], ["Desc", int32_type], ["Image", bool_type], ["ActionClass", bool_type], ["Value", bool_type], ["Areal", bool_type], ["Tips", array_type(string_type)]]);
}

export class ProjectRole extends Record {
    constructor(Id, ProjectId, Role) {
        super();
        this.Id = (Id | 0);
        this.ProjectId = (ProjectId | 0);
        this.Role = Role;
    }
}

export function ProjectRole$reflection() {
    return record_type("ApiDataTypes.ProjectRole", [], ProjectRole, () => [["Id", int32_type], ["ProjectId", int32_type], ["Role", Role$reflection()]]);
}

export class ProjectRoleUser extends Record {
    constructor(Id, Role, User, Allocated, PeriodStart, PeriodEnd) {
        super();
        this.Id = (Id | 0);
        this.Role = Role;
        this.User = User;
        this.Allocated = (Allocated | 0);
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
    }
}

export function ProjectRoleUser$reflection() {
    return record_type("ApiDataTypes.ProjectRoleUser", [], ProjectRoleUser, () => [["Id", int32_type], ["Role", ProjectRole$reflection()], ["User", User$reflection()], ["Allocated", int32_type], ["PeriodStart", option_type(string_type)], ["PeriodEnd", option_type(string_type)]]);
}

export class CompanyRole extends Record {
    constructor(id, compid, role) {
        super();
        this.id = (id | 0);
        this.compid = (compid | 0);
        this.role = role;
    }
}

export function CompanyRole$reflection() {
    return record_type("ApiDataTypes.CompanyRole", [], CompanyRole, () => [["id", int32_type], ["compid", int32_type], ["role", Role$reflection()]]);
}

export class CompanyRoleUser extends Record {
    constructor(id, comprole, user, allocated) {
        super();
        this.id = (id | 0);
        this.comprole = comprole;
        this.user = user;
        this.allocated = (allocated | 0);
    }
}

export function CompanyRoleUser$reflection() {
    return record_type("ApiDataTypes.CompanyRoleUser", [], CompanyRoleUser, () => [["id", int32_type], ["comprole", CompanyRole$reflection()], ["user", User$reflection()], ["allocated", int32_type]]);
}

export class ProjectPreloaded extends Record {
    constructor(project, builders, projectCategories, companies) {
        super();
        this.project = project;
        this.builders = builders;
        this.projectCategories = projectCategories;
        this.companies = companies;
    }
}

export function ProjectPreloaded$reflection() {
    return record_type("ApiDataTypes.ProjectPreloaded", [], ProjectPreloaded, () => [["project", Project$reflection()], ["builders", array_type(Helpers_IdNameDesc$reflection())], ["projectCategories", array_type(ProjectCategory$reflection())], ["companies", array_type(Company$reflection())]]);
}

export function ProjectPreloaded_get_Empty() {
    return new ProjectPreloaded(Project_get_Empty(), [], [], []);
}

export class ProcessActivityDoc extends Record {
    constructor(Id, ActivityId, FileStoreId, PdfFileStoreId, SupFileStoreId, Name, Filename, PdfFilename, SupFilename, NextRevision, LastRevision, Tags, Desc, CompanyVersions, Version, RowPrefix, RowPos) {
        super();
        this.Id = (Id | 0);
        this.ActivityId = (ActivityId | 0);
        this.FileStoreId = (FileStoreId | 0);
        this.PdfFileStoreId = PdfFileStoreId;
        this.SupFileStoreId = SupFileStoreId;
        this.Name = Name;
        this.Filename = Filename;
        this.PdfFilename = PdfFilename;
        this.SupFilename = SupFilename;
        this.NextRevision = NextRevision;
        this.LastRevision = LastRevision;
        this.Tags = Tags;
        this.Desc = Desc;
        this.CompanyVersions = CompanyVersions;
        this.Version = (Version | 0);
        this.RowPrefix = RowPrefix;
        this.RowPos = (RowPos | 0);
    }
}

export function ProcessActivityDoc$reflection() {
    return record_type("ApiDataTypes.ProcessActivityDoc", [], ProcessActivityDoc, () => [["Id", int32_type], ["ActivityId", int32_type], ["FileStoreId", int32_type], ["PdfFileStoreId", option_type(int32_type)], ["SupFileStoreId", option_type(int32_type)], ["Name", string_type], ["Filename", string_type], ["PdfFilename", option_type(string_type)], ["SupFilename", option_type(string_type)], ["NextRevision", string_type], ["LastRevision", option_type(string_type)], ["Tags", array_type(Helpers_IdName$reflection())], ["Desc", string_type], ["CompanyVersions", bool_type], ["Version", int32_type], ["RowPrefix", string_type], ["RowPos", int32_type]]);
}

export function ProcessActivityDoc_get_Empty() {
    return new ProcessActivityDoc(-1, -1, 0, void 0, void 0, "", "", void 0, void 0, "", void 0, [], "", false, 0, "", 0);
}

export class ProcessActivityDocForm extends Record {
    constructor(Entry, Filename, FileExt, FileContent) {
        super();
        this.Entry = Entry;
        this.Filename = Filename;
        this.FileExt = FileExt;
        this.FileContent = FileContent;
    }
}

export function ProcessActivityDocForm$reflection() {
    return record_type("ApiDataTypes.ProcessActivityDocForm", [], ProcessActivityDocForm, () => [["Entry", ProcessActivityDoc$reflection()], ["Filename", string_type], ["FileExt", string_type], ["FileContent", string_type]]);
}

export class CompanyProcessActivityDoc extends Record {
    constructor(id, processActivityDocId, processActivityDocVersionId, companyId, filestoreid, pdffilestoreid, supfilestoreid, version, uploaded) {
        super();
        this.id = (id | 0);
        this.processActivityDocId = (processActivityDocId | 0);
        this.processActivityDocVersionId = (processActivityDocVersionId | 0);
        this.companyId = (companyId | 0);
        this.filestoreid = (filestoreid | 0);
        this.pdffilestoreid = (pdffilestoreid | 0);
        this.supfilestoreid = (supfilestoreid | 0);
        this.version = (version | 0);
        this.uploaded = uploaded;
    }
}

export function CompanyProcessActivityDoc$reflection() {
    return record_type("ApiDataTypes.CompanyProcessActivityDoc", [], CompanyProcessActivityDoc, () => [["id", int32_type], ["processActivityDocId", int32_type], ["processActivityDocVersionId", int32_type], ["companyId", int32_type], ["filestoreid", int32_type], ["pdffilestoreid", int32_type], ["supfilestoreid", int32_type], ["version", int32_type], ["uploaded", class_type("System.DateTime")]]);
}

export function CompanyProcessActivityDoc_get_Empty() {
    return new CompanyProcessActivityDoc(0, 0, 0, 0, 0, 0, 0, 0, now());
}

export class ProcessActivityDocVersion extends Record {
    constructor(Id, ProcessActivityDocId, FileStoreId, PdfFileStoreId, SupFileStoreId, Filename, PdfFilename, Version, Uploaded) {
        super();
        this.Id = (Id | 0);
        this.ProcessActivityDocId = (ProcessActivityDocId | 0);
        this.FileStoreId = (FileStoreId | 0);
        this.PdfFileStoreId = (PdfFileStoreId | 0);
        this.SupFileStoreId = (SupFileStoreId | 0);
        this.Filename = Filename;
        this.PdfFilename = PdfFilename;
        this.Version = (Version | 0);
        this.Uploaded = Uploaded;
    }
}

export function ProcessActivityDocVersion$reflection() {
    return record_type("ApiDataTypes.ProcessActivityDocVersion", [], ProcessActivityDocVersion, () => [["Id", int32_type], ["ProcessActivityDocId", int32_type], ["FileStoreId", int32_type], ["PdfFileStoreId", int32_type], ["SupFileStoreId", int32_type], ["Filename", string_type], ["PdfFilename", string_type], ["Version", int32_type], ["Uploaded", class_type("System.DateTime")]]);
}

export function ProcessActivityDocVersion_get_Empty() {
    return new ProcessActivityDocVersion(0, 0, 0, 0, 0, "", "", 0, now());
}

export class ProcessActivityDocCompanyVersion extends Record {
    constructor(Id, OfDocId, OfVersion, CompanyId, CompanyName, FileStoreId, PdfFileStoreId, SupFileStoreId, Filename, PdfFilename, Version, UploadedBy, Uploaded) {
        super();
        this.Id = (Id | 0);
        this.OfDocId = (OfDocId | 0);
        this.OfVersion = OfVersion;
        this.CompanyId = (CompanyId | 0);
        this.CompanyName = CompanyName;
        this.FileStoreId = (FileStoreId | 0);
        this.PdfFileStoreId = (PdfFileStoreId | 0);
        this.SupFileStoreId = (SupFileStoreId | 0);
        this.Filename = Filename;
        this.PdfFilename = PdfFilename;
        this.Version = (Version | 0);
        this.UploadedBy = UploadedBy;
        this.Uploaded = Uploaded;
    }
}

export function ProcessActivityDocCompanyVersion$reflection() {
    return record_type("ApiDataTypes.ProcessActivityDocCompanyVersion", [], ProcessActivityDocCompanyVersion, () => [["Id", int32_type], ["OfDocId", int32_type], ["OfVersion", ProcessActivityDocVersion$reflection()], ["CompanyId", int32_type], ["CompanyName", string_type], ["FileStoreId", int32_type], ["PdfFileStoreId", int32_type], ["SupFileStoreId", int32_type], ["Filename", string_type], ["PdfFilename", string_type], ["Version", int32_type], ["UploadedBy", string_type], ["Uploaded", class_type("System.DateTime")]]);
}

export class DocumentSearchEntry extends Record {
    constructor(ProcessActivityDocId, Filename, LastChanged) {
        super();
        this.ProcessActivityDocId = (ProcessActivityDocId | 0);
        this.Filename = Filename;
        this.LastChanged = LastChanged;
    }
}

export function DocumentSearchEntry$reflection() {
    return record_type("ApiDataTypes.DocumentSearchEntry", [], DocumentSearchEntry, () => [["ProcessActivityDocId", int32_type], ["Filename", string_type], ["LastChanged", class_type("System.DateTime")]]);
}

export class ProcessActivityDocVersionForm extends Record {
    constructor(derivedFrom, filename, filecontent, pdffilename, supfilename, pdffilecontent, supfilecontent, nextrevision, companyid) {
        super();
        this.derivedFrom = derivedFrom;
        this.filename = filename;
        this.filecontent = filecontent;
        this.pdffilename = pdffilename;
        this.supfilename = supfilename;
        this.pdffilecontent = pdffilecontent;
        this.supfilecontent = supfilecontent;
        this.nextrevision = nextrevision;
        this.companyid = (companyid | 0);
    }
}

export function ProcessActivityDocVersionForm$reflection() {
    return record_type("ApiDataTypes.ProcessActivityDocVersionForm", [], ProcessActivityDocVersionForm, () => [["derivedFrom", ProcessActivityDoc$reflection()], ["filename", string_type], ["filecontent", string_type], ["pdffilename", string_type], ["supfilename", string_type], ["pdffilecontent", string_type], ["supfilecontent", string_type], ["nextrevision", class_type("System.DateTime")], ["companyid", int32_type]]);
}

export class ProcessActivityDocVersionResponse extends Record {
    constructor(versions, companyversions) {
        super();
        this.versions = versions;
        this.companyversions = companyversions;
    }
}

export function ProcessActivityDocVersionResponse$reflection() {
    return record_type("ApiDataTypes.ProcessActivityDocVersionResponse", [], ProcessActivityDocVersionResponse, () => [["versions", array_type(ProcessActivityDocVersion$reflection())], ["companyversions", array_type(ProcessActivityDocCompanyVersion$reflection())]]);
}

export class ProcessActivityDocVersionRequest extends Record {
    constructor(id, companyspecific, companyid) {
        super();
        this.id = (id | 0);
        this.companyspecific = companyspecific;
        this.companyid = (companyid | 0);
    }
}

export function ProcessActivityDocVersionRequest$reflection() {
    return record_type("ApiDataTypes.ProcessActivityDocVersionRequest", [], ProcessActivityDocVersionRequest, () => [["id", int32_type], ["companyspecific", bool_type], ["companyid", int32_type]]);
}

export class ProcessActivityLink extends Record {
    constructor(Id, ActivityId, Name, Desc, Url) {
        super();
        this.Id = (Id | 0);
        this.ActivityId = (ActivityId | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.Url = Url;
    }
}

export function ProcessActivityLink$reflection() {
    return record_type("ApiDataTypes.ProcessActivityLink", [], ProcessActivityLink, () => [["Id", int32_type], ["ActivityId", int32_type], ["Name", string_type], ["Desc", string_type], ["Url", string_type]]);
}

export class ProcessActivity extends Record {
    constructor(Id, ProcessId, Name, Desc, RowPrefix, RowPos, Docs, Links, NumDocs) {
        super();
        this.Id = (Id | 0);
        this.ProcessId = (ProcessId | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.RowPrefix = RowPrefix;
        this.RowPos = (RowPos | 0);
        this.Docs = Docs;
        this.Links = Links;
        this.NumDocs = (NumDocs | 0);
    }
}

export function ProcessActivity$reflection() {
    return record_type("ApiDataTypes.ProcessActivity", [], ProcessActivity, () => [["Id", int32_type], ["ProcessId", int32_type], ["Name", string_type], ["Desc", string_type], ["RowPrefix", string_type], ["RowPos", int32_type], ["Docs", array_type(ProcessActivityDoc$reflection())], ["Links", array_type(ProcessActivityLink$reflection())], ["NumDocs", int32_type]]);
}

export function ProcessActivity_get_Empty() {
    return new ProcessActivity(0, -1, "", "", "", 0, [], [], 0);
}

export class Process extends Record {
    constructor(Id, Name, Desc, RowPrefix, RowPos, ParentId, Children, Activities, ChartCode, NumChildren, NumActivities, IsManagement) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.RowPrefix = RowPrefix;
        this.RowPos = (RowPos | 0);
        this.ParentId = ParentId;
        this.Children = Children;
        this.Activities = Activities;
        this.ChartCode = (ChartCode | 0);
        this.NumChildren = (NumChildren | 0);
        this.NumActivities = (NumActivities | 0);
        this.IsManagement = IsManagement;
    }
}

export function Process$reflection() {
    return record_type("ApiDataTypes.Process", [], Process, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["RowPrefix", string_type], ["RowPos", int32_type], ["ParentId", option_type(int32_type)], ["Children", array_type(Process$reflection())], ["Activities", array_type(ProcessActivity$reflection())], ["ChartCode", int32_type], ["NumChildren", int32_type], ["NumActivities", int32_type], ["IsManagement", bool_type]]);
}

export class ProjectProcessActivityDoc extends Record {
    constructor(id, filestoreid, supfilestoreid, filename, responsible) {
        super();
        this.id = (id | 0);
        this.filestoreid = (filestoreid | 0);
        this.supfilestoreid = (supfilestoreid | 0);
        this.filename = filename;
        this.responsible = (responsible | 0);
    }
}

export function ProjectProcessActivityDoc$reflection() {
    return record_type("ApiDataTypes.ProjectProcessActivityDoc", [], ProjectProcessActivityDoc, () => [["id", int32_type], ["filestoreid", int32_type], ["supfilestoreid", int32_type], ["filename", string_type], ["responsible", int32_type]]);
}

export class ProjectProcessActivityFile extends Record {
    constructor(id, filestoreid, filename, revision, activityid) {
        super();
        this.id = (id | 0);
        this.filestoreid = (filestoreid | 0);
        this.filename = filename;
        this.revision = (revision | 0);
        this.activityid = (activityid | 0);
    }
}

export function ProjectProcessActivityFile$reflection() {
    return record_type("ApiDataTypes.ProjectProcessActivityFile", [], ProjectProcessActivityFile, () => [["id", int32_type], ["filestoreid", int32_type], ["filename", string_type], ["revision", int32_type], ["activityid", int32_type]]);
}

export class ProjectProcessActivityFileForm extends Record {
    constructor(updateid, filename, content, activityid) {
        super();
        this.updateid = (updateid | 0);
        this.filename = filename;
        this.content = content;
        this.activityid = (activityid | 0);
    }
}

export function ProjectProcessActivityFileForm$reflection() {
    return record_type("ApiDataTypes.ProjectProcessActivityFileForm", [], ProjectProcessActivityFileForm, () => [["updateid", int32_type], ["filename", string_type], ["content", string_type], ["activityid", int32_type]]);
}

export class ProjectProcessActivityComment extends Record {
    constructor(id, comment, by, activityid) {
        super();
        this.id = (id | 0);
        this.comment = comment;
        this.by = by;
        this.activityid = (activityid | 0);
    }
}

export function ProjectProcessActivityComment$reflection() {
    return record_type("ApiDataTypes.ProjectProcessActivityComment", [], ProjectProcessActivityComment, () => [["id", int32_type], ["comment", string_type], ["by", string_type], ["activityid", int32_type]]);
}

export class ProjectProcessActivityLink extends Record {
    constructor(id, href, name, activityid) {
        super();
        this.id = (id | 0);
        this.href = href;
        this.name = name;
        this.activityid = (activityid | 0);
    }
}

export function ProjectProcessActivityLink$reflection() {
    return record_type("ApiDataTypes.ProjectProcessActivityLink", [], ProjectProcessActivityLink, () => [["id", int32_type], ["href", string_type], ["name", string_type], ["activityid", int32_type]]);
}

export class ProjectProcessActivity extends Record {
    constructor(id, projectprocessid, managerid, name, desc, rowpos, documents, files, links, comments) {
        super();
        this.id = (id | 0);
        this.projectprocessid = (projectprocessid | 0);
        this.managerid = (managerid | 0);
        this.name = name;
        this.desc = desc;
        this.rowpos = (rowpos | 0);
        this.documents = documents;
        this.files = files;
        this.links = links;
        this.comments = comments;
    }
}

export function ProjectProcessActivity$reflection() {
    return record_type("ApiDataTypes.ProjectProcessActivity", [], ProjectProcessActivity, () => [["id", int32_type], ["projectprocessid", int32_type], ["managerid", int32_type], ["name", string_type], ["desc", string_type], ["rowpos", int32_type], ["documents", array_type(ProjectProcessActivityDoc$reflection())], ["files", array_type(ProjectProcessActivityFile$reflection())], ["links", array_type(ProjectProcessActivityLink$reflection())], ["comments", array_type(ProjectProcessActivityComment$reflection())]]);
}

export class ProjectProcess extends Record {
    constructor(id, name, desc, rowpos, activities, srcprocess, managerid) {
        super();
        this.id = (id | 0);
        this.name = name;
        this.desc = desc;
        this.rowpos = (rowpos | 0);
        this.activities = activities;
        this.srcprocess = (srcprocess | 0);
        this.managerid = (managerid | 0);
    }
}

export function ProjectProcess$reflection() {
    return record_type("ApiDataTypes.ProjectProcess", [], ProjectProcess, () => [["id", int32_type], ["name", string_type], ["desc", string_type], ["rowpos", int32_type], ["activities", array_type(ProjectProcessActivity$reflection())], ["srcprocess", int32_type], ["managerid", int32_type]]);
}

export class ProjectActivityDocRole extends Record {
    constructor(docid, projroleid) {
        super();
        this.docid = (docid | 0);
        this.projroleid = (projroleid | 0);
    }
}

export function ProjectActivityDocRole$reflection() {
    return record_type("ApiDataTypes.ProjectActivityDocRole", [], ProjectActivityDocRole, () => [["docid", int32_type], ["projroleid", int32_type]]);
}

export class ProcessModule_Tag extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function ProcessModule_Tag$reflection() {
    return record_type("ApiDataTypes.ProcessModule.Tag", [], ProcessModule_Tag, () => [["Id", int32_type], ["Name", string_type]]);
}

export class ProcessModule_DocVersion extends Record {
    constructor(Version, Modified, ModifiedBy) {
        super();
        this.Version = Version;
        this.Modified = Modified;
        this.ModifiedBy = ModifiedBy;
    }
}

export function ProcessModule_DocVersion$reflection() {
    return record_type("ApiDataTypes.ProcessModule.DocVersion", [], ProcessModule_DocVersion, () => [["Version", string_type], ["Modified", string_type], ["ModifiedBy", string_type]]);
}

export class ProcessModule_DocRevision extends Record {
    constructor(Id, DriveId, DriveUrl, Version) {
        super();
        this.Id = (Id | 0);
        this.DriveId = DriveId;
        this.DriveUrl = DriveUrl;
        this.Version = Version;
    }
}

export function ProcessModule_DocRevision$reflection() {
    return record_type("ApiDataTypes.ProcessModule.DocRevision", [], ProcessModule_DocRevision, () => [["Id", int32_type], ["DriveId", string_type], ["DriveUrl", string_type], ["Version", string_type]]);
}

export class ProcessModule_RevisionForm extends Record {
    constructor(DocumentId, Version, NextRevision, FileContent, Filename) {
        super();
        this.DocumentId = (DocumentId | 0);
        this.Version = Version;
        this.NextRevision = NextRevision;
        this.FileContent = FileContent;
        this.Filename = Filename;
    }
}

export function ProcessModule_RevisionForm$reflection() {
    return record_type("ApiDataTypes.ProcessModule.RevisionForm", [], ProcessModule_RevisionForm, () => [["DocumentId", int32_type], ["Version", string_type], ["NextRevision", option_type(string_type)], ["FileContent", string_type], ["Filename", string_type]]);
}

export class ProcessModule_RevisionHistory extends Record {
    constructor(DocumentId, Prefix, Name, Version, Revised) {
        super();
        this.DocumentId = (DocumentId | 0);
        this.Prefix = Prefix;
        this.Name = Name;
        this.Version = Version;
        this.Revised = Revised;
    }
}

export function ProcessModule_RevisionHistory$reflection() {
    return record_type("ApiDataTypes.ProcessModule.RevisionHistory", [], ProcessModule_RevisionHistory, () => [["DocumentId", int32_type], ["Prefix", string_type], ["Name", string_type], ["Version", string_type], ["Revised", string_type]]);
}

export class ProcessModule_ProcessActivityDoc extends Record {
    constructor(Id, ActivityId, DriveId, DriveUrl, SupDoc, Name, Filename, NextRevision, LastRevision, Tags, Desc, CompanyVersions, RowPrefix, RowPos, Version, Revisions) {
        super();
        this.Id = (Id | 0);
        this.ActivityId = (ActivityId | 0);
        this.DriveId = DriveId;
        this.DriveUrl = DriveUrl;
        this.SupDoc = SupDoc;
        this.Name = Name;
        this.Filename = Filename;
        this.NextRevision = NextRevision;
        this.LastRevision = LastRevision;
        this.Tags = Tags;
        this.Desc = Desc;
        this.CompanyVersions = CompanyVersions;
        this.RowPrefix = RowPrefix;
        this.RowPos = (RowPos | 0);
        this.Version = Version;
        this.Revisions = Revisions;
    }
}

export function ProcessModule_ProcessActivityDoc$reflection() {
    return record_type("ApiDataTypes.ProcessModule.ProcessActivityDoc", [], ProcessModule_ProcessActivityDoc, () => [["Id", int32_type], ["ActivityId", int32_type], ["DriveId", string_type], ["DriveUrl", string_type], ["SupDoc", option_type(anonRecord_type(["DriveId", string_type], ["DriveUrl", string_type], ["Filename", string_type]))], ["Name", string_type], ["Filename", string_type], ["NextRevision", option_type(string_type)], ["LastRevision", option_type(string_type)], ["Tags", array_type(ProcessModule_Tag$reflection())], ["Desc", string_type], ["CompanyVersions", bool_type], ["RowPrefix", string_type], ["RowPos", int32_type], ["Version", string_type], ["Revisions", array_type(ProcessModule_DocRevision$reflection())]]);
}

export class ProcessModule_ProcessActivityDocForm extends Record {
    constructor(Entry, Filename, FileContent) {
        super();
        this.Entry = Entry;
        this.Filename = Filename;
        this.FileContent = FileContent;
    }
}

export function ProcessModule_ProcessActivityDocForm$reflection() {
    return record_type("ApiDataTypes.ProcessModule.ProcessActivityDocForm", [], ProcessModule_ProcessActivityDocForm, () => [["Entry", ProcessModule_ProcessActivityDoc$reflection()], ["Filename", string_type], ["FileContent", string_type]]);
}

export class ProcessModule_ProcessActivity extends Record {
    constructor(Id, ProcessId, Name, Desc, RowPrefix, RowPos, Docs, NumDocs) {
        super();
        this.Id = (Id | 0);
        this.ProcessId = (ProcessId | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.RowPrefix = RowPrefix;
        this.RowPos = (RowPos | 0);
        this.Docs = Docs;
        this.NumDocs = (NumDocs | 0);
    }
}

export function ProcessModule_ProcessActivity$reflection() {
    return record_type("ApiDataTypes.ProcessModule.ProcessActivity", [], ProcessModule_ProcessActivity, () => [["Id", int32_type], ["ProcessId", int32_type], ["Name", string_type], ["Desc", string_type], ["RowPrefix", string_type], ["RowPos", int32_type], ["Docs", array_type(ProcessModule_ProcessActivityDoc$reflection())], ["NumDocs", int32_type]]);
}

export class ProcessModule_Process extends Record {
    constructor(Id, Name, Desc, RowPrefix, RowPos, ParentId, Children, Activities, NumChildren, NumActivities, IsManagement) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Desc = Desc;
        this.RowPrefix = RowPrefix;
        this.RowPos = (RowPos | 0);
        this.ParentId = ParentId;
        this.Children = Children;
        this.Activities = Activities;
        this.NumChildren = (NumChildren | 0);
        this.NumActivities = (NumActivities | 0);
        this.IsManagement = IsManagement;
    }
}

export function ProcessModule_Process$reflection() {
    return record_type("ApiDataTypes.ProcessModule.Process", [], ProcessModule_Process, () => [["Id", int32_type], ["Name", string_type], ["Desc", string_type], ["RowPrefix", string_type], ["RowPos", int32_type], ["ParentId", option_type(int32_type)], ["Children", array_type(ProcessModule_Process$reflection())], ["Activities", array_type(ProcessModule_ProcessActivity$reflection())], ["NumChildren", int32_type], ["NumActivities", int32_type], ["IsManagement", bool_type]]);
}

export class ProcessModule_SharePointSite extends Record {
    constructor(Id, Name, Description, WebUrl) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.WebUrl = WebUrl;
    }
}

export function ProcessModule_SharePointSite$reflection() {
    return record_type("ApiDataTypes.ProcessModule.SharePointSite", [], ProcessModule_SharePointSite, () => [["Id", string_type], ["Name", string_type], ["Description", string_type], ["WebUrl", string_type]]);
}

export class ProcessModule_CompanyOnedriveStatus extends Record {
    constructor(Id, Name, Site) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Site = Site;
    }
}

export function ProcessModule_CompanyOnedriveStatus$reflection() {
    return record_type("ApiDataTypes.ProcessModule.CompanyOnedriveStatus", [], ProcessModule_CompanyOnedriveStatus, () => [["Id", int32_type], ["Name", string_type], ["Site", option_type(anonRecord_type(["Name", string_type], ["SiteId", string_type]))]]);
}

export class ProcessModule_PreviewState extends Record {
    constructor(Applicable, Source, Link, FileExt) {
        super();
        this.Applicable = Applicable;
        this.Source = Source;
        this.Link = Link;
        this.FileExt = FileExt;
    }
}

export function ProcessModule_PreviewState$reflection() {
    return record_type("ApiDataTypes.ProcessModule.PreviewState", [], ProcessModule_PreviewState, () => [["Applicable", bool_type], ["Source", string_type], ["Link", string_type], ["FileExt", string_type]]);
}

export class ProjectPlan_PlanTemplate extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function ProjectPlan_PlanTemplate$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.PlanTemplate", [], ProjectPlan_PlanTemplate, () => [["Id", int32_type], ["Name", string_type]]);
}

export class ProjectPlan_Comment extends Record {
    constructor(Id, Comment$, UserId, User) {
        super();
        this.Id = (Id | 0);
        this.Comment = Comment$;
        this.UserId = (UserId | 0);
        this.User = User;
    }
}

export function ProjectPlan_Comment$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.Comment", [], ProjectPlan_Comment, () => [["Id", int32_type], ["Comment", string_type], ["UserId", int32_type], ["User", string_type]]);
}

export class ProjectPlan_Link extends Record {
    constructor(Id, Url, Name, UserId, User) {
        super();
        this.Id = (Id | 0);
        this.Url = Url;
        this.Name = Name;
        this.UserId = (UserId | 0);
        this.User = User;
    }
}

export function ProjectPlan_Link$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.Link", [], ProjectPlan_Link, () => [["Id", int32_type], ["Url", string_type], ["Name", string_type], ["UserId", int32_type], ["User", string_type]]);
}

export class ProjectPlan_Responsible extends Record {
    constructor(Id, Code, Role) {
        super();
        this.Id = (Id | 0);
        this.Code = Code;
        this.Role = Role;
    }
}

export function ProjectPlan_Responsible$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.Responsible", [], ProjectPlan_Responsible, () => [["Id", int32_type], ["Code", string_type], ["Role", string_type]]);
}

export class ProjectPlan_ProjectDoc extends Record {
    constructor(Id, ActivityId, DriveId, DriveUrl, Prefix, Name, Filename, Desc, RowPos, Responsible, Category) {
        super();
        this.Id = (Id | 0);
        this.ActivityId = (ActivityId | 0);
        this.DriveId = DriveId;
        this.DriveUrl = DriveUrl;
        this.Prefix = Prefix;
        this.Name = Name;
        this.Filename = Filename;
        this.Desc = Desc;
        this.RowPos = (RowPos | 0);
        this.Responsible = Responsible;
        this.Category = (Category | 0);
    }
}

export function ProjectPlan_ProjectDoc$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.ProjectDoc", [], ProjectPlan_ProjectDoc, () => [["Id", int32_type], ["ActivityId", int32_type], ["DriveId", string_type], ["DriveUrl", string_type], ["Prefix", string_type], ["Name", string_type], ["Filename", string_type], ["Desc", string_type], ["RowPos", int32_type], ["Responsible", array_type(ProjectPlan_Responsible$reflection())], ["Category", int32_type]]);
}

export class ProjectPlan_ProjectDocForm extends Record {
    constructor(Entry, Filename, FileContent) {
        super();
        this.Entry = Entry;
        this.Filename = Filename;
        this.FileContent = FileContent;
    }
}

export function ProjectPlan_ProjectDocForm$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.ProjectDocForm", [], ProjectPlan_ProjectDocForm, () => [["Entry", ProjectPlan_ProjectDoc$reflection()], ["Filename", string_type], ["FileContent", string_type]]);
}

export class ProjectPlan_ProjectActivityUserResponsibility extends Record {
    constructor(ProjectId, DeadLine, ActivityName, ActivityDesc) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.DeadLine = DeadLine;
        this.ActivityName = ActivityName;
        this.ActivityDesc = ActivityDesc;
    }
}

export function ProjectPlan_ProjectActivityUserResponsibility$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.ProjectActivityUserResponsibility", [], ProjectPlan_ProjectActivityUserResponsibility, () => [["ProjectId", int32_type], ["DeadLine", option_type(class_type("System.DateTime"))], ["ActivityName", string_type], ["ActivityDesc", string_type]]);
}

export class ProjectPlan_ActivityResponsible extends Record {
    constructor(Id, ActivityId, UserId, Username, Fullname) {
        super();
        this.Id = (Id | 0);
        this.ActivityId = (ActivityId | 0);
        this.UserId = (UserId | 0);
        this.Username = Username;
        this.Fullname = Fullname;
    }
}

export function ProjectPlan_ActivityResponsible$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.ActivityResponsible", [], ProjectPlan_ActivityResponsible, () => [["Id", int32_type], ["ActivityId", int32_type], ["UserId", int32_type], ["Username", string_type], ["Fullname", string_type]]);
}

export class ProjectPlan_ProjectActivity extends Record {
    constructor(Id, ProcessId, Prefix, Name, Desc, RowPos, Docs, Comments, Links, NumDocs, DriveId, DriveUrl, Deadline, State, Responsible) {
        super();
        this.Id = (Id | 0);
        this.ProcessId = (ProcessId | 0);
        this.Prefix = Prefix;
        this.Name = Name;
        this.Desc = Desc;
        this.RowPos = (RowPos | 0);
        this.Docs = Docs;
        this.Comments = Comments;
        this.Links = Links;
        this.NumDocs = (NumDocs | 0);
        this.DriveId = DriveId;
        this.DriveUrl = DriveUrl;
        this.Deadline = Deadline;
        this.State = (State | 0);
        this.Responsible = Responsible;
    }
}

export function ProjectPlan_ProjectActivity$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.ProjectActivity", [], ProjectPlan_ProjectActivity, () => [["Id", int32_type], ["ProcessId", int32_type], ["Prefix", string_type], ["Name", string_type], ["Desc", string_type], ["RowPos", int32_type], ["Docs", array_type(ProjectPlan_ProjectDoc$reflection())], ["Comments", array_type(ProjectPlan_Comment$reflection())], ["Links", array_type(ProjectPlan_Link$reflection())], ["NumDocs", int32_type], ["DriveId", string_type], ["DriveUrl", string_type], ["Deadline", option_type(string_type)], ["State", int32_type], ["Responsible", array_type(ProjectPlan_ActivityResponsible$reflection())]]);
}

export class ProjectPlan_ProjectProcess extends Record {
    constructor(Id, TemplateId, Prefix, Name, Desc, RowPos, ParentId, Children, Activities, NumChildren, NumActivities, DriveId, DriveUrl) {
        super();
        this.Id = (Id | 0);
        this.TemplateId = (TemplateId | 0);
        this.Prefix = Prefix;
        this.Name = Name;
        this.Desc = Desc;
        this.RowPos = (RowPos | 0);
        this.ParentId = ParentId;
        this.Children = Children;
        this.Activities = Activities;
        this.NumChildren = (NumChildren | 0);
        this.NumActivities = (NumActivities | 0);
        this.DriveId = DriveId;
        this.DriveUrl = DriveUrl;
    }
}

export function ProjectPlan_ProjectProcess$reflection() {
    return record_type("ApiDataTypes.ProjectPlan.ProjectProcess", [], ProjectPlan_ProjectProcess, () => [["Id", int32_type], ["TemplateId", int32_type], ["Prefix", string_type], ["Name", string_type], ["Desc", string_type], ["RowPos", int32_type], ["ParentId", option_type(int32_type)], ["Children", array_type(ProjectPlan_ProjectProcess$reflection())], ["Activities", array_type(ProjectPlan_ProjectActivity$reflection())], ["NumChildren", int32_type], ["NumActivities", int32_type], ["DriveId", string_type], ["DriveUrl", string_type]]);
}

export class Competence_SkillLevel extends Record {
    constructor(Id, Name, Weight, ColorCode, TextColorCode) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Weight = (Weight | 0);
        this.ColorCode = ColorCode;
        this.TextColorCode = TextColorCode;
    }
}

export function Competence_SkillLevel$reflection() {
    return record_type("ApiDataTypes.Competence.SkillLevel", [], Competence_SkillLevel, () => [["Id", int32_type], ["Name", string_type], ["Weight", int32_type], ["ColorCode", string_type], ["TextColorCode", string_type]]);
}

export class Competence_Skill extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function Competence_Skill$reflection() {
    return record_type("ApiDataTypes.Competence.Skill", [], Competence_Skill, () => [["Id", int32_type], ["Name", string_type]]);
}

export class Competence_UserSkill extends Record {
    constructor(Id, UserId, Level, Skill) {
        super();
        this.Id = (Id | 0);
        this.UserId = (UserId | 0);
        this.Level = Level;
        this.Skill = Skill;
    }
}

export function Competence_UserSkill$reflection() {
    return record_type("ApiDataTypes.Competence.UserSkill", [], Competence_UserSkill, () => [["Id", int32_type], ["UserId", int32_type], ["Level", Competence_SkillLevel$reflection()], ["Skill", Competence_Skill$reflection()]]);
}

export class Competence_Course extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function Competence_Course$reflection() {
    return record_type("ApiDataTypes.Competence.Course", [], Competence_Course, () => [["Id", int32_type], ["Name", string_type]]);
}

export class Competence_UserCertification extends Record {
    constructor(Id, Name, Date$) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.Date = Date$;
    }
}

export function Competence_UserCertification$reflection() {
    return record_type("ApiDataTypes.Competence.UserCertification", [], Competence_UserCertification, () => [["Id", int32_type], ["Name", string_type], ["Date", string_type]]);
}

export class Competence_UserCourse extends Record {
    constructor(Id, UserId, Start, End, Course, Certifications) {
        super();
        this.Id = (Id | 0);
        this.UserId = (UserId | 0);
        this.Start = Start;
        this.End = End;
        this.Course = Course;
        this.Certifications = Certifications;
    }
}

export function Competence_UserCourse$reflection() {
    return record_type("ApiDataTypes.Competence.UserCourse", [], Competence_UserCourse, () => [["Id", int32_type], ["UserId", int32_type], ["Start", string_type], ["End", string_type], ["Course", Competence_Course$reflection()], ["Certifications", array_type(Competence_UserCertification$reflection())]]);
}

export class Competence_ResourceSearchEntry extends Record {
    constructor(User, Skills, Courses) {
        super();
        this.User = User;
        this.Skills = Skills;
        this.Courses = Courses;
    }
}

export function Competence_ResourceSearchEntry$reflection() {
    return record_type("ApiDataTypes.Competence.ResourceSearchEntry", [], Competence_ResourceSearchEntry, () => [["User", User$reflection()], ["Skills", array_type(Competence_UserSkill$reflection())], ["Courses", array_type(Competence_UserCourse$reflection())]]);
}

export class FilingKeyRef extends Record {
    constructor(id, filingkeyid, objtype, objid) {
        super();
        this.id = (id | 0);
        this.filingkeyid = (filingkeyid | 0);
        this.objtype = objtype;
        this.objid = (objid | 0);
    }
}

export function FilingKeyRef$reflection() {
    return record_type("ApiDataTypes.FilingKeyRef", [], FilingKeyRef, () => [["id", int32_type], ["filingkeyid", int32_type], ["objtype", string_type], ["objid", int32_type]]);
}

export function FilingKeyRef_get_Empty() {
    return new FilingKeyRef(0, 0, "", 0);
}

export class FilingKey extends Record {
    constructor(id, code, name, description, rowpos, parentid, children, refs) {
        super();
        this.id = (id | 0);
        this.code = code;
        this.name = name;
        this.description = description;
        this.rowpos = (rowpos | 0);
        this.parentid = (parentid | 0);
        this.children = children;
        this.refs = refs;
    }
}

export function FilingKey$reflection() {
    return record_type("ApiDataTypes.FilingKey", [], FilingKey, () => [["id", int32_type], ["code", string_type], ["name", string_type], ["description", string_type], ["rowpos", int32_type], ["parentid", int32_type], ["children", array_type(FilingKey$reflection())], ["refs", array_type(FilingKeyRef$reflection())]]);
}

export class FileStore extends Record {
    constructor(id, name, description, content, uploaded) {
        super();
        this.id = (id | 0);
        this.name = name;
        this.description = description;
        this.content = content;
        this.uploaded = uploaded;
    }
}

export function FileStore$reflection() {
    return record_type("ApiDataTypes.FileStore", [], FileStore, () => [["id", int32_type], ["name", string_type], ["description", string_type], ["content", string_type], ["uploaded", class_type("System.DateTime")]]);
}

export function FileStore_get_Empty() {
    return new FileStore(0, "", "", "", now());
}

export class FileStoreUploadDoc extends Record {
    constructor(doc, filestore, pdffilestore, supfilestore) {
        super();
        this.doc = doc;
        this.filestore = filestore;
        this.pdffilestore = pdffilestore;
        this.supfilestore = supfilestore;
    }
}

export function FileStoreUploadDoc$reflection() {
    return record_type("ApiDataTypes.FileStoreUploadDoc", [], FileStoreUploadDoc, () => [["doc", ProcessActivityDoc$reflection()], ["filestore", FileStore$reflection()], ["pdffilestore", FileStore$reflection()], ["supfilestore", FileStore$reflection()]]);
}

export class FileStoreUploadVer extends Record {
    constructor(doc, ver, filestore, pdffilestore, supfilestore) {
        super();
        this.doc = doc;
        this.ver = ver;
        this.filestore = filestore;
        this.pdffilestore = pdffilestore;
        this.supfilestore = supfilestore;
    }
}

export function FileStoreUploadVer$reflection() {
    return record_type("ApiDataTypes.FileStoreUploadVer", [], FileStoreUploadVer, () => [["doc", ProcessActivityDoc$reflection()], ["ver", ProcessActivityDocVersion$reflection()], ["filestore", FileStore$reflection()], ["pdffilestore", FileStore$reflection()], ["supfilestore", FileStore$reflection()]]);
}

export class FileStoreUploadCompVer extends Record {
    constructor(compVer, filestore, pdffilestore, supfilestore) {
        super();
        this.compVer = compVer;
        this.filestore = filestore;
        this.pdffilestore = pdffilestore;
        this.supfilestore = supfilestore;
    }
}

export function FileStoreUploadCompVer$reflection() {
    return record_type("ApiDataTypes.FileStoreUploadCompVer", [], FileStoreUploadCompVer, () => [["compVer", CompanyProcessActivityDoc$reflection()], ["filestore", FileStore$reflection()], ["pdffilestore", FileStore$reflection()], ["supfilestore", FileStore$reflection()]]);
}

export class CVPrintRequest extends Record {
    constructor(UserId, PrintDesc, PrintKC, PrintProjectRef, PrintEmployment, PrintEducation, PrintCourses, Desc, KeyCompetencies, ProjectReferences, ExternalReferences, Employment, Education, Courses) {
        super();
        this.UserId = (UserId | 0);
        this.PrintDesc = PrintDesc;
        this.PrintKC = PrintKC;
        this.PrintProjectRef = PrintProjectRef;
        this.PrintEmployment = PrintEmployment;
        this.PrintEducation = PrintEducation;
        this.PrintCourses = PrintCourses;
        this.Desc = Desc;
        this.KeyCompetencies = KeyCompetencies;
        this.ProjectReferences = ProjectReferences;
        this.ExternalReferences = ExternalReferences;
        this.Employment = Employment;
        this.Education = Education;
        this.Courses = Courses;
    }
}

export function CVPrintRequest$reflection() {
    return record_type("ApiDataTypes.CVPrintRequest", [], CVPrintRequest, () => [["UserId", int32_type], ["PrintDesc", bool_type], ["PrintKC", bool_type], ["PrintProjectRef", bool_type], ["PrintEmployment", bool_type], ["PrintEducation", bool_type], ["PrintCourses", bool_type], ["Desc", string_type], ["KeyCompetencies", string_type], ["ProjectReferences", array_type(UserReferenceProject$reflection())], ["ExternalReferences", array_type(ExternalProjectReference$reflection())], ["Employment", array_type(UserEmployment$reflection())], ["Education", array_type(UserEducation$reflection())], ["Courses", array_type(Competence_UserCourse$reflection())]]);
}

export class PdfFile extends Record {
    constructor(filename, filestoreid, content) {
        super();
        this.filename = filename;
        this.filestoreid = (filestoreid | 0);
        this.content = content;
    }
}

export function PdfFile$reflection() {
    return record_type("ApiDataTypes.PdfFile", [], PdfFile, () => [["filename", string_type], ["filestoreid", int32_type], ["content", string_type]]);
}

export class UserProfileAccess extends Record {
    constructor(company, cv, edit) {
        super();
        this.company = company;
        this.cv = cv;
        this.edit = edit;
    }
}

export function UserProfileAccess$reflection() {
    return record_type("ApiDataTypes.UserProfileAccess", [], UserProfileAccess, () => [["company", Company$reflection()], ["cv", array_type(CompanyRole$reflection())], ["edit", array_type(CompanyRole$reflection())]]);
}

export class DocumentNotification extends Record {
    constructor(id, comprole, notifydate, notifytext) {
        super();
        this.id = (id | 0);
        this.comprole = comprole;
        this.notifydate = notifydate;
        this.notifytext = notifytext;
    }
}

export function DocumentNotification$reflection() {
    return record_type("ApiDataTypes.DocumentNotification", [], DocumentNotification, () => [["id", int32_type], ["comprole", CompanyRole$reflection()], ["notifydate", class_type("System.DateTime")], ["notifytext", string_type]]);
}

export class DocumentNotificationForm extends Record {
    constructor(patid, comproleid, notifydate, notifytext) {
        super();
        this.patid = (patid | 0);
        this.comproleid = (comproleid | 0);
        this.notifydate = notifydate;
        this.notifytext = notifytext;
    }
}

export function DocumentNotificationForm$reflection() {
    return record_type("ApiDataTypes.DocumentNotificationForm", [], DocumentNotificationForm, () => [["patid", int32_type], ["comproleid", int32_type], ["notifydate", class_type("System.DateTime")], ["notifytext", string_type]]);
}

export class DocumentRevision extends Record {
    constructor(docid, name, revised) {
        super();
        this.docid = (docid | 0);
        this.name = name;
        this.revised = revised;
    }
}

export function DocumentRevision$reflection() {
    return record_type("ApiDataTypes.DocumentRevision", [], DocumentRevision, () => [["docid", int32_type], ["name", string_type], ["revised", class_type("System.DateTime")]]);
}

export class DocumentOverviewEntry extends Record {
    constructor(documentid, documentname, filestoreid, filename, companyversions) {
        super();
        this.documentid = (documentid | 0);
        this.documentname = documentname;
        this.filestoreid = (filestoreid | 0);
        this.filename = filename;
        this.companyversions = companyversions;
    }
}

export function DocumentOverviewEntry$reflection() {
    return record_type("ApiDataTypes.DocumentOverviewEntry", [], DocumentOverviewEntry, () => [["documentid", int32_type], ["documentname", string_type], ["filestoreid", int32_type], ["filename", string_type], ["companyversions", bool_type]]);
}

export class DocumentOverviewActivity extends Record {
    constructor(activityid, activityname, docs) {
        super();
        this.activityid = (activityid | 0);
        this.activityname = activityname;
        this.docs = docs;
    }
}

export function DocumentOverviewActivity$reflection() {
    return record_type("ApiDataTypes.DocumentOverviewActivity", [], DocumentOverviewActivity, () => [["activityid", int32_type], ["activityname", string_type], ["docs", array_type(DocumentOverviewEntry$reflection())]]);
}

export class DocumentOverviewProcess extends Record {
    constructor(processid, processname, subprocesses, activities) {
        super();
        this.processid = (processid | 0);
        this.processname = processname;
        this.subprocesses = subprocesses;
        this.activities = activities;
    }
}

export function DocumentOverviewProcess$reflection() {
    return record_type("ApiDataTypes.DocumentOverviewProcess", [], DocumentOverviewProcess, () => [["processid", int32_type], ["processname", string_type], ["subprocesses", array_type(DocumentOverviewProcess$reflection())], ["activities", array_type(DocumentOverviewActivity$reflection())]]);
}

export class DeleteResponse extends Record {
    constructor(result, message, id) {
        super();
        this.result = result;
        this.message = message;
        this.id = (id | 0);
    }
}

export function DeleteResponse$reflection() {
    return record_type("ApiDataTypes.DeleteResponse", [], DeleteResponse, () => [["result", string_type], ["message", string_type], ["id", int32_type]]);
}

export class HValueEntry extends Record {
    constructor(month, year, hh1val, hh2val) {
        super();
        this.month = (month | 0);
        this.year = (year | 0);
        this.hh1val = hh1val;
        this.hh2val = hh2val;
    }
}

export function HValueEntry$reflection() {
    return record_type("ApiDataTypes.HValueEntry", [], HValueEntry, () => [["month", int32_type], ["year", int32_type], ["hh1val", float64_type], ["hh2val", float64_type]]);
}

export class HValueSummary extends Record {
    constructor(periodhh1, periodhh2, monthh1, monthh2, yearhh1, yearhh2) {
        super();
        this.periodhh1 = periodhh1;
        this.periodhh2 = periodhh2;
        this.monthh1 = monthh1;
        this.monthh2 = monthh2;
        this.yearhh1 = yearhh1;
        this.yearhh2 = yearhh2;
    }
}

export function HValueSummary$reflection() {
    return record_type("ApiDataTypes.HValueSummary", [], HValueSummary, () => [["periodhh1", float64_type], ["periodhh2", float64_type], ["monthh1", float64_type], ["monthh2", float64_type], ["yearhh1", float64_type], ["yearhh2", float64_type]]);
}

export class ProjectVacationRequest extends Record {
    constructor(userid, periodstart, periodend) {
        super();
        this.userid = (userid | 0);
        this.periodstart = periodstart;
        this.periodend = periodend;
    }
}

export function ProjectVacationRequest$reflection() {
    return record_type("ApiDataTypes.ProjectVacationRequest", [], ProjectVacationRequest, () => [["userid", int32_type], ["periodstart", class_type("System.DateTime")], ["periodend", class_type("System.DateTime")]]);
}

export class UserProjectPeriod extends Record {
    constructor(project, role, allocated, period) {
        super();
        this.project = project;
        this.role = role;
        this.allocated = (allocated | 0);
        this.period = period;
    }
}

export function UserProjectPeriod$reflection() {
    return record_type("ApiDataTypes.UserProjectPeriod", [], UserProjectPeriod, () => [["project", string_type], ["role", string_type], ["allocated", int32_type], ["period", Helpers_TimePeriod$reflection()]]);
}

export class UserProjectVacation extends Record {
    constructor(userid, vacation, projects) {
        super();
        this.userid = (userid | 0);
        this.vacation = vacation;
        this.projects = projects;
    }
}

export function UserProjectVacation$reflection() {
    return record_type("ApiDataTypes.UserProjectVacation", [], UserProjectVacation, () => [["userid", int32_type], ["vacation", array_type(Helpers_TimePeriod$reflection())], ["projects", array_type(UserProjectPeriod$reflection())]]);
}

export class Print_ProjectReferenceData extends Record {
    constructor(ProjId, Desc, ShortDesc, EnvProfile, Areal, Sum, Builder, BuildTime, EnterpriseForm, EnergyClass, EnvDemands, ActionClasses, ContactPerson, ContactPhone, ContactEmail, IncludeDesc, IncludeRole, IncludeEnvProfile, IncludeSum, IncludeAreal, IncludeEnterpriseForm, IncludeActionClasses, IncludeBuildTime, IncludeEnvDemand, IncludeEnergyClass, IncludeBuilder, IncludeContactPerson, IncludeContactPhone, IncludeContactMail) {
        super();
        this.ProjId = (ProjId | 0);
        this.Desc = Desc;
        this.ShortDesc = ShortDesc;
        this.EnvProfile = EnvProfile;
        this.Areal = Areal;
        this.Sum = Sum;
        this.Builder = Builder;
        this.BuildTime = BuildTime;
        this.EnterpriseForm = EnterpriseForm;
        this.EnergyClass = EnergyClass;
        this.EnvDemands = EnvDemands;
        this.ActionClasses = ActionClasses;
        this.ContactPerson = ContactPerson;
        this.ContactPhone = ContactPhone;
        this.ContactEmail = ContactEmail;
        this.IncludeDesc = IncludeDesc;
        this.IncludeRole = IncludeRole;
        this.IncludeEnvProfile = IncludeEnvProfile;
        this.IncludeSum = IncludeSum;
        this.IncludeAreal = IncludeAreal;
        this.IncludeEnterpriseForm = IncludeEnterpriseForm;
        this.IncludeActionClasses = IncludeActionClasses;
        this.IncludeBuildTime = IncludeBuildTime;
        this.IncludeEnvDemand = IncludeEnvDemand;
        this.IncludeEnergyClass = IncludeEnergyClass;
        this.IncludeBuilder = IncludeBuilder;
        this.IncludeContactPerson = IncludeContactPerson;
        this.IncludeContactPhone = IncludeContactPhone;
        this.IncludeContactMail = IncludeContactMail;
    }
}

export function Print_ProjectReferenceData$reflection() {
    return record_type("ApiDataTypes.Print.ProjectReferenceData", [], Print_ProjectReferenceData, () => [["ProjId", int32_type], ["Desc", string_type], ["ShortDesc", string_type], ["EnvProfile", string_type], ["Areal", string_type], ["Sum", string_type], ["Builder", string_type], ["BuildTime", string_type], ["EnterpriseForm", string_type], ["EnergyClass", string_type], ["EnvDemands", string_type], ["ActionClasses", string_type], ["ContactPerson", string_type], ["ContactPhone", string_type], ["ContactEmail", string_type], ["IncludeDesc", bool_type], ["IncludeRole", bool_type], ["IncludeEnvProfile", bool_type], ["IncludeSum", bool_type], ["IncludeAreal", bool_type], ["IncludeEnterpriseForm", bool_type], ["IncludeActionClasses", bool_type], ["IncludeBuildTime", bool_type], ["IncludeEnvDemand", bool_type], ["IncludeEnergyClass", bool_type], ["IncludeBuilder", bool_type], ["IncludeContactPerson", bool_type], ["IncludeContactPhone", bool_type], ["IncludeContactMail", bool_type]]);
}

export class Print_CVData extends Record {
    constructor(UserId, Desc, KeyCompetencies, MostRelevant, ProjectReferences, ExternalReferences, Employment, Education, Courses, IncludeMostRelevant, IncludeInternal, IncludeExternal, IncludeEmployment, IncludeEducation, IncludeCourses) {
        super();
        this.UserId = (UserId | 0);
        this.Desc = Desc;
        this.KeyCompetencies = KeyCompetencies;
        this.MostRelevant = MostRelevant;
        this.ProjectReferences = ProjectReferences;
        this.ExternalReferences = ExternalReferences;
        this.Employment = Employment;
        this.Education = Education;
        this.Courses = Courses;
        this.IncludeMostRelevant = IncludeMostRelevant;
        this.IncludeInternal = IncludeInternal;
        this.IncludeExternal = IncludeExternal;
        this.IncludeEmployment = IncludeEmployment;
        this.IncludeEducation = IncludeEducation;
        this.IncludeCourses = IncludeCourses;
    }
}

export function Print_CVData$reflection() {
    return record_type("ApiDataTypes.Print.CVData", [], Print_CVData, () => [["UserId", int32_type], ["Desc", string_type], ["KeyCompetencies", string_type], ["MostRelevant", array_type(tuple_type(string_type, int32_type))], ["ProjectReferences", array_type(int32_type)], ["ExternalReferences", array_type(int32_type)], ["Employment", array_type(int32_type)], ["Education", array_type(int32_type)], ["Courses", array_type(int32_type)], ["IncludeMostRelevant", bool_type], ["IncludeInternal", bool_type], ["IncludeExternal", bool_type], ["IncludeEmployment", bool_type], ["IncludeEducation", bool_type], ["IncludeCourses", bool_type]]);
}

