import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { anonRecord_type, obj_type, union_type, record_type, string_type, bool_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ProjectPlan_Responsible$reflection, ProjectPlan_Responsible, ProjectPlan_ActivityResponsible$reflection, ProjectPlan_ActivityResponsible, Helpers_ResultMessage$reflection, ProjectPlan_Link$reflection, ProjectPlan_Link, ProjectPlan_Comment$reflection, ProjectPlan_Comment, ProjectRole$reflection, ProjectPlan_ProjectProcess$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { hasPermission } from "../LoginState.js";
import { Functionality__ToInt, Functionality } from "../Shared/PermissionMapping.js";
import { sortBy, maxBy, map, append, tryFind } from "../.fable/fable-library.3.2.4/Array.js";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { comparePrimitives, createObj, uncurry, numberHash } from "../.fable/fable-library.3.2.4/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { interpolate, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { loadingIndicatorMedium, loadingIndicatorSmall, PageElements_filenameToIcon, snackbarError, errorBanner } from "../ViewHelpers.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { now, date, compare, parse, toString } from "../.fable/fable-library.3.2.4/Date.js";
import { UserSearch } from "../UserSearch.js";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Document_AddFormTarget, Document_AddForm, Document_categoryTag } from "../Administration/ProjectPlanTemplate.js";
import { fromUtcToLocalDate } from "../Utils.js";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Remove from "@material-ui/icons/Remove";
import InsertComment from "@material-ui/icons/InsertComment";
import Link from "@material-ui/icons/Link";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Project_fetchLoggedInUserPermissions } from "../Promises.js";

class Model extends Record {
    constructor(ProjectId, Plan, ProjectRoles, CanEdit, ShowWork, ShowHelper, ShowSupport, ShowResponsible, SelectedRoles, ErrorMsg) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Plan = Plan;
        this.ProjectRoles = ProjectRoles;
        this.CanEdit = CanEdit;
        this.ShowWork = ShowWork;
        this.ShowHelper = ShowHelper;
        this.ShowSupport = ShowSupport;
        this.ShowResponsible = ShowResponsible;
        this.SelectedRoles = SelectedRoles;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ProjectPlan.Model", [], Model, () => [["ProjectId", int32_type], ["Plan", option_type(array_type(ProjectPlan_ProjectProcess$reflection()))], ["ProjectRoles", option_type(array_type(ProjectRole$reflection()))], ["CanEdit", bool_type], ["ShowWork", bool_type], ["ShowHelper", bool_type], ["ShowSupport", bool_type], ["ShowResponsible", bool_type], ["SelectedRoles", array_type(ProjectRole$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PlanResponse", "PermissionsResponse", "ProjectRolesResponse", "ToggleShowWork", "ToggleShowHelper", "ToggleShowSupport", "ToggleShowResponsible", "SelectRole", "RemoveRole", "SelectAllRoles", "RemoveAllRoles", "SelectAllCategories", "UnselectAllCategories", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProjectPlan.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectPlan_ProjectProcess$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectPlan_ProjectProcess$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(int32_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(int32_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectRole$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectRole$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", int32_type]], [["Item", int32_type]], [], [], [], [], []]);
}

function init(projectId) {
    return new Model(projectId, void 0, void 0, false, true, true, true, true, [], void 0);
}

function update(model, msg) {
    let x_2, matchValue, _arg1, matchValue_1;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                return new Model(model.ProjectId, model.Plan, model.ProjectRoles, hasPermission(new Functionality(60)) ? true : res_1.fields[0].some((x_2 = (Functionality__ToInt(new Functionality(2)) | 0), (y_2) => (x_2 === y_2))), model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, model.SelectedRoles, model.ErrorMsg);
            }
            else {
                return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, model.SelectedRoles, "Kunne ikke laste prosjektrettigheter.");
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.ProjectId, model.Plan, res_2.fields[0], model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, model.SelectedRoles, model.ErrorMsg);
            }
        }
        case 3: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, msg.fields[0], model.ShowHelper, model.ShowSupport, model.ShowResponsible, model.SelectedRoles, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, msg.fields[0], model.ShowSupport, model.ShowResponsible, model.SelectedRoles, model.ErrorMsg);
        }
        case 5: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, msg.fields[0], model.ShowResponsible, model.SelectedRoles, model.ErrorMsg);
        }
        case 6: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, msg.fields[0], model.SelectedRoles, model.ErrorMsg);
        }
        case 7: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, (matchValue = model.ProjectRoles, (matchValue != null) ? ((_arg1 = tryFind((r) => (r.Id === msg.fields[0]), matchValue), (_arg1 != null) ? Array_distinctBy((r_1) => r_1.Id, append([_arg1], model.SelectedRoles), {
                Equals: (x_9, y_3) => (x_9 === y_3),
                GetHashCode: (x_9) => numberHash(x_9),
            }) : model.SelectedRoles)) : model.SelectedRoles), model.ErrorMsg);
        }
        case 8: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, model.SelectedRoles.filter((z_1) => (z_1.Id !== msg.fields[0])), model.ErrorMsg);
        }
        case 9: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, (matchValue_1 = model.ProjectRoles, (matchValue_1 != null) ? matchValue_1 : []), model.ErrorMsg);
        }
        case 10: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, [], model.ErrorMsg);
        }
        case 11: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, true, true, true, model.ShowResponsible, model.SelectedRoles, model.ErrorMsg);
        }
        case 12: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, false, false, false, model.ShowResponsible, model.SelectedRoles, model.ErrorMsg);
        }
        case 13: {
            return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, model.SelectedRoles, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                let pattern_matching_result;
                if (err.tag === 2) {
                    if ((err.fields[0].status) === 409) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, model.SelectedRoles, "Prosjektet har ikke en kvalitetsplan");
                    }
                    case 1: {
                        return new Model(model.ProjectId, model.Plan, model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, model.SelectedRoles, "Noe gikk galt. Kunne ikke laste prosjektplan");
                    }
                }
            }
            else {
                return new Model(model.ProjectId, res.fields[0], model.ProjectRoles, model.CanEdit, model.ShowWork, model.ShowHelper, model.ShowSupport, model.ShowResponsible, model.SelectedRoles, model.ErrorMsg);
            }
        }
    }
}

function fetchProjectPlan(projectId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/project-plan/%i"))(projectId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProjectPlan_ProjectProcess$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProjectPlan_ProjectProcess$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchProjectRoles(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/project/roles/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProjectRole$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProjectRole$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

export function CommentForm(commentFormInputProps) {
    const onClose = commentFormInputProps.onClose;
    const activityId = commentFormInputProps.activityId;
    const projectId = commentFormInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const comment = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til kommentar"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["size", "small"], ["variant", "outlined"], ["label", "Kommantar"], ["fullWidth", true], ["autoFocus", true], ["multiline", true], ["required", true], ["onChange", (e) => {
            patternInput[1](Browser_Types_Event__Event_get_Value(e));
        }]]), errorBanner(patternInput_1[0], () => {
            setErrMsg(void 0);
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Lukk"], ["onClick", (_arg3) => {
        onClose(void 0);
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", comment.length === 0], ["children", "Legg til"], ["onClick", (_arg4) => {
        if (comment.length > 0) {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const req = new ProjectPlan_Comment(-1, comment, -1, "");
                const requestPath = toText(printf("/api/project-plan/comment/%i/%i"))(projectId)(activityId);
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                        ResolveType: ProjectPlan_Comment$reflection,
                    })(req);
                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: ProjectPlan_Comment$reflection,
                    });
                    return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                        ResolveType: ProjectPlan_Comment$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg1) => {
                    const res = _arg1;
                    if (res.tag === 1) {
                        setErrMsg("Kunne ikke legge til kommentar");
                        return Promise.resolve();
                    }
                    else {
                        onClose(res.fields[0]);
                        return Promise.resolve();
                    }
                }));
            }));
            pr.then();
        }
    }]])])]])])]]);
}

export function LinkForm(linkFormInputProps) {
    const onClose = linkFormInputProps.onClose;
    const activityId = linkFormInputProps.activityId;
    const projectId = linkFormInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const url = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_2[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til lenke/url"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            maxWidth: 400,
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["size", "small"], ["variant", "outlined"], ["label", "Url (https://...)"], ["fullWidth", true], ["autoFocus", true], ["multiline", true], ["required", true], ["onChange", (e) => {
            patternInput[1](Browser_Types_Event__Event_get_Value(e));
        }]]), MuiHelpers_createElement(TextField, [["style", {
            marginTop: 10,
        }], ["size", "small"], ["variant", "outlined"], ["label", "Navn"], ["helperText", "Valgfritt. Gi lange nettaddresser ett alternativ navn."], ["fullWidth", true], ["multiline", true], ["onChange", (e_1) => {
            patternInput_1[1](Browser_Types_Event__Event_get_Value(e_1));
        }]]), errorBanner(patternInput_2[0], () => {
            setErrMsg(void 0);
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Lukk"], ["onClick", (_arg3) => {
        onClose(void 0);
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["disabled", url.length < 8], ["children", "Legg til"], ["onClick", (_arg4) => {
        if (url.length > 7) {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const req = new ProjectPlan_Link(-1, url, patternInput_1[0], -1, "");
                const requestPath = toText(printf("/api/project-plan/link/%i/%i"))(projectId)(activityId);
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                        ResolveType: ProjectPlan_Link$reflection,
                    })(req);
                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: ProjectPlan_Link$reflection,
                    });
                    return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                        ResolveType: ProjectPlan_Link$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg1) => {
                    const res = _arg1;
                    if (res.tag === 1) {
                        setErrMsg("Kunne ikke legge til lenke");
                        return Promise.resolve();
                    }
                    else {
                        onClose(res.fields[0]);
                        return Promise.resolve();
                    }
                }));
            }));
            pr.then();
        }
    }]])])]])])]]);
}

export function DeadlineForm(deadlineFormInputProps) {
    const onClose = deadlineFormInputProps.onClose;
    const x = deadlineFormInputProps.x;
    const activityId = deadlineFormInputProps.activityId;
    const projectId = deadlineFormInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(x);
    const setDeadline = patternInput[1];
    const deadline = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(x);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Sett frist for aktivitet"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            maxWidth: 400,
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton(["type", "date"]), delay(() => append_1(singleton(["variant", "outlined"]), delay(() => append_1(singleton(["size", "small"]), delay(() => append_1(singleton(["fullWidth", true]), delay(() => append_1((deadline == null) ? singleton(["value", "Ingen frist"]) : singleton(["value", deadline]), delay(() => singleton(["onChange", (e) => {
            const d_1 = Browser_Types_Event__Event_get_Value(e);
            try {
                setDeadline(toString(parse(d_1), "yyyy-MM-dd"));
            }
            catch (matchValue) {
                setDeadline(void 0);
            }
        }])))))))))))))), errorBanner(patternInput_1[0], () => {
            setErrMsg(void 0);
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Lukk"], ["onClick", (_arg3) => {
        onClose(x);
    }]]), MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Sett frist"], ["onClick", (_arg4) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let item;
            const requestPath = toText(printf("/api/project-plan/deadline/%i/%i"))(projectId)(activityId);
            return ((item = {
                Deadline: deadline,
            }, PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: () => anonRecord_type(["Deadline", option_type(string_type)]),
                })(item);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })))).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 1) {
                    setErrMsg("Kunne ikke sette frist");
                    return Promise.resolve();
                }
                else {
                    const x_1 = res.fields[0];
                    if (x_1.Result === "success") {
                        onClose(deadline);
                        return Promise.resolve();
                    }
                    else {
                        setErrMsg(x_1.Message);
                        return Promise.resolve();
                    }
                }
            }));
        }));
        pr.then();
    }]])])]])])]]);
}

function ActivityResponsibleForm(activityResponsibleFormInputProps) {
    const onClose = activityResponsibleFormInputProps.onClose;
    const activityId = activityResponsibleFormInputProps.activityId;
    const projectId = activityResponsibleFormInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Velg ansvarlig for aktivitet"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: "expanded-dialog-content",
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray([snackbarError(patternInput[0], () => {
            setErrMsg(void 0);
        }), createElement(UserSearch, {
            onSelect: (x_1) => {
                const u = x_1;
                const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    if (u != null) {
                        const u_1 = u;
                        const requestPath = toText(printf("/api/project-plan/activity-responsible/%i"))(projectId);
                        const req = new ProjectPlan_ActivityResponsible(-1, activityId, u_1.Id, u_1.Username, toText(interpolate("%P() %P()", [u_1.Firstname, u_1.Lastname])));
                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: ProjectPlan_ActivityResponsible$reflection,
                            })(req);
                            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: ProjectPlan_ActivityResponsible$reflection,
                            });
                            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                ResolveType: ProjectPlan_ActivityResponsible$reflection,
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg1) => {
                            const res = _arg1;
                            if (res.tag === 1) {
                                setErrMsg("Kunne ikke sette aktivitetsansvarlig");
                                return Promise.resolve();
                            }
                            else {
                                onClose(res.fields[0]);
                                return Promise.resolve();
                            }
                        }));
                    }
                    else {
                        return Promise.resolve();
                    }
                }));
                pr.then();
            },
        })]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg3) => {
        onClose(void 0);
    }]])])]])])]]);
}

export function DrawDocument(drawDocumentInputProps) {
    const showResponsible = drawDocumentInputProps.showResponsible;
    const canEdit = drawDocumentInputProps.canEdit;
    const projectRoles = drawDocumentInputProps.projectRoles;
    const projectId = drawDocumentInputProps.projectId;
    const x = drawDocumentInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505(x.Responsible);
    const setResponsible = patternInput[1];
    const responsible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505({
        menuOpen: false,
    });
    const updateState = patternInput_1[1];
    const anchor = useReact_useRef_1505(void 0);
    let patternInput_2;
    const _arg1 = (x.Prefix.length > 0) ? toText(printf("%s - %s"))(x.Prefix)(x.Name) : x.Name;
    if (_arg1.length > 35) {
        const y_1 = _arg1;
        patternInput_2 = [y_1.slice(0, (35 - 3) + 1) + "...", y_1];
    }
    else {
        patternInput_2 = [_arg1, void 0];
    }
    const filenameTooltip = patternInput_2[1];
    const filename = patternInput_2[0];
    return createElement("div", {
        style: {
            display: "flex",
            borderBottom: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                padding: 3,
                paddingLeft: 8,
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    display: "flex",
                    alignItems: "center",
                    width: 450,
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(showResponsible ? singleton(createElement("div", {
                    style: {
                        margin: ((0 + "px ") + 4) + "px",
                        display: "flex",
                        alignItems: "center",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(canEdit ? singleton(createElement("div", {
                        style: {},
                        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["ref", anchor], ["title", "Legg til ansvarlig rolle"], ["placement", "left"], ["children", createElement("span", {
                            style: {
                                marginRight: 3,
                                cursor: "pointer",
                                color: "#8B008B",
                                fontSize: 0.7 + "rem",
                            },
                            className: "fas fa-plus",
                            onClick: (_arg1_1) => {
                                updateState({
                                    menuOpen: true,
                                });
                            },
                        })]]), MuiHelpers_createElement(Menu, [["open", patternInput_1[0].menuOpen], ["onClose", (_arg16, v) => {
                            updateState({
                                menuOpen: false,
                            });
                        }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray([map((pr_3) => MuiHelpers_createElement(MenuItem, [["children", pr_3.Role.title], ["onClick", (_arg3_1) => {
                            updateState({
                                menuOpen: false,
                            });
                            const r = pr_3;
                            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const requestPath = toText(printf("/api/project-plan/responsible/%i/%i"))(projectId)(x.Id);
                                const req = new ProjectPlan_Responsible(-1, r.Role.code, r.Role.title);
                                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                    const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                        ResolveType: ProjectPlan_Responsible$reflection,
                                    })(req);
                                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                        ResolveType: ProjectPlan_Responsible$reflection,
                                    });
                                    return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                        ResolveType: ProjectPlan_Responsible$reflection,
                                    }, {
                                        ResolveType: () => obj_type,
                                    });
                                })).then(((_arg2) => {
                                    const res = _arg2;
                                    if (res.tag === 1) {
                                        return Promise.resolve();
                                    }
                                    else {
                                        setResponsible(append([res.fields[0]], responsible));
                                        return Promise.resolve();
                                    }
                                }));
                            }));
                            pr.then();
                        }]]), projectRoles.filter((pr_2) => (!responsible.some((r_2) => (r_2.Code === pr_2.Role.code)))))])]])]),
                    })) : empty_1(), delay(() => singleton(createElement("div", {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: 68,
                        },
                        children: Interop_reactApi.Children.toArray([map((r_3) => MuiHelpers_createElement(Tooltip, [["title", toText(printf("Ansvarlig: %s"))(r_3.Role)], ["children", createElement("div", {
                            style: {
                                fontSize: 0.7 + "rem",
                                padding: ((3 + "px ") + 5) + "px",
                                borderRadius: 8,
                                margin: 2,
                                backgroundColor: "#8B008B",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                                display: "flex",
                                alignItems: "center",
                                height: 17,
                            },
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("span", {
                                style: {
                                    userSelect: "none",
                                },
                                children: r_3.Code,
                            })), delay(() => (canEdit ? singleton(createElement("span", {
                                style: {
                                    marginLeft: 5,
                                    cursor: "pointer",
                                },
                                className: "fas fa-times",
                                onClick: (_arg4) => {
                                    const rId = r_3.Id | 0;
                                    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                        const requestPath_2 = toText(printf("/api/project-plan/responsible/%i/%i"))(projectId)(rId);
                                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                            const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                                ResolveType: () => string_type,
                                            })("");
                                            const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                                                ResolveType: Helpers_ResultMessage$reflection,
                                            });
                                            return Fetch_tryDelete_5760677E(requestPath_2, some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                                                ResolveType: Helpers_ResultMessage$reflection,
                                            }, {
                                                ResolveType: () => obj_type,
                                            });
                                        })).then(((_arg3) => {
                                            const res_1 = _arg3;
                                            if (res_1.tag === 0) {
                                                if (res_1.fields[0].Result === "success") {
                                                    setResponsible(responsible.filter((r$0027) => (r$0027.Id !== rId)));
                                                    return Promise.resolve();
                                                }
                                                else {
                                                    return Promise.resolve();
                                                }
                                            }
                                            else {
                                                return Promise.resolve();
                                            }
                                        }));
                                    }));
                                    pr_1.then();
                                },
                            })) : empty_1()))))))),
                        })]]), responsible)]),
                    })))))))),
                })) : empty_1(), delay(() => append_1(singleton(Document_categoryTag(x.Category)), delay(() => append_1(singleton(PageElements_filenameToIcon(x.Filename)), delay(() => ((filenameTooltip != null) ? singleton(MuiHelpers_createElement(Tooltip, [["title", filenameTooltip], ["children", createElement("a", {
                    href: x.DriveUrl,
                    target: "_blank",
                    style: {
                        fontSize: 0.8 + "rem",
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                    },
                    children: filename,
                })]])) : singleton(createElement("a", {
                    href: x.DriveUrl,
                    target: "_blank",
                    style: {
                        fontSize: 0.8 + "rem",
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                    },
                    children: filename,
                }))))))))))))),
            })]),
        })]),
    });
}

export function DrawActivity(drawActivityInputProps) {
    const showResponsible = drawActivityInputProps.showResponsible;
    const showSupport = drawActivityInputProps.showSupport;
    const showHelper = drawActivityInputProps.showHelper;
    const showWork = drawActivityInputProps.showWork;
    const selectedRoles = drawActivityInputProps.selectedRoles;
    const canEdit = drawActivityInputProps.canEdit;
    const projectRoles = drawActivityInputProps.projectRoles;
    const projectId = drawActivityInputProps.projectId;
    const x = drawActivityInputProps.x;
    const patternInput = useFeliz_React__React_useState_Static_1505({
        commentForm: false,
        comments: x.Comments,
        deadline: x.Deadline,
        deadlineForm: false,
        docs: x.Docs,
        documentForm: false,
        linkForm: false,
        links: x.Links,
        responsible: x.Responsible,
        responsibleForm: false,
        state: x.State,
    });
    const updateState = patternInput[1];
    const state_1 = patternInput[0];
    const trySetState = (newState) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let item;
            const requestPath = toText(printf("/api/project-plan/state/%i/%i"))(projectId)(x.Id);
            return ((item = {
                State: newState,
            }, PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: () => anonRecord_type(["State", int32_type]),
                })(item);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })))).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 1) {
                    return Promise.resolve();
                }
                else if (res.fields[0].Result === "success") {
                    updateState({
                        commentForm: state_1.commentForm,
                        comments: state_1.comments,
                        deadline: state_1.deadline,
                        deadlineForm: state_1.deadlineForm,
                        docs: state_1.docs,
                        documentForm: state_1.documentForm,
                        linkForm: state_1.linkForm,
                        links: state_1.links,
                        responsible: state_1.responsible,
                        responsibleForm: state_1.responsibleForm,
                        state: newState,
                    });
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            }));
        }));
        pr.then();
    };
    return createElement("div", {
        style: createObj(toList(delay(() => append_1(singleton(["display", "flex"]), delay(() => append_1(singleton(["minHeight", 70]), delay(() => {
            let matchValue_4, d_2;
            return (state_1.state === 3) ? singleton(["backgroundColor", "rgb(201, 255, 194, 0.3)"]) : (((matchValue_4 = state_1.deadline, (matchValue_4 != null) ? ((d_2 = matchValue_4, (() => {
                let copyOfStruct_1;
                try {
                    return compare(date(parse(d_2)), (copyOfStruct_1 = now(), date(copyOfStruct_1))) < 0;
                }
                catch (matchValue_5) {
                    return false;
                }
            })())) : false)) ? singleton(["backgroundColor", "rgba(247,183,187,0.2)"]) : ((state_1.state === 2) ? singleton(["backgroundColor", "rgb(223, 245, 244, 0.3)"]) : empty_1()));
        }))))))),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(state_1.commentForm ? singleton(createElement(CommentForm, {
            projectId: projectId,
            activityId: x.Id,
            onClose: (comment) => {
                updateState({
                    commentForm: false,
                    comments: (comment != null) ? append([comment], state_1.comments) : state_1.comments,
                    deadline: state_1.deadline,
                    deadlineForm: state_1.deadlineForm,
                    docs: state_1.docs,
                    documentForm: state_1.documentForm,
                    linkForm: state_1.linkForm,
                    links: state_1.links,
                    responsible: state_1.responsible,
                    responsibleForm: state_1.responsibleForm,
                    state: state_1.state,
                });
            },
        })) : empty_1(), delay(() => append_1(state_1.linkForm ? singleton(createElement(LinkForm, {
            projectId: projectId,
            activityId: x.Id,
            onClose: (link) => {
                updateState({
                    commentForm: state_1.commentForm,
                    comments: state_1.comments,
                    deadline: state_1.deadline,
                    deadlineForm: state_1.deadlineForm,
                    docs: state_1.docs,
                    documentForm: state_1.documentForm,
                    linkForm: false,
                    links: (link != null) ? append([link], state_1.links) : state_1.links,
                    responsible: state_1.responsible,
                    responsibleForm: state_1.responsibleForm,
                    state: state_1.state,
                });
            },
        })) : empty_1(), delay(() => append_1(state_1.deadlineForm ? singleton(createElement(DeadlineForm, {
            projectId: projectId,
            activityId: x.Id,
            x: state_1.deadline,
            onClose: (deadline_1) => {
                updateState({
                    commentForm: state_1.commentForm,
                    comments: state_1.comments,
                    deadline: deadline_1,
                    deadlineForm: false,
                    docs: state_1.docs,
                    documentForm: state_1.documentForm,
                    linkForm: state_1.linkForm,
                    links: state_1.links,
                    responsible: state_1.responsible,
                    responsibleForm: state_1.responsibleForm,
                    state: state_1.state,
                });
            },
        })) : empty_1(), delay(() => {
            let defaultRowPos;
            return append_1(state_1.documentForm ? ((defaultRowPos = (((state_1.docs.length === 0) ? 1 : (maxBy((y) => y.RowPos, state_1.docs, {
                Compare: (x_3, y_1) => comparePrimitives(x_3, y_1),
            }).RowPos + 1)) | 0), singleton(createElement(Document_AddForm, {
                target: new Document_AddFormTarget(1, projectId),
                updating: void 0,
                activityId: x.Id,
                defaultRowPos: defaultRowPos,
                onClose: (doc) => {
                    updateState({
                        commentForm: state_1.commentForm,
                        comments: state_1.comments,
                        deadline: state_1.deadline,
                        deadlineForm: state_1.deadlineForm,
                        docs: (doc != null) ? append([doc], state_1.docs) : state_1.docs,
                        documentForm: false,
                        linkForm: state_1.linkForm,
                        links: state_1.links,
                        responsible: state_1.responsible,
                        responsibleForm: state_1.responsibleForm,
                        state: state_1.state,
                    });
                },
            })))) : empty_1(), delay(() => append_1(state_1.responsibleForm ? singleton(createElement(ActivityResponsibleForm, {
                projectId: projectId,
                activityId: x.Id,
                onClose: (r_2) => {
                    if (r_2 != null) {
                        updateState({
                            commentForm: state_1.commentForm,
                            comments: state_1.comments,
                            deadline: state_1.deadline,
                            deadlineForm: state_1.deadlineForm,
                            docs: state_1.docs,
                            documentForm: state_1.documentForm,
                            linkForm: state_1.linkForm,
                            links: state_1.links,
                            responsible: append(state_1.responsible, [r_2]),
                            responsibleForm: false,
                            state: state_1.state,
                        });
                    }
                    else {
                        updateState({
                            commentForm: state_1.commentForm,
                            comments: state_1.comments,
                            deadline: state_1.deadline,
                            deadlineForm: state_1.deadlineForm,
                            docs: state_1.docs,
                            documentForm: state_1.documentForm,
                            linkForm: state_1.linkForm,
                            links: state_1.links,
                            responsible: state_1.responsible,
                            responsibleForm: false,
                            state: state_1.state,
                        });
                    }
                },
            })) : empty_1(), delay(() => append_1(singleton(createElement("div", {
                style: {
                    display: "flex",
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        padding: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 25,
                        backgroundColor: "#fafafa",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        className: "fas fa-level-up-alt fa-rotate-90",
                    })]),
                }), createElement("div", {
                    style: {
                        borderLeft: (((3 + "px ") + "solid") + " ") + "#87CEEB",
                        borderBottom: (((2 + "px ") + "solid") + " ") + "#ddd",
                        borderTop: (((2 + "px ") + "solid") + " ") + "#ddd",
                        minWidth: 250,
                        maxWidth: 500,
                        width: 360,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        style: {
                            padding: 3,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexGrow: 1,
                        },
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            style: {
                                fontSize: 1 + "rem",
                                fontWeight: "bold",
                            },
                            children: (x.Prefix.length > 0) ? toText(printf("%s - %s"))(x.Prefix)(x.Name) : x.Name,
                        })]),
                    }), createElement("div", {
                        style: {
                            padding: 3,
                        },
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: x.Desc,
                        })]),
                    }), createElement("div", {
                        style: {
                            padding: 3,
                            paddingTop: 5,
                            display: "flex",
                            flexDirection: "column",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let copyOfStruct, matchValue_2;
                            let beyondDeadline;
                            if (state_1.state === 3) {
                                beyondDeadline = false;
                            }
                            else {
                                const matchValue = state_1.deadline;
                                if (matchValue != null) {
                                    const d = matchValue;
                                    try {
                                        beyondDeadline = (compare(date(parse(d)), (copyOfStruct = now(), date(copyOfStruct))) < 0);
                                    }
                                    catch (matchValue_1) {
                                        beyondDeadline = false;
                                    }
                                }
                                else {
                                    beyondDeadline = false;
                                }
                            }
                            return append_1(singleton(createElement("span", {
                                style: createObj(toList(delay(() => append_1(singleton(["fontSize", 0.8 + "rem"]), delay(() => append_1(singleton(["fontWeight", "bold"]), delay(() => (beyondDeadline ? singleton(["color", "#FF0000"]) : empty_1())))))))),
                                children: (matchValue_2 = (state_1.state | 0), (matchValue_2 === 2) ? (beyondDeadline ? "På overtid" : "Under arbeid") : ((matchValue_2 === 3) ? "Gjennomført" : "Ikke påbegynt")),
                            })), delay(() => {
                                let matchValue_3, d_1;
                                return append_1((matchValue_3 = state_1.deadline, (matchValue_3 != null) ? ((d_1 = matchValue_3, singleton(createElement("span", {
                                    style: {
                                        fontSize: 0.8 + "rem",
                                        fontWeight: "bold",
                                    },
                                    children: Interop_reactApi.Children.toArray([toText(printf("Frist: ")), createElement("span", {
                                        style: createObj(toList(delay(() => (beyondDeadline ? singleton(["color", "#FF0000"]) : empty_1())))),
                                        children: fromUtcToLocalDate(d_1),
                                    })]),
                                })))) : ((empty_1()))), delay(() => ((state_1.responsible.length > 0) ? append_1(singleton(createElement("span", {
                                    style: {
                                        fontSize: 0.8 + "rem",
                                        fontWeight: "bold",
                                    },
                                    children: (state_1.responsible.length === 1) ? "Ansvarlig" : "Ansvarlige",
                                })), delay(() => singleton(map((r_1) => createElement("div", {
                                    style: {
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 0.9 + "rem",
                                        padding: ((5 + "px ") + 0) + "px",
                                    },
                                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(Avatar, [["alt", "Profilbilde"], ["src", toText(printf("/api/user/profilepicture/%i"))(r_1.UserId)], ["width", 30], ["height", 30], ["style", {
                                        width: 30,
                                        height: 30,
                                    }]])), delay(() => append_1(singleton(createElement("span", {
                                        style: {
                                            margin: ((0 + "px ") + 5) + "px",
                                            fontSize: 0.8 + "rem",
                                        },
                                        children: r_1.Fullname,
                                    })), delay(() => (canEdit ? singleton(MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Remove, {})], ["onClick", (_arg1_1) => {
                                        const responsibleId = r_1.Id | 0;
                                        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                            const requestPath_2 = toText(printf("/api/project-plan/activity-responsible/%i/%i"))(projectId)(responsibleId);
                                            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                                const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                                    ResolveType: () => string_type,
                                                })("");
                                                const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                                                    ResolveType: Helpers_ResultMessage$reflection,
                                                });
                                                return Fetch_tryDelete_5760677E(requestPath_2, some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                                                    ResolveType: Helpers_ResultMessage$reflection,
                                                }, {
                                                    ResolveType: () => obj_type,
                                                });
                                            })).then(((_arg2) => {
                                                const res_1 = _arg2;
                                                if (res_1.tag === 0) {
                                                    if (res_1.fields[0].Result === "success") {
                                                        updateState({
                                                            commentForm: state_1.commentForm,
                                                            comments: state_1.comments,
                                                            deadline: state_1.deadline,
                                                            deadlineForm: state_1.deadlineForm,
                                                            docs: state_1.docs,
                                                            documentForm: state_1.documentForm,
                                                            linkForm: state_1.linkForm,
                                                            links: state_1.links,
                                                            responsible: state_1.responsible.filter((r) => (r.Id !== responsibleId)),
                                                            responsibleForm: state_1.responsibleForm,
                                                            state: state_1.state,
                                                        });
                                                        return Promise.resolve();
                                                    }
                                                    else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                                else {
                                                    return Promise.resolve();
                                                }
                                            }));
                                        }));
                                        pr_1.then();
                                    }]])) : empty_1()))))))))),
                                }), state_1.responsible)))) : empty_1())));
                            }));
                        })))),
                    })]),
                })]),
            })), delay(() => singleton(createElement("div", {
                style: {
                    display: "flex",
                    padding: 3,
                    border: (((2 + "px ") + "solid") + " ") + "#ddd",
                    flexGrow: 1,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        display: "flex",
                        flexGrow: 1,
                        maxWidth: 320,
                        minWidth: 250,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: 100 + "%",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let arg10_4;
                            return append_1(singleton(createElement("span", {
                                style: {
                                    fontSize: 0.8 + "rem",
                                    fontStyle: "italic",
                                    color: "#9a9a9a",
                                },
                                children: (arg10_4 = (state_1.comments.length | 0), toText(printf("Kommentarer (%i)"))(arg10_4)),
                            })), delay(() => append_1((state_1.comments.length > 0) ? singleton(createElement("div", {
                                style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    maxWidth: 310,
                                    margin: 5,
                                    maxHeight: 160,
                                    paddingRight: 10,
                                    overflowY: "auto",
                                },
                                children: Interop_reactApi.Children.toArray([map((c_2) => createElement("div", {
                                    style: createObj(toList(delay(() => append_1(singleton(["display", "flex"]), delay(() => append_1(singleton(["flexDirection", "column"]), delay(() => ((c_2.UserId === (loggedInUserId)) ? singleton(["alignItems", "flex-end"]) : empty_1())))))))),
                                    children: Interop_reactApi.Children.toArray([createElement("div", {
                                        style: {
                                            display: "flex",
                                            alignItems: "center",
                                        },
                                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((c_2.UserId > 0) ? append_1(singleton(MuiHelpers_createElement(Avatar, [["alt", toText(printf("Profilbilde %s"))(c_2.User)], ["src", toText(printf("/api/user/profilepicture/%i"))(c_2.UserId)], ["style", {
                                            width: 25,
                                            height: 25,
                                            marginRight: 5,
                                        }]])), delay(() => singleton(createElement("a", {
                                            href: toText(printf("/user/%i"))(c_2.UserId),
                                            target: "_blank",
                                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                                style: {
                                                    color: "#757575",
                                                },
                                                children: c_2.User,
                                            })]),
                                        })))) : singleton(createElement("span", {
                                            style: {
                                                color: "#757575",
                                            },
                                            children: c_2.User,
                                        }))))))),
                                    }), createElement("p", {
                                        style: {
                                            fontSize: 0.8 + "rem",
                                        },
                                        children: c_2.Comment,
                                    })]),
                                }), sortBy((c_1) => c_1.Id, state_1.comments, {
                                    Compare: (x_5, y_2) => comparePrimitives(x_5, y_2),
                                }))]),
                            })) : empty_1(), delay(() => singleton(createElement("div", {
                                style: {
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    paddingRight: 10,
                                },
                                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", "Legg til kommentar"], ["placement", "left"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", Interop_reactApi.Children.toArray([react.createElement(InsertComment, {})])], ["onClick", (_arg2_1) => {
                                    updateState({
                                        commentForm: true,
                                        comments: state_1.comments,
                                        deadline: state_1.deadline,
                                        deadlineForm: state_1.deadlineForm,
                                        docs: state_1.docs,
                                        documentForm: state_1.documentForm,
                                        linkForm: state_1.linkForm,
                                        links: state_1.links,
                                        responsible: state_1.responsible,
                                        responsibleForm: state_1.responsibleForm,
                                        state: state_1.state,
                                    });
                                }]])]])]),
                            }))))));
                        })))),
                    })]),
                }), createElement("div", {
                    style: {
                        display: "flex",
                        flexGrow: 1,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        style: {
                            display: "flex",
                            flexWrap: "wrap",
                            flexGrow: 1,
                            justifyContent: "flex-end",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("div", {
                            style: {
                                margin: 5,
                            },
                            children: Interop_reactApi.Children.toArray([map((d_6) => createElement(DrawDocument, {
                                x: d_6,
                                projectId: projectId,
                                projectRoles: projectRoles,
                                canEdit: canEdit,
                                showResponsible: showResponsible,
                            }), sortBy((d_5) => d_5.RowPos, state_1.docs.filter((d_4) => {
                                if ((((d_4.Category === 1) ? showWork : false) ? true : ((d_4.Category === 2) ? showHelper : false)) ? true : ((d_4.Category === 3) ? showSupport : false)) {
                                    if (selectedRoles.length === 0) {
                                        return true;
                                    }
                                    else {
                                        return d_4.Responsible.some((r_3) => selectedRoles.some((y_3) => (r_3.Code === y_3)));
                                    }
                                }
                                else {
                                    return false;
                                }
                            }), {
                                Compare: (x_7, y_4) => comparePrimitives(x_7, y_4),
                            }))]),
                        })), delay(() => ((state_1.links.length > 0) ? singleton(createElement("div", {
                            style: {
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 20,
                                maxWidth: 250,
                                margin: 5,
                            },
                            children: Interop_reactApi.Children.toArray([map((x_8) => {
                                let value_241;
                                return createElement("a", {
                                    href: x_8.Url,
                                    target: "_blank",
                                    children: Interop_reactApi.Children.toArray([createElement("span", {
                                        style: {
                                            marginRight: 5,
                                        },
                                        className: "fas fa-link",
                                    }), (value_241 = ((x_8.Name.length > 0) ? x_8.Name : x_8.Url), createElement("span", {
                                        children: [value_241],
                                    }))]),
                                });
                            }, state_1.links)]),
                        })) : empty_1()))))))),
                    }), createElement("div", {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 20,
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(Tooltip, [["title", "Legg til lenke"], ["placement", "left"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", Interop_reactApi.Children.toArray([react.createElement(Link, {})])], ["onClick", (_arg3) => {
                            updateState({
                                commentForm: state_1.commentForm,
                                comments: state_1.comments,
                                deadline: state_1.deadline,
                                deadlineForm: state_1.deadlineForm,
                                docs: state_1.docs,
                                documentForm: state_1.documentForm,
                                linkForm: true,
                                links: state_1.links,
                                responsible: state_1.responsible,
                                responsibleForm: state_1.responsibleForm,
                                state: state_1.state,
                            });
                        }]])]])), delay(() => append_1(singleton(MuiHelpers_createElement(Tooltip, [["title", "Legg til dokument"], ["placement", "left"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", createElement("span", {
                            className: "fas fa-file-upload",
                        })], ["onClick", (_arg4) => {
                            updateState({
                                commentForm: state_1.commentForm,
                                comments: state_1.comments,
                                deadline: state_1.deadline,
                                deadlineForm: state_1.deadlineForm,
                                docs: state_1.docs,
                                documentForm: true,
                                linkForm: state_1.linkForm,
                                links: state_1.links,
                                responsible: state_1.responsible,
                                responsibleForm: state_1.responsibleForm,
                                state: state_1.state,
                            });
                        }]])]])), delay(() => append_1(singleton(MuiHelpers_createElement(Tooltip, [["title", "Sett frist"], ["placement", "left"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", createElement("span", {
                            className: "fas fa-hourglass-half",
                        })], ["onClick", (_arg5) => {
                            updateState({
                                commentForm: state_1.commentForm,
                                comments: state_1.comments,
                                deadline: state_1.deadline,
                                deadlineForm: true,
                                docs: state_1.docs,
                                documentForm: state_1.documentForm,
                                linkForm: state_1.linkForm,
                                links: state_1.links,
                                responsible: state_1.responsible,
                                responsibleForm: state_1.responsibleForm,
                                state: state_1.state,
                            });
                        }]])]])), delay(() => append_1(singleton(MuiHelpers_createElement(Tooltip, [["title", "Sett ansvarlig"], ["placement", "left"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["children", createElement("span", {
                            className: "fas fa-user",
                        })], ["onClick", (_arg6) => {
                            updateState({
                                commentForm: state_1.commentForm,
                                comments: state_1.comments,
                                deadline: state_1.deadline,
                                deadlineForm: state_1.deadlineForm,
                                docs: state_1.docs,
                                documentForm: state_1.documentForm,
                                linkForm: state_1.linkForm,
                                links: state_1.links,
                                responsible: state_1.responsible,
                                responsibleForm: true,
                                state: state_1.state,
                            });
                        }]])]])), delay(() => ((state_1.state !== 3) ? singleton(MuiHelpers_createElement(Tooltip, [["title", (state_1.state === 2) ? "Sett gjennomført" : "Sett påbegynt"], ["placement", "left"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg7) => {
                            if (state_1.state === 2) {
                                trySetState(3);
                            }
                            else {
                                trySetState(2);
                            }
                        }], ["children", createElement("span", {
                            className: (state_1.state === 2) ? "fas fa-check-circle" : "fas fa-play-circle",
                        })]])]])) : empty_1()))))))))))))),
                    })]),
                })]),
            }))))))));
        })))))))))),
    });
}

function DrawProcess(drawProcessInputProps) {
    const showResponsible = drawProcessInputProps.showResponsible;
    const showSupport = drawProcessInputProps.showSupport;
    const showHelper = drawProcessInputProps.showHelper;
    const showWork = drawProcessInputProps.showWork;
    const selectedRoles = drawProcessInputProps.selectedRoles;
    const canEdit = drawProcessInputProps.canEdit;
    const projectRoles = drawProcessInputProps.projectRoles;
    const projectId = drawProcessInputProps.projectId;
    const x = drawProcessInputProps.x;
    const allCompleted = (!x.Activities.some((y) => (y.State !== 3))) ? (x.Children.length === 0) : false;
    const patternInput = useFeliz_React__React_useState_Static_1505(allCompleted);
    const collapsed = patternInput[0];
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("div", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1((x.ParentId != null) ? singleton(createElement("div", {
                style: {
                    padding: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 25,
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    className: "fas fa-level-up-alt fa-rotate-90",
                })]),
            })) : empty_1(), delay(() => singleton(createElement("div", {
                style: {
                    backgroundColor: "#f1f1f1",
                    color: "#000000",
                    border: (((2 + "px ") + "solid") + " ") + "#ddd",
                    borderLeft: (((4 + "px ") + "solid") + " ") + "#1E90FF",
                    display: "flex",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    padding: 5,
                    flexDirection: "column",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "space-between",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("div", {
                        style: {},
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(createElement("span", {
                            style: {
                                fontSize: 1.2 + "rem",
                                fontWeight: "bold",
                            },
                            children: (x.Prefix.length > 0) ? toText(printf("%s - %s"))(x.Prefix)(x.Name) : x.Name,
                        })), delay(() => (allCompleted ? singleton(createElement("span", {
                            className: "fas fa-check",
                            style: {
                                color: "#008000",
                                marginLeft: 10,
                                fontSize: 1.2 + "rem",
                            },
                        })) : empty_1()))))))),
                    })), delay(() => (((x.Children.length > 0) ? true : (x.Activities.length > 0)) ? singleton(MuiHelpers_createElement(Tooltip, [["title", collapsed ? "Vis prosess" : "Skjul prosess"], ["placement", "left"], ["children", MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg1) => {
                        patternInput[1](!collapsed);
                    }], ["children", collapsed ? react.createElement(Remove, {}) : react.createElement(ExpandMore, {})]])]])) : empty_1()))))))),
                })), delay(() => (((x.Desc.length > 0) ? (!collapsed) : false) ? singleton(createElement("div", {
                    style: {},
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            display: "block",
                            maxWidth: 600,
                        },
                        children: x.Desc,
                    })]),
                })) : empty_1()))))))),
            })))))))),
        })), delay(() => ((!collapsed) ? singleton(createElement("div", {
            style: createObj(toList(delay(() => append_1(singleton(["display", "flex"]), delay(() => append_1(singleton(["flexDirection", "column"]), delay(() => ((x.ParentId != null) ? singleton(["marginLeft", 25]) : empty_1())))))))),
            children: Interop_reactApi.Children.toArray([map((a_1) => createElement("div", {
                key: a_1.Id,
                children: Interop_reactApi.Children.toArray([createElement(DrawActivity, {
                    x: a_1,
                    projectId: projectId,
                    projectRoles: projectRoles,
                    canEdit: canEdit,
                    selectedRoles: selectedRoles,
                    showWork: showWork,
                    showHelper: showHelper,
                    showSupport: showSupport,
                    showResponsible: showResponsible,
                })]),
            }), sortBy((a) => a.RowPos, x.Activities, {
                Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
            })), map((c_1) => createElement("div", {
                key: c_1.Id,
                children: Interop_reactApi.Children.toArray([createElement(DrawProcess, {
                    x: c_1,
                    projectId: projectId,
                    projectRoles: projectRoles,
                    canEdit: canEdit,
                    selectedRoles: selectedRoles,
                    showWork: showWork,
                    showHelper: showHelper,
                    showSupport: showSupport,
                    showResponsible: showResponsible,
                })]),
            }), sortBy((c) => c.RowPos, x.Children, {
                Compare: (x_2, y_2) => comparePrimitives(x_2, y_2),
            }))]),
        })) : empty_1()))))))),
    });
}

function view(model, dispatch) {
    const roleAnchor = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setRoleMenuOpen = patternInput[1];
    const roleMenuOpen = patternInput[0];
    const categoryAnchor = useReact_useRef_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setCategoryMenuOpen = patternInput_1[1];
    const categoryMenuOpen = patternInput_1[0];
    const filterActive = ((((!model.ShowResponsible) ? true : (!model.ShowWork)) ? true : (!model.ShowHelper)) ? true : (!model.ShowSupport)) ? true : (model.SelectedRoles.length > 0);
    const numCategoriesActive = (((model.ShowWork ? 1 : 0) + (model.ShowHelper ? 1 : 0)) + (model.ShowSupport ? 1 : 0)) | 0;
    return createElement("div", {
        style: {
            padding: 1 + "vw",
            overflowX: "auto",
            maxWidth: 100 + "vw",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(13));
        })), delay(() => append_1(filterActive ? singleton(MuiHelpers_createElement(Snackbar, [["open", filterActive], ["autoHideDuration", 20000], ["anchorOrigin", {
            vertical: "bottom",
            horizontal: "right",
        }], ["children", MuiHelpers_createElement(Alert, [["style", {
            backgroundColor: "#696969",
        }], ["variant", "filled"], ["children", "Filter aktivt. Deler av kvalitetsplanen kan være skjult."]])]])) : empty_1(), delay(() => {
            let arg10, matchValue, projectRoles;
            return append_1(singleton(createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        display: "flex",
                    },
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["style", {
                        minWidth: 225,
                        textTransform: "none",
                    }], ["size", "small"], ["ref", roleAnchor], ["onClick", (_arg2) => {
                        setRoleMenuOpen(!roleMenuOpen);
                    }], ["children", (model.SelectedRoles.length > 0) ? ((arg10 = (model.SelectedRoles.length | 0), toText(printf("Filtrer ansvarlig rolle (%i)"))(arg10))) : "Filtrer ansvarlig rolle"]]), MuiHelpers_createElement(Popper, [["anchorEl", () => roleAnchor.current], ["placement", "bottom-end"], ["open", roleMenuOpen], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ClickAwayListener, [["onClickAway", (_arg3) => {
                        setRoleMenuOpen(false);
                    }], ["children", (matchValue = model.ProjectRoles, (matchValue != null) ? ((projectRoles = matchValue, createElement("div", {
                        style: {
                            padding: 10,
                            display: "flex",
                            flexDirection: "column",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1((projectRoles.length === 0) ? singleton(createElement("span", {
                            style: {
                                fontSize: 0.8 + "rem",
                                fontStyle: "italic",
                            },
                            children: "Ingen prosjektroller",
                        })) : singleton(MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["style", {
                            color: "#8B008B",
                        }], ["checked", projectRoles.length === model.SelectedRoles.length], ["onChange", (e) => {
                            if (Browser_Types_Event__Event_get_Checked(e)) {
                                dispatch(new Message(9));
                            }
                            else {
                                dispatch(new Message(10));
                            }
                        }], ["color", "primary"]])], ["label", "Marker alle"]])), delay(() => singleton(map((pr) => MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["style", {
                            color: "#8B008B",
                        }], ["checked", model.SelectedRoles.some((r_1) => (r_1.Role.code === pr.Role.code))], ["onChange", (e_1) => {
                            if (Browser_Types_Event__Event_get_Checked(e_1)) {
                                dispatch(new Message(7, pr.Id));
                            }
                            else {
                                dispatch(new Message(8, pr.Id));
                            }
                        }], ["color", "primary"]])], ["label", pr.Role.title]]), projectRoles)))))))),
                    }))) : loadingIndicatorSmall())]])])]])])]]), MuiHelpers_createElement(Button, [["style", {
                        minWidth: 225,
                        textTransform: "none",
                    }], ["size", "small"], ["ref", categoryAnchor], ["onClick", (_arg4) => {
                        setCategoryMenuOpen(!categoryMenuOpen);
                    }], ["children", toText(printf("Filtrer dokumentkategori (%i)"))(numCategoriesActive)]]), MuiHelpers_createElement(Popper, [["anchorEl", () => categoryAnchor.current], ["placement", "bottom-end"], ["open", categoryMenuOpen], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ClickAwayListener, [["onClickAway", (_arg5) => {
                        setCategoryMenuOpen(false);
                    }], ["children", createElement("div", {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            padding: 10,
                        },
                        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["style", {
                            color: "gra",
                        }], ["checked", (model.ShowWork ? model.ShowHelper : false) ? model.ShowSupport : false], ["onChange", (e_2) => {
                            if (Browser_Types_Event__Event_get_Checked(e_2)) {
                                dispatch(new Message(11));
                            }
                            else {
                                dispatch(new Message(12));
                            }
                        }], ["color", "primary"]])], ["label", "Marker alle"]]), MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["style", {
                            color: "#4682B4",
                        }], ["checked", model.ShowWork], ["onChange", (e_3) => {
                            dispatch(new Message(3, Browser_Types_Event__Event_get_Checked(e_3)));
                        }], ["color", "primary"]])], ["label", "Vis arbeidsdokument"]]), MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["style", {
                            color: "#6A5ACD",
                        }], ["checked", model.ShowHelper], ["onChange", (e_4) => {
                            dispatch(new Message(4, Browser_Types_Event__Event_get_Checked(e_4)));
                        }], ["color", "primary"]])], ["label", "Vis veilederdokument"]]), MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["style", {
                            color: "#191970",
                        }], ["checked", model.ShowSupport], ["onChange", (e_5) => {
                            dispatch(new Message(5, Browser_Types_Event__Event_get_Checked(e_5)));
                        }], ["color", "primary"]])], ["label", "Vis støttedokument"]])]),
                    })]])])]])])]])]),
                }), createElement("div", {
                    style: {
                        display: "flex",
                        flexWrap: "wrap",
                    },
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["style", {
                        color: "#8B008B",
                    }], ["checked", model.ShowResponsible], ["onChange", (e_6) => {
                        dispatch(new Message(6, Browser_Types_Event__Event_get_Checked(e_6)));
                    }], ["color", "primary"]])], ["label", "Vis ansvarlig rolle"]])]),
                })]),
            })), delay(() => {
                const matchValue_1 = model.Plan;
                if (matchValue_1 != null) {
                    const plan = matchValue_1;
                    let pr_2;
                    const matchValue_2 = model.ProjectRoles;
                    pr_2 = ((matchValue_2 == null) ? [] : matchValue_2);
                    const selected_1 = map((r_3) => r_3.Role.code, model.SelectedRoles);
                    return singleton(map((p) => createElement(DrawProcess, {
                        x: p,
                        projectId: model.ProjectId,
                        projectRoles: pr_2,
                        canEdit: model.CanEdit,
                        selectedRoles: selected_1,
                        showWork: model.ShowWork,
                        showHelper: model.ShowHelper,
                        showSupport: model.ShowSupport,
                        showResponsible: model.ShowResponsible,
                    }), sortBy((x_1) => x_1.RowPos, plan, {
                        Compare: (x_2, y) => comparePrimitives(x_2, y),
                    })));
                }
                else {
                    return singleton(loadingIndicatorMedium());
                }
            }));
        })))))))),
    });
}

export function ProjectPlan(projectPlanInputProps) {
    const projectId = projectPlanInputProps.projectId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectId));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchProjectPlan(projectId, dispatch);
        Project_fetchLoggedInUserPermissions(projectId, (arg) => {
            dispatch(new Message(1, arg));
        });
        fetchProjectRoles(projectId, dispatch);
    });
    return view(patternInput[0], dispatch);
}

