import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, string_type, option_type, array_type, class_type, union_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { join, printf, interpolate, toText } from "../.fable/fable-library.3.2.4/String.js";
import { RegistrationStatus$reflection, Hms_PeriodSpread$reflection, Hms_MonthEntry$reflection, Hms_HmsType$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, append, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { stringHash, int32ToString, compareArrays, comparePrimitives, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { toString } from "../.fable/fable-library.3.2.4/Date.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import { snackbarError, Text_secondaryText } from "../ViewHelpers.js";
import { monthsInPeriod, toDateStringNO } from "../Utils.js";
import { append as append_1, empty as empty_1, singleton, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { sortByDescending, map, sortBy, mapIndexed } from "../.fable/fable-library.3.2.4/Array.js";
import { Colors_getColorWheel } from "../Design.js";
import Skeleton from "@material-ui/lab/Skeleton";
import { object } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Cell, Pie, PieChart, Bar, Legend, Tooltip, YAxis, XAxis, CartesianGrid, BarChart, ResponsiveContainer } from "recharts";
import Table from "@material-ui/core/Table";
import { Array_groupBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";

export class ReportLevel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Project", "Company", "Group"];
    }
}

export function ReportLevel$reflection() {
    return union_type("HmsReport.ReportLevel", [], ReportLevel, () => [[["Item", int32_type]], [["Item", int32_type]], []]);
}

export function ReportLevel__ToName(this$) {
    switch (this$.tag) {
        case 1: {
            return "selskapsnivå";
        }
        case 2: {
            return "konsernnivå";
        }
        default: {
            return "prosjektnivå";
        }
    }
}

export function ReportLevel__ToMonthlyUrl(this$) {
    switch (this$.tag) {
        case 1: {
            return toText(interpolate("/api/hms/report/montly/company/%P()", [this$.fields[0]]));
        }
        case 2: {
            return "/api/hms/report/montly/group";
        }
        default: {
            return toText(interpolate("/api/hms/report/monthly/project/%P()", [this$.fields[0]]));
        }
    }
}

export function ReportLevel__ToTypeSpreadUrl(this$) {
    switch (this$.tag) {
        case 1: {
            return toText(interpolate("/api/hms/report/type-spread/company/%P()", [this$.fields[0]]));
        }
        case 2: {
            return "/api/hms/report/type-spread/group";
        }
        default: {
            return toText(interpolate("/api/hms/report/type-spread/project/%P()", [this$.fields[0]]));
        }
    }
}

export function ReportLevel__ToStatusUrl(this$) {
    switch (this$.tag) {
        case 1: {
            return toText(interpolate("/api/hms/report/status/company/%P()", [this$.fields[0]]));
        }
        case 2: {
            return "/api/hms/report/status/group";
        }
        default: {
            return toText(interpolate("/api/hms/report/status/project/%P()", [this$.fields[0]]));
        }
    }
}

class Model extends Record {
    constructor(Level, PeriodStart, PeriodEnd, HmsTypes, Monthly, TypeSpread, Status, ErrorMsg) {
        super();
        this.Level = Level;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.HmsTypes = HmsTypes;
        this.Monthly = Monthly;
        this.TypeSpread = TypeSpread;
        this.Status = Status;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("HmsReport.Model", [], Model, () => [["Level", ReportLevel$reflection()], ["PeriodStart", class_type("System.DateTime")], ["PeriodEnd", class_type("System.DateTime")], ["HmsTypes", option_type(array_type(Hms_HmsType$reflection()))], ["Monthly", option_type(array_type(Hms_MonthEntry$reflection()))], ["TypeSpread", option_type(array_type(Hms_PeriodSpread$reflection()))], ["Status", option_type(array_type(RegistrationStatus$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TypesResponse", "MonthlyResponse", "TypeSpreadResponse", "StatusResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("HmsReport.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Hms_HmsType$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Hms_HmsType$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Hms_MonthEntry$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Hms_MonthEntry$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Hms_PeriodSpread$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Hms_PeriodSpread$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(RegistrationStatus$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(RegistrationStatus$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(x, y, z) {
    return new Model(x, y, z, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.HmsTypes, model.Monthly, model.TypeSpread, model.Status, "Noe gikk galt. Kunne ikke laste månedlig oppsummering.");
            }
            else {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.HmsTypes, res_1.fields[0], model.TypeSpread, model.Status, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.HmsTypes, model.Monthly, model.TypeSpread, model.Status, "Noe gikk galt. Kunne ikke laste HMS-type fordeling for perioden.");
            }
            else {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.HmsTypes, model.Monthly, res_2.fields[0], model.Status, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.HmsTypes, model.Monthly, model.TypeSpread, model.Status, "Noe gikk galt. Kunne ikke laste registreringsstatus.");
            }
            else {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.HmsTypes, model.Monthly, model.TypeSpread, res_3.fields[0], model.ErrorMsg);
            }
        }
        case 4: {
            return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.HmsTypes, model.Monthly, model.TypeSpread, model.Status, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, model.HmsTypes, model.Monthly, model.TypeSpread, model.Status, "Noe gikk galt. Kunne ikke laste HMS-typer.");
            }
            else {
                return new Model(model.Level, model.PeriodStart, model.PeriodEnd, res.fields[0], model.Monthly, model.TypeSpread, model.Status, model.ErrorMsg);
            }
        }
    }
}

function fetchTypes(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Hms_HmsType$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/hms/types", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Hms_HmsType$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchMonthlyRegistrations(x, s, e, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let periodStart;
        const arg10 = toString(s, "yyyy-MM-dd");
        periodStart = toText(printf("\u0026start=%s"))(arg10);
        let periodEnd;
        const arg10_1 = toString(e, "yyyy-MM-dd");
        periodEnd = toText(printf("\u0026end=%s"))(arg10_1);
        let requestPath;
        const arg10_2 = ReportLevel__ToMonthlyUrl(x);
        requestPath = toText(printf("%s?%s%s"))(arg10_2)(periodStart)(periodEnd);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Hms_MonthEntry$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Hms_MonthEntry$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchTypeSpread(x, s, e, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let periodStart;
        const arg10 = toString(s, "yyyy-MM-dd");
        periodStart = toText(printf("\u0026start=%s"))(arg10);
        let periodEnd;
        const arg10_1 = toString(e, "yyyy-MM-dd");
        periodEnd = toText(printf("\u0026end=%s"))(arg10_1);
        let requestPath;
        const arg10_2 = ReportLevel__ToTypeSpreadUrl(x);
        requestPath = toText(printf("%s?%s%s"))(arg10_2)(periodStart)(periodEnd);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Hms_PeriodSpread$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Hms_PeriodSpread$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchRegistrationStatus(x, s, e, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let periodStart;
        const arg10 = toString(s, "yyyy-MM-dd");
        periodStart = toText(printf("\u0026start=%s"))(arg10);
        let periodEnd;
        const arg10_1 = toString(e, "yyyy-MM-dd");
        periodEnd = toText(printf("\u0026end=%s"))(arg10_1);
        let requestPath;
        const arg10_2 = ReportLevel__ToStatusUrl(x);
        requestPath = toText(printf("%s?%s%s"))(arg10_2)(periodStart)(periodEnd);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(RegistrationStatus$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(RegistrationStatus$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function section(x) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            border: (((1 + "px ") + "solid") + " ") + "#dedede",
            borderRadius: 5,
            padding: 20,
        },
        children: Interop_reactApi.Children.toArray(Array.from(x)),
    });
}

function sectionTitle(x) {
    return MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", x]]);
}

function fromToText(s, e) {
    let arg20, arg10;
    return Text_secondaryText((arg20 = toDateStringNO(e), (arg10 = toDateStringNO(s), toText(printf("Fra %s til %s"))(arg10)(arg20))));
}

function drawSummary(model) {
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            gap: 10 + "px ",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = model.TypeSpread;
            return (matchValue != null) ? singleton(mapIndexed((i, x_2) => {
                const color = Colors_getColorWheel(i);
                return section([sectionTitle(x_2.Source), fromToText(model.PeriodStart, model.PeriodEnd), createElement("span", {
                    style: {
                        color: color,
                        fontSize: 2.2 + "rem",
                    },
                    children: x_2.Quantity,
                })]);
            }, sortBy((x) => x.SourceId, matchValue, {
                Compare: (x_1, y) => comparePrimitives(x_1, y),
            }))) : singleton(MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                width: 100 + "%",
                minHeight: 150,
            }]]));
        })))),
    });
}

function monthlyChart(model) {
    return createElement("div", {
        style: {
            maxWidth: 95 + "vw",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = [model.Monthly, model.HmsTypes];
            let pattern_matching_result, x, y;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    pattern_matching_result = 0;
                    x = matchValue[0];
                    y = matchValue[1];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const data = map((y_3) => {
                        const t = ofArray(map((z) => [z.Type.Name, z.Quantity], y_3.Entries));
                        return object(append(singleton_1(["month", toText(interpolate("%P()/%P()", [y_3.Month, y_3.Year]))]), t));
                    }, sortBy((y_1) => [y_1.Year, y_1.Month], x, {
                        Compare: (x_1, y_2) => compareArrays(x_1, y_2),
                    }));
                    return singleton(Interop_reactApi.createElement(ResponsiveContainer, {
                        minWidth: 50 + "vw",
                        minHeight: 300 + "px",
                        children: Interop_reactApi.createElement(BarChart, {
                            width: 800,
                            height: 300,
                            data: data,
                            children: ["children", Interop_reactApi.Children.toArray([Interop_reactApi.createElement(CartesianGrid, {
                                strokeDasharray: join(" ", map((value_17) => int32ToString(value_17), new Int32Array([3, 3]))),
                            }), Interop_reactApi.createElement(XAxis, {
                                dataKey: (x_2) => x_2.month,
                            }), Interop_reactApi.createElement(YAxis, {}), Interop_reactApi.createElement(Tooltip, {}), Interop_reactApi.createElement(Legend, {}), mapIndexed((i, z_2) => {
                                const fill = Colors_getColorWheel(i);
                                return Interop_reactApi.createElement(Bar, {
                                    dataKey: z_2.Name,
                                    fill: fill,
                                    name: z_2.Name,
                                });
                            }, sortBy((z_1) => z_1.Id, y, {
                                Compare: (x_3, y_4) => comparePrimitives(x_3, y_4),
                            }))])],
                        }),
                    }));
                }
                case 1: {
                    return singleton(MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                        width: 100 + "%",
                        minHeight: 300,
                    }]]));
                }
            }
        })))),
    });
}

class PieData extends Record {
    constructor(quantity, name, id) {
        super();
        this.quantity = (quantity | 0);
        this.name = name;
        this.id = (id | 0);
    }
}

function PieData$reflection() {
    return record_type("HmsReport.PieData", [], PieData, () => [["quantity", int32_type], ["name", string_type], ["id", int32_type]]);
}

function periodSpreadChart(data) {
    if (data != null) {
        const x = data;
        return Interop_reactApi.createElement(PieChart, {
            height: 200,
            width: 200,
            children: ["children", Interop_reactApi.Children.toArray([Interop_reactApi.createElement(Pie, {
                cx: 50 + "%",
                cy: 50 + "%",
                label: true,
                data: x,
                dataKey: "quantity",
                nameKey: "name",
                outerRadius: 80 + "%",
                innerRadius: 30 + "%",
                children: ["children", Interop_reactApi.Children.toArray([mapIndexed((i, _arg1) => {
                    const fill = Colors_getColorWheel(i);
                    return Interop_reactApi.createElement(Cell, {
                        key: int32ToString(i),
                        fill: fill,
                    });
                }, sortByDescending((y) => y.id, x, {
                    Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
                }))])],
            })])],
        });
    }
    else {
        return MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
            minHeight: 200,
            minWidth: 200,
        }]]);
    }
}

function typeSpread(data) {
    const pieData = (data != null) ? map((y_2) => (new PieData(y_2.Quantity, y_2.Source, y_2.SourceId)), sortBy((y) => y.SourceId, data, {
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    })) : (void 0);
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                fontSize: 0.7 + "rem",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let children_2;
                if (data != null) {
                    const d = data;
                    return singleton(MuiHelpers_createElement(Table, [["size", "small"], ["style", {
                        marginBottom: 0,
                    }], ["children", Interop_reactApi.Children.toArray([createElement("thead", {}), (children_2 = singleton_1(map((y_5) => {
                        const children = ofArray([createElement("th", {
                            children: [y_5.Source],
                        }), createElement("td", {
                            children: [y_5.Quantity],
                        })]);
                        return createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        });
                    }, sortBy((y_3) => y_3.SourceId, d, {
                        Compare: (x_2, y_4) => comparePrimitives(x_2, y_4),
                    }))), createElement("tbody", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    }))])]]));
                }
                else {
                    return empty_1();
                }
            })))),
        }), createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([periodSpreadChart(pieData)]),
        }), createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
                fontSize: 0.8 + "rem",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let children_4;
                if (data != null) {
                    return singleton((children_4 = singleton_1(mapIndexed((i, y_8) => {
                        const fill = Colors_getColorWheel(i);
                        return createElement("li", {
                            style: {
                                display: "flex",
                                alignItems: "center",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                style: {
                                    width: 10,
                                    height: 10,
                                    backgroundColor: fill,
                                    marginRight: 5,
                                },
                            }), y_8.Source]),
                        });
                    }, sortBy((y_6) => y_6.SourceId, data, {
                        Compare: (x_3, y_7) => comparePrimitives(x_3, y_7),
                    }))), createElement("ul", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })));
                }
                else {
                    return empty_1();
                }
            })))),
        })]),
    });
}

function spreads(model) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
            flexWrap: "wrap",
            alignItems: "center",
            minHeight: 200 + "px",
        },
        children: Interop_reactApi.Children.toArray([typeSpread(model.TypeSpread)]),
    });
}

function greenCheck() {
    return createElement("i", {
        style: {
            color: "green",
        },
        className: "fas fa-check",
    });
}

function redX() {
    return createElement("i", {
        style: {
            color: "red",
        },
        className: "fas fa-times",
    });
}

export function helperText(txt, isValid) {
    return createElement("span", {
        style: {
            fontSize: 0.7 + "rem",
            marginRight: 15,
            display: "block",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(isValid ? singleton(greenCheck()) : singleton(redX()), delay(() => singleton(createElement("span", {
            style: {
                marginLeft: 5,
            },
            children: txt,
        })))))))),
    });
}

function registrationStatus(status, periodStart, periodEnd) {
    const months = monthsInPeriod(periodStart, periodEnd);
    return createElement("div", {
        style: {
            flexGrow: 1,
            overflowX: "auto",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let children_2, children, children_6;
            if (status != null) {
                const grouped = map((tupledArg) => [tupledArg[0], sortByDescending((a) => [a.Year, a.Month], tupledArg[1], {
                    Compare: (x_2, y_3) => compareArrays(x_2, y_3),
                })], Array_groupBy((y) => y.Source, status, {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: (x_1) => stringHash(x_1),
                }));
                return singleton(MuiHelpers_createElement(Table, [["style", {
                    margin: 0,
                }], ["size", "small"], ["children", Interop_reactApi.Children.toArray([(children_2 = singleton_1((children = ofArray([createElement("td", {}), map((tupledArg_1) => createElement("td", {
                    style: {
                        textAlign: "center",
                    },
                    children: toText(interpolate("%P()/%P()", [tupledArg_1[1], tupledArg_1[0]])),
                }), months)]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), (children_6 = singleton_1(map((tupledArg_2) => {
                    const children_4 = ofArray([createElement("th", {
                        children: [tupledArg_2[0]],
                    }), map((z_1) => createElement("td", {
                        style: {
                            textAlign: "center",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (z_1.Registrered ? singleton(greenCheck()) : singleton(redX())))))),
                    }), tupledArg_2[1])]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    });
                }, grouped)), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                }))])]]));
            }
            else {
                return singleton(MuiHelpers_createElement(Skeleton, [["variant", "rect"], ["style", {
                    width: 100 + "%",
                    minHeight: 100,
                }]]));
            }
        })))),
    });
}

function view(model, dispatch) {
    let children;
    return createElement("div", {
        className: "report-content",
        children: Interop_reactApi.Children.toArray([snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(4));
        }), drawSummary(model), section([sectionTitle("Månedlig fordeling"), fromToText(model.PeriodStart, model.PeriodEnd), monthlyChart(model)]), section([sectionTitle("Registreringsstatus"), (children = toList(delay(() => {
            const matchValue = model.Level;
            switch (matchValue.tag) {
                case 1: {
                    return append_1(singleton(helperText("Alle påkrevde HMS-typer registrert for gitt prosjekt for gitt måned", true)), delay(() => singleton(helperText("En eller flere påkrevde HMS-typer er ikke registrert for gitt prosjekt for gitt måned", false))));
                }
                case 2: {
                    return append_1(singleton(helperText("Alle påkrevde HMS-typer er registrert for alle prosjekter for gitt selskap for gitt måned", true)), delay(() => singleton(helperText("En eller flere påkrevde HMS-typer er ikke registrert for en eller flere prosjekter for gitt selskap for gitt måned", false))));
                }
                default: {
                    return append_1(singleton(helperText("HMS-type registrert for gitt måned", true)), delay(() => singleton(helperText("HMS-type ikke registrert for gitt måned", false))));
                }
            }
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
            },
            children: Interop_reactApi.Children.toArray([registrationStatus(model.Status, model.PeriodStart, model.PeriodEnd)]),
        })])]),
    });
}

export function HmsReport(hmsReportInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(hmsReportInputProps.lvl, hmsReportInputProps.pStart, hmsReportInputProps.pEnd));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchTypes(dispatch);
        fetchMonthlyRegistrations(model_1.Level, model_1.PeriodStart, model_1.PeriodEnd, dispatch);
        fetchTypeSpread(model_1.Level, model_1.PeriodStart, model_1.PeriodEnd, dispatch);
        fetchRegistrationStatus(model_1.Level, model_1.PeriodStart, model_1.PeriodEnd, dispatch);
    });
    return view(model_1, dispatch);
}

