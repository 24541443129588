import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty, ofArray } from "./.fable/fable-library.3.2.4/List.js";
import { some } from "./.fable/fable-library.3.2.4/Option.js";
import { useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.68.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "./.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { obj_type, string_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { Auto_generateDecoder_7848D058 } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Helpers_ResultMessage$reflection } from "./Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { uncurry } from "./.fable/fable-library.3.2.4/Util.js";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import { loadingIndicatorSmallColored, snackbarError } from "./ViewHelpers.js";
import { empty as empty_1, singleton, append, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { tryFocus } from "./Utils.js";
import Button from "@material-ui/core/Button";

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    const submit = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#212121"], ["borderColor", "#ffcf01"], ["backgroundColor", "#ffcf01"], ["marginTop", 10]]));
    o = {
        inpt: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginTop", 10], ["marginBottom", 10]])),
        submit: submit,
    };
    return Object.assign({}, o);
});

export function RecoveryForm(recoveryFormInputProps) {
    const uid = recoveryFormInputProps.uid;
    const token = recoveryFormInputProps.token;
    console.log(some(uid));
    const s = useStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const password = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const setValidPassword = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(true);
    const setMatching = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_4[1];
    const processing = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_5[1];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(false);
    const tryCommit = () => {
        if (password.length < 8) {
            setValidPassword(false);
        }
        else {
            setValidPassword(true);
            if (password !== patternInput_1[0]) {
                setMatching(false);
            }
            else {
                setMatching(true);
                setProcessing(true);
                const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let uuid;
                    if (uid != null) {
                        const u = uid;
                        uuid = toText(printf("?uid=%s"))(u);
                    }
                    else {
                        uuid = "";
                    }
                    const requestPath = toText(printf("/api/auth/recover/%s%s"))(token)(uuid);
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: () => string_type,
                        })(password);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: Helpers_ResultMessage$reflection,
                        });
                        return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: Helpers_ResultMessage$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg1) => {
                        const res = _arg1;
                        if (res.tag === 0) {
                            const x = res.fields[0];
                            if (x.Result === "success") {
                                patternInput_6[1](true);
                                setProcessing(false);
                                return Promise.resolve();
                            }
                            else {
                                setErrMsg(x.Message);
                                setProcessing(false);
                                return Promise.resolve();
                            }
                        }
                        else {
                            setErrMsg("Kunne ikke oppdatere passord. Vennligst prøv igjen senere.");
                            setProcessing(false);
                            return Promise.resolve();
                        }
                    }));
                }));
                pr.then();
            }
        }
    };
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 100 + "%",
            backgroundColor: "#f1f1f1",
        },
        children: Interop_reactApi.Children.toArray([snackbarError(patternInput_5[0], () => {
            setErrMsg(void 0);
        }), createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                padding: 20,
                margin: 10,
                maxWidth: 450,
                backgroundColor: "#FFFFFF",
                borderRadius: 4,
                border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
                style: {
                    textAlign: "center",
                },
                children: Interop_reactApi.Children.toArray([createElement("img", {
                    src: "/images/logo1155x419.png",
                    style: {
                        marginBottom: 15 + "px",
                    },
                })]),
            })), delay(() => (patternInput_6[0] ? append(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["style", {
                textAlign: "center",
            }], ["children", "Passord oppdatert"]])), delay(() => singleton(createElement("a", {
                style: {
                    marginTop: 10,
                    textAlign: "center",
                },
                href: "/",
                children: "Logg inn",
            })))) : append(singleton(createElement("span", {
                style: {
                    textAlign: "center",
                },
                children: "Tilbakestill passord.",
            })), delay(() => {
                let children;
                return singleton((children = ofArray([MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["className", s.inpt]), delay(() => append(singleton(["id", "input-pwd"]), delay(() => append(singleton(["autoFocus", true]), delay(() => append(singleton(["required", true]), delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["label", "Nytt passord"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["type", "password"]), delay(() => append(singleton(["onChange", (e) => {
                    patternInput[1](Browser_Types_Event__Event_get_Value(e));
                }]), delay(() => append((!patternInput_2[0]) ? append(singleton(["error", true]), delay(() => singleton(["helperText", "Ugyldig passord"]))) : empty_1(), delay(() => singleton(["onKeyUp", (kc) => {
                    kc.preventDefault();
                    if ((kc.key === "Enter") ? (!processing) : false) {
                        if (password.length < 8) {
                            setValidPassword(false);
                        }
                        else {
                            setValidPassword(true);
                            tryFocus("repeat-pwd");
                        }
                    }
                }])))))))))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["className", s.inpt]), delay(() => append(singleton(["id", "repeat-pwd"]), delay(() => append(singleton(["required", true]), delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["label", "Gjenta passord"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["type", "password"]), delay(() => append(singleton(["onChange", (e_1) => {
                    patternInput_1[1](Browser_Types_Event__Event_get_Value(e_1));
                }]), delay(() => append((!patternInput_3[0]) ? append(singleton(["error", true]), delay(() => singleton(["helperText", "Passord er ikke like"]))) : empty_1(), delay(() => singleton(["onKeyUp", (kc_1) => {
                    kc_1.preventDefault();
                    if ((kc_1.key === "Enter") ? (!processing) : false) {
                        tryCommit();
                    }
                }])))))))))))))))))))))))), MuiHelpers_createElement(Button, [["className", s.submit], ["type", "submit"], ["fullWidth", true], ["variant", "contained"], ["color", "primary"], ["onClick", (e_2) => {
                    e_2.preventDefault();
                    if (!processing) {
                        tryCommit();
                    }
                }], ["style", {
                    height: 37,
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!processing) ? singleton("Oppdater passord") : singleton(loadingIndicatorSmallColored("#fff")))))))]])]), createElement("form", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                })));
            }))))))))),
        })]),
    });
}

