import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Tooltip_1 from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { toArray, empty, singleton, append, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { split, join, printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { Record, Union } from "./.fable/fable-library.3.2.4/Types.js";
import { equals, obj_type, bool_type, lambda_type, unit_type, record_type, string_type, list_type, getCaseTag, getCaseName, class_type, union_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import IconButton from "@material-ui/core/IconButton";
import { Interop_reactApi } from "./.fable/Feliz.1.68.0/Interop.fs.js";
import Icon from "@material-ui/core/Icon";
import { createElement } from "react";
import * as react from "react";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { keyValueList } from "./.fable/fable-library.3.2.4/MapUtil.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helpers_combineClasses, Helpers_extractClasses } from "./.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { parse } from "./.fable/fable-library.3.2.4/Int32.js";
import { map } from "./.fable/fable-library.3.2.4/Array.js";
import { toArray as toArray_1, tryFind, filter, fold, length, singleton as singleton_1, empty as empty_1, append as append_1, cons, ofArray } from "./.fable/fable-library.3.2.4/List.js";
import { createObj } from "./.fable/fable-library.3.2.4/Util.js";
import { rangeDouble } from "./.fable/fable-library.3.2.4/Range.js";
import { Helpers_classList } from "./.fable/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { CSSProp, DOMAttr, HTMLAttr } from "./.fable/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { defaultArg } from "./.fable/fable-library.3.2.4/Option.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "./.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import Switch from "@material-ui/core/Switch";

export function drawAllocation(alloc) {
    return MuiHelpers_createElement(Tooltip_1, [["title", (alloc > 100) ? "Overallokert" : ((alloc === 100) ? "Full-allokert" : ((alloc === 0) ? "Ikke allokert" : "Under-allokert"))], ["children", MuiHelpers_createElement(Typography, toList(delay(() => append((alloc > 100) ? singleton(["style", {
        color: "#d00000",
    }]) : ((alloc === 100) ? singleton(["style", {
        color: "#2d7d95",
    }]) : empty()), delay(() => singleton(["children", toText(printf("%i%%"))(alloc)]))))))]]);
}

export class RotationAngle extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Forward90", "Forward180", "Backward90", "Backward180"];
    }
}

export function RotationAngle$reflection() {
    return union_type("ViewHelpers.RotationAngle", [], RotationAngle, () => [[], [], [], []]);
}

export function rotatingChevronButton(angle, oc) {
    return MuiHelpers_createElement(IconButton, [["onClick", (_arg1) => {
        oc();
    }], ["className", "rotate-button-root"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(ChevronRight, keyValueList(toList(delay(() => append(singleton(["style", {
        transitionProperty: "transform",
        transitionDuration: 300 + "ms",
    }]), delay(() => ((angle.tag === 1) ? singleton(["className", "rotate-icon180"]) : ((angle.tag === 2) ? singleton(["className", "rotate-icon90rev"]) : ((angle.tag === 3) ? singleton(["className", "rotate-icon180rev"]) : singleton(["className", "rotate-icon90"])))))))), 1))])]])])]]);
}

export function insertMaterialTableNorwegianLocalization() {
    return ["localization", {
        body: {
            emptyDataSourceMessage: "Ingen data tilgjengelig. Legg til ny i hjørnet.",
            editTooltip: "Rediger",
            deleteTooltip: "Slett",
            addTooltip: "Legg til",
        },
        toolbar: {
            searchTooltip: "Søk",
            searchPlaceholder: "Søk",
            addRemoveColumns: "Rediger",
        },
        pagination: {
            labelRowsSelect: "Rader",
            labelDisplayedRows: " {from}-{to} av {count}",
            firstTooltip: "Første side",
            previousTooltip: "Forrige side",
            nextTooltip: "Neste side",
            lastTooltip: "Siste side",
        },
        header: {
            actions: "Rediger",
        },
    }];
}

export function genericDeleteDialog(abort, commit, explain) {
    return MuiHelpers_createElement(Dialog, [["style", {
        width: 100 + "vw",
    }], ["open", true], ["onClose", (delegateArg0, delegateArg1) => {
        abort();
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Vennligst bekreft at du vil fjerne dette elementet"], ["style", {
        margin: "auto",
    }]]), MuiHelpers_createElement(DialogContent, [["style", {
        display: "flex",
        width: 100 + "%",
        height: 100 + "%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(explain))]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg3) => {
        abort();
    }], ["color", "primary"], ["children", "Avbryt"]]), MuiHelpers_createElement(Button, [["onClick", (_arg4) => {
        const pr = commit();
        pr.then();
    }], ["color", "primary"], ["children", "Bekreft"]])])]])])]]);
}

export function addTooltip(title, element) {
    return MuiHelpers_createElement(Tooltip_1, [["title", title], ["children", element]]);
}

export function errorBanner(errMsg, onClose) {
    if (errMsg != null) {
        const eMsg = errMsg;
        return createElement("div", {
            style: {
                marginTop: 5,
                marginBottom: 5,
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Alert, [["onClose", (_arg1) => {
                onClose();
            }], ["severity", "error"], ["variant", "filled"], ["children", eMsg]])]),
        });
    }
    else {
        return null;
    }
}

export function snackbarError(errMsg, onClose) {
    return MuiHelpers_createElement(Snackbar, [["open", errMsg != null], ["onClose", (_arg33, v) => {
        onClose();
    }], ["autoHideDuration", 20000], ["anchorOrigin", {
        vertical: "bottom",
        horizontal: "center",
    }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg2) => {
        onClose();
    }], ["severity", "error"], ["variant", "filled"], ["children", (errMsg == null) ? "" : errMsg]])]]);
}

export function snackbarWarn(warnMsg, onClose) {
    return MuiHelpers_createElement(Snackbar, [["open", warnMsg != null], ["onClose", (_arg33, v) => {
        onClose();
    }], ["autoHideDuration", 20000], ["anchorOrigin", {
        vertical: "bottom",
        horizontal: "center",
    }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg2) => {
        onClose();
    }], ["severity", "warning"], ["variant", "filled"], ["children", (warnMsg == null) ? "" : warnMsg]])]]);
}

export function loadingIndicatorSmall() {
    return MuiHelpers_createElement(CircularProgress, [["style", {
        color: "#2d3a48",
    }], ["size", 0.8 + "rem"]]);
}

export function loadingIndicatorSmallColored(color) {
    return MuiHelpers_createElement(CircularProgress, [["size", 0.8 + "rem"], ["style", {
        color: color,
    }]]);
}

export function loadingIndicatorMedium() {
    return MuiHelpers_createElement(CircularProgress, [["style", {
        color: "#2d3a48",
    }]]);
}

export function loadingIndicatorLarge() {
    return MuiHelpers_createElement(CircularProgress, [["style", {
        color: "#2d3a48",
    }], ["size", 1.2 + "rem"]]);
}

export function loadingIndicatorCenteredSmall() {
    return createElement("div", {
        style: {
            display: "flex",
            width: 100 + "%",
            height: 100 + "%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#2d3a48",
        },
        children: Interop_reactApi.Children.toArray([loadingIndicatorSmall()]),
    });
}

export function monthDropdown(currentVal, onChange) {
    const patternInput = Helpers_extractClasses(ofArray([["className", "is-small"], ["value", currentVal], ["onChange", (ev) => {
        const x = ev.target.value;
        try {
            onChange(parse(x, 511, false, 32));
        }
        catch (matchValue) {
        }
    }], ["children", Interop_reactApi.Children.toArray([map((tupledArg) => createElement("option", {
        value: tupledArg[1],
        children: tupledArg[0],
    }), [["Januar", 1], ["Februar", 2], ["Mars", 3], ["April", 4], ["Mai", 5], ["Juni", 6], ["Juli", 7], ["August", 8], ["September", 9], ["Oktober", 10], ["November", 11], ["Desember", 12]])])]]));
    return createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    });
}

export function yearDropdown(minYear, maxYear, currentVal, onChange) {
    const patternInput = Helpers_extractClasses(ofArray([["className", "is-small"], ["value", currentVal], ["onChange", (ev) => {
        const x = ev.target.value;
        try {
            onChange(parse(x, 511, false, 32));
        }
        catch (matchValue) {
        }
    }], ["children", Interop_reactApi.Children.toArray([map((x_1) => createElement("option", {
        value: x_1,
        children: x_1,
    }), toArray(rangeDouble(minYear, 1, maxYear)))])]]));
    return createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    });
}

export function Text_secondaryText(txt) {
    return createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
            fontStyle: "italic",
            color: "#9a9a9a",
        },
        children: txt,
    });
}

export class Tooltip {
    constructor() {
    }
}

export function Tooltip$reflection() {
    return class_type("ViewHelpers.Tooltip", void 0, Tooltip);
}

export function Tooltip_Button_Z5AEE11A8(attrs, iconClasses, buttonName, tooltipText, onClick) {
    return react.createElement("span", keyValueList(append_1(attrs, ofArray([Helpers_classList([["fs-tooltip", true], ["fs-tooltip-icon", true]]), ["style", {
        border: "1px solid lightgrey",
        borderRadius: "4px",
        backgroundColor: "white",
        padding: "0 2px 4px 2px",
        margin: "0 4px",
    }]])), 1), ...toList(delay(() => append(singleton(react.createElement("i", {
        style: {
            verticalAlign: "middle",
        },
        className: join(" ", iconClasses),
        onClick: onClick,
    })), delay(() => append(singleton(react.createElement("span", {
        style: {
            padding: "0 3px",
            verticalAlign: "middle",
        },
        onClick: onClick,
    }, buttonName)), delay(() => ((tooltipText.trim().length > 0) ? singleton(react.createElement("div", {
        className: "fs-tooltip-text",
    }, tooltipText)) : empty()))))))));
}

export function Tooltip_Button_2C295D54(iconClasses, buttonName, tooltipText, onClick) {
    return Tooltip_Button_Z5AEE11A8(empty_1(), iconClasses, buttonName, tooltipText, onClick);
}

export function Tooltip_Icon_Z2D0FE13D(attrs, iconClasses, tooltipText, onClick) {
    return react.createElement("span", keyValueList(append_1(attrs, singleton_1(Helpers_classList([["fs-tooltip", true], ["fs-tooltip-icon", true]]))), 1), react.createElement("i", {
        className: join(" ", iconClasses),
        onClick: onClick,
    }), react.createElement("div", {
        className: "fs-tooltip-text",
    }, tooltipText));
}

export function Tooltip_Icon_59863326(attrs, iconClasses, tooltipText) {
    return Tooltip_Icon_Z2D0FE13D(attrs, iconClasses, tooltipText, (_arg1) => {
    });
}

export function Tooltip_Icon_215B7F4F(iconClasses, tooltipText, onClick) {
    return Tooltip_Icon_Z2D0FE13D(empty_1(), iconClasses, tooltipText, onClick);
}

export function Tooltip_Icon_ZBD22ED6(iconClasses, tooltipText) {
    return Tooltip_Icon_Z2D0FE13D(empty_1(), iconClasses, tooltipText, (_arg2) => {
    });
}

export function Tooltip_Div_5056ABEC(attrs, children, tooltip) {
    return react.createElement("div", keyValueList(append_1(attrs, singleton_1(new HTMLAttr(64, "fs-tooltip"))), 1), ...append(children, [(length(tooltip) > 0) ? react.createElement("div", {
        className: "fs-tooltip-text",
    }, ...tooltip) : react.createElement("div", {})]));
}

export function Tooltip_Text_Z24A98F16(attrs, children, tooltipText) {
    let rows;
    return react.createElement("div", keyValueList(append_1(attrs, singleton_1(new HTMLAttr(64, "fs-tooltip"))), 1), ...append(children, [(tooltipText.length > 80) ? ((rows = (((tooltipText.length > 240) ? 6 : 3) | 0), react.createElement("textarea", {
        className: "fs-tooltip-text",
        rows: rows,
        cols: 80,
    }, tooltipText))) : ((tooltipText.length > 0) ? react.createElement("div", {
        className: "fs-tooltip-text",
    }, tooltipText) : react.createElement("div", {}))]));
}

export function ClearFilter(isFilterSet, onClick) {
    if (isFilterSet) {
        return react.createElement("span", keyValueList([Helpers_classList([["fs-tooltip", true], ["fs-tooltip-icon", true]]), ["style", {
            fontSize: "1rem",
        }]], 1), react.createElement("i", {
            className: join(" ", ["fa", "fa-filter"]),
            style: {
                color: "rgb(229, 79, 68)",
            },
            onClick: onClick,
        }), react.createElement("div", {
            className: "fs-tooltip-text",
        }, "Slett filter"));
    }
    else {
        return react.createElement("div", {});
    }
}

export function errorHeader(errMsg) {
    return react.createElement("div", {
        className: "non-print-section",
        style: {
            backgroundColor: "#d65974",
            borderRadius: "3px",
            padding: "5px",
        },
    }, react.createElement("p", {
        style: {
            color: "#f6f6f6",
            fontSize: "15px",
            fontWeight: "bold",
            textAlign: "center",
            margin: "3px 0",
        },
    }, errMsg));
}

export function errorMsg(msg) {
    return react.createElement("div", {
        style: {
            borderRadius: "3px",
            padding: "3px",
        },
    }, react.createElement("p", {
        style: {
            color: "red",
            fontWeight: "bold",
            textAlign: "left",
            margin: "3px 0",
        },
    }, msg));
}

export function iconizedName(iconClass, name) {
    return react.createElement("div", {
        className: "fs-iconized-name",
    }, ...toList(delay(() => append(singleton(react.createElement("i", {
        className: iconClass,
    })), delay(() => ((name !== "") ? singleton(react.createElement("span", {}, name)) : empty()))))));
}

export function Reflection_getCaseName(case$) {
    return getCaseName(case$);
}

export function Reflection_getCaseTag(case$) {
    return getCaseTag(case$);
}

export class FontAwesome_IconClass extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["fa fa-plus"];
    }
}

export function FontAwesome_IconClass$reflection() {
    return union_type("ViewHelpers.FontAwesome.IconClass", [], FontAwesome_IconClass, () => [[]]);
}

export class Bootstrap_GenericOptions extends Record {
    constructor(Props, Classes, Label) {
        super();
        this.Props = Props;
        this.Classes = Classes;
        this.Label = Label;
    }
}

export function Bootstrap_GenericOptions$reflection() {
    return record_type("ViewHelpers.Bootstrap.GenericOptions", [], Bootstrap_GenericOptions, () => [["Props", list_type(class_type("Fable.React.Props.IHTMLProp"))], ["Classes", list_type(string_type)], ["Label", string_type]]);
}

export function Bootstrap_GenericOptions_get_Empty() {
    return new Bootstrap_GenericOptions(empty_1(), empty_1(), "");
}

export function Bootstrap_GenericOptions_Parse_Z21646BE4(options, parser, baseClass, baseProps) {
    const result = fold(parser, Bootstrap_GenericOptions_get_Empty(), options);
    const result_1 = (baseClass == null) ? result : Bootstrap_GenericOptions__AddClass_Z721C83C5(result, baseClass);
    if (baseProps == null) {
        return result_1;
    }
    else {
        return Bootstrap_GenericOptions__AddProps_416C4D0B(result_1, baseProps);
    }
}

export function Bootstrap_GenericOptions__AddProp_7BFEDA81(this$, prop) {
    return new Bootstrap_GenericOptions(cons(prop, this$.Props), this$.Classes, this$.Label);
}

export function Bootstrap_GenericOptions__AddProps_416C4D0B(this$, props) {
    return new Bootstrap_GenericOptions(append_1(props, this$.Props), this$.Classes, this$.Label);
}

export function Bootstrap_GenericOptions__AddClass_Z721C83C5(this$, cl) {
    return new Bootstrap_GenericOptions(this$.Props, cons(cl, this$.Classes), this$.Label);
}

export function Bootstrap_GenericOptions__RemoveClass_Z721C83C5(this$, cl) {
    return new Bootstrap_GenericOptions(this$.Props, filter((cls) => (cls !== cl), this$.Classes), this$.Label);
}

export function Bootstrap_GenericOptions__AddCaseName_1505(this$, case$) {
    return Bootstrap_GenericOptions__AddClass_Z721C83C5(this$, Reflection_getCaseName(case$));
}

export function Bootstrap_GenericOptions__AddLabel_Z721C83C5(this$, l) {
    return new Bootstrap_GenericOptions(this$.Props, this$.Classes, l);
}

export function Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(this$, el, children) {
    const children_1 = defaultArg(children, empty_1());
    return el(cons(new HTMLAttr(64, join(" ", this$.Classes)), this$.Props), children_1);
}

export function Bootstrap_GenericOptions__ToReactElement_Z46A53D36(this$, el) {
    const classes = new HTMLAttr(64, join(" ", this$.Classes));
    if (this$.Label === "") {
        return el(cons(classes, this$.Props));
    }
    else {
        return react.createElement("label", {
            className: "checkbox-inline",
        }, el(cons(classes, this$.Props)), this$.Label);
    }
}

export class Bootstrap_GenericOption extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Props", "CustomClass"];
    }
}

export function Bootstrap_GenericOption$reflection() {
    return union_type("ViewHelpers.Bootstrap.GenericOption", [], Bootstrap_GenericOption, () => [[["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

export function Bootstrap_parseGenericOption(result, option) {
    if (option.tag === 1) {
        return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
    }
    else {
        return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
    }
}

export class Bootstrap_Button_ButtonClass extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["btn-default", "btn-info", "btn-primary", "btn-secondary", "btn-success", "btn-warning", "btn-danger", "btn-link"];
    }
}

export function Bootstrap_Button_ButtonClass$reflection() {
    return union_type("ViewHelpers.Bootstrap.Button.ButtonClass", [], Bootstrap_Button_ButtonClass, () => [[], [], [], [], [], [], [], []]);
}

export function Bootstrap_Button_ofButtonClass(btnClass) {
    return Reflection_getCaseName(btnClass);
}

export class Bootstrap_Button_ButtonSize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["btn-xs", "btn-sm", "btn-md", "btn-lg"];
    }
}

export function Bootstrap_Button_ButtonSize$reflection() {
    return union_type("ViewHelpers.Bootstrap.Button.ButtonSize", [], Bootstrap_Button_ButtonSize, () => [[], [], [], []]);
}

function Bootstrap_Button_ofButtonSize(btnSize) {
    return Reflection_getCaseName(btnSize);
}

export class Bootstrap_Button_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Class", "Size", "OnClick", "Props", "CustomClass"];
    }
}

export function Bootstrap_Button_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Button.Option", [], Bootstrap_Button_Option, () => [[["Item", Bootstrap_Button_ButtonClass$reflection()]], [["Item", Bootstrap_Button_ButtonSize$reflection()]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent"), unit_type)]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

export function Bootstrap_Button_btnView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 2: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new DOMAttr(40, option.fields[0]));
            }
            case 1: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Button_ofButtonSize(option.fields[0]));
            }
            case 3: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 4: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Button_ofButtonClass(option.fields[0]));
            }
        }
    }, "btn"), element, children);
}

export function Bootstrap_Button_button(options, children) {
    return Bootstrap_Button_btnView((props, children_1) => react.createElement("button", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Input_InputSize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["form-control-xs", "form-control-sm", "form-control-md", "form-control-lg"];
    }
}

export function Bootstrap_Input_InputSize$reflection() {
    return union_type("ViewHelpers.Bootstrap.Input.InputSize", [], Bootstrap_Input_InputSize, () => [[], [], [], []]);
}

export class Bootstrap_Input_InputType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Text", "Password", "Email", "Date", "Month", "Week", "Time", "Number", "Checkbox"];
    }
}

export function Bootstrap_Input_InputType$reflection() {
    return union_type("ViewHelpers.Bootstrap.Input.InputType", [], Bootstrap_Input_InputType, () => [[], [], [], [], [], [], [], [], []]);
}

function Bootstrap_Input_ofInputSize(inptSize) {
    return Reflection_getCaseName(inptSize);
}

function Bootstrap_Input_ofInputType(inptType) {
    switch (inptType.tag) {
        case 1: {
            return "password";
        }
        case 2: {
            return "email";
        }
        case 3: {
            return "date";
        }
        case 4: {
            return "month";
        }
        case 5: {
            return "week";
        }
        case 6: {
            return "time";
        }
        case 7: {
            return "number";
        }
        case 8: {
            return "checkbox";
        }
        default: {
            return "text";
        }
    }
}

export class Bootstrap_Input_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "Type", "OnChange", "Disabled", "Value", "DefaultValue", "Placeholder", "AutoFocus", "Props", "CustomClass", "Checked", "DefaultChecked", "Label"];
    }
}

export function Bootstrap_Input_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Input.Option", [], Bootstrap_Input_Option, () => [[["Item", Bootstrap_Input_InputSize$reflection()]], [["Item", Bootstrap_Input_InputType$reflection()]], [["Item", lambda_type(class_type("Browser.Types.Event"), unit_type)]], [["Item", bool_type]], [["Item", obj_type]], [["Item", obj_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]]]);
}

export function Bootstrap_Input_inputView(element, options) {
    let matchValue;
    return Bootstrap_GenericOptions__ToReactElement_Z46A53D36(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 2: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new DOMAttr(9, option.fields[0]));
            }
            case 1: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(159, Bootstrap_Input_ofInputType(option.fields[0])));
            }
            case 3: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(79, option.fields[0]));
            }
            case 4: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(161, option.fields[0]));
            }
            case 5: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(1, option.fields[0]));
            }
            case 6: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(128, option.fields[0]));
            }
            case 7: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(55, option.fields[0]));
            }
            case 8: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 9: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 10: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(62, option.fields[0]));
            }
            case 11: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(0, option.fields[0]));
            }
            case 12: {
                return Bootstrap_GenericOptions__AddLabel_Z721C83C5(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Input_ofInputSize(option.fields[0]));
            }
        }
    }, (matchValue = tryFind((o) => equals(Bootstrap_Input_Option$reflection(), Bootstrap_Input_InputType$reflection()), options), (matchValue == null) ? "form-control" : ((matchValue.tag === 1) ? ((matchValue.fields[0].tag === 8) ? "" : "form-control") : "form-control"))), element);
}

export function Bootstrap_Input_input(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), options);
}

export function Bootstrap_Input_text(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), append_1(options, singleton_1(new Bootstrap_Input_Option(1, new Bootstrap_Input_InputType(0)))));
}

export function Bootstrap_Input_password(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), append_1(options, singleton_1(new Bootstrap_Input_Option(1, new Bootstrap_Input_InputType(1)))));
}

export function Bootstrap_Input_email(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), append_1(options, singleton_1(new Bootstrap_Input_Option(1, new Bootstrap_Input_InputType(2)))));
}

export function Bootstrap_Input_date(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), append_1(options, singleton_1(new Bootstrap_Input_Option(1, new Bootstrap_Input_InputType(3)))));
}

export function Bootstrap_Input_month(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), append_1(options, singleton_1(new Bootstrap_Input_Option(1, new Bootstrap_Input_InputType(4)))));
}

export function Bootstrap_Input_week(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), append_1(options, singleton_1(new Bootstrap_Input_Option(1, new Bootstrap_Input_InputType(5)))));
}

export function Bootstrap_Input_time(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), append_1(options, singleton_1(new Bootstrap_Input_Option(1, new Bootstrap_Input_InputType(6)))));
}

export function Bootstrap_Input_number(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), append_1(options, singleton_1(new Bootstrap_Input_Option(1, new Bootstrap_Input_InputType(7)))));
}

export function Bootstrap_Input_checkbox(options) {
    return Bootstrap_Input_inputView((props) => react.createElement("input", keyValueList(props, 1)), append_1(options, ofArray([new Bootstrap_Input_Option(1, new Bootstrap_Input_InputType(8)), new Bootstrap_Input_Option(9, "checkbox")])));
}

export class Bootstrap_Spinner_SpinnerClass extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["text-primary", "text-secondary", "text-success", "text-danger", "text-warning", "text-info", "text-light", "text-dark"];
    }
}

export function Bootstrap_Spinner_SpinnerClass$reflection() {
    return union_type("ViewHelpers.Bootstrap.Spinner.SpinnerClass", [], Bootstrap_Spinner_SpinnerClass, () => [[], [], [], [], [], [], [], []]);
}

function Bootstrap_Spinner_ofSpinnerClass(spinnClass) {
    return Reflection_getCaseName(spinnClass);
}

export class Bootstrap_Spinner_SpinnerType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["spinner-border", "spinner-grow"];
    }
}

export function Bootstrap_Spinner_SpinnerType$reflection() {
    return union_type("ViewHelpers.Bootstrap.Spinner.SpinnerType", [], Bootstrap_Spinner_SpinnerType, () => [[], []]);
}

function Bootstrap_Spinner_ofSpinnerType(spinnType) {
    return Reflection_getCaseName(spinnType);
}

export class Bootstrap_Spinner_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Class", "Type", "Props"];
    }
}

export function Bootstrap_Spinner_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Spinner.Option", [], Bootstrap_Spinner_Option, () => [[["Item", Bootstrap_Spinner_SpinnerClass$reflection()]], [["Item", Bootstrap_Spinner_SpinnerType$reflection()]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]]]);
}

export function Bootstrap_Spinner_spinnerView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Spinner_ofSpinnerType(option.fields[0]));
            }
            case 2: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Spinner_ofSpinnerClass(option.fields[0]));
            }
        }
    }), element, children);
}

export function Bootstrap_Spinner_Div_spinner(options, children) {
    return Bootstrap_Spinner_spinnerView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Spinner_Div_border(options, children) {
    return Bootstrap_Spinner_spinnerView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), append_1(options, singleton_1(new Bootstrap_Spinner_Option(1, new Bootstrap_Spinner_SpinnerType(0)))), children);
}

export function Bootstrap_Spinner_Div_growing(options, children) {
    return Bootstrap_Spinner_spinnerView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), append_1(options, singleton_1(new Bootstrap_Spinner_Option(1, new Bootstrap_Spinner_SpinnerType(1)))), children);
}

export function Bootstrap_Spinner_Span_spinner(options, children) {
    return Bootstrap_Spinner_spinnerView((props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Spinner_Span_border(options, children) {
    return Bootstrap_Spinner_spinnerView((props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), append_1(options, ofArray([new Bootstrap_Spinner_Option(1, new Bootstrap_Spinner_SpinnerType(0)), new Bootstrap_Spinner_Option(2, singleton_1(new HTMLAttr(135, "status")))])), children);
}

export function Bootstrap_Spinner_Span_growing(options, children) {
    return Bootstrap_Spinner_spinnerView((props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), append_1(options, ofArray([new Bootstrap_Spinner_Option(1, new Bootstrap_Spinner_SpinnerType(1)), new Bootstrap_Spinner_Option(2, singleton_1(new HTMLAttr(135, "status")))])), children);
}

export class Bootstrap_Table_TableSize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["table-xs", "table-sm", "table-md", "table-lg"];
    }
}

export function Bootstrap_Table_TableSize$reflection() {
    return union_type("ViewHelpers.Bootstrap.Table.TableSize", [], Bootstrap_Table_TableSize, () => [[], [], [], []]);
}

function Bootstrap_Table_ofTableSize(tableSize) {
    return Reflection_getCaseName(tableSize);
}

export class Bootstrap_Table_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Bordered", "Borderless", "Striped", "Hoverable", "IsDark", "Responsive", "ResponsiveSmall", "ResponsiveMedium", "ResponsiveLarge", "Size", "Props", "CustomClass"];
    }
}

export function Bootstrap_Table_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Table.Option", [], Bootstrap_Table_Option, () => [[], [], [], [], [], [], [], [], [], [["Item", Bootstrap_Table_TableSize$reflection()]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

export function Bootstrap_Table_tableView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "table-borderless");
            }
            case 2: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "table-striped");
            }
            case 3: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "table-hover");
            }
            case 4: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "table-dark");
            }
            case 5: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "table-responsive");
            }
            case 6: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "table-responsive-sm");
            }
            case 7: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "table-responsive-md");
            }
            case 8: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "table-responsive-lg");
            }
            case 9: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Table_ofTableSize(option.fields[0]));
            }
            case 10: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 11: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "table-bordered");
            }
        }
    }, "table"), element, children);
}

export function Bootstrap_Table_table(options, children) {
    return Bootstrap_Table_tableView((props, children_1) => react.createElement("table", keyValueList(props, 1), ...children_1), options, children);
}

function Bootstrap_Dropdown_Divider_dividerView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => Bootstrap_parseGenericOption(result, option), "dropdown-divider"), element, children);
}

export function Bootstrap_Dropdown_Divider_divider(options, children) {
    return Bootstrap_Dropdown_Divider_dividerView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Dropdown_Item_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnClick", "Href", "Blank", "Props", "CustomClass"];
    }
}

export function Bootstrap_Dropdown_Item_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Dropdown.Item.Option", [], Bootstrap_Dropdown_Item_Option, () => [[["Item", lambda_type(class_type("Browser.Types.MouseEvent"), unit_type)]], [["Item", string_type]], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

function Bootstrap_Dropdown_Item_itemView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(94, option.fields[0]));
            }
            case 3: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new HTMLAttr(157, "_blank"));
            }
            case 4: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new DOMAttr(40, option.fields[0]));
            }
        }
    }, "dropdown-item"), element, children);
}

export function Bootstrap_Dropdown_Item_a(options, children) {
    return Bootstrap_Dropdown_Item_itemView((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Dropdown_Item_button(options, children) {
    return Bootstrap_Dropdown_Item_itemView((props, children_1) => react.createElement("button", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Dropdown_Toggle_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ButtonClass", "OnClick", "Props", "CustomClass"];
    }
}

export function Bootstrap_Dropdown_Toggle_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Dropdown.Toggle.Option", [], Bootstrap_Dropdown_Toggle_Option, () => [[["Item", Bootstrap_Button_ButtonClass$reflection()]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent"), unit_type)]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

function Bootstrap_Dropdown_Toggle_toggleView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions__AddProps_416C4D0B(Bootstrap_GenericOptions__AddClass_Z721C83C5(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddProp_7BFEDA81(result, new DOMAttr(40, option.fields[0]));
            }
            case 2: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 3: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Button_ofButtonClass(option.fields[0]));
            }
        }
    }, "dropdown-toggle"), "btn"), ofArray([new HTMLAttr(94, "#"), new HTMLAttr(135, "button"), new HTMLAttr(23, true), new HTMLAttr(21, false)])), element, children);
}

export function Bootstrap_Dropdown_Toggle_a(options, children) {
    return Bootstrap_Dropdown_Toggle_toggleView((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Dropdown_Toggle_button(options, children) {
    return Bootstrap_Dropdown_Toggle_toggleView((props, children_1) => react.createElement("button", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Dropdown_Menu_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IsActive", "Props", "CustomClass"];
    }
}

export function Bootstrap_Dropdown_Menu_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Dropdown.Menu.Option", [], Bootstrap_Dropdown_Menu_Option, () => [[["Item", bool_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

function Bootstrap_Dropdown_Menu_menuView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "show");
                }
                else {
                    return result;
                }
            }
        }
    }, "dropdown-menu"), element, children);
}

export function Bootstrap_Dropdown_Menu_div(options, children) {
    return Bootstrap_Dropdown_Menu_menuView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Dropdown_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IsActive", "Props", "CustomClass"];
    }
}

export function Bootstrap_Dropdown_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Dropdown.Option", [], Bootstrap_Dropdown_Option, () => [[["Item", bool_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

function Bootstrap_Dropdown_dropdownView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "show");
                }
                else {
                    return result;
                }
            }
        }
    }, "dropdown"), element, children);
}

export function Bootstrap_Dropdown_dropdown(options, children) {
    return Bootstrap_Dropdown_dropdownView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Modal_Dialog_ModalSize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["modal-sm", "modal-lg"];
    }
}

export function Bootstrap_Modal_Dialog_ModalSize$reflection() {
    return union_type("ViewHelpers.Bootstrap.Modal.Dialog.ModalSize", [], Bootstrap_Modal_Dialog_ModalSize, () => [[], []]);
}

function Bootstrap_Modal_Dialog_ofModalSize(mSize) {
    return Reflection_getCaseName(mSize);
}

export class Bootstrap_Modal_Dialog_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Centered", "Size", "Props", "CustomClass"];
    }
}

export function Bootstrap_Modal_Dialog_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Modal.Dialog.Option", [], Bootstrap_Modal_Dialog_Option, () => [[], [["Item", Bootstrap_Modal_Dialog_ModalSize$reflection()]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

export function Bootstrap_Modal_Dialog_dialogView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions__AddProps_416C4D0B(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Modal_Dialog_ofModalSize(option.fields[0]));
            }
            case 2: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 3: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "modal-dialog-centered");
            }
        }
    }, "modal-dialog"), singleton_1(new HTMLAttr(135, "document"))), element, children);
}

export function Bootstrap_Modal_Dialog_dialog(options, children) {
    return Bootstrap_Modal_Dialog_dialogView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Modal_Content_contentView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => Bootstrap_parseGenericOption(result, option), "modal-content"), element, children);
}

export function Bootstrap_Modal_Content_content(options, children) {
    return Bootstrap_Modal_Content_contentView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Modal_Title_titleView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => Bootstrap_parseGenericOption(result, option), "modal-title"), element, children);
}

export function Bootstrap_Modal_Title_title(options, children) {
    return Bootstrap_Modal_Title_titleView((props, children_1) => react.createElement("h5", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Modal_Header_headerView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => Bootstrap_parseGenericOption(result, option), "modal-header"), element, children);
}

export function Bootstrap_Modal_Header_header(options, children) {
    return Bootstrap_Modal_Header_headerView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Modal_Body_bodyView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => Bootstrap_parseGenericOption(result, option), "modal-body"), element, children);
}

export function Bootstrap_Modal_Body_body(options, children) {
    return Bootstrap_Modal_Body_bodyView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Modal_Footer_footerView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => Bootstrap_parseGenericOption(result, option), "modal-footer"), element, children);
}

export function Bootstrap_Modal_Footer_footer(options, children) {
    return Bootstrap_Modal_Footer_footerView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Modal_Modal_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Fade", "IsActive", "Props"];
    }
}

export function Bootstrap_Modal_Modal_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Modal.Modal.Option", [], Bootstrap_Modal_Modal_Option, () => [[], [["Item", bool_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]]]);
}

export function Bootstrap_Modal_Modal_modalView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions__AddProps_416C4D0B(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                if (option.fields[0]) {
                    return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "show");
                }
                else {
                    return result;
                }
            }
            case 2: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "fade");
            }
        }
    }, "modal"), ofArray([new HTMLAttr(156, -1), new HTMLAttr(135, "dialog")])), element, children);
}

export function Bootstrap_Modal_Modal_modal(options, children) {
    return Bootstrap_Modal_Modal_modalView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Text_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Lowercase", "Uppercase", "Capitalized", "Bold", "Bolder", "Italic", "Light", "Lighter", "Monospace", "Muted", "Break", "Reset", "NoDecoration", "Lead", "Props", "CustomClass"];
    }
}

export function Bootstrap_Text_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Text.Option", [], Bootstrap_Text_Option, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

export function Bootstrap_Text_textView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "text-uppercase");
            }
            case 2: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "text-capitalize");
            }
            case 3: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "font-weight-bold");
            }
            case 4: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "font-weight-bolder");
            }
            case 5: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "font-italic");
            }
            case 6: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "font-weight-light");
            }
            case 7: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "font-weight-lighter");
            }
            case 8: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "text-monospace");
            }
            case 9: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "text-muted");
            }
            case 10: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "text-break");
            }
            case 11: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "text-reset");
            }
            case 12: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "text-decoration-none");
            }
            case 13: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "lead");
            }
            case 14: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 15: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "text-lowercase");
            }
        }
    }), element, children);
}

export function Bootstrap_Text_p(options, children) {
    return Bootstrap_Text_textView((props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), options, children);
}

export function Bootstrap_Text_a(options, children) {
    return Bootstrap_Text_textView((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Collapse_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Show", "Props", "CustomClass"];
    }
}

export function Bootstrap_Collapse_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Collapse.Option", [], Bootstrap_Collapse_Option, () => [[["Item", bool_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

export function Bootstrap_Collapse_collapseView(element, options, children) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, "show");
                }
                else {
                    return result;
                }
            }
        }
    }, "collapse"), element, children);
}

export function Bootstrap_Collapse_div(options, children) {
    return Bootstrap_Collapse_collapseView((props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), options, children);
}

export class Bootstrap_Icon_IconClass extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["fa-edit", "fa-trash", "fa-check-circle", "fa-times-circle", "fa-ban", "fa-plus", "fa-times", "fa-user", "fa-search", "fa-list", "fa-eye", "fa-arrow-left", "fa-print"];
    }
}

export function Bootstrap_Icon_IconClass$reflection() {
    return union_type("ViewHelpers.Bootstrap.Icon.IconClass", [], Bootstrap_Icon_IconClass, () => [[], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function Bootstrap_Icon_ofIconClass(iconClass) {
    return Reflection_getCaseName(iconClass);
}

export class Bootstrap_Icon_IconSize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["fas", "fa"];
    }
}

export function Bootstrap_Icon_IconSize$reflection() {
    return union_type("ViewHelpers.Bootstrap.Icon.IconSize", [], Bootstrap_Icon_IconSize, () => [[], []]);
}

function Bootstrap_Icon_ofIconSize(iconSize) {
    return Reflection_getCaseName(iconSize);
}

export class Bootstrap_Icon_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Class", "Size", "Props", "CustomClass"];
    }
}

export function Bootstrap_Icon_Option$reflection() {
    return union_type("ViewHelpers.Bootstrap.Icon.Option", [], Bootstrap_Icon_Option, () => [[["Item", Bootstrap_Icon_IconClass$reflection()]], [["Item", Bootstrap_Icon_IconSize$reflection()]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]]]);
}

export function Bootstrap_Icon_iconView(element, options) {
    return Bootstrap_GenericOptions__ToReactElement_Z6D3CD4B7(Bootstrap_GenericOptions_Parse_Z21646BE4(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Icon_ofIconSize(option.fields[0]));
            }
            case 2: {
                return Bootstrap_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 3: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            default: {
                return Bootstrap_GenericOptions__AddClass_Z721C83C5(result, Bootstrap_Icon_ofIconClass(option.fields[0]));
            }
        }
    }, "fa"), element, empty_1());
}

export function Bootstrap_Icon_i(options) {
    return Bootstrap_Icon_iconView((props, children) => react.createElement("i", keyValueList(props, 1), ...children), options);
}

export class Bs {
    constructor() {
    }
}

export function Bs$reflection() {
    return class_type("ViewHelpers.Bs", void 0, Bs);
}

export function Bs_SaveConfirm_Z3A341040(onSaveText, onSave, onCloseText, onClose) {
    return react.createElement("div", {}, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, new Bootstrap_Button_ButtonClass(5)), new Bootstrap_Button_Option(1, new Bootstrap_Button_ButtonSize(0)), new Bootstrap_Button_Option(2, onSave)]), singleton_1(iconizedName("fa fa-trash", onSaveText))), react.createElement("span", {}, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(1, new Bootstrap_Button_ButtonSize(0)), new Bootstrap_Button_Option(2, onClose)]), singleton_1(iconizedName("fa fa-times-circle", onCloseText)))));
}

export function Bs_SaveConfirm_28F124E0(onSave, onClose) {
    return Bs_SaveConfirm_Z3A341040("Lagre", onSave, "Avbryt", onClose);
}

export function Bs_DeleteConfirm_Z3A341040(onSaveText, onSave, onCloseText, onClose) {
    return react.createElement("div", {}, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, new Bootstrap_Button_ButtonClass(5)), new Bootstrap_Button_Option(1, new Bootstrap_Button_ButtonSize(0)), new Bootstrap_Button_Option(2, onSave)]), singleton_1(iconizedName("fa fa-trash", onSaveText))), react.createElement("span", {}, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(1, new Bootstrap_Button_ButtonSize(0)), new Bootstrap_Button_Option(2, onClose)]), singleton_1(iconizedName("fa fa-times-circle", onCloseText)))));
}

export function Bs_DeleteConfirm_28F124E0(onSave, onClose) {
    return Bs_DeleteConfirm_Z3A341040("Slett", onSave, "Avbryt", onClose);
}

export function Bs_IconButton(attrs, iconClasses, text, onClick) {
    return react.createElement("div", keyValueList(attrs, 1), ...toList(delay(() => append(singleton(react.createElement("i", {
        className: join(" ", iconClasses),
        onClick: onClick,
    })), delay(() => ((text !== "") ? singleton(react.createElement("span", {
        style: {
            paddingLeft: "6px",
            cursor: "pointer",
        },
        onClick: onClick,
    }, text)) : empty()))))));
}

export function Bs_IconAnchor(attrs, iconClasses, href, text) {
    return react.createElement("div", keyValueList(attrs, 1), react.createElement("a", {
        style: {
            cursor: "pointer",
            color: "black",
        },
        href: href,
    }, react.createElement("i", {
        className: join(" ", iconClasses),
        style: {
            color: "black",
        },
    }), react.createElement("span", {
        style: {
            paddingLeft: "6px",
            color: "black",
        },
    }, text)));
}

export function Bs_IconAnchorNt(attrs, iconClasses, href, text) {
    return react.createElement("div", keyValueList(attrs, 1), react.createElement("a", {
        style: {
            cursor: "pointer",
            color: "black",
        },
        href: href,
        target: "_blank",
    }, react.createElement("i", {
        className: join(" ", iconClasses),
        style: {
            color: "black",
        },
    }), react.createElement("span", {
        style: {
            paddingLeft: "6px",
            color: "black",
        },
    }, text)));
}

export function PageElements_arrowUp() {
    return react.createElement("i", {
        style: {
            margin: "auto auto",
            paddingLeft: "3px",
            paddingRight: "3px",
        },
        className: join(" ", ["fas", "fa-angle-up"]),
    });
}

export function PageElements_arrowDown() {
    return react.createElement("i", {
        style: {
            margin: "auto auto",
            paddingLeft: "3px",
            paddingRight: "3px",
        },
        className: join(" ", ["fas", "fa-angle-down"]),
    });
}

export function PageElements_documentTag(name) {
    return Bootstrap_Button_button(singleton_1(new Bootstrap_Button_Option(3, singleton_1(["style", {
        border: "1px solid #131313",
        borderRadius: "5px",
        padding: "5px",
        marginRight: "2px",
    }]))), ofArray([react.createElement("i", {
        className: join(" ", ["fas", "fa-tag"]),
        style: {
            margin: "5px",
        },
    }), name]));
}

export function PageElements_documentTagWithDelete(name, onClick) {
    return Bootstrap_Button_button(singleton_1(new Bootstrap_Button_Option(3, singleton_1(["style", {
        border: "1px solid #131313",
        borderRadius: "5px",
        padding: "5px",
        marginRight: "2px",
    }]))), ofArray([react.createElement("i", {
        className: join(" ", ["fas", "fa-tag"]),
        style: {
            margin: "5px",
        },
    }), name, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, new Bootstrap_Button_ButtonClass(0)), new Bootstrap_Button_Option(1, new Bootstrap_Button_ButtonSize(0)), new Bootstrap_Button_Option(2, onClick)]), singleton_1(react.createElement("i", {
        className: join(" ", ["fas", "fa-trash"]),
    })))]));
}

export function PageElements_documentTagWithDeleteSmall(name, onClick) {
    return Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(3, singleton_1(["style", {
        border: "1px solid #131313",
        borderRadius: "5px",
        padding: "5px",
        marginRight: "2px",
        fontSize: "0.7rem",
    }])), new Bootstrap_Button_Option(2, onClick)]), ofArray([react.createElement("i", {
        className: join(" ", ["fas", "fa-tag"]),
        style: {
            margin: "5px",
        },
    }), name]));
}

export function PageElements_documentTagSmall(name) {
    return Bootstrap_Button_button(singleton_1(new Bootstrap_Button_Option(3, singleton_1(["style", {
        border: "1px solid #131313",
        borderRadius: "5px",
        padding: "5px",
        fontSize: "0.7rem",
        marginRight: "2px",
    }]))), ofArray([react.createElement("i", {
        className: join(" ", ["fas", "fa-tag"]),
        style: {
            margin: "3px",
        },
    }), name]));
}

export function PageElements_inlineCheckbox(onChange, title, isChecked) {
    return MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["checked", isChecked], ["onChange", (e) => {
        onChange(Browser_Types_Event__Event_get_Checked(e));
    }], ["color", "primary"]])], ["label", title]]);
}

export function PageElements_sliderCheckbox(isChecked, onChange, checkboxId) {
    return MuiHelpers_createElement(Switch, [["id", checkboxId], ["checked", isChecked], ["onChange", (ev) => {
        onChange(ev.target.value);
    }], ["color", "primary"]]);
}

export function PageElements_textArea(text, rows, cols, onChange) {
    return react.createElement("textarea", {
        rows: rows,
        cols: cols,
        onChange: onChange,
    }, text);
}

export function PageElements_filenameToIcon(filename) {
    try {
        const style = singleton_1(new CSSProp(225, "5px"));
        const splitted = split(filename, ["."], null, 0);
        const fileExt = (splitted.length > 1) ? splitted[splitted.length - 1] : "";
        switch (fileExt) {
            case "docx":
            case "doxm":
            case "dotx": {
                return react.createElement("i", {
                    style: keyValueList(style, 1),
                    className: join(" ", ["far", "fa-file-word"]),
                });
            }
            case "pdf": {
                return react.createElement("i", {
                    style: keyValueList(style, 1),
                    className: join(" ", ["far", "fa-file-pdf"]),
                });
            }
            case "png":
            case "jpg":
            case "jpeg": {
                return react.createElement("i", {
                    style: keyValueList(style, 1),
                    className: join(" ", ["far", "fa-file-image"]),
                });
            }
            case "mp4":
            case "avi":
            case "wmv":
            case "ogg": {
                return react.createElement("i", {
                    style: keyValueList(style, 1),
                    className: join(" ", ["far", "fa-file-video"]),
                });
            }
            case "wav":
            case "mp3":
            case "mid":
            case "aif": {
                return react.createElement("i", {
                    style: keyValueList(style, 1),
                    className: join(" ", ["far", "fa-file-audio"]),
                });
            }
            case "pptx":
            case "ppt":
            case "ppm": {
                return react.createElement("i", {
                    style: keyValueList(style, 1),
                    className: join(" ", ["far", "fa-file-powerpoint"]),
                });
            }
            default: {
                return react.createElement("i", {
                    style: keyValueList(style, 1),
                    className: join(" ", ["far", "fa-file"]),
                });
            }
        }
    }
    catch (matchValue) {
        return toArray_1(empty_1());
    }
}

export function PageElements_limitFilename(filename, maxLength) {
    const splitted = split(filename, ["."], null, 0);
    const fn = splitted[0];
    const fileExt = (splitted.length > 1) ? splitted[splitted.length - 1] : "";
    if (filename.length > maxLength) {
        const newLength = ((maxLength - fileExt.length) - 1) | 0;
        if (fn.length > newLength) {
            const x = fn.slice(0, newLength + 1);
            return toText(printf("%s.. .%s"))(x)(fileExt);
        }
        else {
            return filename;
        }
    }
    else {
        return filename;
    }
}

export function PageElements_modal(title, onCloseClick, onSubmitClick, children) {
    let elms_3, elms, elms_1, elms_2;
    const props_13 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", onCloseClick])))), (elms_3 = ofArray([(elms = ofArray([createElement("p", {
        className: "modal-card-title",
        children: "Produkter utløper på dato",
    }), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", onCloseClick]))))]), createElement("header", {
        className: "modal-card-head",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton_1(toArray_1(children)), createElement("section", {
        className: "modal-card-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_2 = ofArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["onClick", onSubmitClick], ["children", "Lagre"]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", onCloseClick], ["children", "Lukk"]]))))]), createElement("footer", {
        className: "modal-card-foot",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "modal-card",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_13)));
}

export function PageElements_modalNoSubmit(title, onCloseClick, children) {
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onCloseClick(v);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", title]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])])]]);
}

export function PageElements_modalDyn(dialogProps, title, onCloseClick, children) {
    return Bootstrap_Modal_Modal_modal(ofArray([new Bootstrap_Modal_Modal_Option(0), new Bootstrap_Modal_Modal_Option(1, true), new Bootstrap_Modal_Modal_Option(2, singleton_1(["style", {
        display: "block",
        backgroundColor: "#aaaa",
    }]))]), singleton_1(Bootstrap_Modal_Dialog_dialog(singleton_1(new Bootstrap_Modal_Dialog_Option(2, dialogProps)), singleton_1(Bootstrap_Modal_Content_content(empty_1(), ofArray([Bootstrap_Modal_Header_header(empty_1(), ofArray([react.createElement("div", {
        style: {
            textAlign: "center",
            fontWeight: "bold",
        },
    }, Bootstrap_Modal_Title_title(empty_1(), singleton_1(title))), Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(4, "close"), new Bootstrap_Button_Option(3, ofArray([["data-dismiss", "modal"], new HTMLAttr(27, "Close")])), new Bootstrap_Button_Option(2, onCloseClick)]), singleton_1(react.createElement("span", {
        ["aria-hidden"]: true,
    }, react.createElement("i", {
        className: join(" ", ["fas", "fa-times"]),
    }))))])), Bootstrap_Modal_Body_body(empty_1(), singleton_1(toArray_1(children))), Bootstrap_Modal_Footer_footer(empty_1(), singleton_1(Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, new Bootstrap_Button_ButtonClass(3)), new Bootstrap_Button_Option(2, onCloseClick)]), singleton_1("Lukk"))))]))))));
}

export function PageElements_errorModal(errMsg, dismiss) {
    let elms_1, elms;
    const props_7 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", dismiss])))), (elms_1 = singleton_1((elms = ofArray([createElement("p", {
        children: [errMsg],
    }), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", dismiss], ["children", "Lukk"]]))))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "modal-content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_7)));
}

