import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, array_type, int32_type, record_type, bool_type, string_type, option_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { UserProfileAccess$reflection, UserInfo$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { Functionality, Functionality__ToInt } from "../Shared/PermissionMapping.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, equals, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { map } from "../.fable/fable-library.3.2.4/Array.js";
import { loadingIndicatorLarge, snackbarError, loadingIndicatorSmall } from "../ViewHelpers.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { ProjectReferences } from "../Competence/ProjectReference.js";
import { UserEmployment } from "../Competence/Employment.js";
import { UserEducation } from "../Competence/Education.js";
import { UserCourses } from "../Competence/Course.js";
import { CvPrint } from "./CVPrint.js";
import { Identity } from "./Identity.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";

class MyPageView extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Identity", "ProjectReference", "Employments", "Education", "Course", "PrintCV", "ProfileAccess"];
    }
}

function MyPageView$reflection() {
    return union_type("MyPage.MyPageView", [], MyPageView, () => [[], [], [], [], [], [], []]);
}

class Model extends Record {
    constructor(User, ProfileAccess, CurrentView, ErrorMsg, IsOwn, CanEdit, CanDownloadCv) {
        super();
        this.User = User;
        this.ProfileAccess = ProfileAccess;
        this.CurrentView = CurrentView;
        this.ErrorMsg = ErrorMsg;
        this.IsOwn = IsOwn;
        this.CanEdit = CanEdit;
        this.CanDownloadCv = CanDownloadCv;
    }
}

function Model$reflection() {
    return record_type("MyPage.Model", [], Model, () => [["User", option_type(UserInfo$reflection())], ["ProfileAccess", option_type(UserProfileAccess$reflection())], ["CurrentView", MyPageView$reflection()], ["ErrorMsg", option_type(string_type)], ["IsOwn", bool_type], ["CanEdit", bool_type], ["CanDownloadCv", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UserResponse", "PermissionsResponse", "AccessResponse", "GotoTab", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("MyPage.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserInfo$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserInfo$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(int32_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(int32_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserProfileAccess$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserProfileAccess$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", MyPageView$reflection()]], []]);
}

function init(uidopt) {
    return new Model(void 0, void 0, new MyPageView(0), void 0, !(uidopt != null), !(uidopt != null), !(uidopt != null));
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.User, model.ProfileAccess, model.CurrentView, "Noe gikk galt. Kunne ikke laste rettigheter", model.IsOwn, model.CanEdit, model.CanDownloadCv);
            }
            else {
                const perms = res_1.fields[0];
                const editPermId = Functionality__ToInt(new Functionality(56)) | 0;
                const downloadCvPermId = Functionality__ToInt(new Functionality(57)) | 0;
                return new Model(model.User, model.ProfileAccess, model.CurrentView, model.ErrorMsg, model.IsOwn, perms.some((y) => (editPermId === y)), perms.some((y_1) => (downloadCvPermId === y_1)));
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.User, model.ProfileAccess, model.CurrentView, "Noe gikk galt. Fant ikke tilgang", model.IsOwn, model.CanEdit, model.CanDownloadCv);
            }
            else {
                return new Model(model.User, res_2.fields[0], model.CurrentView, model.ErrorMsg, model.IsOwn, model.CanEdit, model.CanDownloadCv);
            }
        }
        case 3: {
            if (model.User != null) {
                return new Model(model.User, model.ProfileAccess, msg.fields[0], void 0, model.IsOwn, model.CanEdit, model.CanDownloadCv);
            }
            else {
                return model;
            }
        }
        case 4: {
            return new Model(model.User, model.ProfileAccess, model.CurrentView, void 0, model.IsOwn, model.CanEdit, model.CanDownloadCv);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.User, model.ProfileAccess, model.CurrentView, "Noe gikk galt. Kunne ikke laste bruker-info", model.IsOwn, model.CanEdit, model.CanDownloadCv);
            }
            else {
                return new Model(res.fields[0], model.ProfileAccess, model.CurrentView, model.ErrorMsg, model.IsOwn, model.CanEdit, model.CanDownloadCv);
            }
        }
    }
}

function getLoggedInUserInfo(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserInfo$reflection,
        });
        return Fetch_tryGet_5760677E("/api/mypage/userinfo", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserInfo$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        const res = _arg1;
        dispatch(new Message(0, res));
        if (res.tag === 0) {
            const requestPath_2 = toText(printf("/api/mypage/hasaccess/%i"))(res.fields[0].Id);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: UserProfileAccess$reflection,
                });
                return Fetch_tryGet_5760677E(requestPath_2, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                    ResolveType: UserProfileAccess$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg2) => {
                dispatch(new Message(2, _arg2));
                return Promise.resolve();
            }));
        }
        else {
            return Promise.resolve();
        }
    })))));
}

function getOtherUserInfo(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("%s/%i"))("/api/mypage/userinfo")(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserInfo$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserInfo$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            const res = _arg1;
            dispatch(new Message(0, res));
            if (res.tag === 0) {
                const requestPath_2 = toText(printf("/api/mypage/hasaccess/%i"))(res.fields[0].Id);
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: UserProfileAccess$reflection,
                    });
                    return Fetch_tryGet_5760677E(requestPath_2, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                        ResolveType: UserProfileAccess$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg2) => {
                    dispatch(new Message(2, _arg2));
                    return Promise.resolve();
                }));
            }
            else {
                return Promise.resolve();
            }
        }));
    }));
}

function getRights(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/mypage/rights/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(int32_type),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(int32_type),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
}

function drawProfileAccess(dispatch, access) {
    return createElement("div", {
        style: {
            padding: 10 + "px",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("span", {
            className: "title is-3",
            children: "Personaltilgang",
        })), delay(() => {
            if (access != null) {
                const access_1 = access;
                return append(singleton(createElement("span", {
                    className: "title is-5",
                    children: "Se/rediger profil",
                })), delay(() => {
                    let elms, children_2;
                    return append(singleton((elms = ofArray([createElement("thead", {}), (children_2 = singleton_1(map((x) => {
                        let value_12;
                        const children = singleton_1((value_12 = toText(printf("%s - %s"))(x.role.title)(access_1.company.Name), createElement("td", {
                            children: [value_12],
                        })));
                        return createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        });
                    }, access_1.edit)), createElement("tbody", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    }))]), createElement("table", {
                        className: "table",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))), delay(() => append(singleton(createElement("span", {
                        className: "title is-5",
                        children: "Last ned CV",
                    })), delay(() => {
                        let elms_1, children_7;
                        return singleton((elms_1 = ofArray([createElement("thead", {}), (children_7 = singleton_1(map((x_1) => {
                            let value_20;
                            const children_5 = singleton_1((value_20 = toText(printf("%s - %s"))(x_1.role.title)(access_1.company.Name), createElement("td", {
                                children: [value_20],
                            })));
                            return createElement("tr", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_5)),
                            });
                        }, access_1.cv)), createElement("body", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_7)),
                        }))]), createElement("table", {
                            className: "table",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        })));
                    }))));
                }));
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        })))))),
    });
}

function myPageMenu(model, dispatch) {
    let children_10, props_4, props_10, props_16, props_22, props_28;
    const props_31 = ofArray([["className", "is-centered"], ["className", "is-boxed"], ["className", "is-small"], ["style", {
        ["max-width"]: "calc(100vw - 20px)",
    }], ["children", Interop_reactApi.Children.toArray([(children_10 = ofArray([(props_4 = toList(delay(() => append(singleton(["onClick", (_arg1) => {
        dispatch(new Message(3, new MyPageView(0)));
    }]), delay(() => append(equals(model.CurrentView, new MyPageView(0)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => {
        let children;
        return singleton(["children", Interop_reactApi.Children.toArray([(children = ofArray([createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-user",
            })]),
        }), createElement("span", {
            children: ["Identitet"],
        })]), createElement("a", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))])]);
    })))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_10 = toList(delay(() => append(singleton(["onClick", (_arg2) => {
        dispatch(new Message(3, new MyPageView(1)));
    }]), delay(() => append(equals(model.CurrentView, new MyPageView(1)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => {
        let children_2;
        return singleton(["children", Interop_reactApi.Children.toArray([(children_2 = ofArray([createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-history",
            })]),
        }), createElement("span", {
            children: ["Ref.prosjekt"],
        })]), createElement("a", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))])]);
    })))))), createElement("li", createObj(Helpers_combineClasses("", props_10)))), (props_16 = toList(delay(() => append(singleton(["onClick", (_arg3) => {
        dispatch(new Message(3, new MyPageView(2)));
    }]), delay(() => append(equals(model.CurrentView, new MyPageView(2)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => {
        let children_4;
        return singleton(["children", Interop_reactApi.Children.toArray([(children_4 = ofArray([createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-briefcase",
            })]),
        }), createElement("span", {
            children: ["Ansettelser"],
        })]), createElement("a", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))])]);
    })))))), createElement("li", createObj(Helpers_combineClasses("", props_16)))), (props_22 = toList(delay(() => append(singleton(["onClick", (_arg4) => {
        dispatch(new Message(3, new MyPageView(3)));
    }]), delay(() => append(equals(model.CurrentView, new MyPageView(3)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => {
        let children_6;
        return singleton(["children", Interop_reactApi.Children.toArray([(children_6 = ofArray([createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-graduation-cap",
            })]),
        }), createElement("span", {
            children: ["Utdanning"],
        })]), createElement("a", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))])]);
    })))))), createElement("li", createObj(Helpers_combineClasses("", props_22)))), (props_28 = toList(delay(() => append(singleton(["onClick", (_arg5) => {
        dispatch(new Message(3, new MyPageView(4)));
    }]), delay(() => append(equals(model.CurrentView, new MyPageView(4)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => {
        let children_8;
        return singleton(["children", Interop_reactApi.Children.toArray([(children_8 = ofArray([createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-book",
            })]),
        }), createElement("span", {
            children: ["Kurs og sertifiseringer"],
        })]), createElement("a", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        }))])]);
    })))))), createElement("li", createObj(Helpers_combineClasses("", props_28))))]), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("tabs", props_31)));
}

function drawMyCompetencies(model, dispatch, usr) {
    return createElement("div", {
        style: {
            maxWidth: 1000,
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            className: "title is-2",
            style: {
                textAlign: "center",
                display: "block",
            },
            children: "Kompetanseprofil",
        }), myPageMenu(model, dispatch), createElement("div", {
            style: {
                width: 100 + "%",
                maxWidth: 100 + "vw",
                padding: 10,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const matchValue = model.CurrentView;
                switch (matchValue.tag) {
                    case 1: {
                        return singleton(createElement(ProjectReferences, {
                            editPerm: model.CanEdit,
                            userInfo: usr,
                        }));
                    }
                    case 2: {
                        return singleton(createElement(UserEmployment, {
                            userId: usr.Id,
                            canEdit: model.CanEdit,
                        }));
                    }
                    case 3: {
                        return singleton(createElement(UserEducation, {
                            userId: usr.Id,
                            canEdit: model.CanEdit,
                        }));
                    }
                    case 4: {
                        return singleton(createElement(UserCourses, {
                            userId: usr.Id,
                            canEdit: model.CanEdit,
                        }));
                    }
                    case 5: {
                        return model.CanDownloadCv ? singleton(createElement(CvPrint, usr)) : singleton(createElement("div", {
                            style: {
                                width: 100 + "%",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                className: "title is-4",
                                children: "Du har ikke de nødvendige rettighetene til å skrive ut CV til denne personen.",
                            })]),
                        }));
                    }
                    case 6: {
                        return singleton(drawProfileAccess(dispatch, model.ProfileAccess));
                    }
                    default: {
                        return singleton(createElement(Identity, {
                            canedit: model.CanEdit,
                            uId: usr.Id,
                            uinfoOpt: usr,
                        }));
                    }
                }
            })))),
        })]),
    });
}

function view(model, dispatch) {
    const children = ofArray([snackbarError(model.ErrorMsg, () => {
        dispatch(new Message(4));
    }), createElement("div", {
        style: {
            maxWidth: 100 + "vw",
            margin: 0,
            display: "flex",
            justifyContent: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = model.User;
            if (matchValue == null) {
                return singleton(createElement("div", {
                    style: {
                        display: "flex",
                        width: 50 + "vw",
                        minWidth: 300,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 300,
                    },
                    children: Interop_reactApi.Children.toArray([loadingIndicatorLarge()]),
                }));
            }
            else {
                const usr = matchValue;
                return singleton(createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "center",
                        width: 100 + "%",
                    },
                    children: Interop_reactApi.Children.toArray([drawMyCompetencies(model, dispatch, usr)]),
                }));
            }
        })))),
    })]);
    return createElement("main", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Mypage(mypageInputProps) {
    const userId = mypageInputProps.userId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(userId));
    const dispatch = patternInput[1];
    if (userId == null) {
        useReact_useEffectOnce_3A5B6456(() => {
            const pr_2 = getLoggedInUserInfo(dispatch);
            pr_2.then();
        });
    }
    else {
        const i = userId | 0;
        useReact_useEffectOnce_3A5B6456(() => {
            const pr = getOtherUserInfo(i, dispatch);
            pr.then();
            const pr_1 = getRights(i, dispatch);
            pr_1.then();
        });
    }
    return view(patternInput[0], dispatch);
}

