import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, int32_type, record_type, string_type, bool_type, array_type, option_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { map as map_1, toArray, singleton, empty, ofArray } from "../.fable/fable-library.3.2.4/List.js";
import { Company$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { hasPermission } from "../LoginState.js";
import { Functionality } from "../Shared/PermissionMapping.js";
import { map, tryFind } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { equals, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { interpolate, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { op_UnaryNegation_Int32 } from "../.fable/fable-library.3.2.4/Int32.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import MenuItem from "@material-ui/core/MenuItem";
import { loadingIndicatorCenteredSmall, RotationAngle, rotatingChevronButton, loadingIndicatorSmall } from "../ViewHelpers.js";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Allocations } from "./CompanyAllocation.js";
import { CompanyPermission } from "./CompanyPermission.js";
import { Roles } from "./CompanyRole.js";
import { Overview } from "./Company.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { Company_fetchCompanies } from "../Promises.js";

class CompanyViews extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Overview", "Role", "Permission", "Allocation"];
    }
}

function CompanyViews$reflection() {
    return union_type("CompanyAdmin.CompanyViews", [], CompanyViews, () => [[], [], [], []]);
}

const getAllViews = ofArray([new CompanyViews(0), new CompanyViews(3)]);

function mapViewToString(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "Roller";
        }
        case 2: {
            return "Rettigheter";
        }
        case 3: {
            return "Belegging";
        }
        default: {
            return "Oversikt";
        }
    }
}

class Model extends Record {
    constructor(SelectedTab, Company, Companies, ShowDropdown, ErrorMsg, DrawerOpen) {
        super();
        this.SelectedTab = SelectedTab;
        this.Company = Company;
        this.Companies = Companies;
        this.ShowDropdown = ShowDropdown;
        this.ErrorMsg = ErrorMsg;
        this.DrawerOpen = DrawerOpen;
    }
}

function Model$reflection() {
    return record_type("CompanyAdmin.Model", [], Model, () => [["SelectedTab", CompanyViews$reflection()], ["Company", option_type(Company$reflection())], ["Companies", option_type(array_type(Company$reflection()))], ["ShowDropdown", bool_type], ["ErrorMsg", option_type(string_type)], ["DrawerOpen", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompaniesResponse", "LoggedInCompanyResponse", "SelectedCompanyResponse", "SelectCompany", "GotoView", "ToggleDrawer"];
    }
}

function Message$reflection() {
    return union_type("CompanyAdmin.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Company$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Company$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Company$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Company$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", int32_type]], [["Item", CompanyViews$reflection()]], []]);
}

function init() {
    return new Model(new CompanyViews(0), void 0, void 0, hasPermission(new Functionality(66)), void 0, true);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.SelectedTab, model.Company, model.Companies, model.ShowDropdown, "Noe gikk galt. \n                Fant ingen selskap tilhørende til innlogget bruker", model.DrawerOpen);
            }
            else {
                return new Model(model.SelectedTab, res_1.fields[0], model.Companies, model.ShowDropdown, model.ErrorMsg, model.DrawerOpen);
            }
        }
        case 0: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.SelectedTab, model.Company, res_2.fields[0], model.ShowDropdown, model.ErrorMsg, model.DrawerOpen);
            }
        }
        case 3: {
            const matchValue = model.Companies;
            if (matchValue == null) {
                return model;
            }
            else {
                const matchValue_1 = tryFind((e) => (e.Id === msg.fields[0]), matchValue);
                if (matchValue_1 == null) {
                    return model;
                }
                else {
                    return new Model(model.SelectedTab, matchValue_1, model.Companies, model.ShowDropdown, model.ErrorMsg, model.DrawerOpen);
                }
            }
        }
        case 5: {
            return new Model(model.SelectedTab, model.Company, model.Companies, model.ShowDropdown, model.ErrorMsg, !model.DrawerOpen);
        }
        case 4: {
            if (model.Company != null) {
                return new Model(msg.fields[0], model.Company, model.Companies, model.ShowDropdown, model.ErrorMsg, model.DrawerOpen);
            }
            else {
                return model;
            }
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.SelectedTab, model.Company, model.Companies, model.ShowDropdown, "Noe gikk galt. Fant ikke selskapet.", model.DrawerOpen);
            }
            else {
                return new Model(model.SelectedTab, res.fields[0], model.Companies, model.ShowDropdown, model.ErrorMsg, model.DrawerOpen);
            }
        }
    }
}

function getLoggedInCompany(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Company$reflection,
        });
        return Fetch_tryGet_5760677E("/api/company/ofuser", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Company$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
}

function getCompanyById(cid, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const path = toText(printf("/api/company/%i"))(cid);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Company$reflection,
            });
            return Fetch_tryGet_5760677E(path, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Company$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let arg10_1, arg10_2;
    let o;
    const theme = arg;
    const styles = StyleCreator$1_$ctor();
    const root = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "flex"]));
    const appBar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["zIndex", 20], ["width", toText(printf("calc(100vw - %ipx)"))(70)], ["left", 70], ["boxShadow", "none"], ["borderBottom", (((1 + "px ") + "solid") + " ") + "#dedede"]]));
    const appBarShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", (arg10_1 = ((250 + 70) | 0), toText(printf("calc(100%% - %ipx)"))(arg10_1))], ["marginLeft", 250 + 70], ["left", 0]]));
    const toolbar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#2F4F4F"], ["backgroundColor", "#F5F5F5"]]));
    const menuButton = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginRight", theme.spacing(2)], ["color", "#2F4F4F"]]));
    const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "none"]));
    const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 250], ["flexShrink", 0]]));
    const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["overflowY", "hidden"], ["width", 250], ["left", 70]]));
    const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, toList(delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["alignItems", "center"]), delay(() => append(singleton_1(["padding", theme.spacing(0, 1)]), delay(() => append(Object.entries(theme.mixins.toolbar), delay(() => singleton_1(["justifyContent", "flex-end"]))))))))))));
    const content = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", op_UnaryNegation_Int32(250)], ["width", "calc(100vw - 30px)"], ["minWidth", 400], ["backgroundColor", "#fff"], ["overflowY", "auto"], ["height", 100 + "vh"]]));
    const contentShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", 0], ["width", (arg10_2 = ((250 + 30) | 0), toText(printf("calc(100vw - %ipx)"))(arg10_2))]]));
    o = {
        appBar: appBar,
        appBarShift: appBarShift,
        buttonChevron: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["transitionProperty", "transform"], ["transitionDuration", 300 + "ms"], ["fontSize", 1 + "rem"]])),
        collapseButtonDiv: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["cursor", "pointer"], ["width", 100 + "%"], ["display", "flex"], ["height", 48], ["alignItems", "center"]])),
        content: content,
        contentShift: contentShift,
        drawer: drawer,
        drawerHeader: drawerHeader,
        drawerPaper: drawerPaper,
        hide: hide,
        menuButton: menuButton,
        root: root,
        toolbar: toolbar,
    };
    return Object.assign({}, o);
});

function view(model, dispatch) {
    let selector, matchValue, companies;
    const s = useStyles();
    const matchValue_1 = model.Company;
    if (matchValue_1 != null) {
        const company_2 = matchValue_1;
        return createElement("div", {
            className: s.root,
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CssBaseline, []), MuiHelpers_createElement(AppBar, [["className", model.DrawerOpen ? toText(printf("%s %s"))(s.appBar)(s.appBarShift) : s.appBar], ["position", "absolute"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Toolbar, [["className", s.toolbar], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["className", model.DrawerOpen ? toText(printf("%s %s"))(s.menuButton)(s.hide) : s.menuButton], ["edge", "start"], ["onClick", (_arg2) => {
                dispatch(new Message(5));
            }], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Menu, {})])]])]]), MuiHelpers_createElement(Typography, [["children", company_2.Name]])])]])])]]), (selector = ((matchValue = model.Companies, (matchValue != null) ? ((companies = matchValue, MuiHelpers_createElement(FormControl, [["style", {
                width: 90 + "%",
                marginLeft: 16,
                marginTop: 16,
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Velg selskap"]]), MuiHelpers_createElement(Select, [["fullWidth", true], ["value", company_2.Id], ["onChange", (e, _arg24) => {
                dispatch(new Message(3, Browser_Types_Event__Event_get_Value(e)));
            }], ["children", Interop_reactApi.Children.toArray([map((x) => MuiHelpers_createElement(MenuItem, [["value", x.Id], ["children", x.Name], ["href", toText(interpolate("/company/%P()", [x.Id]))]]), companies)])]])])]]))) : loadingIndicatorSmall())), MuiHelpers_createElement(Drawer, [["className", s.drawer], ["classes.paper", s.drawerPaper], ["variant", "persistent"], ["anchor", "left"], ["open", model.DrawerOpen], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", {
                className: s.drawerHeader,
                children: Interop_reactApi.Children.toArray([rotatingChevronButton(new RotationAngle(1), () => {
                    dispatch(new Message(5));
                })]),
            })), delay(() => append(singleton_1(MuiHelpers_createElement(Divider, [])), delay(() => append(model.ShowDropdown ? singleton_1(selector) : empty_1(), delay(() => singleton_1(MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray([toArray(map_1((x_1) => {
                const isActive = equals(model.SelectedTab, x_1);
                const title = mapViewToString(x_1);
                return MuiHelpers_createElement(ListItem, [["button", true], ["dense", false], ["selected", isActive], ["key", title], ["onClick", (_arg1) => {
                    dispatch(new Message(4, x_1));
                }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", title]])])]]);
            }, getAllViews))])]]))))))))))))]])), createElement("main", {
                className: model.DrawerOpen ? toText(interpolate("%P() %P()", [s.content, s.contentShift])) : s.content,
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: s.drawerHeader,
                }), createElement("div", {
                    style: {
                        alignContent: "center",
                        overflowX: "auto",
                        paddingBottom: 1 + "rem",
                    },
                    key: company_2.Id,
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        const matchValue_2 = model.SelectedTab;
                        switch (matchValue_2.tag) {
                            case 3: {
                                return singleton_1(createElement(Allocations, {
                                    companyId: company_2.Id,
                                }));
                            }
                            case 2: {
                                return singleton_1(createElement(CompanyPermission, {
                                    companyId: company_2.Id,
                                }));
                            }
                            case 1: {
                                return singleton_1(createElement(Roles, company_2));
                            }
                            default: {
                                return singleton_1(createElement(Overview, company_2));
                            }
                        }
                    })))),
                })]),
            })]),
        });
    }
    else {
        return loadingIndicatorCenteredSmall();
    }
}

export function Company(companyInputProps) {
    const companyId = companyInputProps.companyId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = (companyId == null) ? getLoggedInCompany(dispatch) : getCompanyById(companyId, dispatch);
        pr.then();
        if (model_1.ShowDropdown) {
            Company_fetchCompanies((arg) => {
                dispatch(new Message(0, arg));
            });
        }
    });
    return view(model_1, dispatch);
}

