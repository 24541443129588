import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Competence_ResourceSearchEntry$reflection, Competence_SkillLevel$reflection, Competence_Course$reflection, Competence_Skill$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, int32_type, union_type, option_type, string_type, record_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { printf, toText, join } from "../.fable/fable-library.3.2.4/String.js";
import { sortByDescending, append, map } from "../.fable/fable-library.3.2.4/Array.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { comparePrimitives, compareArrays, uncurry, numberHash } from "../.fable/fable-library.3.2.4/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { createElement } from "react";
import * as react from "react";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { skillPillNoLevel, skillPill, skillPillNoLevelDelete } from "../Administration/Skill.js";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import Search from "@material-ui/icons/Search";
import { Competence_fetchSkillLevels } from "../Promises.js";

export class ResourceQuery extends Record {
    constructor(Skills, Courses) {
        super();
        this.Skills = Skills;
        this.Courses = Courses;
    }
}

export function ResourceQuery$reflection() {
    return record_type("ResourceSearch.ResourceQuery", [], ResourceQuery, () => [["Skills", array_type(Competence_Skill$reflection())], ["Courses", array_type(Competence_Course$reflection())]]);
}

export function ResourceQuery_Empty() {
    return new ResourceQuery([], []);
}

export function ResourceQuery__ToRequestQuery(this$) {
    return ("?" + ((this$.Skills.length === 0) ? "" : join("", map((x) => toText(printf("\u0026skill=%i"))(x.Id), this$.Skills)))) + ((this$.Courses.length === 0) ? "" : join("", map((x_1) => toText(printf("\u0026course=%i"))(x_1.Id), this$.Courses)));
}

class Model extends Record {
    constructor(Query, SkillLevels, Users, ErrorMsg) {
        super();
        this.Query = Query;
        this.SkillLevels = SkillLevels;
        this.Users = Users;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ResourceSearch.Model", [], Model, () => [["Query", ResourceQuery$reflection()], ["SkillLevels", array_type(Competence_SkillLevel$reflection())], ["Users", array_type(Competence_ResourceSearchEntry$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LevelsResponse", "UpdateQuery", "UsersResponse", "SelectSkill", "RemoveSkill", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ResourceSearch.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Competence_SkillLevel$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Competence_SkillLevel$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", ResourceQuery$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Competence_ResourceSearchEntry$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Competence_ResourceSearchEntry$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", Competence_Skill$reflection()]], [["Item", int32_type]], []]);
}

function init() {
    return new Model(ResourceQuery_Empty(), [], [], void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(msg.fields[0], model.SkillLevels, model.Users, model.ErrorMsg);
        }
        case 2: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Query, model.SkillLevels, model.Users, "Kunne ikke laste brukere");
            }
            else {
                return new Model(model.Query, model.SkillLevels, res_1.fields[0], model.ErrorMsg);
            }
        }
        case 3: {
            return new Model(new ResourceQuery(Array_distinctBy((z) => z.Id, append([msg.fields[0]], model.Query.Skills), {
                Equals: (x_4, y) => (x_4 === y),
                GetHashCode: (x_4) => numberHash(x_4),
            }), model.Query.Courses), model.SkillLevels, model.Users, model.ErrorMsg);
        }
        case 4: {
            return new Model(new ResourceQuery(model.Query.Skills.filter((s) => (s.Id !== msg.fields[0])), model.Query.Courses), model.SkillLevels, model.Users, model.ErrorMsg);
        }
        case 5: {
            return new Model(model.Query, model.SkillLevels, model.Users, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return model;
            }
            else {
                return new Model(model.Query, res.fields[0], model.Users, model.ErrorMsg);
            }
        }
    }
}

function searchResources(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(1, x));
        const requestQuery = ResourceQuery__ToRequestQuery(x);
        const requestPath = toText(printf("/api/resource-search/search%s"))(requestQuery);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Competence_ResourceSearchEntry$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Competence_ResourceSearchEntry$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function noContent(x) {
    return createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
            color: "#9a9a9a",
            fontStyle: "italic",
        },
        children: x,
    });
}

export function SkillSelect(skillSelectInputProps) {
    const onSelect = skillSelectInputProps.onSelect;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput[1];
    const active = patternInput[0];
    const anchor = useReact_useRef_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([]);
    const updateSearchResult = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(0);
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["children", react.createElement(Add, {})], ["size", "small"], ["ref", anchor], ["onClick", (_arg1_1) => {
            setActive(!active);
        }]]), MuiHelpers_createElement(Popper, [["open", active], ["anchorEl", () => anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ClickAwayListener, [["onClickAway", (_arg2) => {
            setActive(false);
        }], ["children", createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                width: 200,
                padding: 5,
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["autoFocus", true], ["fullWidth", true], ["size", "small"], ["variant", "outlined"], ["label", "Søk i ferdigheter"], ["onChange", (e) => {
                const x_2 = Browser_Types_Event__Event_get_Value(e);
                window.clearTimeout(patternInput_2[0]);
                if (x_2 === "") {
                    updateSearchResult([]);
                }
                else {
                    patternInput_2[1](window.setTimeout((_arg3) => {
                        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: () => string_type,
                            })(x_2);
                            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: () => array_type(Competence_Skill$reflection()),
                            });
                            return Fetch_tryPost_5760677E("/api/skill/search", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                ResolveType: () => array_type(Competence_Skill$reflection()),
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg1) => {
                            const res = _arg1;
                            if (res.tag === 0) {
                                updateSearchResult(res.fields[0]);
                                return Promise.resolve();
                            }
                            else {
                                return Promise.resolve();
                            }
                        })))));
                        pr.then();
                    }, 500));
                }
            }], ["onKeyDown", (kc) => {
                if (kc.key === "Escape") {
                    kc.preventDefault();
                    setActive(false);
                    updateSearchResult([]);
                }
            }]]), MuiHelpers_createElement(List, [["dense", true], ["children", Interop_reactApi.Children.toArray([map((s) => MuiHelpers_createElement(ListItem, [["dense", true], ["button", true], ["onClick", (_arg4) => {
                setActive(false);
                onSelect(s);
                updateSearchResult([]);
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", s.Name]])])]]), patternInput_1[0])])]])]),
        })]])])]])])]])]),
    });
}

export function CourseSelect(courseSelectInputProps) {
    const onSelect = courseSelectInputProps.onSelect;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput[1];
    const active = patternInput[0];
    const anchor = useReact_useRef_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([]);
    const updateSearchResult = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(0);
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["children", react.createElement(Add, {})], ["size", "small"], ["ref", anchor], ["onClick", (_arg1_1) => {
            setActive(!active);
        }]]), MuiHelpers_createElement(Popper, [["open", active], ["anchorEl", () => anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ClickAwayListener, [["onClickAway", (_arg2) => {
            setActive(false);
        }], ["children", createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                width: 200,
                padding: 5,
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["autoFocus", true], ["fullWidth", true], ["size", "small"], ["variant", "outlined"], ["label", "Søk i kurs"], ["onChange", (e) => {
                const x_2 = Browser_Types_Event__Event_get_Value(e);
                window.clearTimeout(patternInput_2[0]);
                if (x_2 === "") {
                    updateSearchResult([]);
                }
                else {
                    patternInput_2[1](window.setTimeout((_arg3) => {
                        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: () => string_type,
                            })(x_2);
                            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: () => array_type(Competence_Course$reflection()),
                            });
                            return Fetch_tryPost_5760677E("/api/course/search", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                ResolveType: () => array_type(Competence_Course$reflection()),
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg1) => {
                            const res = _arg1;
                            if (res.tag === 0) {
                                updateSearchResult(res.fields[0]);
                                return Promise.resolve();
                            }
                            else {
                                return Promise.resolve();
                            }
                        })))));
                        pr.then();
                    }, 500));
                }
            }], ["onKeyDown", (kc) => {
                if (kc.key === "Escape") {
                    kc.preventDefault();
                    setActive(false);
                    updateSearchResult([]);
                }
            }]]), MuiHelpers_createElement(List, [["dense", true], ["children", Interop_reactApi.Children.toArray([map((s) => MuiHelpers_createElement(ListItem, [["dense", true], ["button", true], ["onClick", (_arg4) => {
                setActive(false);
                onSelect(s);
                updateSearchResult([]);
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", s.Name]])])]]), patternInput_1[0])])]])]),
        })]])])]])])]])]),
    });
}

function drawSkillFilter(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            marginRight: 50,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    marginRight: 5,
                },
                children: "Valgte ferdigheter",
            }), createElement(SkillSelect, {
                onSelect: (skill) => {
                    searchResources(new ResourceQuery(Array_distinctBy((z) => z.Id, append([skill], model.Query.Skills), {
                        Equals: (x, y) => (x === y),
                        GetHashCode: (x) => numberHash(x),
                    }), model.Query.Courses), dispatch);
                },
            })]),
        }), createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
                maxWidth: 450,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1((model.Query.Skills.length === 0) ? singleton(noContent("Ingen valgt")) : empty_1(), delay(() => singleton(map((s) => skillPillNoLevelDelete(s.Name, () => {
                searchResources(new ResourceQuery(model.Query.Skills.filter((a) => (a.Id !== s.Id)), model.Query.Courses), dispatch);
            }), model.Query.Skills)))))))),
        })]),
    });
}

function drawCourseFilter(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            marginRight: 50,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    marginRight: 5,
                },
                children: "Valgte kurs",
            }), createElement(CourseSelect, {
                onSelect: (course) => {
                    searchResources(new ResourceQuery(model.Query.Skills, Array_distinctBy((z) => z.Id, append([course], model.Query.Courses), {
                        Equals: (x, y) => (x === y),
                        GetHashCode: (x) => numberHash(x),
                    })), dispatch);
                },
            })]),
        }), createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
                maxWidth: 450,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1((model.Query.Courses.length === 0) ? singleton(noContent("Ingen valgt")) : empty_1(), delay(() => singleton(map((s) => skillPillNoLevelDelete(s.Name, () => {
                searchResources(new ResourceQuery(model.Query.Skills, model.Query.Courses.filter((a) => (a.Id !== s.Id))), dispatch);
            }), model.Query.Courses)))))))),
        })]),
    });
}

function drawFilterBar(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            borderBottom: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
            paddingBottom: 20,
        },
        children: Interop_reactApi.Children.toArray([drawSkillFilter(model, dispatch), drawCourseFilter(model, dispatch)]),
    });
}

function userCard(x) {
    return MuiHelpers_createElement(Paper, [["style", {
        margin: 5,
        padding: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 300,
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(Avatar, [["style", {
        width: 150,
        height: 150,
    }], ["src", toText(printf("/api/user/profilepicture/%i"))(x.User.Id)], ["alt", "Profilbilde"]])), delay(() => append_1(singleton(createElement("span", {
        children: toText(printf("%s %s "))(x.User.Firstname)(x.User.Lastname),
    })), delay(() => append_1(singleton(createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
        },
        children: toText(printf("%s, %s"))(x.User.Company)(x.User.Office),
    })), delay(() => append_1((x.Skills.length > 0) ? singleton(createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            width: 100 + "%",
            marginTop: 5,
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
            },
            children: "Utvalgte ferdigheter",
        }), createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
            },
            children: Interop_reactApi.Children.toArray([map((s_1) => skillPill(s_1.Skill.Name, s_1.Level.Name, s_1.Level.ColorCode, s_1.Level.TextColorCode), sortByDescending((s) => [s.Level.Weight, s.Skill.Name], x.Skills, {
                Compare: (x_1, y) => compareArrays(x_1, y),
            }))]),
        })]),
    })) : empty_1(), delay(() => ((x.Courses.length > 0) ? singleton(createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            width: 100 + "%",
            marginTop: 5,
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
            },
            children: "Utvalgte kurs",
        }), createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
            },
            children: Interop_reactApi.Children.toArray([map((c) => skillPillNoLevel(c.Course.Name), sortByDescending((s_2) => s_2.Course.Name, x.Courses, {
                Compare: (x_2, y_1) => comparePrimitives(x_2, y_1),
            }))]),
        })]),
    })) : empty_1())))))))))))))]]);
}

function drawUserEntries(model, dispatch) {
    return createElement("div", {
        style: {
            padding: 20,
            display: "flex",
            width: 100 + "%",
            flexWrap: "wrap",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(map((u_1) => userCard(u_1), sortByDescending((u) => u.Skills.length, model.Users, {
            Compare: (x, y) => comparePrimitives(x, y),
        }))), delay(() => ((model.Users.length === 0) ? singleton(createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                width: 100 + "%",
                height: 100 + "%",
                minHeight: 300,
                alignItems: "center",
                justifyContent: "center",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["style", {
                width: 5 + "rem",
                height: 5 + "rem",
                color: "#aaa",
            }], ["children", react.createElement(Search, {
                style: {
                    width: 5 + "rem",
                    height: 5 + "rem",
                },
            })]]), createElement("span", {
                children: "Valgt filter ga ingen resultat.",
            }), createElement("span", {
                children: "",
            })]),
        })) : empty_1()))))))),
    });
}

export function ResourceSearch() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        Competence_fetchSkillLevels((arg) => {
            dispatch(new Message(0, arg));
        });
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            padding: 20,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
                width: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: "Ressurssøk",
            })]),
        }), drawFilterBar(model_1, dispatch), drawUserEntries(model_1, dispatch)]),
    });
}

