import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.68.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.68.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import { ProjectRegistration, sectionTitle } from "./NonconformityRegistration.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { nb } from "date-fns/locale";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { bind, some, defaultArg } from "../.fable/fable-library.3.2.4/Option.js";
import { singleton as singleton_1, empty, ofArray } from "../.fable/fable-library.3.2.4/List.js";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import date$002Dfns from "@date-io/date-fns";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { interpolate, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { tryParseDateString, fromUtcString, fromUtcToLocalString, toUtcString } from "../Utils.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { union_type, obj_type, anonRecord_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Nonconformity_ProjectRegistration, Nonconformity_ProjectAction$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { int32ToString, compareArrays, compare as compare_1, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { sortByDescending, sortBy as sortBy_1, map } from "../.fable/fable-library.3.2.4/Array.js";
import Chip from "@material-ui/core/Chip";
import { loadingIndicatorSmall, snackbarWarn, Text_secondaryText } from "../ViewHelpers.js";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import { now, compare } from "../.fable/fable-library.3.2.4/Date.js";
import Modal from "@material-ui/core/Modal";
import { toString, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { Project_fetchLoggedInUserPermissions, Nonconformity_fetchCategories, Nonconformity_fetchProjectRegistrations } from "../Promises.js";
import { Functionality, Functionality__ToInt } from "../Shared/PermissionMapping.js";
import { hasPermission } from "../LoginState.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export function ActionForm(actionFormInputProps) {
    let props_4, el;
    const onClose = actionFormInputProps.onClose;
    const registrationId = actionFormInputProps.registrationId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const actionTime = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const desc = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_3[1];
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        onClose(void 0);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Registrer tiltak"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
        },
        children: Interop_reactApi.Children.toArray([sectionTitle("Beskrivelse av tiltak"), MuiHelpers_createElement(TextField, [["variant", "outlined"], ["size", "small"], ["label", "Beskrivelse"], ["autoFocus", true], ["onChange", (e) => {
            patternInput_1[1](Browser_Types_Event__Event_get_Value(e));
        }], ["defaultValue", desc], ["multiline", true]]), sectionTitle("Tidspunkt for gjennomført tiltak"), (props_4 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DateTimePicker, [["value", defaultArg(actionTime, null)], ["autoOk", true], ["variant", "inline"], ["onChange", (d) => {
            if (d == null) {
            }
            else {
                patternInput[1](d);
            }
        }], ["inputVariant", "outlined"], ["inputProps", {
            className: "MuiInputBase-inputMarginDense \n                                            MuiOutlinedInput-inputMarginDense",
        }]])])]]), (el = MuiPickersUtilsProvider, MuiHelpers_createElement(el, toList(delay(() => {
            let value_33;
            return append(singleton((value_33 = date$002Dfns, ["utils", value_33])), delay(() => props_4));
        })))))]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        onClose(void 0);
    }]]), MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["children", "Registrer"], ["disabled", (patternInput_3[0] ? true : (desc.length === 0)) ? true : (actionTime == null)], ["onClick", (_arg3) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let item;
            if (actionTime != null) {
                const at = actionTime;
                setProcessing(true);
                const requestPath = toText(printf("/api/nonconformity/project/action/%i"))(registrationId);
                const time = toUtcString(at);
                return ((item = {
                    ActionTime: time,
                    Desc: desc,
                }, PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                        ResolveType: () => anonRecord_type(["ActionTime", string_type], ["Desc", string_type]),
                    })(item);
                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: Nonconformity_ProjectAction$reflection,
                    });
                    return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                        ResolveType: Nonconformity_ProjectAction$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                })))).then(((_arg1) => {
                    const res = _arg1;
                    if (res.tag === 1) {
                        patternInput_2[1]("Kunne ikke registrere tiltak. Vennligst prøv igjen senere");
                        setProcessing(false);
                        return Promise.resolve();
                    }
                    else {
                        onClose(res.fields[0]);
                        return Promise.resolve();
                    }
                }));
            }
            else {
                return Promise.resolve();
            }
        }));
        pr.then();
    }]])])]])])]]);
}

function drawRegCategories(x) {
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(map((c) => MuiHelpers_createElement(Chip, [["label", c.Name], ["style", {
                margin: 3,
            }]]), x.Categories)), delay(() => ((x.Categories.length === 0) ? singleton(Text_secondaryText("Ingen kategorier registrert")) : empty_1()))))))),
        }), createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
            },
            children: Interop_reactApi.Children.toArray([Text_secondaryText("Registrert av"), createElement("span", {
                children: toText(printf("%s %s"))(x.User.Firstname)(x.User.Lastname),
            })]),
        })]),
    });
}

export function drawRegistration(x, canEdit, showProject, startAction, onSelectImage) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            marginBottom: 2 + "vw",
            padding: 10,
            borderRadius: 5,
            border: (((2 + "px ") + "solid") + " ") + "#2d3a48",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(showProject ? singleton(createElement("div", {
            style: {
                paddingLeft: 5,
            },
            children: Interop_reactApi.Children.toArray([createElement("a", {
                href: toText(interpolate("/project/%P()", [x.ProjectId])),
                style: {
                    fontSize: 1.2 + "rem",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    children: [x.Project],
                })]),
            })]),
        })) : empty_1(), delay(() => append(singleton(createElement("div", {
            style: {
                borderBottom: (((2 + "px ") + "solid") + " ") + "#c1c1c1",
                paddingBottom: 10,
            },
            children: Interop_reactApi.Children.toArray([drawRegCategories(x)]),
        })), delay(() => {
            let arg10;
            return append(singleton(createElement("div", {
                style: {
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {
                        padding: ((10 + "px ") + 0) + "px",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        style: {
                            marginBottom: 5,
                        },
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            style: {
                                fontSize: 0.85 + "rem",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                className: "fas fa-calendar-alt",
                                style: {
                                    marginRight: 5,
                                },
                            }), (arg10 = fromUtcToLocalString(x.IncidentTime), toText(printf("Tidspunkt for hendelse: %s"))(arg10))]),
                        })]),
                    }), createElement("span", {
                        children: x.Desc,
                    })]),
                }), createElement("div", {
                    style: {
                        padding: ((10 + "px ") + 0) + "px",
                        display: "flex",
                        flexWrap: "wrap",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((x.Images.length === 0) ? singleton(Text_secondaryText("Ingen bilder lagt ved")) : empty_1(), delay(() => singleton(map((i) => {
                        const url = toText(printf("/api/nonconformity/project/image/%i?size=small"))(i);
                        return createElement("div", {
                            style: {
                                width: 120,
                                height: 120,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                                margin: 5,
                            },
                            children: Interop_reactApi.Children.toArray([createElement("img", {
                                style: {
                                    maxWidth: 100 + "%",
                                    maxHeight: 100 + "%",
                                    ["box-shadow"]: "2px 2px 4px rgba(0,0,0,0.1), 4px 4px 4px rgba(0,0,0,0.2)",
                                    borderRadius: 5,
                                    cursor: "pointer",
                                },
                                onClick: (_arg1) => {
                                    onSelectImage(i);
                                },
                                src: url,
                            })]),
                        });
                    }, x.Images)))))))),
                })]),
            })), delay(() => singleton(createElement("div", {
                style: {},
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let arg10_6;
                    const responsible = () => {
                        const matchValue = x.Responsible;
                        if (matchValue != null) {
                            const y = matchValue;
                            return createElement("div", {
                                style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: ((0 + "px ") + 10) + "px",
                                },
                                children: Interop_reactApi.Children.toArray([Text_secondaryText("Ansvarlig for tiltak"), createElement("div", {
                                    style: {
                                        display: "flex",
                                        alignItems: "center",
                                    },
                                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Avatar, [["alt", toText(printf("Bilde %s"))(y.Firstname)], ["src", toText(printf("/api/user/profilepicture/%i"))(y.Id)]]), createElement("span", {
                                        style: {
                                            marginLeft: 10,
                                        },
                                        children: toText(printf("%s %s"))(y.Firstname)(y.Lastname),
                                    })]),
                                })]),
                            });
                        }
                        else {
                            return null;
                        }
                    };
                    const matchValue_1 = x.Action;
                    if (matchValue_1 != null) {
                        const action = matchValue_1;
                        return singleton(createElement("div", {
                            style: {
                                border: (((2 + "px ") + "solid") + " ") + "#008000",
                                backgroundColor: "#f6fff6",
                                padding: 5,
                                borderRadius: 5,
                                display: "flex",
                                flexDirection: "column",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                style: {
                                    color: "#008000",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    className: "fas fa-check-circle",
                                    style: {
                                        marginRight: 5,
                                    },
                                }), "Tiltak er gjennomført"]),
                            }), createElement("span", {
                                style: {
                                    color: "#9a9a9a",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    className: "fas fa-clock",
                                    style: {
                                        marginRight: 5,
                                    },
                                }), (arg10_6 = fromUtcToLocalString(action.ActionTime), toText(printf("Gjennomført %s"))(arg10_6))]),
                            }), createElement("span", {
                                style: {
                                    marginTop: 10,
                                },
                                children: action.Desc,
                            }), responsible()]),
                        }));
                    }
                    else {
                        return singleton(createElement("div", {
                            style: {
                                border: (((2 + "px ") + "solid") + " ") + "#2d3a48",
                                padding: 5,
                                borderRadius: 5,
                                display: "flex",
                                flexDirection: "column",
                            },
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
                                style: {
                                    display: "flex",
                                    alignItems: "center",
                                },
                                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("span", {
                                    style: {
                                        color: "#9a9a9a",
                                        marginLeft: 10,
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("span", {
                                        className: "fas fa-info-circle",
                                        style: {
                                            marginRight: 5,
                                        },
                                    }), "Ingen tiltak gjennomført"]),
                                })), delay(() => (canEdit ? singleton(MuiHelpers_createElement(Tooltip, [["title", "Registrer tiltak"], ["children", MuiHelpers_createElement(IconButton, [["style", {
                                    marginLeft: 10,
                                }], ["children", react.createElement(Add, {})], ["onClick", (_arg2) => {
                                    startAction(x.Id);
                                }]])]])) : empty_1()))))))),
                            })), delay(() => {
                                let matchValue_2, ad, beyondDeadline, arg10_5;
                                return append((matchValue_2 = x.ActionDeadline, (matchValue_2 != null) ? ((ad = matchValue_2, (beyondDeadline = (compare(fromUtcString(ad), now()) < 0), singleton(createElement("div", {
                                    style: {
                                        paddingBottom: 10,
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("span", {
                                        style: createObj(toList(delay(() => append(singleton(["marginLeft", 10]), delay(() => (beyondDeadline ? singleton(["color", "#FF0000"]) : singleton(["color", "#9a9a9a"]))))))),
                                        children: Interop_reactApi.Children.toArray([createElement("span", {
                                            className: "fas fa-clock",
                                            style: {
                                                marginRight: 5,
                                            },
                                        }), (arg10_5 = fromUtcToLocalString(ad), toText(printf("Frist: %s"))(arg10_5))]),
                                    })]),
                                }))))) : ((empty_1()))), delay(() => singleton(responsible())));
                            })))))),
                        }));
                    }
                })))),
            }))));
        })))))))),
    });
}

export function ImagePreview(imagePreviewInputProps) {
    let children;
    const onClose = imagePreviewInputProps.onClose;
    const imageId = imagePreviewInputProps.imageId;
    return MuiHelpers_createElement(Modal, [["open", true], ["onClose", (_arg17, v) => {
        onClose();
    }], ["children", (children = singleton_1(createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 100 + "vw",
            height: 100 + "vh",
        },
        onClick: (_arg2) => {
            onClose();
        },
        children: Interop_reactApi.Children.toArray([createElement("img", {
            style: {
                maxWidth: 90 + "vw",
                maxHeight: 90 + "vh",
            },
            src: toText(printf("/api/nonconformity/project/image/%i?size=small"))(imageId),
        })]),
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))]]);
}

class SortBy extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EarliestHappened", "LatestHappened", "EarliestReg", "LatestReg", "EarliestDeadline"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 0: {
                return "Tidspunkt tidligst";
            }
            case 1: {
                return "Tidspunkt senest";
            }
            case 2: {
                return "Registrert tidligst";
            }
            case 3: {
                return "Registrert senest";
            }
            default: {
                return "Tiltaksfrist";
            }
        }
    }
}

function SortBy$reflection() {
    return union_type("ProjectNonconformity.SortBy", [], SortBy, () => [[], [], [], [], []]);
}

function SortBy__get_ToInt(this$) {
    switch (this$.tag) {
        case 0: {
            return 2;
        }
        case 1: {
            return 3;
        }
        case 2: {
            return 4;
        }
        case 3: {
            return 5;
        }
        default: {
            return 1;
        }
    }
}

function SortBy__get_SortArray(this$) {
    switch (this$.tag) {
        case 0: {
            return (array_1) => sortBy_1((y_2) => tryParseDateString(y_2.IncidentTime), array_1, {
                Compare: (x_1, y_3) => compare_1(x_1, y_3),
            });
        }
        case 1: {
            return (array_2) => sortByDescending((y_4) => tryParseDateString(y_4.IncidentTime), array_2, {
                Compare: (x_2, y_5) => compare_1(x_2, y_5),
            });
        }
        case 2: {
            return (array_3) => sortBy_1((y_6) => tryParseDateString(y_6.Registered), array_3, {
                Compare: (x_3, y_7) => compare_1(x_3, y_7),
            });
        }
        case 3: {
            return (array_4) => sortByDescending((y_8) => tryParseDateString(y_8.Registered), array_4, {
                Compare: (x_4, y_9) => compare_1(x_4, y_9),
            });
        }
        default: {
            return (array) => sortBy_1((y) => [y.Action != null, y.ActionDeadline == null, bind((dt) => tryParseDateString(dt), y.ActionDeadline)], array, {
                Compare: (x, y_1) => compareArrays(x, y_1),
            });
        }
    }
}

function SortBy_FromString_Z721C83C5(i) {
    switch (i) {
        case "2": {
            return new SortBy(0);
        }
        case "3": {
            return new SortBy(1);
        }
        case "4": {
            return new SortBy(2);
        }
        case "5": {
            return new SortBy(3);
        }
        default: {
            return new SortBy(4);
        }
    }
}

function SortBy_get_Items() {
    return [new SortBy(4), new SortBy(1), new SortBy(0)];
}

export function ProjectNonconformity(projectNonconformityInputProps) {
    const projectId = projectNonconformityInputProps.projectId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setAddForm = patternInput[1];
    const addForm = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setRegistrations = patternInput_1[1];
    const registrations = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const canEdit = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const setAddWarning = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const setActionForm = patternInput_4[1];
    const actionForm = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const setSelectedImage = patternInput_5[1];
    const selectedImage = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(void 0);
    const categories = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505("-1");
    const selectedCategory = patternInput_7[0];
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(false);
    const showActionsNotConfirmed = patternInput_8[0];
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(new SortBy(4));
    const sortBy = patternInput_9[0];
    const handleFilter = (regs) => {
        const regs_1 = (selectedCategory === "-1") ? regs : regs.filter((z) => z.Categories.some((l) => (int32ToString(l.Id) === selectedCategory)));
        if (showActionsNotConfirmed) {
            return regs_1.filter((z_1) => (z_1.Action == null));
        }
        else {
            return regs_1;
        }
    };
    useReact_useEffectOnce_3A5B6456(() => {
        Nonconformity_fetchProjectRegistrations(projectId, (res) => {
            if (res.tag === 1) {
            }
            else {
                setRegistrations(res.fields[0]);
            }
        });
        Nonconformity_fetchCategories((res_1) => {
            if (res_1.tag === 1) {
            }
            else {
                patternInput_6[1](res_1.fields[0]);
            }
        });
        Project_fetchLoggedInUserPermissions(projectId, (res_2) => {
            patternInput_2[1](((res_2.tag === 0) ? res_2.fields[0].some((p) => (p === Functionality__ToInt(new Functionality(14)))) : false) ? true : hasPermission(new Functionality(67)));
        });
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            height: toText(printf("calc(100vh - %ipx)"))(64),
            padding: 2 + "vw",
            overflowY: "auto",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let si;
            return append((selectedImage != null) ? ((si = (selectedImage | 0), singleton(createElement(ImagePreview, {
                imageId: si,
                onClose: () => {
                    setSelectedImage(void 0);
                },
            })))) : ((empty_1())), delay(() => (addForm ? singleton(createElement(ProjectRegistration, {
                projectId: projectId,
                onClose: (addResult) => {
                    const fetch$ = () => {
                        Nonconformity_fetchProjectRegistrations(projectId, (res_3) => {
                            if (res_3.tag === 1) {
                            }
                            else {
                                setRegistrations(res_3.fields[0]);
                            }
                        });
                    };
                    switch (addResult.tag) {
                        case 2: {
                            fetch$();
                            setAddWarning(addResult.fields[0]);
                            break;
                        }
                        case 1: {
                            fetch$();
                            break;
                        }
                        default: {
                        }
                    }
                    setAddForm(false);
                },
            })) : append(singleton(createElement("div", {
                style: {
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(canEdit ? singleton(MuiHelpers_createElement(Button, [["color", "primary"], ["variant", "contained"], ["children", "Registrer avvik"], ["onClick", (_arg3) => {
                    setAddForm(!addForm);
                }]])) : singleton(createElement("div", {})), delay(() => {
                    let af;
                    return append((actionForm != null) ? ((af = (actionForm | 0), singleton(createElement(ActionForm, {
                        registrationId: af,
                        onClose: (res_4) => {
                            if (res_4 != null) {
                                const action = res_4;
                                setRegistrations((registrations != null) ? map((y) => {
                                    if (y.Id === af) {
                                        return new Nonconformity_ProjectRegistration(y.Id, y.ProjectId, y.Project, y.Categories, y.User, y.Responsible, y.IncidentTime, y.ActionDeadline, y.Registered, y.Desc, y.Images, action);
                                    }
                                    else {
                                        return y;
                                    }
                                }, registrations) : (void 0));
                            }
                            setActionForm(void 0);
                        },
                    })))) : ((empty_1())), delay(() => append(singleton(createElement("div", {
                        style: {
                            margin: ((0 + "px ") + 8) + "px",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let cats;
                            return append((categories != null) ? ((cats = categories, singleton(MuiHelpers_createElement(TextField, [["style", {
                                minWidth: 120,
                                marginRight: 20,
                                marginTop: 5,
                                marginBottom: 5,
                            }], ["size", "small"], ["select", true], ["variant", "outlined"], ["label", "Kategori"], ["value", selectedCategory], ["onChange", (e) => {
                                patternInput_7[1](Browser_Types_Event__Event_get_Value(e));
                            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(MuiHelpers_createElement(MenuItem, [["key", "0"], ["value", "-1"], ["children", "Ingen valgt"]])), delay(() => map((x_4) => MuiHelpers_createElement(MenuItem, [["key", int32ToString(x_4.Id)], ["value", int32ToString(x_4.Id)], ["children", x_4.Name]]), cats)))))))]])))) : ((empty_1())), delay(() => append(singleton(MuiHelpers_createElement(TextField, [["style", {
                                minWidth: 120,
                                marginRight: 20,
                                marginTop: 5,
                            }], ["size", "small"], ["select", true], ["variant", "outlined"], ["label", "Sortering"], ["value", SortBy__get_ToInt(sortBy)], ["onChange", (e_1) => {
                                patternInput_9[1](SortBy_FromString_Z721C83C5(Browser_Types_Event__Event_get_Value(e_1)));
                            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map((x_5) => MuiHelpers_createElement(MenuItem, [["key", int32ToString(SortBy__get_ToInt(x_5))], ["value", int32ToString(SortBy__get_ToInt(x_5))], ["children", toString(x_5)]]), SortBy_get_Items())))))]])), delay(() => singleton(MuiHelpers_createElement(FormControl, [["style", {
                                marginTop: 5,
                            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControlLabel, [["label", "Uten tiltak"], ["control", MuiHelpers_createElement(Switch, [["color", "primary"], ["checked", showActionsNotConfirmed], ["onChange", (e_2) => {
                                patternInput_8[1](Browser_Types_Event__Event_get_Checked(e_2));
                            }]])]])])]]))))));
                        })))),
                    })), delay(() => {
                        let arg10_1;
                        if (registrations != null) {
                            const regs_3 = registrations;
                            const numActions = handleFilter(regs_3.filter((r_1) => (r_1.Action != null))).length | 0;
                            return singleton(createElement("span", {
                                children: (arg10_1 = (regs_3.length | 0), toText(printf("%i avvik registrert. %i tiltak gjennomført"))(arg10_1)(numActions)),
                            }));
                        }
                        else {
                            return empty_1();
                        }
                    }))));
                })))))),
            })), delay(() => append(singleton(snackbarWarn(patternInput_3[0], () => {
                setAddWarning(void 0);
            })), delay(() => {
                if (registrations != null) {
                    const regs_4 = registrations;
                    return singleton(map((r_2) => drawRegistration(r_2, canEdit, false, (arg_1) => {
                        setActionForm(arg_1);
                    }, (arg_2) => {
                        setSelectedImage(arg_2);
                    }), SortBy__get_SortArray(sortBy)(handleFilter(regs_4))));
                }
                else {
                    return singleton(loadingIndicatorSmall());
                }
            })))))));
        })))),
    });
}

